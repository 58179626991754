import { ApiResponseHandler } from "../ApiResponseHandler";
import WorkOrderConfig from "../WorkOrderConfig";
import {
  AdditionalInfoType,
  ApproverType,
  AssigneeType,
  CardDetailsType,
  ImageType,
  ScheduleExecutionType,
  StatusDataProps,
  StatusDataType,
} from "../moduleCleaning/ModuleCleaningApi";

export interface WorkOrderGcInfo {
  scheduledStartDateTime: string | null;
  scheduledEndDateTime: string | null;
  actualStartDateTime: string | null;
  actualEndDateTime: string | null;
  plannedArea: number;
  actualArea: number;
  rejectedArea: number;
  plannedTables: number;
  actualTables: number;
  rejectedTables: number;
  cycleName: string;
  leadAssignee: AssigneeType;
}

export interface GrassCuttingInfoBean {
  selectedGCCoverageTypeIds: number[];
  selectedGrassCuttingTypeId: number;
  selectedGrassCuttingTypeName: string;
  selectedGCMethodId: number;
  laboursAllocated: number;
  machineAllocated: number;
  rentPerMachine?: number;
  fuelConsumption: number;
  fuelUnitPrice: number;
  herbicideTanks: number;
  herbicideConsumption: number;
  cost?: number;
  remarks: string;
  selectedReasonForDeviationId: number;
  otherReason?: string;
  dumpingInfo: string;
  proofOfDump?: string;
  numberOfWorkingHours: number;
  impactedHours: number;
}

export interface Vendor {
  id: number;
  name: string;
  title: string;
  availability: string;
  image: string;
  status: string;
  date: string;
  isPrimary: boolean;
}

export interface DropDownType {
  id: number;
  name: string;
  code?: string;
}

export interface GrassCutting {
  woId: number;
  isEdit: boolean;
  isAssetGrouped?: boolean;
  isEditCard?: boolean;
  status: StatusDataType[];
  cardDetailsInfo: CardDetailsType;
  additionalInfoBean: AdditionalInfoType;
  assignee: AssigneeType[];
  workOrderGcInfo: WorkOrderGcInfo;
  grassCuttingInfoBean: GrassCuttingInfoBean;
  beforeImage?: ImageType[];
  afterImage?: ImageType[];
  dumpImage?: ImageType[];
  vendorList?: Vendor[];
  gcMethod?: DropDownType[];
  gcCoverageType?: DropDownType[];
  reasonForDeviationList?: DropDownType[];
  approver1?: ApproverType | null;
  approver2?: ApproverType | null;
  approver3?: ApproverType | null;
  definedScheduleStatus: StatusDataProps[];
  scheduleExecution: ScheduleExecutionType;
  version: number;
}

export const getGrassCuttingApi = async (
  cardId: number
): Promise<GrassCutting> => {
  return ApiResponseHandler(
    await WorkOrderConfig.getInstance().get(`workorder/gc/${cardId}`)
  );
};

export const saveGrassCuttingApi = async (
  cardId: number,
  params: GrassCutting
) => {
  return ApiResponseHandler(
    await WorkOrderConfig.getInstance().put<GrassCutting, GrassCutting>(
      `workorder/gc/save/${cardId}`,
      params
    )
  );
};
