import { ApiResponseHandler } from "../ApiResponseHandler";
import WorkOrderConfig from "../WorkOrderConfig";
import {
  PMDetailsType,
  PMTasksDataType,
  PMTasksSaveDataType,
  SavePMDetailsType,
} from "../../pages/workOrderDetailScreens/preventiveMaintenance/types";

export const getPreventiveMaintenanceTasksApi = async (
  cardId: number,
  assetId?: number
): Promise<PMTasksDataType> => {
  let assetQuery = "";
  if (assetId || assetId === 0) {
    assetQuery = `?assetId=${assetId}`;
  }
  return ApiResponseHandler(
    await WorkOrderConfig.getInstance().get(
      `workorder/pm/${cardId}/tasks${assetQuery}`
    )
  );
};

export const getPreventiveMaintenanceDetailsApi = async (
  cardId: number
): Promise<PMDetailsType> => {
  return ApiResponseHandler(
    await WorkOrderConfig.getInstance().get(`workorder/pm/${cardId}`)
  );
};

export const savePreventiveMaintenanceTasksApi = async (
  cardId: number,
  params: PMTasksSaveDataType
) => {
  return ApiResponseHandler(
    await WorkOrderConfig.getInstance().patch<
      PMTasksSaveDataType,
      PMTasksSaveDataType
    >(`workorder/pm/${cardId}/updateTasks`, params)
  );
};

export const savePreventiveMaintenanceDetailsApi = async (
  cardId: number,
  params: SavePMDetailsType
) => {
  return ApiResponseHandler(
    await WorkOrderConfig.getInstance().put<
      SavePMDetailsType,
      SavePMDetailsType
    >(`workorder/pm/${cardId}/save`, params)
  );
};
