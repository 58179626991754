import React, { FC, useContext, useState, useEffect } from "react";
import "./TreeDropDown.css";
import { Box, Radio } from "@mui/material";
import { ReactComponent as RightArrow } from "../../assets/images/RightArrowBlue.svg";
import { ReactComponent as DownArrow } from "../../assets/images/DownArrow.svg";
import SearchBox from "./SearchBox";
import MediumTypography from "./MediumTypography";
import { SitesType } from "../../utils/type";
import { DropdownContext } from "../../layouts/appSidebar";
import CheckBox from "./CheckBox";
import ButtonComponent from "./ButtonComponent";

interface SiteDropdownProps {
  data: SitesType[];
  siteName: string;
}

const TreeDropdown: FC<SiteDropdownProps> = ({ data, siteName }) => {
  const [expanded, setExpanded] = useState<Record<string, boolean>>({});
  const [selectedSite, setSelectedSite] = useState<string>(siteName);
  const [selectedSubgroup, setSelectedSubgroup] = useState<string | null>(
    localStorage.getItem("selectedSubgroupId") ?? null
  );
  const [selectedAssets, setSelectedAssets] = useState<number[]>([]);
  const context = useContext(DropdownContext);
  const initialSelectedAssets: number[] = [];
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const storedSiteId = localStorage.getItem("selectedSiteId");
    const storedSubgroupId = localStorage.getItem("selectedSubgroupId");

    if (storedSiteId && storedSubgroupId) {
      const storedSite = data.find(
        (site) => site.siteId === parseInt(storedSiteId)
      );
      const storedSubgroup = storedSite?.subgroups.find(
        (subgroup) => subgroup.subGroupId === parseInt(storedSubgroupId)
      );
      if (storedSite && storedSubgroup) {
        setSelectedSite(storedSite.siteName);
        setSelectedSubgroup(storedSubgroup.subGroupName);
      }
    } else {
      const firstSite = data[0];
      const firstSubgroup = firstSite.subgroups[0];
      setSelectedSite(firstSite.siteName);
      setSelectedSubgroup(firstSubgroup?.subGroupName || null);
    }

    if (context.selectedAssetGroupsIds.length === 0) {
      data.forEach((site) => {
        site.subgroups.forEach((subgroup) => {
          subgroup.assetGroup.forEach((asset) => {
            initialSelectedAssets.push(asset.assetGroupId);
          });
        });
      });
    } else {
      initialSelectedAssets.push(...context.selectedAssetGroupsIds);
    }

    setSelectedAssets(initialSelectedAssets);
  }, []);

  const handleSiteSelection = (siteNameSelected: string, siteId: number) => {
    const selectedSiteData = data.find(
      (site: SitesType) => site.siteId === siteId
    );
    const firstSubgroupName =
      selectedSiteData?.subgroups?.[0]?.subGroupName || null;
    if (selectedSite === siteNameSelected) {
      setExpanded((prevExpanded) => ({
        ...prevExpanded,
        [siteName]: !prevExpanded[siteNameSelected],
      }));
      setSelectedSubgroup(firstSubgroupName);
    } else {
      setSelectedSite(siteNameSelected);
      setSelectedSubgroup(null);
      setSelectedAssets([]);
      const newExpanded = data.reduce((acc, site) => {
        acc[site.siteName] = site.siteName === siteNameSelected;
        return acc;
      }, {} as Record<string, boolean>);

      setExpanded(newExpanded);
    }
  };

  const handleSubgroupSelection = (
    subGroupSiteName: string,
    siteId: number,
    subGroupName: string,
    subGroupId: number
  ) => {
    const isCurrentlyExpanded = expanded[subGroupName];
    const selectedSiteData = data.find(
      (site) => site.siteName === subGroupSiteName
    );
    const selectedSubgroupData = selectedSiteData?.subgroups.find(
      (subgroup) => subgroup.subGroupName === subGroupName
    );

    if (selectedSubgroup === subGroupName) {
      setExpanded((prevExpanded) => ({
        ...prevExpanded,
        [subGroupName]: !isCurrentlyExpanded,
      }));
    } else {
      setSelectedSubgroup(subGroupName);
      const newExpanded = { ...expanded };
      data.forEach((site) => {
        if (site.siteName === subGroupSiteName) {
          site.subgroups.forEach((subgroup) => {
            newExpanded[subgroup.subGroupName] =
              subgroup.subGroupName === subGroupName;
          });
        } else {
          site.subgroups.forEach((subgroup) => {
            newExpanded[subgroup.subGroupName] = false;
          });
        }
      });

      setExpanded(newExpanded);

      const newSelectedAssets =
        selectedSubgroupData?.assetGroup.map((asset) => asset.assetGroupId) ||
        [];
      setSelectedAssets(newSelectedAssets);
    }
  };

  const handleAssetSelection = (assetGroupId: number) => {
    setSelectedAssets((prevSelectedAssets) => {
      const newSelectedAssets = prevSelectedAssets.includes(assetGroupId)
        ? prevSelectedAssets.filter((id) => id !== assetGroupId)
        : [...prevSelectedAssets, assetGroupId];

      // Use newSelectedAssets for context update

      localStorage.setItem(
        "selectedAssetGroupsIds",
        JSON.stringify(newSelectedAssets)
      );
      return newSelectedAssets;
    });
  };

  const handleSearchChange = (value: string) => {
    setSearchQuery(value);
  };

  const filterData = (filteredData: SitesType[]) => {
    return filteredData
      .filter((site) => {
        const siteNameMatches = site.siteName
          .toLowerCase()
          .includes(searchQuery.toLowerCase());
        const subgroupsMatch = site.subgroups.some((subgroup) => {
          const subgroupNameMatches = subgroup.subGroupName
            .toLowerCase()
            .includes(searchQuery.toLowerCase());
          const assetsMatch = subgroup.assetGroup.some((asset) =>
            asset.assetGroupName
              .toLowerCase()
              .includes(searchQuery.toLowerCase())
          );
          return subgroupNameMatches || assetsMatch;
        });
        return siteNameMatches || subgroupsMatch;
      })
      .map((site) => ({
        ...site,
        subgroups: site.subgroups
          .filter((subgroup) => {
            const subgroupNameMatches = subgroup.subGroupName
              .toLowerCase()
              .includes(searchQuery.toLowerCase());
            const assetsMatch = subgroup.assetGroup.some((asset) =>
              asset.assetGroupName
                .toLowerCase()
                .includes(searchQuery.toLowerCase())
            );
            return subgroupNameMatches || assetsMatch;
          })
          .map((subgroup) => ({
            ...subgroup,
            assetGroup: subgroup.assetGroup.filter((asset) =>
              asset.assetGroupName
                .toLowerCase()
                .includes(searchQuery.toLowerCase())
            ),
          })),
      }));
  };

  const filteredData = filterData(data);

  return (
    <Box sx={{ minWidth: "450px", display: "flex", flexDirection: "column" }}>
      <Box sx={{ alignSelf: "center", my: "8px" }}>
        <SearchBox
          labelId="defaultSearch"
          defaultlabel="Search"
          backgroundColor="#22272B"
          sxProps={{
            minWidth: "350px !important",
            textAlign: "center",
            alignSelf: "center !important",
          }}
          onChange={handleSearchChange}
        />
      </Box>

      <Box sx={{ alignSelf: "left", my: "8px" }}>
        <ul className="no-marker">
          {filteredData.map((items) => (
            <li key={items.siteId}>
              <Box
                className="flex__"
                sx={{
                  padding: "8px",
                  alignItems: "center",
                  display: "flex", // Ensure a flex container
                  justifyContent: "space-between", // Space out elements
                  width: "100%", // Prevent expanding width
                }}
              >
                {expanded[items.siteName] ? (
                  <DownArrow
                    style={{ flexShrink: 0 }}
                    className="cursor__pointer"
                    onClick={() => {
                      handleSiteSelection(items.siteName, items.siteId);
                    }}
                  />
                ) : (
                  <RightArrow
                    style={{ flexShrink: 0 }}
                    className="cursor__pointer"
                    onClick={() => {
                      handleSiteSelection(items.siteName, items.siteId);
                    }}
                  />
                )}
                <Radio
                  checked={selectedSite === items.siteName}
                  sx={{ color: "#FFFFFF", flexShrink: 0, cursor: "default" }}
                />
                <Box
                  className="flex__"
                  sx={{ flexGrow: 1 }}
                  alignItems="center"
                >
                  <MediumTypography
                    label={items.siteName}
                    fontSize="14px"
                    textColor="#ffffff"
                  />
                </Box>
              </Box>
              {items.subgroups.map(
                (subItems) =>
                  expanded[items.siteName] && (
                    <ul key={subItems.subGroupId} className="no-marker">
                      <li className="listItem">
                        <Box
                          className="flex__"
                          sx={{
                            alignItems: "center",
                          }}
                        >
                          {expanded[subItems.subGroupName] ? (
                            <DownArrow
                              className="cursor__pointer"
                              onClick={(e) => {
                                e.stopPropagation();

                                handleSubgroupSelection(
                                  items.siteName,
                                  items.siteId,
                                  subItems.subGroupName,
                                  subItems.subGroupId
                                );
                              }}
                            />
                          ) : (
                            <RightArrow
                              className="cursor__pointer"
                              onClick={(e) => {
                                e.stopPropagation();

                                handleSubgroupSelection(
                                  items.siteName,
                                  items.siteId,
                                  subItems.subGroupName,
                                  subItems.subGroupId
                                );
                              }}
                            />
                          )}
                          <Radio
                            checked={selectedSubgroup === subItems.subGroupName}
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                            sx={{
                              color: "#FFFFFF",
                              flexShrink: 0,
                              cursor: "default",
                            }}
                          />
                          <Box className="flex__" alignItems="center">
                            <MediumTypography
                              label={subItems.subGroupName}
                              fontSize="14px"
                              textColor="#ffffff"
                            />
                          </Box>
                        </Box>
                        {subItems.assetGroup.map(
                          (subSubItem) =>
                            expanded[subItems.subGroupName] && (
                              <ul
                                key={subSubItem.assetGroupId}
                                className="no-marker"
                              >
                                <li className="listItem">
                                  <Box
                                    className="flex__"
                                    sx={{
                                      padding: "8px",
                                      alignItems: "center",
                                      gap: "8px",
                                      cursor: "pointer",
                                      display: "flex", // Ensure a flex container
                                      justifyContent: "space-between", // Space out elements
                                      width: "100%", // Prevent expanding width
                                    }}
                                  >
                                    <Box className="flex__" alignItems="center">
                                      <CheckBox
                                        value={selectedAssets.includes(
                                          subSubItem.assetGroupId
                                        )}
                                        onCheckBoxClick={() => {
                                          handleAssetSelection(
                                            subSubItem.assetGroupId
                                          );
                                        }}
                                      />
                                      <MediumTypography
                                        label={subSubItem.assetGroupName}
                                        fontSize="14px"
                                        textColor="#ffffff"
                                      />
                                    </Box>
                                  </Box>
                                </li>
                              </ul>
                            )
                        )}
                      </li>
                    </ul>
                  )
              )}
            </li>
          ))}
        </ul>
      </Box>
      <Box
        className="width__100 flex__container p-md"
        sx={{
          position: "sticky",
          bottom: 0,
          zIndex: 1,
          backgroundColor: "#1D2125",
        }}
      >
        <ButtonComponent
          className="btn-primary btn-submit"
          labelId="Apply"
          defaultLabelId="Apply"
          sxProps={{ width: "100px" }}
          onClick={() => {
            const selectedSiteData = data.find(
              (site) => site.siteName === selectedSite
            );
            const selectedSubgroupData = selectedSiteData?.subgroups.find(
              (subgroup) => subgroup.subGroupName === selectedSubgroup
            );
            context?.dropdownValue(
              data,
              selectedSite,
              selectedSiteData?.siteId || 0,
              selectedSubgroupData?.subGroupId || 0,
              selectedAssets
            );
          }}
        />
      </Box>
    </Box>
  );
};

export default TreeDropdown;
