import { InputAdornment, SxProps, TextField } from "@mui/material";
import { ReactComponent as Search } from "../../assets/images/SearchIcon.svg";
import { ReactComponent as Cancel } from "../../assets/images/CloseIcon.svg";
import { useIntl } from "react-intl";

const ALLOWED_CHARACTERS = /^[a-zA-Z0-9,'.\s!-]*$/;

interface SearchType {
  onClick?: () => void;
  onChange?: (value: string) => void;
  value?: string;
  sxProps?: SxProps;
  labelId?: string;
  defaultlabel?: string;
  backgroundColor?: string;
  onCancel?: () => void;
  cancelRequired?: boolean;
}
const SearchBox = (props: SearchType) => {
  const {
    onClick,
    onChange,
    value,
    labelId,
    defaultlabel,
    sxProps,
    backgroundColor,
    onCancel,
    cancelRequired,
  } = props;
  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter" && onClick) {
      onClick();
    } else if (!ALLOWED_CHARACTERS.test(event.key)) {
      event.preventDefault();
    }
  };
  const handleCancelClick = () => {
    if (onCancel) {
      onCancel();
    }

    if (onChange) {
      onChange("");
    }
  };

  const intl = useIntl();
  return (
    <TextField
      className="SearchBox "
      placeholder={intl.formatMessage({
        id: labelId,
        defaultMessage: defaultlabel,
      })}
      autoFocus
      autoComplete="off"
      sx={{
        bgcolor: backgroundColor ? backgroundColor : "rgba(255, 255, 255, 1)",
        minWidth: "350px ",
        textAlign: "center",
        borderRadius: "4px",

        "& .MuiOutlinedInput-root": {
          "& > fieldset": {
            padding: 0,
            fontFamily: "Inter",
            border: "1px solid rgba(166, 197, 226, 0.16)",
            color: "#8C9BAB",
          },
          "&.Mui-focused fieldset": {
            borderColor: "rgba(166, 197, 226, 0.16)", // Border color when focused
          },
          "&:hover fieldset": {
            borderColor: "rgba(166, 197, 226, 0.16)", // Border color on hover
          },
          "& input.MuiInputBase-input": {
            fontFamily: "Inter",
            fontSize: "14px",
            color: "#8C9BAB",
          },
          "& input:placeholder": {
            fontFamily: "Inter",
            fontSize: "14px",
            color: "#8C9BAB",
          },
        },
        ...sxProps,
      }}
      size="small"
      onChange={(event) => {
        if (onChange !== undefined) {
          onChange(event.target.value.trimStart());
        }
      }}
      value={value}
      InputProps={{
        startAdornment: (
          <InputAdornment
            onClick={onClick}
            onKeyDown={handleKeyPress}
            sx={{
              cursor: "pointer",
              color: "#97A6A5",
            }}
            position="start"
          >
            <Search />
          </InputAdornment>
        ),
        endAdornment: value !== undefined && value !== "" && cancelRequired && (
          <InputAdornment
            onClick={handleCancelClick} // Handle cancel click
            sx={{
              cursor: "pointer",
              color: "#97A6A5",
            }}
            position="end"
          >
            <Cancel />
          </InputAdornment>
        ),
      }}
      onKeyDown={handleKeyPress}
    />
  );
};

export default SearchBox;
