import { Box, Button, Tooltip } from "@mui/material";
import TextInput from "./TextInput";
import MediumTypography from "./MediumTypography";
import { useState, FC } from "react";
import "../../../src/index.css";
import ButtonComponent from "./ButtonComponent";

interface inputMap {
  values?: string;
  fieldname?: string;
  fieldValue: string[];
  buttonCallBackClick?: () => void;
}

const TextInputMap: FC<inputMap> = ({
  values,
  fieldname,
  fieldValue,
  buttonCallBackClick,
}) => {
  const displayValues = (array: string[]) => {
    if (array.length === 0) {
      return "";
    }

    const firstValue = array[0];
    const remainingCount = array.length - 1;

    if (remainingCount > 0) {
      return `${firstValue} +${remainingCount}`;
    } else {
      return firstValue;
    }
  };

  return (
    <>
      <Box className="main-box">
        <Tooltip title={fieldValue.toString()} followCursor arrow>
          <Box sx={{ width: "100%" }}>
            <TextInput
              className="text-input-field"
              type="text"
              variant="outlined"
              inputProps={{
                readOnly: false,
              }}
              disabled={true}
              labelId="common.placeHolderText"
              defaultLabelId="common.placeHolderText"
              Value={displayValues(fieldValue)}
            />
          </Box>
        </Tooltip>
        {/* <ButtonComponent
          labelId="btn.Map"
          defaultLabelId="Map"
          onClick={buttonCallBackClick}
        /> */}
        <Button className="map-btn" onClick={buttonCallBackClick}>
          <MediumTypography
            labelId={"btn.Map"}
            defaultLabel="Map"
            textColor="#FFFFFF"
            sxProps={{
              fontSize: "12px",
              textAlign: "center",
              paddingBottom: "4px ",
            }}
          />
        </Button>
      </Box>
    </>
  );
};

export default TextInputMap;
