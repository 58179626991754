import React from "react";
import { Box } from "@mui/material";

import { ReactComponent as ErrorIcon } from "../../assets/images/ErrorIcon.svg";
import MediumTypography from "../../components/formlib/MediumTypography";
import { FormikErrors, FormikTouched } from "formik";

interface FormikErrorComponentProps {
  errors: FormikErrors<any>;
  touched: FormikTouched<any>;
  field: string;
}

const FormikErrorComponent: React.FC<FormikErrorComponentProps> = ({
  errors,
  touched,
  field,
}) => {
  if (errors[field] && touched[field]) {
    return (
      <Box className="loginError" sx={{ mt: "8px" }}>
        <Box sx={{ mr: "3px" }}>
          <ErrorIcon />
        </Box>
        <MediumTypography
          labelId={errors[field] as string}
          defaultLabel={"Please enter a valid value"}
          fontSize="12px"
          textColor="#AE2E24"
          fontWeight={400}
        />
      </Box>
    );
  }

  return <></>;
};

export default FormikErrorComponent;
