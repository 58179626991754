// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Custom styles for individual toasts */
.Toastify__toast {
  background-color: rgba(40, 46, 51, 1);
  color: #fff;
}

/* Custom styles for the progress bar */
.Toastify__progress-bar {
  background-color: #007aff; /* Custom progress bar color */
}
`, "",{"version":3,"sources":["webpack://./src/layouts/styles.css"],"names":[],"mappings":"AAAA,wCAAwC;AACxC;EACE,qCAAqC;EACrC,WAAW;AACb;;AAEA,uCAAuC;AACvC;EACE,yBAAyB,EAAE,8BAA8B;AAC3D","sourcesContent":["/* Custom styles for individual toasts */\n.Toastify__toast {\n  background-color: rgba(40, 46, 51, 1);\n  color: #fff;\n}\n\n/* Custom styles for the progress bar */\n.Toastify__progress-bar {\n  background-color: #007aff; /* Custom progress bar color */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
