import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import CheckBox from "../../components/formlib/CheckBox";
import MediumTypography from "../../components/formlib/MediumTypography";
import DayPagination from "../../components/formlib/DayPagination";
import ButtonComponent from "../../components/formlib/ButtonComponent";
import {
  ShiftCardTypes,
  ShiftDashboardType,
  ShiftsCardType,
} from "../../utils/type";
import { ReactComponent as DragIcon } from "../../assets/images/DragIcon.svg";
import CardComponent from "../../components/formlib/CardComponent";
import { ReactComponent as Revoke } from "../../assets/images/Revoke.svg";
import { ReactComponent as BacklogsIcon } from "../../assets/images/BacklogsIcon.svg";
import { ReactComponent as SingleDispatch } from "../../assets/images/SingleDispatchIcon.svg";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import TeamMembers from "./TeamMembers";
import { TeamMemberComponentProps } from "../../components/formlib/TeamMemberComponent";
import DispatchModal from "../../components/formlib/modal/DispatchModal";
import ShiftWarningModal from "../../components/formlib/modal/ShiftWarningModal";
import {
  DispatchWorkOrderParams,
  dispatchWorkOrderApi,
  moveScheduleCardsApi,
} from "../../api/workOrderApi/schedulerApi/Scheduler";
import moment from "moment";
import RevokeModal from "../../components/formlib/modal/RevokeModal";
import SchedulerCalendar from "../../components/formlib/SchedulerCalendar";
import ErrorModal from "../../components/formlib/modal/ErrorModal";

import ReassignModalPopup from "../ReassignModalPopup";
import { hasPermission } from "../../utils/checkPermission";
import dayjs from "dayjs";
import useAvailableHeight from "../../utils/useAvailableHeight";
import { handleError } from "../../utils/commonFunctions";

interface SchedulerCardProps {
  cardDashboardDetails: ShiftDashboardType;
  dataLoadCallBack: () => void;
  callApiCallBack: () => void;
  selectedAssetGroupIds?: number[];
}

const SchedulerCardDashboard: FC<SchedulerCardProps> = ({
  cardDashboardDetails,
  dataLoadCallBack,
  callApiCallBack,
  selectedAssetGroupIds,
}) => {
  const activeDate = new Date();

  const [shiftsData, setShiftsData] = useState<ShiftsCardType[]>([]);
  const [backlogsData, setBacklogsData] = useState<ShiftCardTypes[]>([]);
  const [selectedDispatchCards, setSelectedDispatchCards] = useState<
    ShiftsCardType[]
  >([]);
  const [openDispatchModal, setOpenDispatchModal] = useState(false);
  const [openShiftWarningModal, setOpenShiftWarningModal] = useState(false);
  const [selectedDragDropResult, setSelectedDragResult] = useState();
  const [selectedIndices, setSelectedIndices] = useState<number[]>([]);
  const shiftWarningText = useRef<string>();
  const [dispatchResponse, setDispatchResponse] =
    useState<DispatchWorkOrderParams>();
  const [selectAllCards, setSelectAllCards] = useState<boolean>(false);
  const [isBacklogsOpen, setIsBacklogsOpen] = useState<boolean>(false);
  const [openRevokeModal, setOpenRevokeModel] = useState(false);
  const [revokeDetails, setRevokeDetails] = useState<
    ShiftCardTypes | undefined
  >();
  const [cardMembers, setCardMembers] = useState<ShiftCardTypes>(
    {} as ShiftCardTypes
  );
  const [cardShiftDetails, setCardShiftDetails] = useState<ShiftsCardType>();

  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [errorDesc, setErrorDesc] = useState<string>("");
  const [draggingItem, setDraggingItem] = useState<ShiftCardTypes | null>(null);
  const [isDragEnabled, setIsDragEnabled] = useState<boolean>(false);
  const [reassignModal, setReassignModal] = useState(false);
  const [cardId, setCardId] = useState(0);
  const [assetGroupId, setAssestGroupId] = useState<number[]>([]);
  const [uniqueId, setUniqueId] = useState(0);
  const [version, setVersion] = useState(0);
  const [shiftCollapseStatus, setShiftCollapseStatus] = useState<boolean[]>([]);

  const availableHeight = useAvailableHeight(350);

  const toggleBacklogs = () => {
    setIsBacklogsOpen(!isBacklogsOpen);
  };

  useEffect(() => {
    getAllCards();
  }, [cardDashboardDetails]);

  // get all the cards
  const getAllCards = useCallback(() => {
    let totalCount = 0;

    let updatedBacklogsData = backlogsData;
    let updatedShiftsData = shiftsData;

    if (cardDashboardDetails !== null) {
      if (cardDashboardDetails.backlogs) {
        cardDashboardDetails.backlogs = cardDashboardDetails.backlogs.map(
          (card) => {
            totalCount++;
            return {
              ...card,
              sequenceCount: totalCount,
              id: totalCount,
            };
          }
        );

        updatedBacklogsData = cardDashboardDetails.backlogs;
      }

      cardDashboardDetails.shifts.forEach((shift) => {
        if (shift.cards) {
          shift.cards = shift.cards.map((card, index) => {
            totalCount++;
            return {
              ...card,
              sequenceCount: totalCount,
              id: totalCount,
            };
          });
        }
      });

      updatedShiftsData = cardDashboardDetails.shifts;

      setBacklogsData(updatedBacklogsData);
      setShiftsData(updatedShiftsData);
    }
  }, [cardDashboardDetails]);

  const reorderOnDragEnd = useCallback(
    (list: any[], startIndex: number, endIndex: number): any[] => {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);
      return result;
    },
    []
  );

  //drag shifts

  const onShiftsDragEnd = (result: any) => {
    const { source, destination } = result;
    const droppableIdPrefix = "droppable-shifts-";

    // Dropped outside the list
    if (!destination) {
      return;
    }

    // Get the source and destination shift indices
    const sourceShiftIndex = parseInt(
      source.droppableId.replace(droppableIdPrefix, ""),
      10
    );
    const destShiftIndex = parseInt(
      destination.droppableId.replace(droppableIdPrefix, ""),
      10
    );

    // const movedCard = shiftsData[destShiftIndex].cards[destination.index];

    if (
      shiftsData[destShiftIndex].shiftEndTime &&
      shiftsData[destShiftIndex].shiftStartTime
    ) {
      const now = dayjs();
      const startTime = dayjs()
        .hour(+shiftsData[destShiftIndex].shiftStartTime.split(":")[0])
        .minute(+shiftsData[destShiftIndex].shiftStartTime.split(":")[1]);
      const endTimeOriginal = dayjs()
        .hour(+shiftsData[destShiftIndex].shiftEndTime.split(":")[0])
        .minute(+shiftsData[destShiftIndex].shiftEndTime.split(":")[1]);
      let endTime = endTimeOriginal;

      // Check if the shift ends the next day and adjust endTime for comparison
      if (endTime.isBefore(startTime)) {
        endTime = endTime.add(1, "day"); // Shift ends the next day
      }

      // Adjust "now" for comparison if it's before the startTime and endTime is the next day
      // This is useful when "now" is after midnight but the shift started before midnight the previous day
      if (
        now.isBefore(startTime) &&
        now.hour() <= endTime.hour() &&
        endTime.day() !== startTime.day()
      ) {
        now.add(1, "day");
      }

      // If the shift end time has already passed, do not allow the drop
      if (now.isAfter(endTime)) {
        return;
      }
    }

    // Reordering in the same list
    if (source.droppableId === destination.droppableId) {
      const items = reorderOnDragEnd(
        shiftsData[sourceShiftIndex].cards,
        source.index,
        destination.index
      );

      // Create a new state with the reordered items
      const newState = shiftsData.map((shift, index) => {
        if (index === sourceShiftIndex) {
          return {
            ...shift,
            cards: items,
          };
        }
        return shift;
      });
      setShiftsData(newState);
      callMoveScheduleCardsApi("MOVE_WO_VERTICAL");
    } else {
      // Moving between lists
      setOpenShiftWarningModal(true);
      setSelectedDragResult(result);
    }
  };

  //after warning perform drag and drop
  const performDragAndDrop = (result: any) => {
    const { source, destination } = result;
    const droppableIdPrefix = "droppable-shifts-";

    const sourceList =
      shiftsData[
        parseInt(source.droppableId.replace(droppableIdPrefix, ""), 10)
      ].cards;
    const destList =
      shiftsData[
        parseInt(destination.droppableId.replace(droppableIdPrefix, ""), 10)
      ].cards;
    const [removed] = sourceList.splice(source.index, 1);
    destList.splice(destination.index, 0, removed);

    // Create a new state with the updated lists
    const newState = shiftsData.map((shift, index) => {
      if (
        index ===
        parseInt(source.droppableId.replace(droppableIdPrefix, ""), 10)
      ) {
        return {
          ...shift,
          cards: sourceList,
        };
      } else if (
        index ===
        parseInt(destination.droppableId.replace(droppableIdPrefix, ""), 10)
      ) {
        callMoveScheduleCardsApi(
          "MOVE_THROUGH_SHIFTS",
          shift.shiftId,
          shift.shiftStartTime,
          shift.shiftEndTime
        );

        return {
          ...shift,
          cards: destList,
        };
      }

      return shift;
    });

    setShiftsData(newState);
  };

  const callMoveScheduleCardsApi = (
    moveAction: "MOVE_WO_VERTICAL" | "MOVE_THROUGH_SHIFTS",
    shiftId?: number,
    scheduledStartTime?: string,
    scheduledEndTime?: string
  ) => {
    const startTime = scheduledStartTime
      ? `${moment(activeDate).format("YYYY-MM-DD")} ${scheduledStartTime}`
      : `${moment(activeDate).format("YYYY-MM-DD")} ${
          draggingItem?.shiftStartTime
        }`;
    const endTime = scheduledEndTime
      ? `${moment(activeDate).format("YYYY-MM-DD")} ${scheduledEndTime}`
      : `${moment(activeDate).format("YYYY-MM-DD")} ${
          draggingItem?.shiftEndTime
        }`;
    let moveSchedulerCardObj = {
      woUniqueId: draggingItem?.sequenceId ?? 0,
      shiftId: shiftId ? shiftId : draggingItem?.shiftId ?? 0,
      startTime: moment(startTime).format("YYYY-MM-DDTHH:mm:ss"),
      endTime: moment(endTime).format("YYYY-MM-DDTHH:mm:ss"),
      currentDate: `${moment(activeDate).format("YYYY-MM-DD")}`,
      version: draggingItem?.version ?? 1,
    };
    moveScheduleCardsApi(
      draggingItem?.cardId ?? 0,
      moveAction,
      moveSchedulerCardObj
    )
      .then((res) => {
        setDraggingItem(null);
        callApiCallBack();
      })
      .catch((err) => {
        setOpenErrorModal(true);
        handleError(err, setErrorDesc);
        setDraggingItem(null);
      });
  };

  //selecting the cards for dispatch
  const toggleCheckBox = useCallback(
    (index: number, card: ShiftCardTypes, shift: ShiftsCardType) => {
      if (selectedIndices.includes(index)) {
        setSelectedIndices(selectedIndices.filter((i) => i !== index));
      } else {
        setSelectedIndices([...selectedIndices, index]);
      }
      const updatedSelectedDispatchCards = [...selectedDispatchCards];

      const existingShiftIndex = updatedSelectedDispatchCards.findIndex(
        (selectedShift) => selectedShift.shiftId === shift.shiftId
      );

      if (existingShiftIndex !== -1) {
        const updatedShift: ShiftsCardType =
          updatedSelectedDispatchCards[existingShiftIndex];
        const existingCardIndex = updatedShift.cards.findIndex(
          (c: any) => c.sequenceCount === card.sequenceCount
        );
        if (existingCardIndex !== -1) {
          updatedShift.cards.splice(existingCardIndex, 1);
          if (updatedShift.cards.length === 0) {
            updatedSelectedDispatchCards.splice(existingShiftIndex, 1);
          }
        } else {
          updatedShift.cards.push(card);
        }
      } else {
        if (card) {
          const newShift = {
            ...shift,
            cards: [card],
          };
          updatedSelectedDispatchCards.push(newShift);
        }
      }

      setSelectedDispatchCards(updatedSelectedDispatchCards);
    },
    [selectedIndices]
  );

  //select all the shifts for dispatch
  const handleToggleAll = (checked: boolean) => {
    if (checked) {
      setSelectAllCards(true);
      setSelectedIndices(
        Array.from({ length: shiftsData.length }, (_, i) =>
          shiftsData[i].cards.map((card) => card.sequenceId)
        ).flat()
      );
      const selectedShifts = shiftsData.filter(
        (shift) => shift.cards.length > 0
      );

      setSelectedDispatchCards(selectedShifts);
    } else {
      setSelectAllCards(false);
      setSelectedIndices([]);
      setSelectedDispatchCards([]);
    }
  };

  //dispatch api call
  const readyToDispatch = (
    selectedDispatchCardsForDispatch: ShiftsCardType[]
  ) => {
    let obj = {
      dispatchDate: moment(activeDate).format("YYYY-MM-DD"),
      isFirstTimeDispatch: dispatchResponse?.isFirstTimeDispatch
        ? dispatchResponse.isFirstTimeDispatch
        : 0,
      includeWarningAndDispatch: dispatchResponse?.includeWarningAndDispatch
        ? dispatchResponse.includeWarningAndDispatch + 1
        : 0,
      shifts: selectedDispatchCardsForDispatch,
    };
    dispatchWorkOrderApi(
      cardDashboardDetails.selectedSiteId,
      cardDashboardDetails.selectedSubgroupId,
      obj
    )
      .then((response) => {
        setDispatchResponse(response);
        setSelectedDispatchCards(response.shifts);
        setOpenDispatchModal(!openDispatchModal);
      })
      .catch((err) => {
        setOpenErrorModal(true);
        handleError(err, setErrorDesc);
      });
  };

  //while moving shift from one shift to other
  const selectedText = (value: string) => {
    if (value === "Yes") {
      shiftWarningText.current = "Yes";
      setOpenShiftWarningModal(!openShiftWarningModal);
      performDragAndDrop(selectedDragDropResult);
    } else {
      shiftWarningText.current = "No";
      setOpenShiftWarningModal(!openShiftWarningModal);
    }
  };

  const teamMembersData = getTeamMembersFromAllShifts(
    cardDashboardDetails?.shifts
  );

  //getting all the team members from all the shifts
  function getTeamMembersFromAllShifts(
    shifts: ShiftsCardType[]
  ): TeamMemberComponentProps[] {
    const allTeamMembers = shifts?.flatMap((shift) =>
      shift?.teams.map((teamMember) => ({
        data: teamMember,
        shiftType: shift.shiftName[0],
        shiftStartTime: shift.shiftStartTime,
        shiftEndTime: shift.shiftEndTime,
        icon: <DragIcon />,
      }))
    );

    return allTeamMembers;
  }

  //call back to know all the data are loaded in card component
  const callback: any = (index: number, lenght: number) => {
    if (index === lenght - 1) {
      dataLoadCallBack();
    }
  };

  const handleSingleDispatchClick = (
    shifts: ShiftsCardType[],
    selectedCard: ShiftCardTypes
  ) => {
    const selectedShift = shifts.find((shift) =>
      shift.cards.some((card) => card.sequenceId === selectedCard.sequenceId)
    );

    if (selectedShift) {
      // Filter the cards within the selected shift to include only the selected card
      const filteredCards = selectedShift.cards.filter(
        (card) => card.sequenceId === selectedCard.sequenceId
      );

      // Create a new shift object with the filtered cards
      const shiftWithSelectedCard: ShiftsCardType = {
        ...selectedShift,
        cards: filteredCards,
      };
      setSelectedDispatchCards((prev) => [shiftWithSelectedCard]);
      // Here you have access to the shift that contains only the selected card
      readyToDispatch([shiftWithSelectedCard]);
    }
  };

  const handleGetCardMembers = (
    item: ShiftCardTypes,
    shiftDetails: ShiftsCardType
  ) => {
    if (hasPermission("Assign_Team_Member")) {
      if (item.isAssetGrouped) {
        setReassignModal(true);
      } else {
        setCardMembers(item);
        setIsDragEnabled(true);
        setCardShiftDetails(shiftDetails);
      }
    }
  };

  const roundOffTime = (time: string): string => {
    // Parse the time string
    const [hours, minutes, seconds] = time.split(":").map(Number);

    // Create a Date object with the parsed time
    const date = new Date();
    date.setHours(hours, minutes, seconds, 0);

    // Determine if we should round up or down
    if (minutes >= 30) {
      date.setHours(date.getHours() + 1);
    }

    // Get the rounded hours
    const roundedHours = date.getHours();

    // Format the rounded time as HH:00:00
    const roundedTime = `${roundedHours.toString().padStart(2, "0")}:00:00`;

    return roundedTime;
  };

  const memoizedBacklogsData = useMemo(() => {
    return backlogsData.map((item, index) => ({
      ...item,
      sequenceCount: index + 1,
      backgroundColor: index % 2 === 0 ? "#161A1D" : "#1F272C",
    }));
  }, [backlogsData]);

  useEffect(() => {
    // Initialize all shifts as open (true)
    setShiftCollapseStatus(new Array(shiftsData.length).fill(true));
  }, [shiftsData]);

  const toggleShiftCollapse = (index: number) => {
    setShiftCollapseStatus((prevState) =>
      prevState.map((status, i) => (i === index ? !status : status))
    );
  };

  return (
    <>
      {openDispatchModal && (
        <DispatchModal
          open={openDispatchModal}
          ShiftDetails={selectedDispatchCards}
          handleClose={() => {
            setOpenDispatchModal(!openDispatchModal);
            setSelectedIndices([]);
            setSelectedDispatchCards([]);
          }}
          selectedSiteId={cardDashboardDetails.selectedSiteId}
          selectedSubgroupId={cardDashboardDetails.selectedSubgroupId}
          activeDate={activeDate}
          handleDispatchCallBack={() => {
            setSelectedIndices([]);
            setSelectedDispatchCards([]);
            callApiCallBack();
          }}
        />
      )}
      {openErrorModal && (
        <ErrorModal
          descriptionText={errorDesc}
          open={openErrorModal}
          handleClose={() => setOpenErrorModal(false)}
          onPositiveClick={() => {
            setOpenErrorModal(false);
          }}
        />
      )}
      {openShiftWarningModal && (
        <ShiftWarningModal
          open={openShiftWarningModal}
          handleClose={() => {
            setOpenShiftWarningModal(!openShiftWarningModal);
          }}
          selectedText={selectedText}
        />
      )}
      {openRevokeModal && revokeDetails && (
        <RevokeModal
          open={openRevokeModal}
          handleClose={() => {
            setOpenRevokeModel(!openRevokeModal);
          }}
          // revokeDetails={revokeDetails}
          sequenceId={revokeDetails.sequenceId}
          version={revokeDetails.version}
          cardId={revokeDetails.cardId}
          statusCode={revokeDetails.statusCode}
          status={revokeDetails.dispatchStatusType}
          requestedDate={activeDate}
          revokeCallBack={() => callApiCallBack()}
        />
      )}
      <Grid container sx={{ color: "#FFFFFF", fontSize: "12px" }}>
        {reassignModal && (
          <ReassignModalPopup
            open={reassignModal}
            handleClose={() => setReassignModal(false)}
            cardId={cardId}
            assetGroupId={assetGroupId}
            woUniqueId={uniqueId}
            version={version}
            reAssignCallBack={() => {
              callApiCallBack();
            }}
          />
        )}
        <Grid item xs={9.5}>
          <TableContainer
            sx={{
              overflow: "auto !important",
              minHeight: availableHeight,
              maxHeight: availableHeight,
            }}
            className="tableStyles"
          >
            <Table
              sx={{
                color: "#FFFFFF",
                fontSize: "12px",
                width: "100%",
                minHeight: "100px",
              }}
              stickyHeader
              aria-label="sticky table"
            >
              <TableHead>
                <TableRow>
                  <TableCell className="tablecellCss">
                    <Box className="flex__" alignItems={"center"}>
                      <Box className="flex__" alignItems={"center"}>
                        <CheckBox
                          value={selectAllCards}
                          style={{ color: "#2C2C2E" }}
                          onCheckBoxClick={(val) => handleToggleAll(val)}
                        />
                        <MediumTypography
                          labelId="SerialNumber"
                          defaultLabel="S.N"
                          fontSize="14px"
                        />
                      </Box>
                      <Box
                        sx={{
                          width: "0px",
                          height: "25px",
                          border: "1px solid rgba(51, 59, 67, 1)",
                          rotate: "-90",
                          position: "relative",
                          left: "10px",
                        }}
                      />
                    </Box>
                  </TableCell>

                  <TableCell className="tablecellCss">
                    <Box className="flex__">
                      <DayPagination />
                    </Box>
                  </TableCell>

                  <TableCell className="tablecellCss">
                    <Box className="flex__" justifyContent={"center"}>
                      <MediumTypography
                        labelId="ActionLabel"
                        defaultLabel="Action"
                        fontSize="14px"
                      />
                    </Box>
                  </TableCell>
                </TableRow>
              </TableHead>

              {backlogsData && (
                <TableBody>
                  <TableRow>
                    <TableCell style={{ borderBottom: "none" }}></TableCell>
                    <TableCell className="tablecellCss-backgroundDiff">
                      <Box className="horizontalTextLine flex__">
                        <MediumTypography
                          labelId="BACKLOGS"
                          defaultLabel="BACKLOGS"
                          label="BACKLOGS"
                          className="mr-sm"
                        />
                        <ButtonComponent
                          label={
                            (cardDashboardDetails.backLogCardCount < 10
                              ? "0"
                              : "") +
                            cardDashboardDetails.backLogCardCount.toString()
                          }
                          type="button"
                          variantType="contained"
                          sxProps={{
                            width: "30px",
                            height: "20px",
                            borderRadius: "36px",
                            padding: "4px",
                            backgroundColor: "rgba(90, 96, 127, 1)",
                            color: "rgba(255, 255, 255, 1)",
                            minWidth: "0",
                            cursor: "default",
                            border: "none",
                            "&:hover": {
                              border: "none",
                              backgroundColor: "rgba(90, 96, 127, 1)",
                              color: "rgba(255, 255, 255, 1)",
                            },
                          }}
                        />
                        <BacklogsIcon
                          className={`ml-xs cursor__pointer ${
                            !isBacklogsOpen ? "rotated-180" : ""
                          }`}
                          onClick={toggleBacklogs}
                        />
                      </Box>
                    </TableCell>
                    <TableCell className="tablecellCss-backgroundDiff"></TableCell>
                  </TableRow>
                </TableBody>
              )}

              <TableBody>
                {isBacklogsOpen &&
                  memoizedBacklogsData.length !== 0 &&
                  memoizedBacklogsData.map((item, index) => {
                    return (
                      <TableRow
                        key={index}
                        sx={{
                          backgroundColor:
                            item.sequenceCount && item.sequenceCount % 2 === 0
                              ? "#161A1D"
                              : "#1F272C",
                        }}
                      >
                        <TableCell className="tableCellCss-withoutBackground">
                          <Box
                            display={"flex"}
                            alignItems={"center"}
                            sx={{
                              textAlign: "center",
                              color: "rgba(255, 255, 255, 1)",
                            }}
                          >
                            <CheckBox
                              value={false}
                              disable
                              style={{ color: "#2C2C2E" }}
                            />
                            {item.sequenceCount}
                          </Box>
                          <Box
                            sx={{
                              textAlign: "center",
                              position: "relative",
                              right: "14px",
                            }}
                          >
                            <DragIcon />
                          </Box>
                        </TableCell>

                        <TableCell style={{ borderBottom: "none" }}>
                          <CardComponent
                            cardDataArray={item}
                            cardType={"backlog"}
                            cardShiftStartTime={
                              item.shiftStartTime
                                ? item.shiftStartTime.substring(0, 5)
                                : ""
                            }
                            cardShiftName={item.shift}
                            cardShiftEndTime={
                              item.shiftEndTime
                                ? item.shiftEndTime.substring(0, 5)
                                : ""
                            }
                            backGroundColor={
                              item.sequenceCount && item.sequenceCount % 2 === 0
                                ? "#161A1D"
                                : "#1F272C"
                            }
                            boxShadow={
                              item.sequenceCount && item.sequenceCount % 2 === 0
                                ? "none"
                                : "0 4px 8px rgba(0, 0, 0, 0.1)"
                            }
                          />
                        </TableCell>
                        <TableCell className="tableCellCss-withoutBackground">
                          <Box>
                            {item.statusCode !== "SCHEDULED" &&
                              item.statusCode !== "NEW" &&
                              item.statusCode !== "INPROGRESS" && (
                                <Revoke
                                  style={{
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setRevokeDetails(item);
                                    if (hasPermission("Reinstate_WO")) {
                                      setRevokeDetails(item);

                                      setOpenRevokeModel(!openRevokeModal);
                                    }
                                  }}
                                />
                              )}
                          </Box>
                          {(item.statusCode === "SCHEDULED" ||
                            item.statusCode === "NEW" ||
                            item.statusCode === "INPROGRESS") && (
                            <Box
                              onClick={(e) => {
                                setCardMembers(item);
                              }}
                            >
                              {hasPermission("Move_WO") && (
                                <SchedulerCalendar
                                  selectedDate={activeDate}
                                  cardDetails={cardMembers}
                                  scheduleMoveCallBack={() => {
                                    callApiCallBack();
                                  }}
                                  type="SCHEDULE"
                                  shiftStartTime={item.shiftStartTime}
                                  shiftEndTime={item.shiftEndTime}
                                />
                              )}
                            </Box>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>

              <DragDropContext
                onDragEnd={onShiftsDragEnd}
                onDragStart={(start) => {
                  const { source } = start;
                  const shiftIndex = parseInt(
                    source.droppableId.split("-")[2],
                    10
                  );
                  const shift = shiftsData[shiftIndex];
                  const item = shift.cards[source.index];

                  setDraggingItem(item);
                }}
              >
                {shiftsData.map((shift, shiftIndex) => (
                  <React.Fragment key={shiftIndex}>
                    <TableBody>
                      <TableRow>
                        <TableCell style={{ borderBottom: "none" }}></TableCell>
                        <TableCell className="tableCellCss-withoutBackground">
                          <Box className="horizontalTextLine flex__">
                            <MediumTypography
                              labelId="ShiftLabel"
                              defaultLabel="Shift"
                              className="mr-sm"
                            />
                            <MediumTypography
                              label={` ${shift.shiftName}`}
                              className="mr-sm"
                            />
                            <ButtonComponent
                              label={`${roundOffTime(
                                shift.shiftStartTime
                              ).substring(0, 5)} - ${roundOffTime(
                                shift.shiftEndTime
                              ).substring(0, 5)}`}
                              type="button"
                              className="mr-sm"
                              variantType="contained"
                              sxProps={{
                                width: "100px",
                                height: "20px",
                                cursor: "default",
                                borderRadius: "36px",
                                padding: "4px",
                                backgroundColor: "rgba(90, 96, 127, 1)",
                                color: "rgba(255, 255, 255, 1)",
                                minWidth: "0",
                                border: "none",
                                "&:hover": {
                                  border: "none",
                                  backgroundColor: "rgba(90, 96, 127, 1)",
                                  color: "rgba(255, 255, 255, 1)",
                                },
                              }}
                            />
                            <ButtonComponent
                              label={
                                (shift.cardBlockCount < 10 ? "0" : "") +
                                shift.cardBlockCount.toString()
                              }
                              type="button"
                              variantType="contained"
                              sxProps={{
                                width: "30px",
                                height: "20px",
                                borderRadius: "36px",
                                border: "none",
                                padding: "4px",
                                backgroundColor: "rgba(90, 96, 127, 1)",
                                color: "rgba(255, 255, 255, 1)",
                                cursor: "default",
                                minWidth: "0",
                                "&:hover": {
                                  border: "none",
                                  backgroundColor: "rgba(90, 96, 127, 1)",
                                  color: "rgba(255, 255, 255, 1)",
                                },
                              }}
                            />
                            <Box
                              onClick={() => toggleShiftCollapse(shiftIndex)}
                            >
                              <BacklogsIcon
                                className={`ml-xs mt-xs cursor__pointer ${
                                  !shiftCollapseStatus[shiftIndex]
                                    ? "rotated-180"
                                    : ""
                                }`}
                              />
                            </Box>
                          </Box>
                        </TableCell>
                        <TableCell style={{ borderBottom: "none" }}></TableCell>
                      </TableRow>
                    </TableBody>
                    {shiftCollapseStatus[shiftIndex] && (
                      <Droppable droppableId={`droppable-shifts-${shiftIndex}`}>
                        {(provided) => (
                          <TableBody
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                          >
                            {/* {shiftCollapseStatus[shiftIndex] && */}
                            {shift.cards.length > 0 &&
                              shift.cards.map((item, index) => (
                                <Draggable
                                  key={String(item.sequenceId)}
                                  draggableId={String(item.sequenceId)}
                                  index={index}
                                >
                                  {(provided2, snapshot) => (
                                    <TableRow
                                      ref={provided2.innerRef}
                                      {...provided2.draggableProps}
                                      {...provided2.dragHandleProps}
                                      style={{
                                        ...provided2.draggableProps.style,
                                      }}
                                      key={index}
                                      sx={{
                                        backgroundColor:
                                          item.sequenceCount &&
                                          item.sequenceCount % 2 === 0
                                            ? "#161A1D"
                                            : "#1F272C",
                                      }}
                                    >
                                      <TableCell className="tableCellCss-withoutBackground">
                                        <Box
                                          className="flex__"
                                          alignItems={"center"}
                                          sx={{
                                            textAlign: "center",
                                            color: "rgba(255, 255, 255, 1)",
                                          }}
                                        >
                                          <CheckBox
                                            value={selectedIndices.includes(
                                              item.sequenceId
                                            )}
                                            onCheckBoxClick={() =>
                                              toggleCheckBox(
                                                item.sequenceId,
                                                item,
                                                shift
                                              )
                                            }
                                          />

                                          {item.sequenceCount}
                                        </Box>
                                        <Box
                                          sx={{
                                            textAlign: "center",
                                            position: "relative",
                                            right: "14px",
                                          }}
                                        >
                                          <DragIcon />
                                        </Box>
                                      </TableCell>
                                      <TableCell
                                        style={{ borderBottom: "none" }}
                                      >
                                        <CardComponent
                                          cardDataArray={item}
                                          cardType={"shift"}
                                          cardShiftStartTime={
                                            shift.shiftStartTime
                                              ? shift.shiftStartTime.substring(
                                                  0,
                                                  5
                                                )
                                              : ""
                                          }
                                          cardShiftName={shift.shiftName[0]}
                                          cardShiftEndTime={
                                            shift.shiftEndTime
                                              ? shift.shiftEndTime.substring(
                                                  0,
                                                  5
                                                )
                                              : ""
                                          }
                                          backGroundColor={
                                            item.sequenceCount &&
                                            item.sequenceCount % 2 === 0
                                              ? "#161A1D"
                                              : "#1F272C"
                                          }
                                          boxShadow={
                                            item.sequenceCount &&
                                            item.sequenceCount % 2 === 0
                                              ? "none"
                                              : "0 4px 8px rgba(0, 0, 0, 0.1)"
                                          }
                                          userStackCallback={() => {
                                            setCardId(item.cardId);
                                            if (selectedAssetGroupIds) {
                                              setAssestGroupId(
                                                selectedAssetGroupIds
                                              );
                                            }
                                            setUniqueId(item.sequenceId);
                                            setVersion(item.version);
                                            handleGetCardMembers(item, shift);
                                          }}
                                        />
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          padding: "15px 10px",
                                          marginTop: "16px",
                                          borderBottom: "none",
                                        }}
                                      >
                                        <Box>
                                          <SingleDispatch
                                            className={`${
                                              hasPermission("Dispatch_WO")
                                                ? "cursor__pointer"
                                                : "cursor__notallowed"
                                            } `}
                                            onClick={() =>
                                              hasPermission("Dispatch_WO")
                                                ? handleSingleDispatchClick(
                                                    shiftsData,
                                                    item
                                                  )
                                                : () => {}
                                            }
                                          />
                                        </Box>

                                        {(item.statusCode === "SCHEDULED" ||
                                          item.statusCode === "NEW" ||
                                          item.statusCode === "INPROGRESS") && (
                                          <Box
                                            onClick={(e) => {
                                              setCardMembers(item);
                                            }}
                                          >
                                            <SchedulerCalendar
                                              selectedDate={activeDate}
                                              cardDetails={cardMembers}
                                              scheduleMoveCallBack={() => {
                                                callApiCallBack();
                                              }}
                                              type="SCHEDULE"
                                              shiftStartTime={
                                                item.shiftStartTime
                                              }
                                              shiftEndTime={item.shiftEndTime}
                                            />
                                          </Box>
                                        )}
                                      </TableCell>
                                    </TableRow>
                                  )}
                                </Draggable>
                              ))}
                            {/* // ))} */}
                            {shift.cards.length === 0 && (
                              <Draggable
                                key={0}
                                draggableId={String(0)}
                                index={0}
                              >
                                {(provided2, snapshot) => (
                                  <TableRow
                                    ref={provided2.innerRef}
                                    {...provided2.draggableProps}
                                    {...provided2.dragHandleProps}
                                    style={{
                                      ...provided2.draggableProps.style,
                                    }}
                                    sx={{
                                      backgroundColor: "#161A1D",
                                    }}
                                  >
                                    <TableCell className="tableCellCss-withoutBackground"></TableCell>
                                    <TableCell className="tableCellCss-withoutBackground">
                                      <ButtonComponent
                                        className="btn-primary btn-cancel teammember_button mb-md"
                                        labelId="NoSchedulesText"
                                        defaultLabelId="No Schedules"
                                        variantType="outlined"
                                        onClick={() => {}}
                                      />
                                    </TableCell>
                                    <TableCell className="tableCellCss-withoutBackground"></TableCell>
                                  </TableRow>
                                )}
                              </Draggable>
                            )}
                            {provided.placeholder}
                          </TableBody>
                        )}
                      </Droppable>
                    )}
                  </React.Fragment>
                ))}
              </DragDropContext>

              {/* <DragDropContext
                onDragEnd={onShiftsDragEnd}
                onDragStart={(start) => {
                  const { source } = start;
                  const shiftIndex = parseInt(
                    source.droppableId.split("-")[2],
                    10
                  );
                  const shift = shiftsData[shiftIndex];
                  const item = shift.cards[source.index];

                  setDraggingItem(item);
                }}
              >
                {shiftsData.map((shift, shiftIndex) => (
                  <React.Fragment key={shiftIndex}>
                    <TableBody>
                      <TableCell style={{ borderBottom: "none" }}></TableCell>
                      <TableCell className="tableCellCss-withoutBackground">
                        <Box className="horizontalTextLine flex__">
                          <MediumTypography
                            labelId="ShiftLabel"
                            defaultLabel="Shift"
                            className="mr-sm"
                          />
                          <MediumTypography
                            label={` ${shift.shiftName}`}
                            className="mr-sm"
                          />
                          <ButtonComponent
                            label={`${roundOffTime(
                              shift.shiftStartTime
                            ).substring(0, 5)} - ${roundOffTime(
                              shift.shiftEndTime
                            ).substring(0, 5)}`}
                            type="button"
                            className="mr-sm"
                            variantType="contained"
                            sxProps={{
                              width: "100px",
                              height: "20px",
                              borderRadius: "36px",
                              padding: "4px",
                              backgroundColor: "rgba(90, 96, 127, 1)",
                              color: "rgba(255, 255, 255, 1)",
                              minWidth: "0",
                              border: "none",
                              "&:hover": {
                                border: "none",
                                backgroundColor: "rgba(90, 96, 127, 1)",
                                color: "rgba(255, 255, 255, 1)",
                              },
                            }}
                          />
                          <ButtonComponent
                            label={
                              (shift.cardBlockCount < 10 ? "0" : "") +
                              shift.cardBlockCount.toString()
                            }
                            type="button"
                            variantType="contained"
                            sxProps={{
                              width: "30px",
                              height: "20px",
                              borderRadius: "36px",
                              border: "none",
                              padding: "4px",
                              backgroundColor: "rgba(90, 96, 127, 1)",
                              color: "rgba(255, 255, 255, 1)",
                              minWidth: "0",
                              "&:hover": {
                                border: "none",
                                backgroundColor: "rgba(90, 96, 127, 1)",
                                color: "rgba(255, 255, 255, 1)",
                              },
                            }}
                          />
                        </Box>
                      </TableCell>
                      <TableCell style={{ borderBottom: "none" }}></TableCell>
                    </TableBody>
                    <Droppable droppableId={`droppable-shifts-${shiftIndex}`}>
                      {(provided) => (
                        <TableBody
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {shift.cards.length > 0 &&
                            shift.cards.map((item, index) => (
                              <Draggable
                                key={String(item.sequenceId)}
                                draggableId={String(item.sequenceId)}
                                index={index}
                              >
                                {(provided2, snapshot) => (
                                  <TableRow
                                    ref={provided2.innerRef}
                                    {...provided2.draggableProps}
                                    {...provided2.dragHandleProps}
                                    style={{
                                      ...provided2.draggableProps.style,
                                    }}
                                    key={index}
                                    sx={{
                                      backgroundColor:
                                        item.sequenceCount &&
                                        item.sequenceCount % 2 === 0
                                          ? "#161A1D"
                                          : "#1F272C",
                                    }}
                                  >
                                    <TableCell className="tableCellCss-withoutBackground">
                                      <Box
                                        className="flex__"
                                        alignItems={"center"}
                                        sx={{
                                          textAlign: "center",
                                          color: "rgba(255, 255, 255, 1)",
                                        }}
                                      >
                                        <CheckBox
                                          value={selectedIndices.includes(
                                            item.sequenceId
                                          )}
                                          onCheckBoxClick={() =>
                                            toggleCheckBox(
                                              item.sequenceId,
                                              item,
                                              shift
                                            )
                                          }
                                        />

                                        {item.sequenceCount}
                                      </Box>
                                      <div>
                                        <p>
                                          {callback(index, shift.cards.length)}
                                        </p>
                                      </div>
                                      <Box
                                        sx={{
                                          textAlign: "center",
                                          position: "relative",
                                          right: "14px",
                                        }}
                                      >
                                        <DragIcon />
                                      </Box>
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        borderBottom: "none",
                                      }}
                                    >
                                      <CardComponent
                                        cardDataArray={item}
                                        cardType={"shift"}
                                        cardShiftStartTime={
                                          shift.shiftStartTime
                                            ? shift.shiftStartTime.substring(
                                                0,
                                                5
                                              )
                                            : ""
                                        }
                                        cardShiftName={shift.shiftName[0]}
                                        cardShiftEndTime={
                                          shift.shiftEndTime
                                            ? shift.shiftEndTime.substring(0, 5)
                                            : ""
                                        }
                                        backGroundColor={
                                          item.sequenceCount &&
                                          item.sequenceCount % 2 === 0
                                            ? "#161A1D"
                                            : "#1F272C"
                                        }
                                        boxShadow={
                                          item.sequenceCount &&
                                          item.sequenceCount % 2 === 0
                                            ? "none"
                                            : "0 4px 8px rgba(0, 0, 0, 0.1)"
                                        }
                                        userStackCallback={() => {
                                          setCardId(item.cardId);

                                          if (selectedAssetGroupIds) {
                                            setAssestGroupId(
                                              selectedAssetGroupIds
                                            );
                                          }

                                          setUniqueId(item.sequenceId);
                                          setVersion(item.version);

                                          handleGetCardMembers(item, shift);
                                        }}
                                      />
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        padding: "15px 10px",

                                        marginTop: "16px",
                                        borderBottom: "none",
                                      }}
                                    >
                                      <Box>
                                        <SingleDispatch
                                          className={`${
                                            hasPermission("Dispatch_WO")
                                              ? "cursor__pointer"
                                              : "cursor__notallowed"
                                          } `}
                                          onClick={() =>
                                            hasPermission("Dispatch_WO")
                                              ? handleSingleDispatchClick(
                                                  shiftsData,
                                                  item
                                                )
                                              : () => {}
                                          }
                                        />
                                      </Box>

                                      {(item.statusCode === "SCHEDULED" ||
                                        item.statusCode === "NEW" ||
                                        item.statusCode === "INPROGRESS") && (
                                        <Box
                                          onClick={(e) => {
                                            setCardMembers(item);
                                          }}
                                        >
                                          <SchedulerCalendar
                                            selectedDate={activeDate}
                                            cardDetails={cardMembers}
                                            scheduleMoveCallBack={() => {
                                              callApiCallBack();
                                            }}
                                            type="SCHEDULE"
                                            shiftStartTime={item.shiftStartTime}
                                            shiftEndTime={item.shiftEndTime}
                                          />
                                        </Box>
                                      )}
                                    </TableCell>
                                  </TableRow>
                                )}
                              </Draggable>
                            ))}
                          {shift.cards.length === 0 && (
                            <Draggable
                              key={0}
                              draggableId={String(0)}
                              index={0}
                            >
                              {(provided2, snapshot) => (
                                <TableRow
                                  ref={provided2.innerRef}
                                  {...provided2.draggableProps}
                                  {...provided2.dragHandleProps}
                                  style={{
                                    ...provided2.draggableProps.style,
                                  }}
                                  sx={{
                                    backgroundColor: "#161A1D",
                                  }}
                                >
                                  <TableCell className="tableCellCss-withoutBackground"></TableCell>
                                  <TableCell className="tableCellCss-withoutBackground">
                                    <ButtonComponent
                                      className="btn-primary btn-cancel teammember_button mb-md"
                                      labelId="NoSchedulesText"
                                      defaultLabelId="No Schedules"
                                      variantType="outlined"
                                      onClick={() => {}}
                                    />
                                  </TableCell>
                                  <TableCell className="tableCellCss-withoutBackground"></TableCell>
                                </TableRow>
                              )}
                            </Draggable>
                          )}
                          {provided.placeholder}
                        </TableBody>
                      )}
                    </Droppable>
                  </React.Fragment>
                ))}
              </DragDropContext> */}
            </Table>
          </TableContainer>
          <Box>
            <Grid
              className="flex__ justifyContent-FlexEnd pt-md pb-lg "
              container
              direction="row"
              alignItems="right"
            >
              <Grid item>
                <ButtonComponent
                  className={`btn-primary ${
                    selectedDispatchCards.length === 0 ||
                    hasPermission("Dispatch_WO") !== true
                      ? "btn-disabled"
                      : "btn-submit"
                  }  mr-md`}
                  variantType="contained"
                  defaultLabelId="Dispatch"
                  labelId="DispatchBtnText"
                  sxProps={{
                    cursor:
                      selectedDispatchCards.length === 0 ||
                      hasPermission("Dispatch_WO") !== true
                        ? "not-allowed"
                        : "pointer",

                    "&.Mui-disabled": {
                      color: "#ffffff",
                      background: "#333b43",
                      border: "1px solid #333b43",
                      opacity: "0.85",
                    },
                  }}
                  disabled={
                    selectedDispatchCards.length === 0 ||
                    hasPermission("Dispatch_WO") !== true
                  }
                  onClick={() => {
                    if (
                      selectedDispatchCards.length > 0 &&
                      hasPermission("Dispatch_WO") === true
                    ) {
                      readyToDispatch(selectedDispatchCards);
                    }
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid item xs={2.5}>
          <TeamMembers
            teamData={teamMembersData}
            cardDetails={cardMembers}
            totalUserCount={cardDashboardDetails?.totalUserCount}
            presentUserCount={cardDashboardDetails?.presentUserCount}
            cardShiftDetails={cardShiftDetails}
            currentDate={activeDate}
            isDragEnabled={isDragEnabled}
            reAssignCallBack={() => {
              setIsDragEnabled(false);
              setCardMembers({} as ShiftCardTypes);
              setCardShiftDetails({} as ShiftsCardType);
              callApiCallBack();
            }}
            closeCallBack={() => {
              setIsDragEnabled(false);
              setCardMembers({} as ShiftCardTypes);
              setCardShiftDetails({} as ShiftsCardType);
            }}
            allShiftData={shiftsData}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default SchedulerCardDashboard;
