import {
  Avatar,
  Box,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import MediumTypography from "../../../components/formlib/MediumTypography";
import { PMDetailsType, SavePMDetailsType } from "./types";
import dayjs, { Dayjs } from "dayjs";

import ButtonComponent from "../../../components/formlib/ButtonComponent";
import DateTimePickerComponent from "../../../components/formlib/DateTimePickerComponent";
import { Formik } from "formik";
import * as yup from "yup";
import DropdownComponent from "../../../components/formlib/DropdownComponent";
import { DropDownType } from "../../../api/GrassCutting/GrassCutting";
import { OptionType, ShiftsCardType } from "../../../utils/type";
import TextInput from "../../../components/formlib/TextInput";
import React, { useEffect, useState } from "react";
import AssetListComponent from "./ViewAssetListComponent";
import { useIntl } from "react-intl";
import RadioComponent from "../../../components/formlib/RadioComponent";
import {
  ApproverType,
  AssigneeType,
} from "../../../api/moduleCleaning/ModuleCleaningApi";
import Loader from "../../../components/shared/Loader";
import { savePreventiveMaintenanceDetailsApi } from "../../../api/preventiveMaintenance/PreventiveMaintenance";
import ErrorModal from "../../../components/formlib/modal/ErrorModal";
import ModalPopup from "../../../components/formlib/modal/ModalPopup";

import { ReactComponent as ErrorIcon } from "../../../assets/images/ErrorIcon.svg";
import { TableContentData, materialData } from "./cmTypes";
import { ReactComponent as Delete } from "../../../assets/images/trash.svg";
import SearchDropDown from "../../../components/formlib/SearchDropdown";
import WorkOrderAssignees from "../WorkOrderAssignees";
import WorkOrderActivity from "../WorkOrderActivity";
import ReassignModalPopup from "../../ReassignModalPopup";
import { hasPermission } from "../../../utils/checkPermission";
import { TeamMemberComponentProps } from "../../../components/formlib/TeamMemberComponent";
import ReassignSidebar, { CardDetailsType } from "../../ReassignSidebar";
import { Category, materialCodesData } from "../correctiveMaintenance/types";
import {
  convertHoursAndMinutesToMinutes,
  convertMinutesToHoursAndMinutes,
} from "../../../api/Constant";
import useAvailableHeight from "../../../utils/useAvailableHeight";
import { handleError } from "../../../utils/commonFunctions";

interface RowData {
  id: string;
  data: TableContentData;
}
interface SelectedSubCategoryMap {
  [key: string]: DropDownType;
}

interface PreventiveMaintenanceDetailsProps {
  apiData: PMDetailsType;
  stateCardId: number;
  reloadPmApiData: () => void;
  isVisible: boolean;
}

const validationSchema = yup.object().shape({
  additionalInfoBean: yup.object().shape({
    vendorId: yup.number().nullable(),
  }),
});

const initialCardDetails = {
  cardId: 0,
  sequenceId: 0,
  version: 0,
  members: [],
  maxStaffCount: 0,
  isAssetGrouped: false,
};

interface UserCountsType {
  total: number;
  present: number;
}

const initialShiftData: ShiftsCardType = {
  shiftId: 0,
  shiftName: "",
  cardBlockCount: 0,
  shiftStartTime: "",
  shiftEndTime: "",
  teams: [],
  memberCount: 0,
  cards: [],
};

const PreventiveMaintenanceDetails: React.FC<
  PreventiveMaintenanceDetailsProps
> = ({ apiData, stateCardId, reloadPmApiData, isVisible }) => {
  const activeDate = new Date();

  const [pmDetailsData, setPmDetailsData] = useState<PMDetailsType>(apiData);

  const [teamData, setTeamData] = useState<TeamMemberComponentProps[]>([]);
  const [cardDetails, setCardDetails] =
    useState<CardDetailsType>(initialCardDetails);
  const [shiftData, setShiftData] = useState<ShiftsCardType>(initialShiftData);
  const [isDragEnabled, setIsDragEnabled] = useState<boolean>(false);
  const [userCounts, setUserCounts] = useState<UserCountsType>({
    total: 0,
    present: 0,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [errorDesc, setErrorDesc] = useState<string>("");
  const [cardEdit, setCardEdit] = useState(false);

  const [viewAllAssets, setViewAllAssets] = useState(false);
  const [approver1, setApprover1] = useState<ApproverType | null | undefined>();
  const [approver2, setApprover2] = useState<ApproverType | null | undefined>();
  const [approver3, setApprover3] = useState<ApproverType | null | undefined>();

  const [leadAssignee, setLeadAssignee] = useState<AssigneeType>();
  const [assignees, setAssignees] = useState<AssigneeType[]>([]);

  const [toolsTableData, setToolsTableData] = useState<RowData[]>([]);

  const [selectedSubCategory, setSelectedSubCategory] =
    useState<SelectedSubCategoryMap>({});
  //category
  const [activeRowId, setActiveRowId] = useState<string | null>(null);
  const [subCategories, setSubCategories] = useState<DropDownType[]>([]);
  const [codes, setCodes] = useState<DropDownType[]>([]);
  const [selectedCodes, setSelectedCodes] = useState<SelectedSubCategoryMap>(
    {}
  );
  const [description, setDescription] = useState<DropDownType[]>([]);

  const [selectedDescriptions, setSelectedDescriptions] =
    useState<SelectedSubCategoryMap>({});
  //subCategory
  const [open, setOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  //codes
  const [openCode, setOpenCode] = useState(false);
  const [anchorElCode, setAnchorElCode] = useState<null | HTMLElement>(null);
  //description
  const [openDescription, setOpenDescription] = useState(false);
  const [anchorElDescription, setAnchorElDescription] =
    useState<null | HTMLElement>(null);

  const [reassignModal, setReassignModal] = useState(false);

  const intl = useIntl();
  const [error, setError] = useState("");
  const [editDisable, setEditDisable] = useState(false);
  const availableHeight = useAvailableHeight(420);
  const collapseAvailHeight = useAvailableHeight(320);

  useEffect(() => {
    if (pmDetailsData.status !== undefined) {
      if (
        pmDetailsData.status[pmDetailsData.status.length - 1]?.code === "CLOSED"
      ) {
        setEditDisable(true);
      } else {
        setEditDisable(false);
      }
    }
  }, [stateCardId, pmDetailsData]);

  useEffect(() => {
    // Assuming toolsList is available in your component's scope
    const transformedSubCategories =
      apiData.consumablesAndSpares?.consumableAndSpares?.category?.map(
        (subCat, index) => ({
          id: subCat?.categoryId, // or any other unique identifier
          name: subCat?.categoryName || "",
        })
      ) || [];

    const lead = apiData.assignee.find((person) => person.isPrimary);
    const assignee = apiData.assignee.filter((person) => !person.isPrimary);

    const updatedShiftData = { ...apiData.shift, cards: [] };

    const cardMembers = apiData.assignee?.map((member) => ({
      ...member,
      availability: "",
    }));

    const updatedCardDetails = {
      cardId: stateCardId,
      sequenceId: apiData.cardDetailsInfo.workOrderUniqueId,
      version: apiData.version,
      members: cardMembers,
      maxStaffCount: apiData.noRequiredStaff,
      isAssetGrouped: apiData.isAssetGrouped,
    };

    const updatedTeamData: TeamMemberComponentProps[] =
      apiData.shift?.teams?.map((team) => ({
        data: { ...team },
        shiftType: updatedShiftData.shiftName,
      }));

    const updatedUserCounts = {
      total: updatedTeamData.length,
      present: cardMembers.length,
    };
    setConsumeablesData(
      apiData.consumablesAndSpares.consumableAndSpares.category
    ); // Please check this entire Logic Pavan.

    setPmDetailsData(apiData);
    setTeamData(updatedTeamData);
    setCardDetails(updatedCardDetails);
    setShiftData(updatedShiftData);
    setUserCounts(updatedUserCounts);
    setSubCategories(transformedSubCategories);
    setLeadAssignee(lead);
    setAssignees(assignee);
    if ("approver1" in apiData) {
      setApprover1(apiData.approver1);
    }
    if ("approver2" in apiData) {
      setApprover2(apiData.approver2);
    }
    if ("approver3" in apiData) {
      setApprover3(apiData.approver3);
    }
  }, [apiData, stateCardId]);

  useEffect(() => {
    if (activeRowId) {
      const selectedCategory =
        apiData.consumablesAndSpares?.consumableAndSpares?.category?.find(
          (subCat) =>
            subCat.categoryName === selectedSubCategory[activeRowId]?.name
        );

      if (selectedCategory) {
        const alreadySelectedCodes = new Set(
          Object.values(selectedCodes).map((code) => code.name)
        );
        const alreadySelectedDescriptions = new Set(
          Object.values(selectedDescriptions).map((desc) => desc.name)
        );

        const filteredCodes =
          selectedCategory?.materialCodes &&
          selectedCategory?.materialCodes
            .filter((code: any) => !alreadySelectedCodes.has(code.code))
            .map((code: any, index: any) => ({
              id: index + 1, // or any other unique identifier
              name: code.code,
            }));

        const filteredDescriptions =
          selectedCategory?.materialCodes &&
          selectedCategory.materialCodes
            .filter((code: any) => !alreadySelectedDescriptions.has(code.name))
            .map((code: any, index: any) => ({
              id: index + 1,
              name: code.name,
            }));

        if (filteredCodes) {
          setCodes(filteredCodes);
        }

        if (filteredDescriptions) {
          setDescription(filteredDescriptions);
        }
      }
    }
  }, [
    apiData,
    selectedSubCategory,
    selectedCodes,
    selectedDescriptions,
    activeRowId,
  ]);

  const savePreventiveMaintenanceDetails = (params: SavePMDetailsType) => {
    setIsLoading(true);

    savePreventiveMaintenanceDetailsApi(stateCardId, params)
      .then(() => {
        setIsLoading(false);
        reloadPmApiData();
        setCardEdit(false);
        setError("");
      })
      .catch((err) => {
        setIsLoading(false);
        setOpenErrorModal(true);
        setError("");
        handleError(err, setErrorDesc);
      });
  };

  const handleQuantChange = (
    rowId: string,
    index: number,
    newValue: string
  ) => {
    setToolsTableData((prevToolsTableData: RowData[]) => {
      return prevToolsTableData.map((row) => {
        // Find the row by ID and update its subcategory
        if (row.id === rowId) {
          return {
            ...row,
            data: {
              ...row.data,
              quantity: newValue,
              // Update the subcategory name
              // You might also need to update other related fields here
            },
          };
        }
        // Leave other rows unchanged
        return row;
      });
    });
  };

  const handleCloseSubCategory = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const convertToOptionType = (data: DropDownType[]): OptionType[] => {
    return data.map((item) => {
      const itemData: OptionType = {
        id: item.id.toString(),
        label: item.name,
      };
      return itemData;
    });
  };

  const handleClickSubCategory = (
    event: React.MouseEvent<HTMLElement>,
    rowId: string
  ) => {
    setActiveRowId(rowId);
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleSelectSubcategory = (
    rowId: string,
    subId: number,
    subName: string
  ) => {
    setSelectedSubCategory((prevState) => ({
      ...prevState,
      [rowId]: { id: subId, name: subName },
    }));

    setToolsTableData((prevToolsTableData: RowData[]) => {
      return prevToolsTableData.map((row) => {
        // Find the row by ID and update its subcategory
        if (row.id === rowId) {
          return {
            ...row,
            data: {
              ...row.data,
              subCategory: subName, // Update the subcategory name
              // You might also need to update other related fields here
            },
          };
        }
        // Leave other rows unchanged
        return row;
      });
    });
  };
  const handleClickCodes = (
    event: React.MouseEvent<HTMLElement>,
    rowId: string
  ) => {
    setActiveRowId(rowId);
    setAnchorElCode(event.currentTarget);
    setOpenCode(true);
  };

  const handleCloseCodes = () => {
    setOpenCode(false);
    setAnchorElCode(null);
  };

  const handleSelectCodes = (rowId: string, codeId: number, code: string) => {
    setSelectedCodes((prevState) => ({
      ...prevState,
      [rowId]: { id: codeId, name: code },
    }));

    // Find the selected code details and update description and UOM

    const selectedCode =
      apiData?.consumablesAndSpares?.consumableAndSpares?.category
        .flatMap((cat) => cat.materialCodes)
        .find((c) => c?.code === code);

    if (selectedCode) {
      setSelectedDescriptions((prevState) => ({
        ...prevState,
        [rowId]: {
          id: selectedCode.id,
          name: selectedCode.name,
        },
      }));

      setToolsTableData((prevToolsTableData: RowData[]) => {
        return prevToolsTableData.map((row) => {
          // Find the row by ID and update its subcategory
          if (row.id === rowId) {
            return {
              ...row,
              data: {
                ...row.data,
                code: selectedCode.code,
                description: selectedCode.name,
                uom: selectedCode.uom,
                id: selectedCode.id, // Update the subcategory name
                // You might also need to update other related fields here
              },
            };
          }
          // Leave other rows unchanged
          return row;
        });
      });
    }
  };
  //description
  const handleClickDescription = (
    event: React.MouseEvent<HTMLElement>,
    rowId: string
  ) => {
    setActiveRowId(rowId);
    setAnchorElDescription(event.currentTarget);
    setOpenDescription(true);
  };
  const handleSelectDescription = (
    rowId: string,
    desId: number,
    desc: string
  ) => {
    setSelectedDescriptions((prevState) => ({
      ...prevState,
      [rowId]: { id: desId, name: desc },
    }));

    // Find the selected code details and update description and UOM
    const selecteddescriptions =
      apiData?.consumablesAndSpares?.consumableAndSpares?.category
        .flatMap((cat) => cat.materialCodes)
        .find((c) => c?.name === desc);

    if (selecteddescriptions) {
      setSelectedCodes((prevState) => ({
        ...prevState,
        [rowId]: {
          id: selecteddescriptions.id,
          name: selecteddescriptions.name,
        },
      }));

      setToolsTableData((prevToolsTableData: RowData[]) => {
        return prevToolsTableData.map((row) => {
          // Find the row by ID and update its subcategory
          if (row.id === rowId) {
            return {
              ...row,
              data: {
                ...row.data,
                code: selecteddescriptions.code,
                description: desc,
                uom: selecteddescriptions.uom,
                id: selecteddescriptions.id, // Update the subcategory name
                // You might also need to update other related fields here
              },
            };
          }
          // Leave other rows unchanged
          return row;
        });
      });
    }
  };

  const handleCloseDescription = () => {
    setOpenDescription(false);
    setAnchorElDescription(null);
  };
  const addNewRow = () => {
    // Correctly calculate and store the new row ID beforehand

    const newRowId = `row-${Date.now()}`;

    // Define the new row using the newRowId
    const newRow: RowData = {
      id: newRowId, // Use the newRowId here
      data: {
        code: "",
        description: "",
        subCategory: "",
        uom: "",
        id: 0,
        quantity: "",
      },
    };

    // Update the toolsTableData with the new row
    const updatedToolsTableData: RowData[] = [...toolsTableData, newRow];
    setToolsTableData(updatedToolsTableData);

    // Update the selectedSubCategory state to include the new row with a default or empty value
    setSelectedSubCategory((prevState) => ({
      ...prevState,
      [newRowId]: { id: 0, name: "" }, // Assuming an id of 0 and an empty name represents a "no selection" state
    }));

    setSelectedSubCategory((prevState) => ({
      ...prevState,
      [newRowId]: { id: 0, name: "" }, // Assuming an id of 0 and an empty name represents a "no selection" state
    }));
    setSelectedCodes((prevState) => ({
      ...prevState,
      [newRowId]: { id: 0, name: "" },
    }));

    setCodes([]);
    setDescription([]);
  };

  const handleDelete = (rowId: string) => {
    const updatedRows = toolsTableData.filter((row) => row.id !== rowId);
    setToolsTableData(updatedRows);
  };

  const materialRequestArray: materialData[] = toolsTableData.map((row) => ({
    materialId: row.data.id, // Use the id from the data object
    quantity: Number(row.data.quantity) || 0, // Convert the quantity to a number, defaulting to 0 if conversion fails
  }));

  const materialRequest: materialCodesData = {
    materials: materialRequestArray,
  };

  const onAssigneeChange = () => {
    if (hasPermission("Assign_Team_Member")) {
      if (pmDetailsData.isAssetGrouped) {
        setReassignModal(true);
      } else {
        setIsDragEnabled(true);
      }
    }
  };

  const setConsumeablesData = (data: Category[]) => {
    let tableData: RowData[] = [];
    data.forEach((category) => {
      category.materialCodes?.forEach((materialCode) => {
        if (materialCode.status) {
          tableData.push({
            id: `row-${Date.now()}`,
            data: {
              id: materialCode.id,
              subCategory: category?.categoryName || "",
              code: materialCode.code,
              description: materialCode.name,
              quantity: materialCode.quantity.toString(),
              uom: materialCode.uom,
            },
          });
        }
      });
    });
    setToolsTableData(tableData);
  };

  return (
    <Grid container>
      {isLoading && <Loader />}
      {openErrorModal && (
        <ErrorModal
          descriptionText={errorDesc}
          open={openErrorModal}
          handleClose={() => setOpenErrorModal(false)}
          onPositiveClick={() => {
            setOpenErrorModal(false);
          }}
        />
      )}
      {openModal && (
        <ModalPopup
          descriptionText={"SavedSuccessfully"}
          open={openModal}
          handleClose={() => setOpenModal(false)}
          onPositiveClick={() => setOpenModal(false)}
          hideButton={true}
          positiveButtonLabelId="OKButtonText"
        />
      )}
      {reassignModal && (
        <ReassignModalPopup
          open={reassignModal}
          handleClose={() => setReassignModal(false)}
          cardId={stateCardId}
          assetGroupId={[pmDetailsData.workOrderPmInfo.assetGroup.assetGroupId]}
          woUniqueId={pmDetailsData.cardDetailsInfo.workOrderUniqueId}
          version={pmDetailsData.version}
          reAssignCallBack={() => {
            reloadPmApiData();
          }}
          cardType="workorder"
        />
      )}
      <Grid
        item
        xs={9.5}
        sx={{
          borderRight: "1px solid #333b43",
          overflowY: "auto",
          height: isVisible ? availableHeight : collapseAvailHeight,
        }}
      >
        <Box className="pm-details-form">
          <Formik
            initialValues={pmDetailsData}
            enableReinitialize
            validateOnChange
            validationSchema={validationSchema}
            onSubmit={(values) => {
              const paramsObj: SavePMDetailsType = {
                additionalInfoBean: {
                  grassImpact: values.additionalInfoBean.grassImpact,
                  vendorId: values.additionalInfoBean.vendorId,
                },
                workOrderPmInfo: {
                  remarks: values.workOrderPmInfo.remarks,
                  actualStartDateTime:
                    values.workOrderPmInfo.actualStartDateTime,
                  actualEndDateTime: values.workOrderPmInfo.actualEndDateTime,
                },
                materialRequest: materialRequest,
                pmInfoBean: {
                  relatedCmWo: values.pmInfoBean.relatedCmWo,
                  woDescription: values.pmInfoBean.woDescription,
                  contractLineInfo: values.pmInfoBean.contractLineInfo,
                  estimatedEffort: values.pmInfoBean.estimatedEffort,
                },
              };
              if (!error) {
                savePreventiveMaintenanceDetails(paramsObj);
              }
            }}
          >
            {({ values, setFieldValue, handleSubmit, errors, touched }) => {
              return (
                <Box sx={{ pr: "24px" }}>
                  <Box sx={{ padding: "24px 0px 0px 0px" }}>
                    <Box className="flex__" sx={{ marginBottom: "20px" }}>
                      <Box>
                        <MediumTypography
                          labelId="WorkOrderInfoText"
                          defaultLabel="Work order Info"
                          fontSize="20px"
                          fontWeight={500}
                          textColor="#FFFFFF"
                        />
                      </Box>
                      {!editDisable && (
                        <Box sx={{ marginLeft: "auto" }}>
                          <Grid
                            className="flex__ justifyContent-FlexEnd"
                            container
                            direction="row"
                            alignItems="right"
                          >
                            {cardEdit && (
                              <Grid item>
                                <ButtonComponent
                                  sxProps={{
                                    color: "#B6C2CF !important",
                                    mr: 0,
                                  }}
                                  className="btn-primary btn-cancel"
                                  variantType="outlined"
                                  defaultLabelId="Cancel"
                                  labelId="btn.cancel"
                                  onClick={() => {
                                    setCardEdit(false);
                                  }}
                                />
                              </Grid>
                            )}
                            {cardEdit && (
                              <Grid item>
                                <ButtonComponent
                                  className="btn-primary btn-submit "
                                  variantType="contained"
                                  defaultLabelId="Save"
                                  labelId="btn.save"
                                  onClick={handleSubmit}
                                />
                              </Grid>
                            )}
                            {!cardEdit && (
                              <Grid item>
                                <ButtonComponent
                                  className="btn-primary btn-submit "
                                  variantType="contained"
                                  defaultLabelId="Edit"
                                  labelId="btn.edit"
                                  onClick={() => {
                                    setCardEdit(true);
                                  }}
                                />
                              </Grid>
                            )}
                          </Grid>
                        </Box>
                      )}
                    </Box>
                    <Grid
                      container
                      direction={"row"}
                      className="justifyContentSpaceBtw"
                      alignItems={"center"}
                      rowSpacing={"16px"}
                      columnSpacing={"40px"}
                      sx={{ marginBottom: "20px", marginTop: "10px" }}
                    >
                      <Grid
                        item
                        sx={{
                          width: "100%",
                        }}
                      >
                        <MediumTypography
                          className="input-label"
                          labelId="AssetCategoryText"
                          defaultLabel="Asset Category"
                          sxProps={{ marginBottom: "10px" }}
                          textColor={"rgba(159, 173, 188, 1)"}
                        />
                        <Box
                          sx={{
                            padding: "12px 6px",
                            backgroundColor: "#282D33",
                            borderRadius: "4px",
                          }}
                        >
                          <Box
                            sx={{
                              borderLeft: "2px solid #9FBBEB",
                              padding: "0px 12px",
                            }}
                          >
                            <MediumTypography
                              label={
                                pmDetailsData.workOrderPmInfo?.assetCategory
                                  ?.assetCategoryName
                              }
                            />
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      direction={"row"}
                      className="justifyContentSpaceBtw"
                      alignItems={"center"}
                      rowSpacing={"16px"}
                      columnSpacing={"40px"}
                      sx={{ marginBottom: "20px", marginTop: "10px", mr: 0 }}
                    >
                      <Grid
                        item
                        sx={{
                          width: "100%",
                        }}
                      >
                        <Box
                          className="flex__"
                          sx={{ justifyContent: "space-between" }}
                        >
                          <MediumTypography
                            labelId="ListOfAssetsText"
                            defaultLabel="List of Assets"
                            sxProps={{ marginBottom: "10px" }}
                            textColor={"rgba(159, 173, 188, 1)"}
                          />
                          {!viewAllAssets ? (
                            <MediumTypography
                              labelId="viewallText"
                              defaultLabel="View all"
                              textColor="#25BBFA"
                              sxProps={{
                                textDecoration: "underline",
                                cursor: "pointer",
                              }}
                              onClick={() => setViewAllAssets(true)}
                            />
                          ) : (
                            <MediumTypography
                              labelId="closeText"
                              defaultLabel="Close"
                              textColor="#25BBFA"
                              sxProps={{
                                textDecoration: "underline",
                                cursor: "pointer",
                              }}
                              onClick={() => setViewAllAssets(false)}
                            />
                          )}
                        </Box>
                      </Grid>
                      {!viewAllAssets ? (
                        <>
                          {pmDetailsData.workOrderPmInfo?.assets
                            ?.slice(0, 7)
                            .map((item, index) => (
                              <Grid item key={item.id}>
                                <Box
                                  sx={{
                                    padding: "12px 6px",
                                    backgroundColor: "#282D33",
                                    borderRadius: "4px",
                                    width: "150px",
                                  }}
                                >
                                  <Tooltip
                                    title={
                                      item.name && item.name.length > 10
                                        ? item.name
                                        : ""
                                    }
                                    arrow={true}
                                    followCursor
                                  >
                                    <Box
                                      sx={{
                                        borderLeft: "2px solid #9FBBEB",
                                        padding: "0px 12px",
                                      }}
                                    >
                                      <MediumTypography
                                        label={item.name}
                                        className="ellipsis"
                                        sxProps={{
                                          width:
                                            item.name.length > 10
                                              ? "120px"
                                              : "auto",
                                        }}
                                      />
                                    </Box>
                                  </Tooltip>
                                </Box>
                              </Grid>
                            ))}
                        </>
                      ) : (
                        <Box
                          sx={{
                            backgroundColor: "#282D33",
                            marginLeft: "40px",
                            marginRight: "0",
                            width: "100%",
                          }}
                        >
                          <AssetListComponent
                            assetsData={pmDetailsData.workOrderPmInfo?.assets}
                          />
                        </Box>
                      )}
                    </Grid>
                    <Grid
                      container
                      direction={"row"}
                      className="justifyContentSpaceBtw"
                      alignItems={"center"}
                      rowSpacing={"16px"}
                      columnSpacing={"40px"}
                      sx={{ marginBottom: "20px", marginTop: "10px" }}
                    >
                      <Grid item xs={3}>
                        <MediumTypography
                          className="input-label"
                          labelId="ScheduledStartTimeInput"
                          defaultLabel="Scheduled Start Time"
                          sxProps={{ marginBottom: "10px" }}
                        />
                        <DateTimePickerComponent
                          value={
                            values.workOrderPmInfo.scheduledStartDateTime !==
                            null
                              ? dayjs(
                                  values.workOrderPmInfo.scheduledStartDateTime?.toString()
                                )
                              : null
                          }
                          labelid="ScheduledStartTimeInput"
                          defaultlabelid="Scheduled Start Time"
                          handlechange={(date: Dayjs) => {
                            const formattedText = date.format(
                              "YYYY-MM-DDTHH:mm:ss"
                            );
                            setFieldValue(
                              "workOrderPmInfo.scheduledStartDateTime",
                              formattedText
                            );
                          }}
                          disabledDate={true}
                          inputFormat="DD MMM YYYY HH:mm:ss"
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <MediumTypography
                          className="input-label"
                          labelId="ScheduledEndTimeTextInput"
                          defaultLabel="Scheduled End Time"
                          sxProps={{ marginBottom: "10px" }}
                        />
                        <DateTimePickerComponent
                          value={
                            values.workOrderPmInfo.scheduledEndDateTime !== null
                              ? dayjs(
                                  values.workOrderPmInfo.scheduledEndDateTime?.toString()
                                )
                              : null
                          }
                          labelid="ScheduledEndTimeTextInput"
                          defaultlabelid="Scheduled End Time"
                          handlechange={(date: Dayjs) => {
                            const formattedText = date.format(
                              "YYYY-MM-DDTHH:mm:ss"
                            );
                            setFieldValue(
                              "workOrderPmInfo.scheduledEndDateTime",
                              formattedText
                            );
                          }}
                          disabledDate={true}
                          inputFormat="DD MMM YYYY HH:mm:ss"
                        />
                      </Grid>

                      <Grid item xs={3}>
                        <MediumTypography
                          className="input-label"
                          labelId="ActualStartTimeTextInput"
                          defaultLabel="Actual Start Time"
                          sxProps={{ marginBottom: "10px" }}
                        />
                        <DateTimePickerComponent
                          value={
                            values.workOrderPmInfo.actualStartDateTime !== null
                              ? dayjs(
                                  values.workOrderPmInfo.actualStartDateTime?.toString()
                                )
                              : null
                          }
                          labelid="ActualStartTimeTextInput"
                          defaultlabelid="Actual Start Time"
                          handlechange={(date: Dayjs) => {
                            const formattedText = date.format(
                              "YYYY-MM-DDTHH:mm:ss"
                            );
                            setFieldValue(
                              "workOrderPmInfo.actualStartDateTime",
                              formattedText
                            );
                          }}
                          disabledDate={!cardEdit}
                          inputFormat="DD MMM YYYY HH:mm:ss"
                        />
                      </Grid>

                      <Grid item xs={3}>
                        <MediumTypography
                          className="input-label"
                          labelId="ActualEndTimeTextInput"
                          defaultLabel="Actual End Time"
                          sxProps={{ marginBottom: "10px" }}
                        />
                        <DateTimePickerComponent
                          value={
                            values.workOrderPmInfo.actualEndDateTime !== null
                              ? dayjs(values.workOrderPmInfo.actualEndDateTime)
                              : null
                          }
                          labelid="ActualEndTimeTextInput"
                          defaultlabelid="Actual End Time"
                          handlechange={(date: Dayjs) => {
                            if (
                              values.workOrderPmInfo.actualStartDateTime ===
                              date.format("YYYY-MM-DDTHH:mm:ss")
                            ) {
                              setError(
                                intl.formatMessage({
                                  id: "Error.EndTimeGreaterThanStartTime",
                                  defaultMessage:
                                    "End time should be greater than start time",
                                })
                              );
                            } else {
                              setError("");
                              setFieldValue(
                                "workOrderPmInfo.actualEndDateTime",
                                date.format("YYYY-MM-DDTHH:mm:ss")
                              );
                            }
                          }}
                          disabledDate={!cardEdit}
                          inputFormat="DD MMM YYYY  HH:mm:ss"
                          width="250px"
                          textWidth="250px"
                          minDateTime={
                            values.workOrderPmInfo.actualStartDateTime !== null
                              ? dayjs(
                                  values.workOrderPmInfo.actualStartDateTime
                                )
                              : undefined
                          }
                        />
                        {error && (
                          <Box
                            className="loginError"
                            sx={{ position: "relative" }}
                          >
                            <Box>
                              <ErrorIcon />
                            </Box>
                            <MediumTypography
                              label={error}
                              fontSize="12px"
                              textColor="#AE2E24"
                              fontWeight={400}
                            />
                          </Box>
                        )}
                      </Grid>

                      <Grid item xs={12}>
                        <MediumTypography
                          className="input-label"
                          labelId="Revoke.RemarksText"
                          defaultLabel="Remarks"
                          sxProps={{ marginBottom: "10px" }}
                        />
                        <TextField
                          variant="outlined"
                          placeholder={intl.formatMessage({
                            id: "pm.textAreaPlaceholder",
                            defaultMessage:
                              "Enter long form text here maximum 1000 characters",
                          })}
                          sx={{
                            backgroundColor: "rgba(34, 39, 43, 1)",
                            borderRadius: "4px",
                            border: "1px solid rgba(166, 197, 226, 0.16)",
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderColor: "rgba(166, 197, 226, 0.16)",
                              },
                              "&:hover fieldset": {
                                borderColor: "rgba(166, 197, 226, 0.16)",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "rgba(166, 197, 226, 0.16)",
                              },
                              "&.MuiInputBase-root": {
                                color: cardEdit
                                  ? "rgba(255, 255, 255, 1)"
                                  : "rgba(140, 155, 171, 1)",
                                fontFamily: "Inter",
                                fontWeight: 400,
                                fontSize: "14px",
                              },
                            },
                            "& .MuiInputLabel-root": {
                              color: cardEdit
                                ? "rgba(255, 255, 255, 1)"
                                : "rgba(140, 155, 171, 1)",
                              fontFamily: "Inter",
                              fontWeight: 400,
                              fontSize: "14px",
                            },
                          }}
                          multiline
                          minRows={2}
                          fullWidth
                          value={values.workOrderPmInfo.remarks}
                          onChange={(event) => {
                            setFieldValue(
                              "workOrderPmInfo.remarks",
                              event.target.value.trimStart()
                            );
                          }}
                          inputProps={{ readOnly: !cardEdit }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Grid container className="mt-xs">
                    <Grid item xs={12} lg={12} md={12} xl={12}>
                      <Box>
                        <Divider
                          variant="fullWidth"
                          sx={{
                            borderTop: "1px solid #333b43",
                          }}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  <Box sx={{ padding: "24px 0px 24px 0px" }}>
                    <Box className="flex__" sx={{}}>
                      <Box>
                        <MediumTypography
                          labelId="pm.pmHeaderText"
                          defaultLabel="Preventive Maintenance Info"
                          fontSize="20px"
                          fontWeight={500}
                          textColor="#FFFFFF"
                        />
                        <Grid
                          container
                          direction={"row"}
                          className="justifyContentSpaceBtw"
                          alignItems={"center"}
                          rowSpacing={"16px"}
                          columnSpacing={"40px"}
                          sx={{ marginBottom: "20px", marginTop: "10px" }}
                        >
                          <Grid item xs={12}>
                            <MediumTypography
                              className="input-label"
                              labelId="pm.woDescriptionLabel"
                              defaultLabel="Work Order Description"
                              sxProps={{ marginBottom: "10px" }}
                            />
                            <TextField
                              variant="outlined"
                              placeholder={intl.formatMessage({
                                id: "pm.textAreaPlaceholder",
                                defaultMessage:
                                  "Enter long form text here maximum 1000 characters",
                              })}
                              sx={{
                                backgroundColor: "rgba(34, 39, 43, 1)",
                                borderRadius: "4px",
                                border: "1px solid rgba(166, 197, 226, 0.16)",
                                "& .MuiOutlinedInput-root": {
                                  "& fieldset": {
                                    borderColor: "rgba(166, 197, 226, 0.16)",
                                  },
                                  "&:hover fieldset": {
                                    borderColor: "rgba(166, 197, 226, 0.16)",
                                  },
                                  "&.Mui-focused fieldset": {
                                    borderColor: "rgba(166, 197, 226, 0.16)",
                                  },
                                  "&.MuiInputBase-root": {
                                    color: cardEdit
                                      ? "rgba(255, 255, 255, 1)"
                                      : "rgba(140, 155, 171, 1)",
                                    fontFamily: "Inter",
                                    fontWeight: 400,
                                    fontSize: "14px",
                                  },
                                },
                                "& .MuiInputLabel-root": {
                                  color: cardEdit
                                    ? "rgba(255, 255, 255, 1)"
                                    : "rgba(140, 155, 171, 1)",
                                  fontFamily: "Inter",
                                  fontWeight: 400,
                                  fontSize: "14px",
                                },
                              }}
                              multiline
                              minRows={2}
                              fullWidth
                              value={values.pmInfoBean.woDescription}
                              onChange={(event) => {
                                setFieldValue(
                                  "pmInfoBean.woDescription",
                                  event.target.value.trimStart()
                                );
                              }}
                              inputProps={{ readOnly: !cardEdit }}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <MediumTypography
                              className="input-label"
                              labelId="pm.relatedWoLabel"
                              defaultLabel="Related PM WO"
                              sxProps={{ marginBottom: "10px" }}
                            />
                            <TextInput
                              type="number"
                              labelId="pm.relatedWoLabel"
                              className="textInputWidth"
                              defaultLabelId="Related PM WO"
                              borderColor="1px solid rgba(166, 197, 226, 0.16)"
                              Value={
                                values.pmInfoBean.relatedPmWo !== null &&
                                values.pmInfoBean.relatedPmWo !== 0
                                  ? values.pmInfoBean.relatedPmWo.toString()
                                  : ""
                              }
                              handlechange={(value: string) => {
                                setFieldValue("pmInfoBean.relatedPmWo", value);
                              }}
                              variant="outlined"
                              backGroundColor="#22272B"
                              fieldheight="40px"
                              inputProps={{
                                readOnly: true,
                              }}
                              textColor={"rgba(140, 155, 171, 1)"}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <MediumTypography
                              className="input-label"
                              labelId="pm.relatedCmWoLabel"
                              defaultLabel="Related CM WO"
                              sxProps={{ marginBottom: "10px" }}
                            />
                            <TextInput
                              type="number"
                              labelId="pm.relatedCmWoLabel"
                              className="textInputWidth"
                              defaultLabelId="Related CM WO"
                              borderColor="1px solid rgba(166, 197, 226, 0.16)"
                              Value={
                                values.pmInfoBean.relatedCmWo !== null &&
                                values.pmInfoBean.relatedCmWo !== 0
                                  ? values.pmInfoBean.relatedCmWo.toString()
                                  : ""
                              }
                              handlechange={(value: string) => {
                                setFieldValue("pmInfoBean.relatedCmWo", value);
                              }}
                              variant="outlined"
                              backGroundColor="#22272B"
                              fieldheight="40px"
                              inputProps={{
                                readOnly: !cardEdit,
                              }}
                              textColor={
                                cardEdit
                                  ? "rgba(255, 255, 255, 1)"
                                  : "rgba(140, 155, 171, 1)"
                              }
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <MediumTypography
                              className="input-label"
                              labelId="ContractLineItemLabelText"
                              defaultLabel="Contract Line Item"
                              sxProps={{ marginBottom: "10px" }}
                            />
                            <TextInput
                              labelId="ContractLineItemLabelText"
                              className="textInputWidth"
                              defaultLabelId="Contract Line Item"
                              borderColor="1px solid rgba(166, 197, 226, 0.16)"
                              Value={values.pmInfoBean.contractLineInfo?.toString()}
                              handlechange={(value: string) => {
                                setFieldValue(
                                  "pmInfoBean.contractLineInfo",
                                  value
                                );
                              }}
                              variant="outlined"
                              backGroundColor="#22272B"
                              fieldheight="40px"
                              inputProps={{
                                readOnly: !cardEdit,
                              }}
                              textColor={
                                cardEdit
                                  ? "rgba(255, 255, 255, 1)"
                                  : "rgba(140, 155, 171, 1)"
                              }
                            />
                          </Grid>

                          <Grid item>
                            <MediumTypography
                              labelId="CM.estimatedEffort"
                              defaultLabel="Estimated Effort"
                              sxProps={{ marginBottom: "10px" }}
                              textColor={"rgba(159, 173, 188, 1)"}
                            />
                            <Box
                              className="flex__"
                              sx={{
                                position: "relative",
                                alignItems: "center",
                              }}
                            >
                              <TextInput
                                labelId="CM.estimatedEffort"
                                className="textInputWidth"
                                defaultLabelId="Estimated Effort"
                                borderColor="1px solid rgba(166, 197, 226, 0.16)"
                                Value={convertMinutesToHoursAndMinutes(
                                  values?.pmInfoBean.estimatedEffort
                                )}
                                inputProps={{
                                  readOnly: !cardEdit,
                                }}
                                handlechange={(value: string) => {
                                  const minutes =
                                    convertHoursAndMinutesToMinutes(value);
                                  setFieldValue(
                                    "pmInfoBean.estimatedEffort",
                                    minutes
                                  );
                                }}
                                variant="outlined"
                                backGroundColor="#22272B"
                                fieldheight="40px"
                                textColor={
                                  cardEdit
                                    ? "rgba(255, 255, 255, 1)"
                                    : "rgba(140, 155, 171, 1)"
                                }
                              />
                              <Box
                                sx={{
                                  padding: "4px 8px 4px 8px",
                                  backgroundColor: "#191E22",
                                  border: "1px solid #374048",
                                  height: "24px",
                                  borderRadius: "4px",
                                  right: "8px",
                                  position: "absolute",
                                }}
                              >
                                <MediumTypography
                                  label="( hh:mm )"
                                  sxProps={{
                                    lineHeight: "normal",
                                    fontSize: "12px",
                                  }}
                                />
                              </Box>
                            </Box>
                          </Grid>

                          <Grid item>
                            <MediumTypography
                              labelId="CM.ActualEffort"
                              defaultLabel="Actual Effort"
                              sxProps={{ marginBottom: "10px" }}
                              textColor={"rgba(159, 173, 188, 1)"}
                            />
                            <Box
                              className="flex__"
                              sx={{
                                position: "relative",
                                alignItems: "center",
                              }}
                            >
                              <TextInput
                                labelId="CM.ActualEffort"
                                className="textInputWidth"
                                defaultLabelId="Actual Effort"
                                borderColor="1px solid rgba(166, 197, 226, 0.16)"
                                Value={
                                  values.pmInfoBean.actalEffort !== null
                                    ? convertMinutesToHoursAndMinutes(
                                        values.pmInfoBean.actalEffort
                                      )
                                    : ""
                                }
                                handlechange={(value: string) => {
                                  setFieldValue(
                                    "pmInfoBean.actalEffort",
                                    value
                                  );
                                }}
                                inputProps={{
                                  readOnly: true,
                                }}
                                variant="outlined"
                                backGroundColor="#22272B"
                                fieldheight="40px"
                                textColor={"rgba(140, 155, 171, 1)"}
                              />
                              <Box
                                sx={{
                                  padding: "4px 8px 4px 8px",
                                  backgroundColor: "#191E22",
                                  border: "1px solid #374048",
                                  height: "24px",
                                  borderRadius: "4px",
                                  right: "8px",
                                  position: "absolute",
                                }}
                              >
                                <MediumTypography
                                  label="( hh:mm )"
                                  sxProps={{
                                    lineHeight: "normal",
                                    fontSize: "12px",
                                  }}
                                />
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </Box>
                  <Grid container className="mt-xs">
                    <Grid item xs={12} lg={12} md={12} xl={12}>
                      <Box>
                        <Divider
                          variant="fullWidth"
                          sx={{
                            borderTop: "1px solid #333b43",
                          }}
                        />
                      </Box>
                    </Grid>
                  </Grid>

                  <Grid item sx={{ marginBottom: "20px", marginTop: "16px" }}>
                    <Box
                      className="flex__"
                      sx={{
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <MediumTypography
                        labelId="consumablesText"
                        defaultLabel="Consumables & Spares"
                        sxProps={{
                          fontSize: "16px",
                        }}
                      />
                      {cardEdit && (
                        <ButtonComponent
                          className="btn-primary btn-submit "
                          variantType="contained"
                          defaultLabelId={"Add Row"}
                          labelId={"btn.AddRow"}
                          onClick={addNewRow}
                        />
                      )}
                    </Box>
                  </Grid>
                  <TableContainer
                    sx={{ maxHeight: "400px", marginBottom: "30px" }}
                    className="mr-md tableStyles"
                  >
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            sx={{
                              verticalAlign: "top",
                              border: "1px solid #323337",
                              backgroundColor: "#22272B",
                              width: "50px",
                            }}
                          >
                            <Box>
                              <MediumTypography
                                sxProps={{
                                  fontWeight: 700,
                                  color: "#FFFF",
                                  whiteSpace: "nowrap",

                                  display: "flex",
                                  alignItems: "center",
                                }}
                                labelId={"table.srNo"}
                                defaultLabel="Sr. No."
                              />
                            </Box>
                          </TableCell>
                          <TableCell
                            sx={{
                              verticalAlign: "top",
                              border: "1px solid #323337",
                              backgroundColor: "#22272B",
                              width: "100px",
                            }}
                          >
                            <Box>
                              <MediumTypography
                                sxProps={{
                                  fontWeight: 700,
                                  color: "#FFFF",
                                  whiteSpace: "nowrap",

                                  display: "flex",
                                  alignItems: "center",
                                }}
                                labelId="table.Category"
                                defaultLabel="Category"
                              />
                            </Box>
                          </TableCell>
                          <TableCell
                            sx={{
                              verticalAlign: "top",
                              border: "1px solid #323337",
                              backgroundColor: "#22272B",
                              width: "100px",
                            }}
                          >
                            <Box>
                              <MediumTypography
                                sxProps={{
                                  fontWeight: 700,
                                  color: "#FFFF",
                                  whiteSpace: "nowrap",

                                  display: "flex",
                                  alignItems: "center",
                                }}
                                labelId={"table.materialCode"}
                                defaultLabel="Material Code"
                              />
                            </Box>
                          </TableCell>
                          <TableCell
                            sx={{
                              verticalAlign: "top",
                              border: "1px solid #323337",
                              backgroundColor: "#22272B",
                              width: "250px",
                            }}
                          >
                            <Box>
                              <MediumTypography
                                sxProps={{
                                  fontWeight: 700,
                                  color: "#FFFF",
                                  whiteSpace: "nowrap",

                                  display: "flex",
                                  alignItems: "center",
                                }}
                                labelId={"table.description"}
                                defaultLabel="Description"
                              />
                            </Box>
                          </TableCell>
                          <TableCell
                            sx={{
                              verticalAlign: "top",
                              border: "1px solid #323337",
                              backgroundColor: "#22272B",
                              width: "100px",
                            }}
                          >
                            <Box>
                              <MediumTypography
                                sxProps={{
                                  fontWeight: 700,
                                  color: "#FFFF",
                                  whiteSpace: "nowrap",

                                  display: "flex",
                                  alignItems: "center",
                                }}
                                labelId={"table.uom"}
                                defaultLabel="UoM"
                              />
                            </Box>
                          </TableCell>
                          <TableCell
                            sx={{
                              verticalAlign: "top",
                              border: "1px solid #323337",
                              backgroundColor: "#22272B",
                              width: "100px",
                            }}
                          >
                            <Box>
                              <MediumTypography
                                sxProps={{
                                  fontWeight: 700,
                                  color: "#FFFF",
                                  whiteSpace: "nowrap",

                                  display: "flex",
                                  alignItems: "center",
                                }}
                                labelId={"quantConsumed.text"}
                                defaultLabel="Quant Consumed"
                              />
                            </Box>
                          </TableCell>
                          <TableCell
                            sx={{
                              verticalAlign: "top",
                              border: "1px solid #323337",
                              backgroundColor: "#22272B",
                              width: "50px",
                            }}
                          >
                            <Box>
                              <MediumTypography
                                sxProps={{
                                  fontWeight: 700,
                                  color: "#FFFF",
                                  whiteSpace: "nowrap",

                                  display: "flex",
                                  alignItems: "center",
                                }}
                                labelId={""}
                                defaultLabel=""
                              />
                            </Box>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {toolsTableData &&
                          toolsTableData.map((row, index) => {
                            return (
                              <TableRow key={row.id}>
                                <TableCell
                                  sx={{
                                    verticalAlign: "top",
                                    border: "1px solid #323337",
                                    backgroundColor: "#22272B",
                                    width: "50px",
                                  }}
                                >
                                  <MediumTypography
                                    label={(index + 1).toString()}
                                  />
                                </TableCell>
                                <TableCell
                                  sx={{
                                    verticalAlign: "top",
                                    border: "1px solid #323337",
                                    backgroundColor: "#22272B",
                                    width: "100px",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      height: "32px",
                                      width: "100%",
                                    }}
                                    onClick={(
                                      event: React.MouseEvent<HTMLElement>
                                    ) => handleClickSubCategory(event, row.id)}
                                  >
                                    <MediumTypography
                                      label={row.data.subCategory}
                                    />
                                  </Box>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    verticalAlign: "top",
                                    border: "1px solid #323337",
                                    backgroundColor: "#22272B",
                                    width: "50px",
                                  }}
                                  onClick={(
                                    event: React.MouseEvent<HTMLElement>
                                  ) => handleClickCodes(event, row.id)}
                                >
                                  <MediumTypography
                                    label={
                                      row.data.code //Please check the logic pavan of prepopulating table values.
                                    }
                                  />
                                </TableCell>
                                <TableCell
                                  sx={{
                                    verticalAlign: "top",
                                    border: "1px solid #323337",
                                    backgroundColor: "#22272B",
                                    width: "50px",
                                  }}
                                  onClick={(
                                    event: React.MouseEvent<HTMLElement>
                                  ) => handleClickDescription(event, row.id)}
                                >
                                  <MediumTypography
                                    label={
                                      row.data.description //Please check the logic pavan of prepopulating table values.
                                    }
                                  />
                                </TableCell>
                                <TableCell
                                  sx={{
                                    verticalAlign: "top",
                                    border: "1px solid #323337",
                                    backgroundColor: "#22272B",
                                    width: "50px",
                                  }}
                                >
                                  <MediumTypography
                                    label={
                                      row.data.uom //Please check the logic pavan of prepopulating table values.
                                    }
                                  />
                                </TableCell>
                                <TableCell
                                  sx={{
                                    verticalAlign: "top",
                                    border: "1px solid #323337",
                                    backgroundColor: "#22272B",
                                    width: "50px",
                                  }}
                                >
                                  <TextField
                                    variant="outlined"
                                    sx={{
                                      backgroundColor: "#22272B",

                                      "& .MuiOutlinedInput-root": {
                                        "& fieldset": {
                                          border: "none",
                                        },

                                        "&:hover fieldset": {
                                          border: "none",
                                        },
                                        "&.Mui-focused fieldset": {
                                          border: "none",
                                        },
                                        "& input": {
                                          padding: 0,
                                          color: cardEdit
                                            ? "rgba(255, 255, 255, 1)"
                                            : "rgba(140, 155, 171, 1)", // Text color inside the input
                                        },
                                      },
                                    }}
                                    value={
                                      row.data.quantity //Please check the logic pavan of prepopulating table values.
                                    }
                                    onChange={(event) =>
                                      handleQuantChange(
                                        row.id,
                                        index,
                                        event.target.value
                                      )
                                    }
                                  />
                                </TableCell>
                                <TableCell
                                  sx={{
                                    verticalAlign: "top",
                                    border: "1px solid #323337",
                                    backgroundColor: "#22272B",
                                    width: "50px",
                                  }}
                                >
                                  <Delete
                                    onClick={() => {
                                      handleDelete(row.id);
                                    }}
                                  />
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                      <SearchDropDown
                        open={open}
                        onClose={handleCloseSubCategory}
                        anchorE1={anchorEl}
                        width="300px"
                        searchWidth="270px"
                        Data={subCategories}
                        onSelect={(id, name) => {
                          if (activeRowId) {
                            handleSelectSubcategory(activeRowId, id, name);
                            setActiveRowId(null);
                          }
                        }}
                      />
                      <SearchDropDown
                        open={openCode}
                        onClose={handleCloseCodes}
                        anchorE1={anchorElCode}
                        width="300px"
                        searchWidth="270px"
                        Data={codes}
                        onSelect={(id, name) => {
                          if (activeRowId) {
                            handleSelectCodes(activeRowId, id, name);
                            setActiveRowId(null);
                          }
                        }}
                      />
                      <SearchDropDown
                        open={openDescription}
                        onClose={handleCloseDescription}
                        anchorE1={anchorElDescription}
                        width="500px"
                        searchWidth="450px"
                        Data={description}
                        onSelect={(id, name) => {
                          if (activeRowId) {
                            handleSelectDescription(activeRowId, id, name);
                            setActiveRowId(null);
                          }
                        }}
                      />
                    </Table>
                    {(toolsTableData?.length === 0 || !toolsTableData) && (
                      <Box
                        sx={{
                          backgroundColor: "#22272B",
                          width: "100%",
                          padding: "24px",
                          display: "flex",
                          borderBottomLeftRadius: "4px",
                          borderBottomRightRadius: "4px",
                          justifyContent: "center",
                        }}
                      >
                        <MediumTypography label="No Tools added yet " />
                      </Box>
                    )}
                  </TableContainer>

                  <Grid container sx={{ mt: "30px" }}>
                    <Grid item xs={12} lg={12} md={12} xl={12}>
                      <Box>
                        <Divider
                          variant="fullWidth"
                          sx={{
                            borderTop: "1px solid #333b43",
                          }}
                        />
                      </Box>
                    </Grid>
                  </Grid>

                  <Box sx={{ padding: "24px 0px 24px 0px" }}>
                    <Box className="" sx={{}}>
                      <Box>
                        <MediumTypography
                          labelId="CM.AdditionalInfo"
                          defaultLabel="Additional Info"
                          fontSize="20px"
                          fontWeight={500}
                          textColor="#FFFFFF"
                        />
                        <Grid
                          container
                          direction={"row"}
                          className="justifyContentSpaceBtw"
                          alignItems={"center"}
                          rowSpacing={"16px"}
                          columnSpacing={"40px"}
                          sx={{ marginBottom: "20px", marginTop: "10px" }}
                        >
                          <Grid item md={6} lg={4}>
                            <MediumTypography
                              className="input-label"
                              labelId="VendorLabelText"
                              defaultLabel="Vendor"
                              sxProps={{ marginBottom: "10px" }}
                            />
                            <DropdownComponent
                              names={convertToOptionType(
                                pmDetailsData.vendorList
                              )}
                              value={
                                values.additionalInfoBean.vendorId === 0
                                  ? ""
                                  : values.additionalInfoBean.vendorId.toString()
                              }
                              handleChange={(value: string) => {
                                setFieldValue(
                                  "additionalInfoBean.vendorId",
                                  parseInt(value)
                                );
                              }}
                              disabled={!cardEdit}
                              labelid="VendoeSelectionLabel"
                              defaultlabelid="--Select Vendor--"
                              minWidth="250px"
                            />
                            {/* {errors.additionalInfoBean?.vendorId &&
                              touched.additionalInfoBean?.vendorId && (
                                <Box
                                  className="loginError"
                                  sx={{ position: "relative" }}
                                >
                                  <Box>
                                    <ErrorIcon />
                                  </Box>
                                  <MediumTypography
                                    labelId={
                                      errors.additionalInfoBean?.vendorId
                                    }
                                    defaultLabel="Vendor is required"
                                    fontSize="12px"
                                    textColor="#AE2E24"
                                    fontWeight={400}
                                  />
                                </Box>
                              )} */}
                          </Grid>
                          <Grid item md={6} lg={4}>
                            <MediumTypography
                              className="input-label"
                              labelId="GrassImpactLabelText"
                              defaultLabel="Grass Impact"
                              sxProps={{ marginBottom: "10px" }}
                              textColor={"rgba(159, 173, 188, 1)"}
                            />
                            <RadioComponent
                              name="multipleBirth"
                              value={values.additionalInfoBean.grassImpact}
                              handleChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                setFieldValue(
                                  "additionalInfoBean.grassImpact",
                                  e.target.value
                                );
                              }}
                              labelid=""
                              defaultlabelid=""
                              labelidA="YesText"
                              defaultlabelidA="Yes"
                              labelidB="NoText"
                              defaultlabelidB="No"
                              disable={!cardEdit}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </Box>
                  <Grid container className="mt-xs">
                    <Grid item xs={12} lg={12} md={12} xl={12}>
                      <Box>
                        <Divider
                          variant="fullWidth"
                          sx={{
                            borderTop: "1px solid #333b43",
                          }}
                        />
                      </Box>
                    </Grid>
                  </Grid>

                  <WorkOrderAssignees
                    leadAssignee={leadAssignee}
                    assignees={assignees}
                    onChangeRequest={cardEdit ? onAssigneeChange : undefined}
                  />

                  <Grid container className="mt-xs">
                    <Grid item xs={12} lg={12} md={12} xl={12}>
                      <Box>
                        <Divider
                          variant="fullWidth"
                          sx={{
                            borderTop: "1px solid #333b43",
                          }}
                        />
                      </Box>
                    </Grid>
                  </Grid>

                  <Box className="mt-md mb-lg ">
                    <MediumTypography
                      labelId="ApproverText"
                      defaultLabel="Approver"
                      fontSize="20px"
                      fontWeight={500}
                      textColor="#FFFFFF"
                      className="mb-md"
                    />
                    <Box className="flex__">
                      {approver1 !== undefined && (
                        <Box
                          className="flex__"
                          sx={{
                            width: "146px",
                            height: "56px",
                            borderRadius: "4px",
                            border: "1px solid rgba(166, 197, 226, 0.16)",
                            backgroundColor: "#22272B",
                            marginRight: "16px",
                          }}
                        >
                          <>
                            {approver1 !== undefined && approver1 !== null && (
                              <React.Fragment>
                                <Box
                                  className="flex__ align__items__center ml-sm"
                                  sx={{ position: "relative" }}
                                >
                                  <Avatar
                                    src={approver1.image}
                                    alt="abc"
                                    style={{ width: "32px", height: "32px" }}
                                  />
                                </Box>
                                <Box className="pt-sm">
                                  <Box className="flex__justify__space-between">
                                    <Box
                                      sx={{ width: "100px", marginTop: "4px" }}
                                    >
                                      <Tooltip
                                        title={
                                          approver1.name &&
                                          approver1.name.length > 12
                                            ? approver1.name
                                            : ""
                                        }
                                        arrow={true}
                                        followCursor
                                      >
                                        <Box>
                                          <MediumTypography
                                            label={approver1.name}
                                            className="ml-sm ellipsis"
                                            fontSize="12px"
                                            textColor="#FFFFFF"
                                            sxProps={{
                                              width:
                                                approver1.name.length > 12
                                                  ? "100px"
                                                  : "auto",
                                            }}
                                          />
                                        </Box>
                                      </Tooltip>
                                      <Tooltip
                                        title={
                                          approver1.roleName &&
                                          approver1.roleName.length > 12
                                            ? approver1.roleName
                                            : ""
                                        }
                                        arrow={true}
                                        followCursor
                                      >
                                        <Box>
                                          <MediumTypography
                                            label={approver1.roleName}
                                            className=" ml-sm ellipsis"
                                            textColor="rgba(255, 255, 255, 0.64)"
                                            fontSize="10px"
                                            fontWeight={400}
                                            sxProps={{
                                              width:
                                                approver1.roleName.length > 12
                                                  ? "100px"
                                                  : "auto",
                                              marginTop:
                                                approver1.name === ""
                                                  ? "8px"
                                                  : "0px",
                                            }}
                                          />
                                        </Box>
                                      </Tooltip>
                                    </Box>
                                  </Box>
                                </Box>
                              </React.Fragment>
                            )}
                            {approver1 !== undefined && approver1 === null && (
                              <React.Fragment>
                                <Box
                                  className="flex__ align__items__center ml-sm"
                                  sx={{ position: "relative" }}
                                >
                                  <Avatar
                                    src={""}
                                    alt="No approver"
                                    style={{ width: "32px", height: "32px" }}
                                  />
                                </Box>
                                <Box className=" pt-sm">
                                  <Box className="flex__justify__space-between">
                                    <Box>
                                      <MediumTypography
                                        label={""}
                                        className="ml-xs ellipsis"
                                        fontSize="12px"
                                        textColor="#FFFFFF"
                                      />
                                      <MediumTypography
                                        label={""}
                                        className=" ml-xs ellipsis"
                                        textColor="rgba(255, 255, 255, 0.64)"
                                        fontSize="10px"
                                        fontWeight={400}
                                      />
                                    </Box>
                                  </Box>
                                </Box>
                              </React.Fragment>
                            )}
                          </>
                        </Box>
                      )}

                      {approver2 !== undefined && (
                        <Box
                          className="flex__"
                          sx={{
                            width: "146px",
                            height: "56px",
                            borderRadius: "4px",
                            border: "1px solid rgba(166, 197, 226, 0.16)",
                            backgroundColor: "#22272B",
                            marginRight: "16px",
                          }}
                        >
                          {approver2 !== undefined && approver2 !== null && (
                            <React.Fragment>
                              <Box
                                className="flex__ align__items__center ml-sm"
                                sx={{ position: "relative" }}
                              >
                                <Avatar
                                  src={approver2.image}
                                  alt="abc"
                                  style={{ width: "32px", height: "32px" }}
                                />
                              </Box>
                              <Box className="pt-sm">
                                <Box className="flex__justify__space-between">
                                  <Box
                                    sx={{ width: "100px", marginTop: "4px" }}
                                  >
                                    <Tooltip
                                      title={
                                        approver2.name &&
                                        approver2.name.length > 12
                                          ? approver2.name
                                          : ""
                                      }
                                      arrow={true}
                                      followCursor
                                    >
                                      <Box>
                                        <MediumTypography
                                          label={approver2.name}
                                          className="ml-sm ellipsis"
                                          fontSize="12px"
                                          textColor="#FFFFFF"
                                          sxProps={{
                                            width:
                                              approver2.name.length > 12
                                                ? "100px"
                                                : "auto",
                                          }}
                                        />
                                      </Box>
                                    </Tooltip>
                                    <Tooltip
                                      title={
                                        approver2.roleName &&
                                        approver2.roleName.length > 12
                                          ? approver2.roleName
                                          : ""
                                      }
                                      arrow={true}
                                      followCursor
                                    >
                                      <Box>
                                        <MediumTypography
                                          label={approver2.roleName}
                                          className=" ml-sm ellipsis"
                                          textColor="rgba(255, 255, 255, 0.64)"
                                          fontSize="10px"
                                          fontWeight={400}
                                          sxProps={{
                                            width:
                                              approver2.roleName.length > 12
                                                ? "100px"
                                                : "auto",
                                            marginTop:
                                              approver2.name === ""
                                                ? "8px"
                                                : "0px",
                                          }}
                                        />
                                      </Box>
                                    </Tooltip>
                                  </Box>
                                </Box>
                              </Box>
                            </React.Fragment>
                          )}
                          {approver2 !== undefined && approver2 === null && (
                            <React.Fragment>
                              <Box
                                className="flex__ align__items__center ml-sm"
                                sx={{ position: "relative" }}
                              >
                                <Avatar
                                  src={""}
                                  alt="No approver"
                                  style={{ width: "32px", height: "32px" }}
                                />
                              </Box>
                              <Box className=" pt-sm">
                                <Box className="flex__justify__space-between">
                                  <Box>
                                    <MediumTypography
                                      label={""}
                                      className="ml-xs ellipsis"
                                      fontSize="12px"
                                      textColor="#FFFFFF"
                                    />
                                    <MediumTypography
                                      label={""}
                                      className=" ml-xs ellipsis"
                                      textColor="rgba(255, 255, 255, 0.64)"
                                      fontSize="10px"
                                      fontWeight={400}
                                    />
                                  </Box>
                                </Box>
                              </Box>
                            </React.Fragment>
                          )}
                        </Box>
                      )}

                      {approver3 !== undefined && (
                        <Box
                          className="flex__"
                          sx={{
                            width: "146px",
                            height: "56px",
                            borderRadius: "4px",
                            border: "1px solid rgba(166, 197, 226, 0.16)",
                            backgroundColor: "#22272B",
                            marginRight: "16px",
                          }}
                        >
                          {approver3 !== undefined && approver3 !== null && (
                            <React.Fragment>
                              <Box
                                className="flex__ align__items__center ml-sm"
                                sx={{ position: "relative" }}
                              >
                                <Avatar
                                  src={approver3.image}
                                  alt="abc"
                                  style={{ width: "32px", height: "32px" }}
                                />
                              </Box>
                              <Box className="pt-sm">
                                <Box className="flex__justify__space-between">
                                  <Box
                                    sx={{ width: "100px", marginTop: "4px" }}
                                  >
                                    <Tooltip
                                      title={
                                        approver3.name &&
                                        approver3.name.length > 12
                                          ? approver3.name
                                          : ""
                                      }
                                      arrow={true}
                                      followCursor
                                    >
                                      <Box>
                                        <MediumTypography
                                          label={approver3.name}
                                          className="ml-sm ellipsis"
                                          fontSize="12px"
                                          textColor="#FFFFFF"
                                          sxProps={{
                                            width:
                                              approver3.name.length > 12
                                                ? "100px"
                                                : "auto",
                                          }}
                                        />
                                      </Box>
                                    </Tooltip>
                                    <Tooltip
                                      title={
                                        approver3.roleName &&
                                        approver3.roleName.length > 12
                                          ? approver3.roleName
                                          : ""
                                      }
                                      arrow={true}
                                      followCursor
                                    >
                                      <Box>
                                        <MediumTypography
                                          label={approver3.roleName}
                                          className=" ml-sm ellipsis"
                                          textColor="rgba(255, 255, 255, 0.64)"
                                          fontSize="10px"
                                          fontWeight={400}
                                          sxProps={{
                                            width:
                                              approver3.roleName.length > 12
                                                ? "100px"
                                                : "auto",
                                            marginTop:
                                              approver3.name === ""
                                                ? "8px"
                                                : "0px",
                                          }}
                                        />
                                      </Box>
                                    </Tooltip>
                                  </Box>
                                </Box>
                              </Box>
                            </React.Fragment>
                          )}{" "}
                          {approver3 !== undefined && approver3 === null && (
                            <React.Fragment>
                              <Box
                                className="flex__ align__items__center ml-sm"
                                sx={{ position: "relative" }}
                              >
                                <Avatar
                                  src={""}
                                  alt="No approver"
                                  style={{ width: "32px", height: "32px" }}
                                />
                              </Box>
                              <Box className=" pt-sm">
                                <Box className="flex__justify__space-between">
                                  <Box>
                                    <MediumTypography
                                      label={""}
                                      className="ml-xs ellipsis"
                                      fontSize="12px"
                                      textColor="#FFFFFF"
                                    />
                                    <MediumTypography
                                      label={""}
                                      className=" ml-xs ellipsis"
                                      textColor="rgba(255, 255, 255, 0.64)"
                                      fontSize="10px"
                                      fontWeight={400}
                                    />
                                  </Box>
                                </Box>
                              </Box>
                            </React.Fragment>
                          )}
                        </Box>
                      )}
                    </Box>
                  </Box>
                  <Grid container className="mt-xs">
                    <Grid item xs={12} lg={12} md={12} xl={12}>
                      <Box>
                        <Divider
                          variant="fullWidth"
                          sx={{
                            borderTop: "1px solid #333b43",
                          }}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              );
            }}
          </Formik>
        </Box>
      </Grid>
      <Grid item xs={2.5}>
        {!isDragEnabled && (
          <WorkOrderActivity isVisible={isVisible} cardId={stateCardId} />
        )}
        {isDragEnabled && (
          <ReassignSidebar
            teamData={teamData}
            cardDetails={cardDetails}
            totalUserCount={userCounts.total}
            presentUserCount={userCounts.present}
            cardShiftDetails={shiftData}
            currentDate={activeDate}
            isDragEnabled={isDragEnabled}
            reAssignCallBack={() => {
              setIsDragEnabled(false);
              reloadPmApiData();
            }}
            allShiftData={[shiftData]}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default PreventiveMaintenanceDetails;
