import { isCustomError } from "../api/ApiResponseHandler";
import { ApiErrorResponse } from "../api/UserApi/User";
import { OptionType } from "../components/formlib/CustomDropdown";

export const getOptions = (
  data: any[],
  nameKey = "name",
  idKey = "id"
): OptionType[] => {
  if (data === undefined || data === null || !Array.isArray(data)) return [];
  return data.map((item) => ({
    id: item[idKey].toString(),
    name: item[nameKey],
  }));
};

export const convertToNumber = (value: any): number => {
  return value === undefined || value === null || value === ""
    ? 0
    : Number(value);
};
export const convertToNumberOrNull = (value: any): number | null => {
  const numberValue = convertToNumber(value);
  return numberValue === 0 ? null : numberValue;
};

export const convertToNumberArray = (value: any): number[] => {
  return value === undefined ||
    value === null ||
    value === "" ||
    !Array.isArray(value)
    ? []
    : value.map((item) => Number(item));
};

export const handleError = (
  error: any, // Error object
  setErrorDesc: (desc: string) => void // Function to set the error description
) => {
  if (isCustomError(error)) {
    const apiError = error as ApiErrorResponse;
    setErrorDesc(apiError.issue[0]?.diagnostics);
  } else {
    error?.id ? setErrorDesc(error.id) : setErrorDesc("errors.somethingWrong");
  }
};

export function splitBySelectionStatus(items: any) {
  const selectedItems = items.filter((item: any) => item.isSelected === true);
  const unselectedItems = items.filter(
    (item: any) => item.isSelected === false
  );

  return {
    selected: selectedItems,
    unselected: unselectedItems,
  };
}
