import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import { FC, useContext, useEffect, useRef, useState } from "react";
import MediumTypography from "../../../components/formlib/MediumTypography";
import ButtonComponent from "../../../components/formlib/ButtonComponent";
import CloseIcon from "@mui/icons-material/Close";
import "../styles.css";
import TextInput from "../../../components/formlib/TextInput";

import CustomDropdown from "../../../components/formlib/CustomDropdown";
import { LoaderContext, LoaderContextType } from "../../../layouts/appSidebar";
import ErrorModal from "../../../components/formlib/modal/ErrorModal";

import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { trim } from "lodash";
import FormikErrorComponent from "../../../components/formlib/FormikErrorComponent";
import {
  convertToNumber,
  getOptions,
  handleError,
} from "../../../utils/commonFunctions";

import {
  AddInventoryType,
  CategoriesType,
  Make,
  Model,
  SaveInventoryType,
} from "../types/inventoryTypes";
import {
  createAdvancedDropdownOptionApi,
  fetchDataApi,
  saveDataApi,
} from "../../../api/MasterDataManagement/Common";
import { createDropdownOptionApi } from "../../../api/MasterDataManagement/Common";
import { CommonOptionType } from "../../../utils/type";

interface ModalProps {
  open: boolean;
  handleClose: () => void;
  selectedComponent?: string;
  id?: number;
  getDataCallback: () => void; // reloadAccountData
}

type FetchDataType = AddInventoryType;
type SaveDataType = SaveInventoryType;

const initialData: AddInventoryType = {
  categorySubcategoryList: [],
  materialCode: "",
  toolCode: "",
  name: "",
  makeList: [],
  uomList: [],
  selectedMakeId: 0,
  selectedModelId: 0,
  rating: "",
  selectedUomId: 0,
  selectedCategoryId: 0,
  selectedSubCategoryId: 0,
};

const dropdownValidation = Yup.mixed()
  .nullable()
  .test("is-valid-number", "validation.dropdown", (value: any) => {
    const theValue = value ? value.toString() : "";
    if (trim(theValue) === "" || theValue === "0") {
      return true;
    }

    return parseFloat(theValue) > 0;
  });

const customRequiredFn = (value: any) => {
  const theValue = value ? value.toString() : "";
  return trim(theValue) !== "" && theValue !== "0";
};

const getModelOptions = (make: Make[], selectedId: number) => {
  return make.find((m) => m.id === selectedId)?.models || [];
};

const getSubCategoryOptions = (
  categorySubcategoryList: CategoriesType[],
  selectedId: number
) => {
  return (
    categorySubcategoryList.find((c) => c.id === selectedId)?.subCategories ||
    []
  );
};

const muiColumnProps = {
  xs: 12,
  sm: 6,
  md: 4,
  lg: 4,
  xl: 4,
};
const validationSchema = Yup.object().shape({
  name: Yup.string()
    .max(500, "validation.maxLength500")
    .required("validation.fieldRequired"),
  rating: Yup.string()
    .max(500, "validation.maxLength500")
    .required("validation.fieldRequired"),

  selectedUomId: dropdownValidation.test(
    "custom-required",
    "validation.dropdown",
    customRequiredFn
  ),
  selectedCategoryId: dropdownValidation.test(
    "custom-required",
    "validation.dropdown",
    customRequiredFn
  ),
  selectedSubCategoryId: dropdownValidation.test(
    "custom-required",
    "validation.dropdown",
    customRequiredFn
  ),
});

const AddEditInventoryModal: FC<ModalProps> = ({
  open,
  handleClose,
  id,
  getDataCallback,
  selectedComponent,
}) => {
  const [apiData, setApiData] = useState<AddInventoryType>(initialData);
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [errorDesc, setErrorDesc] = useState<string>("");
  const formikRef = useRef<FormikProps<AddInventoryType>>(null);
  const [modelOptions, setModelOptions] = useState<Model[]>([]);
  const [subCategoryOptions, setSubCategoryOptions] = useState<
    CommonOptionType[]
  >([]);
  const [isVersionRequired, setIsVersionRequired] = useState<boolean>(false);
  const apiUri =
    selectedComponent === "ManageToolsAndPPE" ? "tools" : "inventory";

  const [modalOpen, setModalOpen] = useState<boolean>(false);

  useEffect(() => {
    toggleLoader(true);
    const getFetchData = async () => {
      await fetchData();
      toggleLoader(false);
    };

    getFetchData();
  }, [id]);

  const handleValidation = (values: AddInventoryType) => {
    const errors: Partial<AddInventoryType> = {};
    if (selectedComponent === "ManageToolsAndPPE" && !values.toolCode) {
      errors.toolCode = "validation.fieldRequired";
    } else if (
      selectedComponent !== "ManageToolsAndPPE" &&
      !values.materialCode
    ) {
      errors.materialCode = "validation.fieldRequired";
    }
    return errors;
  };

  const mockFetchDataApi = async (id?: number) => {
    let res;
    if (id) {
      res = await fetch("mock_api/inventory/fetch.json");
    } else {
      res = await fetch("mock_api/inventory/metadata.json");
    }

    return await res.json();
  };

  const fetchData = async () => {
    toggleLoader(true);
    await fetchDataApi<FetchDataType>(apiUri, id)
      .then(async (res) => {
        const updatedRes = { ...initialData, ...res };
        setApiData(updatedRes);
        setModalOpen(true);
        setModelOptions(getModelOptions(res.makeList, res.selectedMakeId));
        setSubCategoryOptions(
          getSubCategoryOptions(
            res.categorySubcategoryList,
            res.selectedCategoryId
          )
        );
        toggleLoader(false);
      })
      .catch((err) => {
        handleError(err, setErrorDesc);
        setOpenErrorModal(true);
        toggleLoader(false);
      });
  };

  const saveData = (params: FetchDataType) => {
    toggleLoader(true);

    const { makeList, uomList, categorySubcategoryList, ...updatedParams } =
      params;

    saveDataApi<SaveDataType>(apiUri, updatedParams, isVersionRequired, id)
      .then((res) => {
        formikRef.current?.resetForm({ values: initialData });
        handleClose();
        getDataCallback();
      })
      .catch((err) => {
        handleError(err, setErrorDesc);
        setOpenErrorModal(true);
        toggleLoader(false);
      });
  };
  return (
    <>
      {openErrorModal && (
        <ErrorModal
          descriptionText={errorDesc}
          open={openErrorModal}
          handleClose={() => {
            setOpenErrorModal(false);
            setModalOpen(false);
          }}
          onPositiveClick={() => {
            setOpenErrorModal(false);
          }}
        />
      )}

      {modalOpen && (
        <Formik
          initialValues={apiData}
          enableReinitialize
          validateOnChange
          innerRef={formikRef}
          validate={handleValidation}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            const paramsObj = {
              ...values,
              selectedMakeId: convertToNumber(values.selectedMakeId),
              selectedModelId: convertToNumber(values.selectedModelId),
              selectedUomId: convertToNumber(values.selectedUomId),
            };

            saveData(paramsObj);
          }}
        >
          {({
            values,
            setFieldValue,
            handleSubmit,
            errors,
            touched,
            dirty,
          }) => {
            return (
              <Dialog
                className="account-dialog main-wrapper-box"
                open={open}
                fullWidth
                maxWidth={"md"}
              >
                <DialogTitle className="padding_20">
                  <MediumTypography
                    labelId={
                      selectedComponent === "ManageToolsAndPPE"
                        ? id
                          ? "toolsAndPPE.update"
                          : "toolsAndPPE.add"
                        : id
                        ? "inventory.update"
                        : "inventory.add"
                    }
                    defaultLabel="Inventory Details"
                    textColor="#FFF"
                    fontSize="22px"
                    fontWeight={700}
                  />
                  <MediumTypography
                    labelId={"common.basicDetails"}
                    defaultLabel="Enter basic details"
                    textColor="rgba(159, 173, 188, 1)"
                    fontSize="14px"
                    fontWeight={400}
                    className="mt-xs"
                  />
                </DialogTitle>

                <IconButton
                  aria-label="close"
                  onClick={() => {
                    formikRef.current?.resetForm({ values: initialData });
                    handleClose();
                  }}
                  className="closeIcon"
                >
                  <CloseIcon />
                </IconButton>

                <DialogContent dividers className="padding_20">
                  <Grid container spacing={2}>
                    <Grid item {...muiColumnProps}>
                      <MediumTypography
                        className="input-label"
                        labelId="inventory.category"
                        defaultLabel="Category"
                      />
                      <CustomDropdown
                        options={getOptions(
                          values.categorySubcategoryList,
                          "category"
                        )}
                        selectedValue={values.selectedCategoryId}
                        onSelectedValueChange={(value) => {
                          setFieldValue("selectedCategoryId", value);
                          setFieldValue("selectedSubCategoryId", "");
                          setSubCategoryOptions(
                            getSubCategoryOptions(
                              values.categorySubcategoryList,
                              Number(value) || 0
                            )
                          );
                        }}
                        onCreateOption={(option) => {
                          toggleLoader(true);
                          createAdvancedDropdownOptionApi<CategoriesType>(
                            "CATEGORY_TYPE",
                            "CategoryType",
                            option
                          )
                            .then((res) => {
                              setFieldValue("categorySubcategoryList", res);
                              const selectedCategoryIdValue =
                                res.find((acc) => acc.category === option)
                                  ?.id || "";
                              setFieldValue(
                                "selectedCategoryId",
                                selectedCategoryIdValue
                              );
                              setSubCategoryOptions(
                                getSubCategoryOptions(
                                  res,
                                  Number(selectedCategoryIdValue) || 0
                                )
                              );
                              setFieldValue("selectedSubCategoryId", "");
                              toggleLoader(false);
                            })
                            .catch((err) => {
                              handleError(err, setErrorDesc);

                              setOpenErrorModal(true);
                              toggleLoader(false);
                            });
                        }}
                      />
                      <FormikErrorComponent
                        errors={errors}
                        touched={touched}
                        field="selectedCategoryId"
                      />
                    </Grid>
                    <Grid item {...muiColumnProps}>
                      <MediumTypography
                        className="input-label"
                        labelId="inventory.subCategory"
                        defaultLabel="Sub Category"
                      />
                      <CustomDropdown
                        options={getOptions(subCategoryOptions)}
                        selectedValue={values.selectedSubCategoryId}
                        onSelectedValueChange={(value) => {
                          setFieldValue("selectedSubCategoryId", value);
                        }}
                        onCreateOption={(option) => {
                          if (
                            values.selectedCategoryId === 0 ||
                            values.selectedCategoryId.toString() === ""
                          ) {
                            setErrorDesc("inventory.categoryError");
                            setOpenErrorModal(true);
                            return;
                          }
                          toggleLoader(true);
                          createAdvancedDropdownOptionApi<CategoriesType>(
                            "SUB_CATEGORY_TYPE",
                            "SubCategoryType",
                            option,
                            "categoryId",
                            values.selectedCategoryId.toString()
                          )
                            .then((res) => {
                              setFieldValue("categorySubcategoryList", res);
                              const newSubCategoryOptions =
                                getSubCategoryOptions(
                                  res,
                                  Number(values.selectedCategoryId) || 0
                                );
                              setSubCategoryOptions(
                                () => newSubCategoryOptions
                              );
                              const selectedSubCategoryIdValue =
                                newSubCategoryOptions.find(
                                  (acc) => acc.name === option
                                )?.id || "";
                              setFieldValue(
                                "selectedSubCategoryId",
                                selectedSubCategoryIdValue
                              );

                              toggleLoader(false);
                            })
                            .catch((err) => {
                              handleError(err, setErrorDesc);

                              setOpenErrorModal(true);
                              toggleLoader(false);
                            });
                        }}
                      />
                      <FormikErrorComponent
                        errors={errors}
                        touched={touched}
                        field="selectedSubCategoryId"
                      />
                    </Grid>
                    <Grid item {...muiColumnProps}>
                      <MediumTypography
                        className="input-label"
                        labelId="inventory.MaterialCode"
                        defaultLabel="Material Code"
                      />

                      <TextInput
                        className="text-input-field"
                        type="text"
                        variant="outlined"
                        inputProps={{
                          readOnly: false,
                        }}
                        labelId="common.placeHolderText"
                        defaultLabelId="--- type here ---"
                        Value={
                          selectedComponent === "ManageToolsAndPPE"
                            ? values.toolCode
                            : values.materialCode
                        }
                        handlechange={(value: string) => {
                          if (selectedComponent === "ManageToolsAndPPE") {
                            setFieldValue("toolCode", value);
                          } else {
                            setFieldValue("materialCode", value);
                          }
                        }}
                      />

                      <FormikErrorComponent
                        errors={errors}
                        touched={touched}
                        field={
                          selectedComponent === "ManageToolsAndPPE"
                            ? "toolCode"
                            : "materialCode"
                        }
                      />
                    </Grid>
                    <Grid item {...muiColumnProps}>
                      <MediumTypography
                        className="input-label"
                        labelId="skillNameText"
                        defaultLabel="Name"
                      />

                      <TextInput
                        className="text-input-field"
                        type="text"
                        variant="outlined"
                        inputProps={{
                          readOnly: false,
                        }}
                        labelId="common.placeHolderText"
                        defaultLabelId="--- type here ---"
                        Value={values.name}
                        handlechange={(value: string) => {
                          setFieldValue("name", value);
                        }}
                      />

                      <FormikErrorComponent
                        errors={errors}
                        touched={touched}
                        field="name"
                      />
                    </Grid>
                    <Grid item {...muiColumnProps}>
                      <MediumTypography
                        className="input-label"
                        labelId="inventory.make"
                        defaultLabel="Make"
                      />
                      <CustomDropdown
                        disableCreate={true}
                        options={getOptions(values.makeList, "name", "id")}
                        selectedValue={values.selectedMakeId}
                        onSelectedValueChange={(value) => {
                          setFieldValue("selectedMakeId", value);
                          setFieldValue("selectedModelId", "");
                          setModelOptions(
                            getModelOptions(
                              apiData.makeList,
                              Number(value) || 0
                            )
                          );
                        }}
                      />
                    </Grid>
                    <Grid item {...muiColumnProps}>
                      <MediumTypography
                        className="input-label"
                        labelId="inventory.model"
                        defaultLabel="Model"
                      />
                      <CustomDropdown
                        disableCreate={true}
                        options={getOptions(modelOptions, "name", "id")}
                        selectedValue={values.selectedModelId}
                        onSelectedValueChange={(value) => {
                          setFieldValue("selectedModelId", value);
                        }}
                      />
                    </Grid>
                    <Grid item {...muiColumnProps}>
                      <MediumTypography
                        className="input-label"
                        labelId="ratingText"
                        defaultLabel="Rating"
                      />

                      <TextInput
                        className="text-input-field"
                        type="text"
                        variant="outlined"
                        inputProps={{
                          readOnly: false,
                        }}
                        labelId="common.placeHolderText"
                        defaultLabelId="--- type here ---"
                        Value={values.rating}
                        handlechange={(value: string) => {
                          setFieldValue("rating", value);
                        }}
                      />

                      <FormikErrorComponent
                        errors={errors}
                        touched={touched}
                        field="rating"
                      />
                    </Grid>
                    <Grid item {...muiColumnProps}>
                      <MediumTypography
                        className="input-label"
                        labelId="inventory.uom"
                        defaultLabel="Unit of Measurement"
                      />
                      <CustomDropdown
                        disableCreate={false}
                        options={getOptions(values.uomList, "name", "id")}
                        selectedValue={values.selectedUomId}
                        onSelectedValueChange={(value) => {
                          setFieldValue("selectedUomId", value);
                        }}
                        onCreateOption={(option) => {
                          toggleLoader(true);
                          createDropdownOptionApi(
                            "INVENTORY_TYPE",
                            "InventoryType",
                            option
                          )
                            .then((res) => {
                              setFieldValue("uomList", res);
                              setFieldValue(
                                "selectedUomId",
                                res.find((inv) => inv.name === option)?.id || ""
                              );
                              toggleLoader(false);
                            })
                            .catch((err) => {
                              handleError(err, setErrorDesc);
                              setOpenErrorModal(true);
                              toggleLoader(false);
                            });
                        }}
                      />
                      <FormikErrorComponent
                        errors={errors}
                        touched={touched}
                        field="selectedUomId"
                      />
                    </Grid>
                  </Grid>
                </DialogContent>

                <DialogActions className="dialogActions">
                  <Box>
                    <Grid
                      className="flex__ justifyContent-FlexEnd"
                      container
                      direction="row"
                      alignItems="right"
                    >
                      <Grid item>
                        <ButtonComponent
                          sxProps={{ color: "#B6C2CF !important" }}
                          className="btn-primary btn-cancel mr-md"
                          variantType="outlined"
                          defaultLabelId={"Reset"}
                          labelId={"common.reset"}
                          onClick={() => formikRef.current?.resetForm()}
                          disabled={!dirty}
                        />
                      </Grid>
                      <Grid item>
                        <ButtonComponent
                          className="btn-primary btn-submit"
                          variantType="contained"
                          defaultLabelId={"Save"}
                          labelId={id ? "btn.update" : "btn.Add"}
                          onClick={() => {
                            handleSubmit();
                            setIsVersionRequired(false);
                          }}
                        />
                      </Grid>
                      {id && (
                        <Grid item>
                          <ButtonComponent
                            className="btn-primary btn-submit ml-sm"
                            variantType="contained"
                            defaultLabelId={"Save"}
                            labelId={"btn.updateWithVersion"}
                            onClick={() => {
                              handleSubmit();
                              setIsVersionRequired(true);
                            }}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Box>
                </DialogActions>
              </Dialog>
            );
          }}
        </Formik>
      )}
    </>
  );
};

export default AddEditInventoryModal;
