import { ApiResponseHandler } from "../ApiResponseHandler";
import WorkOrderConfig from "../WorkOrderConfig";
import {
  PlannerDataType,
  PlannerSaveDataType,
} from "../../pages/planner/plannerTypes";

export const getModuleCleaningPlannerApi = async (
  cardId: number,
  assetGroupId: number,
  idType: "workorder" | "schedule" = "workorder",
  selectedCycleNumber: number = 0,
  cyclePageNo: number = 0
): Promise<PlannerDataType> => {
  let appendString = "";
  if (selectedCycleNumber !== 0) {
    appendString = `&selectedCycleNumber=${selectedCycleNumber}`;
  }
  return ApiResponseHandler(
    await WorkOrderConfig.getInstance().get(
      `workorder/mc/${cardId}/plannerDetails?assetGroupId=${assetGroupId}&cyclePageNo=${cyclePageNo}&idType=${idType}${appendString}`
    )
  );
};

export const saveModuleCleaningPlannerApi = async (
  cardId: number,
  idType: "workorder" | "schedule" = "workorder",
  action:
    | "save-plan"
    | "save-actual"
    | "approve-actual"
    | "reject-actual"
    | "partial-reject",
  params: PlannerSaveDataType
) => {
  return ApiResponseHandler(
    await WorkOrderConfig.getInstance().post<
      PlannerSaveDataType,
      PlannerSaveDataType
    >(
      `workorder/mc/save/planner?workOrderId=${cardId}&idType=${idType}&action=${action}`,
      params
    )
  );
};
