import { Box, Button, Tabs } from "@mui/material";
import React, { FC, useCallback, useEffect, useState } from "react";
import MediumTypography from "./MediumTypography";
import { WOCategoryType } from "../../utils/type";

interface WorkOrderCategoryProps {
  onSelectTabs: (selectedTabs: number[]) => void;
  workOrderType: WOCategoryType[];
  totalWOCount: number;
  selectedWOCategoryFromParent: number[] | null;
}

const WorkOrderCategory: FC<WorkOrderCategoryProps> = ({
  onSelectTabs,
  workOrderType,
  totalWOCount,
  selectedWOCategoryFromParent,
}) => {
  const [selectedTabs, setSelectedTabs] = useState<number[]>([0]);

  useEffect(() => {
    if (
      selectedWOCategoryFromParent &&
      selectedWOCategoryFromParent.length > 0
    ) {
      setSelectedTabs(selectedWOCategoryFromParent);
    } else {
      setSelectedTabs([0]);
    }
  }, [selectedWOCategoryFromParent]);

  const handleTabClick = useCallback(
    (tabIndex: number) => {
      let updatedTabs: number[];
      if (selectedTabs.includes(tabIndex)) {
        updatedTabs = selectedTabs.filter((tab) => tab !== tabIndex);
        if (updatedTabs.length === 0) updatedTabs = [0]; // Ensure at least one tab is selected
      } else if (tabIndex === 0) {
        updatedTabs = [0];
      } else {
        updatedTabs = selectedTabs.includes(0)
          ? [tabIndex]
          : [...selectedTabs, tabIndex];
      }

      setSelectedTabs(updatedTabs);
      onSelectTabs(updatedTabs); // Immediately pass the updated tabs to the parent
    },
    [onSelectTabs, selectedTabs]
  );

  return (
    <Box>
      <Box
        sx={{
          "@media (max-width: 1280px)": {
            maxWidth: 270,
          },
          "@media (min-width: 1300px) and (max-width: 1400px)": {
            maxWidth: 400,
          },
          "@media (min-width: 1500px) and (max-width: 1900px)": {
            maxWidth: 470,
          },
          "@media (min-width: 1900px)": {
            maxWidth: 550,
          },
        }}
      >
        <Tabs
          value={selectedTabs}
          variant="scrollable"
          scrollButtons={false}
          selectionFollowsFocus={false}
          aria-label="scrollable auto tabs example"
        >
          <Button
            className={`button-ui p-xs tabs_scroller_parent ${
              selectedTabs.includes(0) ? "active" : ""
            } flex__`}
            onClick={() => handleTabClick(0)}
          >
            <MediumTypography
              labelId="ALL"
              defaultLabel="ALL"
              fontSize="12px"
              fontWeight={600}
              className="mr-xs"
            />
            <MediumTypography
              label={totalWOCount?.toString()}
              fontSize="12px"
              fontWeight={600}
            />
          </Button>

          {workOrderType &&
            workOrderType.length > 0 &&
            workOrderType.map((item, index) => (
              <Button
                key={index}
                className={`button-ui p-xs tabs_scroller_parent ${
                  selectedTabs.includes(item.workTypeId) ? "active" : ""
                } flex__ ${item.code === "CM" ? "tabs_warning" : ""}`}
                onClick={() => handleTabClick(item.workTypeId)}
              >
                <Box
                  className={
                    selectedTabs.includes(item.workTypeId)
                      ? ""
                      : "tabs_scroller_child"
                  }
                >
                  <MediumTypography
                    label={item.code}
                    fontSize="12px"
                    fontWeight={600}
                    textColor={
                      selectedTabs.includes(item.workTypeId)
                        ? "#FFFFFF"
                        : item.code === "CM"
                        ? "#FF453A"
                        : "#B8ACF6"
                    }
                  />
                </Box>
                <MediumTypography
                  label={item.count.toString()}
                  fontSize="12px"
                  fontWeight={600}
                  sxProps={{
                    marginLeft: selectedTabs.includes(item.workTypeId)
                      ? "4px"
                      : "",
                  }}
                />
              </Button>
            ))}
        </Tabs>
      </Box>
    </Box>
  );
};

export default WorkOrderCategory;
