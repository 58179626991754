import { Box, Grid, Tooltip } from "@mui/material";
import MediumTypography from "../../../components/formlib/MediumTypography";
import { FC } from "react";
import { PMAssetType } from "./types";

interface AssetListProps {
  assetsData: PMAssetType[] | null;
}
const ViewAssetListComponent: FC<AssetListProps> = ({ assetsData }) => {
  return (
    <Box component={"div"}>
      <Grid
        container
        direction={"row"}
        className="justifyContentSpaceBtw pl-md pr-md"
        alignItems={"center"}
        rowSpacing={"16px"}
        columnSpacing={"40px"}
        sx={{ marginBottom: "20px", marginTop: "10px" }}
      >
        {assetsData &&
          assetsData?.map((item, index) => (
            <Grid item key={item.id}>
              <Box
                sx={{
                  padding: "12px 6px",
                  backgroundColor: "rgba(29, 33, 37, 0.7)",
                  borderRadius: "4px",
                  width: "150px",
                  cursor: "pointer",
                }}
                // onClick={() => handleSelectItem(item.id)}
              >
                <Tooltip
                  title={item.name && item.name.length > 10 ? item.name : ""}
                  arrow={true}
                  followCursor
                >
                  <Box
                    sx={{
                      borderLeft: "2px solid #9FBBEB",
                      padding: "0px 12px",
                    }}
                  >
                    <MediumTypography
                      label={item.name}
                      className="ellipsis"
                      sxProps={{
                        width: item.name.length > 10 ? "120px" : "auto",
                      }}
                    />
                  </Box>
                </Tooltip>
              </Box>
            </Grid>
          ))}
      </Grid>
    </Box>
  );
};

export default ViewAssetListComponent;
