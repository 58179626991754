import * as React from "react";
import LinearProgress, {
  LinearProgressProps,
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import { styled } from "@mui/material";
import MediumTypography from "../../../components/formlib/MediumTypography";

interface LinearProgressBarProps {
  value?: number;
  width?: number | string;
  height?: number;
  fillColor?: string;
  bgColor?: string;
  radius?: number;
  fontSize?: number;
  fontWeight?: number;
}

interface BorderLinearProgressProps extends LinearProgressProps {
  height: number;
  fillColor: string;
  bgColor: string;
  radius: number;
  fontSize: number;
  fontWeight: number;
}

const BorderLinearProgress = styled(LinearProgress)<BorderLinearProgressProps>(
  ({ theme, height, fillColor, bgColor, radius }) => ({
    height: height,
    borderRadius: radius,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: bgColor,
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: radius,
      backgroundColor: fillColor,
    },
  })
);

function CustomLinearProgress(
  props: LinearProgressProps & BorderLinearProgressProps
) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 2 }}>
        <BorderLinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <MediumTypography
          label={`${Math.round(props.value || 0)}%`}
          sxProps={{
            fontSize: props.fontSize,
            fontWeight: props.fontWeight,
          }}
        />
      </Box>
    </Box>
  );
}

const LinearProgressBar: React.FC<LinearProgressBarProps> = ({
  value = 0,
  width = 100,
  height = 10,
  fillColor = "rgba(42, 187, 127, 1)",
  bgColor = "rgba(69, 74, 82, 1)",
  radius = 5,
  fontSize = 14,
  fontWeight = 700,
}) => {
  return (
    <Box sx={{ width: width }}>
      <CustomLinearProgress
        value={value}
        height={height}
        fillColor={fillColor}
        bgColor={bgColor}
        radius={radius}
        fontSize={fontSize}
        fontWeight={fontWeight}
      />
    </Box>
  );
};

export default LinearProgressBar;
