import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Slider,
  styled,
  SliderThumb,
} from "@mui/material";
import React, { FC, useState, useEffect } from "react";
import MediumTypography from "../../components/formlib/MediumTypography";
import ButtonComponent from "../../components/formlib/ButtonComponent";
import { ReactComponent as Cancel } from "../../assets/images/Cancel.svg";
import { shiftDetails } from "./types";

interface ModalProps {
  open: boolean;
  onSave?: (data: shiftDetails[]) => void;
  onNegativeClick: () => void;
  handleClose: () => void;
  data?: shiftDetails[];
}

const convertTimeToMinutes = (time: string) => {
  const [hours, minutes] = time.split(":").map(Number);
  return hours * 60 + minutes;
};

const convertMinutesToTime = (minutes: number) => {
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;
  return `${String(hours).padStart(2, "0")}:${String(mins).padStart(2, "0")}`;
};

interface ThumbComponentProps {
  children?: React.ReactNode;
  shiftStaticName: string | null;
  index: number;
}

const StyledSliderThumb = styled(SliderThumb)<ThumbComponentProps>(
  ({ theme, ...props }) => ({
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 12,
    fontWeight: "bold",
    boxShadow: theme.shadows[2],
    "&::after": {
      content: `"${props.shiftStaticName}"`,
      position: "absolute",
      padding: "5px 9px",
      whiteSpace: "nowrap",
      zIndex: 1,
      fontSize: "10px",
      color: "#1D2125",
      fontWeight: "bold",
    },
  })
);

const ThumbComponent: React.FC<ThumbComponentProps> = (props) => {
  return <StyledSliderThumb {...props}>{props.children}</StyledSliderThumb>;
};

const ShiftTimerComponent: FC<ModalProps> = ({
  open,
  onSave,
  onNegativeClick,
  handleClose,
  data,
}) => {
  const [generalShiftValue, setGeneralShiftValue] = useState<number[]>([]);
  const [values, setValues] = useState<number[]>([]);
  const [shouldRenderSlider, setShouldRenderSlider] = useState<boolean>(true);
  const [shiftData, setShiftData] = useState<shiftDetails[]>([]);

  useEffect(() => {
    if (data) {
      setShiftData(data);
    }
  }, [data]);

  useEffect(() => {
    if (shiftData.length) {
      const generalShift = shiftData.find(
        (shift) => shift.shiftStaticName === "G"
      );
      const filteredShifts = shiftData.filter(
        (shift) => shift.shiftStaticName !== "G"
      );

      if (shiftData.length === 1 && generalShift) {
        setShouldRenderSlider(false);
      } else {
        let shiftTimes: number[] = [];

        if (shiftData.length === 1 && !generalShift) {
          shiftTimes = shiftData[0].shiftTimeRange
            .split("-")
            .map(convertTimeToMinutes);
        } else if (shiftData.length === 2 && generalShift) {
          shiftTimes = shiftData[0].shiftTimeRange
            .split("-")
            .map(convertTimeToMinutes);
        } else if (shiftData.length === 2 && !generalShift) {
          const startTime1 = convertTimeToMinutes(
            filteredShifts[0].shiftTimeRange.split("-")[0]
          );
          const [start2, end2] = filteredShifts[1].shiftTimeRange
            .split("-")
            .map(convertTimeToMinutes);
          shiftTimes = [startTime1, start2, end2];
        } else if (shiftData.length === 3 && !generalShift) {
          shiftTimes = filteredShifts.map((shift) =>
            convertTimeToMinutes(shift.shiftTimeRange.split("-")[0])
          );
        } else if (shiftData.length === 4 && generalShift) {
          shiftTimes = filteredShifts.map((shift) =>
            convertTimeToMinutes(shift.shiftTimeRange.split("-")[0])
          );
        }
        setValues(shiftTimes);

        if (generalShift) {
          const [start, end] = generalShift.shiftTimeRange
            .split("-")
            .map(convertTimeToMinutes);
          setGeneralShiftValue([start, end]);
        }
      }
    }
  }, [shiftData]);

  const handleChange =
    (index: number) => (event: Event, newValue: number | number[]) => {
      setGeneralShiftValue(newValue as number[]);
    };

  const handleRangeChange =
    (index: number) => (event: Event, newValue: number | number[]) => {
      setValues(newValue as number[]);
    };

  const handleSave = () => {
    if (onSave && shiftData) {
      const convertToTime = (minutes: number) => {
        const hours = Math.floor(minutes / 60);
        const mins = minutes % 60;
        return `${String(hours).padStart(2, "0")}:${String(mins).padStart(
          2,
          "0"
        )}`;
      };

      const startTime1 = convertToTime(values[0]);
      const endTime1 = convertToTime(values[1]); // 1 second before the second value

      const startTime2 = convertToTime(generalShiftValue[0]);
      const endTime2 = convertToTime(generalShiftValue[1]); // 1 second before the second value

      const startTime3 = convertToTime(values[1]);
      const endTime3 = convertToTime(values[2]); // 1 second before the second value

      const startTime4 = convertToTime(values[2]);
      const endTime4 = convertToTime(values[0]); // 1 second before the second value
      if (shiftData) {
        const updatedData = shiftData.map((shift): shiftDetails => {
          if (shift.shiftStaticName === "G") {
            return { ...shift, shiftTimeRange: `${startTime2}-${endTime2}` };
          } else if (shift.shiftStaticName === "A") {
            return { ...shift, shiftTimeRange: `${startTime1}-${endTime1}` };
          } else if (shift.shiftStaticName === "B") {
            return { ...shift, shiftTimeRange: `${startTime3}-${endTime3}` };
          } else if (shift.shiftStaticName === "C") {
            return { ...shift, shiftTimeRange: `${startTime4}-${endTime4}` };
          }
          return shift; // Default return if none of the conditions above match
        });

        onSave(updatedData);
      }
    }
  };

  const getShiftStaticNameByIndex = (index: number) => {
    const filteredShifts = shiftData.filter(
      (shift) => shift.shiftStaticName !== "G"
    );
    if (index < filteredShifts.length) {
      return filteredShifts[index].shiftStaticName;
    }
    return "";
  };

  return (
    <Dialog open={open} fullWidth maxWidth={"sm"}>
      <DialogTitle sx={{ backgroundColor: "#1D2125" }} className="p-none">
        <Box className="flex__justify__space-between p-lg">
          <MediumTypography
            labelId={"shift.Timer"}
            defaultLabel="Shift Timer"
            textColor="#B6C2CF"
            fontSize="20px"
            fontWeight={700}
          />
          <Cancel style={{ cursor: "pointer" }} onClick={handleClose} />
        </Box>
        <Box className="horizontalLine" sx={{ marginTop: "0px" }} />
      </DialogTitle>

      <DialogContent sx={{ backgroundColor: "#1D2125" }}>
        <Box className="pt-md">
          {shouldRenderSlider && (
            <Box className="flex__ align__items__center ">
              <MediumTypography
                labelId={"shift.All"}
                defaultLabel="All Shifts"
                textColor="#B6C2CF"
                fontSize="12px"
                fontWeight={700}
                className="mr-md"
              />
              <Box
                className="p-lg"
                sx={{ width: "85%", backgroundColor: "#191E22" }}
              >
                <Slider
                  className="dailogBoxSliderBar"
                  value={values}
                  onChange={handleRangeChange(0)}
                  valueLabelDisplay="on"
                  valueLabelFormat={convertMinutesToTime}
                  aria-labelledby="shift-slider"
                  marks
                  step={30}
                  max={1440}
                  slots={{
                    thumb: (props) => {
                      const thumbIndex = Number(props["data-index"]); // Get the thumb index
                      const shiftStaticName =
                        getShiftStaticNameByIndex(thumbIndex); // Get the shiftStaticName based on the index

                      return (
                        <ThumbComponent
                          {...props}
                          shiftStaticName={shiftStaticName}
                        />
                      );
                    },
                  }}
                />
                <Box
                  className="flex__"
                  sx={{ justifyContent: "space-between" }}
                >
                  <MediumTypography
                    label="00:00"
                    fontSize="10px"
                    fontWeight={400}
                    textColor="#626F86"
                  />
                  <MediumTypography
                    label="24:00"
                    fontSize="10px"
                    fontWeight={400}
                    textColor="#626F86"
                  />
                </Box>
              </Box>
            </Box>
          )}
          <Box className="flex__ align__items__center mt-md">
            <Box className="flex__">
              <MediumTypography
                label="G"
                fontSize="14px"
                fontWeight={800}
                textColor="#b8acf6"
                className="shift_btn_1 mr-xs"
              />
              <MediumTypography
                labelId={"shift.Text"}
                defaultLabel="Shift"
                textColor="#B6C2CF"
                fontSize="12px"
                fontWeight={700}
                className="mr-md mt-xs"
              />
            </Box>
            {data && data.length > 1 && generalShiftValue.length > 0 && (
              <Box
                className="p-lg"
                sx={{ width: "85%", backgroundColor: "#191E22" }}
              >
                <Slider
                  className="dailogBoxSliderBar"
                  value={generalShiftValue}
                  onChange={handleChange(0)}
                  valueLabelDisplay="on"
                  valueLabelFormat={convertMinutesToTime}
                  aria-labelledby="shift-slider"
                  marks
                  step={30}
                  max={1440}
                />
                <Box
                  className="flex__"
                  sx={{ justifyContent: "space-between" }}
                >
                  <MediumTypography
                    label="00:00"
                    fontSize="10px"
                    fontWeight={400}
                    textColor="#626F86"
                  />
                  <MediumTypography
                    label="24:00"
                    fontSize="10px"
                    fontWeight={400}
                    textColor="#626F86"
                  />
                </Box>
              </Box>
            )}
          </Box>
          <Box className="horizontalLine" sx={{ marginY: "10px" }} />
          <Box>
            <Grid
              className="flex__ justifyContent-FlexEnd"
              container
              direction="row"
              alignItems="right"
            >
              <Grid item>
                <ButtonComponent
                  className="btn-primary btn-confirm mr-md"
                  variantType="contained"
                  defaultLabelId={"Save"}
                  labelId={"btn.save"}
                  onClick={handleSave}
                />
              </Grid>
              <Grid item>
                <ButtonComponent
                  sxProps={{ color: "#B6C2CF !important" }}
                  className="btn-primary btn-cancel "
                  variantType="contained"
                  defaultLabelId={"Cancel"}
                  labelId={"btn.cancel"}
                  onClick={onNegativeClick}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ShiftTimerComponent;
