import WorkFlowConfig from "../WorkFlowConfig";
import { SiteDetailsTypes } from "../../pages/wizards/wizrads.types";
import { ApiMessage, ApiResponseHandler } from "../ApiResponseHandler";
import { AssetCategoryType } from "../../pages/wizards/types/assetGroupTypes";

export type MappedCommonTypes = AssetCategoryType;

export interface ValidateApiResponse {
  exportBeanList: WizardsDataTypes[];
  errorMessages: string[];
}

export type WizardsDataTypes = SiteDetailsTypes;

export const fetchAllWizardsDataApi = async <T>(
  uri: string,
  language: string,
  filterQuery: string
): Promise<T> => {
  return ApiResponseHandler(
    await WorkFlowConfig.getInstance().get(
      `${uri}/fetchAll?languageCode=${language}&${filterQuery}`
    )
  );
};

export const fetchWizardsDataApi = async <T>(
  uri: string,
  id?: number,
  languageCode?: string
): Promise<T> => {
  if (id) {
    return ApiResponseHandler(
      await WorkFlowConfig.getInstance().get(`${uri}/${id}/fetch`)
    );
  }

  return ApiResponseHandler(
    await WorkFlowConfig.getInstance().get(
      `${uri}/metadata?languageCode=${languageCode}`
    )
  );
};

export const fetchWizardsMenuMetaDataApi = async <T>(
  uri: string,
  languageCode?: string
): Promise<T> => {
  return ApiResponseHandler(
    await WorkFlowConfig.getInstance().get(
      `${uri}/metadata?languageCode=${languageCode}`
    )
  );
};

export const SaveWizardsApi = async <T>(
  uri: string,
  params: T,
  isVersionRequired?: boolean,
  id?: number
) => {
  if (id) {
    return ApiResponseHandler(
      await WorkFlowConfig.getInstance().put<T, T>(
        `${uri}/${id}/update?isVersionRequired=${isVersionRequired}`,
        params
      )
    );
  }

  return ApiResponseHandler(
    await WorkFlowConfig.getInstance().post<T, T>(`${uri}/save`, params)
  );
};

export const customUpdateDataApi = async <T>(fullUri: string, params: T) => {
  return ApiResponseHandler(
    await WorkFlowConfig.getInstance().put<T, T>(fullUri, params)
  );
};

export const customSaveDataApi = async <T>(fullUri: string, params: T) => {
  return ApiResponseHandler(
    await WorkFlowConfig.getInstance().post<T, T>(fullUri, params)
  );
};

export const deleteWizardsDataApi = async (uri: string, id: number) => {
  return ApiResponseHandler(
    await WorkFlowConfig.getInstance().delete(`${uri}/${id}/delete`)
  );
};

export const uploadWizardsCSVFile = async (
  fileName: string,
  language: string,
  params: WizardsDataTypes[],
  name: string,
  isVersionRequired: boolean
): Promise<ApiMessage> => {
  return ApiResponseHandler(
    await WorkFlowConfig.getInstance().post(
      `${name}/import?languageCode=${language}&fileName=${fileName}&isVersionRequired=${isVersionRequired}`,
      params
    )
  );
};

export const validateWizradsCSVFileApi = async (
  csvFile: File | null,
  language: string,
  name: string
): Promise<ValidateApiResponse> => {
  const formData = new FormData();

  if (csvFile !== null) {
    formData.append(
      "file",
      new File([csvFile], csvFile.name.toLowerCase(), {
        type: "text/csv",
      })
    );
  } else {
    formData.append("file", "");
  }
  return ApiResponseHandler(
    await WorkFlowConfig.getInstance().post(
      `${name}/validateFile?languageCode=${language}`,
      formData
    )
  );
};
