import { Box, Button, ButtonGroup, Grid } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import WeekDatePicker from "../../components/formlib/WeekDatePicker";
import dayjs, { Dayjs } from "dayjs";
import ButtonComponent from "../../components/formlib/ButtonComponent";
import MediumTypography from "../../components/formlib/MediumTypography";
import SearchBox from "../../components/formlib/SearchBox";
import { ReactComponent as RightArrow } from "../../assets/images/RightArrow.svg";
import { ReactComponent as DownArrow } from "../../assets/images/DownArrow.svg";
import ModalPopup from "../../components/formlib/modal/ModalPopup";
import TeamMemberWithStatusComponent from "../../components/formlib/TeamMemberWithStatusComponent";
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from "react-beautiful-dnd";
import { TeamMemberForShift } from "../../utils/type";
import {
  LeaveType,
  ManageAbsenceType,
  PlanType,
  TeamMembersListType,
} from "./types";
import {
  addTimeOff,
  addWeekOff,
  getTimeOffType,
  getWeekOffType,
} from "../../api/manageAbsence/ManageAbsence";
import {
  DropdownContext,
  LoaderContext,
  LoaderContextType,
} from "../../layouts/appSidebar";
import ErrorModal from "../../components/formlib/modal/ErrorModal";
import { hasPermission } from "../../utils/checkPermission";
import { useIntl } from "react-intl";
import useAvailableWidth from "../../utils/useAvailableWidth";
import useAvailableHeight from "../../utils/useAvailableHeight";
import TeamMembersWithCancel from "../../components/formlib/TeamMembersWithCancel";
import { handleError } from "../../utils/commonFunctions";

interface WeekDatesTable {
  date: string;
  day: string;
  count: string;
}

const ManageAbsence = () => {
  const leaveTypes: string[] = ["WeekOffTextLabel", "TimeOffTextLabel"];
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [activeButton, setActiveButton] = useState(leaveTypes[0]);

  const [expanded, setExpanded] = useState<Record<string, boolean>>({});
  const [searchTerm, setSearchTerm] = useState<string>("");

  const [saveButton, setSaveButton] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const [manageAbsence, setManageAbsence] = useState<ManageAbsenceType | null>(
    null
  );
  const [checkedMembers, setCheckedMembers] = useState<
    Record<number | string, boolean>
  >({});
  const [openClickCancelModal, setOpenClickCancelModal] = useState(false);
  const [openDateChangeModal, setOpenDateChangeModal] = useState(false);
  const [hasDragged, setHasDragged] = useState(false);
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  const intl = useIntl();
  const startOfWeek =
    dayjs(selectedDate).day() === 0
      ? dayjs(selectedDate)
          .subtract(1, "week")
          .startOf("week")
          .add(1, "day")
          .format("YYYY-MM-DD") // If Sunday, go to previous week and set Monday
      : dayjs(selectedDate).startOf("week").add(1, "day").format("YYYY-MM-DD"); // Otherwise, set to Monday of this week

  // The end of the week should be Sunday, so add 6 days to the startOfWeek
  const endOfWeek = dayjs(startOfWeek).add(6, "days").format("YYYY-MM-DD");
  const [tempSelectedDate, setTempSelectedDate] = useState<Dayjs | null>(null);
  const isPreviousWeek = dayjs(selectedDate).isBefore(dayjs().startOf("week"));
  const [forceRerender, setForceRerender] = useState(0);
  const [warningModal, setWarningModal] = useState(false);
  const [recordExistingModal, setRecordExistingModal] = useState(false);
  const [apiResponseModal, setApiResponseModal] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [errorDesc, setErrorDesc] = useState<string>("");
  const availableHeight = useAvailableHeight(240);
  const availableWidth = useAvailableWidth(430);

  const context = useContext(DropdownContext);

  useEffect(() => {
    if (activeButton === leaveTypes[0]) {
      getWeekOffData();
    } else {
      getTimeOffData();
    }
  }, [
    activeButton,
    selectedDate,
    context.selectedSubgroupId,
    context.selectedAssetGroupsIds,
  ]);

  const generateUniqueId = () => {
    const uniqueNumber = parseInt(
      (
        Date.now().toString().slice(-5) +
        Math.floor(Math.random() * 100000).toString()
      ).slice(0, 10),
      10
    );
    return uniqueNumber;
  };

  const processShiftDetails = (leaveData: LeaveType | null): LeaveType => {
    if (!leaveData || !leaveData.weekPlan) {
      return { type: leaveData?.type || null, weekPlan: [], members: [] }; // Return an empty weekPlan to match LeaveType
    }

    const memberMap: Record<string, number> = {}; // To track unique members

    const processedWeekPlan = leaveData.weekPlan.map((item) => {
      const processedMembers = item.members.map((member) => {
        const memberKey = `${member.id}-${member.name}-${member.title}`;

        if (memberMap[memberKey]) {
          // If the member with the same id, name, and title has been seen before, create a new unique ID
          const uniqueId = generateUniqueId();
          return {
            ...member,
            elementId: member.id,
            id: uniqueId.toString(),
          };
        } else {
          // If the member is unique, store it in the map
          memberMap[memberKey] = member.id as number;
          return member;
        }
      });

      return {
        ...item,
        members: processedMembers,
      };
    });

    return {
      type: leaveData.type,
      weekPlan: processedWeekPlan,
      members: leaveData.members,
    };
  };

  const getWeekOffData = () => {
    toggleLoader(true);
    getWeekOffType(context.selectedSubgroupId, startOfWeek, endOfWeek)
      .then((response) => {
        toggleLoader(false);
        const processedResponse = {
          ...response,
          weekOffType: processShiftDetails(response.weekOffType),
        };
        setManageAbsence(processedResponse);
      })

      .catch((error) => {
        toggleLoader(false);
        setOpenErrorModal(true);
        handleError(error, setErrorDesc);
      });
  };
  const getTimeOffData = () => {
    toggleLoader(true);
    getTimeOffType(context.selectedSubgroupId, startOfWeek, endOfWeek)
      .then((response) => {
        toggleLoader(false);
        const processedResponse = {
          ...response,
          timeOffType: processShiftDetails(response.timeOffType),
        };
        setManageAbsence(processedResponse);
      })
      .catch((error) => {
        toggleLoader(false);
        setOpenErrorModal(true);
        handleError(error, setErrorDesc);
      });
  };

  const handleButtonClick = (value: string) => {
    if (saveButton) {
      setOpenModal(true);
    } else {
      setActiveButton(value);
    }
  };

  const filteredTechnicians =
    manageAbsence?.technicians &&
    manageAbsence?.technicians
      .map((technician) => {
        const matchingMembers = technician.members.filter(
          (member) =>
            member.name.toLowerCase().includes(searchTerm) ||
            member.title.toLowerCase().includes(searchTerm)
        );

        return {
          ...technician,
          members: matchingMembers,
        };
      })
      .filter(
        (technician) =>
          technician.members.length > 0 ||
          technician.title.toLowerCase().includes(searchTerm)
      );

  useEffect(() => {
    if (manageAbsence?.technicians) {
      if (searchTerm) {
        manageAbsence?.technicians.forEach((technician) => {
          setExpanded((prevExpanded) => ({
            ...prevExpanded,
            [technician.title]: true, // Keep them expanded by default
          }));
        });
      }

      if (!searchTerm) {
        setExpanded({});
      }
    }
  }, [searchTerm]);

  useEffect(() => {
    if (manageAbsence?.technicians) {
      const initialExpandedState = manageAbsence?.technicians.reduce(
        (acc, item) => {
          acc[item.title] = false;
          return acc;
        },
        {} as Record<string, boolean>
      );

      setExpanded(initialExpandedState);
    }
  }, []);
  const handleTechnicianMembers = (titleName: string) => {
    setExpanded((prevExpanded) => {
      const isExpanded = prevExpanded[titleName];

      if (isExpanded) {
        return {
          ...prevExpanded,
          [titleName]: false,
        };
      } else {
        return {
          ...prevExpanded,
          [titleName]: true,
        };
      }
    });
  };

  const handleSearchChange = (value: string) => {
    setSearchTerm(value.toLowerCase());
  };

  function formatDatePlan(weekData: PlanType[] | null | undefined) {
    return weekData?.map((item) => {
      const day = dayjs(item.date).format("ddd");
      const date = dayjs(item.date).format("DD");
      const count =
        item.members.length < 10 && item.members.length > 0
          ? "0" + item.members.length
          : item.members.length;
      const weekDate: WeekDatesTable = {
        date: date,
        day: day,
        count: count.toString(),
      };
      return weekDate;
    });
  }

  const Dates = formatDatePlan(manageAbsence?.weekOffType.weekPlan);
  const TimeDate = formatDatePlan(manageAbsence?.timeOffType.weekPlan);

  //checkbox function for selection of teammembers
  const toggleCheckStatus = (memberId: number | string) => {
    setCheckedMembers((prevCheckedMembers) => ({
      ...prevCheckedMembers,
      [memberId]: !prevCheckedMembers[memberId],
    }));
  };

  const handleDragEnd = (result: DropResult) => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return; // If there's no destination, we don't need to do anything.
    }

    let newManageAbsence: ManageAbsenceType = JSON.parse(
      JSON.stringify(manageAbsence)
    );

    const leavePlanType =
      activeButton === leaveTypes[0]
        ? newManageAbsence.weekOffType.weekPlan
        : newManageAbsence.timeOffType.weekPlan;
    const oppositeLeavePlanType =
      activeButton === leaveTypes[0]
        ? newManageAbsence.timeOffType.weekPlan
        : newManageAbsence.weekOffType.weekPlan;

    const destinationDate = destination.droppableId.split("/")[1];
    const sourceDate = source.droppableId.split("/")[1];
    const checkDestinationDate = dayjs(destinationDate);
    const checkSourceDate = dayjs(sourceDate);
    const today = dayjs().startOf("day");

    const isFromTechniciansList =
      source.droppableId.startsWith("technicians-list");

    if (
      (isFromTechniciansList && checkDestinationDate.isBefore(today)) ||
      (isFromTechniciansList && checkDestinationDate.isSame(today))
    ) {
      setWarningModal(true); // Show a warning modal
      return; // Do not proceed with the drag
    }

    const destinationList = leavePlanType?.find(
      (day: PlanType) => day.date === destinationDate
    );

    const isSameDayDrag = source.droppableId === destination.droppableId;

    const handleSingleTechnicianMove = (technicianId: number) => {
      let draggedTechnician: TeamMemberForShift | undefined;
      if (isFromTechniciansList) {
        const oppositeDayPlan =
          oppositeLeavePlanType &&
          oppositeLeavePlanType.find(
            (day: PlanType) => day.date === destinationDate
          );
        if (
          oppositeDayPlan &&
          oppositeDayPlan.members.some((member) => member.id === technicianId)
        ) {
          setRecordExistingModal(true);
          return; // Do not proceed with the drag
        } else {
          const sourceList = newManageAbsence.technicians.find(
            (list: TeamMembersListType) =>
              list.members.some((member) => member.id === technicianId)
          );

          if (sourceList) {
            const sourceTechnicianIndex = sourceList.members.findIndex(
              (member: TeamMemberForShift) => member.id === technicianId
            );

            if (
              sourceTechnicianIndex !== -1 &&
              source.droppableId !== destination.droppableId
            ) {
              [draggedTechnician] = sourceList.members.splice(
                sourceTechnicianIndex,
                1
              );
            }
          }
        }
      } else if (destination.droppableId.startsWith("technicians-list")) {
        return;
      } else {
        const sourceDayPlan: PlanType | undefined = leavePlanType?.find(
          (day: PlanType) => day.date === source.droppableId.split("/")[1]
        );
        if (
          (sourceDayPlan &&
            checkSourceDate.isBefore(today) &&
            !isSameDayDrag) ||
          (sourceDayPlan && checkSourceDate.isSame(today) && !isSameDayDrag)
        ) {
          const copies = sourceDayPlan.members.filter((member) =>
            typeof member.id === "string"
              ? parseInt(member.id) === technicianId
              : member.id === technicianId
          );

          const copyObject = sourceDayPlan.members.filter((member) => {
            if (
              member.name === copies?.[0].name &&
              member.title === copies?.[0].title
            ) {
              // Check if IDs or elementId match based on type
              if (
                typeof member.id === "string" &&
                typeof copies?.[0].id === "string"
              ) {
                return member.elementId === copies?.[0].elementId;
              } else if (
                typeof member.id === "string" &&
                typeof copies?.[0].id === "number"
              ) {
                return member.elementId === copies?.[0].id;
              } else if (
                typeof member.id === "number" &&
                typeof copies?.[0].id === "string"
              ) {
                return member.id === copies?.[0].elementId;
              } else {
                return member.id === copies?.[0].id;
              }
            }
            return false; // Return false to exclude non-matching members
          });

          if (copyObject && copyObject.length > 1) {
            draggedTechnician = copyObject?.find((member: TeamMemberForShift) =>
              typeof member.id === "string"
                ? parseInt(member.id) === technicianId
                : member.id === technicianId
            );
          } else {
            return;
          }
        } else if (sourceDayPlan) {
          draggedTechnician = sourceDayPlan.members.find(
            (member: TeamMemberForShift) =>
              typeof member.id === "string"
                ? parseInt(member.id) === technicianId
                : member.id === technicianId
          );
        }
      }

      if (draggedTechnician) {
        if (isSameDayDrag) {
          // Generate a 10-digit unique number
          const uniqueNumber = parseInt(
            (
              Date.now().toString().slice(-5) +
              Math.floor(Math.random() * 100000).toString()
            ).slice(0, 10),
            10
          );
          draggedTechnician = {
            ...draggedTechnician,
            id: `${uniqueNumber}`,
            elementId:
              typeof draggedTechnician.id === "string"
                ? draggedTechnician.elementId
                : draggedTechnician.id,
          };
        }

        if (!isSameDayDrag) {
          const sourceDayPlan = leavePlanType?.find(
            (day: PlanType) => day.date === source.droppableId.split("/")[1]
          );

          if (sourceDayPlan) {
            if (
              checkDestinationDate.isBefore(today) ||
              checkDestinationDate.isSame(today)
            ) {
              setWarningModal(true); // Show a warning modal
              return; // Do not proceed with the drag
            } else {
              const oppositeDayPlan =
                oppositeLeavePlanType &&
                oppositeLeavePlanType.find(
                  (day: PlanType) => day.date === destinationDate
                );
              if (
                oppositeDayPlan &&
                oppositeDayPlan.members.some((member) =>
                  typeof draggedTechnician?.id === "string"
                    ? member.id === draggedTechnician?.elementId
                    : member.id === draggedTechnician?.id
                )
              ) {
                setRecordExistingModal(true);
                return; // Do not proceed with the drag
              } else {
                const sourceTechnicianIndex = sourceDayPlan.members.findIndex(
                  (member: TeamMemberForShift) =>
                    typeof member.id === "string"
                      ? parseInt(member.id) === technicianId
                      : member.id === technicianId
                );

                if (sourceTechnicianIndex !== -1) {
                  sourceDayPlan.members.splice(sourceTechnicianIndex, 1);
                }
              }
            }
          }
        }
        if (destinationList) {
          destinationList.members.splice(destination.index, 0, {
            ...draggedTechnician,
          });
        }
      }
    };

    // Check if this is a multiple drag scenario
    const isMultipleDrag = Object.keys(checkedMembers).some(
      (key) => checkedMembers[key]
    );

    if (isMultipleDrag) {
      // Handle moving multiple checked items
      Object.keys(checkedMembers).forEach((id) => {
        if (checkedMembers[id]) {
          handleSingleTechnicianMove(parseInt(id));
        }
      });
      setCheckedMembers({});
    } else {
      // Handle single drag
      const technicianId = parseInt(draggableId.split("-")[1]);
      handleSingleTechnicianMove(technicianId);
    }

    setManageAbsence(newManageAbsence);
    setHasDragged(true);
  };
  const confirmDateChange = () => {
    if (tempSelectedDate) {
      setSelectedDate(tempSelectedDate.toDate());
      setHasDragged(false);
      setSaveButton(false);
      setCheckedMembers({});
      setOpenDateChangeModal(false);
    }
  };

  const filterUniqueMembers = (
    members: TeamMemberForShift[]
  ): TeamMemberForShift[] => {
    const uniqueMembers: TeamMemberForShift[] = [];
    const seen = new Map<string, TeamMemberForShift>();

    members.forEach((member) => {
      const key = `${member.name}-${member.title}-${
        member.elementId || member.id
      }`;
      if (!seen.has(key)) {
        // If no member with the same name, title, and id exists, add to seen
        if (member.elementId) {
          member.id = member.elementId;
        }
        seen.set(key, member);
        uniqueMembers.push(member);
      }
    });

    return uniqueMembers;
  };

  const filterWeekOffType = (weekOffType: LeaveType) => {
    const filteredWeekPlan =
      weekOffType.weekPlan &&
      weekOffType.weekPlan.map((day) => {
        const uniqueMembers = filterUniqueMembers(day.members);
        return {
          ...day,
          members: uniqueMembers,
        };
      });

    return {
      ...weekOffType,
      weekPlan: filteredWeekPlan,
    };
  };

  const handleSave = () => {
    toggleLoader(true);

    if (manageAbsence !== null) {
      let updatedManageAbsence: ManageAbsenceType = JSON.parse(
        JSON.stringify(manageAbsence)
      );

      // Iterate over each shiftTimingAndMember

      if (activeButton === leaveTypes[0]) {
        if (updatedManageAbsence.weekOffType?.weekPlan) {
          const filteredWeekOffType = filterWeekOffType(
            updatedManageAbsence.weekOffType
          );

          updatedManageAbsence.weekOffType = filteredWeekOffType;
        }

        if (updatedManageAbsence?.weekOffType) {
          addWeekOff(
            context.selectedSubgroupId,
            startOfWeek,
            endOfWeek,
            updatedManageAbsence?.weekOffType
          )
            .then(() => {
              getWeekOffData();

              setSaveButton(false);
              setHasDragged(false);
              setApiResponseModal(true);
              setCheckedMembers({});
            })
            .catch((error) => {
              setOpenErrorModal(true);
              handleError(error, setErrorDesc);
              toggleLoader(false);
            });
        }
      } else {
        if (updatedManageAbsence.weekOffType?.weekPlan) {
          const filteredTimeOffType = filterWeekOffType(
            updatedManageAbsence.timeOffType
          );
          updatedManageAbsence.timeOffType = filteredTimeOffType;
        }
        if (updatedManageAbsence?.timeOffType) {
          addTimeOff(
            context.selectedSubgroupId,
            startOfWeek,
            endOfWeek,
            updatedManageAbsence?.timeOffType
          )
            .then(() => {
              getTimeOffData();
              setSaveButton(false);
              setHasDragged(false);
              setApiResponseModal(true);
              setCheckedMembers({});
            })
            .catch((error) => {
              setOpenErrorModal(true);
              handleError(error, setErrorDesc);
              toggleLoader(false);
            });
        }
      }
    }
  };

  //removing the technician back to  list

  const handleRemoveClick = (technicianId: number | string, dayId: string) => {
    setManageAbsence((prevManageAbsence) => {
      const newManageAbsence: ManageAbsenceType = JSON.parse(
        JSON.stringify(prevManageAbsence)
      );

      const leavePlanType =
        activeButton === leaveTypes[0]
          ? newManageAbsence?.weekOffType?.weekPlan
          : newManageAbsence?.timeOffType?.weekPlan;

      const dayPlanIndex = leavePlanType?.findIndex(
        (day: PlanType) => day.date === dayId
      ); // If the day plan is not found, return early

      if (dayPlanIndex === undefined || dayPlanIndex === -1) {
        return prevManageAbsence;
      } // Retrieve the day plan using the index

      const dayPlan = leavePlanType![dayPlanIndex]; // Find the index of the technician in the day plan's members array

      const technicianIndex = dayPlan.members.findIndex(
        (member: TeamMemberForShift) => member.id === technicianId
      ); // If the technician is not found, return early

      if (technicianIndex === -1) {
        return prevManageAbsence;
      } // Remove the technician from the day plan

      const [removedTechnician] = dayPlan.members.splice(technicianIndex, 1); // Ensure that the removed technician has an ID before proceeding

      if (!removedTechnician || typeof removedTechnician.id === "undefined") {
        return newManageAbsence;
      } // Check if the technician is scheduled for multiple days

      const isScheduledMultipleDays = leavePlanType!.some((planDay: PlanType) =>
        planDay.members.some((member: TeamMemberForShift) => {
          return typeof member.id === "string"
            ? member.elementId === removedTechnician?.elementId ||
                removedTechnician?.id
            : member.id === removedTechnician?.elementId;
        })
      ); // If the technician is only scheduled for one day, add them back to the technicians list

      if (!isScheduledMultipleDays) {
        const titleGroup = newManageAbsence.technicians.find(
          (group: TeamMembersListType) =>
            group.title === removedTechnician.title
        );

        if (titleGroup) {
          // Update the checked state of the technician
          // Push the removed technician back into the titleGroup's members array
          if (
            typeof removedTechnician.id === "string" &&
            removedTechnician.elementId !== undefined
          ) {
            removedTechnician.id = removedTechnician.elementId;
            removedTechnician.elementId = undefined;
          }

          titleGroup.members.push(removedTechnician);
        } else {
          // If the group is not found, create a new group and add the technician to it
          newManageAbsence.technicians.push({
            title: removedTechnician.title,
            members: [removedTechnician],
          });
        }
      } // Return the updated manageAbsence state

      return newManageAbsence;
    }); // Indicate that a drag operation has occurred

    setHasDragged(true);
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd} key={forceRerender}>
      <Box component={"main"} className="p-md">
        {openErrorModal && (
          <ErrorModal
            descriptionText={errorDesc}
            open={openErrorModal}
            handleClose={() => setOpenErrorModal(false)}
            onPositiveClick={() => {
              setOpenErrorModal(false);
            }}
          />
        )}
        {manageAbsence && (
          <Box component={"section"} className="pt-md pb-md pl-sm pr-sm">
            {openModal && (
              <ModalPopup
                descriptionText="Weekoff.description"
                open={openModal}
                handleClose={() => setOpenModal(false)}
                onPositiveClick={() => {
                  setSaveButton(false);
                  setActiveButton(
                    activeButton === leaveTypes[0]
                      ? leaveTypes[1]
                      : leaveTypes[0]
                  );
                  setOpenModal(false);
                  setCheckedMembers({});
                }}
                onNegativeClick={() => {
                  setOpenModal(false);
                }}
                positiveButtonLabel="Yes"
                positiveButtonLabelId="YesText"
                negativeButtonLabel="No"
                negativeButtonLabelId="NoText"
              />
            )}

            {openClickCancelModal && (
              <ModalPopup
                descriptionText="Weekoff.description"
                open={openClickCancelModal}
                handleClose={() => setOpenClickCancelModal(false)}
                onPositiveClick={() => {
                  setSaveButton(false);
                  setOpenClickCancelModal(false);
                  setCheckedMembers({});
                  if (activeButton === leaveTypes[0]) {
                    getWeekOffData();
                  } else {
                    getTimeOffData();
                  }
                  setHasDragged(false);
                }}
                onNegativeClick={() => {
                  setOpenClickCancelModal(false);
                }}
                positiveButtonLabel="Yes"
                positiveButtonLabelId="YesText"
                negativeButtonLabel="No"
                negativeButtonLabelId="NoText"
              />
            )}
            {openDateChangeModal && (
              <ModalPopup
                descriptionText="Weekoff.description"
                open={openDateChangeModal}
                handleClose={() => setOpenDateChangeModal(false)}
                onPositiveClick={confirmDateChange}
                onNegativeClick={() => {
                  setOpenDateChangeModal(false);
                }}
                positiveButtonLabel="Yes"
                positiveButtonLabelId="YesText"
                negativeButtonLabel="No"
                negativeButtonLabelId="NoText"
              />
            )}
            {warningModal && (
              <ModalPopup
                descriptionText="WarningText.PreviousDates"
                open={warningModal}
                handleClose={() => setWarningModal(false)}
                onPositiveClick={() => {
                  setForceRerender((prev) => prev + 1);
                  setWarningModal(false);
                  setCheckedMembers({});
                }}
                positiveButtonLabel="Ok"
                positiveButtonLabelId="WarningText.ok"
              />
            )}
            {recordExistingModal && (
              <ModalPopup
                descriptionText={
                  activeButton === leaveTypes[0]
                    ? "WarningText.TimeoffList"
                    : "WarningText.WeekoffList"
                }
                open={recordExistingModal}
                handleClose={() => setRecordExistingModal(false)}
                onPositiveClick={() => {
                  setForceRerender((prev) => prev + 1);
                  setRecordExistingModal(false);
                  setCheckedMembers({});
                }}
                positiveButtonLabel="Ok"
                positiveButtonLabelId="WarningText.ok"
              />
            )}

            {apiResponseModal && (
              <ModalPopup
                descriptionText={
                  activeButton === leaveTypes[0]
                    ? "WeekOff.SuccessSave"
                    : "TimeOff.SuccessSave"
                }
                open={apiResponseModal}
                handleClose={() => setApiResponseModal(false)}
                onPositiveClick={() => {
                  setApiResponseModal(false);
                }}
                positiveButtonLabel="Ok"
                positiveButtonLabelId="WarningText.ok"
              />
            )}

            <Box className="flex__justify__space-between mb-md">
              <Box className="flex__">
                <Box>
                  <ButtonGroup
                    variant="contained"
                    aria-label="Basic button group"
                  >
                    {leaveTypes.map((item, index) => (
                      <Button
                        className={`buttongroup yearMonthDay_btn ${
                          activeButton === item ? "active" : ""
                        }`}
                        onClick={() => handleButtonClick(item)}
                        key={index}
                      >
                        {intl.formatMessage({ id: item })}
                      </Button>
                    ))}
                  </ButtonGroup>
                </Box>
                <Box sx={{ width: "max-content" }} className="ml-lg">
                  <WeekDatePicker
                    date={dayjs(selectedDate)}
                    disableFutureDate={false}
                    onDateSelect={(newDate: Dayjs) => {
                      if (hasDragged && saveButton) {
                        setOpenDateChangeModal(true);
                        setTempSelectedDate(newDate);
                      } else {
                        setSelectedDate(newDate.toDate());
                        setSaveButton(false);
                      }
                    }}
                  />
                </Box>
              </Box>
              {saveButton ? (
                <Box className="flex__">
                  <ButtonComponent
                    className="btn-primary btn-cancel "
                    labelId="btn.cancel"
                    defaultLabelId="Cancel"
                    onClick={() => {
                      if (hasDragged) {
                        setOpenClickCancelModal(true);
                      } else {
                        setSaveButton(false);
                      }
                    }}
                  />
                  <ButtonComponent
                    className="btn-primary btn-submit ml-sm"
                    labelId="btn.save"
                    defaultLabelId="Save"
                    onClick={
                      hasPermission("Edit_Absence") ? handleSave : () => {}
                    }
                  />
                </Box>
              ) : (
                !isPreviousWeek && (
                  <Box className="flex__">
                    <ButtonComponent
                      className={`btn-primary ${
                        hasPermission("Edit_Absence")
                          ? "btn-submit"
                          : "btn-disabled"
                      }  ml-sm`}
                      labelId="btn.edit"
                      defaultLabelId="Edit"
                      onClick={() =>
                        hasPermission("Edit_Absence")
                          ? setSaveButton(true)
                          : setSaveButton(false)
                      }
                    />
                  </Box>
                )
              )}
            </Box>
            {manageAbsence.technicians !== null && (
              <Box className="flex__ ">
                {!isPreviousWeek && manageAbsence.technicians.length > 0 && (
                  <Box className="mr-sm">
                    <Box className="teamMember_weekoff">
                      <MediumTypography
                        labelId="TeamMembers.title"
                        defaultLabel="Team Members"
                        fontSize="16px"
                        fontWeight={600}
                      />
                      <Box className="teamMember_count">
                        <MediumTypography
                          label={
                            manageAbsence?.presentUserCount +
                            "/" +
                            manageAbsence?.totalCount
                          }
                          fontSize="12px"
                          fontWeight={400}
                        />
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        backgroundColor: "#191E22",
                        position: "relative",
                        overflowY: "auto",
                        maxHeight: availableHeight,
                        height: availableHeight,
                      }}
                      className="tableStyles"
                    >
                      <Box
                        sx={{
                          position: "sticky",
                          top: 0,
                          zIndex: 1,
                          backgroundColor: "#191E22",
                        }}
                        className="pt-md pl-md pr-md"
                      >
                        <SearchBox
                          labelId="defaultSearch"
                          defaultlabel="Search"
                          backgroundColor="#22272B"
                          onChange={handleSearchChange}
                          sxProps={{
                            minWidth: "250px",
                          }}
                        />
                      </Box>
                      <Box className="pb-md pl-md pr-md">
                        <ul
                          className="no-marker "
                          style={{
                            scrollbarWidth: "none",
                            scrollbarColor: "red",
                          }}
                        >
                          <Droppable droppableId="technicians-list">
                            {(provided) => (
                              <ul
                                className="no-marker"
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                              >
                                {filteredTechnicians !== null &&
                                  filteredTechnicians?.map((techies) => {
                                    return (
                                      <li>
                                        <Box
                                          className="flex__"
                                          sx={{
                                            padding: "8px",
                                            alignItems: "center",
                                            gap: "8px",
                                            cursor: "pointer",
                                          }}
                                          onClick={() =>
                                            handleTechnicianMembers(
                                              techies.title
                                            )
                                          }
                                        >
                                          {expanded[techies.title] ? (
                                            <DownArrow />
                                          ) : (
                                            <RightArrow />
                                          )}
                                          <Box
                                            className="flex__"
                                            alignItems="center"
                                          >
                                            <MediumTypography
                                              label={techies.title}
                                              fontSize="14px"
                                              textColor="#ffffff"
                                            />
                                            <MediumTypography
                                              label={`(${techies.members.length})`}
                                              fontSize="14px"
                                              className="ml-sm"
                                            />
                                          </Box>
                                        </Box>
                                        {techies.members.map((mem, i) => {
                                          return (
                                            expanded[techies.title] && (
                                              <Draggable
                                                key={mem.id}
                                                draggableId={`technician-${mem.id}`}
                                                index={i}
                                                isDragDisabled={!saveButton}
                                              >
                                                {(provide) => (
                                                  <ul
                                                    className="no-marker pt-sm"
                                                    {...provide.draggableProps}
                                                    {...provide.dragHandleProps}
                                                    ref={provide.innerRef}
                                                  >
                                                    <TeamMemberWithStatusComponent
                                                      key={mem.id}
                                                      data={mem}
                                                      value={
                                                        mem.id !== undefined &&
                                                        !isPreviousWeek &&
                                                        saveButton &&
                                                        checkedMembers[mem.id]
                                                      }
                                                      onClickChange={() => {
                                                        if (
                                                          mem.id !==
                                                            undefined &&
                                                          !isPreviousWeek &&
                                                          saveButton
                                                        ) {
                                                          toggleCheckStatus(
                                                            mem.id
                                                          );
                                                        }
                                                      }}
                                                      statusNotRequired={false}
                                                    />
                                                  </ul>
                                                )}
                                              </Draggable>
                                            )
                                          );
                                        })}
                                      </li>
                                    );
                                  })}
                                {provided.placeholder}
                              </ul>
                            )}
                          </Droppable>
                        </ul>
                      </Box>
                    </Box>
                  </Box>
                )}

                <Box
                  sx={{
                    backgroundColor: "#191E22",
                    position: "relative",
                    overflowX: "auto",
                    maxWidth:
                      isPreviousWeek || manageAbsence.technicians.length === 0
                        ? "1450px"
                        : availableWidth,
                  }}
                >
                  <Grid
                    container
                    className="insideGridWeekOff"
                    sx={{ overflowX: "auto" }}
                  >
                    {(activeButton === leaveTypes[0] ? Dates : TimeDate)?.map(
                      (weekDate, index) => (
                        <Grid
                          item
                          key={index}
                          sx={{
                            height: "fit-content",
                            backgroundColor: "#161A1D",
                            padding: "16px",
                            borderBottom: "2px solid rgba(166, 197, 226, 0.16)",
                          }}
                        >
                          <Box
                            className="flex__container "
                            sx={{ width: "193px" }}
                          >
                            <MediumTypography
                              label={weekDate.day}
                              fontSize="16px"
                              fontWeight={700}
                              className="mr-sm"
                            />
                            <MediumTypography
                              label={weekDate.date}
                              fontSize="16px"
                              fontWeight={700}
                            />
                            <Box className="teamMember_count">
                              <MediumTypography
                                label={weekDate.count}
                                fontSize="12px"
                                fontWeight={400}
                              />
                            </Box>
                          </Box>
                        </Grid>
                      )
                    )}
                  </Grid>

                  <Grid
                    container
                    sx={{
                      minWidth: "1600px",
                      overflowX: "auto",
                    }}
                  >
                    {(activeButton === leaveTypes[0]
                      ? manageAbsence?.weekOffType.weekPlan ?? []
                      : manageAbsence?.timeOffType.weekPlan ?? []
                    ).map((dayPlan) => {
                      const pastDates =
                        dayPlan.date <= dayjs().format("YYYY-MM-DD");
                      return (
                        <Droppable droppableId={`dayPlan/${dayPlan.date}`}>
                          {(provided) => (
                            <Grid
                              item
                              key={dayPlan.date}
                              sx={{
                                backgroundColor: "#161A1D",
                                padding: "8px",
                                overflowY: "auto",
                                width: "218px",
                                maxWidth: "250px",
                                maxHeight: availableHeight,
                                height: availableHeight,
                                marginRight: "8px",
                              }}
                              className="tableStyles"
                              ref={provided.innerRef}
                              {...provided.droppableProps}
                            >
                              {dayPlan.members.map((teamMember, itemIndex) => (
                                <Draggable
                                  key={teamMember.id}
                                  draggableId={`assigned-${teamMember.id}`}
                                  index={itemIndex}
                                  isDragDisabled={!saveButton}
                                >
                                  {(provider) => (
                                    <Box
                                      key={teamMember.id}
                                      sx={{ width: "200px", paddingTop: "8px" }}
                                      {...provider.draggableProps}
                                      {...provider.dragHandleProps}
                                      ref={provider.innerRef}
                                    >
                                      <TeamMembersWithCancel
                                        data={teamMember}
                                        statusIndication={true}
                                        buttonStatus={!saveButton || pastDates}
                                        onRemoveClick={() => {
                                          if (teamMember.id) {
                                            handleRemoveClick(
                                              teamMember?.id,
                                              dayPlan.date
                                            );
                                          }
                                        }}
                                      />
                                    </Box>
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </Grid>
                          )}
                        </Droppable>
                      );
                    })}
                  </Grid>
                </Box>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </DragDropContext>
  );
};

export default ManageAbsence;
