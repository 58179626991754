import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import { FC, useContext, useEffect, useRef, useState } from "react";
import MediumTypography from "../../../components/formlib/MediumTypography";
import ButtonComponent from "../../../components/formlib/ButtonComponent";
import CloseIcon from "@mui/icons-material/Close";
import "../styles.css";
import TextInput from "../../../components/formlib/TextInput";

import PhoneNumberInput from "../../../components/formlib/PhoneNumberInput";
import CustomDropdown, {
  OptionType,
} from "../../../components/formlib/CustomDropdown";
import { LoaderContext, LoaderContextType } from "../../../layouts/appSidebar";
import {
  AddAccountDataType,
  CountriesType,
  SaveAddAccountDataType,
  StateType,
} from "../types/accountTypes";
import ErrorModal from "../../../components/formlib/modal/ErrorModal";

import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { trim } from "lodash";
import FormikErrorComponent from "../../../components/formlib/FormikErrorComponent";
import FileUploadInput from "../../workOrderDetailScreens/correctiveMaintenance/FileUploadInput";
import { DocumentTypeBean } from "../../workOrderDetailScreens/correctiveMaintenance/types";
import {
  createDropdownOptionApi,
  fetchDataApi,
  saveDataApi,
} from "../../../api/MasterDataManagement/Common";
import { handleError } from "../../../utils/commonFunctions";

const apiUri = "account";

const getOptions = (
  data: any[],
  nameKey = "name",
  idKey = "id"
): OptionType[] => {
  if (data === undefined || data === null || !Array.isArray(data)) return [];
  return data.map((item) => ({
    id: item[idKey].toString(),
    name: item[nameKey],
  }));
};

const getStateOptions = (
  countries: CountriesType[],
  selectedCountryId: number
) => {
  return (
    countries.find((country) => country.id === selectedCountryId)?.states || []
  );
};

const convertToNumber = (value: any): number => {
  return value === undefined || value === null || value === ""
    ? 0
    : Number(value);
};

const convertToNumberArray = (value: any): number[] => {
  return value === undefined ||
    value === null ||
    value === "" ||
    !Array.isArray(value)
    ? []
    : value.map((item) => Number(item));
};

const urlToDocumentTypeBean = async (
  url: string
): Promise<DocumentTypeBean> => {
  return fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      const reader = new FileReader();
      return new Promise<DocumentTypeBean>((resolve, reject) => {
        reader.onloadend = () => {
          const base64StringWithPrefix = reader.result as string;
          const base64String = base64StringWithPrefix.split(",")[1];
          const fileName = url.split("/").pop() || "file";
          const extension = fileName.split(".").pop() || "";
          resolve({
            document: base64String,
            fileName: fileName,
            extension: extension,
          });
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    });
};

interface ModalProps {
  open: boolean;
  handleClose: () => void;
  accountId?: number;
  getAccountDataCallback: () => void; // reloadAccountData
}

const initialData: AddAccountDataType = {
  accountId: undefined,
  name: "",
  primaryContactName: "",
  secondaryContactName: "",
  address1: "",
  country: [],
  code: "",
  languages: [],
  primaryPhone: "",
  secondaryPhone: "",
  address2: "",
  website: "",
  numberOfSites: 0,
  primaryEmail: "",
  secondaryEmail: "",
  pincode: "",
  logoUrl: "",
  accountImage: null,
  accountType: [],
  numberOfLanguages: [],
  selectedCountry: 0,
  selectedState: 0,
  selectedAccountType: 0,
};

const dropdownValidation = Yup.mixed()
  .nullable()
  .test("is-valid-number", "validation.dropdown", (value) => {
    const theValue = value ? value.toString() : "";
    if (trim(theValue) === "" || theValue === "0") {
      return true;
    }

    return parseFloat(theValue) > 0;
  });

const customRequiredFn = (value: any) => {
  const theValue = value ? value.toString() : "";
  return trim(theValue) !== "" && theValue !== "0";
};

const phoneNumberValidation = Yup.string().test(
  "is-valid-phone",
  "validation.phoneNumber",
  (value) => {
    if (value) {
      // Check if the number starts with "91"
      if (value.startsWith("91")) {
        // Validate that the number is 12 digits long
        return /^\d{12}$/.test(value);
      } else {
        // Validate that the number is at least 6 digits long
        return /^\d{6,}$/.test(value);
      }
    }
    return true;
  }
);

const websiteValidation = Yup.string().test(
  "is-valid-website",
  "validation.invalidWebsite",
  (value) => {
    if (!value) return true; // Allow empty values if the field is not required
    const urlPattern =
      /^(https?:\/\/)?(www\.)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
    return urlPattern.test(value);
  }
);

const validationSchema = Yup.object().shape({
  name: Yup.string().required("validation.fieldRequired"),
  numberOfLanguages: Yup.array().min(1, "validation.dropdown"),
  primaryContactName: Yup.string().max(80, "validation.maxLength80"),
  primaryEmail: Yup.string().email("validation.invaldiEmail"),
  primaryPhone: phoneNumberValidation,
  secondaryContactName: Yup.string().max(80, "validation.maxLength80"),
  secondaryEmail: Yup.string().email("validation.invaldiEmail"),
  secondaryPhone: phoneNumberValidation,
  address1: Yup.string()
    .max(80, "validation.maxLength80")
    .required("validation.fieldRequired"),
  address2: Yup.string().max(80, "validation.maxLength80"),
  pincode: Yup.number().max(9999999999, "validation.invalidPin"),
  selectedCountry: dropdownValidation.test(
    "custom-required",
    "validation.dropdown",
    customRequiredFn
  ),
  selectedState: dropdownValidation,
  code: Yup.string()
    .matches(/^[^\s]*$/, "validation.noSpaces")
    .max(20, "validation.maxLength20")
    .required("validation.fieldRequired"),
  selectedAccountType: dropdownValidation.test(
    "custom-required",
    "validation.dropdown",
    customRequiredFn
  ),
  website: websiteValidation,
  // logoUrl: Yup.mixed(),
});

const muiColumnProps = {
  xs: 12,
  sm: 6,
  md: 4,
  lg: 4,
  xl: 4,
};

const AddEditAccountModal: FC<ModalProps> = ({
  open,
  handleClose,
  accountId,
  getAccountDataCallback,
}) => {
  const [apiData, setApiData] = useState<AddAccountDataType>(initialData);
  const [stateOptions, setStateOptions] = useState<StateType[]>([]);
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [errorDesc, setErrorDesc] = useState<string>("");
  const [errorType, setErrorType] = useState<"fetch" | "edit">("fetch");
  const formikRef = useRef<FormikProps<AddAccountDataType>>(null);
  const [isVersionRequired, setIsVersionRequired] = useState<boolean>(false);

  useEffect(() => {
    if (open) {
      getAddAccountData(accountId);
    }
  }, [accountId, open]);

  const getAddAccountData = (accId: number | undefined) => {
    toggleLoader(true);

    fetchDataApi<AddAccountDataType>(apiUri, accId)
      .then(async (resp) => {
        const res = { ...initialData, ...resp };

        const updatedResponse: AddAccountDataType = {
          ...res,
          accountId: accId,
          accountImage: null,
          primaryPhone: res.primaryPhone.startsWith("+")
            ? res.primaryPhone.substring(1)
            : res.primaryPhone,
          secondaryPhone: res.secondaryPhone.startsWith("+")
            ? res.secondaryPhone.substring(1)
            : res.secondaryPhone,
        };

        const logoUrl = res.logoUrl;

        if (logoUrl !== "" && logoUrl !== null) {
          const documentTypeBean = await urlToDocumentTypeBean(logoUrl);
          updatedResponse.accountImage = documentTypeBean;
        }

        setApiData(updatedResponse);
        setStateOptions(getStateOptions(res.country, res.selectedCountry));
        toggleLoader(false);
      })
      .catch((err) => {
        handleError(err, setErrorDesc);
        setErrorType("fetch");
        setOpenErrorModal(true);
        toggleLoader(false);
      });
  };

  const saveAddAccountData = (params: AddAccountDataType) => {
    toggleLoader(true);
    const { country, languages, accountType, ...updatedParams } = params;
    saveDataApi<SaveAddAccountDataType>(
      apiUri,
      updatedParams,
      isVersionRequired,
      updatedParams?.accountId
    )
      .then((res) => {
        formikRef.current?.resetForm({ values: initialData });
        handleClose();
        getAccountDataCallback();
        toggleLoader(false);
      })
      .catch((err) => {
        setErrorType("edit");
        setOpenErrorModal(true);
        handleError(err, setErrorDesc);
        toggleLoader(false);
      });
  };

  const phoneValidation = (val: string) => {
    let formattedPrimaryPhone = val;

    if (formattedPrimaryPhone?.length < 5) {
      formattedPrimaryPhone = "";
    } else if (!formattedPrimaryPhone.startsWith("+")) {
      formattedPrimaryPhone = "+" + formattedPrimaryPhone;
    }
    return formattedPrimaryPhone;
  };

  return (
    <>
      {openErrorModal && (
        <ErrorModal
          descriptionText={errorDesc}
          open={openErrorModal}
          handleClose={() => {
            setOpenErrorModal(false);
            if (errorType === "fetch") return handleClose(); // Early return pattern
          }}
          onPositiveClick={() => {
            setOpenErrorModal(false);
            if (errorType === "fetch") {
              // Adding a log to differentiate
              handleClose();
            }
          }}
        />
      )}

      <Formik
        initialValues={apiData}
        enableReinitialize
        validateOnChange
        innerRef={formikRef}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          const paramsObj: AddAccountDataType = {
            ...values,
            primaryPhone: phoneValidation(values.primaryPhone),
            secondaryPhone: phoneValidation(values.secondaryPhone),
            numberOfLanguages: convertToNumberArray(values.numberOfLanguages),
            selectedCountry: convertToNumber(values.selectedCountry),
            selectedState: convertToNumber(values.selectedState),
            selectedAccountType: convertToNumber(values.selectedAccountType),
          };

          saveAddAccountData(paramsObj);
        }}
      >
        {({ values, setFieldValue, handleSubmit, errors, touched, dirty }) => {
          return (
            <Dialog
              className="account-dialog main-wrapper-box"
              open={open}
              fullWidth
              maxWidth={"lg"}
            >
              <DialogTitle className="padding_20">
                <MediumTypography
                  labelId={
                    accountId
                      ? "accounts.updateAccount"
                      : "accounts.addNewAccount"
                  }
                  defaultLabel="Account Details"
                  textColor="#FFF"
                  fontSize="22px"
                  fontWeight={700}
                />
                <MediumTypography
                  labelId={"accounts.basicDetails"}
                  defaultLabel="Enter your basic details"
                  textColor="rgba(159, 173, 188, 1)"
                  fontSize="14px"
                  fontWeight={400}
                  className="mt-xs"
                />
              </DialogTitle>

              <IconButton
                aria-label="close"
                onClick={() => {
                  formikRef.current?.resetForm({ values: initialData });
                  handleClose();
                }}
                className="closeIcon"
              >
                <CloseIcon />
              </IconButton>

              <DialogContent dividers className="padding_20">
                <Grid container spacing={2}>
                  <Grid item {...muiColumnProps}>
                    <MediumTypography
                      className="input-label"
                      labelId="accounts.accountName"
                      defaultLabel="Account Name"
                    />
                    <TextInput
                      className="text-input-field"
                      type="text"
                      variant="outlined"
                      inputProps={{
                        readOnly: false,
                      }}
                      labelId="common.placeHolderText"
                      defaultLabelId="--- type here ---"
                      Value={values.name}
                      handlechange={(value: string) => {
                        setFieldValue("name", value);
                      }}
                    />
                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="name"
                    />
                  </Grid>
                  <Grid item {...muiColumnProps}>
                    <MediumTypography
                      className="input-label"
                      labelId="accounts.languages"
                      defaultLabel="Languages"
                    />
                    <CustomDropdown
                      multiple={true}
                      disableSearch={true}
                      disableCreate={true}
                      options={getOptions(values.languages, "language")}
                      selectedValue={values.numberOfLanguages}
                      onSelectedValueChange={(value) => {
                        setFieldValue("numberOfLanguages", value);
                      }}
                      overrideHeight="auto"
                    />
                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="numberOfLanguages"
                    />
                  </Grid>
                  <Grid item {...muiColumnProps}>
                    <Box sx={{ display: accountId ? "block" : "none" }}>
                      <MediumTypography
                        className="input-label"
                        labelId="accounts.sitesCount"
                        defaultLabel="No. of Sites"
                      />
                      <TextInput
                        className="text-input-field"
                        type="number"
                        variant="outlined"
                        inputProps={{
                          readOnly: true,
                        }}
                        labelId="common.placeHolderText"
                        defaultLabelId="--- select ---"
                        Value={values.numberOfSites.toString()}
                        handlechange={(value: string) => {
                          setFieldValue("numberOfSites", value);
                        }}
                      />
                      <FormikErrorComponent
                        errors={errors}
                        touched={touched}
                        field="numberOfSites"
                      />
                    </Box>
                  </Grid>
                  <Grid item {...muiColumnProps}>
                    <MediumTypography
                      className="input-label"
                      labelId="accounts.primaryContactName"
                      defaultLabel="Primary Contact Name"
                    />
                    <TextInput
                      className="text-input-field"
                      type="text"
                      variant="outlined"
                      inputProps={{
                        readOnly: false,
                      }}
                      labelId="common.placeHolderText"
                      defaultLabelId="--- type here ---"
                      Value={values.primaryContactName}
                      handlechange={(value: string) => {
                        setFieldValue("primaryContactName", value);
                      }}
                    />
                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="primaryContactName"
                    />
                  </Grid>
                  <Grid item {...muiColumnProps}>
                    <MediumTypography
                      className="input-label"
                      labelId="accounts.primaryPhone"
                      defaultLabel="Primary Phone"
                    />
                    <PhoneNumberInput
                      value={values.primaryPhone}
                      onChange={(value: string) => {
                        setFieldValue("primaryPhone", value);
                      }}
                      labelId="common.placeHolderText"
                      defaultLabelId="--- type here ---"
                      disabled={false}
                    />
                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="primaryPhone"
                    />
                  </Grid>
                  <Grid item {...muiColumnProps}>
                    <MediumTypography
                      className="input-label"
                      labelId="accounts.primaryEmail"
                      defaultLabel="Primary Email"
                    />
                    <TextInput
                      className="text-input-field"
                      type="text"
                      variant="outlined"
                      inputProps={{
                        readOnly: false,
                      }}
                      labelId="common.placeHolderText"
                      defaultLabelId="--- type here ---"
                      Value={values.primaryEmail}
                      handlechange={(value: string) => {
                        setFieldValue("primaryEmail", value);
                      }}
                    />
                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="primaryEmail"
                    />
                  </Grid>
                  <Grid item {...muiColumnProps}>
                    <MediumTypography
                      className="input-label"
                      labelId="accounts.secondContactName"
                      defaultLabel="Secondary Contact Name"
                    />
                    <TextInput
                      className="text-input-field"
                      type="text"
                      variant="outlined"
                      inputProps={{
                        readOnly: false,
                      }}
                      labelId="common.placeHolderText"
                      defaultLabelId="--- type here ---"
                      Value={values.secondaryContactName}
                      handlechange={(value: string) => {
                        setFieldValue("secondaryContactName", value);
                      }}
                    />
                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="secondaryContactName"
                    />
                  </Grid>
                  <Grid item {...muiColumnProps}>
                    <MediumTypography
                      className="input-label"
                      labelId="accounts.secondPhone"
                      defaultLabel="Secondary Phone"
                    />
                    <PhoneNumberInput
                      value={values.secondaryPhone}
                      onChange={(value: string) => {
                        setFieldValue("secondaryPhone", value);
                      }}
                      labelId="common.placeHolderText"
                      defaultLabelId="--- type here ---"
                      disabled={false}
                    />
                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="secondaryPhone"
                    />
                  </Grid>
                  <Grid item {...muiColumnProps}>
                    <MediumTypography
                      className="input-label"
                      labelId="accounts.secondEmail"
                      defaultLabel="Secondary Email"
                    />
                    <TextInput
                      className="text-input-field"
                      type="text"
                      variant="outlined"
                      inputProps={{
                        readOnly: false,
                      }}
                      labelId="common.placeHolderText"
                      defaultLabelId="--- type here ---"
                      Value={values.secondaryEmail}
                      handlechange={(value: string) => {
                        setFieldValue("secondaryEmail", value);
                      }}
                    />
                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="secondaryEmail"
                    />
                  </Grid>
                  <Grid item {...muiColumnProps}>
                    <MediumTypography
                      className="input-label"
                      labelId="accounts.address1"
                      defaultLabel="Address1"
                    />
                    <TextInput
                      className="text-input-field"
                      type="text"
                      variant="outlined"
                      inputProps={{
                        readOnly: false,
                      }}
                      labelId="common.placeHolderText"
                      defaultLabelId="--- type here ---"
                      Value={values.address1}
                      handlechange={(value: string) => {
                        setFieldValue("address1", value);
                      }}
                    />
                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="address1"
                    />
                  </Grid>
                  <Grid item {...muiColumnProps}>
                    <MediumTypography
                      className="input-label"
                      labelId="accounts.address2"
                      defaultLabel="Address 2"
                    />
                    <TextInput
                      className="text-input-field"
                      type="text"
                      variant="outlined"
                      inputProps={{
                        readOnly: false,
                      }}
                      labelId="common.placeHolderText"
                      defaultLabelId="--- type here ---"
                      Value={values.address2}
                      handlechange={(value: string) => {
                        setFieldValue("address2", value);
                      }}
                    />
                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="address2"
                    />
                  </Grid>
                  <Grid item {...muiColumnProps}>
                    <MediumTypography
                      className="input-label"
                      labelId="accounts.pincode"
                      defaultLabel="Pincode"
                    />
                    <TextInput
                      className="text-input-field"
                      type="number"
                      variant="outlined"
                      inputProps={{
                        readOnly: false,
                      }}
                      labelId="common.placeHolderText"
                      defaultLabelId="--- type here ---"
                      Value={values.pincode}
                      handlechange={(value: string) => {
                        setFieldValue("pincode", value);
                      }}
                    />
                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="pincode"
                    />
                  </Grid>
                  <Grid item {...muiColumnProps}>
                    <MediumTypography
                      className="input-label"
                      labelId="accounts.country"
                      defaultLabel="Country"
                    />

                    <CustomDropdown
                      disableCreate={true}
                      options={getOptions(values.country, "countryName")}
                      selectedValue={values.selectedCountry}
                      onSelectedValueChange={(value) => {
                        const countryIdValue = value as string;
                        setFieldValue("selectedCountry", countryIdValue);
                        setFieldValue("selectedState", "");
                        setStateOptions(
                          getStateOptions(
                            apiData.country,
                            Number(countryIdValue) || 0
                          )
                        );
                      }}
                    />
                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="selectedCountry"
                    />
                  </Grid>
                  <Grid item {...muiColumnProps}>
                    <MediumTypography
                      className="input-label"
                      labelId="accounts.state"
                      defaultLabel="State"
                    />

                    <CustomDropdown
                      disableCreate={true}
                      options={getOptions(stateOptions, "stateName")}
                      selectedValue={values.selectedState}
                      onSelectedValueChange={(value) => {
                        setFieldValue("selectedState", value);
                      }}
                    />
                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="selectedState"
                    />
                  </Grid>
                  <Grid item {...muiColumnProps}>
                    <MediumTypography
                      className="input-label"
                      labelId="accounts.accountType"
                      defaultLabel="Account Type"
                    />
                    <CustomDropdown
                      options={getOptions(values.accountType)}
                      selectedValue={values.selectedAccountType}
                      onSelectedValueChange={(value) => {
                        setFieldValue("selectedAccountType", value);
                      }}
                      onCreateOption={(option) => {
                        toggleLoader(true);
                        createDropdownOptionApi(
                          "ACCOUNT_TYPE",
                          "accountType",
                          option
                        )
                          .then((res) => {
                            setFieldValue("accountType", res);
                            setFieldValue(
                              "selectedAccountType",
                              res.find((acc) => acc.name === option)?.id || ""
                            );
                            toggleLoader(false);
                          })
                          .catch((err) => {
                            handleError(err, setErrorDesc);
                            setErrorType("edit");
                            setOpenErrorModal(true);
                            toggleLoader(false);
                          });
                      }}
                    />
                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="selectedAccountType"
                    />
                  </Grid>
                  <Grid item {...muiColumnProps}>
                    <MediumTypography
                      className="input-label"
                      labelId="accounts.code"
                      defaultLabel="Code"
                    />
                    <TextInput
                      className="text-input-field"
                      type="text"
                      variant="outlined"
                      inputProps={{
                        readOnly: false,
                      }}
                      labelId="common.placeHolderText"
                      defaultLabelId="--- type here ---"
                      Value={values.code}
                      handlechange={(value: string) => {
                        setFieldValue("code", value);
                      }}
                    />
                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="code"
                    />
                  </Grid>
                  <Grid item {...muiColumnProps}>
                    <MediumTypography
                      className="input-label"
                      labelId="accounts.website"
                      defaultLabel="Website"
                    />
                    <TextInput
                      className="text-input-field"
                      type="text"
                      variant="outlined"
                      inputProps={{
                        readOnly: false,
                      }}
                      labelId="common.placeHolderText"
                      defaultLabelId="--- type here ---"
                      Value={values.website}
                      handlechange={(value: string) => {
                        setFieldValue("website", value);
                      }}
                    />
                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="website"
                    />
                  </Grid>
                  <Grid item {...muiColumnProps}>
                    <MediumTypography
                      className="input-label"
                      labelId="accounts.logo"
                      defaultLabel="Logo"
                    />
                    <FileUploadInput
                      fileCategory="image"
                      labelId="accounts.uploadPlaceholder"
                      defaultLabel="--- upload ---"
                      value={
                        values.accountImage !== null
                          ? values.accountImage.fileName
                          : ""
                      }
                      handleChange={(value: DocumentTypeBean) =>
                        setFieldValue("accountImage", value)
                      }
                      url={values.logoUrl !== null ? values.logoUrl : ""}
                      reset={false}
                      cardEdit={true}
                    />
                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="logoUrl"
                    />
                  </Grid>
                </Grid>
              </DialogContent>

              <DialogActions className="dialogActions">
                <Box>
                  <Grid
                    className="flex__ justifyContent-FlexEnd"
                    container
                    direction="row"
                    alignItems="right"
                  >
                    <Grid item>
                      <ButtonComponent
                        sxProps={{ color: "#B6C2CF !important" }}
                        className="btn-primary btn-cancel mr-md"
                        variantType="outlined"
                        defaultLabelId={"Reset"}
                        labelId={"common.reset"}
                        onClick={() => formikRef.current?.resetForm()}
                        disabled={!dirty}
                      />
                    </Grid>
                    <Grid item>
                      <ButtonComponent
                        className="btn-primary btn-submit"
                        variantType="contained"
                        defaultLabelId={"Save"}
                        labelId={accountId ? "btn.update" : "btn.Add"}
                        onClick={() => {
                          handleSubmit();
                          setIsVersionRequired(false);
                        }}
                      />
                    </Grid>
                    {accountId && (
                      <Grid item>
                        <ButtonComponent
                          className="btn-primary btn-submit ml-md"
                          variantType="contained"
                          defaultLabelId={"Save"}
                          labelId={
                            accountId ? "btn.updateWithVersion" : undefined
                          }
                          onClick={() => {
                            handleSubmit();
                            setIsVersionRequired(true);
                          }}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Box>
              </DialogActions>
            </Dialog>
          );
        }}
      </Formik>
    </>
  );
};

export default AddEditAccountModal;
