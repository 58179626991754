import {
  Avatar,
  Box,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  DialogTitle,
} from "@mui/material";
import React, { FC, useEffect, useRef, useState } from "react";
import MediumTypography from "../MediumTypography";
import { ReactComponent as MessageIcon } from "../../../assets/images/_MentionIcon_.svg";
import ButtonComponent from "../ButtonComponent";
import {
  RevokeApiProps,
  RevokeDetails,
  getRevokeDetails,
  revokeScheduleApi,
} from "../../../api/workOrderApi/schedulerApi/Scheduler";
import moment from "moment";
import ErrorModal from "./ErrorModal";

import { handleError } from "../../../utils/commonFunctions";

interface RevokeModalProps {
  open: boolean;
  handleClose: () => void;
  requestedDate: Date;
  revokeCallBack: () => void;
  sequenceId: number;
  version: number;
  cardId: number;
  statusCode: string;
  status: string;
}

const RevokeModal: FC<RevokeModalProps> = ({
  open,
  handleClose,
  requestedDate,
  revokeCallBack,
  sequenceId,
  version,
  cardId,
  statusCode,
  status,
}) => {
  const [message, setMessage] = useState<string>("");
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [errorDesc, setErrorDesc] = useState<string>("");
  const [revokeDetails, setRevokeDetails] = useState<RevokeDetails>();
  const remarksRef = useRef<string>("");
  const profileImage = useRef<string>("");
  const revokedBy = useRef<string>("");

  const statusTextMap: { [key: string]: string } = {
    ONHOLD: "On Hold",
    EXPIRED: "Expired",
    REJECTED: "Rejected",
    PAUSED: "Paused",
  };

  const backgroundColorMap: { [key: string]: string } = {
    ONHOLD: "rgba(245, 197, 24, 1)",
    EXPIRED: "rgba(91, 114, 130, 1)",
    REJECTED: "rgba(217, 31, 17, 1)",
    PAUSED: "rgba(24, 106, 222, 1)",
  };

  // Use the maps directly without extra variable assignment
  const statusText = statusTextMap[statusCode] || "Rejected";
  const backgroundColor =
    backgroundColorMap[statusCode] || "rgba(217, 31, 17, 1)";

  useEffect(() => {
    getRevokeDetails(cardId, sequenceId)
      .then((res) => {
        setRevokeDetails(res);
        remarksRef.current = res.remarks;
        profileImage.current = res.member.image;
        revokedBy.current = res.member.name;
      })
      .catch((err) => {
        setOpenErrorModal(true);

        handleError(err, setErrorDesc);
        handleClose();
      });
  }, []);

  const handleCallRevokeApi = () => {
    let obj: RevokeApiProps = {
      uniqueId: sequenceId,
      comments: message,
      requestedDate: moment(requestedDate).format("YYYY-MM-DD"),
      version: version,
    };
    revokeScheduleApi(cardId, obj)
      .then(() => {
        handleClose();
        revokeCallBack();
      })
      .catch((err) => {
        handleClose();
        setOpenErrorModal(true);

        handleError(err, setErrorDesc);
      });
  };

  return (
    <>
      {openErrorModal && (
        <ErrorModal
          descriptionText={errorDesc}
          open={openErrorModal}
          handleClose={() => setOpenErrorModal(false)}
          onPositiveClick={() => {
            setOpenErrorModal(false);
          }}
        />
      )}
      <Dialog open={open} fullWidth maxWidth={"md"}>
        <DialogTitle sx={{ backgroundColor: "#282E33" }}>
          <MediumTypography
            labelId="RevokeHeaderText"
            fontSize="24px"
            fontWeight={700}
            textColor="#9FADBC"
            defaultLabel="Revoke Status"
          />
          <MediumTypography
            labelId="RevokeSubHeaderText"
            fontSize="16px"
            fontWeight={400}
            textColor="#9FADBC"
            defaultLabel="Drop your comments to revoke the status"
            sxProps={{ mt: "10px" }}
          />
        </DialogTitle>
        <DialogContent sx={{ backgroundColor: "#282E33" }}>
          <Box
            component="main"
            sx={{
              width: "850px",
              height: "84px",
              borderRadius: "4px",
              padding: "12px",
              backgroundColor: "#1D2226",
              gap: "10px",
            }}
          >
            <Box className="flex__">
              <MediumTypography
                labelId="Revoke.RemarksText"
                fontSize="16px"
                fontWeight={700}
                textColor="#B6C2CF"
                defaultLabel="Remarks"
              />
              <Box
                className="flex__ position__relative"
                sx={{
                  padding: "4px 8px 4px 8px",
                  borderRadius: "3px",
                  backgroundColor: "rgba(161, 189, 217, 0.08)",
                  left: "10px",
                }}
              >
                <Avatar
                  src={revokeDetails?.member.image}
                  sx={{ width: "24px", height: "24px" }}
                />
                <Box className="flex__">
                  <MediumTypography
                    label={statusText}
                    textColor="#9FADBC"
                    fontSize="12px"
                    sxProps={{
                      textTransform: "none",
                      position: "relative",
                      left: "4px",
                    }}
                  />
                  <MediumTypography
                    labelId="ByLabel"
                    defaultLabel="By"
                    textColor="#9FADBC"
                    fontSize="12px"
                    className="ml-xs"
                    sxProps={{
                      textTransform: "none",
                      position: "relative",
                      left: "4px",
                    }}
                  />
                  <MediumTypography
                    label={revokeDetails?.member.name}
                    textColor="#9FADBC"
                    fontSize="12px"
                    className="ml-xs"
                    sxProps={{
                      textTransform: "none",
                      position: "relative",
                      left: "4px",
                    }}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  padding: "4px 16px 4px 16px",
                  borderRadius: "4px",
                  height: "30px",
                  backgroundColor: { backgroundColor },
                  marginLeft: "auto",
                }}
              >
                <MediumTypography
                  label={status}
                  textColor={statusCode === "ONHOLD" ? "#1C2B36" : "#FFFFFF"}
                  sxProps={{
                    textTransform: "none",
                  }}
                />
              </Box>
            </Box>
            <Box>
              <MediumTypography
                label={revokeDetails?.remarks}
                fontSize="16px"
                fontWeight={400}
                textColor="#9FADBC"
                sxProps={{ mt: "10px" }}
              />
            </Box>
          </Box>
          <Box sx={{ mt: "10px" }}>
            <TextField
              variant="outlined"
              sx={{
                backgroundColor: "#1D2226",
                borderRadius: "4px",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#1D2226",
                  },
                  width: "850px",
                  height: "40px",
                  "&:hover fieldset": {
                    borderColor: "#1D2226",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#1D2226",
                  },
                  "& input": {
                    color: "#fff", // Text color inside the input
                  },
                },
                "& .MuiInputLabel-root": {
                  color: "#bbb", // Placeholder color
                },
              }}
              placeholder="Message"
              value={message}
              onChange={(event) => {
                setMessage(event.target.value.trimStart());
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end">
                      <MessageIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box>
            <Grid
              className="flex__ justifyContent-FlexEnd pt-md  "
              container
              direction="row"
              alignItems="right"
            >
              <Grid item>
                <ButtonComponent
                  className="btn-confirm mr-md"
                  variantType="contained"
                  defaultLabelId="Confirm"
                  labelId="Revoke.confirmText"
                  sxProps={{
                    textTransform: "none",
                    fontFamily: "Inter",
                    "&:disabled": {
                      color: "white",
                      border: "none",
                    },
                  }}
                  disabled={message === ""}
                  onClick={() => handleCallRevokeApi()}
                />
              </Grid>
              <Grid item>
                <ButtonComponent
                  sxProps={{ color: "#B6C2CF !important" }}
                  className="btn-primary btn-cancel "
                  variantType="contained"
                  defaultLabelId="Cancel"
                  labelId="CancelBtnText"
                  onClick={() => {
                    handleClose();
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default RevokeModal;
