import { Box, Menu, MenuProps, Theme, styled } from "@mui/material";
import { FC, useMemo, useState } from "react";
import SearchBox from "./SearchBox";
import { DropDownType } from "../../api/GrassCutting/GrassCutting";
import MediumTypography from "./MediumTypography";

const CustomMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    style={{ left: -20 }}
    {...props}
  />
))(({ theme }: { theme: Theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    minWidth: 150,
    maxWidth: 500,
    maxHeight: 550,
    padding: "0px 0px",
    borderWidth: "0 10px 10px 10px",
    boxShadow: "none",
    backgroundColor: "#1D2125",
    border: "1px solid rgba(166, 197, 226, 0.16)",
  },
  "& .MuiPaper-root.MuiPaper-elevation > ul": {
    paddingBottom: "0px",
  },
  "& .MuiPaper-root::-webkit-scrollbar": {
    height: "5px",
    width: "4px",
    marginLeft: "10px",
  },
  "& .MuiPaper-root::-webkit-scrollbar-track": {
    backgroundColor: "transparent",
  },
  "& .MuiPaper-root::-webkit-scrollbar-thumb": {
    backgroundColor: "rgba(12, 102, 228, 0.6)",
    borderRadius: "10px",
    border: "1px solid rgba(12, 102, 228, 0.6)",
    backgroundClip: "content-box",
    marginLeft: "10px",
  },
  "& .MuiPaper-root::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#a8bbbf",
  },
}));

interface SearchDropDownProps {
  open: boolean;
  onClose: () => void;
  anchorE1: HTMLElement | null;
  width?: string;
  searchWidth?: string;
  Data?: DropDownType[];
  onSelect: (id: number, name: string) => void;
}
const SearchDropDown: FC<SearchDropDownProps> = ({
  open,
  onClose,
  anchorE1,
  width,
  searchWidth,
  Data,
  onSelect,
}) => {
  const MemorizedCustomMenu = useMemo(() => CustomMenu, []);

  const handleItemClick = (item: DropDownType) => {
    onSelect(item.id, item.name); // Use the onSelect callback
    onClose(); // Close the dropdown
  };
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (value: string) => {
    setSearchTerm(value);
  };

  const filteredData =
    Data?.filter((item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    ) || [];

  return (
    <MemorizedCustomMenu
      id="demo-customized-menu"
      anchorEl={anchorE1}
      open={open}
      onClose={onClose}
      sx={{ marginLeft: "20px" }}
    >
      <Box
        sx={{
          minWidth: width ? width : "200px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box sx={{ alignSelf: "center", margin: "8px" }}>
          <SearchBox
            labelId="defaultSearch"
            defaultlabel="Search"
            backgroundColor="#22272B"
            sxProps={{
              minWidth: searchWidth ? searchWidth : "350px",
            }}
            onChange={handleSearchChange}
          />

          <ul className="no-marker">
            <Box className="p-md ">
              {filteredData?.map((item) => {
                return (
                  <Box
                    className="flex__ pb-sm"
                    onClick={() => handleItemClick(item)}
                  >
                    <MediumTypography
                      label={item.name}
                      fontSize="14px"
                      textColor="#ffffff"
                    />
                  </Box>
                );
              })}
            </Box>
          </ul>
        </Box>
      </Box>
    </MemorizedCustomMenu>
  );
};

export default SearchDropDown;
