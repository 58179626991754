import React, {
  FC,
  useContext,
  useEffect,
  useRef,
  useState,
  useCallback,
} from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Box,
  Avatar,
  Tooltip,
  IconButton,
  MenuItem,
  Popover,
} from "@mui/material";
import { SxProps } from "@mui/system";
import MediumTypography from "../../../components/formlib/MediumTypography";
import { ReactComponent as More } from "../../../assets/images/ThreeDots.svg";
import useAvailableHeight from "../../../utils/useAvailableHeight";
import dayjs from "dayjs";
import useAvailableWidth from "../../../utils/useAvailableWidth";
import { hasPermission } from "../../../utils/checkPermission";
import CustomTableHeader from "../../../components/formlib/CustomTableHeader";
import { LoaderContext, LoaderContextType } from "../../../layouts/appSidebar";

import TableHeaderSelection from "../TableHeaderSelection";
import { ReactComponent as Menu } from "../../../assets/images/HorizontalMenu.svg";
import { ReactComponent as Sort } from "../../../assets/images/Sort.svg";
import AddEditAccountModal from "./AddEditAccountModal";
import ErrorModal from "../../../components/formlib/modal/ErrorModal";
import ModalPopup from "../../../components/formlib/modal/ModalPopup";
import {
  applySearchFilters,
  cancelSearchFilters,
  sortTableData,
} from "../TableUtility";
import { useLocation } from "react-router-dom";
import {
  deleteDataApi,
  fetchAllDataApi,
} from "../../../api/MasterDataManagement/Common";
import {
  AccountData,
  HeadCell,
  ManageAccountType,
} from "../types/accountTypes";
import { handleError } from "../../../utils/commonFunctions";

// Configurations

const apiUri = "account";

const accountSearchKeys = [
  "name",
  "accType",
  "primaryContactName",
  "secondaryContactName",
  "primaryPhone",
  "primaryEmail",
  "addressLine1",
  "secondaryEmail",
  "secondaryPhone",
  "address1",
  "address2",
];

interface TableProps {
  labelSxProp?: SxProps;
  className?: string;
}

export interface ManageAccountsProps {
  TableProps?: TableProps;
  onAccountDataFetch: (data: ManageAccountType) => void;
  selectedLanguageCode: string;
  onOpenAddModal: boolean;
  handleModalClose: () => void;
  handleModalOpen: () => void;
  onFilter: (filteredData: { [key: string]: string }) => void;
}

const ManageAccounts: FC<ManageAccountsProps> = ({
  TableProps,
  onAccountDataFetch,
  selectedLanguageCode,
  onOpenAddModal,
  handleModalClose,
  handleModalOpen,
  onFilter,
}) => {
  const availableWidth = useAvailableWidth(420);
  const availableHeight = useAvailableHeight(220);
  const emptyTableHeight = useAvailableHeight(300);

  const [selectedRow, setSelectedRow] = useState<null | number>(null);
  const containerRef = useRef(null);
  const [activeFilters, setActiveFilters] = useState<{ [key: string]: string }>(
    {}
  );
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  const [listData, setListData] = useState<ManageAccountType | null>(null);
  const [tableData, setTableData] = useState<AccountData[]>([]);
  const [originalTableData, setOriginalTableData] = useState<AccountData[]>([]); // Store original data

  const [reorderedFields, setReorderedFields] = useState<HeadCell[]>([]);
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [errorDesc, setErrorDesc] = useState<string>("");
  const [filterOpen, setFilterOpen] = useState<null | HTMLElement>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [warningModal, setWarningModal] = useState<boolean>(false);
  const [modalAccountId, setModalAccountId] = useState<number>();
  const open = Boolean(filterOpen);
  const addEditOpen = Boolean(anchorEl);

  const location = useLocation();

  const dataFieldsWithIcons =
    reorderedFields &&
    reorderedFields.map((field) => {
      return { ...field, icon: <Sort /> };
    });

  useEffect(() => {
    getAccountData(true);
  }, [selectedLanguageCode, location?.state]);

  const getAccountData = (
    resetData: boolean = false,
    language: string = selectedLanguageCode,
    applyFilters: boolean = false
  ) => {
    toggleLoader(true);

    // getListOfAccounts(language)
    fetchAllDataApi<ManageAccountType>(apiUri, language, "")
      .then((response) => {
        toggleLoader(false);
        if (response) {
          setListData(response);

          if (resetData) {
            setTableData(response.account.records);
            setOriginalTableData(response.account.records);
          } else {
            const updateTableData = (newRecords: AccountData[]) => {
              setTableData((prevMessages) => [...prevMessages, ...newRecords]);
              setOriginalTableData((prevMessages) => [
                ...prevMessages,
                ...newRecords,
              ]);
            };

            // Use the helper function with response data
            updateTableData(response.account.records);
          }

          setReorderedFields(response.dataFields);
          onAccountDataFetch(response);
          if (applyFilters && Object.keys(activeFilters).length > 0) {
            const filteredData = applySearchFilters(
              response.account.records,
              activeFilters
            );
            setTableData(filteredData as AccountData[]);
          }
        }
      })
      .catch((error) => {
        toggleLoader(false);
        setOpenErrorModal(true);
        handleError(error, setErrorDesc);
      });
    setActiveFilters({});
  };

  const handleDeleteAccount = (id: number) => {
    toggleLoader(true);
    deleteDataApi(apiUri, id)
      .then(() => {
        getAccountData(true, selectedLanguageCode, true);
      })
      .catch((error) => {
        toggleLoader(false);
        setOpenErrorModal(true);
        handleError(error, setErrorDesc);
      });
    handleClose();
    setModalAccountId(undefined);
  };

  const handleClick = (
    event: React.MouseEvent<HTMLElement>,
    rowIndex: number
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(rowIndex);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handleEdit = (id: number) => {
    setModalAccountId(id);
    handleModalOpen();
    handleClose();
  };

  const handleCloseMenu = () => {
    setFilterOpen(null);
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setFilterOpen(event.currentTarget);
  };

  const handleApply = (reorderedCheckedItems: HeadCell[]) => {
    setReorderedFields(reorderedCheckedItems);
    // Perform any additional actions with the reorderedCheckedItems
  };

  const handleSort = (columnKey: string, direction: string) => {
    const sortedData = sortTableData(
      tableData,
      columnKey,
      direction,
      originalTableData
    );
    setTableData(sortedData as AccountData[]);
  };

  const handleSearchApply = (columnKey: string, value: string) => {
    const newFilters = { ...activeFilters, [columnKey]: value };
    const filteredData = applySearchFilters(originalTableData, newFilters);
    setTableData(filteredData as AccountData[]);
    onFilter(newFilters);
    setActiveFilters(newFilters);
  };

  const handleSearchCancel = (columnKey: string) => {
    const { filteredData, updatedFilters } = cancelSearchFilters(
      originalTableData,
      activeFilters,
      columnKey
    );
    setTableData(filteredData as AccountData[]);
    onFilter(updatedFilters);
    setActiveFilters(updatedFilters);
  };

  const handleConfirmDelete = useCallback(() => {
    if (modalAccountId) {
      handleDeleteAccount(modalAccountId);
    }
    setWarningModal(false);
  }, [modalAccountId]);

  const isFilterApplied = () => {
    const filterApplied = Object.values(activeFilters).some(
      (value) => value !== ""
    );

    if (filterApplied == false || JSON.stringify(activeFilters) === "{}") {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Box>
      {onOpenAddModal && (
        <AddEditAccountModal
          accountId={modalAccountId}
          open={onOpenAddModal}
          handleClose={() => {
            setModalAccountId(undefined);
            handleModalClose();
          }}
          getAccountDataCallback={() => getAccountData(true)}
        />
      )}
      {warningModal && (
        <ModalPopup
          descriptionText={"Account.deleteWarningmessage"}
          open={warningModal}
          handleClose={() => setWarningModal(!warningModal)}
          onPositiveClick={handleConfirmDelete}
          onNegativeClick={() => {
            setWarningModal(false);
            setModalAccountId(undefined);
            handleClose();
          }}
          positiveButtonLabel="Yes"
          positiveButtonLabelId="YesText"
          negativeButtonLabel="No"
          negativeButtonLabelId="NoText"
        />
      )}
      {openErrorModal && (
        <ErrorModal
          descriptionText={errorDesc}
          open={openErrorModal}
          handleClose={() => setOpenErrorModal(false)}
          onPositiveClick={() => {
            setOpenErrorModal(false);
          }}
        />
      )}
      {listData && (
        <Box>
          <Box className="flex__justify__space-between mb-md">
            <MediumTypography
              labelId="accountsHeader"
              defaultLabel="Accounts"
              fontSize="20px"
            />
            <Box className="flex__" alignItems="center">
              <MediumTypography
                labelId="totalNumberOfRecords"
                defaultLabel="Total number of records"
                textColor="rgba(255, 255, 255, 0.64)"
                className="mr-xs"
              />
              <MediumTypography
                label={
                  isFilterApplied()
                    ? listData.totalRecords
                    : `${tableData.length} / ${listData.totalRecords}`
                }
                textColor="rgba(255, 255, 255, 0.64)"
              />
              <Box
                className="ml-md display-flex-center menu-item"
                onClick={handleMenuClick}
              >
                <Menu />
              </Box>
              <TableHeaderSelection
                data={listData.dataFields}
                anchorEl={filterOpen}
                handleClose={handleCloseMenu}
                open={open}
                onApply={handleApply}
                fixedItem="name"
              />
            </Box>
          </Box>
          <Box
            sx={{
              overflowY: "auto",
              height: availableHeight,
            }}
            className={"position__relative overflow_Y"}
          >
            <TableContainer
              component={Paper}
              sx={{
                width: availableWidth,
                height: tableData.length !== 0 ? availableHeight : "auto",
              }}
              className={"tableContainer"}
              // onScroll={handleScroll}
              ref={containerRef}
            >
              <Table>
                <CustomTableHeader
                  headerNames={dataFieldsWithIcons}
                  searchableKeys={accountSearchKeys}
                  stickyItems={"name"}
                  onSortApply={handleSort}
                  onCancelSearch={handleSearchCancel}
                  onSearchApply={handleSearchApply}
                  languageCode={selectedLanguageCode}
                />
                <TableBody>
                  {tableData.length !== 0 &&
                    tableData.map((row, rowIndex) => (
                      <TableRow
                        key={rowIndex}
                        sx={{
                          backgroundColor:
                            rowIndex % 2 === 0 ? "#2B3033" : "#22272B",
                        }}
                      >
                        {/* Sticky First Column */}
                        <TableCell
                          align={"left"}
                          sx={{
                            backgroundColor:
                              rowIndex % 2 === 0 ? "#2B3033" : "#22272B",
                          }}
                          className="stickyColumn"
                        >
                          <MediumTypography label={(rowIndex + 1).toString()} />
                        </TableCell>

                        {dataFieldsWithIcons.map((cell: HeadCell) => {
                          const cellValue = row[cell.key as keyof AccountData];

                          return (
                            <>
                              {cell.key === "lastUpdated" ? (
                                <TableCell
                                  key={cell.key}
                                  align={"left"}
                                  sx={{
                                    backgroundColor:
                                      rowIndex % 2 === 0
                                        ? "#2B3033"
                                        : "#22272B",
                                  }}
                                  className="p-md"
                                >
                                  <Box className="display-flex-center">
                                    <Avatar
                                      src={
                                        row.lastUpdated !== null &&
                                        row.lastUpdated !== undefined
                                          ? row.lastUpdated.imageUrl
                                          : ""
                                      }
                                      alt="abc"
                                      className="avatar-style"
                                    />
                                    <Box className="ml-sm">
                                      <MediumTypography
                                        label={
                                          row.lastUpdated !== null &&
                                          row.lastUpdated !== undefined
                                            ? row.lastUpdated.name
                                            : "-"
                                        }
                                        sxProps={{
                                          lineHeight: "normal",
                                        }}
                                        className="mt-xs"
                                      />
                                      <MediumTypography
                                        label={
                                          row.lastUpdated !== null &&
                                          row.lastUpdated !== undefined
                                            ? dayjs(
                                                row.lastUpdated.timestamp
                                              ).format("DD MMM YYYY HH:mm")
                                            : ""
                                        }
                                        fontSize="12px"
                                        textColor={"rgba(255, 255, 255, 0.64)"}
                                      />
                                    </Box>
                                  </Box>
                                </TableCell>
                              ) : (
                                <>
                                  {cell.key === "name" ? (
                                    <TableCell
                                      key={cell.key}
                                      align={"left"}
                                      sx={{
                                        backgroundColor:
                                          rowIndex % 2 === 0
                                            ? "#2B3033"
                                            : "#22272B",
                                      }}
                                      className="stickyColumn_left"
                                    >
                                      <MediumTypography
                                        label={
                                          cellValue !== undefined &&
                                          cellValue !== null &&
                                          cellValue !== ""
                                            ? cellValue.toString()
                                            : "-"
                                        }
                                      />
                                    </TableCell>
                                  ) : (
                                    <>
                                      {cell.key === "logoUrl" ? (
                                        <TableCell
                                          key={cell.key}
                                          align={"left"}
                                          sx={{
                                            backgroundColor:
                                              rowIndex % 2 === 0
                                                ? "#2B3033"
                                                : "#22272B",
                                          }}
                                          className="p-md"
                                        >
                                          {row.logoUrl !== undefined &&
                                          row.logoUrl !== null &&
                                          row.logoUrl !== "" ? (
                                            <img
                                              src={row.logoUrl}
                                              alt=""
                                              width={"32px"}
                                              height={"32px"}
                                            />
                                          ) : (
                                            <MediumTypography label={"-"} />
                                          )}
                                        </TableCell>
                                      ) : (
                                        <TableCell
                                          key={cell.key}
                                          align={"left"}
                                          sx={{
                                            backgroundColor:
                                              rowIndex % 2 === 0
                                                ? "#2B3033"
                                                : "#22272B",
                                          }}
                                          className="p-md"
                                        >
                                          <MediumTypography
                                            label={
                                              cellValue !== undefined &&
                                              cellValue !== null &&
                                              cellValue !== ""
                                                ? cellValue.toString()
                                                : "-"
                                            }
                                          />
                                        </TableCell>
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          );
                        })}

                        {/* Sticky Last Column */}
                        <TableCell
                          align={"left"}
                          sx={{
                            backgroundColor:
                              rowIndex % 2 === 0 ? "#2B3033" : "#22272B",
                          }}
                          className="stickyColumn_right"
                        >
                          <Tooltip title="More">
                            <IconButton
                              onClick={(event) => handleClick(event, rowIndex)}
                            >
                              <More />
                            </IconButton>
                          </Tooltip>
                          <Popover
                            anchorEl={anchorEl}
                            open={addEditOpen && selectedRow === rowIndex}
                            onClose={handleClose}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                            sx={{
                              "& .MuiPaper-root": {
                                backgroundColor: "#2B3033",
                              },
                            }}
                          >
                            <MenuItem
                              onClick={() => {
                                if (
                                  selectedLanguageCode === "en" &&
                                  hasPermission("Onboarding_Admin")
                                ) {
                                  handleEdit(row.id);
                                } else {
                                  return;
                                }
                              }}
                              style={{
                                backgroundColor: "2B3033",
                              }}
                              disabled={selectedLanguageCode !== "en"}
                            >
                              <MediumTypography
                                labelId="btn.edit"
                                defaultLabel="Edit"
                              />
                            </MenuItem>
                            <MenuItem
                              onClick={() =>
                                hasPermission("Onboarding_Admin")
                                  ? (setWarningModal(true),
                                    setModalAccountId(row.id))
                                  : () => {}
                              }
                              style={{ backgroundColor: "2B3033" }}
                            >
                              <MediumTypography
                                labelId="planner.deleteText"
                                defaultLabel="Delete"
                              />
                            </MenuItem>
                          </Popover>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            {tableData.length === 0 && (
              <Box
                sx={{
                  height: emptyTableHeight,
                }}
                className="display-flex-center flex__justify__center "
              >
                <MediumTypography
                  labelId="ManageAccounts.emptymsg"
                  defaultLabel="No records available"
                  fontSize="20px"
                  fontWeight={700}
                />
              </Box>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ManageAccounts;
