import { ForgotPasswordProps } from "../../pages/signIn/ForgotPassword";
import { ApiResponseHandler } from "../ApiResponseHandler";
import { USER_BASE_URL } from "../Constant";
import UserApiClient from "../UserApi";

export interface ChangePassword {
  verificationcode: string;
  email: string;
  password: string;
}
export interface loginRequest {
  empId: string;
  password: string;
}

export interface PreferredLanuageInterface {
  id: number;
  languageCode: string;
  language: string;
  implemented: boolean;
}

export interface loginResponse {
  id: number;
  userId: string;
  changePassword: boolean;
  session: string;
  accessToken: string;
  idToken: string;
  refreshToken: string;
  expiresIn: string;
  firstname: string;
  middlename: string;
  lastname: string;
  phoneNumber: string;
  groups: string[];
  email: string;
  preferredLanguage: PreferredLanuageInterface;
}
export interface ResetPasswordResponse {
  userId: string;
  changePassword: boolean;
  session: string;
  accessToken: string;
  idToken: string;
  refreshToken: string;
  expiresIn: string;
  firstName: string;
  middleName: string;
  lastName: string;
  phoneNumber: string;
  groups: string[];
}

export interface ResetPasswordRequest {
  username: string;
  password: string;
  session: string;
  newpassword: string;
}

export type Error = {
  severity: string;
  code: string;
  diagnostics: string;
};
export type ApiErrorResponse = {
  id: string;
  issue: Error[];
};

export type LogoutRequest = {
  username: string;
  refreshToken: string;
};

export interface RolesType {
  id: number;
  roleName: string;
  permission: PermissionType[];
}

export interface PermissionType {
  id: number;
  permissionName: string;
}

export interface RolesAndPermissions {
  id: number;
  userId: string;
  firstName: string;
  middleName: string;
  lastName: string;
  phone: string;
  email: string;
  status: string | null;
  profileImage: string;
  title: string;
  employeeId: string;
  userType: string | null;
  language: string;
  theme: string;
  themePreferenceStartTime: string | null;
  themePreferenceEndTime: string | null;
  accountId: number;
  dateOfBirth: string | null;
  dateOfJoining: string | null;
  termConditionAccepted: boolean;
  pin: string | null;
  temporaryPin: string | null;
  roles: RolesType[];
}

export interface ForgotPasswordResponse {
  code: number;
  message: string;
  status: string;
  password: boolean;
}

export const forgotPassword = async (
  body: ForgotPasswordProps
): Promise<ForgotPasswordResponse> => {
  return ApiResponseHandler(
    await UserApiClient.getInstance().post(`user/forgotPassword`, body)
  );
};

export const changePassword = async (body: ChangePassword) => {
  return ApiResponseHandler(
    await UserApiClient.getInstance().post("user/confirmcode", body)
  );
};

export const login = async (body: loginRequest): Promise<loginResponse> => {
  return ApiResponseHandler(
    await UserApiClient.getInstance().post<loginRequest, loginResponse>(
      "user/signin",
      body
    )
  );
};

export const resetPassword = async (body: ResetPasswordRequest) => {
  return ApiResponseHandler(
    await UserApiClient.getInstance().post<
      ResetPasswordRequest,
      ResetPasswordResponse
    >("user/newpassword", body)
  );
};

export const signOutUser = async (body: LogoutRequest) => {
  return ApiResponseHandler(
    await UserApiClient.getInstance().post("user/signOut", body, {
      baseURL: USER_BASE_URL,
      headers: {
        Authorization: `${localStorage.getItem("accessToken")}`,
      },
    })
  );
};

export const getUserProfile = async (
  userId: number
): Promise<RolesAndPermissions> => {
  return ApiResponseHandler(
    await UserApiClient.getInstance().get(`user/${userId}`)
  );
};
