import { Box, Radio, Tooltip } from "@mui/material";
import MediumTypography from "../../../components/formlib/MediumTypography";
import { FC, useEffect, useMemo, useState } from "react";
import SearchBox from "../../../components/formlib/SearchBox";
import ButtonComponent from "../../../components/formlib/ButtonComponent";
import { ReactComponent as DownArrow } from "../../../assets/images/DownArrowBlue.svg";
import { ReactComponent as DownArrowWhite } from "../../../assets/images/downarrow2.svg";
import { ReactComponent as RightArrow } from "../../../assets/images/RightArrowBlue.svg";
import { AssetCategory, AssetGroup } from "./types";
import CheckBox from "../../../components/formlib/CheckBox";
import { useIntl } from "react-intl";
import { CustomMenu } from "../../../components/formlib/CustomMenu";

interface AssetProps {
  AssetData: AssetGroup[];
  addButton?: boolean;
  assetCallBack: (
    assetSelected: number[],
    selecetdAssetGroup: number,
    selectedAssetCategories: number
  ) => void;
  selectedAssetGroupsParent: number | null;
  selectedAssetsParent: number[] | [];
  selectedAssetCategoryParent: number | null;
}

const AssetGroupCategoryDropDown: FC<AssetProps> = ({
  AssetData,
  addButton,
  assetCallBack,
  selectedAssetGroupsParent,
  selectedAssetsParent,
  selectedAssetCategoryParent,
}) => {
  const MemorizedCustomMenu = useMemo(() => CustomMenu, []);
  const [assetAnchorEl, setAssetAnchorEl] = useState<null | HTMLElement>(null);
  const openitAsset = Boolean(assetAnchorEl);
  const [searchQuery, setSearchQuery] = useState("");

  const [selectedAssetGroupId, setSelectedAssetGroupId] = useState<
    number | null
  >(null);
  const [selectedAssetCategoryId, setSelectedAssetCategoryId] = useState<
    number | null
  >(null);
  const [expandedGroupId, setExpandedGroupId] = useState<number | null>(null);

  const [expandedCategories, setExpandedCategories] = useState<number[]>([]);
  const [selectedAssets, setSelectedAssets] = useState<number[]>([]);

  const intl = useIntl();
  const [assetCategoryName, setAssetCategoryName] = useState<string>("");

  useEffect(() => {
    if (AssetData && AssetData.length > 0) {
      const group = AssetData.find(
        (group) => group.assetGroupId === selectedAssetGroupId
      );

      // Ensure group is defined before accessing assetCategories
      if (group) {
        const category = group.assetCategories.find(
          (category) => category.assetCategoryId === selectedAssetCategoryId
        );

        // Handle category existence
        if (category) {
          setAssetCategoryName(category.assetCategoryName);
        } else {
          setAssetCategoryName("");
        }
      }
    } else {
      // Handle case where AssetData is not yet defined or empty
      setAssetCategoryName("");
    }
  }, [AssetData, selectedAssetGroupId, selectedAssetCategoryId]);

  useEffect(() => {
    setSelectedAssetGroupId(selectedAssetGroupsParent);
    setSelectedAssetCategoryId(selectedAssetCategoryParent);
    setSelectedAssets(selectedAssetsParent);
  }, [
    selectedAssetGroupsParent,
    selectedAssetCategoryParent,
    selectedAssetsParent,
  ]);

  const handleAssetDropdown = (event: React.MouseEvent<HTMLElement>) => {
    setAssetAnchorEl(event.currentTarget);
  };

  const handleAssetDropdownClose = () => {
    // Step 3: Only reset if the apply button has not been clicked
    setSelectedAssetGroupId(selectedAssetGroupsParent);
    setSelectedAssetCategoryId(selectedAssetCategoryParent);
    setSelectedAssets(selectedAssetsParent);
    setAssetAnchorEl(null);
  };

  const handleSearchChange = (value: string) => {
    setSearchQuery(value);
    if (value.trim() === "") {
      setExpandedGroupId(null);
      setExpandedCategories([]);
      return; // Return early since there's nothing more to do
    }

    // Automatically expand the groups or categories based on the search term
    const lowerCaseValue = value.toLowerCase();

    // Find the first matching group and category if the search term matches an asset name
    const matchingAsset = AssetData.reduce<{
      group: AssetGroup;
      category: AssetCategory;
    } | null>((acc, group) => {
      const foundCategory = group.assetCategories.find((category) =>
        category.assets.some((asset) =>
          asset.name.toLowerCase().includes(lowerCaseValue)
        )
      );
      if (foundCategory && !acc) {
        return { group, category: foundCategory };
      }
      return acc;
    }, null); // The initial value is null, which matches the type of the accumulator.

    // Find the first matching group if the search term matches a category name
    const matchingCategory = AssetData.find((group) =>
      group.assetCategories.some((category) =>
        category.assetCategoryName.toLowerCase().includes(lowerCaseValue)
      )
    )?.assetCategories.find((category) =>
      category.assetCategoryName.toLowerCase().includes(lowerCaseValue)
    );

    // Find the first matching group if the search term matches a group name
    const matchingGroup = AssetData.find((group) =>
      group.assetGroupName.toLowerCase().includes(lowerCaseValue)
    );

    if (matchingAsset) {
      // Expand the matching group and category
      setExpandedGroupId(matchingAsset.group.assetGroupId);
      setExpandedCategories([matchingAsset.category.assetCategoryId]);
    } else if (matchingCategory) {
      // Expand the matching category
      setExpandedCategories([matchingCategory.assetCategoryId]);
    } else if (matchingGroup) {
      // Expand the matching group and all its categories
      setExpandedGroupId(matchingGroup.assetGroupId);
      setExpandedCategories(
        matchingGroup.assetCategories.map(
          (category) => category.assetCategoryId
        )
      );
    }
  };

  const filterData = (filteredData: AssetGroup[]) => {
    return filteredData
      .filter((assetGroup) => {
        const assetGroupNameMatches = assetGroup.assetGroupName
          .toLowerCase()
          .includes(searchQuery.toLowerCase());
        const assetCategoriesMatch = assetGroup.assetCategories.some(
          (category) => {
            const assetCategoriesNameMatches = category.assetCategoryName
              .toLowerCase()
              .includes(searchQuery.toLowerCase());
            const assetsMatch = category.assets.some((asset) =>
              asset.name.toLowerCase().includes(searchQuery.toLowerCase())
            );
            return assetCategoriesNameMatches || assetsMatch;
          }
        );
        return assetGroupNameMatches || assetCategoriesMatch;
      })
      .map((assetGroups) => ({
        ...assetGroups,
        assetCategories: assetGroups.assetCategories
          .filter((category) => {
            const assetCategoriesNameMatches = category.assetCategoryName
              .toLowerCase()
              .includes(searchQuery.toLowerCase());
            return assetCategoriesNameMatches;
          })
          .map((categories) => ({
            ...categories,
            assets: categories.assets, // Don't filter out assets within a matching category
          })),
      }));
  };

  const filteredData: AssetGroup[] = filterData(AssetData);

  const handleAssetGroupSelection = (groupId: number) => {
    setSelectedAssetGroupId(groupId);
    setExpandedGroupId(groupId);
    setSelectedAssets([]);
    setSelectedAssetCategoryId(null);
    setExpandedCategories([]);
  };
  const handleCategorySelection = (categoryId: number) => {
    const groupToSelect = AssetData.find((group) =>
      group.assetCategories.some(
        (category) => category.assetCategoryId === categoryId
      )
    )?.assetGroupId;

    setSelectedAssetCategoryId(categoryId);
    setExpandedCategories([categoryId]); // Collapse any previously expanded categories

    if (groupToSelect) {
      setSelectedAssetGroupId(groupToSelect);
    }

    setSelectedAssets([]);
  };

  const handleAssetSelection = (assetId: number) => {
    setSelectedAssets((prevSelectedAssets) => {
      const isAlreadySelected = prevSelectedAssets.includes(assetId);
      if (isAlreadySelected) {
        // If the asset is already selected, remove it from the selectedAssets array
        return prevSelectedAssets.filter((id) => id !== assetId);
      } else {
        // If the asset is not selected, add it to the selectedAssets array
        return [...prevSelectedAssets, assetId];
      }
    });

    // Find the category and group that the asset belongs to
    let categoryToSelect: number | null = null;
    let groupToSelect: number | null = null;

    for (const group of AssetData) {
      for (const category of group.assetCategories) {
        if (category.assets.some((asset) => asset.id === assetId)) {
          categoryToSelect = category.assetCategoryId;
          groupToSelect = group.assetGroupId;
          break;
        }
      }
      if (categoryToSelect !== null) {
        break;
      }
    }

    // Auto-select the category and group if they are not already selected
    if (categoryToSelect !== null) {
      setSelectedAssetCategoryId(categoryToSelect);
      setExpandedCategories([categoryToSelect]); // Also make sure to expand the selected category
    }
    if (groupToSelect !== null) {
      setSelectedAssetGroupId(groupToSelect);
      setExpandedGroupId(groupToSelect); // Also make sure to expand the selected group
    }
  };

  // Find assetNames
  const assetName = useMemo(
    () =>
      selectedAssets
        .map((assetId) => {
          for (const group of AssetData) {
            for (const category of group.assetCategories) {
              const asset = category.assets.find(
                (asset) => asset.id === assetId
              );
              if (asset) {
                return asset.name;
              }
            }
          }
          return null;
        })
        .filter((name) => name !== null),
    [AssetData, selectedAssets]
  );

  return (
    <Box>
      {!addButton ? (
        <Box
          className="cursor__pointer"
          sx={{
            border: "1px solid rgba(166, 197, 226, 0.16)",
            width: "500px",
            height: "42px",
            borderRadius: "4px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: "#22272B",
          }}
          onClick={handleAssetDropdown}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {selectedAssetGroupId &&
            selectedAssetCategoryId &&
            selectedAssets.length > 0 ? (
              <>
                <Tooltip
                  title={
                    assetCategoryName && assetCategoryName.length > 12
                      ? assetCategoryName
                      : ""
                  }
                  arrow={true}
                  followCursor
                >
                  <Box>
                    <MediumTypography
                      label={assetCategoryName}
                      sxProps={{
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "#FFFFFF",
                        marginLeft: "8px",
                        marginRight: "8px",
                        width:
                          assetCategoryName && assetCategoryName.length > 12
                            ? "100px"
                            : "auto",
                      }}
                      className="ellipsis"
                    />
                  </Box>
                </Tooltip>
                <MediumTypography label=" | " className="mr-xs" />
                {/* Map through the first two selected assets and display their names */}
                {assetName.slice(0, 2).map((name, index) => {
                  return (
                    <>
                      <Tooltip
                        title={name && name.length > 12 ? name : ""}
                        arrow={true}
                        followCursor
                        key={index}
                      >
                        <Box>
                          <MediumTypography
                            label={name || ""}
                            sxProps={{
                              fontSize: "14px",
                              fontWeight: "400",
                              color: "#FFFFFF",
                              marginLeft: index === 0 ? "8px" : "4px",
                              width:
                                name && name.length > 12 ? "100px" : "auto",
                            }}
                            className="ellipsis"
                          />
                        </Box>
                      </Tooltip>
                    </>
                  );
                })}
              </>
            ) : (
              <MediumTypography
                label={"Select Assets"}
                sxProps={{
                  fontSize: "14px",
                  fontWeight: "400",
                  color: "#FFFFFF",
                  opacity: "0.6",
                  lineHeight: "20px",
                  padding: "8px",
                }}
              />
            )}
          </Box>
          {selectedAssets.length > 2 && (
            <Box
              sx={{
                backgroundColor: "rgba(159, 173, 188, 0.2)",
                padding: "4px",
                borderRadius: "36px",
              }}
            >
              <MediumTypography
                label={`+${selectedAssets.length - 2}  ${intl.formatMessage({
                  id: "AssetsTextLabel",
                  defaultMessage: "ASSETS",
                })}`}
                sxProps={{
                  fontSize: "14px",
                  fontWeight: "400",
                  color: "#9FADBC",
                  marginLeft: "4px",
                  lineHeight: "normal",
                }}
              />
            </Box>
          )}
          <DownArrowWhite
            className="position__relative cursor__pointer"
            style={{ right: "10px" }}
          />
        </Box>
      ) : (
        <Box onClick={handleAssetDropdown}>
          <MediumTypography
            labelId="Add"
            defaultLabel="Add"
            sxProps={{
              marginBottom: "10px",
              marginRight: "10px",
              cursor: "pointer",
            }}
          />
        </Box>
      )}
      <MemorizedCustomMenu
        id="demo-customized-menu"
        anchorEl={assetAnchorEl}
        open={openitAsset}
        maxWidth={500}
        minWidth={480}
        left={-20}
        onClose={handleAssetDropdownClose}
        sx={{ marginLeft: "20px" }}
      >
        <Box
          sx={{ minWidth: "400px", display: "flex", flexDirection: "column" }}
        >
          {addButton && (
            <MediumTypography
              labelId="dropdownTitleAssets"
              defaultLabel="select assets to add"
              className="mb-xs ml-lg"
              textColor="rgba(159, 173, 188, 1)"
            />
          )}
          <Box sx={{ alignSelf: "center", margin: "8px" }}>
            <SearchBox
              labelId="defaultSearch"
              defaultlabel="Search"
              backgroundColor="#22272B"
              sxProps={{ minWidth: "350px !important", marginLeft: "16px" }}
              onChange={handleSearchChange}
            />

            <ul className="no-marker">
              {filteredData.map((assetGroupItems: AssetGroup) => {
                return (
                  <li key={assetGroupItems.assetGroupId}>
                    <Box
                      className="flex__"
                      sx={{
                        padding: "8px",
                        alignItems: "center",
                        gap: "8px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        handleAssetGroupSelection(assetGroupItems.assetGroupId);
                      }}
                    >
                      {expandedGroupId ? (
                        <DownArrow
                          onClick={(e) => {
                            e.stopPropagation();
                            setExpandedGroupId(null);
                          }}
                        />
                      ) : (
                        <RightArrow
                          onClick={(e) => {
                            e.stopPropagation();
                            setExpandedGroupId(assetGroupItems.assetGroupId);
                          }}
                        />
                      )}
                      <Radio
                        checked={
                          selectedAssetGroupId === assetGroupItems.assetGroupId
                        }
                        onClick={(e) => {
                          e.stopPropagation();
                          handleAssetGroupSelection(
                            assetGroupItems.assetGroupId
                          );
                        }}
                        sx={{ color: "#FFFFFF" }}
                      />
                      <Box className="flex__" alignItems="center">
                        <MediumTypography
                          label={assetGroupItems.assetGroupName}
                          fontSize="14px"
                          textColor="#ffffff"
                        />
                      </Box>
                    </Box>
                    {assetGroupItems.assetCategories.map(
                      (categories: AssetCategory) => {
                        const isCategoryExpanded = expandedCategories.includes(
                          categories.assetCategoryId
                        );
                        return (
                          expandedGroupId && (
                            <ul
                              key={categories.assetCategoryId}
                              className="no-marker"
                            >
                              <li className="listItem">
                                <Box
                                  className="flex__"
                                  sx={{
                                    padding: "8px",
                                    alignItems: "center",
                                    gap: "8px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    handleCategorySelection(
                                      categories.assetCategoryId
                                    );
                                  }}
                                >
                                  {isCategoryExpanded ? (
                                    <DownArrow
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        setExpandedCategories(
                                          (prevExpandedCategories) =>
                                            prevExpandedCategories.filter(
                                              (id) =>
                                                id !==
                                                categories.assetCategoryId
                                            )
                                        );
                                      }}
                                    />
                                  ) : (
                                    <RightArrow
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        setExpandedCategories(
                                          (prevExpandedCategories) => [
                                            ...prevExpandedCategories,
                                            categories.assetCategoryId,
                                          ]
                                        );
                                      }}
                                    />
                                  )}
                                  <Radio
                                    checked={
                                      selectedAssetCategoryId ===
                                      categories.assetCategoryId
                                    }
                                    onClick={(e) => {
                                      e.stopPropagation();

                                      handleCategorySelection(
                                        categories.assetCategoryId
                                      );
                                    }}
                                    sx={{ color: "#FFFFFF" }}
                                  />
                                  <Box className="flex__" alignItems="center">
                                    <MediumTypography
                                      label={categories.assetCategoryName}
                                      fontSize="14px"
                                      textColor="#ffffff"
                                    />
                                  </Box>
                                </Box>
                                {categories.assets.map(
                                  (asset) =>
                                    isCategoryExpanded && (
                                      <ul key={asset.id} className="no-marker">
                                        <li
                                          className="listItem"
                                          style={{ paddingLeft: "40px" }}
                                        >
                                          <Box
                                            className="flex__"
                                            sx={{
                                              padding: "8px",
                                              alignItems: "center",
                                              gap: "8px",
                                              cursor: "pointer",
                                            }}
                                          >
                                            <Box
                                              className="flex__"
                                              alignItems="center"
                                            >
                                              <CheckBox
                                                value={selectedAssets.includes(
                                                  asset.id
                                                )}
                                                onCheckBoxClick={() =>
                                                  handleAssetSelection(asset.id)
                                                }
                                              />
                                              <MediumTypography
                                                label={asset.name}
                                                fontSize="14px"
                                                textColor="#ffffff"
                                              />
                                            </Box>
                                          </Box>
                                        </li>
                                      </ul>
                                    )
                                )}
                              </li>
                            </ul>
                          )
                        );
                      }
                    )}
                  </li>
                );
              })}
            </ul>
          </Box>
          {addButton ? (
            <Box
              className="width__100 flex__ p-md"
              sx={{
                position: "sticky",
                bottom: 0,
                zIndex: 1,
                backgroundColor: "#1D2125",
                justifyContent: "flex-end",
              }}
            >
              <ButtonComponent
                sxProps={{ color: "#B6C2CF !important" }}
                className="btn-primary btn-cancel mr-md"
                variantType="contained"
                defaultLabelId={"Cancel"}
                labelId={"btn.cancel"}
                onClick={handleAssetDropdownClose}
              />
              <ButtonComponent
                className="btn-primary btn-submit"
                labelId="btn.save"
                defaultLabelId="Save"
                sxProps={{ width: "100px" }}
                disabled={
                  selectedAssets.length < 0 || filteredData.length === 0
                }
                onClick={() => {
                  if (selectedAssetCategoryId && selectedAssetGroupId) {
                    assetCallBack(
                      selectedAssets,
                      selectedAssetGroupId,
                      selectedAssetCategoryId
                    );
                  }

                  setAssetAnchorEl(null);
                }}
              />
            </Box>
          ) : (
            <Box
              className="width__100 flex__container p-md"
              sx={{
                position: "sticky",
                bottom: 0,
                zIndex: 1,
                backgroundColor: "#1D2125",
              }}
            >
              <ButtonComponent
                className="btn-primary btn-submit"
                labelId="Apply"
                defaultLabelId="Apply"
                sxProps={{ width: "100px" }}
                disabled={
                  selectedAssets.length < 0 || filteredData.length === 0
                }
                onClick={() => {
                  if (selectedAssetCategoryId && selectedAssetGroupId) {
                    assetCallBack(
                      selectedAssets,
                      selectedAssetGroupId,
                      selectedAssetCategoryId
                    );
                  }

                  setAssetAnchorEl(null);
                }}
              />
            </Box>
          )}
        </Box>
      </MemorizedCustomMenu>
    </Box>
  );
};

export default AssetGroupCategoryDropDown;
