import WorkFlowConfig from "../WorkFlowConfig";
import { ApiMessage, ApiResponseHandler } from "../ApiResponseHandler";
import { AssetCategoryType } from "../../pages/wizards/types/assetGroupTypes";
import { CommonOptionType } from "../../utils/type";
import { MapType } from "../../pages/wizards/user/users";
import { SiteDetailsTypes } from "../../pages/wizards/wizrads.types";
import { FeatureCollectionType } from "../../pages/planner/plannerTypes";

export type MappedCommonTypes = AssetCategoryType | MapType;

export interface ValidateApiResponse {
  exportBeanList: WizardsDataTypes[];
  errorMessages: string[];
}

export type KMLresponseType = {
  message: string | FeatureCollectionType;
  status: string;
};

export type WizardsDataTypes = SiteDetailsTypes;

export const fetchAllWizardsDataApi = async <T>(
  uri: string,
  language: string,
  filterQuery: string
): Promise<T> => {
  return ApiResponseHandler(
    await WorkFlowConfig.getInstance().get(
      `${uri}/fetchAll?languageCode=${language}&${filterQuery}`
    )
  );
};
export const fetchAllShiftsDataApi = async <T>(
  uri: string,
  language: string,
  filterQuery: string,
  status: string
): Promise<T> => {
  return ApiResponseHandler(
    await WorkFlowConfig.getInstance().get(
      `${uri}/fetchAll?statusType=${status}&languageCode=${language}&${filterQuery}`
    )
  );
};

export const fetchWizardsDataApi = async <T>(
  uri: string,
  id?: number,
  languageCode?: string
): Promise<T> => {
  if (id) {
    return ApiResponseHandler(
      await WorkFlowConfig.getInstance().get(
        `${uri}/${id}/fetch?languageCode=${languageCode}`
      )
    );
  }

  return ApiResponseHandler(
    await WorkFlowConfig.getInstance().get(
      `${uri}/metadata?languageCode=${languageCode}`
    )
  );
};

export const fetchWizardsMenuMetaDataApi = async <T>(
  uri: string,
  languageCode?: string
): Promise<T> => {
  return ApiResponseHandler(
    await WorkFlowConfig.getInstance().get(
      `${uri}/metadata?languageCode=${languageCode}`
    )
  );
};

export const SaveWizardsApi = async <T>(
  uri: string,
  params: T,
  isVersionRequired?: boolean,
  id?: number
) => {
  if (id) {
    return ApiResponseHandler(
      await WorkFlowConfig.getInstance().put<T, T>(
        `${uri}/${id}/update?isVersionRequired=${isVersionRequired}`,
        params
      )
    );
  }

  return ApiResponseHandler(
    await WorkFlowConfig.getInstance().post<T, T>(`${uri}/save`, params)
  );
};

export const customUpdateDataApi = async <T>(fullUri: string, params: T) => {
  return ApiResponseHandler(
    await WorkFlowConfig.getInstance().put<T, T>(fullUri, params)
  );
};

export const customSaveDataApi = async <T>(fullUri: string, params: T) => {
  return ApiResponseHandler(
    await WorkFlowConfig.getInstance().post<T, T>(fullUri, params)
  );
};

export const deleteWizardsDataApi = async (uri: string, id: number) => {
  return ApiResponseHandler(
    await WorkFlowConfig.getInstance().delete(`${uri}/${id}/delete`)
  );
};

export const uploadWizardsCSVFile = async (
  fileName: string,
  language: string,
  params: WizardsDataTypes[],
  name: string,
  isVersionRequired: boolean
): Promise<ApiMessage> => {
  return ApiResponseHandler(
    await WorkFlowConfig.getInstance().post(
      `${name}/import?languageCode=${language}&fileName=${fileName}&isVersionRequired=${isVersionRequired}`,
      params
    )
  );
};

export const validateWizradsCSVFileApi = async (
  csvFile: File | null,
  language: string,
  name: string
): Promise<ValidateApiResponse> => {
  const formData = new FormData();

  if (csvFile !== null) {
    formData.append(
      "file",
      new File([csvFile], csvFile.name.toLowerCase(), {
        type: "text/csv",
      })
    );
  } else {
    formData.append("file", "");
  }
  return ApiResponseHandler(
    await WorkFlowConfig.getInstance().post(
      `${name}/validateFile?languageCode=${language}`,
      formData
    )
  );
};

export const createDropdownOptionApi = async (
  fieldType: string,
  fieldName: string,
  option: string
): Promise<CommonOptionType[]> => {
  return ApiResponseHandler(
    await WorkFlowConfig.getInstance().post(
      `autoSaveFields?field=${fieldType}&${fieldName}=${option}`
    )
  );
};

export const publishShiftMaster = async (subgroupId: number) => {
  return ApiResponseHandler(
    await WorkFlowConfig.getInstance().patch<
      CommonOptionType,
      CommonOptionType
    >(`${subgroupId}/shift/publish`)
  );
};

export const fetchAssetsWizardsDataApi = async <T>(
  uri: string,
  id?: number,
  languageCode?: string
): Promise<T> => {
  if (id) {
    return ApiResponseHandler(
      await WorkFlowConfig.getInstance().get(
        `${uri}/${id}/asset/fetch?languageCode=${languageCode}`
      )
    );
  }

  return ApiResponseHandler(
    await WorkFlowConfig.getInstance().get(
      `${uri}/metadata?languageCode=${languageCode}`
    )
  );
};

export const fetchKmlData = async <T>(
  uri: string,
  languageCode?: string
): Promise<T> => {
  return ApiResponseHandler(
    await WorkFlowConfig.getInstance().get(
      `${uri}/kml/fetch?languageCode=${languageCode}`
    )
  );
};

export const kmlUploadApi = async (
  csvFile: File | null,
  language: string,
  assetGroupId: number
): Promise<ValidateApiResponse> => {
  const formData = new FormData();

  if (csvFile !== null) {
    formData.append(
      "file",
      new File([csvFile], csvFile.name.toLowerCase(), {
        type: "text/geojson",
      })
    );
  } else {
    formData.append("file", "");
  }
  console.log("formdata", formData);
  return ApiResponseHandler(
    await WorkFlowConfig.getInstance().post(
      `${assetGroupId}/kml/upload?languageCode=${language}`,
      formData
    )
  );
};
