// utils/tableUtils.ts

import { AccountData } from "./types/accountTypes";
import { AnomalyRecord } from "./types/anomalyTypes";
import { AssetCategory } from "./types/assetCategoryTypes";
import { ChecklistLineItemRecordType } from "./types/checklistLineItemTypes";
import { ChecklistRecordType } from "./types/checklistTypes";
import { FrequencyRecordType } from "./types/frequencyTypes";
import { Inventory } from "./types/inventoryTypes";
import { PermitLineItem } from "./types/permitLineItemTypes";
import { ManageMapChecklistRecordType } from "./types/mapChecklistTypes";
import { PermitRecord } from "./types/permitTypes";
import { ReasonForDeviationRecord } from "./types/reasonForDeviation";
import { SkillRecordType } from "./types/skillTypes";
import { DepartmentRecordType } from "./types/departmentTypes";
import { UserRecord } from "../wizards/user/users";

import { AssetWizardsType } from "../wizards/types/assetTypes";
import { HolidayRecordType } from "../wizards/types/holidayTypes";

// Utility function for sorting table data
export type MasterDataManagementType =
  | AccountData
  | AssetCategory
  | AnomalyRecord
  | Inventory
  | ChecklistRecordType
  | ManageMapChecklistRecordType
  | ChecklistLineItemRecordType
  | SkillRecordType
  | FrequencyRecordType
  | PermitLineItem
  | PermitRecord
  | ReasonForDeviationRecord
  | DepartmentRecordType
  | UserRecord
  | AssetWizardsType
  | HolidayRecordType;

export const sortTableData = (
  data: MasterDataManagementType[],
  columnKey: string,
  direction: string,
  originalData: MasterDataManagementType[]
): MasterDataManagementType[] => {
  if (direction) {
    return [...data].sort((a, b) => {
      let aValue: any = a[columnKey as keyof MasterDataManagementType] ?? "";
      let bValue: any = b[columnKey as keyof MasterDataManagementType] ?? "";

      if (columnKey === "pincode") {
        aValue = Number(aValue);
        bValue = Number(bValue);
      } else if (
        typeof aValue === "object" &&
        typeof bValue === "object" &&
        "timestamp" in aValue &&
        "timestamp" in bValue
      ) {
        aValue = (aValue as any).timestamp;
        bValue = (bValue as any).timestamp;
      } else if (typeof aValue === "string" && typeof bValue === "string") {
        aValue = aValue.toLowerCase();
        bValue = bValue.toLowerCase();
      }

      if (direction === "asc") {
        if (aValue > bValue) return 1;
        if (aValue < bValue) return -1;
        return 0;
      } else {
        if (aValue > bValue) return -1;
        if (aValue < bValue) return 1;
        return 0;
      }
    });
  } else {
    // Reset to the original table data
    return originalData;
  }
};
// Utility function for applying search filters

export const applySearchFilters = (
  data: MasterDataManagementType[],
  filters: { [key: string]: string }
): MasterDataManagementType[] => {
  let filteredData = [...data];
  for (const key in filters) {
    filteredData = filteredData.filter((row) => {
      const cellValue = row[key as keyof MasterDataManagementType];
      return (
        cellValue != null &&
        String(cellValue).toLowerCase().includes(filters[key].toLowerCase())
      );
    });
  }
  return filteredData;
};

// Utility function for canceling search filters
export const cancelSearchFilters = (
  data: MasterDataManagementType[],
  activeFilters: { [key: string]: string },
  filterKey: string
): {
  filteredData: MasterDataManagementType[];
  updatedFilters: { [key: string]: string };
} => {
  const updatedFilters = { ...activeFilters };
  delete updatedFilters[filterKey];

  const isEmptyFilters = Object.keys(updatedFilters).length === 0;
  const filteredData = isEmptyFilters
    ? data
    : applySearchFilters(data, updatedFilters);

  return { filteredData, updatedFilters };
};
