import { Box, Button, Card, CardContent } from "@mui/material";
import React from "react";
import MediumTypography from "./MediumTypography";
import { ReactComponent as EnquireIcon } from "../../assets/images/enquire.svg";
import { ReactComponent as ProfilePhoto } from "../../assets/images/ProfilePicture.svg";
import { ReactComponent as ErrorIcon } from "../../assets/images/ErrorIcon.svg";
import { ReactComponent as DispatchIcon } from "../../assets/images/DispatchIcon.svg";
import UserStack from "./UserStack";
import { PlannerCardDetailsType } from "../../pages/planner/plannerTypes";

type CardProps = {
  cardDataArray: PlannerCardDetailsType;
};

const CardComponentSidebar: React.FC<CardProps> = ({ cardDataArray }) => {
  let btnBg = "rgba(24, 106, 222, 1)";
  let textColor = cardDataArray.statusCode === "ONHOLD" ? "#1C2B36" : "#FFFFFF";
  let statusIcon = <></>;
  let btnText = cardDataArray.status;

  if (cardDataArray.statusCode === "ONHOLD") {
    btnBg = "rgba(245, 197, 24, 1)";
  } else if (cardDataArray.statusCode === "EXPIRED") {
    btnBg = "rgba(91, 114, 130, 1)";
  } else if (cardDataArray.statusCode === "REJECTED") {
    btnBg = "rgba(217, 31, 17, 1)";
  } else if (cardDataArray.statusCode === "PAUSED") {
    btnBg = "rgba(24, 106, 222, 1)";
  } else if (
    cardDataArray.statusCode === "SCHEDULED" ||
    cardDataArray.statusCode === "DISPATCHED"
  ) {
    btnBg = "rgba(7, 125, 85, 1)";
  } else if (cardDataArray.statusCode === "ERROR") {
    btnBg = "rgba(244, 71, 57, 0.2)";
    textColor = "rgba(244, 71, 57, 1)";
    statusIcon = <ErrorIcon style={{ width: "20px", height: "20px" }} />;
  } else if (cardDataArray.statusCode === "SUCCESS") {
    btnBg = "rgba(28, 51, 41, 1)";
    textColor = "rgba(42, 187, 127, 1)";
    statusIcon = <DispatchIcon style={{ width: "20px", height: "20px" }} />;
  } else if (cardDataArray.statusCode === "WARNING") {
    btnBg = "rgba(245, 197, 24, 1)";
    textColor = "#1D2125";
    statusIcon = <ErrorIcon style={{ width: "20px", height: "20px" }} />;
  }

  return (
    <Card
      style={{
        borderRadius: "4px",
        boxShadow: "none",
        backgroundColor: "#282d33",
        top: "0px",
        left: "0px",
      }}
    >
      <CardContent sx={{ mb: "-5px" }}>
        <Box className="flex__">
          <Button
            className={` flex__`}
            sx={{
              height: "32px",
              p: 0,
              minWidth: "inherit",
              textTransform: "none",
            }}
          >
            <Box className="tabs_scroller_child">
              <MediumTypography
                label={cardDataArray.code}
                textColor={cardDataArray.code === "CM" ? "#FF453A" : "#B8ACF6"}
                sxProps={{ textTransform: "none" }}
              />
            </Box>
          </Button>
          <Box sx={{ pt: "5px", pr: "5px" }}>
            <MediumTypography
              label={cardDataArray.sequenceId.toString()}
              textColor="#FFFFFF"
              sxProps={{
                textTransform: "none",
              }}
            />
          </Box>
          <Box>
            <Box
              sx={{
                mt: "5px",
                width: "0px",
                height: "20px",
                borderLeft: "2px solid #FFFFFF",
                padding: "4px 0px",
              }}
            ></Box>
          </Box>
          <Box sx={{ padding: "5px 8px" }}>
            <Box display={"flex"} className="ellipsis-item-container">
              <MediumTypography
                className="card-wo-routine"
                label={cardDataArray.frequency}
              />
            </Box>
          </Box>
          <Box sx={{ padding: "7px 8px", marginLeft: "auto" }}>
            <Box className="userStack">
              {cardDataArray.members && (
                <UserStack users={cardDataArray.members} showMax={4} />
              )}
            </Box>
          </Box>
        </Box>
        <Box className="flex__">
          <Box sx={{ padding: "4px 4px", mb: "8px" }}>
            <MediumTypography
              label={cardDataArray.description}
              textColor="rgba(255, 255, 255, 0.72)"
              sxProps={{
                textTransform: "none",
              }}
            />
          </Box>
        </Box>
        <Box
          className="button-boxes flex__"
          flexWrap={"wrap"}
          sx={{ gap: "8px", padding: "0px 0px" }}
        >
          {cardDataArray.processedBy && cardDataArray.processedOn && (
            <Box
              className="flex__"
              sx={{
                padding: "4px 8px 4px 8px",
                borderRadius: "3px",
                backgroundColor: "rgba(161, 189, 217, 0.08)",
              }}
            >
              <ProfilePhoto />
              <Box className="flex__ mr-xs">
                <MediumTypography
                  labelId={`${
                    cardDataArray.statusCode === "LEVEL1" ||
                    cardDataArray.statusCode === "LEVEL2" ||
                    cardDataArray.statusCode === "LEVEL3"
                      ? "Approved By"
                      : cardDataArray.statusCode === "REJECTED"
                      ? "Rejected By"
                      : "Last Updated By"
                  }`}
                  defaultLabel={`${
                    cardDataArray.statusCode === "LEVEL1" ||
                    cardDataArray.statusCode === "LEVEL2" ||
                    cardDataArray.statusCode === "LEVEL3"
                      ? "Approved By"
                      : cardDataArray.statusCode === "REJECTED"
                      ? "Rejected By"
                      : "Last Updated By"
                  }`}
                  sxProps={{
                    textTransform: "none",
                    position: "relative",
                    left: "4px",
                    textWrap: "nowrap",
                  }}
                />
              </Box>

              <MediumTypography
                label={`${cardDataArray.processedBy} | ${cardDataArray.processedOn}`}
                textColor="rgba(255, 255, 255, 0.72)"
                sxProps={{
                  textTransform: "none",
                  position: "relative",
                  left: "4px",
                  textWrap: "nowrap",
                }}
              />
            </Box>
          )}

          {cardDataArray.processedCount > 0 && (
            <Box
              className="flex__"
              sx={{
                padding: "4px 8px 4px 8px",
                borderRadius: "3px",
                backgroundColor: "rgba(161, 189, 217, 0.08)",
              }}
            >
              <EnquireIcon style={{ width: "20px", height: "20px" }} />
              <MediumTypography
                label={cardDataArray.processedCount?.toString()}
                textColor="rgba(255, 255, 255, 0.72)"
                sxProps={{
                  ml: "4px",
                  textTransform: "none",
                  textWrap: "nowrap",
                }}
              />
            </Box>
          )}

          <Box
            className="flex__"
            sx={{
              padding: "4px 8px 4px 8px",
              borderRadius: "3px",
              backgroundColor: `${btnBg}`,
            }}
          >
            {statusIcon}
            <MediumTypography
              label={btnText}
              textColor={textColor}
              sxProps={{
                textTransform: "none",
                textWrap: "nowrap",
              }}
            />
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default CardComponentSidebar;
