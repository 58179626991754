import { useState, useEffect } from "react";

const useAvailableHeight = (headerHeight: number) => {
  const [availableHeight, setAvailableHeight] = useState(
    window.innerHeight - headerHeight
  );

  useEffect(() => {
    const handleResize = () => {
      setAvailableHeight(window.innerHeight - headerHeight);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [headerHeight]);

  return availableHeight;
};

export default useAvailableHeight;
