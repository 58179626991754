import { Box, Grid } from "@mui/material";
import React, { FC, useContext, useEffect, useState } from "react";
import MediumTypography from "../../../components/formlib/MediumTypography";
import { SubgroupDetailsTypes } from "../wizrads.types";
import * as Yup from "yup";
import { Formik } from "formik";
import FormikErrorComponent from "../../../components/formlib/FormikErrorComponent";
import TextInput from "../../../components/formlib/TextInput";
import { getOptions, handleError } from "../../../utils/commonFunctions";
import CustomDropdown from "../../../components/formlib/CustomDropdown";
import { WORK_FLOW_BASE_URL } from "../../../api/Constant";
import {
  customSaveDataApi,
  customUpdateDataApi,
  fetchWizardsDataApi,
} from "../../../api/wizardsApi/Common";
import { LoaderContext, LoaderContextType } from "../../../layouts/appSidebar";
import PhoneNumberInput from "../../../components/formlib/PhoneNumberInput";
import ButtonComponent from "../../../components/formlib/ButtonComponent";
import { StatusArray } from "../siteDeatilsScreen/SiteDetailsComponent";
import ErrorModal from "../../../components/formlib/modal/ErrorModal";

const muiColumnProps = {
  xs: 12,
  sm: 8,
  md: 6,
  lg: 6,
  xl: 6,
};

interface SubGroupProps {
  siteId: number;
  subGroupId: number;
  subGroupName: string;
}

const initialSubgroupData: SubgroupDetailsTypes = {
  name: "",
  location: "",
  longitude: "",
  latitude: "",
  contactPersonFirstName: "",
  contactPersonLastName: "",
  phoneNumber: "",
  email: "",
  // status: "",
  code: "",
  countryCode: "",
  radius: 0,
  checkInThreshold: 0,
  noShowThreshold: 0,
  checkoutThreshold: 0,
  autoCheckoutThreshold: 0,
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required("validation.fieldRequired"),
  location: Yup.string().nullable(),
  longitude: Yup.string().nullable(),
  latitude: Yup.string().nullable(),
  contactPersonFirstName: Yup.string().nullable(),
  contactPersonLastName: Yup.string().nullable(),
  phoneNumber: Yup.string().nullable(),
  email: Yup.string().nullable(),
  status: Yup.string().nullable(),
  code: Yup.string().nullable(),
  countryCode: Yup.string().nullable(),
  radius: Yup.number().min(0, "validation.fieldRequired").nullable(),
  checkInThreshold: Yup.number().min(0, "validation.fieldRequired").nullable(),
  noShowThreshold: Yup.number().min(0, "validation.fieldRequired").nullable(),
  checkoutThreshold: Yup.number().min(0, "validation.fieldRequired").nullable(),
  autoCheckoutThreshold: Yup.number()
    .min(0, "validation.fieldRequired")
    .nullable(),
});

const SubgroupDetailsComponent: FC<SubGroupProps> = ({
  siteId,
  subGroupId,
  subGroupName,
}) => {
  const [subGroupDetailsData, setSubgroupDetailsData] =
    useState<SubgroupDetailsTypes>(initialSubgroupData);
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [errorDesc, setErrorDesc] = useState<string>("");

  const [idExist, setIsIdExist] = useState<boolean>(true);

  useEffect(() => {
    getSubGroupById();
  }, [subGroupId]);

  const getSubGroupById = () => {
    const fetchSitetUrl = `${WORK_FLOW_BASE_URL}subgroup`;
    fetchWizardsDataApi<SubgroupDetailsTypes>(fetchSitetUrl, subGroupId)
      .then(async (resp: SubgroupDetailsTypes) => {
        const res = { ...initialSubgroupData, ...resp };

        const updatedResponse: SubgroupDetailsTypes = {
          ...res,
          id: res.id,
        };
        setSubgroupDetailsData(updatedResponse);
        toggleLoader(false);
      })
      .catch((err) => {
        handleError(err, setErrorDesc);
        setOpenErrorModal(true);
        toggleLoader(false);
      });
  };

  const saveData = (data: SubgroupDetailsTypes) => {
    let params: SubgroupDetailsTypes = {
      id: data?.id,
      name: data?.name,
      location: data?.location,
      longitude: data?.longitude,
      latitude: data?.latitude,
      contactPersonFirstName: data?.contactPersonFirstName,
      contactPersonLastName: data?.contactPersonLastName,
      phoneNumber: data?.phoneNumber,
      email: data?.email,
      code: data?.code,
      countryCode: data?.countryCode,
      selectedSubgroupStatusId: data?.selectedSubgroupStatusId,
      radius: data?.radius,
      checkInThreshold: data?.checkInThreshold,
      noShowThreshold: data?.noShowThreshold,
      checkoutThreshold: data?.checkoutThreshold,
      autoCheckoutThreshold: data?.autoCheckoutThreshold,
    };

    const url = `${WORK_FLOW_BASE_URL}${siteId}/subgroup/save`;

    // CreateSiteDetails(selectedAcc, params)
    customSaveDataApi(url, params)
      .then(() => {})
      .catch((error) => {
        handleError(error, setErrorDesc);
        setOpenErrorModal(true);
        toggleLoader(false);
      });
    // Add your API call logic
  };

  const updateData = (data: SubgroupDetailsTypes) => {
    let params: SubgroupDetailsTypes = {
      id: data?.id,
      name: data?.name,
      location: data?.location,
      longitude: data?.longitude,
      latitude: data?.latitude,
      contactPersonFirstName: data?.contactPersonFirstName,
      contactPersonLastName: data?.contactPersonLastName,
      phoneNumber: data?.phoneNumber,
      email: data?.email,
      code: data?.code,
      countryCode: data?.countryCode,
      selectedSubgroupStatusId: data?.selectedSubgroupStatusId,
      radius: data?.radius,
      checkInThreshold: data?.checkInThreshold,
      noShowThreshold: data?.noShowThreshold,
      checkoutThreshold: data?.checkoutThreshold,
      autoCheckoutThreshold: data?.autoCheckoutThreshold,
    };
    const SubGroupIdExist = subGroupId === 0 ? undefined : subGroupId;
    const updateUrl = `${WORK_FLOW_BASE_URL}subgroup/${SubGroupIdExist}/update`;
    // if (data.id) {
    customUpdateDataApi(updateUrl, params)
      .then(() => {
        getSubGroupById();
      })
      .catch((error) => {
        handleError(error, setErrorDesc);
        setOpenErrorModal(true);
        toggleLoader(false);
      });
    // }

    // Add your API call logic
  };

  return (
    <Box>
      {openErrorModal && (
        <ErrorModal
          descriptionText={errorDesc}
          open={openErrorModal}
          handleClose={() => setOpenErrorModal(false)}
          onPositiveClick={() => {
            setOpenErrorModal(false);
          }}
        />
      )}
      <Box className="flex__justify__space-between mb-md">
        <MediumTypography
          label={subGroupName}
          fontSize="20px"
          fontWeight={700}
        />
      </Box>
      <Box>
        <Formik
          initialValues={subGroupDetailsData}
          enableReinitialize
          validateOnChange
          validationSchema={validationSchema}
          onSubmit={(values) => {
            if (subGroupId !== 0) {
              updateData(values);
            } else {
              saveData(values);
            }
          }}
        >
          {({
            values,
            setFieldValue,
            handleSubmit,
            errors,
            touched,
            dirty,
          }) => {
            return (
              <Box>
                <Grid container spacing={2}>
                  <Grid item {...muiColumnProps}>
                    <MediumTypography
                      className="input-label"
                      labelId="Site.name"
                      defaultLabel="Name"
                    />
                    <TextInput
                      className="text-input-field"
                      type="text"
                      variant="outlined"
                      inputProps={{
                        readOnly: false,
                      }}
                      labelId="common.placeHolderText"
                      defaultLabelId="--- type here ---"
                      Value={values.name}
                      handlechange={(value: string) => {
                        setFieldValue("name", value);
                      }}
                    />
                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="name"
                    />
                  </Grid>
                  <Grid item {...muiColumnProps}>
                    <MediumTypography
                      className="input-label"
                      labelId="Site.code"
                      defaultLabel="Code"
                    />
                    <TextInput
                      className="text-input-field"
                      type="text"
                      variant="outlined"
                      inputProps={{
                        readOnly: false,
                      }}
                      labelId="common.placeHolderText"
                      defaultLabelId="--- type here ---"
                      Value={values.code}
                      handlechange={(value: string) => {
                        setFieldValue("code", value);
                      }}
                    />
                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="code"
                    />
                  </Grid>
                  <Grid item {...muiColumnProps}>
                    <MediumTypography
                      className="input-label"
                      labelId="Site.location"
                      defaultLabel="Location"
                    />
                    <TextInput
                      className="text-input-field"
                      type="text"
                      variant="outlined"
                      inputProps={{
                        readOnly: false,
                      }}
                      labelId="common.placeHolderText"
                      defaultLabelId="--- type here ---"
                      Value={values.location}
                      handlechange={(value: string) => {
                        setFieldValue("location", value);
                      }}
                    />
                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="location"
                    />
                  </Grid>
                  <Grid item {...muiColumnProps}>
                    <MediumTypography
                      className="input-label"
                      labelId="Site.latitude"
                      defaultLabel="Latitude"
                    />
                    <TextInput
                      className="text-input-field"
                      type="text"
                      variant="outlined"
                      inputProps={{
                        readOnly: false,
                      }}
                      labelId="common.placeHolderText"
                      defaultLabelId="--- type here ---"
                      Value={values.latitude}
                      handlechange={(value: string) => {
                        setFieldValue("latitude", value);
                      }}
                    />
                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="latitude"
                    />
                  </Grid>
                  <Grid item {...muiColumnProps}>
                    <MediumTypography
                      className="input-label"
                      labelId="Site.longitude"
                      defaultLabel="Longitude"
                    />
                    <TextInput
                      className="text-input-field"
                      type="text"
                      variant="outlined"
                      inputProps={{
                        readOnly: false,
                      }}
                      labelId="common.placeHolderText"
                      defaultLabelId="--- type here ---"
                      Value={values.longitude}
                      handlechange={(value: string) => {
                        setFieldValue("longitude", value);
                      }}
                    />
                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="longitude"
                    />
                  </Grid>
                  <Grid item {...muiColumnProps}>
                    <MediumTypography
                      className="input-label"
                      labelId="Site.contactPersonFirstName"
                      defaultLabel="Contact Person First Name"
                    />
                    <TextInput
                      className="text-input-field"
                      type="text"
                      variant="outlined"
                      inputProps={{
                        readOnly: false,
                      }}
                      labelId="common.placeHolderText"
                      defaultLabelId="--- type here ---"
                      Value={values.contactPersonFirstName}
                      handlechange={(value: string) => {
                        setFieldValue("contactPersonFirstName", value);
                      }}
                    />
                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="contactPersonFirstName"
                    />
                  </Grid>
                  <Grid item {...muiColumnProps}>
                    <MediumTypography
                      className="input-label"
                      labelId="Site.contactPersonLastName"
                      defaultLabel="Contact Person Last Name"
                    />
                    <TextInput
                      className="text-input-field"
                      type="text"
                      variant="outlined"
                      inputProps={{
                        readOnly: false,
                      }}
                      labelId="common.placeHolderText"
                      defaultLabelId="--- type here ---"
                      Value={values.contactPersonLastName}
                      handlechange={(value: string) => {
                        setFieldValue("contactPersonLastName", value);
                      }}
                    />
                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="contactPersonLastName"
                    />
                  </Grid>
                  <Grid item {...muiColumnProps}>
                    <MediumTypography
                      className="input-label"
                      labelId="Site.contactPersonPhoneNumber"
                      defaultLabel="Contact Person Phone Number"
                    />
                    <PhoneNumberInput
                      value={`${values.countryCode || ""}${
                        values.phoneNumber || ""
                      }`} // Display full value for UI, concatenating code & number
                      onChange={(value: string, countryData: any) => {
                        const countryCode = countryData.dialCode; // Get the country code
                        const phoneNumber = value.startsWith(countryCode)
                          ? value.slice(countryCode.length) // Remove country code from the start of the value
                          : value; // Use the value directly if no country code prefix

                        setFieldValue("countryCode", countryCode); // Store only the country code separately
                        setFieldValue("phoneNumber", phoneNumber); // Store only the raw phone number without country code
                      }}
                      labelId="common.placeHolderText"
                      defaultLabelId="--- type here ---"
                      disabled={false}
                    />
                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="phoneNumber"
                    />
                  </Grid>
                  <Grid item {...muiColumnProps}>
                    <MediumTypography
                      className="input-label"
                      labelId="Site.contactPersonEmail"
                      defaultLabel="Contact Person Email"
                    />
                    <TextInput
                      className="text-input-field"
                      type="text"
                      variant="outlined"
                      inputProps={{
                        readOnly: false,
                      }}
                      labelId="common.placeHolderText"
                      defaultLabelId="--- type here ---"
                      Value={values.email}
                      handlechange={(value: string) => {
                        setFieldValue("email", value);
                      }}
                    />
                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="email"
                    />
                  </Grid>
                  <Grid item {...muiColumnProps}>
                    <MediumTypography
                      className="input-label"
                      labelId="Site.status"
                      defaultLabel="Status"
                    />
                    <CustomDropdown
                      disableCreate={true}
                      options={getOptions(StatusArray)}
                      selectedValue={values.selectedSubgroupStatusId}
                      onSelectedValueChange={(value) => {
                        const statusId = value as string;
                        setFieldValue("selectedSubgroupStatusId", statusId);
                      }}
                    />
                  </Grid>
                  <Grid item {...muiColumnProps}>
                    <MediumTypography
                      className="input-label"
                      labelId="Subgroup.radiusName"
                      defaultLabel="Attendance Radius (Meter)"
                    />
                    <TextInput
                      className="text-input-field"
                      type="text"
                      variant="outlined"
                      inputProps={{
                        readOnly: false,
                      }}
                      labelId="common.placeHolderText"
                      defaultLabelId="--- type here ---"
                      Value={values.radius.toString()}
                      handlechange={(value: string) => {
                        setFieldValue("radius", value);
                      }}
                    />
                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="radius"
                    />
                  </Grid>
                  <Grid item {...muiColumnProps}>
                    <MediumTypography
                      className="input-label"
                      labelId="Subgroup.checkinthreshold"
                      defaultLabel="Check In Threshold ( Min )"
                    />
                    <TextInput
                      className="text-input-field"
                      type="text"
                      variant="outlined"
                      inputProps={{
                        readOnly: false,
                      }}
                      labelId="common.placeHolderText"
                      defaultLabelId="--- type here ---"
                      Value={values.checkInThreshold.toString()}
                      handlechange={(value: string) => {
                        setFieldValue("checkInThreshold", value);
                      }}
                    />
                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="checkInThreshold"
                    />
                  </Grid>
                  <Grid item {...muiColumnProps}>
                    <MediumTypography
                      className="input-label"
                      labelId="Subgroup.checkoutthreshold"
                      defaultLabel="Check Out Threshold ( Min )"
                    />
                    <TextInput
                      className="text-input-field"
                      type="text"
                      variant="outlined"
                      inputProps={{
                        readOnly: false,
                      }}
                      labelId="common.placeHolderText"
                      defaultLabelId="--- type here ---"
                      Value={values.checkoutThreshold.toString()}
                      handlechange={(value: string) => {
                        setFieldValue("checkoutThreshold", value);
                      }}
                    />
                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="checkoutThreshold"
                    />
                  </Grid>
                  <Grid item {...muiColumnProps}>
                    <MediumTypography
                      className="input-label"
                      labelId="Subgroup.noShowThreshold"
                      defaultLabel="No Show Threshold ( Min )"
                    />
                    <TextInput
                      className="text-input-field"
                      type="text"
                      variant="outlined"
                      inputProps={{
                        readOnly: false,
                      }}
                      labelId="common.placeHolderText"
                      defaultLabelId="--- type here ---"
                      Value={values.noShowThreshold.toString()}
                      handlechange={(value: string) => {
                        setFieldValue("noShowThreshold", value);
                      }}
                    />
                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="noShowThreshold"
                    />
                  </Grid>
                  <Grid item {...muiColumnProps}>
                    <MediumTypography
                      className="input-label"
                      labelId="Subgroup.autoCheckOutThreshold"
                      defaultLabel="Auto Check Out Threshold ( Min )"
                    />
                    <TextInput
                      className="text-input-field"
                      type="text"
                      variant="outlined"
                      inputProps={{
                        readOnly: false,
                      }}
                      labelId="common.placeHolderText"
                      defaultLabelId="--- type here ---"
                      Value={values.autoCheckoutThreshold.toString()}
                      handlechange={(value: string) => {
                        setFieldValue("autoCheckoutThreshold", value);
                      }}
                    />
                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="autoCheckoutThreshold"
                    />
                  </Grid>
                </Grid>
                <Box mt={"10px"} className="flex__ justifyContent-FlexEnd">
                  <ButtonComponent
                    className="btn-primary btn-submit"
                    variantType="contained"
                    defaultLabelId={"Save"}
                    labelId={subGroupId !== 0 ? "btn.update" : "btn.Add"}
                    onClick={() => {
                      handleSubmit();
                    }}
                  />
                </Box>
              </Box>
            );
          }}
        </Formik>
      </Box>
    </Box>
  );
};

export default SubgroupDetailsComponent;
