import {
  Avatar,
  Box,
  CssBaseline,
  Divider,
  Drawer,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { getSideBarMenus } from "./Sidebar";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";

import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import React, {
  FC,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { ReactComponent as Note } from "../assets/images/note.svg";
import { ReactComponent as ParamLogo } from "../assets/images/param_logo_text.svg";
import { ReactComponent as PersonalCard } from "../assets/images/personalcard.svg";
import { ReactComponent as ProfileUser } from "../assets/images/profile-2user.svg";
import { ReactComponent as ShieldSearch } from "../assets/images/shield-search.svg";
import { ReactComponent as EnquireIcon } from "../assets/images/enquire.svg";
import { ReactComponent as FullScreenIcon } from "../assets/images/fullScreen.svg";
import { ReactComponent as NotificationIcon } from "../assets/images/_NotificationIndicator_.svg";
import { ReactComponent as Expandmoreprofile } from "../assets/images/expandmoresvg.svg";
import { ReactComponent as DownArrow } from "../assets/images/DownArrow.svg";
import SearchBox from "../components/formlib/SearchBox";
import MediumTypography from "../components/formlib/MediumTypography";
import { Context } from "../LanguageWrapper";
import TreeDropdown from "../components/formlib/TreeDropdown";
import { SitesType } from "../utils/type";
import {
  ApiErrorResponse,
  LogoutRequest,
  signOutUser,
} from "../api/UserApi/User";
import { isCustomError } from "../api/ApiResponseHandler";
import Loader from "../components/shared/Loader";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useWebSocket from "../utils/useWebSocket";
import "./styles.css";
import { USER_ID_DB, WORK_FLOW_BASE_URL } from "../api/Constant";
import {
  CORRECTIVE_MAINTENANCE,
  CREATE_WORK_ORDER,
  GRASS_CUTTING_EXECUTION,
  GRASS_CUTTING_PLANNER,
  MASTER_DATA_MANAGEMENT,
  MODULE_CLEANING_PLANNER,
  PREVENTIVE_MAINTENANCE,
  PREVENTIVE_MAINTENANCE_DETAILS,
  PREVENTIVE_MAINTENANCE_TASKS,
  WORK_ORDER_EXECUTION,
} from "../routes/Routing";
import { CustomMenu } from "../components/formlib/CustomMenu";

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

export interface SiteDropdownType {
  dropdownValue: (
    value: SitesType[],
    selectedSiteName: string,
    selectedSiteId: number,
    selectedSubgroupId: number,
    selectedAssetGroupsIds?: number[]
  ) => void;
  selectedSiteName: string;
  selectedSiteId: number;
  selectedSubgroupId: number;
  selectedAssetGroupsIds: number[];
}

export const DropdownContext = React.createContext<SiteDropdownType>({
  dropdownValue: () => {},
  selectedSiteName: "",
  selectedSiteId: 0,
  selectedSubgroupId: 0,
  selectedAssetGroupsIds: [],
});

export interface LoaderContextType {
  loader: boolean;
  toggleLoader: (value: boolean) => void;
}

export const LoaderContext = createContext<LoaderContextType | undefined>(
  undefined
);

const drawerWidth = "8%";

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,

    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),

  ...theme.mixins.toolbar,
}));

const getMenuIcons = (type: string) => {
  switch (type) {
    case "note":
      return <Note />;
    case "personalCard":
      return <PersonalCard />;
    case "profile":
      return <ProfileUser />;
    case "shield":
      return <ShieldSearch />;
  }
};

const settings = [{ labelId: "logout.text", defaultLabelId: "Logout" }];

interface HomeScreenPropType {
  onLogout: () => void;
  userName: string | null;
  refreshToken: string | null;
}

interface NotificationBodyType {
  fileName: string;
  message: string;
  type: string;
  userId: number;
}
const AppsideBar: FC<HomeScreenPropType> = ({
  onLogout,
  userName,
  refreshToken,
}) => {
  const [open] = React.useState(false);
  const { pathname } = useLocation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const MemorizedCustomMenu = React.useMemo(() => CustomMenu, []);
  const openit = Boolean(anchorEl);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleNotification = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const [siteDropdownArrayValue, setSiteDropdownValueArray] = useState<
    SitesType[]
  >([]);
  const [selectedSiteName, setSelectedSiteName] = useState<string>("");
  const [selectedSiteId, setSelectedSiteId] = useState<number>(0);
  const [selectedSubgroupId, setSelectedSubgroupId] = useState<number>(0);
  const [selectedAssetGroupsIds, setSelectedAssetGroupIds] = useState<number[]>(
    []
  );

  const [loader, setloader] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const location = useLocation(); // Get the current route
  const currentPath = location.pathname;
  const showSiteDetailsRef = useRef<boolean>(true);

  // WebSocket notifications
  const { connected, subscribe } = useWebSocket({
    url: WORK_FLOW_BASE_URL + "ws",
  });

  const currentUserId = localStorage.getItem(USER_ID_DB);

  // Show a toast notification when a new message is received
  useEffect(() => {
    if (connected) {
      subscribe("/topic/messages", (data) => {
        if (data.body && currentUserId) {
          const notificationBody: NotificationBodyType = JSON.parse(data.body);

          if (notificationBody) {
            let navRoute = "";
            if (notificationBody?.type) {
              navRoute = MASTER_DATA_MANAGEMENT;
            }

            if (
              notificationBody?.message &&
              notificationBody?.userId?.toString() === currentUserId
            ) {
              const toastId = toast(notificationBody.message, {
                onClick: () => {
                  if (navRoute !== "") {
                    navigate(navRoute, {
                      state: {
                        pageKey: notificationBody?.type,
                      },
                    });
                    toast.dismiss(toastId);
                  }
                },
                style: {
                  cursor: navRoute !== "" ? "pointer" : "auto",
                },
              });
            }
          }
        }
      });
    }
  }, [connected, currentUserId]);

  const toggleLoader = (value: boolean) => {
    setloader(value);
  };

  const contextValue: LoaderContextType = {
    loader,
    toggleLoader,
  };

  const handleNotificationClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();

  const context = useContext(Context);
  useEffect(() => {
    context.selectLanguage();
  }, []);

  const dropdownValue = (
    value: SitesType[],
    siteName: string,
    siteId: number,
    subGroupId: number,
    assetGroupIds?: number[]
  ) => {
    setSiteDropdownValueArray(value);
    setSelectedSiteId(siteId);
    setSelectedSubgroupId(subGroupId);
    setSelectedSiteName(siteName);
    if (assetGroupIds) {
      setSelectedAssetGroupIds(assetGroupIds);
    }

    localStorage.setItem("selectedSiteId", siteId.toString());
    localStorage.setItem("selectedSiteName", siteName);
    localStorage.setItem("selectedSubgroupId", subGroupId.toString());
    localStorage.setItem("siteDropdownArrayValue", JSON.stringify(value));
    localStorage.setItem(
      "selectedAssetGroupsIds",
      JSON.stringify(selectedAssetGroupsIds)
    );
  };

  useEffect(() => {
    const storedSiteId = localStorage.getItem("selectedSiteId");
    const storedSiteName = localStorage.getItem("selectedSiteName");
    const storedSubgroupId = localStorage.getItem("selectedSubgroupId");
    const storedDropDownValue = localStorage.getItem("siteDropdownArrayValue");
    const storedAssetGroupdIds = localStorage.getItem("selectedAssetGroupsIds");

    if (
      storedSiteId &&
      storedSiteName &&
      storedSubgroupId &&
      storedDropDownValue &&
      storedAssetGroupdIds
    ) {
      setSelectedSiteId(Number(storedSiteId));
      setSelectedSiteName(storedSiteName);
      setSelectedSubgroupId(Number(storedSubgroupId));
      setSiteDropdownValueArray(JSON.parse(storedDropDownValue));
      setSelectedAssetGroupIds(JSON.parse(storedAssetGroupdIds));
    }
  }, []);

  const user = `${localStorage.getItem("firstname")} ${localStorage.getItem(
    "lastname"
  )}`;

  const requestFullscreen = (element: HTMLElement) => {
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if ((element as any).mozRequestFullScreen) {
      // Firefox
      (element as any).mozRequestFullScreen();
    } else if ((element as any).webkitRequestFullscreen) {
      // Chrome, Safari and Opera
      (element as any).webkitRequestFullscreen();
    } else if ((element as any).msRequestFullscreen) {
      // IE/Edge
      (element as any).msRequestFullscreen();
    }
  };

  const exitFullscreen = () => {
    const doc = document as any;
    if (
      doc.fullscreenElement ||
      doc.mozFullScreenElement ||
      doc.webkitFullscreenElement ||
      doc.msFullscreenElement
    ) {
      if (doc.exitFullscreen) {
        doc.exitFullscreen();
      } else if (doc.mozCancelFullScreen) {
        // Firefox
        doc.mozCancelFullScreen();
      } else if (doc.webkitExitFullscreen) {
        // Chrome, Safari and Opera
        doc.webkitExitFullscreen();
      } else if (doc.msExitFullscreen) {
        // IE/Edge
        doc.msExitFullscreen();
      }
    }
  };
  const toggleFullscreen = () => {
    const docEl = document.documentElement;

    if (!isFullscreen) {
      requestFullscreen(docEl);
    } else {
      exitFullscreen();
    }

    setIsFullscreen(!isFullscreen);
  };

  useEffect(() => {
    if (
      currentPath === WORK_ORDER_EXECUTION ||
      currentPath === GRASS_CUTTING_EXECUTION ||
      currentPath === CORRECTIVE_MAINTENANCE ||
      currentPath === PREVENTIVE_MAINTENANCE ||
      currentPath === CREATE_WORK_ORDER ||
      currentPath === MASTER_DATA_MANAGEMENT ||
      currentPath === MODULE_CLEANING_PLANNER ||
      currentPath === GRASS_CUTTING_PLANNER
    ) {
      showSiteDetailsRef.current = false;
    } else {
      showSiteDetailsRef.current = true;
    }
  }, [location.pathname]);

  return (
    <LoaderContext.Provider value={contextValue}>
      <DropdownContext.Provider
        value={{
          dropdownValue,
          selectedSiteName,
          selectedSiteId,
          selectedSubgroupId,
          selectedAssetGroupsIds,
        }}
      >
        {loader && <Loader />}

        <React.Fragment>
          <CssBaseline />
          <AppBar
            position="fixed"
            open={open}
            style={{
              background: "#1D2125",
              zIndex: 1200,
              borderBottom: "1px solid #333B43",
              boxShadow: "none",
            }}
            className="appHeaderStyle"
          >
            <Toolbar className="appToolbarPaddingZero">
              <Grid container>
                <Grid
                  item
                  xs={4}
                  lg={4}
                  md={4}
                  xl={4}
                  className="headerRespoGrid"
                >
                  <Box className="flex__ ">
                    <Box className="position__relative align_content_center">
                      <ParamLogo
                        className="position__relative"
                        style={{ top: "6px" }}
                      />
                    </Box>
                    <Box
                      className="position__relative align_content_center"
                      style={{
                        marginLeft: "20px",
                        gap: "10px",
                      }}
                    >
                      <SearchBox
                        labelId="defaultSearch"
                        defaultlabel="Search"
                        backgroundColor="#22272B"
                        sxProps={{ position: "relative", top: "3px" }}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={8}
                  lg={8}
                  md={8}
                  xl={8}
                  className="headerRespoGrid"
                >
                  <Box sx={{ flexGrow: 0 }}>
                    <Box
                      className="flex__"
                      sx={{
                        flexDirection: "row",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Box
                        className="position__relative align_content_center flex__"
                        style={{
                          marginLeft: "150px",
                        }}
                      >
                        <Box
                          className={` ${
                            showSiteDetailsRef.current
                              ? "cursor__pointer"
                              : "cursor__none"
                          }`}
                          sx={{
                            border: "1px solid rgba(166, 197, 226, 0.16)",
                            width: "450px",
                            height: "43px",
                            borderRadius: "4px",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                          onClick={
                            showSiteDetailsRef.current
                              ? handleNotification
                              : () => {}
                          }
                        >
                          <MediumTypography
                            label={selectedSiteName}
                            sxProps={{
                              fontSize: "14px",
                              fontWeight: "400",
                              color: "#FFFFFF",
                              lineHeight: "20px",
                              padding: "8px",
                              maxWidth: "350px", // Adjust the maximum width as needed
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          />
                          {showSiteDetailsRef.current && (
                            <DownArrow
                              className={`position__relative cursor__pointer`}
                              style={{ right: "10px", top: "18px" }}
                            />
                          )}
                        </Box>
                      </Box>

                      {showSiteDetailsRef.current && (
                        <MemorizedCustomMenu
                          id="demo-customized-menu"
                          anchorEl={anchorEl}
                          open={openit}
                          maxWidth={600}
                          minWidth={450}
                          onClose={handleNotificationClose}
                          sx={{
                            width: "800px",
                            padding: "0px",
                          }}
                        >
                          {siteDropdownArrayValue.length > 0 && (
                            <TreeDropdown
                              data={siteDropdownArrayValue}
                              siteName={selectedSiteName}
                            />
                          )}
                        </MemorizedCustomMenu>
                      )}
                      <Box
                        className="flex__ align_content_center"
                        style={{
                          marginLeft: "25px",
                        }}
                      >
                        <Box
                          style={{
                            marginRight: "10px",
                            padding: "4px 6px 0px 6px",
                            border: "2px solid rgba(166, 197, 226, 0.16)",
                            borderRadius: "4px",
                          }}
                          onClick={handleOpenUserMenu}
                        >
                          <EnquireIcon
                            style={{ width: "24px", height: "24px" }}
                          />
                        </Box>
                        <Box
                          style={{
                            marginRight: "10px",
                            padding: "9px 6px 0px 6px",
                            border: "2px solid rgba(166, 197, 226, 0.16)",
                            borderRadius: "4px",
                          }}
                          className="cursor__pointer"
                        >
                          <FullScreenIcon
                            style={{ width: "20px", height: "20px" }}
                            onClick={toggleFullscreen}
                          />
                        </Box>
                        <Box
                          style={{
                            padding: "4px 6px 0px 6px",
                            border: "2px solid rgba(166, 197, 226, 0.16)",
                            borderRadius: "4px",
                          }}
                        >
                          <NotificationIcon
                            style={{ width: "24px", height: "24px" }}
                          />
                        </Box>
                      </Box>

                      <Box
                        sx={{
                          border: "2px solid rgba(166, 197, 226, 0.16)",
                          marginLeft: "10px",
                          borderRadius: "4px",
                          boxShadow: "none",
                        }}
                      >
                        <Tooltip
                          sx={{
                            backgroundColor: "white",
                            color: "white",
                            borderRadius: "4px",
                            fontSize: "14px",
                          }}
                          title={user == null ? "" : user}
                        >
                          <ListItemButton
                            sx={{
                              justifyContent: "flex-start",
                              "&:hover": {
                                backgroundColor: "transparent",
                              },
                              borderRadius: "4px",
                              padding: "8px 8px",
                            }}
                            onClick={handleOpenUserMenu}
                          >
                            <Avatar
                              alt=""
                              sx={{ width: "24px", height: "24px" }}
                              src={
                                localStorage.getItem("Profile Image") ||
                                undefined
                              }
                            />
                            <MediumTypography
                              label={user == null ? "" : user}
                              sxProps={{
                                color: "#8C9BAB",
                                fontWeight: 600,
                                maxWidth: "100px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                marginLeft: "5px",
                              }}
                            />
                            <Expandmoreprofile style={{ marginLeft: "5px" }} />
                          </ListItemButton>
                        </Tooltip>
                      </Box>
                    </Box>
                    <Menu
                      sx={{ mt: "45px", width: "150px" }}
                      className="headerProfiledropdown"
                      id="menu-appbar"
                      anchorEl={anchorElUser}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      open={Boolean(anchorElUser)}
                      onClose={handleCloseUserMenu}
                    >
                      {settings.map((setting, index) => (
                        <MenuItem
                          key={setting.defaultLabelId}
                          sx={{
                            backgroundColor: "#1F272C",
                          }}
                          onClick={() => {
                            handleCloseUserMenu();
                            if (userName === null || refreshToken === null) {
                              return;
                            }
                            const body: LogoutRequest = {
                              username: userName,
                              refreshToken: refreshToken,
                            };
                            if (index === 0) {
                              signOutUser(body)
                                .then((response) => {
                                  if (response) {
                                    localStorage.clear();
                                    onLogout();
                                    navigate("/");
                                  }
                                })
                                .catch(async (error) => {
                                  if (isCustomError(error)) {
                                    const apiError = error as ApiErrorResponse;
                                    console.log(apiError.issue[0].diagnostics);
                                  } else {
                                    console.log(error.id);
                                  }
                                });
                            }
                          }}
                        >
                          <MediumTypography
                            labelId={setting.labelId}
                            defaultLabel={setting.defaultLabelId}
                            sxProps={{
                              fontSize: "12px",
                              fontWeight: "500",
                              textAlign: "center",
                              padding: "4px 6px 4px 6px",
                            }}
                          />
                        </MenuItem>
                      ))}
                    </Menu>
                  </Box>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>

          <Box className="flex__" sx={{ flexDirection: "row", top: "64px" }}>
            <Drawer
              className="appSidebar"
              variant="permanent"
              open={open}
              style={{
                zIndex: "999",
                background: "#1D2125",
                backgroundColor: "#1D2125",
                top: "64px",
                position: "relative",
                width: "80px",
                height: "fit-content",
              }}
              classes={{
                paper: "drawer-paper", // This is how you can assign custom classes to the paper element
              }}
            >
              <DrawerHeader
                style={{
                  textAlign: "center",
                  justifyContent: "space-around",
                  backgroundColor: "#1D2125",
                  minHeight: "0px",
                  position: "relative",
                }}
              ></DrawerHeader>

              <Divider />
              <List
                style={{
                  backgroundColor: "#1D2125",
                  color: "#fff",
                  height: "100%",
                  overflow: "hidden",
                  padding: "24px, 24px, 0px, 24px",
                  position: "relative",
                  borderRight: "1px solid #333B43",
                  boxShadow: "none",
                }}
              >
                {getSideBarMenus().map((item) => (
                  <ListItem
                    key={item.id}
                    disablePadding
                    sx={{
                      display: "block",
                      padding: "24px 24px 0px 24px",
                    }}
                    className="sidebarList"
                  >
                    {item.type === "NORMAL" && (
                      <ListItemButton
                        sx={{
                          minHeight: 32,
                          justifyContent: open ? "initial" : "center",
                          paddingLeft: "0px",
                          paddingRight: "0px",
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                            display: open ? "none" : "flex",
                            borderRadius: "8px",
                            width: "32px",
                            height: "32px",
                            alignItems: "end",
                            backgroundColor: pathname.includes(item.to)
                              ? "#175FFA"
                              : "transparent",
                          }}
                        >
                          <Link
                            to={item.to}
                            target="_blank"
                            className={
                              pathname.includes(item.to)
                                ? "listItemIconColor"
                                : ""
                            }
                          >
                            {getMenuIcons(item.leftIcon)}
                          </Link>
                        </ListItemIcon>
                        <ListItemText
                          className="textIconSidebar"
                          sx={{
                            opacity: open ? 1 : 0,
                            marginTop: "0px",
                            marginBottom: "0px",
                          }}
                        ></ListItemText>
                      </ListItemButton>
                    )}
                  </ListItem>
                ))}
              </List>
              <Divider />
            </Drawer>
            <Box
              sx={{
                width: "100%",
                backgroundColor: "#1D2125",
              }}
            >
              <DrawerHeader className="DrawerHeaderDiv" />
              <Box>
                <Outlet />
                <ToastContainer
                  position="bottom-right"
                  autoClose={15000}
                  hideProgressBar={false}
                  newestOnTop
                  closeOnClick={false}
                  rtl={false}
                  pauseOnFocusLoss={false}
                  draggable={false}
                  pauseOnHover={false}
                  theme="dark"
                  style={{ bottom: "10px" }}
                />
              </Box>
            </Box>
          </Box>
        </React.Fragment>
      </DropdownContext.Provider>
    </LoaderContext.Provider>
  );
};

export default AppsideBar;
