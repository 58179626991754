import { FC } from "react";
import { Box } from "@mui/system";
// Import your GIF
import loadingGif from "../../assets/LoaderGif.gif"; // Adjust the path to your GIF

const Loader: FC = () => {
  return (
    <Box
      sx={{
        position: "fixed",
        height: "100%",
        width: "100%",
        zIndex: 9999,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgba(0, 0, 0, 0.25)", // Optional: can remove this if you want no background
        display: "flex",
      }}
    >
      <Box
        component="img"
        src={loadingGif} // Set your GIF source here
        alt="Loading"
        sx={{
          width: "60px", // Adjust size as needed
          height: "60px", // Adjust size as needed
          backgroundColor: "transparent", // Set to transparent if needed
        }}
      />
    </Box>
  );
};

export default Loader;
