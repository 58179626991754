import React, { useState, ChangeEvent, useEffect } from "react";
import {
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  InputAdornment,
  Button,
} from "@mui/material";
import { ReactComponent as CrossIcon } from "../../../assets/images/_CrossIcon_.svg";
import DocViewer, {
  DocViewerRenderers,
  IDocument,
} from "@cyntler/react-doc-viewer";
import { pdfjs } from "react-pdf";
import TextInput from "../../../components/formlib/TextInput";
import MediumTypography from "../../../components/formlib/MediumTypography";
import { DocumentTypeBean } from "./types";
import "@cyntler/react-doc-viewer/dist/index.css";
import "react-pdf/dist/Page/TextLayer.css";
import "react-pdf/dist/Page/AnnotationLayer.css";

// Set the PDF worker URL globally for react-pdf
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`
).toString();

interface FileUploadInputProps {
  labelId: string;
  defaultLabel: string;
  value: string;
  handleChange: (value: DocumentTypeBean) => void;
  cardEdit: boolean;
  url?: string;
  reset?: boolean;
  fileCategory?: "document" | "image";
}

const FileUploadInput: React.FC<FileUploadInputProps> = ({
  labelId,
  defaultLabel,
  value,
  handleChange,
  cardEdit,
  url,
  reset,
  fileCategory = "document",
}) => {
  const [fileName, setFileName] = useState<string>(value);
  const [file, setFile] = useState<File | null>(null);
  const [fileURL, setFileURL] = useState<string | null>(null);
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    setFileName(value);
    // Only update the fileURL with the url prop if there's no recently uploaded local file.
    // This assumes fileURL is null initially or reset when there's no file.
    if (url && !file && url !== fileURL) {
      setFileURL(url);
    }
    // Cleanup function to revoke Object URL to avoid memory leaks.
    return () => {
      if (fileURL && file) {
        URL.revokeObjectURL(fileURL);
      }
    };
  }, [value, url]);

  useEffect(() => {
    if (reset) {
      // Reset state here
      setFileName("");
      setFile(null);
      setFileURL(null);
    }
  }, [reset]);

  useEffect(() => {
    // Cleanup function to revoke Object URL to avoid memory leaks
    return () => {
      if (fileURL) {
        URL.revokeObjectURL(fileURL);
      }
    };
  }, [fileURL]);
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64StringWithPrefix = reader.result as string;
        // Strip the prefix (data:<mime-type>;base64,)
        const base64String = base64StringWithPrefix.split(",")[1];
        const extension = selectedFile.name.split(".").pop() || "";
        const fileObj: DocumentTypeBean = {
          document: base64String,
          fileName: selectedFile.name,
          extension: extension,
        };
        setFileName(selectedFile.name);
        setFile(selectedFile);
        setFileURL(URL.createObjectURL(selectedFile));
        handleChange(fileObj);
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  const handleUploadClick = () => {
    document.getElementById("file-input")?.click();
  };

  const handleCancelClick = () => {
    setFileName("");
    setFile(null);
    setFileURL(null);
    handleChange({ document: "", fileName: "", extension: "" });
  };

  const handleFileNameClick = () => {
    if (fileURL) {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const documents: IDocument[] = [
    {
      uri: fileURL ? fileURL : "",
      fileName: fileName,
    },
  ];

  return (
    <Box>
      <Box display="flex" alignItems="center" className="flex__">
        <TextInput
          labelId={labelId}
          className={
            fileCategory === "image" ? "text-input-field" : "textInputWidth"
          }
          defaultLabelId={defaultLabel}
          borderColor="1px solid rgba(166, 197, 226, 0.16)"
          Value={fileName}
          handleClick={fileName && cardEdit ? handleFileNameClick : undefined}
          variant="outlined"
          backGroundColor="#22272B"
          fieldheight="40px"
          textColor={
            cardEdit ? "rgba(255, 255, 255, 1)" : "rgba(140, 155, 171, 1)"
          }
          inputProps={{
            readOnly: true,
          }}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                {cardEdit && (
                  <>
                    {fileName ? (
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          handleCancelClick();
                        }}
                        color="secondary"
                      >
                        <CrossIcon />
                      </IconButton>
                    ) : (
                      <Box
                        sx={{ cursor: "pointer" }}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleUploadClick();
                        }}
                      >
                        <MediumTypography
                          labelId="Rca.Upload"
                          defaultLabel="Upload"
                          sxProps={{
                            color: "rgba(159, 173, 188, 1)",
                            textDecoration: "underline",
                            textDecorationThickness: "2px",
                            textUnderlineOffset: "4px",
                          }}
                        />
                      </Box>
                    )}
                  </>
                )}
              </InputAdornment>
            ),
          }}
          sxProps={{ cursor: fileName ? "pointer" : "default" }}
        />
        <input
          id="file-input"
          type="file"
          style={{ display: "none" }}
          onChange={handleFileChange}
          accept={fileCategory === "image" ? "image/*" : ".pdf,.doc,.docx"}
        />
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={fileCategory === "image" ? "md" : "lg"}
        fullWidth
      >
        <DialogTitle sx={{ backgroundColor: "#1D2125" }}>
          <MediumTypography label={fileName} fontSize="16px" />
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CrossIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{ backgroundColor: "#1D2125" }}
          className="tableStyles"
        >
          {fileURL &&
            (fileCategory === "image" ? (
              <Box sx={{ textAlign: "center" }}>
                <img src={fileURL} alt={fileName} />
              </Box>
            ) : (
              <DocViewer
                documents={documents}
                pluginRenderers={DocViewerRenderers}
                config={{
                  header: {
                    disableHeader: true,
                  },
                }}
              />
            ))}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default FileUploadInput;
