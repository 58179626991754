import { Box } from "@mui/material";
import TeamMembersComponent from "./TeamMemberComponent";
import { FC, useEffect, useMemo, useState } from "react";
import "../../pages/scheduler/css/TeamMembers.css";
import SearchBox from "./SearchBox";
import MediumTypography from "./MediumTypography";
import { TechnicaianTitleType, TechnicianType } from "../../utils/type";
import { ReactComponent as RightArrow } from "../../assets/images/RightArrowBlue.svg";
import { ReactComponent as DownArrow } from "../../assets/images/DownArrow.svg";
import ButtonComponent from "./ButtonComponent";
import CheckBox from "./CheckBox";

import { CustomMenu } from "./CustomMenu";

export interface TeamMembersDropDownProps {
  newItems: TechnicaianTitleType[];
  teamMemberSelectedCallBack: (value: number[]) => void;
  selectedMembersIds: number[];
}

const TeamMembersDropDown: FC<TeamMembersDropDownProps> = ({
  newItems,
  teamMemberSelectedCallBack,
  selectedMembersIds,
}) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [expanded, setExpanded] = useState<Record<string, boolean>>({});
  const [anyTechnicianChecked, setAnyTechnicianChecked] = useState(true);
  const MemorizedCustomMenu = useMemo(() => CustomMenu, []);
  const [selectedTechniciansId, setSelectedTechniciansId] = useState<number[]>(
    []
  );
  const [selectedMembersCount, setSelectedMembersCount] = useState<number>(0);
  const [allMembers, setAllMembers] = useState<boolean>(false);
  const [unAssignedMembers, setUnAssignedMembers] = useState<boolean>(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openit = Boolean(anchorEl);
  const handleTeamDropDown = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleTeamDropdownClose = () => {
    setAnchorEl(null);
  };
  const [selectedMembers, setSelectedMembers] = useState<
    TechnicaianTitleType[]
  >([]);

  const [getAllTechniciansId, setAllTechniciansId] = useState<number[]>([]);

  useEffect(() => {
    if (selectedMembersIds.length === 0) {
      setSelectedMembersCount(0);
    }
  }, [selectedMembersIds]);

  useEffect(() => {
    // Initialize members based on selectedMembersIds if provided, otherwise select all by default
    if (newItems) {
      setSelectedMembers(newItems);

      const allIds = newItems.flatMap((item) =>
        item.members.map((member) => member.id)
      );
      setAllTechniciansId(allIds);

      if (selectedMembersIds.length > 0) {
        setSelectedTechniciansId(selectedMembersIds);
      } else {
        setSelectedTechniciansId(allIds);
      }

      if (selectedMembersIds.length === allIds.length) {
        setAllMembers(false);
      } else {
        setAllMembers(true);
      }

      const allExpanded = newItems.reduce((acc, item) => {
        acc[item.title] = true;
        return acc;
      }, {} as Record<string, boolean>);
      setExpanded(allExpanded);
      setAnyTechnicianChecked(selectedMembersIds.length > 0 || true);
    }
  }, [newItems, selectedMembersIds]);

  const handleCheck = (technician: TechnicianType, title: string) => {
    handleMemberCheck(technician, title);
  };

  const isSelected = (member: TechnicianType, techiesTitle: string) =>
    selectedTechniciansId.includes(member.id);

  const handleSearchChange = (value: string) => {
    setSearchTerm(value.toLowerCase());
  };

  const handleTechnicianMembers = (titleName: string) => {
    setExpanded((prevExpanded) => {
      const isExpanded = prevExpanded[titleName];

      if (isExpanded) {
        return {
          ...prevExpanded,
          [titleName]: false,
        };
      } else {
        return {
          ...prevExpanded,
          [titleName]: true,
        };
      }
    });
  };

  const filteredTechnicians = newItems
    ?.map((technician) => {
      const matchingMembers = technician.members.filter(
        (member) =>
          member.name.toLowerCase().includes(searchTerm) ||
          member.title.toLowerCase().includes(searchTerm)
      );

      return {
        ...technician,
        members: matchingMembers,
      };
    })
    .filter(
      (technician) =>
        technician.members.length > 0 ||
        technician.title.toLowerCase().includes(searchTerm)
    );

  useEffect(() => {
    if (newItems) {
      const initialExpandedState = newItems.reduce((acc, item) => {
        acc[item.title] = true; // Keep them expanded by default
        return acc;
      }, {} as Record<string, boolean>);

      setExpanded(initialExpandedState);
    }
  }, [newItems]);

  const handleMemberCheck = (technician: TechnicianType, title: string) => {
    setSelectedTechniciansId((prevSelectedIds) => {
      const updatedIds = prevSelectedIds.includes(technician.id)
        ? prevSelectedIds.filter((id) => id !== technician.id)
        : [...prevSelectedIds, technician.id];
      const selectedMemCount = updatedIds.length;

      if (updatedIds.length === getAllTechniciansId.length) {
        setSelectedMembersCount(0);
      } else {
        setSelectedMembersCount(selectedMemCount);
      }

      // setAllMembers(updatedIds.length === getAllTechniciansId.length);

      if (prevSelectedIds.includes(technician.id)) {
        // Remove the technician ID if it is already selected
        return prevSelectedIds.filter((id) => id !== technician.id);
      } else {
        // Add the technician ID if it is not selected
        return [...prevSelectedIds, technician.id];
      }
    });
  };

  useEffect(() => {
    setAnyTechnicianChecked(selectedTechniciansId.length > 0);
  }, [selectedTechniciansId]);

  useEffect(() => {
    // Ensure the "All" checkbox is checked if all non-unassigned IDs are selected
    const nonUnassignedIds = selectedTechniciansId.filter((id) => id !== 0);
    setAllMembers(nonUnassignedIds.length === getAllTechniciansId.length);
  }, [selectedTechniciansId, getAllTechniciansId]);

  const handleUnAssignedCheck = () => {
    setUnAssignedMembers(!unAssignedMembers);

    setSelectedTechniciansId((prevSelectedIds) => {
      if (!unAssignedMembers) {
        // If unassigned is being checked, add 0 to the selected IDs
        return [...prevSelectedIds, 0];
      } else {
        // If unassigned is being unchecked, remove 0 from the selected IDs
        return prevSelectedIds.filter((id) => id !== 0);
      }
    });
  };

  const handleAllMembers = () => {
    if (allMembers) {
      // Unchecking "All" - deselect all IDs
      setSelectedTechniciansId([]);
      setAllMembers(false);
    } else {
      // Checking "All" - select all IDs
      const newSelectedIds = [...getAllTechniciansId];

      if (unAssignedMembers) {
        newSelectedIds.push(0); // Include 0 (unassigned) if "Unassigned" is already checked
      }

      setSelectedTechniciansId(newSelectedIds);
      setAllMembers(true);
      setSelectedMembersCount(0);
    }
  };

  return (
    <Box>
      <Box
        className="cursor__pointer"
        sx={{
          border: "1px solid rgba(166, 197, 226, 0.16)",
          width: "220px",
          height: "32px",
          borderRadius: "4px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor:
            selectedMembersCount >= 1 ? "rgba(34, 63, 100, 1)" : "",
        }}
        onClick={handleTeamDropDown}
      >
        <Box sx={{ display: "flex" }}>
          <MediumTypography
            labelId="SelectTeamMembers"
            defaultLabel="Select Team Members"
            sxProps={{
              fontSize: "14px",
              fontWeight: "400",
              color: "#FFFFFF",
              opacity: "0.6",
              lineHeight: "20px",
              padding: "8px",
            }}
          />
          {selectedMembersCount >= 1 && (
            <Box
              sx={{
                margin: "auto",
                background: "#175FFA",
                padding: "0px 6px",
                borderRadius: "20px",
              }}
            >
              <MediumTypography
                label={selectedMembersCount.toString()}
                sxProps={{
                  fontSize: "14px",
                  fontWeight: "400",
                  color: "#FFFFFF",
                }}
              />
            </Box>
          )}
        </Box>
        <DownArrow
          className="position__relative cursor__pointer"
          style={{ right: "10px" }}
        />
      </Box>

      <MemorizedCustomMenu
        id="demo-customized-menu"
        anchorEl={anchorEl}
        open={openit}
        minWidth={310}
        maxWidth={310}
        onClose={handleTeamDropdownClose}
        sx={{ marginLeft: "15px" }}
      >
        <Box>
          <Box className="pt-md pl-md pr-md" sx={{ position: "relative" }}>
            <SearchBox
              labelId="search"
              defaultlabel="Search"
              backgroundColor="#22272B"
              sxProps={{
                position: "sticky",
                top: 0,
                zIndex: 1,
                minWidth: "270px !important",
              }}
              onChange={handleSearchChange}
            />
            <Box className="flex__ pt-sm" alignItems="center">
              <CheckBox value={allMembers} onCheckBoxClick={handleAllMembers} />
              <MediumTypography label="All" fontSize="14px" />
            </Box>
            <Box className="flex__ pt-sm" alignItems="center">
              <CheckBox
                value={unAssignedMembers}
                onCheckBoxClick={handleUnAssignedCheck}
              />
              <MediumTypography label="Unassigned" fontSize="14px" />
            </Box>
            <ul
              className="no-marker pt-sm"
              style={{
                scrollbarWidth: "none",
                scrollbarColor: "red",
              }}
            >
              <ul className="no-marker">
                {filteredTechnicians &&
                  filteredTechnicians.length > 0 &&
                  filteredTechnicians.map((techies) => {
                    return (
                      <li key={techies.title}>
                        <Box
                          className="flex__"
                          sx={{
                            padding: "8px",
                            alignItems: "center",
                            gap: "8px",
                            cursor: "pointer",
                          }}
                          onClick={() => handleTechnicianMembers(techies.title)}
                        >
                          {expanded[techies.title] ? (
                            <DownArrow />
                          ) : (
                            <RightArrow />
                          )}
                          <Box className="flex__" alignItems="center">
                            <MediumTypography
                              label={techies.title}
                              fontSize="14px"
                              textColor="#ffffff"
                            />
                          </Box>
                        </Box>
                        {techies.members.map((mem) => {
                          return (
                            expanded[techies.title] && (
                              <ul className="no-marker pt-sm" key={mem.id}>
                                <TeamMembersComponent
                                  key={mem.id}
                                  data={mem}
                                  checkBoxRequired={true}
                                  value={isSelected(mem, techies.title)}
                                  onClickChange={() => {
                                    handleCheck(mem, techies.title);
                                  }}
                                  isAvailabilityRequired={false}
                                  statusNotRequired={false}
                                />
                              </ul>
                            )
                          );
                        })}
                      </li>
                    );
                  })}
              </ul>
            </ul>
          </Box>
          <Box
            className="width__100 flex__container p-md"
            sx={{
              position: "sticky",
              bottom: 0,
              zIndex: 1,
              backgroundColor: "#1D2125",
            }}
          >
            <ButtonComponent
              className="btn-primary btn-submit "
              labelId="Apply"
              defaultLabelId="Apply"
              sxProps={{ width: "100px" }}
              disabled={
                (filteredTechnicians && filteredTechnicians.length === 0) ||
                !anyTechnicianChecked
              }
              onClick={() => {
                teamMemberSelectedCallBack(selectedTechniciansId);
                handleTeamDropdownClose();
                setSearchTerm("");
              }}
            />
          </Box>
        </Box>
      </MemorizedCustomMenu>
    </Box>
  );
};

export default TeamMembersDropDown;
