import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import MediumTypography from "../../../components/formlib/MediumTypography";
import { useEffect, useState } from "react";
import {
  PMTasksCheckListType,
  PMTasksLineItemType,
  PMTasksAssetType,
  PMTasksSaveDataType,
  PMTasksCheckListWithoutImagesType,
  PMTasksDataType,
} from "./types";
import ButtonComponent from "../../../components/formlib/ButtonComponent";
import TextInput from "../../../components/formlib/TextInput";
import React from "react";
import "./preventiveMaintenance.css";
import CompletionStatusIcon from "./CompletionStatusIcon";
import LinearProgressBar from "./LinearProgressBar";
import {
  getPreventiveMaintenanceTasksApi,
  savePreventiveMaintenanceTasksApi,
} from "../../../api/preventiveMaintenance/PreventiveMaintenance";

import Loader from "../../../components/shared/Loader";
import ErrorModal from "../../../components/formlib/modal/ErrorModal";
import ModalPopup from "../../../components/formlib/modal/ModalPopup";
import { ReactComponent as ErrorIcon } from "../../../assets/images/ErrorIcon.svg";
import WorkOrderActivity from "../WorkOrderActivity";
import { StatusDataType } from "../../../api/moduleCleaning/ModuleCleaningApi";
import WorkOrderGallery from "../WorkOrderGallery";
import useAvailableHeight from "../../../utils/useAvailableHeight";
import CancelIcon from "@mui/icons-material/Cancel";
import { handleError } from "../../../utils/commonFunctions";

const initialPMTasksData: PMTasksDataType = {
  isPcChecklistRequired: false,
  isPcChecklistCompleted: false,
  isPcChecklistMarkedNo: false,
  lineItems: [],
  assets: [],
  tasks: [],
  isNAResponse: [],
};

interface PreventiveMaintenanceTasksProps {
  stateCardId: number;
  status: StatusDataType[];
  tasksCallBack: (completed: boolean) => void;
  isVisible: boolean;
}

interface TasksInputsType extends PMTasksCheckListWithoutImagesType {
  addedWeightage: number;
  addedAssetId: number;
}

interface ModifiedPMTasksCheckListType extends PMTasksCheckListType {
  lineItem: PMTasksLineItemType;
}

interface GroupTaskCheckListType {
  taskGroupId: number;
  taskGroupName: string;
  taskCheckLists: ModifiedPMTasksCheckListType[];
}

interface AssetsTaskType {
  assetId: number;
  asset: PMTasksAssetType;
  groupTaskCheckLists: GroupTaskCheckListType[];
}

const PreventiveMaintenanceTasks: React.FC<PreventiveMaintenanceTasksProps> = ({
  stateCardId,
  status,
  tasksCallBack,
  isVisible,
}) => {
  const [apiData, setApiData] = useState<PMTasksDataType>(initialPMTasksData);
  const [assetsTasks, setAssetsTasks] = useState<AssetsTaskType[]>([]);
  const [assets, setAssets] = useState<Map<number, PMTasksAssetType>>(
    new Map()
  );
  const [activeAssetId, setActiveAssetId] = useState(0);
  const [tasksInputs, setTasksInputs] = useState<Map<number, TasksInputsType>>(
    new Map()
  );
  const [isAllAssetsDone, setIsAllAssetsDone] = useState(false);
  // const [isEditable, setIsEditable] = useState(false);
  const [expandedAccordions, setExpandedAccordions] = useState<{
    [key: string]: boolean;
  }>({});
  const [isLoading, setIsLoading] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [errorDesc, setErrorDesc] = useState<string>("");
  const availableHeight = useAvailableHeight(480);
  const collapseAvailHeight = useAvailableHeight(320);

  const [validationError, setValidationError] = useState<{
    [key: string]: string | undefined;
  }>({});
  const [isEditableBasedOnStatus, setIsEditableBasedOnStatus] = useState(false);

  const [renderCount, setRenderCount] = useState(0);

  useEffect(() => {
    if (
      status[status.length - 2]?.code === "INPROGRESS" ||
      status[status.length - 2]?.code === "COMPLETED"
    ) {
      setIsEditableBasedOnStatus(true);
    } else {
      setIsEditableBasedOnStatus(false);
    }

    if (renderCount > 0) {
      getPreventiveMaintenanceTasks(activeAssetId);
    } else {
      getPreventiveMaintenanceTasks();
    }

    setRenderCount((prev) => prev + 1);
  }, [stateCardId, status]);

  useEffect(() => {
    if (assets.size > 0) {
      const updatedIsAllAssetsDone = Array.from(assets.values()).every(
        (assetData) => {
          return assetData.isCompleted;
        }
      );

      if (isAllAssetsDone !== updatedIsAllAssetsDone) {
        setIsAllAssetsDone(() => updatedIsAllAssetsDone);
        tasksCallBack(updatedIsAllAssetsDone);
      }
    }
  }, [assets]);

  const getPreventiveMaintenanceTasks = (assetId?: number) => {
    setIsLoading(true);
    getPreventiveMaintenanceTasksApi(stateCardId, assetId)
      .then((res) => {
        const newActiveAsset = res.tasks[0]?.assetId || 0;
        const newAssetsMap = new Map();
        res.assets?.forEach((asset) => {
          newAssetsMap.set(asset.id, { ...asset });
        });

        if (res.isPcChecklistRequired) {
          newAssetsMap.set(0, {
            id: 0,
            sequence: 0,
            name: "Safety Checks After Completion",
            isCompleted: res.isPcChecklistCompleted,
            isDoneWithIssue: res.isPcChecklistMarkedNo,
            completionPer: 0,
          });
        }
        formatApiData(res);
        setApiData(res);
        setAssets(newAssetsMap);
        setActiveAssetId(newActiveAsset);
        setIsLoading(false);
      })
      .catch((err) => {
        setOpenErrorModal(true);
        handleError(err, setErrorDesc);
        setIsLoading(false);
      });
  };

  const handleSavePreventiveMaintenanceTasksApi = (
    tasksInputItem: TasksInputsType
  ) => {
    setIsLoading(true);

    let params: PMTasksSaveDataType = [tasksInputItem];

    if (tasksInputItem.addedAssetId === 0) {
      params = [{ ...tasksInputItem, isDoneWithIssue: false }];
    }

    savePreventiveMaintenanceTasksApi(stateCardId, params)
      .then(() => {
        const updatedTasksInputs = new Map(tasksInputs);

        updatedTasksInputs.set(tasksInputItem.woTaskId, tasksInputItem);

        if (tasksInputItem.addedAssetId === 0) {
          // For asset 0 - safety checklist

          const isCompleted = Array.from(updatedTasksInputs.values()).every(
            (taskInput) => {
              return taskInput.isCompleted;
            }
          );

          const isDoneWithIssue = Array.from(updatedTasksInputs.values()).some(
            (taskInput) => {
              return taskInput.isDoneWithIssue;
            }
          );

          const assetItem = assets.get(tasksInputItem.addedAssetId);
          const updatedAssets = new Map(assets);

          if (assetItem) {
            updatedAssets.set(tasksInputItem.addedAssetId, {
              ...assetItem,
              isDoneWithIssue,
              isCompleted,
            });
          }

          setAssets(updatedAssets);
        } else {
          // For assets
          let isDoneWithIssue = false;
          let isCompleted = false;
          let completionPer = 0;

          const doneWithIssuesArray = [];
          const isCompletedArray = [];

          Array.from(updatedTasksInputs.values()).forEach((taskInput) => {
            if (taskInput.isCompleted) {
              isCompletedArray.push(taskInput.woTaskId);
              if (taskInput.isDoneWithIssue) {
                doneWithIssuesArray.push(taskInput.woTaskId);
              }
              completionPer += taskInput.addedWeightage;
            }
          });

          if (isCompletedArray.length === updatedTasksInputs.size) {
            isCompleted = true;
            if (doneWithIssuesArray.length > 0) {
              isDoneWithIssue = true;
            }
          }

          const assetItem = assets.get(tasksInputItem.addedAssetId);
          const updatedAssets = new Map(assets);

          if (assetItem) {
            updatedAssets.set(tasksInputItem.addedAssetId, {
              ...assetItem,
              isDoneWithIssue,
              isCompleted,
              completionPer,
            });
          }

          setAssets(updatedAssets);
          setExpandedAccordions((prev) => ({
            ...prev,
            [tasksInputItem.woTaskId]: true,
          }));
        }

        setTasksInputs(updatedTasksInputs);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setOpenErrorModal(true);
        handleError(err, setErrorDesc);
      });
  };

  const formatApiData = (fetchedApiData: PMTasksDataType) => {
    const updatedAssetsTasks: AssetsTaskType[] = [];
    const updatedTasksInputs = new Map<number, TasksInputsType>();
    const updatedExpandedAccordions: { [key: string]: boolean } = {};

    if (fetchedApiData.tasks?.length === 1) {
      fetchedApiData.tasks.forEach((task) => {
        const assetId = task.assetId;

        let asset = null;

        if (assetId !== 0) {
          asset = getAsset(fetchedApiData, assetId);
        } else {
          asset = {
            id: 0,
            sequence: 0,
            name: "Safety Checks After Completion",
            isCompleted: fetchedApiData.isPcChecklistCompleted,
            isDoneWithIssue: fetchedApiData.isPcChecklistMarkedNo,
            completionPer: 0,
          };
        }

        if (asset) {
          if (task.taskCheckLists?.length > 0) {
            const taskCheckLists = task.taskCheckLists;
            const groupTaskCheckLists: GroupTaskCheckListType[] = [];

            taskCheckLists.forEach((taskCheckList) => {
              const lineItem = getLineItem(
                fetchedApiData,
                taskCheckList.lineItemId
              );

              if (lineItem) {
                const taskGroupId = taskCheckList.taskGroupId || 0;
                const taskGroupName = taskCheckList.taskGroupName || "";

                const groupTaskCheckList = groupTaskCheckLists.find(
                  (group) => group.taskGroupId === taskGroupId
                );

                if (groupTaskCheckList) {
                  groupTaskCheckList.taskCheckLists.push({
                    ...taskCheckList,
                    lineItem,
                  });
                } else {
                  groupTaskCheckLists.push({
                    taskGroupId,
                    taskGroupName,
                    taskCheckLists: [
                      {
                        ...taskCheckList,
                        lineItem,
                      },
                    ],
                  });
                }

                updatedTasksInputs.set(taskCheckList.woTaskId, {
                  ...taskCheckList,
                  images: null,
                  addedWeightage: lineItem.weightage,
                  addedAssetId: assetId,
                });

                if (taskCheckList.isCompleted) {
                  updatedExpandedAccordions[taskCheckList.woTaskId] = true;
                }
              }
            });

            updatedAssetsTasks.push({
              assetId,
              asset,
              groupTaskCheckLists,
            });
          }
        }
      });
    }

    setAssetsTasks(updatedAssetsTasks);
    setTasksInputs(updatedTasksInputs);
    setExpandedAccordions(updatedExpandedAccordions);
  };

  const handleAccordionToggle = (id: string) => {
    setExpandedAccordions((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const getLineItem = (fetchedApiData: PMTasksDataType, id: number) =>
    fetchedApiData.lineItems?.find((lineItem) => lineItem.lineItemId === id) ||
    null;

  const getAsset = (fetchedApiData: PMTasksDataType, id: number) =>
    fetchedApiData.assets?.find((asset) => asset.id === id) || null;

  const getIsNA = (id: number) =>
    apiData.isNAResponse?.find((isNAitem) => isNAitem.id === id) || null;

  const handleActiveAssetIdChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    getPreventiveMaintenanceTasks(
      Number((event.target as HTMLInputElement).value)
    );
  };

  const addValidationError = (
    taskId: number,
    valueType: string,
    message: string
  ) => {
    setValidationError((prev) => ({
      ...prev,
      [`${taskId}_${valueType}`]: message,
    }));
  };

  const removeValidationError = (taskId: number, valueType: string) => {
    setValidationError((prev) => ({
      ...prev,
      [`${taskId}_${valueType}`]: undefined,
    }));
  };

  const getValidationError = (taskId: number, valueType: string) => {
    return validationError[`${taskId}_${valueType}`] || undefined;
  };

  const handleInputChange = (type: string, taskId: number, value: string) => {
    let naValue = 0;

    if (value === null || value === undefined || value?.trim() === "") {
      const validationErrorMsg =
        type === "capturedValue"
          ? "pm.capturedValueError"
          : type === "selectedNAId"
          ? "pm.selectedNAIdError"
          : "";
      if (type !== "remarks") {
        addValidationError(taskId, type, validationErrorMsg);
      }
    } else {
      removeValidationError(taskId, type);
      naValue = type === "selectedNAId" ? Number(value) : 0;
    }

    const updatedTasksInputs = new Map(tasksInputs);
    const updatedTaskInput = tasksInputs.get(taskId);
    if (updatedTaskInput) {
      updatedTasksInputs.set(taskId, {
        ...updatedTaskInput,
        [type]: type === "selectedNAId" ? naValue : value,
      });

      setTasksInputs(updatedTasksInputs);
    }
  };

  const handleSafetyInputChange = (taskId: number, value: string) => {
    let naValue = 0;

    if (
      value === null ||
      value === undefined ||
      value?.trim() === "" ||
      value === "0"
    ) {
      addValidationError(taskId, "selectedNAId", "pm.selectedNAIdError");
      return;
    } else {
      removeValidationError(taskId, "selectedNAId");
      naValue = Number(value) || 0;
    }

    const updatedTaskInput = tasksInputs.get(taskId);
    if (updatedTaskInput) {
      updatedTaskInput.selectedNAId = naValue;

      const isNA = getIsNA(naValue);
      if (isNA) {
        if (isNA.code === "No") {
          updatedTaskInput.isCompleted = false;
          updatedTaskInput.isDoneWithIssue = true;
        } else {
          updatedTaskInput.isCompleted = true;
          updatedTaskInput.isDoneWithIssue = false;
        }
      }

      // Call save api
      handleSavePreventiveMaintenanceTasksApi(updatedTaskInput);
    }
  };

  const handleTaskChecklistDone = (type: string, taskId: number) => {
    const tasksInputItem = tasksInputs.get(taskId);
    if (!tasksInputItem) return;

    const taskLineItem = getLineItem(apiData, tasksInputItem.lineItemId);
    if (!taskLineItem) return;

    let isError = false;

    if (
      taskLineItem.valueCapture &&
      tasksInputItem.capturedValue.trim() === ""
    ) {
      addValidationError(taskId, "capturedValue", "pm.capturedValueError");
      isError = true;
    } else {
      removeValidationError(taskId, "capturedValue");
    }

    if (taskLineItem.isNA && !tasksInputItem.selectedNAId) {
      addValidationError(taskId, "selectedNAId", "pm.selectedNAIdError");
      isError = true;
    } else {
      removeValidationError(taskId, "selectedNAId");
    }

    if (type === "report" && tasksInputItem.remarks.trim() === "") {
      addValidationError(taskId, "remarks", "pm.remarksError");
      isError = true;
    } else {
      removeValidationError(taskId, "remarks");
    }

    if (isError) return;

    tasksInputItem.isCompleted = true;
    tasksInputItem.isDoneWithIssue = type === "report" ? true : false;

    // Call save api
    handleSavePreventiveMaintenanceTasksApi(tasksInputItem);
  };

  let isEditableInput = isEditableBasedOnStatus
    ? {
        readOnly: false,
        disabled: false,
      }
    : {
        readOnly: true,
        disabled: true,
      };

  return (
    <Grid container>
      {isLoading && <Loader />}
      {openErrorModal && (
        <ErrorModal
          descriptionText={errorDesc}
          open={openErrorModal}
          handleClose={() => setOpenErrorModal(false)}
          onPositiveClick={() => {
            setOpenErrorModal(false);
          }}
        />
      )}
      {openModal && (
        <ModalPopup
          descriptionText={"SavedSuccessfully"}
          open={openModal}
          handleClose={() => setOpenModal(false)}
          onPositiveClick={() => setOpenModal(false)}
          hideButton={true}
          positiveButtonLabelId="OKButtonText"
        />
      )}
      <Grid
        item
        xs={9.5}
        sx={{
          borderRight: "1px solid #333b43",
        }}
      >
        <Box className="pm-tasks-form">
          <Box>
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <Box>
                <Box sx={{ padding: "20px 0px 0px 0px" }}>
                  <Box className="flex__" sx={{ marginBottom: "5px" }}>
                    <Box>
                      <MediumTypography
                        labelId="pm.taskListsText"
                        defaultLabel="Task Lists"
                        fontSize="20px"
                        fontWeight={500}
                        textColor="#FFFFFF"
                      />
                    </Box>
                    <Box sx={{ marginLeft: "auto" }}>
                      <Grid
                        className="flex__ justifyContent-FlexEnd"
                        container
                        direction="row"
                        alignItems="right"
                      ></Grid>
                    </Box>
                  </Box>
                  <Grid
                    container
                    direction={"row"}
                    className="justifyContentSpaceBtw"
                    alignItems={"center"}
                    rowSpacing={"16px"}
                    columnSpacing={"40px"}
                    // sx={{ marginBottom: "20px", marginTop: "10px" }}
                  >
                    <Grid
                      item
                      sx={{
                        width: "100%",
                        marginRight: "16px",
                      }}
                    >
                      <Grid container className="task-list-wrapper">
                        <Grid item xs={4}>
                          {assets.size > 0 && (
                            <Box
                              className="p-sm messageScroll"
                              sx={{
                                height: isVisible
                                  ? availableHeight
                                  : collapseAvailHeight,
                                overflowY: "auto",
                              }}
                              // maxHeight={"550px"}
                              // minHeight={"550px"}
                              // overflow={"scroll"}
                            >
                              <FormControl fullWidth>
                                <RadioGroup
                                  defaultValue={activeAssetId}
                                  name="asset-radio-buttons-group"
                                  onChange={handleActiveAssetIdChange}
                                >
                                  <Box>
                                    {Array.from(assets.values()).map(
                                      (assetData) => {
                                        return (
                                          <Box
                                            className="radio-label-wrapper"
                                            key={assetData.id}
                                          >
                                            <Box>
                                              <FormControlLabel
                                                className="radio-label"
                                                value={assetData.id}
                                                control={<Radio />}
                                                label={assetData.name}
                                              />
                                            </Box>
                                            {assetData.id !== 0 && (
                                              <Box className="progress-check-holder">
                                                {assetData.completionPer >
                                                  0 && (
                                                  <LinearProgressBar
                                                    value={
                                                      assetData.completionPer
                                                    }
                                                    height={6}
                                                  />
                                                )}
                                                <CompletionStatusIcon
                                                  status={
                                                    assetData.isDoneWithIssue
                                                      ? "checkedNegative"
                                                      : assetData.isCompleted
                                                      ? "checkedPositive"
                                                      : "hidden"
                                                  }
                                                />
                                              </Box>
                                            )}
                                            {assetData.id === 0 && (
                                              <Box className="progress-check-holder">
                                                <CompletionStatusIcon
                                                  status={
                                                    assetData.isDoneWithIssue
                                                      ? "checkedNegative"
                                                      : assetData.isCompleted
                                                      ? "checkedPositive"
                                                      : "hidden"
                                                  }
                                                  checkedNegativeIcon={
                                                    <CancelIcon
                                                      sx={{ color: "#c9372c" }}
                                                    />
                                                  }
                                                />
                                              </Box>
                                            )}
                                          </Box>
                                        );
                                      }
                                    )}
                                  </Box>
                                </RadioGroup>
                              </FormControl>
                            </Box>
                          )}
                        </Grid>
                        <Grid item xs={8}>
                          <Box className="">
                            {assetsTasks.length === 1 &&
                              assetsTasks.map((assetsTask) => {
                                if (assetsTask.assetId !== activeAssetId)
                                  return <></>;

                                let sequenceCount = 0;

                                return (
                                  <Box
                                    sx={{
                                      height: isVisible
                                        ? availableHeight
                                        : collapseAvailHeight,
                                      overflowY: "auto",
                                    }}
                                    className={`asset-level-box p-sm messageScroll`}
                                    key={assetsTask.assetId}
                                  >
                                    {assetsTask.groupTaskCheckLists.map(
                                      (groupTaskCheckList) => {
                                        return (
                                          <Box
                                            className="group-level-box"
                                            key={groupTaskCheckList.taskGroupId}
                                          >
                                            <Box className="group-title-box">
                                              {groupTaskCheckList.taskGroupName && (
                                                <MediumTypography
                                                  sxProps={{ mb: "8px" }}
                                                  label={
                                                    groupTaskCheckList.taskGroupName
                                                  }
                                                />
                                              )}
                                            </Box>
                                            <Box className="task-input-wrapper">
                                              {groupTaskCheckList.taskCheckLists.map(
                                                (taskCheckList) => {
                                                  sequenceCount++;

                                                  const lineItem =
                                                    taskCheckList.lineItem;

                                                  const uniqueTaskId =
                                                    taskCheckList.woTaskId.toString();

                                                  const taskCheckListInput =
                                                    tasksInputs.get(
                                                      taskCheckList.woTaskId
                                                    );

                                                  if (!taskCheckListInput)
                                                    return <></>;

                                                  let showOnExpand = "";
                                                  let showOnCollapse = "hide";

                                                  if (
                                                    expandedAccordions[
                                                      uniqueTaskId
                                                    ]
                                                  ) {
                                                    showOnExpand = "hide";
                                                    showOnCollapse = "";
                                                  }

                                                  let showReference = false;
                                                  let showActual = false;
                                                  let showImages = false;
                                                  let showReadDivider = false;
                                                  let showReadBox = false;
                                                  let showReadWriteDivider =
                                                    false;

                                                  if (
                                                    lineItem.referenceValue?.trim()
                                                  ) {
                                                    showReference = true;
                                                  }

                                                  if (
                                                    lineItem.valueCapture &&
                                                    taskCheckListInput.capturedValue?.trim()
                                                  ) {
                                                    showActual = true;
                                                  }

                                                  if (
                                                    lineItem.imageCapture &&
                                                    taskCheckList.images
                                                      ?.length > 0
                                                  ) {
                                                    showImages = true;
                                                  }

                                                  if (
                                                    showReference &&
                                                    showActual
                                                  ) {
                                                    showReadDivider = true;
                                                  }

                                                  if (
                                                    showReference ||
                                                    showActual ||
                                                    showImages
                                                  ) {
                                                    showReadBox = true;
                                                  }

                                                  if (
                                                    (showOnCollapse === "" &&
                                                      showReadBox) ||
                                                    showOnExpand === ""
                                                  ) {
                                                    showReadWriteDivider = true;
                                                  }

                                                  let columnWidths = {
                                                    reference: 6,
                                                    valueCapture: 6,
                                                    isNA: 12,
                                                    imageCapture: 12,
                                                    remarks: 12,
                                                  };

                                                  if (
                                                    !showReference &&
                                                    !lineItem.isNA
                                                  ) {
                                                    if (lineItem.valueCapture) {
                                                      columnWidths.remarks = 6;
                                                    }
                                                  }

                                                  return (
                                                    <Box
                                                      className="task-level-box task-input-item task-accordion"
                                                      key={
                                                        taskCheckList.woTaskId
                                                      }
                                                    >
                                                      <Box
                                                        className="task-input-title-holder"
                                                        onClick={() => {
                                                          assetsTask.assetId !==
                                                            0 &&
                                                            handleAccordionToggle(
                                                              uniqueTaskId
                                                            );
                                                        }}
                                                      >
                                                        <MediumTypography
                                                          label={`${sequenceCount}. ${lineItem.description}`}
                                                        />
                                                        {assetsTask.assetId !==
                                                          0 && (
                                                          <CompletionStatusIcon
                                                            status={
                                                              taskCheckListInput.isDoneWithIssue
                                                                ? "checkedNegative"
                                                                : taskCheckListInput.isCompleted
                                                                ? "checkedPositive"
                                                                : "hidden"
                                                            }
                                                          />
                                                        )}
                                                      </Box>
                                                      {assetsTask.assetId !==
                                                        0 &&
                                                        showReadWriteDivider && (
                                                          <Box className="">
                                                            <Divider className="task-input-divider" />
                                                          </Box>
                                                        )}
                                                      {assetsTask.assetId !==
                                                        0 &&
                                                        showReadBox && (
                                                          <Box
                                                            className={`task-input-read ${showOnCollapse}`}
                                                          >
                                                            <Box className="texts-holder">
                                                              {showReference && (
                                                                <>
                                                                  <MediumTypography
                                                                    labelId="pm.referenceText"
                                                                    defaultLabel="Reference :"
                                                                  />
                                                                  <MediumTypography
                                                                    label={
                                                                      lineItem.referenceValue
                                                                    }
                                                                  />
                                                                </>
                                                              )}
                                                              {showReadDivider && (
                                                                <MediumTypography label="|" />
                                                              )}
                                                              {showActual && (
                                                                <>
                                                                  <MediumTypography
                                                                    labelId="pm.actualText"
                                                                    defaultLabel="Actual :"
                                                                  />
                                                                  <MediumTypography
                                                                    label={
                                                                      taskCheckListInput.capturedValue
                                                                    }
                                                                  />
                                                                </>
                                                              )}
                                                            </Box>

                                                            {showActual &&
                                                              taskCheckListInput.remarks !==
                                                                "" && (
                                                                <Box
                                                                  className="flex__ "
                                                                  sx={{
                                                                    gap: "5px",
                                                                  }}
                                                                >
                                                                  <>
                                                                    <MediumTypography
                                                                      labelId="RemarksText"
                                                                      defaultLabel="Remarks : "
                                                                    />
                                                                    <MediumTypography
                                                                      label={
                                                                        taskCheckListInput.remarks
                                                                      }
                                                                    />
                                                                  </>
                                                                </Box>
                                                              )}

                                                            {showImages && (
                                                              <Box className="task-input-image-read">
                                                                <WorkOrderGallery
                                                                  dumgImage={[]}
                                                                  beforeImage={[]}
                                                                  afterImage={[]}
                                                                  pmTaskImage={
                                                                    taskCheckList.images
                                                                  }
                                                                  titlesRequired={
                                                                    false
                                                                  }
                                                                />
                                                              </Box>
                                                            )}
                                                          </Box>
                                                        )}
                                                      {
                                                        // !taskCheckList.isCompleted &&
                                                        //   !taskCheckList.isDoneWithIssue &&
                                                        <Box
                                                          className={`task-input-write ${
                                                            assetsTask.assetId !==
                                                            0
                                                              ? showOnExpand
                                                              : "show"
                                                          }`}
                                                        >
                                                          <Grid
                                                            container
                                                            spacing={2}
                                                          >
                                                            {assetsTask.assetId !==
                                                              0 && (
                                                              <>
                                                                {showReference && (
                                                                  <Grid
                                                                    item
                                                                    xs={6}
                                                                  >
                                                                    <MediumTypography
                                                                      className="task-input-label"
                                                                      labelId="pm.referenceValueText"
                                                                      defaultLabel="Reference Value"
                                                                    />
                                                                    <TextInput
                                                                      className="task-input-field"
                                                                      variant="outlined"
                                                                      Value={
                                                                        lineItem.referenceValue
                                                                      }
                                                                      inputProps={{
                                                                        readOnly:
                                                                          true,
                                                                        disabled:
                                                                          true,
                                                                      }}
                                                                    />
                                                                  </Grid>
                                                                )}

                                                                {lineItem.valueCapture && (
                                                                  <Grid
                                                                    item
                                                                    xs={
                                                                      columnWidths.valueCapture
                                                                    }
                                                                  >
                                                                    <MediumTypography
                                                                      className="task-input-label"
                                                                      labelId="pm.actualValueText"
                                                                      defaultLabel="Actual Value"
                                                                    />
                                                                    <TextInput
                                                                      className="task-input-field"
                                                                      variant="outlined"
                                                                      Value={
                                                                        taskCheckListInput.capturedValue
                                                                      }
                                                                      handlechange={(
                                                                        value: string
                                                                      ) => {
                                                                        handleInputChange(
                                                                          "capturedValue",
                                                                          taskCheckList.woTaskId,
                                                                          value
                                                                        );
                                                                      }}
                                                                      inputProps={
                                                                        isEditableInput
                                                                      }
                                                                    />

                                                                    {isEditableBasedOnStatus &&
                                                                      getValidationError(
                                                                        taskCheckList.woTaskId,
                                                                        "capturedValue"
                                                                      ) && (
                                                                        <Box className="loginError input-error">
                                                                          <Box>
                                                                            <ErrorIcon />
                                                                          </Box>
                                                                          <MediumTypography
                                                                            labelId={getValidationError(
                                                                              taskCheckList.woTaskId,
                                                                              "capturedValue"
                                                                            )}
                                                                            defaultLabel="Please enter a valid Actual Value"
                                                                          />
                                                                        </Box>
                                                                      )}
                                                                  </Grid>
                                                                )}
                                                              </>
                                                            )}

                                                            {lineItem.isNA && (
                                                              <Grid
                                                                item
                                                                xs={
                                                                  columnWidths.isNA
                                                                }
                                                              >
                                                                <FormControl
                                                                  className="input-radio-boxes-fc"
                                                                  fullWidth
                                                                >
                                                                  <RadioGroup
                                                                    row
                                                                    name="input-radio-buttons-group"
                                                                    value={
                                                                      taskCheckListInput.selectedNAId
                                                                    }
                                                                    onChange={(
                                                                      event
                                                                    ) => {
                                                                      assetsTask.assetId ===
                                                                      0
                                                                        ? handleSafetyInputChange(
                                                                            taskCheckList.woTaskId,
                                                                            event
                                                                              .target
                                                                              .value
                                                                          )
                                                                        : handleInputChange(
                                                                            "selectedNAId",
                                                                            taskCheckList.woTaskId,
                                                                            event
                                                                              .target
                                                                              .value
                                                                          );
                                                                    }}
                                                                  >
                                                                    {apiData.isNAResponse?.map(
                                                                      (
                                                                        isNAitem
                                                                      ) => {
                                                                        return (
                                                                          <FormControlLabel
                                                                            key={
                                                                              isNAitem.id
                                                                            }
                                                                            className="input-radio-label"
                                                                            value={
                                                                              isNAitem.id
                                                                            }
                                                                            control={
                                                                              <Radio />
                                                                            }
                                                                            label={
                                                                              isNAitem.name
                                                                            }
                                                                            disabled={
                                                                              !isEditableBasedOnStatus
                                                                            }
                                                                          />
                                                                        );
                                                                      }
                                                                    )}
                                                                  </RadioGroup>
                                                                </FormControl>

                                                                {isEditableBasedOnStatus &&
                                                                  getValidationError(
                                                                    taskCheckList.woTaskId,
                                                                    "selectedNAId"
                                                                  ) && (
                                                                    <Box className="loginError input-error">
                                                                      <Box>
                                                                        <ErrorIcon />
                                                                      </Box>
                                                                      <MediumTypography
                                                                        labelId={getValidationError(
                                                                          taskCheckList.woTaskId,
                                                                          "selectedNAId"
                                                                        )}
                                                                        defaultLabel="Please select an option"
                                                                      />
                                                                    </Box>
                                                                  )}
                                                              </Grid>
                                                            )}

                                                            {assetsTask.assetId !==
                                                              0 && (
                                                              <>
                                                                {showImages && (
                                                                  <Grid
                                                                    className="task-input-image-item-container"
                                                                    item
                                                                    xs={
                                                                      columnWidths.imageCapture
                                                                    }
                                                                  >
                                                                    <Box className="task-input-image-write">
                                                                      <WorkOrderGallery
                                                                        dumgImage={
                                                                          taskCheckList.images
                                                                        }
                                                                        beforeImage={[]}
                                                                        afterImage={[]}
                                                                        titlesRequired={
                                                                          false
                                                                        }
                                                                      />
                                                                    </Box>
                                                                  </Grid>
                                                                )}

                                                                <Grid
                                                                  item
                                                                  xs={
                                                                    columnWidths.remarks
                                                                  }
                                                                >
                                                                  <MediumTypography
                                                                    className="task-input-label"
                                                                    labelId="RemarksLabelText"
                                                                    defaultLabel="Remarks"
                                                                  />

                                                                  <TextField
                                                                    className="text-area-custom" // Add the custom class name here
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    multiline
                                                                    minRows={
                                                                      columnWidths.remarks ===
                                                                      12
                                                                        ? 2
                                                                        : 1
                                                                    }
                                                                    value={
                                                                      taskCheckListInput.remarks
                                                                    }
                                                                    onChange={(
                                                                      event
                                                                    ) => {
                                                                      handleInputChange(
                                                                        "remarks",
                                                                        taskCheckList.woTaskId,
                                                                        event
                                                                          .target
                                                                          .value
                                                                      );
                                                                    }}
                                                                    inputProps={
                                                                      isEditableInput
                                                                    }
                                                                  />

                                                                  {isEditableBasedOnStatus &&
                                                                    getValidationError(
                                                                      taskCheckList.woTaskId,
                                                                      "remarks"
                                                                    ) && (
                                                                      <Box className="loginError input-error">
                                                                        <Box>
                                                                          <ErrorIcon />
                                                                        </Box>
                                                                        <MediumTypography
                                                                          labelId={getValidationError(
                                                                            taskCheckList.woTaskId,
                                                                            "remarks"
                                                                          )}
                                                                          defaultLabel="Please enter remarks"
                                                                        />
                                                                      </Box>
                                                                    )}
                                                                </Grid>

                                                                {isEditableBasedOnStatus && (
                                                                  <Grid
                                                                    item
                                                                    xs={12}
                                                                  >
                                                                    <Box
                                                                      display={
                                                                        "flex"
                                                                      }
                                                                      justifyContent={
                                                                        "flex-end"
                                                                      }
                                                                    >
                                                                      <ButtonComponent
                                                                        sxProps={{
                                                                          color:
                                                                            "#B6C2CF !important",
                                                                        }}
                                                                        className="btn-primary btn-cancel"
                                                                        variantType="outlined"
                                                                        defaultLabelId="Report Issue"
                                                                        labelId="pm.reportIssueBtnText"
                                                                        onClick={() => {
                                                                          handleTaskChecklistDone(
                                                                            "report",
                                                                            taskCheckList.woTaskId
                                                                          );
                                                                        }}
                                                                      />
                                                                      <ButtonComponent
                                                                        className="btn-primary btn-submit"
                                                                        variantType="contained"
                                                                        defaultLabelId="Done"
                                                                        labelId="pm.doneBtnText"
                                                                        onClick={() => {
                                                                          handleTaskChecklistDone(
                                                                            "done",
                                                                            taskCheckList.woTaskId
                                                                          );
                                                                        }}
                                                                      />
                                                                    </Box>
                                                                  </Grid>
                                                                )}
                                                              </>
                                                            )}
                                                          </Grid>
                                                        </Box>
                                                      }
                                                    </Box>
                                                  );
                                                }
                                              )}
                                            </Box>
                                          </Box>
                                        );
                                      }
                                    )}
                                  </Box>
                                );
                              })}
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
                <Grid container className="mt-xs">
                  <Grid item xs={12} lg={12} md={12} xl={12}>
                    <Box>
                      <Divider
                        variant="fullWidth"
                        sx={{
                          borderTop: "1px solid #333b43",
                        }}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </form>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={2.5}>
        <WorkOrderActivity isVisible={isVisible} cardId={stateCardId} />
      </Grid>
    </Grid>
  );
};

export default PreventiveMaintenanceTasks;
