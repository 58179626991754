import { Box, Grid, Tooltip } from "@mui/material";
import { TeamMemberForShift } from "../../utils/type";
import MediumTypography from "./MediumTypography";
import { FC } from "react";
import CheckBox from "./CheckBox";
import { ReactComponent as DragIcon } from "../../assets/images/DragIcon.svg";
import UserprofileIcon from "../../assets/images/profile-circle.svg";

interface TeamMemberwithStatusProps {
  data: TeamMemberForShift;
  value?: boolean;
  onClickChange?: (checked: boolean) => void;
  statusNotRequired?: boolean;
}
const TeamMemberWithStatusComponent: FC<TeamMemberwithStatusProps> = ({
  data,
  value,
  onClickChange,
  statusNotRequired,
}) => {
  return (
    <Box
      sx={{
        width: "250px",
      }}
    >
      <Grid container className="teamMemberCard ">
        <Grid
          item
          className="flex__ align__items__center pt-md pb-md pl-md"
          sx={{ position: "relative" }}
        >
          <div
            style={{
              width: "45px",
              height: "45px",
              backgroundImage: data.image
                ? `url(${data.image})`
                : `url(${UserprofileIcon})`, // Use imported image
              backgroundSize: "cover",
              backgroundPosition: "center",
              borderRadius: "50%",
            }}
          ></div>
        </Grid>
        <Grid item xs={8} className="ml-xs pt-md pb-sm">
          <Box className="flex__" sx={{ justifyContent: "space-between" }}>
            <Box className={!statusNotRequired ? "mt-none" : "mt-xs"}>
              <Tooltip
                title={data.name && data.name.length > 15 ? data.name : ""}
                arrow={true}
                followCursor
              >
                <Box>
                  <MediumTypography
                    label={
                      data.name === "" || data.name === null ? "-" : data.name
                    }
                    className="ml-xs ellipsis"
                    fontSize="14px"
                    sxProps={{
                      width: data.name.length > 15 ? "120px" : "auto",
                    }}
                  />
                </Box>
              </Tooltip>
              <Tooltip
                title={data.title && data.title.length > 15 ? data.title : ""}
                arrow={true}
                followCursor
              >
                <Box>
                  <MediumTypography
                    label={
                      data.title === "" || data.title === null
                        ? "-"
                        : data.title
                    }
                    className=" ml-xs ellipsis"
                    fontSize="12px"
                    sxProps={{
                      width: data.title.length > 15 ? "150px" : "auto",
                      opacity: 0.64,
                    }}
                  />
                </Box>
              </Tooltip>
              {!statusNotRequired && (
                <Box
                  sx={{
                    backgroundColor:
                      data.statusCode === "AVAILABLE"
                        ? "rgba(19, 196, 19, 0.24)"
                        : data.statusCode === "NO SHOW"
                        ? "rgba(255, 0, 0, 0.24)"
                        : "",
                    width: "max-content",
                    borderRadius: "2px",
                  }}
                  className={"ml-xs mt-xs"}
                >
                  <MediumTypography
                    label={data.status}
                    className="ml-xs pr-xs"
                    fontSize="12px"
                    sxProps={{
                      color:
                        data.statusCode === "AVAILABLE"
                          ? "#4BE74B"
                          : data.statusCode === "NO SHOW"
                          ? "#C9372C"
                          : "#FFFFFF",
                    }}
                  />
                </Box>
              )}
            </Box>

            <Box className="ml-sm">
              <CheckBox
                value={value === undefined ? false : value}
                onCheckBoxClick={(checked) => {
                  if (onClickChange) {
                    onClickChange(checked);
                  }
                }}
                className="p-none"
              />
              <Box className="flex__ align__items__center flex__justify__center mt-sm">
                <DragIcon />
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TeamMemberWithStatusComponent;
