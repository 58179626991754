import { Box, Dialog, DialogContent, DialogTitle, Grid } from "@mui/material";
import React, { FC } from "react";
import MediumTypography from "../MediumTypography";
import ButtonComponent from "../ButtonComponent";

interface ModalProps {
  open: boolean;
  handleClose: () => void;
  onOkClick?: () => void;
  descriptionTexts?: string[];
  descriptionSuccess?: string;
  isValid?: boolean;
  onImportClick?: () => void;
  onCancelClick?: () => void;
  onImportWithVersionClick?: () => void;
  languageCode?: string;
  selectedComponent?: string;
}

const ValidationErrorModal: FC<ModalProps> = ({
  open,
  descriptionTexts,
  onOkClick,
  isValid,
  descriptionSuccess,
  onImportClick,
  onCancelClick,
  onImportWithVersionClick,
  languageCode,
  selectedComponent,
}) => {
  return (
    <Dialog open={open} fullWidth maxWidth={"sm"}>
      {!isValid && (
        <DialogTitle sx={{ backgroundColor: "#1D2125" }}>
          <MediumTypography
            labelId={"ValidationErrorModal.headerText"}
            defaultLabel="Validation Error"
            textColor="#B6C2CF"
            fontSize="20px"
            fontWeight={700}
          />
        </DialogTitle>
      )}

      <DialogContent sx={{ backgroundColor: "#1D2125" }}>
        <Box>
          {descriptionTexts && descriptionTexts.length > 0 && (
            <Box sx={{ color: "red", textAlign: "center" }}>
              {descriptionTexts.map((error, index) => (
                <MediumTypography
                  key={index}
                  label={error}
                  textColor="red"
                  fontSize="16px"
                  fontWeight={400}
                />
              ))}
            </Box>
          )}
          {descriptionSuccess && (
            <Box>
              <MediumTypography labelId={descriptionSuccess} fontSize="16px" />
            </Box>
          )}
          <Box className="horizontalLine" sx={{ marginY: "10px" }} />
          <Box>
            <Grid
              className="flex__ justifyContent-FlexEnd"
              container
              direction="row"
              alignItems="right"
            >
              {!isValid ? (
                <Grid item>
                  <ButtonComponent
                    className="btn-primary btn-submit mr-md"
                    variantType="contained"
                    defaultLabelId={"Ok"}
                    labelId={"OkText"}
                    onClick={onOkClick}
                  />
                </Grid>
              ) : (
                <>
                  <Grid item>
                    <ButtonComponent
                      className="btn-primary btn-submit mr-md"
                      variantType="contained"
                      defaultLabelId={"Import"}
                      labelId={"importText"}
                      onClick={onImportClick}
                    />
                    {languageCode === "en" &&
                      selectedComponent !== "ManageSkills" &&
                      selectedComponent !== "ManageCheckListLineItem" &&
                      selectedComponent !== "ManageFrequency" &&
                      selectedComponent !== "ManagePermitLineItemsAndOptions" &&
                      selectedComponent !== "ManageReasonForDeviation" &&
                      selectedComponent !== "ManageDepartments" &&
                      selectedComponent !== "site" &&
                      selectedComponent !== "subgroup" &&
                      selectedComponent !== "assetGroup" &&
                      selectedComponent !== "assets" &&
                      selectedComponent !== "holidays" && (
                        <ButtonComponent
                          className="btn-primary btn-submit mr-md"
                          variantType="contained"
                          defaultLabelId={"Import with version"}
                          labelId={"importWithVersionText"}
                          onClick={onImportWithVersionClick}
                        />
                      )}

                    <ButtonComponent
                      className="btn-primary btn-cancel"
                      variantType="contained"
                      defaultLabelId={"Cancel"}
                      labelId={"CancelBtnText"}
                      onClick={onCancelClick}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ValidationErrorModal;
