// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ql-toolbar.ql-snow {
    background: #1d2125;
    border: 1px solid rgba(166, 197, 226, 0.16);
    border-radius: 4px;
  }
  
  .ql-container {
    min-height: 300px;
    background: #282e33;
    border-radius: 4px;
  }
  .ql-container.ql-snow {
    border: 1px solid rgba(166, 197, 226, 0.16);
  }
  .ql-snow .ql-stroke {
    stroke: #9fadbc;
  }
  
  .ql-snow .ql-picker {
    color: #9fadbc;
  }
  
  .ql-editor {
    color: #8c9bab;
    min-height: 500px;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/workOrderDetailScreens/correctiveMaintenance/RichTextEditor.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,2CAA2C;IAC3C,kBAAkB;EACpB;;EAEA;IACE,iBAAiB;IACjB,mBAAmB;IACnB,kBAAkB;EACpB;EACA;IACE,2CAA2C;EAC7C;EACA;IACE,eAAe;EACjB;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,cAAc;IACd,iBAAiB;EACnB","sourcesContent":[".ql-toolbar.ql-snow {\n    background: #1d2125;\n    border: 1px solid rgba(166, 197, 226, 0.16);\n    border-radius: 4px;\n  }\n  \n  .ql-container {\n    min-height: 300px;\n    background: #282e33;\n    border-radius: 4px;\n  }\n  .ql-container.ql-snow {\n    border: 1px solid rgba(166, 197, 226, 0.16);\n  }\n  .ql-snow .ql-stroke {\n    stroke: #9fadbc;\n  }\n  \n  .ql-snow .ql-picker {\n    color: #9fadbc;\n  }\n  \n  .ql-editor {\n    color: #8c9bab;\n    min-height: 500px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
