import React, { FC, useState } from "react";
import { Box, TextField } from "@mui/material";
import {
  BaseSingleInputFieldProps,
  DatePicker,
  DatePickerProps,
  DateValidationError,
  FieldSection,
  LocalizationProvider,
  UseDateFieldProps,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dayjs } from "dayjs";
import { ReactComponent as LeftArrow } from "../../assets/images/LeftArrow.svg";
import { ReactComponent as RightArrow } from "../../assets/images/RightArrow.svg";

interface TextFieldProps
  extends UseDateFieldProps<Dayjs, false>,
    BaseSingleInputFieldProps<
      Dayjs | null,
      Dayjs,
      FieldSection,
      false,
      DateValidationError
    > {
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

interface CalendarWeekProps {
  date: Dayjs;
  onDateSelect: (newDate: Dayjs) => void;
  disableFutureDate?: boolean;
}

const CustomTextField: FC<TextFieldProps> = (props) => {
  const { label, id, setOpen, InputProps: { ref } = {} } = props;
  return (
    <Box onClick={() => setOpen?.((prev) => !prev)} display={"flex"}>
      <TextField
        id={id}
        ref={ref}
        value={label}
        className={"date-text-field"}
        sx={{
          "& fieldset": { border: "none" },
          "& .MuiOutlinedInput-root": {
            height: "40px",
            fontFamily: "Inter",
            fontSize: "16px",
            color: "#FFFFFF",
            cursor: "pointer",
            padding: "0px",
          },
        }}
      />
    </Box>
  );
};

const CustomDatePicker = (
  props: Omit<DatePickerProps<Dayjs>, "open" | "onOpen" | "onClose">
) => {
  const [open, setOpen] = useState(false);

  return (
    <DatePicker
      {...props}
      slots={{ ...props.slots, field: CustomTextField }}
      slotProps={{
        ...props.slotProps,
        field: { setOpen } as never,
      }}
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
    />
  );
};

const WeekDatePicker: FC<CalendarWeekProps> = ({
  date,
  onDateSelect,
  disableFutureDate,
}) => {
  const [selectedDate, setSelectedDate] = useState<Dayjs>(date);
  function getStartOfWeek(startDate: Dayjs) {
    const currentDay = startDate.day();
    const offset = currentDay === 0 ? -6 : 1 - currentDay;
    return startDate.add(offset, "day");
  }

  function getEndOfWeek(endDate: Dayjs) {
    const currentDay = endDate.day();
    const offset = currentDay === 0 ? 0 : 7 - currentDay;
    return endDate.add(offset, "day");
  }

  const startDate = getStartOfWeek(selectedDate);
  const endDate = getEndOfWeek(selectedDate);

  const handleDateChange = (newDate: Dayjs | null) => {
    if (newDate !== null) {
      setSelectedDate(newDate);
      onDateSelect(newDate);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box
        className={"flex__"}
        sx={{
          cursor: "pointer",
          paddingLeft: "8px",
          paddingRight: "8px",
          borderRadius: "4px",
          border: "1px solid rgba(166, 197, 226, 0.16)",
          backgroundColor: "#22272B",
        }}
      >
        <Box
          sx={{ display: "flex", padding: "8px", cursor: "pointer" }}
          alignItems={"center"}
          onClick={() => handleDateChange(startDate.subtract(1, "week"))}
        >
          <LeftArrow width={"14px"} height={"14px"} />
        </Box>
        <Box>
          <CustomDatePicker
            label={`${startDate.format("DD MMM YYYY")} - ${endDate.format(
              "DD MMM YYYY"
            )}`}
            disableFuture={disableFutureDate}
            value={selectedDate}
            onChange={handleDateChange}
          />
        </Box>
        <Box
          sx={{ display: "flex", padding: "8px", cursor: "pointer" }}
          alignItems={"center"}
          onClick={() => handleDateChange(startDate.add(1, "week"))}
        >
          <RightArrow width={"14px"} height={"14px"} />
        </Box>
      </Box>
    </LocalizationProvider>
  );
};

export default WeekDatePicker;
