import { Box, Button, Tabs, Tooltip } from "@mui/material";
import MediumTypography from "./MediumTypography";
import React, { useState, FC, useCallback, useEffect } from "react";
import { StatusType } from "../../utils/type";
import { ReactComponent as RejectedIcon } from "../../assets/images/close-circle.svg";
import { ReactComponent as ExpiredIcon } from "../../assets/images/calendar-remove.svg";
import { ReactComponent as OnHoldStatusIcon } from "../../assets/images/OnHoldStatusIcon.svg";
import { ReactComponent as SCHEDULED } from "../../assets/images/SCHEDULED.svg";
import { ReactComponent as PauseIcon } from "../../assets/images/pause.svg";

interface WorkOrderStatusProps {
  onSelectTabs: (selectedTabs: number[]) => void;
  status: StatusType[];
  totalWOStatusCount: number;
  selectedWOStatusFromParent: number[] | null;
}

const WorkOrderStatus: FC<WorkOrderStatusProps> = ({
  onSelectTabs,
  status,
  totalWOStatusCount,
  selectedWOStatusFromParent,
}) => {
  const [selectedTabs, setSelectedTabs] = useState<number[]>([0]);

  useEffect(() => {
    if (selectedWOStatusFromParent && selectedWOStatusFromParent.length > 0) {
      setSelectedTabs(selectedWOStatusFromParent);
    } else {
      setSelectedTabs([0]);
    }
  }, [selectedWOStatusFromParent]);

  const handleStatusTabClick = useCallback(
    (tabIndex: number) => {
      let updatedTabs: number[];
      if (selectedTabs.includes(0)) {
        updatedTabs = [tabIndex];
      } else if (tabIndex === 0) {
        updatedTabs = [0];
      } else if (selectedTabs.includes(tabIndex)) {
        updatedTabs = selectedTabs.filter((tab) => tab !== tabIndex);
      } else {
        updatedTabs = [...selectedTabs, tabIndex];
      }
      setSelectedTabs(updatedTabs);
      onSelectTabs(updatedTabs); // Immediately pass the updated tabs to the parent
    },
    [onSelectTabs, selectedTabs]
  );

  return (
    <Box>
      <Box
        sx={{
          // bgcolor: "background.paper",
          "@media (max-width: 1280px)": {
            maxWidth: 280,
          },
          "@media (min-width: 1300px) and (max-width: 1400px)": {
            maxWidth: 280,
          },
          "@media (min-width: 1500px) and (max-width: 1900px)": {
            maxWidth: 310,
          },
          "@media (min-width: 1900px)": {
            maxWidth: 400,
          },
        }}
      >
        <Tabs
          className="workOrderStatus_parent"
          value={selectedTabs}
          variant="scrollable"
          scrollButtons={false}
          aria-label="scrollable auto tabs example"
        >
          <Button
            className={`button-ui p-xs status_btn_parent ${
              selectedTabs.includes(0) ? "active" : ""
            } flex__`}
            onClick={() => handleStatusTabClick(0)}
          >
            <MediumTypography
              labelId="ALL"
              defaultLabel="ALL"
              fontSize="12px"
              fontWeight={600}
              className="mr-xs"
            />
            <MediumTypography
              label={totalWOStatusCount?.toString()}
              fontSize="12px"
              fontWeight={600}
            />
          </Button>

          {status &&
            status.length > 0 &&
            status.map((item, index) => (
              <>
                {item.count > 0 && (
                  <Tooltip title={item.name} arrow={true} followCursor>
                    <Button
                      className={`button-ui p-xs status_btn_parent ${
                        selectedTabs.includes(item.id) ? "active" : ""
                      } ${
                        item.name === "REJECTED"
                          ? "status_variant_red"
                          : item.name === "ON HOLD"
                          ? "status_variant_Yellow"
                          : item.name === "SCHEDULED" ||
                            item.name === "DISPATCHED" ||
                            item.name === "COMPLETED" ||
                            item.name === "IN PROGRESS"
                          ? "status_variant_Green"
                          : item.name === "EXPIRED"
                          ? "status_variant_grey"
                          : item.name === "PAUSED"
                          ? "status_variant_Blue"
                          : "status_variant_Orange"
                      } flex__`}
                      key={item.id}
                      onClick={() => handleStatusTabClick(item.id)}
                    >
                      {item.name === "SCHEDULED" ||
                      item.name === "DISPATCHED" ||
                      item.name === "IN PROGRESS" ? (
                        <SCHEDULED className="status_btn_icon" />
                      ) : item.name === "PAUSED" ? (
                        <PauseIcon className="status_btn_icon" />
                      ) : item.name === "REJECTED" ? (
                        <RejectedIcon className="status_btn_icon" />
                      ) : item.name === "EXPIRED" ? (
                        <ExpiredIcon className="status_btn_icon" />
                      ) : item.name === "ON HOLD" ? (
                        <OnHoldStatusIcon className="status_btn_icon" />
                      ) : (
                        <SCHEDULED className="status_btn_icon" />
                      )}

                      <MediumTypography
                        label={item.count.toString()}
                        fontSize="12px"
                        fontWeight={600}
                      />
                    </Button>
                  </Tooltip>
                )}
              </>
            ))}
        </Tabs>
      </Box>
    </Box>
  );
};

export default WorkOrderStatus;
