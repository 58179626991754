import { ApiResponseHandler } from "../ApiResponseHandler";
import WorkOrderConfig from "../WorkOrderConfig";
import {
  GCPlannerDataType,
  GCPlannerSaveDataType,
} from "../../pages/planner/plannerTypes";

export const getGrassCuttingPlannerApi = async (
  cardId: number,
  assetGroupId: number,
  idType: "schedule" | "workorder" = "workorder"
): Promise<GCPlannerDataType> => {
  return ApiResponseHandler(
    await WorkOrderConfig.getInstance().get(
      `workorder/gc/${cardId}/plannerDetails?assetGroupId=${assetGroupId}&idType=${idType}`
    )
  );
};

export const saveGrassCuttingPlannerApi = async (
  cardId: number,
  idType: " schedule" | "workorder" = "workorder",
  action:
    | "save-plan"
    | "save-actual"
    | "approve-actual"
    | "reject-actual"
    | "partial-reject",
  params: GCPlannerSaveDataType
) => {
  return ApiResponseHandler(
    await WorkOrderConfig.getInstance().post<
      GCPlannerSaveDataType,
      GCPlannerSaveDataType
    >(
      `workorder/gc/save/planner?workOrderId=${cardId}&idType=${idType}&action=${action}`,
      params
    )
  );
};
