import { Box, Grid, Tooltip } from "@mui/material";
import MediumTypography from "../../../components/formlib/MediumTypography";
import { FC, useEffect, useMemo, useState } from "react";
import { AssetGroup } from "./types";
import AssetGroupCategoryDropDown from "./AssetGroupCategoryDropDown";

interface AssetListProps {
  data: AssetGroup[] | null;
  onDeleteSelectedItems: (selectedItems: number[]) => void;
  selectedAssetIds: number[];
  selectedAssetGroupId: number;
  selectedAssetCategoryId: number;
  onAssetListUpdate: (
    selectedIds: number[],
    assetCategoryId: number,
    assetGroupId: number
  ) => void;
}
const AssetListComponent: FC<AssetListProps> = ({
  data,
  onDeleteSelectedItems,
  selectedAssetGroupId,
  selectedAssetCategoryId,
  selectedAssetIds,
  onAssetListUpdate,
}) => {
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [selectedAssetsData, setSelectedAssetsData] = useState<number[]>([]);
  const [selectedAssetCategoryIds, setSelectedAssetCategoryIds] = useState(0);
  const [selectedAssetGroupIds, setSelectedAssetGroupIds] = useState(0);

  useEffect(() => {
    if (selectedAssetGroupId && selectedAssetCategoryId && selectedAssetIds) {
      // Assuming setSelectedItems, setSelectedAssetGroupIds, and setSelectedAssetCategoryIds are your state setters
      setSelectedAssetsData(selectedAssetIds);
      setSelectedAssetGroupIds(selectedAssetGroupId);
      setSelectedAssetCategoryIds(selectedAssetCategoryId);
    }
  }, [selectedAssetCategoryId, selectedAssetGroupId, selectedAssetIds]); // Add dependencies here to update state when props change
  const handleSelectAll = () => {
    if (selectedItems.length === selectedAssetsData.length) {
      setSelectedItems([]);
    } else {
      setSelectedItems(selectedAssetsData || []);
    }
  };

  const handleSelectItem = (item: number) => {
    if (selectedItems.includes(item)) {
      setSelectedItems(selectedItems.filter((i) => i !== item));
    } else {
      setSelectedItems([...selectedItems, item]);
    }
  };
  const handleDelete = () => {
    // Update localSelectedAssetIds by removing selectedItems
    onDeleteSelectedItems(selectedItems);
    setSelectedItems([]);
  };
  const isAllSelected = selectedItems.length === selectedAssetsData.length;

  const selectedGroup = useMemo(() => {
    return (
      data && data.find((group) => group.assetGroupId === selectedAssetGroupIds)
    );
  }, [data, selectedAssetGroupIds]);

  const selectedCategory = useMemo(() => {
    return selectedGroup?.assetCategories.find(
      (cat) => cat.assetCategoryId === selectedAssetCategoryIds
    );
  }, [selectedGroup, selectedAssetCategoryIds]); // Recompute when selectedGroup or selectedAssetCategoryIds changes

  const selectedAssets = useMemo(() => {
    return selectedCategory?.assets.filter((asset) =>
      selectedAssetsData.includes(asset.id)
    );
  }, [selectedCategory, selectedAssetsData]);

  const assetCallBack = (
    value: number[],
    selectedAssetGroups: number,
    assetCategories: number
  ) => {
    onAssetListUpdate(value, assetCategories, selectedAssetGroups);

    setSelectedAssetsData(value);
    setSelectedAssetCategoryIds(assetCategories);
    setSelectedAssetGroupIds(selectedAssetGroups);
  };
  return (
    <Box component={"div"}>
      <Box className="flex__  " sx={{ justifyContent: "end", margin: "16px" }}>
        <AssetGroupCategoryDropDown
          AssetData={data ? data : []}
          addButton={true}
          assetCallBack={assetCallBack}
          selectedAssetGroupsParent={selectedAssetGroupIds}
          selectedAssetsParent={selectedAssetsData}
          selectedAssetCategoryParent={selectedAssetCategoryIds}
        />
        <Box
          sx={{
            borderLeft: "2px solid rgba(159, 173, 188, 0.29)",
            height: "20px",
          }}
        ></Box>
        <MediumTypography
          labelId="Delete"
          defaultLabel="Delete"
          sxProps={{
            marginBottom: "10px",
            marginRight: "10px",
            marginLeft: "10px",
            cursor: "pointer",
          }}
          onClick={handleDelete}
        />
        <Box
          sx={{
            borderLeft: "2px solid rgba(159, 173, 188, 0.29)",
            height: "20px",
          }}
        ></Box>
        <MediumTypography
          labelId={isAllSelected ? "Clear" : "Select All"}
          defaultLabel={isAllSelected ? "Clear" : "Select All"}
          sxProps={{
            marginBottom: "10px",
            marginRight: "10px",
            marginLeft: "10px",
            cursor: "pointer",
          }}
          onClick={handleSelectAll}
        />
      </Box>
      <Grid
        container
        direction={"row"}
        className="justifyContentSpaceBtw pl-md pr-md"
        alignItems={"center"}
        rowSpacing={"16px"}
        columnSpacing={"40px"}
        sx={{ marginBottom: "20px", marginTop: "10px" }}
      >
        {selectedAssets &&
          selectedAssets.map((item, index) => (
            <Grid item key={item.id}>
              <Box
                sx={{
                  padding: "12px 6px",
                  backgroundColor: selectedItems.includes(item.id)
                    ? "rgba(159, 187, 235, 0.16)"
                    : "rgba(29, 33, 37, 0.7)",
                  borderRadius: "4px",
                  width: "150px",
                  cursor: "pointer",
                }}
                onClick={() => handleSelectItem(item.id)}
              >
                <Tooltip
                  title={item.name && item.name.length > 10 ? item.name : ""}
                  arrow={true}
                  followCursor
                >
                  <Box
                    sx={{
                      borderLeft: "2px solid #9FBBEB",
                      padding: "0px 12px",
                    }}
                  >
                    <MediumTypography
                      label={item.name}
                      className="ellipsis"
                      sxProps={{
                        width: item.name.length > 10 ? "120px" : "auto",
                      }}
                    />
                  </Box>
                </Tooltip>
              </Box>
            </Grid>
          ))}
      </Grid>
    </Box>
  );
};

export default AssetListComponent;
