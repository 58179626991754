import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/high-res.css";
import "./PhoneNumberInputStyle.css";
import { useIntl } from "react-intl";

interface PhoneNumberInputProps {
  labelId?: string;
  defaultLabelId?: string;
  [key: string]: any;
}

const PhoneNumberInput: React.FC<PhoneNumberInputProps> = ({
  labelId,
  defaultLabelId,
  ...props
}) => {
  const intl = useIntl();

  return (
    <PhoneInput
      country={"in"}
      placeholder={
        labelId &&
        intl.formatMessage({
          id: labelId,
          defaultMessage: defaultLabelId,
        })
      }
      containerClass="phone-input-container"
      containerStyle={{
        fontFamily: "Inter",
        fontSize: "12px",
        fontWeight: 600,
        color: "rgba(140, 155, 171, 1)",
      }}
      inputStyle={{
        backgroundColor: "rgba(34, 39, 43, 1)",
        border: "1px solid rgba(166, 197, 226, 0.16)",
        color: "rgba(140, 155, 171, 1)",
        width: "100%",
        height: "40px",
      }}
      buttonStyle={{
        backgroundColor: "rgba(34, 39, 43, 1)",
        border: "1px solid rgba(166, 197, 226, 0.16)",
      }}
      dropdownStyle={{
        backgroundColor: "rgba(34, 39, 43, 1)",
      }}
      searchStyle={{
        backgroundColor: "rgba(34, 39, 43, 1)",
      }}
      {...props}
    />
  );
};

export default PhoneNumberInput;
