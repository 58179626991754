import { Box, Grid, Tooltip } from "@mui/material";
import { TeamMemberForShift } from "../../utils/type";
import MediumTypography from "./MediumTypography";
import { FC } from "react";
import UserprofileIcon from "../../assets/images/profile-circle.svg";
import { ReactComponent as Cancel } from "../../assets/images/Cancel.svg";

interface TeamMemberwithCancelProps {
  data: TeamMemberForShift;
  statusIndication?: boolean;
  onRemoveClick?: (data: TeamMemberForShift) => void;
  buttonStatus?: boolean;
}
const TeamMembersWithCancel: FC<TeamMemberwithCancelProps> = ({
  data,
  statusIndication,
  onRemoveClick,
  buttonStatus,
}) => {
  return (
    <Box>
      <Grid container className="teamMemberCard " sx={{ position: "relative" }}>
        <Grid
          item
          className="flex__ align__items__center pt-md pb-md pl-md"
          sx={{ position: "relative" }}
        >
          <div
            style={{
              width: "45px",
              height: "45px",
              backgroundImage: data.image
                ? `url(${data.image})`
                : `url(${UserprofileIcon})`, // Use imported image
              backgroundSize: "cover",
              backgroundPosition: "center",
              borderRadius: "50%",
            }}
          ></div>

          {statusIndication && data.statusCode !== "AVAILABLE" && (
            <Box
              style={{
                width: "12px",
                height: "12px",
                borderRadius: "50%",
                backgroundColor:
                  data.statusCode === "NO SHOW"
                    ? "#C9372C"
                    : data.statusCode === "ONSITE"
                    ? "#13C413"
                    : data.statusCode === "REMOTE"
                    ? "rgba(24, 106, 222, 1)"
                    : "",
                border: "1.6px solid #22272B",
                position: "absolute",
                right: 7,
                top: 21,
                transform: "translate(50%, -50%)",
              }}
            />
          )}
        </Grid>
        <Grid item xs={8} className="ml-xs pt-md pb-sm">
          <Box className="flex__justify__space-between">
            <Box className="mt-xs">
              <Tooltip
                title={data.name && data.name.length > 10 ? data.name : ""}
                arrow={true}
                followCursor
              >
                <Box>
                  <MediumTypography
                    label={
                      data.name === "" || data.name === null ? "-" : data.name
                    }
                    className="ml-xs ellipsis"
                    fontSize="14px"
                    sxProps={{
                      width: data.name.length > 10 ? "100px" : "auto",
                    }}
                  />
                </Box>
              </Tooltip>
              <Tooltip
                title={data.title && data.title.length > 15 ? data.title : ""}
                arrow={true}
                followCursor
              >
                <Box>
                  <MediumTypography
                    label={
                      data.title === "" || data.title === null
                        ? "-"
                        : data.title
                    }
                    className=" ml-xs ellipsis"
                    fontSize="12px"
                    sxProps={{
                      width: data.title.length > 15 ? "100px" : "auto",
                      opacity: 0.64,
                    }}
                  />
                </Box>
              </Tooltip>
            </Box>
            {!buttonStatus && (
              <Box
                className="mr-sm"
                sx={{
                  display: "none",
                  position: "absolute",
                  right: "8px",
                  top: "10px",
                  cursor: "pointer",
                  zIndex: 2,
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                  },
                  ".teamMemberCard:hover &": {
                    display: "block",
                  },
                }}
                onClick={() => {
                  if (onRemoveClick) {
                    onRemoveClick(data);
                  }
                }}
              >
                <Cancel />
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TeamMembersWithCancel;
