// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MuiGrid-root.MuiGrid-container.teamMemberCard.css-11lq3yg-MuiGrid-root {
  background-color: #22272b;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ReassignModal.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B","sourcesContent":[".MuiGrid-root.MuiGrid-container.teamMemberCard.css-11lq3yg-MuiGrid-root {\n  background-color: #22272b;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
