import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Tooltip,
} from "@mui/material";
import MediumTypography from "../MediumTypography";
import { FC, useEffect, useState } from "react";
import ButtonComponent from "../ButtonComponent";
import { ReactComponent as Map } from "../../../assets/images/Map.svg";

import TextInput from "../TextInput";
import { AddCircle, Cancel } from "@mui/icons-material";

import useAvailableHeight from "../../../utils/useAvailableHeight";
import { MappedCommonTypes } from "../../../api/wizardsApi/Common";
import { splitBySelectionStatus } from "../../../utils/commonFunctions";

interface ModalProps {
  open: boolean;
  handleClose: () => void;
  titleLabelId: string;
  onApply: (
    mappedData: MappedCommonTypes[],
    availableData: MappedCommonTypes[]
  ) => void;
  mappingDetailsLabel: string;
  availableLabel: string;
  mappedList: MappedCommonTypes[];
  textInputRequired?: boolean;
}

const MappingModal: FC<ModalProps> = ({
  open,
  handleClose,
  titleLabelId,
  mappingDetailsLabel,
  onApply,
  availableLabel,
  mappedList,
  textInputRequired,
}) => {
  const availableHeight = useAvailableHeight(450);
  const [mapData, setMapData] = useState<MappedCommonTypes[]>([]);
  const [availableData, setAvailableData] = useState<MappedCommonTypes[]>([]);

  const handleAddItem = (item: MappedCommonTypes) => {
    const newItem = { ...item, isSelected: true };
    const newMapData = [...mapData, newItem];
    const newAvailableData = availableData.filter(
      (availableItem) => availableItem.id !== newItem.id
    );
    setMapData(newMapData);
    setAvailableData(newAvailableData);
  };
  const handleRemoveItem = (item: MappedCommonTypes) => {
    const newItem = { ...item, isSelected: false };
    const newAvailableData = [...availableData, newItem];
    const newMapData = mapData.filter((mapItem) => mapItem.id !== newItem.id);
    setMapData(newMapData);
    setAvailableData(newAvailableData);
  };
  useEffect(() => {
    const getMappedData = splitBySelectionStatus(mappedList);
    setMapData(getMappedData.selected);
    setAvailableData(getMappedData.unselected);
  }, []);

  return (
    <Dialog open={open} fullWidth maxWidth={"md"}>
      <DialogTitle sx={{ backgroundColor: "#1D2125" }}>
        <MediumTypography
          labelId={titleLabelId}
          defaultLabel="Mapping Details"
          textColor="#FFFF"
          fontSize="14px"
          fontWeight={700}
        />
      </DialogTitle>
      <DialogContent
        sx={{
          backgroundColor: "#1D2125",
          padding: "0px",
        }}
      >
        <Box
          className="ml-md mr-md  flex__"
          sx={{
            backgroundColor: "#22272B",
            border: "1px solid #575B5E",
            borderRadius: "4px",
          }}
        >
          <Grid container>
            <Grid item xs={5.6} className="pl-md pt-md">
              <MediumTypography
                labelId={mappingDetailsLabel}
                defaultLabel="Map AssetCategory"
                textColor="#9FADBC"
                fontSize="14px"
                fontWeight={400}
                className="mb-sm"
              />
              <Box sx={{ height: availableHeight, overflow: "auto" }}>
                {mapData.length > 0 &&
                  mapData.map((item: MappedCommonTypes) => (
                    <Box
                      sx={{
                        backgroundColor: "#34383C",
                        borderRadius: "4px",
                        justifyContent: "space-between",
                      }}
                      className="pt-sm pb-sm pl-md pr-md  mb-sm flex__ align__items__center"
                      key={item.id}
                    >
                      <Tooltip title={item.name} followCursor arrow>
                        <Box>
                          <MediumTypography
                            label={item.name}
                            textColor="#9FADBC"
                            fontSize="14px"
                            fontWeight={400}
                            className={"ellipsis"}
                            sxProps={{ width: "250px" }}
                          />
                        </Box>
                      </Tooltip>
                      <Box className="flex__ align__items__center">
                        {textInputRequired && (
                          <TextInput
                            //className="text-input-field"
                            type="text"
                            variant="outlined"
                            inputProps={{
                              readOnly: false,
                            }}
                            Value={
                              item?.level !== undefined
                                ? item.level.toString()
                                : ""
                            }
                            handlechange={(value: string) => {
                              const newLevel =
                                value !== "" ? parseInt(value, 10) : 0;
                              const updatedItem = {
                                ...item,
                                level: newLevel,
                              };
                              const updatedMapData = mapData.map((mapItem) => {
                                if (mapItem.id === item.id) {
                                  return updatedItem;
                                }
                                return mapItem;
                              });
                              setMapData(updatedMapData);
                            }}
                            sxProps={{
                              width: "50px",
                              height: "24px",
                            }}
                            className="mr-sm"
                          />
                        )}
                        <Cancel
                          sx={{
                            cursor: "pointer",
                            width: "20px",
                            color: "#ffff",
                          }}
                          onClick={() => handleRemoveItem(item)}
                        />
                      </Box>
                    </Box>
                  ))}
              </Box>
            </Grid>
            <Grid
              item
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{ width: "2px", flexGrow: 1, backgroundColor: "#575B5E" }}
              />
              <Map />
              <Box
                sx={{ width: "2px", flexGrow: 1, backgroundColor: "#575B5E" }}
              />
            </Grid>
            <Grid item xs={5.6} className="pr-md pt-md">
              <MediumTypography
                labelId={availableLabel}
                defaultLabel="Available AssetCategory"
                textColor="#9FADBC"
                fontSize="14px"
                fontWeight={400}
                className="mb-sm"
              />
              <Box sx={{ height: availableHeight, overflow: "auto" }}>
                {availableData.length > 0 &&
                  availableData.map((item: MappedCommonTypes) => (
                    <Box
                      sx={{
                        backgroundColor: "#34383C",
                        borderRadius: "4px",
                        justifyContent: "space-between",
                      }}
                      className="pt-sm pb-sm pl-md pr-md mb-sm flex__ align__items__center"
                      key={item.id}
                    >
                      <Tooltip title={item.name} followCursor arrow>
                        <Box>
                          <MediumTypography
                            label={item.name}
                            textColor="#9FADBC"
                            fontSize="14px"
                            fontWeight={400}
                            className={"ellipsis"}
                            sxProps={{ width: "250px" }}
                          />
                        </Box>
                      </Tooltip>
                      <Box className="flex__ align__items__center">
                        <AddCircle
                          sx={{
                            cursor: "pointer",
                            width: "20px",
                            color: "#ffff",
                          }}
                          onClick={() => handleAddItem(item)}
                        />
                      </Box>
                    </Box>
                  ))}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions sx={{ padding: "0px" }}>
        <Box
          className="width__100 flex__ p-md"
          sx={{
            backgroundColor: "#1D2125",
            justifyContent: "end",
          }}
        >
          <ButtonComponent
            className="btn-primary btn-cancel mr-md"
            labelId="btn.cancel"
            defaultLabelId="Cancel"
            onClick={handleClose}
          />
          <ButtonComponent
            className={"btn-primary btn-submit mr-md"}
            labelId="Apply"
            defaultLabelId="Apply"
            onClick={() => onApply(mapData, availableData)}
            disabled={false}
            sxProps={{
              cursor: "pointer",
            }}
          />
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default MappingModal;
