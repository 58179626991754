import React, { useContext, useEffect, useState } from "react";
import "./App.css";
import "./fonts/Inter-Black.ttf";
import "./fonts/Inter-Bold.ttf";
import "./fonts/Inter-SemiBold.ttf";
import "./fonts/Inter-Thin.ttf";
import "./fonts/Inter-Regular.ttf";
import "./fonts/Inter-Light.ttf";
import "./fonts/Inter-Medium.ttf";
import "./fonts/Inter-ExtraBold.ttf";
import "./fonts/Inter-ExtraLight.ttf";
import { ErrorProvider } from "./pages/signIn/context/ApiErrorContext";
import { STORAGE_USER_ID_KEY } from "./api/Constant";
import { ApplicationRoute } from "./routes/Routing";
import SignInComponent from "./pages/signIn/SignInComponent";
import { Context } from "./LanguageWrapper";
import { SchedulerFilterContextProvider } from "./pages/scheduler/Context/SchedulerContext";
import ScrollToTop from "./ScrollToTop";
import { EventBusProvider } from "./context/EventBusProvider";
import { useNavigate } from "react-router-dom";

function App() {
  const [userLoggedIn, setUserLoggedIn] = useState<string | null>();
  const navigate = useNavigate();

  const context = useContext(Context);
  useEffect(() => {
    context.selectLanguage();
  }, [context]);

  useEffect(() => {
    EventBusProvider.subscribe("logout", () => {
      setUserLoggedIn(null);
      navigate("/");
    });
  }, []);

  useEffect(() => {
    if (userLoggedIn === undefined || userLoggedIn === null) {
      setUserLoggedIn(null);
    }
    setUserLoggedIn(localStorage.getItem(STORAGE_USER_ID_KEY));
  }, [userLoggedIn]);

  if (userLoggedIn === null) {
    return (
      <ErrorProvider>
        <SignInComponent
          onLoginSuccess={(userId) => {
            setUserLoggedIn(userId);
          }}
        />
      </ErrorProvider>
    );
  }
  return (
    <>
      <SchedulerFilterContextProvider>
        <ScrollToTop />
        <ApplicationRoute onLogout={() => setUserLoggedIn(null)} />
      </SchedulerFilterContextProvider>
    </>
  );
}

export default App;
