import { Box, Grid } from "@mui/material";
import { Formik, FormikProps } from "formik";
import ButtonComponent from "../../../components/formlib/ButtonComponent";
import MediumTypography from "../../../components/formlib/MediumTypography";
import CustomDropdown from "../../../components/formlib/CustomDropdown";
import TextInput from "../../../components/formlib/TextInput";
import CheckBox from "../../../components/formlib/CheckBox";
import FormikErrorComponent from "../../../components/formlib/FormikErrorComponent";
import {
  convertToNumberOrNull,
  getOptions,
  handleError,
} from "../../../utils/commonFunctions";
import DateTimePickerComponent from "../../../components/formlib/DateTimePickerComponent";
import dayjs, { Dayjs } from "dayjs";
import { AddAssetType, Make, SaveAssetType } from "../types/assetTypes";
import ErrorModal from "../../../components/formlib/modal/ErrorModal";
import * as Yup from "yup";
import { trim } from "lodash";
import { FC, useContext, useEffect, useRef, useState } from "react";
import { LoaderContext, LoaderContextType } from "../../../layouts/appSidebar";
import useAvailableHeight from "../../../utils/useAvailableHeight";
import { CommonOptionType } from "../../../utils/type";
import {
  customSaveDataApi,
  customUpdateDataApi,
  fetchAssetsWizardsDataApi,
} from "../../../api/wizardsApi/Common";

interface AddEditProps {
  open: boolean;
  id?: number;
  handleClose: () => void;
  selectedLanguageCode?: string;
  getDataCallback: () => void;
  assetGroupId: number;
  assetCategoryId: number;
}

const initialData: AddAssetType = {
  id: 0,
  assetName: "",
  code: "",
  serialNo: "",
  make: "",
  model: "",
  parent: "",
  latLong: "",
  acCapacity: 0,
  dcCapacity: 0,
  rating: "",
  lastCalibratedDate: null,
  manufacturedDate: null,
  installationDate: null,
  warrantyExpiryDate: null,
  insuranceExpiryDate: null,
  selectedParentId: 0,
  selectedStatusId: 0,
  selectedCalibrationFrequency: 0,
  selectedMakeModelId: 0,
  latitude: "",
  longitude: "",
  isCalibrationRequired: false,
  isInsured: false,
  parentList: [],
  frequencyList: [],
  selectedMakeId: 0,
  makeModelList: [],
  statusList: [],
};

const dropdownValidation = Yup.mixed()
  .nullable()
  .test("is-valid-number", "validation.dropdown", (value) => {
    const theValue = value ? value.toString() : "";
    if (trim(theValue) === "" || theValue === "0") {
      return true;
    }

    return parseFloat(theValue) > 0;
  });
export const customRequiredFn = (value: any) => {
  const theValue = value ? value.toString() : "";
  return trim(theValue) !== "" && theValue !== "0";
};

const validationSchema = Yup.object().shape({
  assetName: Yup.string().required("validation.fieldRequired"),
  code: Yup.string().required("validation.fieldRequired"),
  selectedStatusId: dropdownValidation.test(
    "custom-required",
    "validation.dropdown",
    customRequiredFn
  ),
  selectedParentId: dropdownValidation.test(
    "custom-required",
    "validation.dropdown",
    customRequiredFn
  ),
});

const muiColumnProps = {
  xs: 12,
  sm: 6,
  md: 6,
  lg: 4,
  xl: 4,
};
const getModelOptions = (make: Make[], selectedId: number) => {
  return make.find((m) => m.id === selectedId)?.models || [];
};
const AddEditAssets: FC<AddEditProps> = ({
  open,
  id,
  handleClose,
  getDataCallback,
  selectedLanguageCode,
  assetCategoryId,
  assetGroupId,
}) => {
  const [apiData, setApiData] = useState<AddAssetType>(initialData);
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [modelOptions, setModelOptions] = useState<CommonOptionType[]>([]);
  const [makeList, setMakeList] = useState<Make[]>([]);

  const [errorDesc, setErrorDesc] = useState<string>("");
  const formikRef = useRef<FormikProps<any>>(null);
  const availableHeight = useAvailableHeight(350);

  useEffect(() => {
    if (open) {
      fetchData(id);
    }
  }, [id, open]);

  const fetchData = (accId: number | undefined) => {
    toggleLoader(true);

    const fetchAssetUrl =
      accId !== undefined && accId !== 0
        ? `${assetGroupId}/${assetCategoryId}`
        : `${assetGroupId}/asset`;
    fetchAssetsWizardsDataApi<AddAssetType>(
      fetchAssetUrl,
      accId,
      selectedLanguageCode
    )
      .then(async (response) => {
        const data = { ...initialData, ...response };

        const updatedResponse: AddAssetType = {
          ...data,
          statusList: data.statusList,
          makeModelList: data.makeModelList,
          frequencyList: data.frequencyList,
          parentList: data.parentList,
        };

        setApiData(updatedResponse);
        const makeListData =
          data.makeModelList &&
          data.makeModelList.find((item) => item.id === data.selectedParentId)
            ?.make;

        setMakeList(makeListData || []);

        setModelOptions(
          getModelOptions(makeListData || [], data.selectedMakeModelId || 0)
        );

        toggleLoader(false);
      })
      .catch((err) => {
        handleError(err, setErrorDesc);
        setOpenErrorModal(true);
        toggleLoader(false);
      });
  };

  const saveData = (params: AddAssetType) => {
    toggleLoader(true);

    const {
      statusList,
      makeModelList,
      frequencyList,
      parentList,
      ...updatedParams
    } = params;

    const Uri = updatedParams.id
      ? `${assetGroupId}/${assetCategoryId}/${updatedParams.id}/asset/update`
      : `${assetGroupId}/${assetCategoryId}/asset/save`;
    if (updatedParams.id !== undefined && updatedParams.id !== 0) {
      customUpdateDataApi<SaveAssetType>(Uri, updatedParams)
        .then(() => {
          formikRef.current?.resetForm({ values: initialData });
          handleClose();
          getDataCallback();
          toggleLoader(false);
        })
        .catch((err) => {
          handleError(err, setErrorDesc);
          setOpenErrorModal(true);
          toggleLoader(false);
        });
    } else {
      customSaveDataApi<SaveAssetType>(Uri, updatedParams)
        .then(() => {
          formikRef.current?.resetForm({ values: initialData });
          handleClose();
          getDataCallback();
          toggleLoader(false);
        })
        .catch((err) => {
          handleError(err, setErrorDesc);
          setOpenErrorModal(true);
          toggleLoader(false);
        });
    }
  };

  return (
    <Box>
      <Box sx={{ mb: "10px" }}>
        <MediumTypography
          labelId={id ? "Edit Asset" : "Add Asset"}
          defaultLabel="Add Asset"
          fontSize="20px"
          fontWeight={700}
        />
      </Box>

      {openErrorModal && (
        <ErrorModal
          descriptionText={errorDesc}
          open={openErrorModal}
          handleClose={() => {
            setOpenErrorModal(false);
            //handleClose();
          }}
          onPositiveClick={() => {
            setOpenErrorModal(false);
            //handleClose();
          }}
        />
      )}

      <Formik
        initialValues={apiData}
        enableReinitialize
        validateOnChange
        innerRef={formikRef}
        validationSchema={validationSchema}
        onSubmit={(values: AddAssetType) => {
          const params: AddAssetType = {
            ...values,
            selectedMakeId: convertToNumberOrNull(values.selectedMakeId),
            selectedCalibrationFrequency: convertToNumberOrNull(
              values.selectedCalibrationFrequency
            ),
            selectedStatusId: convertToNumberOrNull(values.selectedStatusId),
            selectedMakeModelId: convertToNumberOrNull(
              values.selectedMakeModelId
            ),
            selectedParentId: convertToNumberOrNull(values.selectedParentId),
          };
          saveData(params);
        }}
      >
        {({ values, setFieldValue, handleSubmit, errors, touched, dirty }) => {
          return (
            <Box>
              <Grid
                container
                spacing={2}
                sx={{
                  overflowY: "auto",
                  height: availableHeight,
                }}
                alignItems={"center"}
              >
                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    className="input-label"
                    labelId="Asset.Name"
                    defaultLabel="Name"
                  />
                  <TextInput
                    className="text-input-field"
                    type="text"
                    variant="outlined"
                    inputProps={{
                      readOnly: false,
                    }}
                    labelId="common.placeHolderText"
                    defaultLabelId="--- type here ---"
                    Value={values.assetName}
                    handlechange={(value: string) => {
                      setFieldValue("assetName", value);
                    }}
                  />
                  <FormikErrorComponent
                    errors={errors}
                    touched={touched}
                    field="assetName"
                  />
                </Grid>
                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    className="input-label"
                    labelId="Asset.Code"
                    defaultLabel="Code"
                  />
                  <TextInput
                    className="text-input-field"
                    type="text"
                    variant="outlined"
                    inputProps={{
                      readOnly: false,
                    }}
                    labelId="common.placeHolderText"
                    defaultLabelId="--- type here ---"
                    Value={values.code}
                    handlechange={(value: string) => {
                      setFieldValue("code", value);
                    }}
                  />
                  <FormikErrorComponent
                    errors={errors}
                    touched={touched}
                    field="code"
                  />
                </Grid>
                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    className="input-label"
                    labelId="Assets.serialNo"
                    defaultLabel="Serial Number"
                  />
                  <TextInput
                    className="text-input-field"
                    type="text"
                    variant="outlined"
                    inputProps={{
                      readOnly: false,
                    }}
                    labelId="common.placeHolderText"
                    defaultLabelId="--- type here ---"
                    Value={values.serialNo}
                    handlechange={(value: string) => {
                      setFieldValue("serialNo", value);
                    }}
                  />
                </Grid>
                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    className="input-label"
                    labelId="Asset.Parent"
                    defaultLabel="Parent"
                  />
                  <CustomDropdown
                    options={getOptions(values.parentList ?? [])}
                    selectedValue={
                      values.selectedParentId === null
                        ? ""
                        : values.selectedParentId
                    }
                    disabled={id ? true : false}
                    onSelectedValueChange={(value) => {
                      setFieldValue("selectedParentId", value);
                      setFieldValue("selectedMakeId", "");
                      setMakeList(
                        (values.makeModelList &&
                          values.makeModelList?.find(
                            (item) => item.id === Number(value)
                          )?.make) ||
                          []
                      );
                    }}
                  />
                  <FormikErrorComponent
                    errors={errors}
                    touched={touched}
                    field="selectedParentId"
                  />
                </Grid>
                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    className="input-label"
                    labelId="Asset.Make"
                    defaultLabel="Make"
                  />
                  <CustomDropdown
                    options={getOptions(makeList || [])}
                    selectedValue={
                      values.selectedMakeId === null
                        ? ""
                        : values.selectedMakeId
                    }
                    onSelectedValueChange={(value) => {
                      setFieldValue("selectedMakeId", value);
                      setFieldValue("selectedMakeModelId", "");
                      setModelOptions(
                        getModelOptions(makeList || [], Number(value) || 0)
                      );
                    }}
                  />
                </Grid>
                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    className="input-label"
                    labelId="Asset.Model"
                    defaultLabel="Model"
                  />
                  <CustomDropdown
                    options={getOptions(modelOptions ?? [])}
                    selectedValue={
                      values.selectedMakeModelId === null
                        ? ""
                        : values.selectedMakeModelId
                    }
                    onSelectedValueChange={(value) => {
                      setFieldValue("selectedMakeModelId", value);
                    }}
                  />
                </Grid>
                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    className="input-label"
                    labelId="assetGroupWizards.acCapacity"
                    defaultLabel="AC Capacity (MW)"
                  />
                  <TextInput
                    className="text-input-field"
                    type="text"
                    variant="outlined"
                    inputProps={{
                      readOnly: false,
                    }}
                    labelId="common.placeHolderText"
                    defaultLabelId="--- type here ---"
                    Value={
                      values.acCapacity !== null && values.acCapacity !== 0
                        ? String(values.acCapacity)
                        : ""
                    }
                    handlechange={(value: string) => {
                      let formattedValue = value.replace(/[^0-9.]/g, "");

                      // Then, format the string to match the pattern "up to 4 digits, optional decimal, up to 2 digits after the decimal"
                      formattedValue = formattedValue.replace(
                        /^(\d{0,8})(\.\d{0,2})?.*$/,
                        (match, p1, p2) => p1 + (p2 || "") // p1 is the first group (digits), p2 is the second group (decimal and digits)
                      );
                      setFieldValue("acCapacity", formattedValue);
                    }}
                  />
                </Grid>
                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    className="input-label"
                    labelId="assetGroupWizards.dcCapacity"
                    defaultLabel="DC Capacity (MWp)"
                  />
                  <TextInput
                    className="text-input-field"
                    type="text"
                    variant="outlined"
                    inputProps={{
                      readOnly: false,
                      type: "number",
                    }}
                    labelId="common.placeHolderText"
                    defaultLabelId="--- type here ---"
                    Value={
                      values.dcCapacity !== null && values.dcCapacity !== 0
                        ? String(values.dcCapacity)
                        : ""
                    }
                    handlechange={(value: string) => {
                      let formattedValue = value.replace(/[^0-9.]/g, "");

                      // Then, format the string to match the pattern "up to 4 digits, optional decimal, up to 2 digits after the decimal"
                      formattedValue = formattedValue.replace(
                        /^(\d{0,8})(\.\d{0,2})?.*$/,
                        (match, p1, p2) => p1 + (p2 || "") // p1 is the first group (digits), p2 is the second group (decimal and digits)
                      );
                      setFieldValue("dcCapacity", formattedValue);
                    }}
                  />
                </Grid>
                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    className="input-label"
                    labelId="Asset.rating"
                    defaultLabel="Rating"
                  />
                  <TextInput
                    className="text-input-field"
                    type="text"
                    variant="outlined"
                    inputProps={{
                      readOnly: false,
                    }}
                    labelId="common.placeHolderText"
                    defaultLabelId="--- type here ---"
                    Value={values.rating}
                    handlechange={(value: string) => {
                      setFieldValue("rating", value);
                    }}
                  />
                </Grid>
                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    className="input-label"
                    labelId="assetGroupWizards.latitude"
                    defaultLabel="Latitude"
                  />
                  <TextInput
                    className="text-input-field"
                    type="text"
                    variant="outlined"
                    inputProps={{
                      readOnly: false,
                    }}
                    labelId="common.placeHolderText"
                    defaultLabelId="--- type here ---"
                    Value={values.latitude}
                    handlechange={(value: string) => {
                      setFieldValue("latitude", value);
                    }}
                  />
                </Grid>
                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    className="input-label"
                    labelId="assetGroupWizards.longitude"
                    defaultLabel="Longitude"
                  />
                  <TextInput
                    className="text-input-field"
                    type="text"
                    variant="outlined"
                    inputProps={{
                      readOnly: false,
                    }}
                    labelId="common.placeHolderText"
                    defaultLabelId="--- type here ---"
                    Value={values.longitude}
                    handlechange={(value: string) => {
                      setFieldValue("longitude", value);
                    }}
                  />
                </Grid>
                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    className="input-label"
                    labelId="User.Status"
                    defaultLabel="Status"
                  />
                  <CustomDropdown
                    options={getOptions(values.statusList ?? [])}
                    selectedValue={
                      values.selectedStatusId === null
                        ? ""
                        : values.selectedStatusId
                    }
                    onSelectedValueChange={(value) => {
                      setFieldValue("selectedStatusId", value);
                    }}
                  />
                  <FormikErrorComponent
                    errors={errors}
                    touched={touched}
                    field="selectedStatusId"
                  />
                </Grid>
                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    className="input-label"
                    labelId="Asset.CalibrationFrequency"
                    defaultLabel="Calibration Frequency"
                  />
                  <CustomDropdown
                    options={getOptions(values.frequencyList ?? [])}
                    selectedValue={
                      values.selectedCalibrationFrequency === null
                        ? ""
                        : values.selectedCalibrationFrequency
                    }
                    onSelectedValueChange={(value) => {
                      setFieldValue("selectedCalibrationFrequency", value);
                    }}
                  />
                </Grid>

                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    className="input-label"
                    labelId="Asset.lastCalibratedDate"
                    defaultLabel="Last Calibrated Date "
                  />
                  <DateTimePickerComponent
                    value={
                      values.lastCalibratedDate !== null
                        ? dayjs(values.lastCalibratedDate)
                        : null
                    }
                    labelid="----dd-mm-yyyy----"
                    defaultlabelid="----dd-mm-yyyy----"
                    handlechange={(date: Dayjs) => {
                      const formattedDate = dayjs(date).format("YYYY-MM-DD");
                      setFieldValue("lastCalibratedDate", formattedDate);
                    }}
                    disabledDate={false}
                    inputFormat="DD-MM-YYYY"
                    width="410px"
                    textWidth="410px"
                    views={["year", "month", "day"]}
                    disableFuture={true}
                  />
                </Grid>
                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    className="input-label"
                    labelId="Asset.md"
                    defaultLabel="Manufactured Date"
                  />

                  <DateTimePickerComponent
                    value={
                      values.manufacturedDate !== null
                        ? dayjs(values.manufacturedDate)
                        : null
                    }
                    labelid="----dd-mm-yyyy----"
                    defaultlabelid="----dd-mm-yyyy----"
                    handlechange={(date: Dayjs) => {
                      const formattedDate = dayjs(date).format("YYYY-MM-DD");
                      setFieldValue("manufacturedDate", formattedDate);
                    }}
                    disabledDate={false}
                    inputFormat="DD-MM-YYYY"
                    width="410px"
                    textWidth="410px"
                    disableFuture={true}
                    views={["year", "month", "day"]}
                  />
                </Grid>
                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    className="input-label"
                    labelId="Asset.id"
                    defaultLabel="Installation Date"
                  />

                  <DateTimePickerComponent
                    value={
                      values.installationDate !== null
                        ? dayjs(values.installationDate)
                        : null
                    }
                    labelid="----dd-mm-yyyy----"
                    defaultlabelid="----dd-mm-yyyy----"
                    handlechange={(date: Dayjs) => {
                      const formattedDate = dayjs(date).format("YYYY-MM-DD");
                      setFieldValue("installationDate", formattedDate);
                    }}
                    disabledDate={false}
                    inputFormat="DD-MM-YYYY"
                    width="410px"
                    textWidth="410px"
                    disableFuture={true}
                    views={["year", "month", "day"]}
                  />
                </Grid>
                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    className="input-label"
                    labelId="Asset.wd"
                    defaultLabel="Warranty Expiry Date"
                  />

                  <DateTimePickerComponent
                    value={
                      values.manufacturedDate !== null
                        ? dayjs(values.warrantyExpiryDate)
                        : null
                    }
                    labelid="----dd-mm-yyyy----"
                    defaultlabelid="----dd-mm-yyyy----"
                    handlechange={(date: Dayjs) => {
                      const formattedDate = dayjs(date).format("YYYY-MM-DD");
                      setFieldValue("warrantyExpiryDate", formattedDate);
                    }}
                    disabledDate={false}
                    inputFormat="DD-MM-YYYY"
                    width="410px"
                    textWidth="410px"
                    views={["year", "month", "day"]}
                  />
                </Grid>
                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    className="input-label"
                    labelId="Asset.insuranceDate"
                    defaultLabel="Insurance Expiry Date"
                  />

                  <DateTimePickerComponent
                    value={
                      values.insuranceExpiryDate !== null
                        ? dayjs(values.insuranceExpiryDate)
                        : null
                    }
                    labelid="----dd-mm-yyyy----"
                    defaultlabelid="----dd-mm-yyyy----"
                    handlechange={(date: Dayjs) => {
                      const formattedDate = dayjs(date).format("YYYY-MM-DD");
                      setFieldValue("insuranceExpiryDate", formattedDate);
                    }}
                    disabledDate={false}
                    inputFormat="DD-MM-YYYY"
                    width="410px"
                    textWidth="410px"
                    views={["year", "month", "day"]}
                  />
                </Grid>

                <Grid
                  item
                  {...muiColumnProps}
                  className=" flex__ align__items__center mt-lg"
                >
                  <CheckBox
                    value={
                      values.isCalibrationRequired === undefined
                        ? false
                        : values.isCalibrationRequired
                    }
                    onCheckBoxClick={(checked) => {
                      setFieldValue("isCalibrationRequired", checked);
                    }}
                    className="p-none"
                  />
                  <Box className="flex__ align__items__center flex__justify__center mt-sm ml-sm">
                    <MediumTypography
                      className="input-label"
                      labelId="Asset.CalibrationRequired"
                      defaultLabel="Calibration Required"
                    />
                  </Box>
                </Grid>
                <Grid
                  item
                  {...muiColumnProps}
                  className=" flex__ align__items__center mt-lg"
                >
                  <CheckBox
                    value={
                      values.isInsured === undefined ? false : values.isInsured
                    }
                    onCheckBoxClick={(checked) => {
                      setFieldValue("isInsured", checked);
                    }}
                    className="p-none"
                  />
                  <Box className="flex__ align__items__center flex__justify__center mt-sm ml-sm">
                    <MediumTypography
                      className="input-label"
                      labelId="Asset.isInsured"
                      defaultLabel="Is Insured"
                    />
                  </Box>
                </Grid>
              </Grid>
              <Box className="flex__ p-md" sx={{ justifyContent: "flex-end" }}>
                <ButtonComponent
                  className="btn-primary btn-cancel ml-md"
                  variantType="contained"
                  defaultLabelId={"btn.cancel"}
                  labelId={"btn.cancel"}
                  onClick={() => {
                    handleClose();
                  }}
                />
                <ButtonComponent
                  className="btn-primary btn-submit ml-md"
                  variantType="contained"
                  defaultLabelId={"Save"}
                  labelId={
                    id !== undefined && id !== 0 ? "btn.update" : "btn.save"
                  }
                  onClick={() => {
                    handleSubmit();
                  }}
                />
              </Box>
            </Box>
          );
        }}
      </Formik>
    </Box>
  );
};

export default AddEditAssets;
