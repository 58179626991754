// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.shiftClassUl {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  border-radius: 6px;
  padding: 0px 0px;
  position: absolute;
}

.shifts-custom-border {
  position: relative;
  /* padding: 8px; */
  font-family: Inter;
  text-transform: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/formlib/ShiftComponent.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,aAAa;EACb,mBAAmB;EACnB,kBAAkB;EAClB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,kBAAkB;EAClB,oBAAoB;AACtB","sourcesContent":[".shiftClassUl {\n  list-style-type: none;\n  display: flex;\n  flex-direction: row;\n  border-radius: 6px;\n  padding: 0px 0px;\n  position: absolute;\n}\n\n.shifts-custom-border {\n  position: relative;\n  /* padding: 8px; */\n  font-family: Inter;\n  text-transform: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
