import { ApiResponse } from "apisauce";
import { EventBusProvider } from "../context/EventBusProvider";
import { navigate } from "./Navigation";

export enum ApiErrorStatus {
  NETWORK_ERROR = "NETWORK_ERROR",
  CLIENT_ERROR = "CLIENT_ERROR",
  SERVER_ERROR = "SERVER_ERROR",
  TIMEOUT_ERROR = "TIMEOUT_ERROR",
  CONNECTION_ERROR = "CONNECTION_ERROR",
  CANCEL_ERROR = "CANCEL_ERROR",
}

export type ApiMessage = {
  message: string;
  status: string;
  code: number;
};

export type ResponseItem = {
  responseId: string;
  formId: string;
};

export class ApiError {
  id: string;
  message: string;

  constructor(id: string, message: string) {
    this.id = id;
    this.message = message;
  }
}

// Helper functions for common error cases
const handleNetworkError = () => {
  localStorage.clear();
  EventBusProvider.publish("logout");
  navigate("/");
};

const createApiError = (id: string, message: string): ApiError => {
  return { id, message };
};

const handleServerError = (response: ApiResponse<any, any>): ApiError => {
  if (response.status === 502) {
    localStorage.clear();
    EventBusProvider.publish("logout");
    return createApiError(
      "serverError",
      "Server is down. Please try again later"
    );
  } else if (
    response.status === 500 &&
    response.data &&
    typeof response.data === "object"
  ) {
    const errorData = response.data as any;
    if (errorData.issue && Array.isArray(errorData.issue)) {
      const detailedMessage = errorData.issue
        .map((issue: any) => issue.diagnostics || issue.severSide)
        .join("; ");
      return createApiError(
        errorData.issue[0].diagnostics || "serverError",
        detailedMessage || "Unable to connect server. Please try again later"
      );
    }
  }
  return createApiError(
    "serverError",
    "Unable to connect server. Please try again later"
  );
};

// export const isCustomError = <T extends object>(error: T): boolean => {
//   return Object.hasOwn(error, "id") && Object.hasOwn(error, "issue");
// };
export const isCustomError = <T extends object>(error: T): boolean => {
  return (
    typeof error === "object" &&
    error !== null &&
    Object.prototype.hasOwnProperty.call(error, "id") &&
    Object.prototype.hasOwnProperty.call(error, "issue") &&
    Array.isArray((error as any).issue) // Ensure 'issue' is an array
  );
};

// Main response handler
export const ApiResponseHandler = async <T, U>(
  response: ApiResponse<T, U>
): Promise<U> => {
  try {
    return await new Promise<U>((resolve, reject) => {
      if (response.ok && response.data) {
        resolve(response.data as U);
      } else if (response.problem !== null) {
        if (response.problem === ApiErrorStatus.CLIENT_ERROR) {
          reject(response.data as ApiMessage);
        } else {
          switch (response.problem) {
            case ApiErrorStatus.NETWORK_ERROR:
              handleNetworkError();
              return reject(
                createApiError(
                  "internetCheckConnectionError",
                  "Check internet connection and try again later"
                )
              );

            case ApiErrorStatus.SERVER_ERROR:
              return reject(handleServerError(response));

            case ApiErrorStatus.TIMEOUT_ERROR:
              return reject(
                createApiError(
                  "requestTimeoutError",
                  "Request timeout. Please try again"
                )
              );

            case ApiErrorStatus.CONNECTION_ERROR:
              return reject(
                createApiError(
                  "serverError",
                  "Unable to connect server. Please try again later"
                )
              );

            case ApiErrorStatus.CANCEL_ERROR:
              return reject(
                createApiError("requestCancelledError", "Request cancelled")
              );

            default:
              return reject(
                createApiError(
                  "somethingWrongError",
                  "Something went wrong. Please try again later"
                )
              );
          }
        }
      } else {
        return reject(
          createApiError(
            "somethingWrongError",
            "Something went wrong. Please try again later"
          )
        );
      }
    });
  } catch (error) {
    return Promise.reject(error);
  }
};
