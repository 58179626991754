import { Box } from "@mui/material";
import MediumTypography from "../../components/formlib/MediumTypography";
import ButtonComponent from "../../components/formlib/ButtonComponent";
import { ChangeEvent, useContext, useEffect, useRef, useState } from "react";
import ManageAccounts from "./accounts/ManageAccountsComponent";
import { ReactComponent as Upload } from "../../assets/images/UploadIcon.svg";
import { ReactComponent as Download } from "../../assets/images/DownloadIcon.svg";
import { WORK_FLOW_BASE_URL } from "../../api/Constant";
import DropdownComponent from "../../components/formlib/DropdownComponent";
import { LanguageType } from "./types/accountTypes";
import { OptionType } from "../../utils/type";
import { LoaderContext, LoaderContextType } from "../../layouts/appSidebar";

import ErrorModal from "../../components/formlib/modal/ErrorModal";
import { hasPermission } from "../../utils/checkPermission";
import ManageAssetCategories from "./assetCategories/ManageAssetCategoriesComponent";
import ManageAnomaly from "./anomaly/ManageAnomalyComponent";
import ValidationErrorModal from "../../components/formlib/modal/ValidationErrorModal";
import { useLocation } from "react-router-dom";
import ManageInventory from "./inventory/ManageInventoryComponent";
import ManageChecklistComponent from "./checklist/ManageChecklistComponent";
import ManageToolsAndPPE from "./toolsAndPPE/ManageToolsAndPPE";
import ManageFrequencyComponent from "./frequency/ManageFrequencyComponent";
import ManageRolesAndPermissionComponent from "./rolesAndPermission/ManageRolesAndPermissionsComponent";
import ManagePermitListItemMapComponent from "./permitLineItemMap/ManagePermitLineItemMapComponent";
import {
  MasterDataTypes,
  uploadCSVFileApi,
  validateCSVFileApi,
} from "../../api/MasterDataManagement/Common";

import ManageMapChecklistComponent from "./mapChecklistLineItems/ManageMapChecklistComponent";
import ManageLineItemComponent from "./checklistLineItem/ManageLineItemComponent";
import ManageSkillComponent from "./skills/ManageSkillComponent";
import useAvailableHeight from "../../utils/useAvailableHeight";
import ManagePermits from "./permits/ManagePermitsComponent";
import ManageReasonForDeviation from "./reasonsForDeviation/ManageReasonForDeviation";
import { handleError } from "../../utils/commonFunctions";

const Data = [
  {
    id: 1,
    labelId: "MasterDataManagement.Accounts",
    defaultLabelId: "Account",
    component: "ManageAccounts",
    pageKey: "ACCOUNT",
  },
  {
    id: 2,
    labelId: "MasterDataManagement.Anomaly",
    defaultLabelId: "Anomaly",
    component: "ManageAnomaly",
    pageKey: "ANOMALY",
  },
  {
    id: 3,
    labelId: "MasterDataManagement.AssetCategories",
    defaultLabelId: "Asset Categories",
    component: "ManageAssetCategories",
    pageKey: "ASSETCATEGORY",
  },
  {
    id: 4,
    labelId: "MasterDataManagement.CheckList",
    defaultLabelId: "Check List",
    component: "ManageCheckList",
    pageKey: "CHECKLIST",
  },
  {
    id: 5,
    labelId: "MasterDataManagement.CheckListLineItem",
    defaultLabelId: "Check List Line Item",
    component: "ManageCheckListLineItem",
    pageKey: "CHECKLISTLINEITEM",
  },
  {
    id: 51,
    labelId: "MasterDataManagement.MapLineItems",
    defaultLabelId: "Map Line Items",
    component: "ManageMapChecklist",
    pageKey: "MAPCHECKLISTLINEITEM",
  },
  {
    id: 6,
    labelId: "MasterDataManagement.Frequency",
    defaultLabelId: "Frequency",
    component: "ManageFrequency",
    pageKey: "FREQUENCY",
  },
  {
    id: 7,
    labelId: "MasterDataManagement.Inventory",
    defaultLabelId: "Inventory",
    component: "ManageInventory",
    pageKey: "INVENTORY",
  },
  {
    id: 8,
    labelId: "MasterDataManagement.Permits",
    defaultLabelId: "Permits",
    component: "ManagePermits",
    pageKey: "PERMIT",
  },
  {
    id: 9,
    labelId: "MasterDataManagement.PermitLineItemsAndOptions",
    defaultLabelId: "Permit Line Items and Options",
    component: "ManagePermitLineItemsAndOptions",
    pageKey: "PERMIT_LINE_ITEM",
  },
  {
    id: 10,
    labelId: "MasterDataManagement.ReasonForDeviation",
    name: "Reason for Deviation",
    component: "ManageReasonForDeviation",
    pageKey: "REASONFORDEVIATION",
  },
  {
    id: 11,
    labelId: "MasterDataManagement.RolesAndPermissions",
    defaultLabelId: "Roles and Permissions",
    component: "ManageRolesAndPermissions",
    pageKey: "ROLEANDPERMISSION",
  },
  {
    id: 12,
    labelId: "MasterDataManagement.Skills",
    defaultLabelId: "Skills",
    component: "ManageSkills",
    pageKey: "SKILL",
  },
  // {
  //   id: 13,
  //   name: "Standard GL Codes",
  //   component: "ManageStandardGLCodes",
  //   pageKey: "STANDARDGLCODE",
  // },
  {
    id: 14,
    labelId: "MasterDataManagement.ToolsAndPPE",
    defaultLabelId: "Tools and PPE",
    component: "ManageToolsAndPPE",
    pageKey: "TOOLS",
  },
  // {
  //   id: 15,
  //   name: "Vendor and Services",
  //   component: "ManageVendorAndServices",
  //   pageKey: "VENDORANDSERVICE",
  // },
];

const MasterDataManagement = () => {
  const [selectedItem, setSelectedItem] = useState(Data[0].id);
  const [listData, setListData] = useState<MasterDataTypes | null>(null);
  const [selectedLanguageId, setSelectedLanguageId] = useState<number>(
    Number(localStorage.getItem("languageCode")) ?? 0
  );
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [openValidationErrorModal, setOpenValidationErrorModal] =
    useState<boolean>(false);
  const [triggerAddModal, setTriggerAddModal] = useState(false);
  const [errorDesc, setErrorDesc] = useState<string>("");
  const inputFileRef = useRef<HTMLInputElement>(null);
  const languageMap = (listData?.languages ?? []).reduce((acc, language) => {
    acc[language.id] = language.languageCode;
    return acc;
  }, {} as { [key: number]: string });
  const currentLanguage = languageMap[Number(selectedLanguageId)] || "en";
  const [activeFilters, setActiveFilters] = useState<{ [key: string]: string }>(
    {}
  );
  const [selectedLanguageCode, setSelectedLanguageCode] = useState(
    localStorage.getItem("language") ?? "en"
  );
  const [selectedComponent, setSelectedComponent] = useState<string | null>(
    null
  );
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [validationSuccess, setValidationSuccess] = useState<string>("");
  const [openValidationSuccessModal, setOpenValidationSuccessModal] =
    useState<boolean>(false);

  const [exportBeanList, setExportBeanList] = useState<any>([]);
  const [files, setFiles] = useState<File | null>(null);
  const [selectedPermitId, setSelectedPermitId] = useState<number>(0);

  const isFirstLoad = useRef(true);

  const location = useLocation();

  useEffect(() => {
    setActiveFilters({});
  }, [selectedLanguageCode, location?.state]);

  useEffect(() => {
    setActiveFilters({});
    if (!isFirstLoad.current) {
      const currentPageKey = location?.state?.pageKey;

      if (currentPageKey) {
        // Find the selected component based on pageKey
        const componentId = Data.find(
          (item) => item.pageKey === currentPageKey
        )?.id;

        if (componentId) {
          setSelectedItem(componentId);
          handleAddEditModalClose();
        }
      }
    }
    isFirstLoad.current = false;
  }, [location?.state]);

  //permitLineItemMap

  const handlePermitIdChange = (id: number) => {
    setSelectedPermitId(id);
  };

  const componentToNameMap: { [key: string]: string } = {
    ManageAccounts: "account",
    ManageAssetCategories: "assetCategory",
    ManageAnomaly: "anomaly",
    ManageInventory: "inventory",
    ManageCheckList: "checklist",
    ManageMapChecklist: "checklistLineMap",
    ManageCheckListLineItem: "checklistLine",
    ManageSkills: "skill",
    ManageToolsAndPPE: "tools",
    ManagePermits: "permit",
    ManageFrequency: "frequency",
    ManageRolesAndPermissionComponent: "roleAndPermission",
    ManagePermitLineItemsAndOptions: "permitLineItem",
    ManageReasonForDeviation: "deviation",
    // Add other mappings here
  };

  const getNameFromComponent = (component: string | null): string => {
    if (component && componentToNameMap[component]) {
      return componentToNameMap[component];
    }
    throw new Error("Unknown component");
  };

  useEffect(() => {
    // Find the selected component based on selectedItem
    const componentName = Data.find(
      (item) => item.id === selectedItem
    )?.component;

    if (componentName) {
      setSelectedComponent(componentName);
      setActiveFilters({});
    } else {
      setSelectedComponent(null);
    }
  }, [selectedItem]);
  const handleClick = (id: number) => {
    setSelectedItem(id);
  };

  const handleAddEditModalClose = () => {
    setTriggerAddModal(false);
  };
  const handleAddEditModalOpen = () => {
    if (hasPermission("Onboarding_Admin")) {
      setTriggerAddModal(true);
    }
    const name = getNameFromComponent(selectedComponent);
    if (name === "inventory") {
      toggleLoader(true);
    }
  };

  const convertToOptionType = (languages: LanguageType[]): OptionType[] =>
    languages
      .filter((language) => language.implemented)
      .map((language) => ({
        id: language.id.toString(),
        label: language.language,
      }));

  const handleDownload = async () => {
    const nonEmptyFilters = Object.entries(activeFilters).filter(
      ([, value]) => value.trim() !== ""
    );
    const filterQuery = nonEmptyFilters
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join("&");
    const queryString = filterQuery
      ? `?${filterQuery}&languageCode=${currentLanguage}`
      : `?languageCode=${currentLanguage}`;

    try {
      const name =
        selectedComponent === "ManagePermitLineItemsAndOptions"
          ? `${getNameFromComponent(selectedComponent)}/${selectedPermitId}`
          : getNameFromComponent(selectedComponent);
      const response = await fetch(
        `${WORK_FLOW_BASE_URL}${name}/export${queryString}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage?.getItem("accessToken")}`,
            userId: String(localStorage.getItem("id")),
            "Accept-Language": localStorage.getItem("language") ?? "en",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      // Create a timestamp
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are 0-based
      const day = String(now.getDate()).padStart(2, "0");
      const hours = String(now.getHours()).padStart(2, "0");
      const minutes = String(now.getMinutes()).padStart(2, "0");
      const timestamp = `${year}-${month}-${day} ${hours}-${minutes}`;
      const FormattedName = name.charAt(0).toUpperCase() + name.slice(1);
      const filename = `${FormattedName} Details ${timestamp}.csv`; // e.g., "Account_Details_20240812_123456.csv"

      const a = document.createElement("a");
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);

      setActiveFilters({});
      return { success: true };
    } catch (error) {
      setOpenErrorModal(true);
      setErrorDesc("DownloadErrorFailedText");
    }
  };

  const browseFiles = () => {
    inputFileRef.current?.click();
  };

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    toggleLoader(true);

    const selectedFile = getSelectedFile(event);
    setFiles(selectedFile);
    if (!selectedFile) {
      handleInvalidFile("CsvValidate");
      return;
    }

    if (!isValidCSVFile(selectedFile)) {
      handleInvalidFile("CsvValidate");
      return;
    }

    try {
      const name =
        selectedComponent === "ManagePermitLineItemsAndOptions"
          ? `${getNameFromComponent(selectedComponent)}/${selectedPermitId}`
          : getNameFromComponent(selectedComponent);
      const response = await validateCSVFileApi(
        selectedFile,
        currentLanguage,
        name
      );
      await handleValidationResponse(response);
    } catch (err) {
      handleErrors(err);
    } finally {
      event.target.value = "";
    }
  };

  const getSelectedFile = (event: ChangeEvent<HTMLInputElement>) => {
    return event.target.files && event.target.files.length > 0
      ? event.target.files[0]
      : null;
  };

  const isValidCSVFile = (file: File) => {
    return (
      (file.type === "application/csv" || file.type === "text/csv") &&
      file.size <= 102400 &&
      file.size !== 0
    );
  };

  const handleInvalidFile = (errorText: string) => {
    toggleLoader(false);
    setOpenErrorModal(true);
    setErrorDesc(errorText);
  };

  const handleValidationResponse = async (response: any) => {
    toggleLoader(false);
    if (response.errorMessages.length > 0) {
      setValidationErrors(response.errorMessages);
      setOpenValidationErrorModal(true);
      return;
    } else {
      setValidationSuccess("SuccessMessageForUpload");
      setOpenValidationSuccessModal(true);
      setExportBeanList(response.exportBeanList);
    }
  };

  const uploadFile = async (file: File, isVersionRequired: boolean) => {
    setOpenValidationSuccessModal(false);
    try {
      const name =
        selectedComponent === "ManagePermitLineItemsAndOptions"
          ? `${getNameFromComponent(selectedComponent)}/${selectedPermitId}`
          : getNameFromComponent(selectedComponent);
      await uploadCSVFileApi(
        file.name,
        currentLanguage,
        exportBeanList,
        name,
        isVersionRequired
      );
      toggleLoader(false);
    } catch (err) {
      handleErrors(err);
    }
  };

  const importButtonClick = () => {
    if (files) {
      uploadFile(files, false);
    }
  };

  const importWithVersionClick = () => {
    if (files) {
      uploadFile(files, true);
    }
  };
  const handleErrors = (err: any) => {
    toggleLoader(false);
    setOpenErrorModal(true);
    handleError(err, setErrorDesc);
  };

  const handleAccountData = (data: MasterDataTypes) => {
    setListData(data);
  };

  const handleFilter = (filteredData: { [key: string]: string }) => {
    setActiveFilters(filteredData);
  };

  const handleClickLang = () => {
    if (selectedLanguageCode === "en") {
      handleAddEditModalOpen();
    }
  };

  return (
    <Box component={"main"} sx={{ padding: "24px 12px  0px 12px" }}>
      {openErrorModal && (
        <ErrorModal
          descriptionText={errorDesc}
          open={openErrorModal}
          handleClose={() => setOpenErrorModal(false)}
          onPositiveClick={() => {
            setOpenErrorModal(false);
          }}
        />
      )}
      {openValidationErrorModal && (
        <ValidationErrorModal
          descriptionTexts={validationErrors}
          open={openValidationErrorModal}
          handleClose={() => setOpenValidationErrorModal(false)}
          onOkClick={() => {
            setOpenValidationErrorModal(false);
          }}
        />
      )}
      {openValidationSuccessModal && (
        <ValidationErrorModal
          descriptionSuccess={validationSuccess}
          open={openValidationSuccessModal}
          handleClose={() => setOpenValidationSuccessModal(false)}
          onCancelClick={() => {
            setOpenValidationSuccessModal(false);
          }}
          onImportClick={importButtonClick}
          onImportWithVersionClick={importWithVersionClick}
          isValid={true}
          languageCode={selectedLanguageCode}
          selectedComponent={
            selectedComponent !== undefined && selectedComponent !== null
              ? selectedComponent
              : ""
          }
        />
      )}
      <Box component={"section"}>
        <Box
          component={"div"}
          className="flex__"
          sx={{ justifyContent: "flex-end" }}
        >
          {selectedComponent !== "ManageRolesAndPermissions" &&
            selectedComponent !== "ManageMapChecklist" && (
              <>
                <Box
                  className="flex__ cursor__pointer"
                  sx={{
                    padding: "8px 12px",
                    borderRadius: "4px",
                    border: "1px solid rgba(166, 197, 226, 0.16)",
                    marginRight: "8px",
                    backgroundColor: "#2B3033",
                  }}
                  onClick={() => {
                    if (hasPermission("Onboarding_Admin")) {
                      browseFiles();
                    }
                  }}
                >
                  <input
                    ref={inputFileRef}
                    type="file"
                    accept=".csv"
                    style={{ display: "none", height: "36px" }}
                    onChange={handleFileChange}
                  />
                  <Upload />
                  <MediumTypography
                    labelId="Upload.text"
                    defaultLabel="Upload"
                    className="ml-xs"
                  />
                </Box>
                <Box
                  className="flex__ cursor__pointer"
                  sx={{
                    padding: "8px 12px",
                    borderRadius: "4px",
                    border: "1px solid rgba(166, 197, 226, 0.16)",
                    marginRight: "8px",
                    backgroundColor: "#2B3033",
                  }}
                  onClick={() => {
                    if (hasPermission("Onboarding_Admin")) {
                      handleDownload();
                    }
                  }}
                >
                  <Download style={{ marginTop: "4px" }} />
                  <MediumTypography
                    labelId="Download.text"
                    defaultLabel="Download"
                    className="ml-xs"
                  />
                </Box>
              </>
            )}

          {selectedComponent !== "ManageMapChecklist" &&
            selectedComponent !== "ManageRolesAndPermissions" && (
              <DropdownComponent
                names={convertToOptionType(listData?.languages ?? [])}
                value={selectedLanguageId?.toString()}
                handleChange={(value: string) => {
                  setSelectedLanguageId(parseInt(value));

                  setSelectedLanguageCode(languageMap[Number(value)]);
                }}
                minWidth="150px"
                sxProps={{ height: "40px" }}
                iconRequired={true}
                disabled={!listData?.isEnRecordsExist}
              />
            )}

          {selectedComponent === "ManageMapChecklist" ? (
            <ButtonComponent
              className="btn-primary btn-submit ml-md"
              variantType="contained"
              defaultLabelId={"Map Line Items"}
              labelId={"btn.mapItems"}
              onClick={handleAddEditModalOpen}
            />
          ) : (
            selectedComponent !== "ManageRolesAndPermissions" && (
              <ButtonComponent
                className="btn-primary btn-submit ml-md"
                variantType="contained"
                defaultLabelId={"Add"}
                labelId={"btn.Add"}
                disabled={selectedLanguageCode !== "en"}
                // onClick={
                //   selectedLanguageCode === "en"
                //     ? handleAddEditModalOpen
                //     : () => {}
                // }
                onClick={handleClickLang}
              />
            )
          )}
        </Box>

        <Box
          sx={{
            backgroundColor: "#323337",
            marginTop: "10px",
            padding: "16px",
            height: useAvailableHeight(140),
            borderRadius: "4px",
            display: "flex",
          }}
          className="master-data-container"
        >
          <Box
            sx={{
              overflowY: "auto",
              height: useAvailableHeight(160),
              width: "250px",
              "&::-webkit-scrollbar": { display: "none" },
            }}
          >
            {Data.map((item) => (
              <Box
                key={item.id}
                sx={{
                  padding: "12px",
                  backgroundColor: selectedItem === item.id ? "#22272B" : "",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
                onClick={() => handleClick(item.id)}
              >
                <MediumTypography
                  labelId={item.labelId}
                  defaultLabel={item.defaultLabelId}
                  sxProps={{
                    color:
                      selectedItem === item.id
                        ? "#ffffff"
                        : "rgba(255, 255, 255, 0.48)",
                  }}
                />
              </Box>
            ))}
          </Box>

          <Box
            sx={{
              borderRight: "2px solid #435161",
              marginRight: "16px",
              marginLeft: "16px",
              width: "2px",
              height: useAvailableHeight(160),
            }}
          ></Box>

          <Box>
            {selectedComponent === "ManageAccounts" && (
              <ManageAccounts
                onAccountDataFetch={handleAccountData}
                selectedLanguageCode={selectedLanguageCode}
                onOpenAddModal={triggerAddModal}
                handleModalClose={handleAddEditModalClose}
                handleModalOpen={handleAddEditModalOpen}
                onFilter={handleFilter}
              />
            )}
            {selectedComponent === "ManageAssetCategories" && (
              <ManageAssetCategories
                onAssetDataFetch={handleAccountData}
                selectedLanguageCode={selectedLanguageCode}
                onOpenAddModal={triggerAddModal}
                handleModalClose={handleAddEditModalClose}
                handleModalOpen={handleAddEditModalOpen}
                onFilter={handleFilter}
              />
            )}
            {selectedComponent === "ManageAnomaly" && (
              <ManageAnomaly
                onDataFetch={handleAccountData}
                selectedLanguageCode={selectedLanguageCode}
                onOpenAddModal={triggerAddModal}
                handleModalClose={handleAddEditModalClose}
                handleModalOpen={handleAddEditModalOpen}
                onFilter={handleFilter}
              />
            )}
            {selectedComponent === "ManageInventory" && (
              <ManageInventory
                onDataFetch={handleAccountData}
                selectedLanguageCode={selectedLanguageCode}
                onOpenAddModal={triggerAddModal}
                handleModalClose={handleAddEditModalClose}
                handleModalOpen={handleAddEditModalOpen}
                onFilter={handleFilter}
              />
            )}
            {selectedComponent === "ManageCheckList" && (
              <ManageChecklistComponent
                onDataFetch={handleAccountData}
                selectedLanguageCode={selectedLanguageCode}
                onOpenAddModal={triggerAddModal}
                handleModalClose={handleAddEditModalClose}
                handleModalOpen={handleAddEditModalOpen}
                onFilter={handleFilter}
              />
            )}
            {selectedComponent === "ManageMapChecklist" && (
              <ManageMapChecklistComponent
                onDataFetch={handleAccountData}
                selectedLanguageCode={selectedLanguageCode}
                onOpenAddModal={triggerAddModal}
                handleModalClose={handleAddEditModalClose}
                handleModalOpen={handleAddEditModalOpen}
                onFilter={handleFilter}
              />
            )}
            {selectedComponent === "ManageCheckListLineItem" && (
              <ManageLineItemComponent
                onDataFetch={handleAccountData}
                selectedLanguageCode={selectedLanguageCode}
                onOpenAddModal={triggerAddModal}
                handleModalClose={handleAddEditModalClose}
                handleModalOpen={handleAddEditModalOpen}
                onFilter={handleFilter}
              />
            )}

            {selectedComponent === "ManageToolsAndPPE" && (
              <ManageToolsAndPPE
                onDataFetch={handleAccountData}
                selectedLanguageCode={selectedLanguageCode}
                onOpenAddModal={triggerAddModal}
                handleModalClose={handleAddEditModalClose}
                handleModalOpen={handleAddEditModalOpen}
                onFilter={handleFilter}
                selectedComponent="ManageToolsAndPPE"
              />
            )}
            {selectedComponent === "ManageSkills" && (
              <ManageSkillComponent
                onDataFetch={handleAccountData}
                selectedLanguageCode={selectedLanguageCode}
                onOpenAddModal={triggerAddModal}
                handleModalClose={handleAddEditModalClose}
                handleModalOpen={handleAddEditModalOpen}
                onFilter={handleFilter}
              />
            )}
            {selectedComponent === "ManageFrequency" && (
              <ManageFrequencyComponent
                onDataFetch={handleAccountData}
                selectedLanguageCode={selectedLanguageCode}
                onOpenAddModal={triggerAddModal}
                handleModalClose={handleAddEditModalClose}
                handleModalOpen={handleAddEditModalOpen}
                onFilter={handleFilter}
              />
            )}
            {selectedComponent === "ManagePermitLineItemsAndOptions" && (
              <ManagePermitListItemMapComponent
                onDataFetch={handleAccountData}
                selectedLanguageCode={selectedLanguageCode}
                onOpenAddModal={triggerAddModal}
                handleModalClose={handleAddEditModalClose}
                handleModalOpen={handleAddEditModalOpen}
                onFilter={handleFilter}
                handlePermitIdChange={handlePermitIdChange}
              />
            )}
            {selectedComponent === "ManagePermits" && (
              <ManagePermits
                onDataFetch={handleAccountData}
                selectedLanguageCode={selectedLanguageCode}
                onOpenAddModal={triggerAddModal}
                handleModalClose={handleAddEditModalClose}
                handleModalOpen={handleAddEditModalOpen}
                onFilter={handleFilter}
              />
            )}
            {selectedComponent === "ManageRolesAndPermissions" && (
              <ManageRolesAndPermissionComponent onFilter={handleFilter} />
            )}

            {selectedComponent === "ManageReasonForDeviation" && (
              <ManageReasonForDeviation
                onDataFetch={handleAccountData}
                selectedLanguageCode={selectedLanguageCode}
                onOpenAddModal={triggerAddModal}
                handleModalClose={handleAddEditModalClose}
                handleModalOpen={handleAddEditModalOpen}
                onFilter={handleFilter}
              />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default MasterDataManagement;
