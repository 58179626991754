import { Box, Tooltip } from "@mui/material";
import MediumTypography from "../../components/formlib/MediumTypography";
import { FC } from "react";
import { ReactComponent as Available } from "../../assets/images/Available.svg";
import { ReactComponent as Noshow } from "../../assets/images/Noshow.svg";
import { ReactComponent as Weekoff } from "../../assets/images/Weekoff.svg";
import { ReactComponent as Timeoff } from "../../assets/images/TimeOffIcon.svg";

export interface shiftDetails {
  shiftName: string;
  shiftStaticName: string;
  shiftUserCount: number;
}
export interface ShiftMonthlyDetails {
  date: string;
  shiftDetails: shiftDetails[];
  availableMemberCount: number;
  noShowMemberCount: number;
  weekOffMemberCount: number;
  timeOffMemberCount: number;
  holidayName: string;
}

interface ShiftDayData {
  data: ShiftMonthlyDetails;
}

const DayShiftDetails: FC<ShiftDayData> = ({ data }) => {
  return (
    <Box>
      <Box className="flex__ mr-md">
        <Box className="mr-lg">
          {data.shiftDetails.map((item: shiftDetails) => (
            <Box key={item.shiftStaticName} className="flex__ mb-sm">
              <Tooltip title={item.shiftName}>
                <Box>
                  <MediumTypography
                    label={item.shiftStaticName}
                    className="dayview_shift_btn_1 mr-sm"
                    textColor="#626F86"
                    sxProps={{
                      height: "fit-content",
                      border: "2px solid #626F86",
                      lineHeight: "normal",
                      fontSize: "14px",
                    }}
                  />
                </Box>
              </Tooltip>
              <MediumTypography
                label={item.shiftUserCount.toString()}
                textColor="#626F86"
                sxProps={{ fontSize: "14px" }}
              />
            </Box>
          ))}
        </Box>
        <Box>
          {data.availableMemberCount > 0 && (
            <Box className="flex__ mb-sm align__items__center">
              <Tooltip title={"Available"}>
                <Available style={{ width: "18px", height: "18px" }} />
              </Tooltip>
              <MediumTypography
                label={data.availableMemberCount?.toString() ?? "0"}
                className="ml-sm"
                textColor="#626F86"
                sxProps={{ fontSize: "14px" }}
              />
            </Box>
          )}
          {data.noShowMemberCount > 0 && (
            <Box className="flex__ mb-sm align__items__center">
              <Tooltip title={"No Show"}>
                <Noshow style={{ width: "18px", height: "18px" }} />
              </Tooltip>
              <MediumTypography
                label={data.noShowMemberCount?.toString() ?? "0"}
                className="ml-sm"
                textColor="#626F86"
                sxProps={{ fontSize: "14px" }}
              />
            </Box>
          )}
          {data.weekOffMemberCount > 0 && (
            <Box className="flex__ mb-sm align__items__center">
              <Tooltip title={"Week Off"}>
                <Weekoff style={{ width: "18px", height: "18px" }} />
              </Tooltip>
              <MediumTypography
                label={data.weekOffMemberCount?.toString() ?? "0"}
                className="ml-sm"
                textColor="#626F86"
                sxProps={{ fontSize: "14px" }}
              />
            </Box>
          )}
          {data.timeOffMemberCount > 0 && (
            <Box className="flex__ mb-sm align__items__center">
              <Tooltip title={"Time Off"}>
                <Timeoff style={{ width: "18px", height: "18px" }} />
              </Tooltip>
              <MediumTypography
                label={data.timeOffMemberCount?.toString() ?? "0"}
                className="ml-sm"
                textColor="#626F86"
                sxProps={{ fontSize: "14px" }}
              />
            </Box>
          )}
        </Box>
      </Box>

      {data.holidayName && (
        <Box
          sx={{
            backgroundColor: "#626F86",
            width: "fit-content",
            borderRadius: "4px",
            padding: "0px 4px",
            position: "absolute",
            top: "105px",
          }}
        >
          <MediumTypography
            label={data.holidayName}
            sxProps={{ fontSize: "12px" }}
          />
        </Box>
      )}
    </Box>
  );
};

export default DayShiftDetails;
