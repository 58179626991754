import { Avatar, Box, Divider, Grid, TextField, Tooltip } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import "./WorkOrderExecution.css";
import MediumTypography from "../../components/formlib/MediumTypography";
import SmallBorderLine from "../../components/formlib/SmallBorderLine";
import { ReactComponent as CrossIcon } from "../../assets/images/_CrossIcon_.svg";
import ButtonComponent from "../../components/formlib/ButtonComponent";
import TextInput from "../../components/formlib/TextInput";
import dayjs, { Dayjs } from "dayjs";
import DropdownComponent from "../../components/formlib/DropdownComponent";
import * as yup from "yup";
import { Formik, FormikProps } from "formik";
import StatusExecution from "../../components/formlib/StatusExecution";
import { useLocation } from "react-router";

import { ReactComponent as ErrorIcon } from "../../assets/images/ErrorIcon.svg";

import {
  DropDownType,
  GrassCutting,
  Vendor,
  getGrassCuttingApi,
  saveGrassCuttingApi,
} from "../../api/GrassCutting/GrassCutting";

import { OptionType } from "../../utils/type";

import ErrorModal from "../../components/formlib/modal/ErrorModal";
import {
  ApproverType,
  AssigneeType,
  ImageType,
  StatusDataType,
} from "../../api/moduleCleaning/ModuleCleaningApi";
import { useNavigate } from "react-router-dom";
import { LoaderContext, LoaderContextType } from "../../layouts/appSidebar";
import DateTimePickerComponent from "../../components/formlib/DateTimePickerComponent";
import WorkOrderGallery from "./WorkOrderGallery";
import WorkOrderActivity from "./WorkOrderActivity";
import WorkOrderAssignees from "./WorkOrderAssignees";
import {
  GRASS_CUTTING_PLANNER,
  SCHEDULER_DASHBOARD,
} from "../../routes/Routing";
import { hasPermission } from "../../utils/checkPermission";
import {
  convertHoursAndMinutesToMinutes,
  convertMinutesToHoursAndMinutes,
} from "../../api/Constant";
import useAvailableHeight from "../../utils/useAvailableHeight";
import { useIntl } from "react-intl";
import { ReactComponent as ChevronCircleIcon } from "../../assets/images/chevronDownCircleIcon.svg";
import { handleError } from "../../utils/commonFunctions";

const initialGrassCuttingValues: GrassCutting = {
  woId: 0,
  isEdit: false,
  isEditCard: false,
  status: [],
  cardDetailsInfo: {
    code: "",
    type: "",
    assetCatFreqDesc: "",
    workOrderUniqueId: 0,
    frequencyDisplay: "",
    assetNameAndCount: "",
    asset: "",
    lastUpdated: "",
  },
  workOrderGcInfo: {
    scheduledStartDateTime: null,
    scheduledEndDateTime: null,
    actualStartDateTime: null,
    actualEndDateTime: null,
    plannedArea: 0,
    actualArea: 0,
    rejectedArea: 0,
    plannedTables: 0,
    actualTables: 0,
    rejectedTables: 0,
    cycleName: "",
    leadAssignee: {
      name: "",
      id: 0,
      title: "",
      image: "",
      status: "",
      availability: "",
      date: "",
      isPrimary: false,
    },
  },
  grassCuttingInfoBean: {
    selectedGrassCuttingTypeId: 0,
    selectedGCCoverageTypeIds: [],
    selectedGrassCuttingTypeName: "",
    selectedGCMethodId: 0,
    laboursAllocated: 0,
    machineAllocated: 0,
    fuelConsumption: 0,
    fuelUnitPrice: 0,
    herbicideTanks: 0,
    herbicideConsumption: 0,
    remarks: "",
    selectedReasonForDeviationId: 0,
    dumpingInfo: "",
    otherReason: "",
    numberOfWorkingHours: 0,
    impactedHours: 0,
  },
  additionalInfoBean: {
    cycleCountInc: 0,
    cumulativeCycleCount: 0,
    rainFall: 0,
    vendorId: 0,
    contractLineItem: "",
    lastUpdatedBy: {
      name: "",
      id: 0,
      title: "",
      image: "",
      status: "",
      availability: "",
      date: "",
      isPrimary: false,
    },
    vendorName: "",
  },
  assignee: [],
  definedScheduleStatus: [],
  scheduleExecution: {},
  version: 0,
};

const convertSquareMetersToAcres = (squareMeters: number): number => {
  return squareMeters * 0.000247105;
};

const getFormattedDisplayArea = (area: number): number => {
  return parseFloat(convertSquareMetersToAcres(area).toFixed(2));
};

const getCovergaeType = (id: number, coverageTypes: DropDownType[]) =>
  coverageTypes.find((type) => type.id === id)?.name || "";

const GrassCuttingComponent = () => {
  const [leadAssignee, setLeadAssignee] = useState<AssigneeType>();
  const [assignees, setAssignees] = useState<AssigneeType[]>([]);

  const [cardEdit, setCardEdit] = useState(false);
  const formikRef = useRef<FormikProps<GrassCutting>>(null);
  const availableHeight = useAvailableHeight(330);
  const collapseAvailHeight = useAvailableHeight(230);

  const navigate = useNavigate();
  const [message, setMessage] = useState<string>("");
  const [othersReasonMessage, setOthersReasonMessage] = useState("");
  const [dumpingInfo, setDumpingInfo] = useState<string>("");
  const [grassCuttingValues, setGrassCuttingValues] = useState<GrassCutting>(
    initialGrassCuttingValues
  );
  const [selectedValues, setSelectedValues] = useState<number[]>([]);
  const location = useLocation();
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [errorDesc, setErrorDesc] = useState<string>("");
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  const [dumgImage, setDumpImage] = useState<ImageType[]>([]);
  const [afterImage, setAfterImage] = useState<ImageType[]>([]);
  const [beforeImage, setBeforeImage] = useState<ImageType[]>([]);
  const [approver1, setApprover1] = useState<ApproverType | null | undefined>();
  const [approver2, setApprover2] = useState<ApproverType | null | undefined>();
  const [approver3, setApprover3] = useState<ApproverType | null | undefined>();
  // const [cardDetails, setCardDetails] = useState<ShiftCardTypes | null>(null);
  const navState = location?.state;
  const [error, setError] = useState("");
  const intl = useIntl();
  const [editDisable, setEditDisable] = useState(false);
  const [showAwaitingApproval, setShowAwaitingApproval] = useState(false);
  const [lastTransition, setLastTransition] = useState<StatusDataType>(
    {} as StatusDataType
  );
  const [isVisibleGC, setIsVisibleGC] = useState(true); // expand or collapse filters state

  const validationSchema = yup.object().shape({
    woId: yup.number().nullable(),
    isEdit: yup.boolean().nullable(),
    isEditCard: yup.boolean().nullable(),
    workOrderGcInfo: yup.object().shape({
      scheduledStartDateTime: yup.string().nullable(),
      scheduledEndDateTime: yup.string().nullable(),
      actualStartDateTime: yup.string().nullable(),
      actualEndDateTime: yup.string().nullable(),
      plannedArea: yup.number().nullable(),
      actualArea: yup.number().nullable(),
      cycleName: yup.string().nullable(),
      rejectedArea: yup.number().nullable(),
      plannedTables: yup.number().nullable(),
      actualTables: yup.number().nullable(),
      rejectedTables: yup.number().nullable(),
    }),
    grassCuttingInfoBean: yup.object().shape({
      selectedGrassCuttingTypeId: yup.number().nullable(),
      selectedGCCoverageTypeIds: yup.array().of(yup.number()).nullable(),
      selectedGCMethodId: yup
        .number()
        .required("validation.fieldRequired")
        .test(
          "is-not-zero",
          "validation.fieldRequired",
          (value) => value !== 0
        ),
      laboursAllocated: yup.number().nullable(),
      machineAllocated: yup.number().nullable(),
      fuelConsumption: yup.number().nullable(),
      fuelUnitPrice: yup.number().nullable(),
      herbicideTanks: yup.number().nullable(),
      herbicideConsumption: yup.number().nullable(),
      remarks: yup.string().nullable(),
      selectedReasonForDeviationId: yup.number().nullable(),
      dumpingInfo: yup.string().nullable(),
      otherReason: yup.string().nullable(),
      numberOfWorkingHours: yup.number().nullable(),
    }),
    additionalInfoBean: yup.object().shape({
      cycleCountInc: yup.number().nullable(),
      cumulativeCycleCount: yup.number().nullable(),
      rainFall: yup.number().nullable(),
      vendorId: yup
        .number()
        .required("validation.fieldRequired")
        .test(
          "is-not-zero",
          "validation.fieldRequired",
          (value) => value !== 0
        ),
      contractLineItem: yup.string().nullable(),
      vendorName: yup.string().nullable(),
    }),
  });

  useEffect(() => {
    getGrassCuttingInfo();
  }, []);

  const stateCardId = JSON.parse(navState.cardId);
  useEffect(() => {
    if (grassCuttingValues.status !== undefined) {
      if (
        grassCuttingValues.status[grassCuttingValues.status.length - 1]
          ?.code === "CLOSED"
      ) {
        setEditDisable(true);
      } else {
        setEditDisable(false);
      }
    }
  }, [stateCardId, grassCuttingValues]);

  const getGrassCuttingInfo = () => {
    toggleLoader(true);
    getGrassCuttingApi(JSON.parse(navState.cardId))
      .then((response) => {
        toggleLoader(false);
        const lastStatus = response.status[response.status.length - 2];
        const showAwaitingApprovals = [
          "COMPLETED",
          "LEVEL1",
          "LEVEL2",
        ].includes(lastStatus.code);

        setShowAwaitingApproval(showAwaitingApprovals);

        // Find the last transition date and time
        const lastTransitionStatus = lastStatus;
        setLastTransition(lastTransitionStatus);
        setGrassCuttingValues(response);
        setMessage(response.grassCuttingInfoBean.remarks);
        setDumpImage(response.dumpImage || []);
        setAfterImage(response.afterImage || []);
        setBeforeImage(response.beforeImage || []);
        if ("approver1" in response) {
          setApprover1(response.approver1);
        }
        if ("approver2" in response) {
          setApprover2(response.approver2);
        }
        if ("approver3" in response) {
          setApprover3(response.approver3);
        }
        setOthersReasonMessage(
          response.grassCuttingInfoBean?.otherReason || ""
        );
        setDumpingInfo(response.grassCuttingInfoBean.dumpingInfo);
        setSelectedValues(
          response.grassCuttingInfoBean.selectedGCCoverageTypeIds
        );
        const lead = response.assignee.find((person) => person.isPrimary);
        const assignee = response.assignee.filter(
          (person) => !person.isPrimary
        );

        setLeadAssignee(lead);
        setAssignees(assignee);
      })
      .catch((err) => {
        toggleLoader(false);
        setOpenErrorModal(true);
        handleError(err, setErrorDesc);
      });
  };

  const convertToOptionType = (data: DropDownType[]): OptionType[] => {
    return data.map((item) => {
      const itemData: OptionType = {
        id: item.id.toString(),
        label: item.name,
      };
      return itemData;
    });
  };

  const convertVendorToOptionType = (data: Vendor[]): OptionType[] => {
    return data.map((item) => {
      const itemData: OptionType = {
        id: item.id.toString(),
        label: item.name,
      };
      return itemData;
    });
  };
  const handleSaveGrassCutting = (values: GrassCutting) => {
    values.isEdit = true;
    toggleLoader(true);
    saveGrassCuttingApi(JSON.parse(navState.cardId), values)
      .then(() => {
        setCardEdit(false);
        getGrassCuttingInfo();
        setError("");
      })
      .catch((err) => {
        toggleLoader(false);
        setOpenErrorModal(true);
        setError("");
        handleError(err, setErrorDesc);
      });
  };

  const toggleVisibility = () => {
    setIsVisibleGC(!isVisibleGC);
  };

  return (
    <Box component="main">
      {openErrorModal && (
        <ErrorModal
          descriptionText={errorDesc}
          open={openErrorModal}
          handleClose={() => setOpenErrorModal(false)}
          onPositiveClick={() => {
            setOpenErrorModal(false);
          }}
        />
      )}

      <Box sx={{ padding: "24px 0px 24px 0px" }}>
        <Box className="flex__" sx={{ gap: "10px", marginBottom: "10px" }}>
          <Box>
            <MediumTypography
              className="card-wo-category"
              label={grassCuttingValues.cardDetailsInfo.code}
              sxProps={{
                color: "#B8ACF6",
                border: `2px solid #B8ACF6`,
              }}
            />
          </Box>
          <Box>
            <MediumTypography
              label={grassCuttingValues.cardDetailsInfo.type}
              textColor="#B8ACF6"
              fontSize="16px"
              fontWeight={700}
            />
          </Box>
          <Box className="flex__" sx={{ marginLeft: "auto" }}>
            <Box className="position__relative" sx={{ right: "20px" }}>
              <MediumTypography
                label={`Last Updated ${dayjs(
                  grassCuttingValues.cardDetailsInfo.lastUpdated
                ).format("DD MMMM YYYY")} at ${dayjs(
                  grassCuttingValues.cardDetailsInfo.lastUpdated
                ).format("HH:mm")}`}
                textColor="#626F86"
                fontSize="14px"
                fontWeight={400}
              />
            </Box>
            <Box className="position__relative" sx={{ right: "10px" }}>
              <MediumTypography
                labelId="CloseText"
                defaultLabel="Close"
                textColor="#626F86"
                fontSize="14px"
                fontWeight={400}
              />
            </Box>
            <Box>
              <CrossIcon
                className="cursor__pointer"
                onClick={() =>
                  navigate(SCHEDULER_DASHBOARD, {
                    state: {
                      date: navState.date,
                      dateRange: navState.dateRange,
                      schedulerViewChange: navState.schedulerViewChange,
                    },
                  })
                }
              />
            </Box>
          </Box>
          {/* <Box>
            <MediumTypography
              label={grassCuttingValues.cardDetailsInfo.assetCatFreqDesc}
              textColor="#9FADBC"
              fontSize="16px"
              fontWeight={500}
            />
          </Box> */}
        </Box>
        <Box className="flex__" sx={{ gap: "10px", marginBottom: "10px" }}>
          <Box>
            <MediumTypography
              label={grassCuttingValues.cardDetailsInfo.workOrderUniqueId.toString()}
              textColor="#FFFFFF"
              fontSize="20px"
              fontWeight={700}
            />
          </Box>
          <SmallBorderLine />

          <Box>
            <MediumTypography
              className="card-wo-routine"
              label={grassCuttingValues.cardDetailsInfo.frequencyDisplay}
              sxProps={{ padding: "4px 4px !important" }}
            />
          </Box>
          <Box className="flex__">
            <MediumTypography
              label={grassCuttingValues.cardDetailsInfo.assetNameAndCount}
              textColor="#FFFFFF"
              fontSize="20px"
              fontWeight={700}
            />
            {isVisibleGC && (
              <Box
                className="cursor__pointer"
                sx={{
                  position: "relative",
                  alignItems: "end",
                  // backgroundColor: "#fff", // Optional: To give a white background around the icon
                  padding: "4px 8px", // Optional: Adjust the padding around the icon
                }}
                onClick={toggleVisibility}
              >
                <Tooltip title="Hide State Transition">
                  <ChevronCircleIcon />
                </Tooltip>
              </Box>
            )}
            {!isVisibleGC && (
              <Box
                className="cursor__pointer"
                sx={{
                  position: "relative",
                  alignItems: "end",
                  // backgroundColor: "#fff", // Optional: To give a white background around the icon
                  padding: "4px 8px", // Optional: Adjust the padding around the icon
                }}
                onClick={toggleVisibility}
              >
                <Tooltip title="Show State Transition">
                  <ChevronCircleIcon className="rotated-180" />
                </Tooltip>
              </Box>
            )}
          </Box>
          {showAwaitingApproval && (
            <Box className="flex__" sx={{ marginLeft: "auto" }}>
              <Box
                className="flex__ position__relative"
                sx={{
                  padding: "4px 12px 4px 12px",
                  borderRadius: "3px",
                  backgroundColor: "rgba(161, 189, 217, 0.08)",
                  right: "20px",
                }}
              >
                {/* <ProfilePhoto /> */}
                <Box className="flex__">
                  <MediumTypography
                    defaultLabel={`Awaiting Approval - `}
                    labelId="AwaitingApproval"
                    textColor="rgba(255, 255, 255, 0.72)"
                    sxProps={{
                      textTransform: "none",
                      position: "relative",
                      left: "4px",
                    }}
                  />
                  <MediumTypography
                    label={`${
                      lastTransition.code === "COMPLETED"
                        ? lastTransition.approverRole1
                        : lastTransition.code === "LEVEL1"
                        ? lastTransition.approverRole2
                        : lastTransition.approverRole3
                    }`}
                    textColor="rgba(255, 255, 255, 0.72)"
                    className="ml-xs"
                    sxProps={{
                      textTransform: "none",
                      position: "relative",
                      left: "4px",
                    }}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  padding: "4px 8px 4px 8px",
                  borderRadius: "3px",
                  backgroundColor: "rgba(161, 189, 217, 0.08)",
                }}
                className="flex__"
              >
                <MediumTypography
                  defaultLabel={"Approval Requested on "}
                  labelId="ApprovedOnLabel"
                  textColor="rgba(255, 255, 255, 0.72)"
                  sxProps={{
                    textTransform: "none",
                  }}
                />
                <MediumTypography
                  label={`${dayjs(lastTransition.transitionDateTime).format(
                    "DD MMMM YYYY"
                  )} ${dayjs(lastTransition.transitionDateTime).format(
                    "HH:mm"
                  )}`}
                  className="ml-xs"
                  textColor="rgba(255, 255, 255, 0.72)"
                  sxProps={{
                    textTransform: "none",
                  }}
                />
              </Box>
            </Box>
          )}
        </Box>
        {isVisibleGC && (
          <Grid container className="mt-xs">
            <Grid item xs={12} lg={12} md={12} xl={12}>
              <Box>
                <Divider
                  variant="fullWidth"
                  sx={{ borderTop: "1px solid #333b43" }}
                />
              </Box>
            </Grid>
          </Grid>
        )}
        {isVisibleGC &&
          grassCuttingValues.status.length > 0 &&
          grassCuttingValues.definedScheduleStatus.length > 0 && (
            <StatusExecution
              statusArray={grassCuttingValues.status}
              cardId={navState.cardId}
              WOUniqueId={grassCuttingValues.cardDetailsInfo.workOrderUniqueId}
              definedScheduleStatus={grassCuttingValues.definedScheduleStatus}
              scheduleExecution={grassCuttingValues.scheduleExecution}
              statusCallBack={getGrassCuttingInfo}
              version={grassCuttingValues.version}
              code={grassCuttingValues.cardDetailsInfo.code}
              assetGroupId={navState.assetGroupId}
              idType="workorder"
              currentDate={navState.date}
              dateRange={navState.dateRange}
              actualsValue={grassCuttingValues?.workOrderGcInfo.actualTables}
            />
          )}

        <Box component="div">
          <Formik
            initialValues={grassCuttingValues}
            enableReinitialize
            validateOnChange
            innerRef={formikRef}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              values.grassCuttingInfoBean.selectedGCCoverageTypeIds =
                selectedValues;
              const payload: GrassCutting = {
                woId: values.woId,
                isEdit: values.isEdit,
                status: values.status,
                cardDetailsInfo: values.cardDetailsInfo,
                additionalInfoBean: values.additionalInfoBean,
                assignee: values.assignee,
                workOrderGcInfo: values.workOrderGcInfo,
                grassCuttingInfoBean: values.grassCuttingInfoBean,
                isAssetGrouped: values.isAssetGrouped,
                definedScheduleStatus: [],
                scheduleExecution: {},
                version: values.version,
              };
              if (!error) {
                handleSaveGrassCutting(payload);
              }
            }}
          >
            {({ values, setFieldValue, handleSubmit, errors, touched }) => {
              const reasonOthers =
                grassCuttingValues.reasonForDeviationList &&
                grassCuttingValues.reasonForDeviationList.find(
                  (type) => type.code === "Others"
                );

              const reasonOthersId = reasonOthers ? reasonOthers.id : null;

              return (
                <Grid container>
                  <Grid
                    item
                    xs={9.5}
                    sx={{
                      borderRight: "1px solid #333b43",
                      overflowY: "auto",
                      height: isVisibleGC
                        ? availableHeight
                        : collapseAvailHeight,
                    }}
                  >
                    <Grid container className="mt-xs">
                      <Grid item xs={12} lg={12} md={12} xl={12}>
                        <Box>
                          <Divider
                            variant="fullWidth"
                            sx={{
                              borderTop: "1px solid #333b43",
                            }}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                    <Box sx={{ padding: "24px 0px 24px 0px" }}>
                      <Box className="flex__" sx={{ marginBottom: "20px" }}>
                        <Box>
                          <MediumTypography
                            labelId="WorkOrderInfoText"
                            defaultLabel="Work order Info"
                            fontSize="20px"
                            fontWeight={500}
                            textColor="#FFFFFF"
                          />
                        </Box>
                        {!editDisable && (
                          <Box sx={{ marginLeft: "auto" }}>
                            <Grid
                              className="flex__ justifyContent-FlexEnd"
                              container
                              direction="row"
                              alignItems="right"
                            >
                              {cardEdit && (
                                <Grid item>
                                  <ButtonComponent
                                    sxProps={{ color: "#B6C2CF !important" }}
                                    className="btn-primary btn-cancel"
                                    variantType="outlined"
                                    defaultLabelId="Cancel"
                                    labelId="btn.cancel"
                                    onClick={() => {
                                      setCardEdit(false);
                                      formikRef.current?.resetForm();
                                      setError("");
                                    }}
                                  />
                                </Grid>
                              )}
                              {cardEdit && (
                                <Grid item>
                                  <ButtonComponent
                                    className="btn-primary btn-submit mr-md"
                                    variantType="contained"
                                    defaultLabelId="Save"
                                    labelId="btn.save"
                                    onClick={handleSubmit}
                                  />
                                </Grid>
                              )}
                              {!cardEdit && (
                                <Grid item>
                                  <ButtonComponent
                                    className="btn-primary btn-submit mr-md"
                                    variantType="contained"
                                    defaultLabelId="Edit"
                                    labelId="btn.edit"
                                    onClick={() => {
                                      setCardEdit(true);
                                    }}
                                  />
                                </Grid>
                              )}
                            </Grid>
                          </Box>
                        )}
                      </Box>
                      <Grid
                        container
                        direction={"row"}
                        className="justifyContentSpaceBtw"
                        alignItems={"center"}
                        rowSpacing={"16px"}
                        columnSpacing={"40px"}
                        sx={{ marginBottom: "20px" }}
                      >
                        <Grid item>
                          <MediumTypography
                            labelId="CycleNameTextInput"
                            defaultLabel="Cycle Name"
                            sxProps={{ marginBottom: "10px" }}
                            textColor={"rgba(159, 173, 188, 1)"}
                          />
                          <TextInput
                            labelId="CycleNameTextInput"
                            className="textInputWidth"
                            defaultLabelId="Cycle Name"
                            borderColor="1px solid rgba(166, 197, 226, 0.16)"
                            Value={values.workOrderGcInfo.cycleName}
                            handlechange={(value: string) => {
                              setFieldValue("workOrderGcInfo.cycleName", value);
                            }}
                            variant="outlined"
                            backGroundColor="#22272B"
                            fieldheight="40px"
                            textColor={
                              cardEdit
                                ? "rgba(255, 255, 255, 1)"
                                : "rgba(140, 155, 171, 1)"
                            }
                          />
                        </Grid>
                        <Grid item>
                          <MediumTypography
                            labelId="LeadAssigneeTextinput"
                            defaultLabel="Lead Assignee"
                            sxProps={{ marginBottom: "10px" }}
                            textColor={"rgba(159, 173, 188, 1)"}
                          />
                          <TextInput
                            labelId="LeadAssigneeTextinput"
                            defaultLabelId="Lead Assignee"
                            className="textInputWidth"
                            borderColor="1px solid rgba(166, 197, 226, 0.16)"
                            variant="outlined"
                            Value={values.workOrderGcInfo.leadAssignee.name}
                            backGroundColor="#22272B"
                            fieldheight="40px"
                            inputProps={{ readOnly: true }}
                            textColor={"rgba(140, 155, 171, 1)"}
                            sxProps={{
                              color: !cardEdit
                                ? "rgba(255, 255, 255, 1)"
                                : "rgba(140, 155, 171, 1)",
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        direction={"row"}
                        className="justifyContentSpaceBtw"
                        alignItems={"center"}
                        rowSpacing={"16px"}
                        columnSpacing={"40px"}
                        sx={{ marginBottom: "20px" }}
                      >
                        <Grid item>
                          <MediumTypography
                            labelId="ScheduledStartTimeInput"
                            defaultLabel="Scheduled Start Time"
                            sxProps={{ marginBottom: "10px" }}
                            textColor={"rgba(159, 173, 188, 1)"}
                          />
                          <DateTimePickerComponent
                            value={dayjs(
                              values.workOrderGcInfo.scheduledStartDateTime?.toString()
                            )}
                            labelid="ScheduledStartTimeInput"
                            defaultlabelid="Scheduled Start Time"
                            handlechange={(date: Dayjs) => {
                              const formattedText = date.format(
                                "YYYY-MM-DDTHH:mm:ss"
                              );
                              setFieldValue(
                                "workOrderGcInfo.scheduledStartDateTime",
                                formattedText
                              );
                            }}
                            disabledDate={true}
                            inputFormat="DD MMM YYYY  HH:mm:ss"
                            width="250px"
                            textWidth="250px"
                          />
                        </Grid>
                        <Grid item>
                          <MediumTypography
                            labelId="ScheduledEndTimeTextInput"
                            defaultLabel="Scheduled End Time"
                            sxProps={{ marginBottom: "10px" }}
                            textColor={"rgba(159, 173, 188, 1)"}
                          />
                          <DateTimePickerComponent
                            value={dayjs(
                              values.workOrderGcInfo.scheduledEndDateTime?.toString()
                            )}
                            labelid="ScheduledEndTimeTextInput"
                            defaultlabelid="Scheduled End Time"
                            handlechange={(date: Dayjs) => {
                              const formattedText = date.format(
                                "YYYY-MM-DDTHH:mm:ss"
                              );
                              setFieldValue(
                                "workOrderGcInfo.scheduledEndDateTime",
                                formattedText
                              );
                            }}
                            disabledDate={true}
                            inputFormat="DD MMM YYYY  HH:mm:ss"
                            width="250px"
                            textWidth="250px"
                          />
                        </Grid>
                        <Grid item>
                          <MediumTypography
                            labelId="ActualStartTimeTextInput"
                            defaultLabel="Actual Start Time"
                            sxProps={{ marginBottom: "10px" }}
                            textColor={"rgba(159, 173, 188, 1)"}
                          />
                          <DateTimePickerComponent
                            value={
                              values.workOrderGcInfo.actualStartDateTime !==
                              null
                                ? dayjs(
                                    values.workOrderGcInfo.actualStartDateTime
                                  )
                                : null
                            }
                            labelid="ActualStartTimeTextInput"
                            defaultlabelid="Actual Start Time"
                            handlechange={(date: Dayjs) => {
                              const formattedText = date.format(
                                "YYYY-MM-DDTHH:mm:ss"
                              );
                              setFieldValue(
                                "workOrderGcInfo.actualStartDateTime",
                                formattedText
                              );
                            }}
                            disabledDate={!cardEdit}
                            inputFormat="DD MMM YYYY  HH:mm:ss"
                            width="250px"
                            textWidth="250px"
                            minDateTime={
                              values.workOrderGcInfo.scheduledStartDateTime
                                ? dayjs(
                                    values.workOrderGcInfo
                                      .scheduledStartDateTime
                                  )
                                : undefined
                            }
                          />
                        </Grid>
                        <Grid item>
                          <MediumTypography
                            labelId="ActualEndTimeTextInput"
                            defaultLabel="Actual End Time"
                            sxProps={{ marginBottom: "10px" }}
                            textColor={"rgba(159, 173, 188, 1)"}
                          />

                          <DateTimePickerComponent
                            value={
                              values.workOrderGcInfo.actualEndDateTime !== null
                                ? dayjs(
                                    values.workOrderGcInfo.actualEndDateTime
                                  )
                                : null
                            }
                            labelid="ActualEndTimeTextInput"
                            defaultlabelid="Actual End Time"
                            handlechange={(date: Dayjs) => {
                              if (
                                values.workOrderGcInfo.actualStartDateTime ===
                                date.format("YYYY-MM-DDTHH:mm:ss")
                              ) {
                                setError(
                                  intl.formatMessage({
                                    id: "Error.EndTimeGreaterThanStartTime",
                                    defaultMessage:
                                      "End time should be greater than start time",
                                  })
                                );
                              } else {
                                setError("");
                                setFieldValue(
                                  "workOrderGcInfo.actualEndDateTime",
                                  date.format("YYYY-MM-DDTHH:mm:ss")
                                );
                              }
                            }}
                            disabledDate={!cardEdit}
                            inputFormat="DD MMM YYYY  HH:mm:ss"
                            width="250px"
                            textWidth="250px"
                            minDateTime={
                              values.workOrderGcInfo.actualStartDateTime !==
                              null
                                ? dayjs(
                                    values.workOrderGcInfo.actualStartDateTime
                                  )
                                : undefined
                            }
                          />
                          {error && (
                            <Box
                              className="loginError"
                              sx={{ position: "relative" }}
                            >
                              <Box>
                                <ErrorIcon />
                              </Box>
                              <MediumTypography
                                label={error}
                                fontSize="12px"
                                textColor="#AE2E24"
                                fontWeight={400}
                              />
                            </Box>
                          )}
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        direction={"row"}
                        className="justifyContentSpaceBtw"
                        alignItems={"center"}
                        rowSpacing={"16px"}
                        columnSpacing={"40px"}
                      >
                        <Grid item>
                          <Box className="flex__">
                            <MediumTypography
                              labelId="gcPlanner.plannedWOTablesAreaLabelText"
                              defaultLabel="Planned Tables | Area (Acres)"
                              sxProps={{ marginBottom: "10px" }}
                              textColor={"rgba(159, 173, 188, 1)"}
                            />

                            <MediumTypography
                              labelId={
                                values.workOrderGcInfo.plannedArea > 0
                                  ? "ReassignText"
                                  : "SelectText"
                              }
                              defaultLabel={
                                values.workOrderGcInfo.plannedArea > 0
                                  ? "Reassign"
                                  : "Select"
                              }
                              fontSize="10px"
                              textColor="#25BBFA"
                              className="ml-sm mt-xs"
                              sxProps={{
                                textDecoration: "underline",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                hasPermission("Plan_MC_GC_WO") &&
                                  navigate(GRASS_CUTTING_PLANNER, {
                                    state: {
                                      cardId: navState.cardId,
                                      assetGroupId: navState.assetGroupId,
                                      idType: "workorder",
                                      date: navState.date,
                                      dateRange: navState.dateRange,
                                    },
                                  });
                              }}
                            />
                          </Box>

                          <TextInput
                            labelId="PlannedAreaOrTableLabelText"
                            defaultLabelId="Planned Tables | Area"
                            borderColor="1px solid rgba(166, 197, 226, 0.16)"
                            variant="outlined"
                            className="textInputWidth"
                            backGroundColor="#22272B"
                            Value={`${
                              values.workOrderGcInfo.plannedTables > 0
                                ? values.workOrderGcInfo.plannedTables
                                : "-"
                            } | ${
                              values.workOrderGcInfo.plannedArea > 0
                                ? getFormattedDisplayArea(
                                    values.workOrderGcInfo.plannedArea
                                  )
                                : "-"
                            }`}
                            fieldheight="40px"
                            inputProps={{ readOnly: true }}
                            textColor={
                              cardEdit
                                ? "rgba(255, 255, 255, 1)"
                                : "rgba(140, 155, 171, 1)"
                            }
                          />
                        </Grid>
                        <Grid item>
                          <MediumTypography
                            labelId="gcPlanner.actualWOTablesAreaLabelText"
                            defaultLabel="Actual Tables | Area (Acres)"
                            sxProps={{ marginBottom: "10px" }}
                            textColor={"rgba(159, 173, 188, 1)"}
                          />

                          <TextInput
                            labelId="ActualAreaOrTableLabelText"
                            defaultLabelId="Actual Tables | Area"
                            borderColor="1px solid rgba(166, 197, 226, 0.16)"
                            variant="outlined"
                            className="textInputWidth"
                            backGroundColor="#22272B"
                            Value={`${
                              values.workOrderGcInfo.actualTables !== 0 ||
                              values.workOrderGcInfo.rejectedTables !== 0
                                ? values.workOrderGcInfo.actualTables
                                : "-"
                            } | ${
                              values.workOrderGcInfo.actualArea !== 0 ||
                              values.workOrderGcInfo.rejectedTables !== 0
                                ? getFormattedDisplayArea(
                                    values.workOrderGcInfo.actualArea
                                  )
                                : "-"
                            }`}
                            fieldheight="40px"
                            inputProps={{ readOnly: true }}
                            textColor={
                              cardEdit
                                ? "rgba(255, 255, 255, 1)"
                                : "rgba(140, 155, 171, 1)"
                            }
                          />
                        </Grid>
                        <Grid item>
                          <MediumTypography
                            labelId="gcPlanner.rejectedWOTablesAreaLabelText"
                            defaultLabel="Rejected Tables | Area (Acres)"
                            sxProps={{ marginBottom: "10px" }}
                            textColor={"rgba(159, 173, 188, 1)"}
                          />

                          <TextInput
                            labelId="RejectedAreaOrTableLabelText"
                            defaultLabelId="Rejected Tables | Area"
                            borderColor="1px solid rgba(166, 197, 226, 0.16)"
                            variant="outlined"
                            Value={`${
                              values.workOrderGcInfo.rejectedTables > 0
                                ? values.workOrderGcInfo.rejectedTables
                                : "-"
                            } | ${
                              values.workOrderGcInfo.rejectedArea > 0
                                ? getFormattedDisplayArea(
                                    values.workOrderGcInfo.rejectedArea
                                  )
                                : "-"
                            }`}
                            inputProps={{ readOnly: true }}
                            backGroundColor="#22272B"
                            fieldheight="40px"
                            className="textInputWidth"
                            textColor={
                              cardEdit
                                ? "rgba(255, 255, 255, 1)"
                                : "rgba(140, 155, 171, 1)"
                            }
                          />
                        </Grid>
                        <Grid item xs={3} lg={3}>
                          <MediumTypography
                            labelId="ReasonForDeviation"
                            defaultLabel="Reason For Deviation"
                            sxProps={{ marginBottom: "10px" }}
                            textColor={"rgba(159, 173, 188, 1)"}
                          />
                          <DropdownComponent
                            names={convertToOptionType(
                              grassCuttingValues.reasonForDeviationList || []
                            )}
                            value={
                              values.grassCuttingInfoBean
                                .selectedReasonForDeviationId === 0
                                ? ""
                                : values.grassCuttingInfoBean.selectedReasonForDeviationId.toString()
                            }
                            handleChange={(value: string) => {
                              setFieldValue(
                                "grassCuttingInfoBean.selectedReasonForDeviationId",
                                parseInt(value)
                              );
                              setOthersReasonMessage("");
                              setFieldValue(
                                "grassCuttingInfoBean.otherReason",
                                ""
                              );
                            }}
                            disabled={!cardEdit}
                            labelid="ReasonForDeviationLabel"
                            defaultlabelid="Select Reason"
                            minWidth="250px"
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        direction={"row"}
                        className="justifyContentSpaceBtw"
                        alignItems={"center"}
                        rowSpacing={"16px"}
                        columnSpacing={"40px"}
                        sx={{ marginTop: "0px" }}
                      >
                        {values.grassCuttingInfoBean
                          .selectedReasonForDeviationId &&
                          values.grassCuttingInfoBean
                            .selectedReasonForDeviationId !== null && (
                            <Grid item>
                              <MediumTypography
                                labelId="MC.ImpactedHours"
                                defaultLabel="Impacted Hours"
                                sxProps={{ marginBottom: "10px" }}
                                textColor={"rgba(159, 173, 188, 1)"}
                              />
                              <Box
                                className="flex__"
                                sx={{
                                  position: "relative",
                                  alignItems: "center",
                                }}
                              >
                                <TextInput
                                  labelId="MC.ImpactedHours"
                                  className="textInputWidth"
                                  defaultLabelId="Impacted Hours"
                                  borderColor="1px solid rgba(166, 197, 226, 0.16)"
                                  Value={convertMinutesToHoursAndMinutes(
                                    values?.grassCuttingInfoBean?.impactedHours
                                  )}
                                  // Value={values.estimatedEffort.toString()}
                                  handlechange={(value: string) => {
                                    // setFieldValue("estimatedEffort", value);
                                    const minutes =
                                      convertHoursAndMinutesToMinutes(value);
                                    setFieldValue(
                                      "grassCuttingInfoBean.impactedHours",
                                      minutes
                                    );
                                  }}
                                  variant="outlined"
                                  backGroundColor="#22272B"
                                  fieldheight="40px"
                                  inputProps={{ readOnly: !cardEdit }}
                                  textColor={
                                    cardEdit
                                      ? "rgba(255, 255, 255, 1)"
                                      : "rgba(140, 155, 171, 1)"
                                  }
                                />
                                <Box
                                  sx={{
                                    padding: "4px 8px 4px 8px",
                                    backgroundColor: "#191E22",
                                    border: "1px solid #374048",
                                    height: "24px",
                                    borderRadius: "4px",
                                    right: "8px",
                                    position: "absolute",
                                  }}
                                >
                                  <MediumTypography
                                    // labelId="cm.minutes"
                                    // defaultLabel="Minutes"
                                    label="( hh:mm )"
                                    sxProps={{
                                      lineHeight: "normal",
                                      fontSize: "12px",
                                    }}
                                  />
                                </Box>
                              </Box>
                            </Grid>
                          )}
                        <Grid item xs={8} lg={8} md={8}>
                          {values.grassCuttingInfoBean
                            .selectedReasonForDeviationId ===
                            reasonOthersId && (
                            <Grid xs={11.5} lg={11.5} md={11.5}>
                              <MediumTypography
                                labelId="OthersText"
                                defaultLabel="Others"
                                textColor={"rgba(159, 173, 188, 1)"}
                                sxProps={{ marginBottom: "10px" }}
                              />
                              <TextField
                                variant="outlined"
                                sx={{
                                  backgroundColor: "#22272B",
                                  borderRadius: "4px",
                                  border: "1px solid rgba(166, 197, 226, 0.16)",
                                  "& .MuiOutlinedInput-root": {
                                    "& fieldset": {
                                      borderColor: "#1D2226",
                                    },

                                    "&:hover fieldset": {
                                      borderColor: "#1D2226",
                                    },
                                    "&.Mui-focused fieldset": {
                                      borderColor: "#1D2226",
                                    },
                                    "& input": {
                                      color: cardEdit
                                        ? "rgba(255, 255, 255, 1)"
                                        : "rgba(140, 155, 171, 1)", // Text color inside the input
                                    },
                                  },
                                  "& .MuiInputLabel-root": {
                                    color: "#bbb", // Placeholder color
                                  },
                                }}
                                minRows={5}
                                fullWidth
                                value={othersReasonMessage}
                                onChange={(event) => {
                                  setOthersReasonMessage(
                                    event.target.value.trimStart()
                                  );
                                  setFieldValue(
                                    "cleaningInfoBean.otherReason",
                                    event.target.value.trimStart()
                                  );
                                }}
                                inputProps={{ readOnly: !cardEdit }}
                              />
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    </Box>
                    <Grid container className="mt-xs">
                      <Grid item xs={12} lg={12} md={12} xl={12}>
                        <Box>
                          <Divider
                            variant="fullWidth"
                            sx={{
                              borderTop: "1px solid #333b43",
                            }}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                    <Box sx={{ padding: "24px 0px 24px 0px" }}>
                      <Box className="flex__" sx={{ marginBottom: "30px" }}>
                        <Box>
                          <MediumTypography
                            labelId="GrassCuttingInfo.Text"
                            defaultLabel="Grass Cutting Info"
                            fontSize="20px"
                            fontWeight={500}
                            textColor="#FFFFFF"
                          />
                        </Box>
                      </Box>
                      <Grid
                        container
                        direction={"row"}
                        className="justifyContentSpaceBtw"
                        // alignItems={"center"}
                        rowSpacing={"16px"}
                        columnSpacing={"40px"}
                        sx={{ marginBottom: "20px" }}
                      >
                        <Grid item>
                          <MediumTypography
                            labelId="GrassCuttingTypeLabelText"
                            defaultLabel="Grass Cutting Type"
                            sxProps={{ marginBottom: "10px" }}
                            textColor={"rgba(159, 173, 188, 1)"}
                          />
                          <TextInput
                            labelId="GrassCuttingTypeLabelText"
                            defaultLabelId="Grass Cutting Type"
                            borderColor="1px solid rgba(166, 197, 226, 0.16)"
                            variant="outlined"
                            Value={
                              values.grassCuttingInfoBean
                                .selectedGrassCuttingTypeName
                            }
                            type="text"
                            inputProps={{ readOnly: true }}
                            backGroundColor="#22272B"
                            fieldheight="40px"
                            className="textInputWidth"
                            textColor={"rgba(140, 155, 171, 1)"}
                          />
                        </Grid>
                        <Grid item>
                          <MediumTypography
                            labelId="GrassCuttingMethodLabelText"
                            defaultLabel="Grass Cutting Method"
                            sxProps={{ marginBottom: "10px" }}
                            textColor={"rgba(159, 173, 188, 1)"}
                          />
                          <DropdownComponent
                            names={convertToOptionType(
                              grassCuttingValues.gcMethod || []
                            )}
                            labelid="GrassCuttingMethodLabel"
                            defaultlabelid="Select Method"
                            value={
                              values.grassCuttingInfoBean.selectedGCMethodId ===
                              0
                                ? ""
                                : values.grassCuttingInfoBean.selectedGCMethodId.toString()
                            }
                            disabled={true}
                            handleChange={(value: string) => {
                              setFieldValue(
                                "grassCuttingInfoBean.selectedGCMethodId",
                                value
                              );
                            }}
                            className="textInputWidth"
                          />
                          {errors.grassCuttingInfoBean?.selectedGCMethodId &&
                            touched.grassCuttingInfoBean
                              ?.selectedGCMethodId && (
                              <Box
                                className="loginError"
                                sx={{ position: "relative" }}
                              >
                                <Box>
                                  <ErrorIcon />
                                </Box>
                                <MediumTypography
                                  labelId={
                                    errors.grassCuttingInfoBean
                                      ?.selectedGCMethodId
                                  }
                                  defaultLabel="Grass cutting method is required"
                                  fontSize="12px"
                                  textColor="#AE2E24"
                                  fontWeight={400}
                                />
                              </Box>
                            )}
                        </Grid>

                        <Grid item>
                          <MediumTypography
                            labelId="CoverageTypeLabelText"
                            defaultLabel="Coverage Type"
                            sxProps={{ marginBottom: "10px" }}
                            textColor={"rgba(159, 173, 188, 1)"}
                          />
                          <TextInput
                            labelId="CoverageTypeLabelText"
                            defaultLabelId="Coverage Type"
                            borderColor="1px solid rgba(166, 197, 226, 0.16)"
                            variant="outlined"
                            Value={getCovergaeType(
                              values?.grassCuttingInfoBean
                                ?.selectedGCCoverageTypeIds[0] ?? 0,
                              values?.gcCoverageType ?? []
                            )}
                            type="text"
                            inputProps={{ readOnly: true }}
                            backGroundColor="#22272B"
                            fieldheight="40px"
                            className="textInputWidth"
                            textColor={"rgba(140, 155, 171, 1)"}
                          />
                        </Grid>

                        <Grid item>
                          <MediumTypography
                            labelId="NumberOfLaborsLabelText"
                            defaultLabel="Number of Labors"
                            sxProps={{ marginBottom: "10px" }}
                            textColor={"rgba(159, 173, 188, 1)"}
                          />
                          <TextInput
                            labelId="NumberOfLaborsLabelText"
                            defaultLabelId="Number of Labors"
                            borderColor="1px solid rgba(166, 197, 226, 0.16)"
                            variant="outlined"
                            Value={
                              values.grassCuttingInfoBean.laboursAllocated !==
                                null &&
                              values.grassCuttingInfoBean.laboursAllocated !== 0
                                ? values.grassCuttingInfoBean.laboursAllocated.toString()
                                : ""
                            }
                            type="text"
                            handlechange={(value: string) => {
                              const onlyNums = value.replace(/[^0-9]/g, ""); // Remove any non-digit characters
                              const maxTenChars = onlyNums.substring(0, 10);
                              setFieldValue(
                                "grassCuttingInfoBean.laboursAllocated",
                                maxTenChars
                              );
                            }}
                            textColor={
                              cardEdit
                                ? "rgba(255, 255, 255, 1)"
                                : "rgba(140, 155, 171, 1)"
                            }
                            inputProps={{
                              maxLength: 10,
                              readOnly: !cardEdit,
                            }}
                            backGroundColor="#22272B"
                            fieldheight="40px"
                            className="textInputWidth"
                          />
                        </Grid>
                        {values.grassCuttingInfoBean
                          .selectedGrassCuttingTypeName &&
                          values.grassCuttingInfoBean
                            .selectedGrassCuttingTypeName === "Equipment" && (
                            <>
                              <Grid item>
                                <MediumTypography
                                  labelId="NumberOfMachinesLabelText"
                                  defaultLabel="Number of Machines"
                                  sxProps={{ marginBottom: "10px" }}
                                  textColor={"rgba(159, 173, 188, 1)"}
                                />
                                <TextInput
                                  labelId="NumberOfMachinesLabelText"
                                  defaultLabelId="Number of Machines"
                                  borderColor="1px solid rgba(166, 197, 226, 0.16)"
                                  variant="outlined"
                                  backGroundColor="#22272B"
                                  Value={
                                    values.grassCuttingInfoBean
                                      .machineAllocated !== null &&
                                    values.grassCuttingInfoBean
                                      .machineAllocated !== 0
                                      ? String(
                                          values.grassCuttingInfoBean
                                            .machineAllocated
                                        )
                                      : ""
                                  }
                                  handlechange={(value: string) => {
                                    const onlyNums = value.replace(
                                      /[^0-9]/g,
                                      ""
                                    ); // Remove any non-digit characters
                                    const maxTenChars = onlyNums.substring(
                                      0,
                                      10
                                    );
                                    setFieldValue(
                                      "grassCuttingInfoBean.machineAllocated",
                                      maxTenChars
                                    );
                                  }}
                                  inputProps={{ readOnly: !cardEdit }}
                                  textColor={
                                    cardEdit
                                      ? "rgba(255, 255, 255, 1)"
                                      : "rgba(140, 155, 171, 1)"
                                  }
                                  fieldheight="40px"
                                  className="textInputWidth"
                                />
                              </Grid>
                              <Grid item>
                                <MediumTypography
                                  labelId="FuelConsumedText"
                                  defaultLabel="Fuel Consumed (Liters)"
                                  sxProps={{ marginBottom: "10px" }}
                                  textColor={"rgba(159, 173, 188, 1)"}
                                />
                                <TextInput
                                  labelId="FuelConsumedText"
                                  defaultLabelId="Fuel Consumed (Liters)"
                                  borderColor="1px solid rgba(166, 197, 226, 0.16)"
                                  variant="outlined"
                                  Value={
                                    values.grassCuttingInfoBean
                                      .fuelConsumption !== null &&
                                    values.grassCuttingInfoBean
                                      .fuelConsumption !== 0
                                      ? String(
                                          values.grassCuttingInfoBean
                                            .fuelConsumption
                                        )
                                      : ""
                                  }
                                  inputProps={{ readOnly: !cardEdit }}
                                  handlechange={(value: string) => {
                                    let formattedValue = value.replace(
                                      /[^0-9.]/g,
                                      ""
                                    );

                                    // Then, format the string to match the pattern "up to 4 digits, optional decimal, up to 2 digits after the decimal"
                                    formattedValue = formattedValue.replace(
                                      /^(\d{0,4})(\.\d{0,2})?.*$/,
                                      (match, p1, p2) => p1 + (p2 || "") // p1 is the first group (digits), p2 is the second group (decimal and digits)
                                    );
                                    setFieldValue(
                                      "grassCuttingInfoBean.fuelConsumption",
                                      formattedValue
                                    );
                                  }}
                                  textColor={
                                    cardEdit
                                      ? "rgba(255, 255, 255, 1)"
                                      : "rgba(140, 155, 171, 1)"
                                  }
                                  backGroundColor="#22272B"
                                  fieldheight="40px"
                                  className="textInputWidth"
                                />
                              </Grid>
                              <Grid item>
                                <MediumTypography
                                  labelId="FuelUnitPriceLabel"
                                  defaultLabel="Fuel Unit Price"
                                  sxProps={{ marginBottom: "10px" }}
                                  textColor={"rgba(159, 173, 188, 1)"}
                                />
                                <TextInput
                                  labelId="FuelUnitPriceLabel"
                                  defaultLabelId="Fuel Unit Price"
                                  borderColor="1px solid rgba(166, 197, 226, 0.16)"
                                  variant="outlined"
                                  Value={
                                    values.grassCuttingInfoBean
                                      .fuelUnitPrice !== null &&
                                    values.grassCuttingInfoBean
                                      .fuelUnitPrice !== 0
                                      ? String(
                                          values.grassCuttingInfoBean
                                            .fuelUnitPrice
                                        )
                                      : ""
                                  }
                                  inputProps={{ readOnly: !cardEdit }}
                                  textColor={
                                    cardEdit
                                      ? "rgba(255, 255, 255, 1)"
                                      : "rgba(140, 155, 171, 1)"
                                  }
                                  handlechange={(value: string) => {
                                    let formattedValue = value.replace(
                                      /[^0-9.]/g,
                                      ""
                                    );

                                    // Then, format the string to match the pattern "up to 4 digits, optional decimal, up to 2 digits after the decimal"
                                    formattedValue = formattedValue.replace(
                                      /^(\d{0,4})(\.\d{0,2})?.*$/,
                                      (match, p1, p2) => p1 + (p2 || "") // p1 is the first group (digits), p2 is the second group (decimal and digits)
                                    );
                                    setFieldValue(
                                      "grassCuttingInfoBean.fuelUnitPrice",
                                      formattedValue
                                    );
                                  }}
                                  backGroundColor="#22272B"
                                  fieldheight="40px"
                                  className="textInputWidth"
                                />
                              </Grid>
                            </>
                          )}
                        {values.grassCuttingInfoBean
                          .selectedGrassCuttingTypeName &&
                          values.grassCuttingInfoBean
                            .selectedGrassCuttingTypeName === "Herbicide" && (
                            <>
                              <Grid item>
                                <MediumTypography
                                  labelId="HerbicideTanksLabelText"
                                  defaultLabel="No of Herbicide Tanks"
                                  sxProps={{ marginBottom: "10px" }}
                                  textColor={"rgba(159, 173, 188, 1)"}
                                />
                                <TextInput
                                  labelId="HerbicideTanksLabelText"
                                  defaultLabelId="No of Herbicide Tanks"
                                  borderColor="1px solid rgba(166, 197, 226, 0.16)"
                                  variant="outlined"
                                  Value={
                                    values.grassCuttingInfoBean
                                      .herbicideTanks !== null &&
                                    values.grassCuttingInfoBean
                                      .herbicideTanks !== 0
                                      ? String(
                                          values.grassCuttingInfoBean
                                            .herbicideTanks
                                        )
                                      : ""
                                  }
                                  inputProps={{ readOnly: !cardEdit }}
                                  handlechange={(value: string) => {
                                    const onlyNums = value.replace(
                                      /[^0-9]/g,
                                      ""
                                    ); // Remove any non-digit characters
                                    const maxTenChars = onlyNums.substring(
                                      0,
                                      10
                                    );
                                    setFieldValue(
                                      "grassCuttingInfoBean.herbicideTanks",
                                      maxTenChars
                                    );
                                  }}
                                  textColor={
                                    cardEdit
                                      ? "rgba(255, 255, 255, 1)"
                                      : "rgba(140, 155, 171, 1)"
                                  }
                                  backGroundColor="#22272B"
                                  fieldheight="40px"
                                  className="textInputWidth"
                                />
                              </Grid>
                              <Grid item>
                                <MediumTypography
                                  labelId="HerbicideConsumptionLabel"
                                  defaultLabel="Herbicide Consumption (Liters)"
                                  sxProps={{ marginBottom: "10px" }}
                                  textColor={"rgba(159, 173, 188, 1)"}
                                />
                                <TextInput
                                  labelId="HerbicideConsumptionLabel"
                                  defaultLabelId="Herbicide Consumption (Liters)"
                                  borderColor="1px solid rgba(166, 197, 226, 0.16)"
                                  variant="outlined"
                                  Value={
                                    values.grassCuttingInfoBean
                                      .herbicideConsumption !== null &&
                                    values.grassCuttingInfoBean
                                      .herbicideConsumption !== 0
                                      ? String(
                                          values.grassCuttingInfoBean
                                            .herbicideConsumption
                                        )
                                      : ""
                                  }
                                  inputProps={{ readOnly: !cardEdit }}
                                  textColor={
                                    cardEdit
                                      ? "rgba(255, 255, 255, 1)"
                                      : "rgba(140, 155, 171, 1)"
                                  }
                                  handlechange={(value: string) => {
                                    let formattedValue = value.replace(
                                      /[^0-9.]/g,
                                      ""
                                    );

                                    // Then, format the string to match the pattern "up to 4 digits, optional decimal, up to 2 digits after the decimal"
                                    formattedValue = formattedValue.replace(
                                      /^(\d{0,4})(\.\d{0,2})?.*$/,
                                      (match, p1, p2) => p1 + (p2 || "") // p1 is the first group (digits), p2 is the second group (decimal and digits)
                                    );
                                    setFieldValue(
                                      "grassCuttingInfoBean.herbicideConsumption",
                                      formattedValue
                                    );
                                  }}
                                  backGroundColor="#22272B"
                                  fieldheight="40px"
                                  className="textInputWidth"
                                />
                              </Grid>
                            </>
                          )}
                        <Grid item>
                          <MediumTypography
                            labelId="NumberOfWorkingHoursLabelText"
                            defaultLabel="No.of Working Hours"
                            sxProps={{ marginBottom: "10px" }}
                            textColor={"rgba(159, 173, 188, 1)"}
                          />
                          <Box
                            className="flex__"
                            sx={{
                              position: "relative",
                              alignItems: "center",
                            }}
                          >
                            <TextInput
                              labelId="NumberOfWorkingHoursLabelText"
                              defaultLabelId="No.of Working Hours"
                              borderColor="1px solid rgba(166, 197, 226, 0.16)"
                              variant="outlined"
                              Value={convertMinutesToHoursAndMinutes(
                                values.grassCuttingInfoBean
                                  ?.numberOfWorkingHours
                              )}
                              type="text"
                              handlechange={(value: string) => {
                                const minutes =
                                  convertHoursAndMinutesToMinutes(value);
                                setFieldValue(
                                  "grassCuttingInfoBean.numberOfWorkingHours",
                                  minutes
                                );
                              }}
                              textColor={
                                cardEdit
                                  ? "rgba(255, 255, 255, 1)"
                                  : "rgba(140, 155, 171, 1)"
                              }
                              inputProps={{
                                maxLength: 10,
                                readOnly: !cardEdit,
                              }}
                              backGroundColor="#22272B"
                              fieldheight="40px"
                              className="textInputWidth"
                            />

                            <Box
                              sx={{
                                padding: "4px 8px 4px 8px",
                                backgroundColor: "#191E22",
                                border: "1px solid #374048",
                                height: "24px",
                                borderRadius: "4px",
                                right: "8px",
                                position: "absolute",
                              }}
                            >
                              <MediumTypography
                                label="( hh:mm )"
                                sxProps={{
                                  lineHeight: "normal",
                                  fontSize: "12px",
                                }}
                              />
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        direction={"row"}
                        className="justifyContentSpaceBtw"
                        alignItems={"center"}
                        rowSpacing={"16px"}
                        columnSpacing={"40px"}
                        sx={{ marginBottom: "20px" }}
                      ></Grid>

                      <Grid container sx={{ marginBottom: "20px" }}>
                        <Grid xs={11.5} lg={11.5} md={11.5}>
                          <MediumTypography
                            labelId="Revoke.RemarksText"
                            defaultLabel="Remarks"
                            sxProps={{ marginBottom: "10px" }}
                            textColor={"rgba(159, 173, 188, 1)"}
                          />
                          <TextField
                            variant="outlined"
                            sx={{
                              backgroundColor: "#22272B",
                              borderRadius: "4px",
                              border: "1px solid rgba(166, 197, 226, 0.16)",
                              "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                  borderColor: "#1D2226",
                                },

                                "&:hover fieldset": {
                                  borderColor: "#1D2226",
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: "#1D2226",
                                },
                                "& input": {
                                  color: cardEdit
                                    ? "rgba(255, 255, 255, 1)"
                                    : "rgba(140, 155, 171, 1)", // Text color inside the input
                                },
                              },
                              "& .MuiInputLabel-root": {
                                color: "#bbb", // Placeholder color
                              },
                            }}
                            minRows={10}
                            fullWidth
                            value={message}
                            onChange={(event) => {
                              setMessage(event.target.value.trimStart());
                              setFieldValue(
                                "grassCuttingInfoBean.remarks",
                                event.target.value.trimStart()
                              );
                            }}
                            inputProps={{ readOnly: !cardEdit }}
                          />
                        </Grid>
                        <Grid
                          xs={11.5}
                          lg={11.5}
                          md={11.5}
                          sx={{ marginTop: "10px" }}
                        >
                          <MediumTypography
                            labelId="GC.DumpingInfo"
                            defaultLabel="Dumping Info"
                            sxProps={{ marginBottom: "10px" }}
                            textColor={"rgba(159, 173, 188, 1)"}
                          />
                          <TextField
                            variant="outlined"
                            sx={{
                              backgroundColor: "#22272B",
                              borderRadius: "4px",
                              border: "1px solid rgba(166, 197, 226, 0.16)",
                              "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                  borderColor: "#1D2226",
                                },

                                "&:hover fieldset": {
                                  borderColor: "#1D2226",
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: "#1D2226",
                                },
                                "& input": {
                                  color: cardEdit
                                    ? "rgba(255, 255, 255, 1)"
                                    : "rgba(140, 155, 171, 1)", // Text color inside the input
                                },
                              },
                              "& .MuiInputLabel-root": {
                                color: "#bbb", // Placeholder color
                              },
                            }}
                            minRows={10}
                            fullWidth
                            value={dumpingInfo}
                            onChange={(event) => {
                              setDumpingInfo(event.target.value.trimStart());
                              setFieldValue(
                                "grassCuttingInfoBean.dumpingInfo",
                                event.target.value.trimStart()
                              );
                            }}
                            inputProps={{ readOnly: !cardEdit }}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                    <Grid container className="mt-xs">
                      <Grid item xs={12} lg={12} md={12} xl={12}>
                        <Box>
                          <Divider
                            variant="fullWidth"
                            sx={{
                              borderTop: "1px solid #333b43",
                            }}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                    <Box sx={{ padding: "24px 0px 24px 0px" }}>
                      <Box sx={{ marginBottom: "20px" }}>
                        <Box>
                          <MediumTypography
                            labelId="AdditionalInfoLaabelText"
                            defaultLabel="Additional Info"
                            fontSize="20px"
                            fontWeight={500}
                            textColor="#FFFFFF"
                          />
                        </Box>
                      </Box>
                      <Grid
                        container
                        direction={"row"}
                        className="justifyContentSpaceBtw"
                        // alignItems={"center"}
                        rowSpacing={"16px"}
                        columnSpacing={"40px"}
                        sx={{ marginBottom: "20px" }}
                      >
                        <Grid item>
                          <MediumTypography
                            labelId="CycleCountIncrementLabelText"
                            defaultLabel="Cycle Count Increment"
                            sxProps={{ marginBottom: "10px" }}
                            textColor={"rgba(159, 173, 188, 1)"}
                          />
                          <TextInput
                            labelId="CycleCountIncrementLabelText"
                            defaultLabelId="Cycle Count Increment"
                            borderColor="1px solid rgba(166, 197, 226, 0.16)"
                            variant="outlined"
                            Value={
                              values.additionalInfoBean.cycleCountInc !==
                                null &&
                              values.additionalInfoBean.cycleCountInc !== 0
                                ? values.additionalInfoBean.cycleCountInc.toString()
                                : ""
                            }
                            inputProps={{ readOnly: true }}
                            handlechange={(value: string) => {
                              let formattedValue = value.replace(
                                /[^0-9.]/g,
                                ""
                              );

                              // Then, format the string to match the pattern "up to 4 digits, optional decimal, up to 2 digits after the decimal"
                              formattedValue = formattedValue.replace(
                                /^(\d{0,4})(\.\d{0,2})?.*$/,
                                (match, p1, p2) => p1 + (p2 || "") // p1 is the first group (digits), p2 is the second group (decimal and digits)
                              );
                              setFieldValue(
                                "additionalInfoBean.cycleCountInc",
                                formattedValue
                              );
                            }}
                            textColor={"rgba(140, 155, 171, 1)"}
                            type="text"
                            backGroundColor="#22272B"
                            fieldheight="40px"
                            className="textInputWidth"
                          />
                        </Grid>
                        <Grid item>
                          <MediumTypography
                            labelId="CumulativeCycleCountLabelText"
                            defaultLabel="Cumulative Cycle Count"
                            sxProps={{ marginBottom: "10px" }}
                            textColor={"rgba(159, 173, 188, 1)"}
                          />
                          <TextInput
                            labelId="CumulativeCycleCountLabelText"
                            defaultLabelId="Cumulative Cycle Count"
                            borderColor="1px solid rgba(166, 197, 226, 0.16)"
                            variant="outlined"
                            Value={
                              values.additionalInfoBean.cumulativeCycleCount !==
                                null &&
                              values.additionalInfoBean.cumulativeCycleCount !==
                                0
                                ? values.additionalInfoBean.cumulativeCycleCount.toString()
                                : ""
                            }
                            inputProps={{ readOnly: true }}
                            handlechange={(value: string) => {
                              let formattedValue = value.replace(
                                /[^0-9.]/g,
                                ""
                              );

                              // Then, format the string to match the pattern "up to 4 digits, optional decimal, up to 2 digits after the decimal"
                              formattedValue = formattedValue.replace(
                                /^(\d{0,4})(\.\d{0,2})?.*$/,
                                (match, p1, p2) => p1 + (p2 || "") // p1 is the first group (digits), p2 is the second group (decimal and digits)
                              );
                              setFieldValue(
                                "additionalInfoBean.cumulativeCycleCount",
                                formattedValue
                              );
                            }}
                            textColor={"rgba(140, 155, 171, 1)"}
                            type={"text"}
                            backGroundColor="#22272B"
                            fieldheight="40px"
                            className="textInputWidth"
                          />
                        </Grid>

                        <Grid item>
                          <MediumTypography
                            labelId="RainFallLabelText"
                            defaultLabel="Rainfall (mm)"
                            sxProps={{ marginBottom: "10px" }}
                            textColor={"rgba(159, 173, 188, 1)"}
                          />
                          <TextInput
                            labelId="RainFallLabelText"
                            defaultLabelId="Rainfall"
                            Value={
                              values.additionalInfoBean.rainFall !== null &&
                              values.additionalInfoBean.rainFall !== 0
                                ? values.additionalInfoBean.rainFall.toString()
                                : ""
                            }
                            inputProps={{ readOnly: !cardEdit }}
                            handlechange={(value: string) => {
                              let formattedValue = value.replace(
                                /[^0-9.]/g,
                                ""
                              );

                              // Then, format the string to match the pattern "up to 4 digits, optional decimal, up to 2 digits after the decimal"
                              formattedValue = formattedValue.replace(
                                /^(\d{0,4})(\.\d{0,2})?.*$/,
                                (match, p1, p2) => p1 + (p2 || "") // p1 is the first group (digits), p2 is the second group (decimal and digits)
                              );
                              setFieldValue(
                                "additionalInfoBean.rainFall",
                                parseInt(formattedValue)
                              );
                            }}
                            textColor={
                              cardEdit
                                ? "rgba(255, 255, 255, 1)"
                                : "rgba(140, 155, 171, 1)"
                            }
                            borderColor="1px solid rgba(166, 197, 226, 0.16)"
                            variant="outlined"
                            type={"text"}
                            backGroundColor="#22272B"
                            fieldheight="40px"
                            className="textInputWidth"
                          />
                        </Grid>
                        <Grid item>
                          <MediumTypography
                            labelId="VendorLabelText"
                            defaultLabel="Vendor"
                            sxProps={{ marginBottom: "10px" }}
                            textColor={"rgba(159, 173, 188, 1)"}
                          />
                          <DropdownComponent
                            names={convertVendorToOptionType(
                              grassCuttingValues.vendorList || []
                            )}
                            value={
                              values.additionalInfoBean.vendorId === 0
                                ? ""
                                : values.additionalInfoBean.vendorId.toString()
                            }
                            handleChange={(value: string) => {
                              setFieldValue(
                                "additionalInfoBean.vendorId",
                                value
                              );
                            }}
                            disabled={!cardEdit}
                            labelid="VendorLabel"
                            defaultlabelid="Select Vendor"
                            minWidth="250px"
                          />
                          {errors.additionalInfoBean?.vendorId &&
                            touched.additionalInfoBean?.vendorId && (
                              <Box
                                className="loginError"
                                sx={{ position: "relative" }}
                              >
                                <Box>
                                  <ErrorIcon />
                                </Box>
                                <MediumTypography
                                  labelId={errors.additionalInfoBean?.vendorId}
                                  defaultLabel="Vendor is required"
                                  fontSize="12px"
                                  textColor="#AE2E24"
                                  fontWeight={400}
                                />
                              </Box>
                            )}
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        direction={"row"}
                        className="justifyContentSpaceBtw"
                        alignItems={"center"}
                        rowSpacing={"16px"}
                        columnSpacing={"40px"}
                        sx={{ marginBottom: "20px" }}
                      >
                        <Grid item>
                          <MediumTypography
                            labelId="ContractLineItemLabelText"
                            defaultLabel="Contract Line Item"
                            sxProps={{ marginBottom: "10px" }}
                            textColor={"rgba(159, 173, 188, 1)"}
                          />
                          <TextInput
                            labelId="ContractLineItemLabelText"
                            defaultLabelId="Contract Line Item"
                            borderColor="1px solid rgba(166, 197, 226, 0.16)"
                            variant="outlined"
                            Value={
                              values.additionalInfoBean.contractLineItem !==
                                null &&
                              values.additionalInfoBean.contractLineItem !==
                                undefined
                                ? values.additionalInfoBean.contractLineItem.toString()
                                : ""
                            }
                            inputProps={{ readOnly: !cardEdit }}
                            handlechange={(value: string) => {
                              setFieldValue(
                                "additionalInfoBean.contractLineItem",
                                value
                              );
                            }}
                            textColor={
                              cardEdit
                                ? "rgba(255, 255, 255, 1)"
                                : "rgba(140, 155, 171, 1)"
                            }
                            type={"text"}
                            backGroundColor="#22272B"
                            fieldheight="40px"
                            className="textInputWidth"
                          />
                        </Grid>
                      </Grid>
                      <Grid container className="mt-xs">
                        <Grid item xs={12} lg={12} md={12} xl={12}>
                          <Box>
                            <Divider
                              variant="fullWidth"
                              sx={{
                                borderTop: "1px solid #333b43",
                              }}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                      <WorkOrderAssignees
                        leadAssignee={leadAssignee}
                        assignees={assignees}
                      />

                      <Grid container className="mt-xs">
                        <Grid item xs={12} lg={12} md={12} xl={12}>
                          <Box>
                            <Divider
                              variant="fullWidth"
                              sx={{
                                borderTop: "1px solid #333b43",
                              }}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                      <Box className="mt-md mb-lg ">
                        <MediumTypography
                          labelId="ApproverText"
                          defaultLabel="Approver"
                          fontSize="20px"
                          fontWeight={500}
                          textColor="#FFFFFF"
                          className="mb-md"
                        />
                        <Box className="flex__">
                          {approver1 !== undefined && (
                            <Box
                              className="flex__"
                              sx={{
                                width: "146px",
                                height: "56px",
                                borderRadius: "4px",
                                border: "1px solid rgba(166, 197, 226, 0.16)",
                                backgroundColor: "#22272B",
                                marginRight: "16px",
                              }}
                            >
                              <>
                                {approver1 !== undefined &&
                                  approver1 !== null && (
                                    <React.Fragment>
                                      <Box
                                        className="flex__ align__items__center ml-sm"
                                        sx={{ position: "relative" }}
                                      >
                                        <Avatar
                                          src={approver1.image}
                                          alt="abc"
                                          style={{
                                            width: "32px",
                                            height: "32px",
                                          }}
                                        />
                                      </Box>
                                      <Box className="pt-sm">
                                        <Box className="flex__justify__space-between">
                                          <Box
                                            sx={{
                                              width: "100px",
                                              marginTop: "4px",
                                            }}
                                          >
                                            <Tooltip
                                              title={
                                                approver1.name &&
                                                approver1.name.length > 12
                                                  ? approver1.name
                                                  : ""
                                              }
                                              arrow={true}
                                              followCursor
                                            >
                                              <Box>
                                                <MediumTypography
                                                  label={approver1.name}
                                                  className="ml-sm ellipsis"
                                                  fontSize="12px"
                                                  textColor="#FFFFFF"
                                                  sxProps={{
                                                    width:
                                                      approver1.name.length > 12
                                                        ? "100px"
                                                        : "auto",
                                                  }}
                                                />
                                              </Box>
                                            </Tooltip>
                                            <Tooltip
                                              title={
                                                approver1.roleName &&
                                                approver1.roleName.length > 12
                                                  ? approver1.roleName
                                                  : ""
                                              }
                                              arrow={true}
                                              followCursor
                                            >
                                              <Box>
                                                <MediumTypography
                                                  label={approver1.roleName}
                                                  className=" ml-sm ellipsis"
                                                  textColor="rgba(255, 255, 255, 0.64)"
                                                  fontSize="10px"
                                                  fontWeight={400}
                                                  sxProps={{
                                                    width:
                                                      approver1.roleName
                                                        .length > 12
                                                        ? "100px"
                                                        : "auto",
                                                    marginTop:
                                                      approver1.name === ""
                                                        ? "8px"
                                                        : "0px",
                                                  }}
                                                />
                                              </Box>
                                            </Tooltip>
                                          </Box>
                                        </Box>
                                      </Box>
                                    </React.Fragment>
                                  )}
                                {approver1 !== undefined &&
                                  approver1 === null && (
                                    <React.Fragment>
                                      <Box
                                        className="flex__ align__items__center ml-sm"
                                        sx={{ position: "relative" }}
                                      >
                                        <Avatar
                                          src={""}
                                          alt="No approver"
                                          style={{
                                            width: "32px",
                                            height: "32px",
                                          }}
                                        />
                                      </Box>
                                      <Box className=" pt-sm">
                                        <Box className="flex__justify__space-between">
                                          <Box>
                                            <MediumTypography
                                              label={""}
                                              className="ml-xs ellipsis"
                                              fontSize="12px"
                                              textColor="#FFFFFF"
                                            />
                                            <MediumTypography
                                              label={""}
                                              className=" ml-xs ellipsis"
                                              textColor="rgba(255, 255, 255, 0.64)"
                                              fontSize="10px"
                                              fontWeight={400}
                                            />
                                          </Box>
                                        </Box>
                                      </Box>
                                    </React.Fragment>
                                  )}
                              </>
                            </Box>
                          )}

                          {approver2 !== undefined && (
                            <Box
                              className="flex__"
                              sx={{
                                width: "146px",
                                height: "56px",
                                borderRadius: "4px",
                                border: "1px solid rgba(166, 197, 226, 0.16)",
                                backgroundColor: "#22272B",
                                marginRight: "16px",
                              }}
                            >
                              {approver2 !== undefined &&
                                approver2 !== null && (
                                  <React.Fragment>
                                    <Box
                                      className="flex__ align__items__center ml-sm"
                                      sx={{ position: "relative" }}
                                    >
                                      <Avatar
                                        src={approver2.image}
                                        alt="abc"
                                        style={{
                                          width: "32px",
                                          height: "32px",
                                        }}
                                      />
                                    </Box>
                                    <Box className="pt-sm">
                                      <Box className="flex__justify__space-between">
                                        <Box
                                          sx={{
                                            width: "100px",
                                            marginTop: "4px",
                                          }}
                                        >
                                          <Tooltip
                                            title={
                                              approver2.name &&
                                              approver2.name.length > 12
                                                ? approver2.name
                                                : ""
                                            }
                                            arrow={true}
                                            followCursor
                                          >
                                            <Box>
                                              <MediumTypography
                                                label={approver2.name}
                                                className="ml-sm ellipsis"
                                                fontSize="12px"
                                                textColor="#FFFFFF"
                                                sxProps={{
                                                  width:
                                                    approver2.name.length > 12
                                                      ? "100px"
                                                      : "auto",
                                                }}
                                              />
                                            </Box>
                                          </Tooltip>
                                          <Tooltip
                                            title={
                                              approver2.roleName &&
                                              approver2.roleName.length > 12
                                                ? approver2.roleName
                                                : ""
                                            }
                                            arrow={true}
                                            followCursor
                                          >
                                            <Box>
                                              <MediumTypography
                                                label={approver2.roleName}
                                                className=" ml-sm ellipsis"
                                                textColor="rgba(255, 255, 255, 0.64)"
                                                fontSize="10px"
                                                fontWeight={400}
                                                sxProps={{
                                                  width:
                                                    approver2.roleName.length >
                                                    12
                                                      ? "100px"
                                                      : "auto",
                                                  marginTop:
                                                    approver2.name === ""
                                                      ? "8px"
                                                      : "0px",
                                                }}
                                              />
                                            </Box>
                                          </Tooltip>
                                        </Box>
                                      </Box>
                                    </Box>
                                  </React.Fragment>
                                )}
                              {approver2 !== undefined &&
                                approver2 === null && (
                                  <React.Fragment>
                                    <Box
                                      className="flex__ align__items__center ml-sm"
                                      sx={{ position: "relative" }}
                                    >
                                      <Avatar
                                        src={""}
                                        alt="No approver"
                                        style={{
                                          width: "32px",
                                          height: "32px",
                                        }}
                                      />
                                    </Box>
                                    <Box className=" pt-sm">
                                      <Box className="flex__justify__space-between">
                                        <Box>
                                          <MediumTypography
                                            label={""}
                                            className="ml-xs ellipsis"
                                            fontSize="12px"
                                            textColor="#FFFFFF"
                                          />
                                          <MediumTypography
                                            label={""}
                                            className=" ml-xs ellipsis"
                                            textColor="rgba(255, 255, 255, 0.64)"
                                            fontSize="10px"
                                            fontWeight={400}
                                          />
                                        </Box>
                                      </Box>
                                    </Box>
                                  </React.Fragment>
                                )}
                            </Box>
                          )}

                          {approver3 !== undefined && (
                            <Box
                              className="flex__"
                              sx={{
                                width: "146px",
                                height: "56px",
                                borderRadius: "4px",
                                border: "1px solid rgba(166, 197, 226, 0.16)",
                                backgroundColor: "#22272B",
                                marginRight: "16px",
                              }}
                            >
                              {approver3 !== undefined &&
                                approver3 !== null && (
                                  <React.Fragment>
                                    <Box
                                      className="flex__ align__items__center ml-sm"
                                      sx={{ position: "relative" }}
                                    >
                                      <Avatar
                                        src={approver3.image}
                                        alt="abc"
                                        style={{
                                          width: "32px",
                                          height: "32px",
                                        }}
                                      />
                                    </Box>
                                    <Box className="pt-sm">
                                      <Box className="flex__justify__space-between">
                                        <Box
                                          sx={{
                                            width: "100px",
                                            marginTop: "4px",
                                          }}
                                        >
                                          <Tooltip
                                            title={
                                              approver3.name &&
                                              approver3.name.length > 12
                                                ? approver3.name
                                                : ""
                                            }
                                            arrow={true}
                                            followCursor
                                          >
                                            <Box>
                                              <MediumTypography
                                                label={approver3.name}
                                                className="ml-sm ellipsis"
                                                fontSize="12px"
                                                textColor="#FFFFFF"
                                                sxProps={{
                                                  width:
                                                    approver3.name.length > 12
                                                      ? "100px"
                                                      : "auto",
                                                }}
                                              />
                                            </Box>
                                          </Tooltip>
                                          <Tooltip
                                            title={
                                              approver3.roleName &&
                                              approver3.roleName.length > 12
                                                ? approver3.roleName
                                                : ""
                                            }
                                            arrow={true}
                                            followCursor
                                          >
                                            <Box>
                                              <MediumTypography
                                                label={approver3.roleName}
                                                className=" ml-sm ellipsis"
                                                textColor="rgba(255, 255, 255, 0.64)"
                                                fontSize="10px"
                                                fontWeight={400}
                                                sxProps={{
                                                  width:
                                                    approver3.roleName.length >
                                                    12
                                                      ? "100px"
                                                      : "auto",
                                                  marginTop:
                                                    approver3.name === ""
                                                      ? "8px"
                                                      : "0px",
                                                }}
                                              />
                                            </Box>
                                          </Tooltip>
                                        </Box>
                                      </Box>
                                    </Box>
                                  </React.Fragment>
                                )}{" "}
                              {approver3 !== undefined &&
                                approver3 === null && (
                                  <React.Fragment>
                                    <Box
                                      className="flex__ align__items__center ml-sm"
                                      sx={{ position: "relative" }}
                                    >
                                      <Avatar
                                        src={""}
                                        alt="No approver"
                                        style={{
                                          width: "32px",
                                          height: "32px",
                                        }}
                                      />
                                    </Box>
                                    <Box className=" pt-sm">
                                      <Box className="flex__justify__space-between">
                                        <Box>
                                          <MediumTypography
                                            label={""}
                                            className="ml-xs ellipsis"
                                            fontSize="12px"
                                            textColor="#FFFFFF"
                                          />
                                          <MediumTypography
                                            label={""}
                                            className=" ml-xs ellipsis"
                                            textColor="rgba(255, 255, 255, 0.64)"
                                            fontSize="10px"
                                            fontWeight={400}
                                          />
                                        </Box>
                                      </Box>
                                    </Box>
                                  </React.Fragment>
                                )}
                            </Box>
                          )}
                        </Box>
                      </Box>
                      <Grid container className="mt-xs">
                        <Grid item xs={12} lg={12} md={12} xl={12}>
                          <Box>
                            <Divider
                              variant="fullWidth"
                              sx={{
                                borderTop: "1px solid #333b43",
                              }}
                            />
                          </Box>
                        </Grid>
                      </Grid>

                      <WorkOrderGallery
                        dumgImage={dumgImage}
                        beforeImage={beforeImage}
                        afterImage={afterImage}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={2.5}>
                    <Grid container className="mt-xs">
                      <Grid item xs={12} lg={12} md={12} xl={12}>
                        <Box>
                          <Divider
                            variant="fullWidth"
                            sx={{
                              borderTop: "1px solid #333b43",
                            }}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                    <WorkOrderActivity
                      isVisible={isVisibleGC}
                      cardId={JSON.parse(navState?.cardId)}
                    />
                  </Grid>
                </Grid>
              );
            }}
          </Formik>
        </Box>
      </Box>
    </Box>
  );
};

export default GrassCuttingComponent;
