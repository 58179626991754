import { Box, Grid } from "@mui/material";
import React, { FC, useContext, useEffect, useRef, useState } from "react";
import MediumTypography from "../../../components/formlib/MediumTypography";
import { SiteDetailsTypes } from "../wizrads.types";
import * as Yup from "yup";
import { Formik, FormikProps } from "formik";
import FormikErrorComponent from "../../../components/formlib/FormikErrorComponent";
import TextInput from "../../../components/formlib/TextInput";
import { getOptions, handleError } from "../../../utils/commonFunctions";
import CustomDropdown from "../../../components/formlib/CustomDropdown";
import ButtonComponent from "../../../components/formlib/ButtonComponent";
import { LoaderContext, LoaderContextType } from "../../../layouts/appSidebar";
import ErrorModal from "../../../components/formlib/modal/ErrorModal";
import PhoneNumberInput from "../../../components/formlib/PhoneNumberInput";
import { WORK_FLOW_BASE_URL } from "../../../api/Constant";
import {
  customSaveDataApi,
  customUpdateDataApi,
  fetchWizardsDataApi,
} from "../../../api/wizardsApi/Common";
import { WizardsContext } from "../WizardParentComponent";
import useAvailableHeight from "../../../utils/useAvailableHeight";

interface SiteDetailsProps {
  selectedAcc: string;
  siteId: number;
  siteName: string;
  siteSaveCallBack: () => void;
  selectedLanguageCode: string;
  AddSiteCallBack: () => void;
}

const muiColumnProps = {
  xs: 12,
  sm: 8,
  md: 6,
  lg: 6,
  xl: 6,
};

export const initialSiteData: SiteDetailsTypes = {
  name: "",
  location: "",
  longitude: "",
  latitude: "",
  contactPersonFirstName: "",
  contactPersonLastName: "",
  phoneNumber: "",
  email: "",
  code: "",
  isOath: false,
  countryCode: "",
  statusList: [],
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required("validation.fieldRequired"),
  location: Yup.string().nullable().required("validation.fieldRequired"),
  longitude: Yup.string().nullable().required("validation.fieldRequired"),
  latitude: Yup.string().nullable().required("validation.fieldRequired"),
  contactPersonFirstName: Yup.string().nullable(),
  contactPersonLastName: Yup.string().nullable(),
  phoneNumber: Yup.string().nullable(),
  email: Yup.string().nullable(),
  code: Yup.string().nullable().required("validation.fieldRequired"),
  isOath: Yup.boolean().nullable(),
  countryCode: Yup.string().nullable(),
});

const SiteDetailsComponent: FC<SiteDetailsProps> = ({
  selectedAcc,
  siteId,
  siteName,
  siteSaveCallBack,
  selectedLanguageCode,
  AddSiteCallBack,
}) => {
  const [siteDetailsData, setSiteDetailsData] =
    useState<SiteDetailsTypes>(initialSiteData);
  const formikRef = useRef<FormikProps<SiteDetailsTypes>>(null);
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [errorDesc, setErrorDesc] = useState<string>("");
  const siteContext = useContext(WizardsContext);
  const [currentSiteId, setCurrentSiteId] = useState<number>(siteId);
  const currentSiteName = useRef<string>(siteName);
  const availableHeight = useAvailableHeight(450);

  useEffect(() => {
    setCurrentSiteId(siteId);
    getSiteDataById();
  }, [siteId, selectedLanguageCode]);

  useEffect(() => {
    currentSiteName.current = siteName;
  }, [siteName]);

  const getSiteDataById = () => {
    const fetchSitetUrl = `${WORK_FLOW_BASE_URL}site`;
    const siteIdExist = siteId ? siteId : undefined;
    fetchWizardsDataApi<SiteDetailsTypes>(
      fetchSitetUrl,
      siteIdExist,
      selectedLanguageCode
    )
      .then(async (resp: SiteDetailsTypes) => {
        const res = { ...initialSiteData, ...resp };
        const updatedResponse: SiteDetailsTypes = {
          ...res,
          id: res.id,
        };
        siteContext?.siteOnChange(updatedResponse);
        setSiteDetailsData(updatedResponse);
        toggleLoader(false);
      })
      .catch((err) => {
        handleError(err, setErrorDesc);
        setOpenErrorModal(true);
        toggleLoader(false);
      });
  };

  const saveData = (data: SiteDetailsTypes) => {
    let params: Omit<SiteDetailsTypes, "statusList"> = {
      id: data?.id,
      name: data?.name,
      location: data?.location,
      longitude: data?.longitude,
      latitude: data?.latitude,
      contactPersonFirstName: data?.contactPersonFirstName,
      contactPersonLastName: data?.contactPersonLastName,
      phoneNumber: data?.phoneNumber,
      email: data?.email,
      code: data?.code,
      isOath: data?.isOath,
      countryCode: data?.countryCode,
      selectedStatusId: data?.selectedStatusId,
    };
    const url = `${WORK_FLOW_BASE_URL}${selectedAcc}/site/save`;

    customSaveDataApi(url, params)
      .then(() => {
        siteSaveCallBack();
        currentSiteName.current = data.name;
      })
      .catch((error) => {
        handleError(error, setErrorDesc);
        setOpenErrorModal(true);
        toggleLoader(false);
      });
  };

  const updateData = (data: SiteDetailsTypes) => {
    let params: Omit<SiteDetailsTypes, "statusList"> = {
      id: data?.id,
      name: data?.name,
      location: data?.location,
      longitude: data?.longitude,
      latitude: data?.latitude,
      contactPersonFirstName: data?.contactPersonFirstName,
      contactPersonLastName: data?.contactPersonLastName,
      phoneNumber: data?.phoneNumber,
      email: data?.email,
      code: data?.code,
      isOath: data?.isOath,
      countryCode: data?.countryCode,
      selectedStatusId: data?.selectedStatusId,
    };
    const updateUrl = `${WORK_FLOW_BASE_URL}${selectedAcc}/site/${data.id}/update`;
    if (data.id) {
      customUpdateDataApi(updateUrl, params)
        .then(() => {
          getSiteDataById();
          currentSiteName.current = data.name;
        })
        .catch((error) => {
          handleError(error, setErrorDesc);
          setOpenErrorModal(true);
          toggleLoader(false);
        });
    }
  };

  return (
    <Box>
      {openErrorModal && (
        <ErrorModal
          descriptionText={errorDesc}
          open={openErrorModal}
          handleClose={() => {
            setOpenErrorModal(false);
          }}
          onPositiveClick={() => {
            setOpenErrorModal(false);
          }}
        />
      )}
      <Box className="flex__justify__space-between mb-md">
        <MediumTypography label={siteName} fontSize="20px" fontWeight={600} />
        {selectedLanguageCode === "en" && (
          <ButtonComponent
            className="btn-primary btn-submit"
            variantType="contained"
            defaultLabelId={"Add New"}
            labelId={"btn.AddNew"}
            onClick={() => {
              AddSiteCallBack();
              setSiteDetailsData(initialSiteData);
              setCurrentSiteId(0);
              currentSiteName.current = siteName;
            }}
          />
        )}
      </Box>
      <Box>
        <Formik
          initialValues={siteDetailsData}
          enableReinitialize
          innerRef={formikRef}
          validateOnChange
          validationSchema={validationSchema}
          onSubmit={(values) => {
            if (values.id) {
              updateData(values);
            } else {
              saveData(values);
            }
          }}
        >
          {({
            values,
            setFieldValue,
            handleSubmit,
            errors,
            touched,
            dirty,
          }) => {
            return (
              <Box>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    overflowY: "auto",
                    height: availableHeight,
                    WebkitOverflowScrolling: "touch",
                    "-webkit-overflow-scrolling": "touch", // iOS specific - enables momentum scrolling
                    "scroll-behavior": "smooth", // Optional: Adds smooth scrolling behavior
                    "&::-webkit-scrollbar": {
                      display: "none", // Hide scrollbar on Chrome, Safari, and Opera
                    },
                  }}
                >
                  <Grid item {...muiColumnProps}>
                    <MediumTypography
                      className="input-label"
                      labelId="Site.name"
                      defaultLabel="Name"
                    />
                    <TextInput
                      className="text-input-field"
                      type="text"
                      variant="outlined"
                      inputProps={{
                        readOnly: false,
                      }}
                      labelId="common.placeHolderText"
                      defaultLabelId="--- type here ---"
                      Value={values.name}
                      handlechange={(value: string) => {
                        setFieldValue("name", value);
                      }}
                    />
                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="name"
                    />
                  </Grid>
                  <Grid item {...muiColumnProps}>
                    <MediumTypography
                      className="input-label"
                      labelId="Site.code"
                      defaultLabel="Code"
                    />
                    <TextInput
                      className="text-input-field"
                      type="text"
                      variant="outlined"
                      inputProps={{
                        readOnly: false,
                      }}
                      labelId="common.placeHolderText"
                      defaultLabelId="--- type here ---"
                      Value={values.code}
                      handlechange={(value: string) => {
                        setFieldValue("code", value);
                      }}
                    />
                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="code"
                    />
                  </Grid>
                  <Grid item {...muiColumnProps}>
                    <MediumTypography
                      className="input-label"
                      labelId="Site.location"
                      defaultLabel="Location"
                    />
                    <TextInput
                      className="text-input-field"
                      type="text"
                      variant="outlined"
                      inputProps={{
                        readOnly: false,
                      }}
                      labelId="common.placeHolderText"
                      defaultLabelId="--- type here ---"
                      Value={values.location}
                      handlechange={(value: string) => {
                        setFieldValue("location", value);
                      }}
                    />
                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="location"
                    />
                  </Grid>
                  <Grid item {...muiColumnProps}>
                    <MediumTypography
                      className="input-label"
                      labelId="Site.latitude"
                      defaultLabel="Latitude"
                    />
                    <TextInput
                      className="text-input-field"
                      type="text"
                      variant="outlined"
                      inputProps={{
                        readOnly: false,
                      }}
                      labelId="common.placeHolderText"
                      defaultLabelId="--- type here ---"
                      Value={values.latitude}
                      handlechange={(value: string) => {
                        setFieldValue("latitude", value);
                      }}
                    />
                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="latitude"
                    />
                  </Grid>
                  <Grid item {...muiColumnProps}>
                    <MediumTypography
                      className="input-label"
                      labelId="Site.longitude"
                      defaultLabel="Longitude"
                    />
                    <TextInput
                      className="text-input-field"
                      type="text"
                      variant="outlined"
                      inputProps={{
                        readOnly: false,
                      }}
                      labelId="common.placeHolderText"
                      defaultLabelId="--- type here ---"
                      Value={values.longitude}
                      handlechange={(value: string) => {
                        setFieldValue("longitude", value);
                      }}
                    />
                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="longitude"
                    />
                  </Grid>
                  <Grid item {...muiColumnProps}>
                    <MediumTypography
                      className="input-label"
                      labelId="Site.contactPersonFirstName"
                      defaultLabel="Contact Person First Name"
                    />
                    <TextInput
                      className="text-input-field"
                      type="text"
                      variant="outlined"
                      inputProps={{
                        readOnly: false,
                      }}
                      labelId="common.placeHolderText"
                      defaultLabelId="--- type here ---"
                      Value={values.contactPersonFirstName}
                      handlechange={(value: string) => {
                        setFieldValue("contactPersonFirstName", value);
                      }}
                    />
                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="contactPersonFirstName"
                    />
                  </Grid>
                  <Grid item {...muiColumnProps}>
                    <MediumTypography
                      className="input-label"
                      labelId="Site.contactPersonLastName"
                      defaultLabel="Contact Person Last Name"
                    />
                    <TextInput
                      className="text-input-field"
                      type="text"
                      variant="outlined"
                      inputProps={{
                        readOnly: false,
                      }}
                      labelId="common.placeHolderText"
                      defaultLabelId="--- type here ---"
                      Value={values.contactPersonLastName}
                      handlechange={(value: string) => {
                        setFieldValue("contactPersonLastName", value);
                      }}
                    />
                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="contactPersonLastName"
                    />
                  </Grid>
                  <Grid item {...muiColumnProps}>
                    <MediumTypography
                      className="input-label"
                      labelId="Site.contactPersonPhoneNumber"
                      defaultLabel="Contact Person Phone Number"
                    />

                    <PhoneNumberInput
                      value={`${values.countryCode || ""}${
                        values.phoneNumber || ""
                      }`}
                      onChange={(value: string, countryData: any) => {
                        const countryCode = countryData.dialCode; // country code
                        const phoneNumber = value.startsWith(countryCode)
                          ? value.slice(countryCode.length) // Remove the country code prefix
                          : value; // Use the value directly if no country code prefix

                        setFieldValue("countryCode", countryCode); // Store only the country code seperat
                        setFieldValue("phoneNumber", phoneNumber); // Store only the raw phone number without country code
                      }}
                      labelId="common.placeHolderText"
                      defaultLabelId="--- type here ---"
                      disabled={false}
                    />
                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="phoneNumber"
                    />
                  </Grid>
                  <Grid item {...muiColumnProps}>
                    <MediumTypography
                      className="input-label"
                      labelId="Site.contactPersonEmail"
                      defaultLabel="Contact Person Email"
                    />
                    <TextInput
                      className="text-input-field"
                      type="text"
                      variant="outlined"
                      inputProps={{
                        readOnly: false,
                      }}
                      labelId="common.placeHolderText"
                      defaultLabelId="--- type here ---"
                      Value={values.email}
                      handlechange={(value: string) => {
                        setFieldValue("email", value);
                      }}
                    />
                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="email"
                    />
                  </Grid>
                  <Grid item {...muiColumnProps}>
                    <MediumTypography
                      className="input-label"
                      labelId="Site.status"
                      defaultLabel="Status"
                    />
                    <CustomDropdown
                      disableCreate={true}
                      sxStyles={{
                        textField: {
                          padding: "0.5px 4px 0px 5px",
                        },
                      }}
                      options={getOptions(values.statusList)}
                      selectedValue={values.selectedStatusId}
                      onSelectedValueChange={(value) => {
                        const statusId = value as string;
                        setFieldValue("selectedStatusId", statusId);
                      }}
                    />
                  </Grid>
                </Grid>
                <Box mt={"10px"} className="flex__ justifyContent-FlexEnd">
                  {selectedLanguageCode === "en" && (
                    <ButtonComponent
                      className="btn-primary btn-submit"
                      variantType="contained"
                      defaultLabelId={"Save"}
                      labelId={currentSiteId ? "btn.update" : "btn.save"}
                      onClick={() => {
                        handleSubmit();
                      }}
                    />
                  )}
                </Box>
              </Box>
            );
          }}
        </Formik>
      </Box>
    </Box>
  );
};

export default SiteDetailsComponent;
