import {
  Box,
  Chip,
  Divider,
  Grid,
  TextField,
  Button,
  ButtonGroup,
  Tooltip,
  TooltipProps,
  styled,
  tooltipClasses,
} from "@mui/material";
import "./planner.css";
import MediumTypography from "../../components/formlib/MediumTypography";
import CardComponentSidebar from "../../components/formlib/CardComponentSidebar";
import CycleProgressBar from "../../components/formlib/CycleProgressBar";
import {
  CycleInfoType,
  FeatureCollectionType,
  PlannerCardDetailsType,
  PlannerDataType,
  PlannerSaveDataType,
  VendorType,
} from "./plannerTypes";
import StatusExecution from "../../components/formlib/StatusExecution";
import ButtonComponent from "../../components/formlib/ButtonComponent";
import TextInput from "../../components/formlib/TextInput";
import DropdownComponent from "../../components/formlib/DropdownComponent";
import dayjs, { Dayjs } from "dayjs";
import {
  getModuleCleaningPlannerApi,
  saveModuleCleaningPlannerApi,
} from "../../api/moduleCleaning/ModuleCleaningPlannerApi";
import ErrorModal from "../../components/formlib/modal/ErrorModal";

import React, { useRef, useCallback, FC, useEffect, useState } from "react";
import {
  DrawingManager,
  GoogleMap,
  Libraries,
  useJsApiLoader,
} from "@react-google-maps/api";

import FullScreenIcon from "./../../assets/images/map_control_buttons/FullScreen.svg";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import PenToolIcon from "./../../assets/images/map_control_buttons/PenTool.svg";
import RectangleIcon from "./../../assets/images/map_control_buttons/Rectangle.svg";
import StoppingHandIcon from "./../../assets/images/map_control_buttons/StoppingHand.svg";
import ZoomInIcon from "./../../assets/images/map_control_buttons/ZoomIn.svg";
import ZoomOutIcon from "./../../assets/images/map_control_buttons/ZoomOut.svg";
import FabWithTooltip from "./FabWithTooltip";
import * as yup from "yup";
import { Formik } from "formik";
import ModalPopup from "../../components/formlib/modal/ModalPopup";
import { useLocation, useNavigate } from "react-router-dom";
import DateTimePickerComponent from "../../components/formlib/DateTimePickerComponent";
import Loader from "../../components/shared/Loader";
import { SCHEDULER_DASHBOARD } from "../../routes/Routing";
import { ReactComponent as ErrorIcon } from "../../assets/images/ErrorIcon.svg";
import { CleaningMethodType } from "../../api/moduleCleaning/ModuleCleaningApi";
import { handleError } from "../../utils/commonFunctions";

const CustomButton = styled(Button)`
  width: 40.42px;
  height: 35px;
  margin-right: 6px;
  margin-left: 6px;
  background-color: rgba(29, 33, 37, 0.9);
  &:hover {
    background-color: rgba(29, 33, 37, 1);
  }
`;

const CustomTooltip = styled(({ className, title, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    title={
      <MediumTypography
        labelId={title as string}
        fontSize="12px"
        fontWeight={500}
      />
    }
    classes={{ popper: className ?? "" }}
  />
))`
  & .${tooltipClasses.tooltip} {
    background-color: rgba(29, 33, 37, 0.9);
    color: #fff;
    font-weight: 500;
    border-radius: 4px;
  }

  & .${tooltipClasses.arrow} {
    color: rgba(29, 33, 37, 0.9);
  }
`;

// To fix type missmatch issue
type Library =
  | "core"
  | "maps"
  | "places"
  | "geocoding"
  | "routes"
  | "marker"
  | "geometry"
  | "elevation"
  | "streetView"
  | "journeySharing"
  | "drawing"
  | "visualization";

const libraries: Libraries = ["drawing", "geometry"];

type SelectionMode = "select" | "deselect" | null;

const apiKey = "AIzaSyAeJTj_G8GZMdKK-FQngfDdMf3glBkGfoM";

// Center coordinates for India, zoom should be set to 5
const center = {
  lat: 20.5937,
  lng: 78.9629,
};

// Map view type
const mapType = "HYBRID"; // "ROADMAP" | "TERRAIN" | "SATELLITE" | "HYBRID"

// Map display styles currently not working, might need to enable this feature on map console
const mapStyles: google.maps.MapTypeStyle[] = [
  {
    featureType: "all",
    elementType: "all",
    stylers: [
      { saturation: -10 }, // reduce saturation
      { lightness: 10 }, // increase lightness
    ],
  },
];

// Style Mappings
const STYLE_MAPPING = {
  strokeColor: "strokeClr",
  strokeOpacity: "strokeOpac",
  strokeWeight: "strokeWt",
  fillColor: "fillColor",
  fillOpacity: "fillOpac",
};

const STATUS_COLORS = {
  PLANNED: "#A3F000", // Green
  PLANNED_TODAY: "#70991d", // Dark Green
  ACTUAL: "#186ade", // Blue
  EXTRA: "#FF6500", // Orange
  REJECTED: "#FF0000", // Red
};

const initialCardDetails: PlannerCardDetailsType = {
  sequenceId: 0,
  type: "",
  code: "",
  frequency: "",
  description: "",
  processedOn: "",
  processedCount: 0,
  processedBy: "",
  status: "",
  statusCode: "",
  members: [],
};

const initialGeoJson: FeatureCollectionType = {
  type: "FeatureCollection",
  name: "",
  crs: {
    type: "name",
    properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" },
  },
  features: [],
};

const initialPlannerData: PlannerDataType = {
  currentDayPlannedTables: [],
  currentDayActualTables: [],
  selectedWOPlannedTables: [],
  selectedWOActualTables: [],
  rejectedWoTables: [],
  cyclesInfo: [],
  shiftDetailBeanList: [],
  vendorsList: [],
  selectedVendorId: 0,
  selectedVendorName: "",
  plannedWOTablesCount: 0,
  plannedWOModulesCount: 0,
  actualWOTablesCount: 0,
  actualWOModulesCount: 0,
  rejectedWoTableCount: 0,
  plannerCycleTablesCount: 0,
  plannerCycleModulesCount: 0,
  actualCycleTablesCount: 0,
  actualCycleModulesCount: 0,
  totalNumberOfCycles: 0,
  shiftTimeRange: "",
  woPlannedStartTime: "",
  woPlannedEndTime: "",
  recommendation: "",
  status: [],
  cardDetailsInfo: initialCardDetails,
  plainJson: JSON.stringify(initialGeoJson),
  cleaningMethod: [{ id: 0, name: "" }],
  definedScheduleStatus: [],
  selectedCleaningMethodId: 0,
};

export interface CountsType {
  tables: number;
  modules: number;
}

export interface AllCountsType {
  workorderPlanned: CountsType;
  workorderActual: CountsType;
  cyclePlanned: CountsType;
  cycleActual: CountsType;
}

type ViewModeType = "planner" | "actual" | "rejection" | null;

const defaultCounts: CountsType = {
  tables: 0,
  modules: 0,
};

const defaultCountsAll = {
  workorderPlanned: defaultCounts,
  workorderActual: defaultCounts,
  workorderRejected: defaultCounts,
  cyclePlanned: defaultCounts,
  cycleActual: defaultCounts,
};

const zeroPrefixedValue = (value: number) => {
  if (value) {
    if (value <= 0) return "0";
    return value.toString().padStart(2, "0");
  }
  return "0";
};

export interface OptionType {
  id: string;
  label: string;
}

interface FormValuesType {
  selectedVendorId: number;
  woPlannedStartTime: string;
  woPlannedEndTime: string;
  selectedCleaningMethodId: number;
}

const ModuleCleaningPlanner: FC = () => {
  const [apiData, setApiData] = useState<PlannerDataType>(initialPlannerData);

  const [counts, setCounts] = useState(defaultCountsAll);

  const [plannedPeriodToggle, setPlannedPeriodToggle] = useState<
    "workorder" | "cycle"
  >("workorder");
  const [actualPeriodToggle, setActualPeriodToggle] = useState<
    "workorder" | "cycle"
  >("workorder");

  const [vendorList, setVendorList] = useState<OptionType[]>([]);

  const [formValues, setFormValues] = useState<FormValuesType>({
    selectedVendorId: 0,
    woPlannedStartTime: "",
    woPlannedEndTime: "",
    selectedCleaningMethodId: 0,
  });

  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [errorDesc, setErrorDesc] = useState<string>("");

  const [map, setMap] = useState<google.maps.Map>();
  const [viewMode, setViewMode] = useState<ViewModeType>(null);

  const [polygons, setPolygons] = useState<Array<google.maps.Polygon>>([]);
  const [selectedPolygonIds, setSelectedPolygonIds] = useState<Set<string>>(
    new Set()
  );
  const [selectedPolygonModules, setSelectedPolygonModules] = useState<
    Map<string, number>
  >(new Map());
  const [drawingMode, setDrawingMode] =
    useState<google.maps.drawing.OverlayType | null>(null);
  const [selectionMode, setSelectionMode] = useState<SelectionMode>(null);
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState(false);

  const [currentCycleNumber, setCurrentCycleNumber] = useState<number>(0);

  const originalStylesRef = useRef<Map<number, any>>(new Map());
  const mapContainerRef = useRef<HTMLElement>();
  const customControlsRef = useRef<HTMLDivElement>(null);

  const [cleaningMethodList, setCleaningMethodList] = useState<OptionType[]>(
    []
  );

  const validationSchema = yup.object().shape({
    selectedCleaningMethodId: yup
      .number()
      .required("CMErrorText")
      .test("is-not-zero", "CMErrorText", (value) => value !== 0),
    selectedVendorId: yup
      .number()
      .required("vendorErrorText")
      .test("is-not-zero", "vendorErrorText", (value) => value !== 0),
    woPlannedStartTime: yup.string().nullable(),
    woPlannedEndTime: yup.string().nullable(),
  });

  const location = useLocation();
  const navState = location?.state;
  const navigate = useNavigate();

  const overrideViewMode = location.state?.viewMode
    ? location.state?.viewMode
    : "actual";

  const refreshComponent = (updatedCycleNumber = 0) => {
    polygons.forEach((polygon) => {
      polygon.setMap(null);
    });
    setPolygons(() => []);
    setSelectedPolygonIds(() => new Set());
    setSelectedPolygonModules(() => new Map());
    originalStylesRef.current = new Map();
    setCurrentCycleNumber(() => updatedCycleNumber);
    getModuleCleaningPlannerInfo(updatedCycleNumber);
  };

  // Fetch api data
  useEffect(() => {
    getModuleCleaningPlannerInfo();
  }, []);

  const getModuleCleaningPlannerInfo = (
    selectedCycleNumber: number = 0,
    cyclePageNo: number = 0
  ) => {
    // const navState = location?.state;
    // getModuleCleaningApi(JSON.parse(navState.cardId))
    setIsLoading(true);
    getModuleCleaningPlannerApi(
      navState.cardId,
      navState.assetGroupId,
      navState.idType,
      selectedCycleNumber,
      cyclePageNo
    )
      .then((res) => {
        const countsObject = {
          workorderPlanned: {
            tables: res.plannedWOTablesCount,
            modules: res.plannedWOModulesCount,
          },
          workorderActual: {
            tables: res.actualWOTablesCount,
            modules: res.actualWOModulesCount,
          },
          workorderRejected: {
            tables: res.rejectedWoTableCount,
            // modules: res.rejectedWOModuleCount,
            modules: 0,
          },
          cyclePlanned: {
            tables: res.plannerCycleTablesCount,
            modules: res.plannerCycleModulesCount,
          },
          cycleActual: {
            tables: res.actualCycleTablesCount,
            modules: res.actualCycleModulesCount,
          },
        };
        const cardStatus = res.cardDetailsInfo?.statusCode;
        let updatedViewMode: ViewModeType = null;
        if (cardStatus && cardStatus !== "") {
          if (cardStatus === "SCHEDULED" || cardStatus === "DISPATCHED") {
            updatedViewMode = "planner";
          } else if (cardStatus === "INPROGRESS") {
            updatedViewMode = "actual";
          } else if (cardStatus === "COMPLETED") {
            updatedViewMode = overrideViewMode; // "actual" | "rejection";
          } else if (
            cardStatus === "LEVEL1" ||
            cardStatus === "LEVEL2" ||
            cardStatus === "LEVEL3"
          ) {
            updatedViewMode = "rejection";
          }
        }

        const vedorOptions: OptionType[] =
          res.vendorsList?.map((vendor: VendorType) => ({
            id: vendor.id.toString(),
            label: vendor.name,
          })) || [];

        const cleaningMethodOptions: OptionType[] = res.cleaningMethod.map(
          (cleaning: CleaningMethodType) => ({
            id: cleaning.id.toString(),
            label: cleaning.name,
          })
        );

        setApiData(res);
        setCounts(countsObject);
        setViewMode(updatedViewMode);
        setVendorList(vedorOptions);
        setCleaningMethodList(cleaningMethodOptions);
        setFormValues({
          selectedVendorId: res.selectedVendorId || 0,
          woPlannedStartTime: res.woPlannedStartTime || "",
          woPlannedEndTime: res.woPlannedEndTime || "",
          selectedCleaningMethodId: res.selectedCleaningMethodId || 0,
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setOpenErrorModal(true);
        handleError(err, setErrorDesc);
        setIsLoading(false);
      });
  };

  const handleSaveModuleCleaningPlannerApi = (
    action:
      | "save-plan"
      | "save-actual"
      | "approve-actual"
      | "reject-actual"
      | "partial-reject"
      | "",
    formData?: FormValuesType
  ) => {
    if (action === "") return;

    const paramsObj: PlannerSaveDataType = {
      plannedWoTableIds: [],
      actualWoTableIds: [],
      partialRejectedWoTableIds: [],
      vendorName: "",
      vendorId: null,
      selectedCleaningMethodId: null,
      scheduledStartDateTime: "",
      scheduledEndDateTime: "",
      warningMessage: "",
      bypassMultiCyclePlanSave: true,
    };

    if (action === "save-plan") {
      paramsObj.plannedWoTableIds = Array.from(selectedPolygonIds);
      paramsObj.vendorId = formData?.selectedVendorId || null;
      paramsObj.selectedCleaningMethodId =
        formData?.selectedCleaningMethodId || null;
      paramsObj.scheduledStartDateTime = formData?.woPlannedStartTime || "";
      paramsObj.scheduledEndDateTime = formData?.woPlannedEndTime || "";
    } else if (action === "save-actual") {
      paramsObj.actualWoTableIds = Array.from(selectedPolygonIds);
    } else if (action === "partial-reject") {
      paramsObj.partialRejectedWoTableIds = Array.from(selectedPolygonIds);
    }

    saveModuleCleaningPlannerApi(
      navState.cardId,
      navState.idType,
      action,
      paramsObj
    )
      .then(() => {
        setOpenModal(true);
      })
      .catch((err) => {
        setOpenErrorModal(true);
        handleError(err, setErrorDesc);
      });
  };

  useEffect(() => {
    const mapContainer = mapContainerRef.current;

    const disableScroll = () => {
      window.addEventListener("wheel", preventScroll, { passive: false });
    };

    const enableScroll = () => {
      window.removeEventListener("wheel", preventScroll);
    };

    const preventScroll = (e: WheelEvent) => {
      e.preventDefault();
    };

    mapContainer?.addEventListener("mouseenter", disableScroll);
    mapContainer?.addEventListener("mouseleave", enableScroll);

    return () => {
      // Cleanup listeners when the component unmounts
      mapContainer?.removeEventListener("mouseenter", disableScroll);
      mapContainer?.removeEventListener("mouseleave", enableScroll);
    };
  }, [mapContainerRef.current]);

  // Count will change dynamically for workorder only
  const onCountsChange = (
    type: "planner" | "actual" | "rejection",
    countsData: CountsType
  ) => {
    if (type === "planner") {
      setCounts((prevCounts) => ({
        ...prevCounts,
        workorderPlanned: countsData,
      }));
    } else if (type === "actual") {
      setCounts((prevCounts) => ({
        ...prevCounts,
        workorderActual: countsData,
      }));
    } else if (type === "rejection") {
      setCounts((prevCounts) => ({
        ...prevCounts,
        workorderRejected: countsData,
      }));
    }
  };

  let showMap = false;

  let cyclesInfo: CycleInfoType[] = apiData.cyclesInfo;

  let currentCycle: CycleInfoType = {
    cycleNumber: 0,
    plannedPercentage: 0,
    actualPercentage: 0,
    cumulativeCount: 0,
    currentCycle: true,
    cycleActualTables: [],
  };

  const plainJson: FeatureCollectionType = JSON.parse(apiData.plainJson);

  const currentWorkorderPlanned = new Set<string>(
    apiData.selectedWOPlannedTables
  );
  const currentWorkorderActuals = new Set<string>(
    apiData.selectedWOActualTables
  );
  const currentWorkorderRejected = new Set<string>(apiData.rejectedWoTables);
  const currentDayPlanned = new Set<string>(apiData.currentDayPlannedTables);
  const currentDayActuals = new Set<string>(apiData.currentDayActualTables);

  let currentCycleActuals = new Set<string>();

  if (cyclesInfo?.length > 0) {
    cyclesInfo = cyclesInfo.sort((a, b) => b.cycleNumber - a.cycleNumber);

    const currentCycleData = cyclesInfo.find(
      (cycle) => cycle.currentCycle === true
    );
    if (currentCycleData) {
      currentCycleActuals = new Set(currentCycleData.cycleActualTables);
      // currentCycleActuals.push(...currentCycleData.cycleActualTables);
      currentCycle = currentCycleData;

      if (plainJson?.features?.length > 0) {
        showMap = true;
      }
    }
  } else {
    // First Cycle
    cyclesInfo.push(currentCycle);

    if (plainJson?.features?.length > 0) {
      showMap = true;
    }
  }

  const workorderPlannedCountsText = `${counts.workorderPlanned.tables} | ${counts.workorderPlanned.modules}`;

  const plannedCountsText =
    plannedPeriodToggle === "workorder"
      ? workorderPlannedCountsText
      : `${counts.cyclePlanned.tables} | ${counts.cyclePlanned.modules}`;

  const actualCountsText =
    actualPeriodToggle === "workorder"
      ? `${counts.workorderActual.tables} | ${counts.workorderActual.modules}`
      : `${counts.cycleActual.tables} | ${counts.cycleActual.modules}`;

  let viewModeFillColor = "#808080";

  if (viewMode === "planner") {
    viewModeFillColor = STATUS_COLORS.PLANNED;
  } else if (viewMode === "actual") {
    viewModeFillColor = STATUS_COLORS.ACTUAL;
  } else if (viewMode === "rejection") {
    viewModeFillColor = STATUS_COLORS.REJECTED;
  }

  // Map container style - Full width & height
  const mapContainerStyle = {
    width: "100%",
    height: isFullScreen ? "100vh" : "600px", // Use viewport height when in full-screen
  };

  // Load required scripts
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: apiKey,
    libraries: libraries as Library[], // Ensure the drawing library is loaded
  });

  // Map load callback
  const onMapLoad = useCallback((mapInstance: google.maps.Map) => {
    mapInstance.setOptions({
      styles: mapStyles, // custom map style array
      mapTypeId: google.maps.MapTypeId[mapType],
    });
    setMap(mapInstance);
  }, []);

  // Map tiles loaded callback
  const onTilesLoaded = useCallback(() => {
    if (customControlsRef.current) {
      customControlsRef.current.style.display = "block";
    }
  }, []);

  // useEffect(() => {
  //   setData(mapData);
  // }, [mapData]);

  // Drawing polygons from GeoJson data
  useEffect(() => {
    if (!map || !apiData) return;

    const features = plainJson.features;

    const updatedPolygonIds: Set<string> = new Set();
    const updatedPolygonModules: Map<string, number> = new Map();

    // Bounds to encompass all the polygons -  this will be the viewport of the map
    const bounds = new google.maps.LatLngBounds();

    const newPolygons = features.map((feature: any) => {
      const coordinates = feature.geometry.coordinates[0][0].map(
        (coord: any) => {
          // Extend the bounds to include each polygon's bounds
          bounds.extend(new google.maps.LatLng(coord[1], coord[0]));
          return {
            lng: coord[0],
            lat: coord[1],
          };
        }
      );

      const properties = feature.properties;

      // Create a style object based on the properties
      const defaultStyle = {
        strokeColor: properties[STYLE_MAPPING.strokeColor],
        strokeOpacity: properties[STYLE_MAPPING.strokeOpacity],
        strokeWeight: properties[STYLE_MAPPING.strokeWeight],
        fillColor: properties[STYLE_MAPPING.fillColor],
        fillOpacity: properties[STYLE_MAPPING.fillOpacity],
      };

      const style = { ...defaultStyle };

      let disableSelection = false;
      let disableTooltip = false;
      let revertToDefault = false;
      let revertColor = "#808080";
      let revertIsFilled = false;
      let isFilled = false;

      if (viewMode === "planner") {
        // Show all stylings - cycle, day, current workorder
        let isCurrentCycleActual = false;
        if (currentCycleActuals.has(properties.id)) {
          style.fillColor = STATUS_COLORS.ACTUAL;
          isFilled = true;
          disableSelection = false;
          isCurrentCycleActual = true;
        }

        let isCurrentDayPlanned = false;
        if (currentDayPlanned.has(properties.id)) {
          style.fillColor = STATUS_COLORS.PLANNED_TODAY;
          isFilled = true;
          disableSelection = true;
          isCurrentDayPlanned = true;
        }

        let isCurrentWOPlanned = false;
        if (currentWorkorderPlanned.has(properties.id)) {
          style.fillColor = STATUS_COLORS.PLANNED;
          isFilled = true;
          disableSelection = false;
          isCurrentWOPlanned = true;
          if (isCurrentCycleActual) {
            revertColor = STATUS_COLORS.ACTUAL;
            revertIsFilled = true;
          } else {
            revertToDefault = true;
          }
          updatedPolygonModules.set(properties.id, Number(properties.modCount));
        }

        if (currentDayActuals.has(properties.id)) {
          if (isCurrentDayPlanned) {
            style.fillColor = STATUS_COLORS.ACTUAL;
          } else {
            style.fillColor = STATUS_COLORS.EXTRA;
          }
          isFilled = true;
          disableSelection = true;
        }

        if (currentWorkorderActuals.has(properties.id)) {
          if (isCurrentWOPlanned) {
            style.fillColor = STATUS_COLORS.ACTUAL;
          } else {
            style.fillColor = STATUS_COLORS.EXTRA;
          }
          isFilled = true;
          disableSelection = true;
        }

        // Current workorder planned only polygons will be by default selected
        if (style.fillColor === STATUS_COLORS.PLANNED) {
          updatedPolygonIds.add(properties.id);
        }
      } else if (viewMode === "actual") {
        // Show only current workorder planned/actual styling
        let isPlanned = false;
        if (currentWorkorderPlanned.has(properties.id)) {
          style.fillColor = STATUS_COLORS.PLANNED;
          isFilled = true;
          disableSelection = false;
          isPlanned = true;
        }

        if (currentWorkorderActuals.has(properties.id)) {
          style.fillColor = STATUS_COLORS.ACTUAL;
          isFilled = true;
          disableSelection = false;
          if (isPlanned) {
            revertColor = STATUS_COLORS.PLANNED;
            revertIsFilled = true;
          } else {
            style.fillColor = STATUS_COLORS.EXTRA;
            revertToDefault = true;
          }
          updatedPolygonModules.set(properties.id, Number(properties.modCount));
        }

        // Current workorder actuals only polygons will be by default selected
        if (
          style.fillColor === STATUS_COLORS.ACTUAL ||
          style.fillColor === STATUS_COLORS.EXTRA
        ) {
          updatedPolygonIds.add(properties.id);
        }
      } else if (viewMode === "rejection") {
        // Show only current workorder actual styling
        let isCurrentPlanned = false;
        if (currentWorkorderPlanned.has(properties.id)) {
          isCurrentPlanned = true;
        }

        if (currentWorkorderActuals.has(properties.id)) {
          style.fillColor = STATUS_COLORS.ACTUAL;
          isFilled = true;
          disableSelection = false;

          if (!isCurrentPlanned) {
            style.fillColor = STATUS_COLORS.EXTRA;
          }
        } else {
          isFilled = false;
          disableSelection = true;
        }

        if (currentWorkorderRejected.has(properties.id)) {
          style.fillColor = STATUS_COLORS.REJECTED;
          isFilled = true;
          disableSelection = true;
          updatedPolygonModules.set(properties.id, Number(properties.modCount));
        }

        // Current workorder rejected only polygons will be by default selected
        if (style.fillColor === STATUS_COLORS.REJECTED) {
          updatedPolygonIds.add(properties.id);
        }
      } else {
        // Show only current workorder planned/actual/rejected styling - view only
        let isCurrentPlanned = false;
        if (currentWorkorderPlanned.has(properties.id)) {
          style.fillColor = STATUS_COLORS.PLANNED;
          isFilled = true;
          isCurrentPlanned = true;
        }

        if (currentWorkorderActuals.has(properties.id)) {
          style.fillColor = STATUS_COLORS.ACTUAL;
          isFilled = true;

          if (!isCurrentPlanned) {
            style.fillColor = STATUS_COLORS.EXTRA;
          }
        }

        if (currentWorkorderRejected.has(properties.id)) {
          style.fillColor = STATUS_COLORS.REJECTED;
          isFilled = true;
        }

        disableSelection = true;
      }

      if (isFilled) {
        style.fillOpacity = 1;
        style.strokeOpacity = 0;
      }

      // const polygonId = feature.id;
      const polygonId = properties.id;
      const moduleCount = Number(properties.modCount);

      // Store the polygon's original styles with its ID
      if (revertToDefault) {
        originalStylesRef.current.set(polygonId, defaultStyle);
      } else if (revertColor !== "#808080") {
        const revertStyle = { ...defaultStyle };
        revertStyle.fillColor = revertColor;
        if (revertIsFilled) {
          revertStyle.fillOpacity = 1;
          revertStyle.strokeOpacity = 0;
        }
        originalStylesRef.current.set(polygonId, revertStyle);
      } else {
        originalStylesRef.current.set(polygonId, style);
      }

      if (polygonId === "outer") {
        disableSelection = true;
        disableTooltip = true;
      }

      const polygonsData = new google.maps.Polygon({
        paths: coordinates,
        ...style,
        zIndex: polygonId === "outer" ? 0 : 1,
      });

      polygonsData.set("polygonId", polygonId);
      polygonsData.set("moduleCount", moduleCount);
      polygonsData.set("disableSelection", disableSelection);
      polygonsData.set("disableTooltip", disableTooltip);

      // Add event listener on polygon hover to show info window
      polygonsData.addListener("mouseover", () => {
        const stringId = properties.stringId;
        if (!disableTooltip) {
          const middleCoords = polygonsData
            .getPath()
            .getArray()
            .slice(0, 2)
            .reduce(
              (acc, coord) => ({
                lng: acc.lng + coord.lng(),
                lat: acc.lat + coord.lat(),
              }),
              { lng: 0, lat: 0 }
            );

          const infoWindow = new google.maps.InfoWindow({
            content: "<p class='info-window-text'>" + stringId + "</p>",
            position: {
              lng: middleCoords.lng / 2,
              lat: middleCoords.lat / 2,
            },
          });

          infoWindow.open(map);
          polygonsData.addListener("mouseout", () => infoWindow.close());
        }
      });

      // Add event listener for polygon selection
      polygonsData.addListener("click", () => {
        handlePolygonClick(polygonsData);
      });

      polygonsData.setMap(map);

      return polygonsData;
    });

    // Check if bounds have been extended; if not, use indiaBounds
    if (bounds.isEmpty()) {
      // Bounds to show all India
      const indiaBounds = new google.maps.LatLngBounds(
        new google.maps.LatLng(6.5546079, 68.1113787), // southwest corner of India
        new google.maps.LatLng(35.6745457, 97.402561) // northeast corner of India
      );
      map.fitBounds(indiaBounds);
    } else {
      map.fitBounds(bounds);
    }

    setPolygons(newPolygons);
    setSelectedPolygonIds(updatedPolygonIds);
    setSelectedPolygonModules(updatedPolygonModules);
  }, [map, apiData]);

  useEffect(() => {
    if (viewMode) {
      const updatedModuelsCount = Array.from(
        selectedPolygonModules.values()
      ).reduce((total, count) => total + count, 0);
      onCountsChange(viewMode, {
        tables: selectedPolygonModules.size,
        modules: updatedModuelsCount,
      });
    }
  }, [selectedPolygonModules, viewMode]);

  // Return polygons within the drawn selection shape
  const selectPolygonsWithin = useCallback(
    (outerShape: google.maps.Polygon | google.maps.Rectangle) => {
      const newSelectedPolygonIds = new Set(selectedPolygonIds);
      const newSelectedPolygonModules = new Map(selectedPolygonModules);

      polygons.forEach((innerPolygon) => {
        const innerPolygonId = innerPolygon.get("polygonId");
        const innerPolygonModuleCount = innerPolygon.get("moduleCount");

        // Disable selection or deselection of outer boundary polygon
        if (!innerPolygon.get("disableSelection")) {
          const innerPath = innerPolygon.getPath();
          // Check if outerShape is a Rectangle and get bounds
          const outerBounds =
            outerShape instanceof google.maps.Rectangle
              ? outerShape.getBounds()
              : null;

          let isWithinBounds = false;

          for (let i = 0; i < innerPath.getLength(); i++) {
            const vertex = innerPath.getAt(i);
            // Check if the vertex is within the outerShape
            const isWithinPolygon =
              outerShape instanceof google.maps.Polygon
                ? google.maps.geometry.poly.containsLocation(vertex, outerShape)
                : false;

            // Check if the vertex is within the rectangle bounds
            const isWithinRectangle = outerBounds
              ? outerBounds.contains(vertex)
              : false;

            if (isWithinPolygon || isWithinRectangle) {
              isWithinBounds = true; // At least one vertex is inside the outerShape
              break;
            }
          }

          if (isWithinBounds) {
            if (selectionMode === "select") {
              // Select the new polygon and set its color to green
              innerPolygon.setOptions({
                fillColor: viewModeFillColor,
                fillOpacity: 1,
                strokeOpacity: 0,
              });
              newSelectedPolygonIds.add(innerPolygonId);
              newSelectedPolygonModules.set(
                innerPolygonId,
                innerPolygonModuleCount
              );
            } else if (selectionMode === "deselect") {
              // Deselect the polygon and revert its color to the original
              const originalStyles =
                originalStylesRef.current.get(innerPolygonId);
              if (originalStyles) {
                innerPolygon.setOptions(originalStyles);
              }
              newSelectedPolygonIds.delete(innerPolygonId);
              newSelectedPolygonModules.delete(innerPolygonId);
            }
          }
          // return false; // None of the vertices are inside the outerShape
        }
      });

      outerShape.setMap(null); // Optional: remove the drawn selction shape from the map

      setSelectedPolygonIds(newSelectedPolygonIds);
      setSelectedPolygonModules(newSelectedPolygonModules);
    },
    [
      selectionMode,
      polygons,
      selectedPolygonIds,
      selectedPolygonModules,
      viewModeFillColor,
    ]
  );

  // Select polygons when selction drawing completes
  const onOverlayComplete = useCallback(
    (event: google.maps.drawing.OverlayCompleteEvent) => {
      if (
        event.type === google.maps.drawing.OverlayType.POLYGON ||
        event.type === google.maps.drawing.OverlayType.RECTANGLE
      ) {
        const shape = event.overlay as
          | google.maps.Polygon
          | google.maps.Rectangle;

        selectPolygonsWithin(shape);
      }
    },
    [selectPolygonsWithin]
  );

  // Add a function to handle polygon click - single selection toggle
  const handlePolygonClick = (polygon: google.maps.Polygon) => {
    if (selectionMode !== null || drawingMode !== null) return;

    const clickedPolygonId = polygon.get("polygonId");
    const clickedPolygonModuleCount = polygon.get("moduleCount");

    // Disable selection or deselection of outer boundary polygon
    if (polygon.get("disableSelection")) return;

    setSelectedPolygonIds((prevSelectedPolygonIds) => {
      const newSelectedPolygonIds = new Set(prevSelectedPolygonIds);
      if (newSelectedPolygonIds.has(clickedPolygonId)) {
        // Deselect the polygon and revert its color to the original
        const originalStyles = originalStylesRef.current.get(clickedPolygonId);
        if (originalStyles) {
          polygon.setOptions(originalStyles);
        }
        newSelectedPolygonIds.delete(clickedPolygonId);
      } else {
        // Select the new polygon and set its color to green
        polygon.setOptions({
          fillColor: viewModeFillColor,
          fillOpacity: 1,
          strokeOpacity: 0,
        });
        newSelectedPolygonIds.add(clickedPolygonId);
      }

      return newSelectedPolygonIds;
    });

    setSelectedPolygonModules((prevSelectedPolygonModules) => {
      const newSelectedPolygonModules = new Map(prevSelectedPolygonModules);
      if (newSelectedPolygonModules.has(clickedPolygonId)) {
        newSelectedPolygonModules.delete(clickedPolygonId);
      } else {
        newSelectedPolygonModules.set(
          clickedPolygonId,
          clickedPolygonModuleCount
        );
      }
      return newSelectedPolygonModules;
    });
  };

  // Function to handle drawing mode change
  const handleDrawingModeChange = (
    mode: google.maps.drawing.OverlayType | null
  ) => {
    if (mode === drawingMode) {
      setSelectionMode((prevSelectionMode) => {
        return prevSelectionMode === "select" ? "deselect" : "select";
      });
    } else {
      setSelectionMode(() => "select");
    }

    setDrawingMode(() => mode);
  };

  const handleStopDrawing = () => {
    setDrawingMode(null);
    setSelectionMode(null);
  };

  // Function to handle toggling fullscreen mode
  const handleToggleFullScreen = () => {
    if (mapContainerRef.current) {
      const mapDiv = mapContainerRef.current;
      if (document.fullscreenElement) {
        document.exitFullscreen();
        setIsFullScreen(false);
      } else {
        mapDiv.requestFullscreen();
        setIsFullScreen(true);
      }
    }
  };

  // Function to handle zoom changes
  const handleZoomChange = (increment: boolean) => {
    if (map) {
      const currentZoom = map.getZoom() || 0;
      map.setZoom(increment ? currentZoom + 1 : currentZoom - 1);
    }
  };

  const onCycleClick = (cycle: number) => {
    refreshComponent(cycle);
  };

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  let polygonText = "SelectText";
  let polygonBg = "rgba(29, 33, 37, 0.9)";

  let rectangleText = "SelectText";
  let rectangleBg = "rgba(29, 33, 37, 0.9)";

  let stopBg = "rgba(29, 33, 37, 0.9)";

  if (drawingMode === google.maps.drawing.OverlayType.POLYGON) {
    if (selectionMode === "select") {
      polygonText = "SelectText";
      polygonBg = "rgba(23, 95, 250, 0.9)";
    } else if (selectionMode === "deselect") {
      polygonText = "planner.deselectText";
      polygonBg = "rgba(217, 31, 17, 0.9)";
    }
  } else if (drawingMode === google.maps.drawing.OverlayType.RECTANGLE) {
    if (selectionMode === "select") {
      rectangleText = "SelectText";
      rectangleBg = "rgba(23, 95, 250, 0.9)";
    } else if (selectionMode === "deselect") {
      rectangleText = "planner.deselectText";
      rectangleBg = "rgba(217, 31, 17, 0.9)";
    }
  } else {
    stopBg = "rgba(23, 95, 250, 0.9)";
  }

  return (
    <Box component="main" className="planner-main">
      {isLoading && <Loader />}
      {openErrorModal && (
        <ErrorModal
          descriptionText={errorDesc}
          open={openErrorModal}
          handleClose={() => setOpenErrorModal(false)}
          onPositiveClick={() => {
            setOpenErrorModal(false);
          }}
        />
      )}
      {openModal && (
        <ModalPopup
          descriptionText={"SavedSuccessfully"}
          open={openModal}
          handleClose={() => {
            setOpenModal(false);
            refreshComponent(currentCycleNumber);
          }}
          onPositiveClick={() => {
            setOpenModal(false);
            refreshComponent(currentCycleNumber);
          }}
          hideButton={true}
          positiveButtonLabelId="OKButtonText"
        />
      )}
      <Formik
        initialValues={formValues}
        enableReinitialize
        validateOnChange
        validationSchema={validationSchema}
        onSubmit={(values) => {
          const saveAction =
            viewMode === "planner"
              ? "save-plan"
              : viewMode === "actual"
              ? "save-actual"
              : viewMode === "rejection"
              ? "partial-reject"
              : "";
          if (values) {
            handleSaveModuleCleaningPlannerApi(saveAction, values);
          } else {
            handleSaveModuleCleaningPlannerApi(saveAction);
          }
        }}
      >
        {({ values, setFieldValue, handleSubmit, errors, touched }) => {
          return (
            <Box className="outer-form-wrapper">
              <Box
                className="planner-title flex__"
                sx={{ marginBottom: "10px" }}
                alignItems={"center"}
              >
                <Box>
                  <MediumTypography
                    label={apiData.cardDetailsInfo.type}
                    textColor="#626F86"
                    fontSize="14px"
                    fontWeight={400}
                  />
                </Box>
                <Box className="position__relative" sx={{ left: "5px" }}>
                  <MediumTypography
                    label={"/"}
                    textColor="#626F86"
                    fontSize="14px"
                    fontWeight={400}
                  />
                </Box>
                <Box className="position__relative" sx={{ left: "10px" }}>
                  <MediumTypography
                    labelId={
                      viewMode === "planner"
                        ? "PlanYourDayText"
                        : viewMode === "actual"
                        ? "planner.markActualsText"
                        : viewMode === "rejection"
                        ? "planner.markRejectionsText"
                        : "planner.viewOnlyText"
                    }
                    defaultLabel="Plan your day"
                    textColor="#FFFFFF"
                    fontSize="14px"
                    fontWeight={600}
                  />
                </Box>
                <Box className="flex__" sx={{ marginLeft: "auto" }}>
                  <Box className="flex__">
                    <ButtonComponent
                      className="btn-primary btn-cancel "
                      labelId="btn.cancel"
                      defaultLabelId="Cancel"
                      onClick={() =>
                        navigate(SCHEDULER_DASHBOARD, {
                          state: {
                            date: navState.date,
                            dateRange: navState.dateRange,
                            schedulerViewChange:
                              navState.schedulerViewChange ?? "team",
                          },
                        })
                      }
                    />
                    {viewMode && (
                      <ButtonComponent
                        className="btn-primary btn-submit "
                        labelId="btn.save"
                        defaultLabelId="Save"
                        onClick={handleSubmit}
                      />
                    )}
                  </Box>
                </Box>
              </Box>
              <Grid container className="planner-title-divider mt-xs">
                <Grid item xs={12} lg={12} md={12} xl={12}>
                  <Box>
                    <Divider
                      variant="fullWidth"
                      sx={{ borderTop: "1px solid #333b43" }}
                    />
                  </Box>
                </Grid>
              </Grid>
              {apiData.status?.length > 0 &&
                apiData.definedScheduleStatus?.length > 0 && (
                  <StatusExecution
                    statusArray={apiData.status}
                    cardId={navState.cardId}
                    WOUniqueId={apiData.cardDetailsInfo.sequenceId}
                    definedScheduleStatus={apiData.definedScheduleStatus}
                    statusCallBack={() => {}}
                    code={apiData.cardDetailsInfo.code}
                  />
                )}
              <Box className="planner-wrapper" display={"flex"}>
                <Box className="map-wrapper" flexGrow={1}>
                  {showMap && (
                    <Box ref={mapContainerRef}>
                      <Box style={{ position: "relative", width: "100%" }}>
                        {/* <input type="file" accept=".kml" onChange={handleFileChange} /> */}
                        <Box ref={customControlsRef} sx={{ display: "none" }}>
                          <Box
                            sx={{
                              position: "absolute",
                              bottom: "30px",
                              right: "20px",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-end",
                              zIndex: 11,
                              // "& > :not(style)": { m: 1 },
                            }}
                            className="custom-controls"
                          >
                            <FabWithTooltip
                              title="planner.fullScreenText"
                              iconSrc={!isFullScreen ? FullScreenIcon : ""}
                              icon={
                                isFullScreen ? (
                                  <CloseFullscreenIcon
                                    sx={{ color: "#FFF", fontSize: 20 }}
                                  />
                                ) : (
                                  ""
                                )
                              }
                              placement="left"
                              onFabClick={handleToggleFullScreen}
                            />

                            <FabWithTooltip
                              title={polygonText}
                              bgColor={polygonBg}
                              iconSrc={PenToolIcon}
                              placement="left"
                              onFabClick={() =>
                                handleDrawingModeChange(
                                  google.maps.drawing.OverlayType.POLYGON
                                )
                              }
                            />

                            <FabWithTooltip
                              title={rectangleText}
                              bgColor={rectangleBg}
                              iconSrc={RectangleIcon}
                              placement="left"
                              onFabClick={() =>
                                handleDrawingModeChange(
                                  google.maps.drawing.OverlayType.RECTANGLE
                                )
                              }
                            />

                            <FabWithTooltip
                              title="planner.stopText"
                              bgColor={stopBg}
                              iconSrc={StoppingHandIcon}
                              placement="left"
                              onFabClick={handleStopDrawing}
                            />

                            <ButtonGroup
                              orientation="vertical"
                              aria-label="Zoom Buttons"
                              variant="contained"
                              sx={{
                                marginTop: "7px",
                                borderRadius: "4px",
                                boxShadow: "none",
                                "& > :not(:last-child)": {
                                  borderBottom: "none",
                                },
                              }}
                            >
                              <CustomTooltip
                                title="planner.zoomInText"
                                aria-label="Zoom In"
                                placement="left"
                              >
                                <CustomButton
                                  key="zin"
                                  onClick={() => handleZoomChange(true)}
                                >
                                  <img src={ZoomInIcon} alt="Zoom In" />
                                </CustomButton>
                              </CustomTooltip>

                              <CustomTooltip
                                title="planner.zoomOutText"
                                aria-label="Zoom Out"
                                placement="left"
                              >
                                <CustomButton
                                  key="zout"
                                  onClick={() => handleZoomChange(false)}
                                >
                                  <img src={ZoomOutIcon} alt="Zoom Out" />
                                </CustomButton>
                              </CustomTooltip>
                            </ButtonGroup>
                          </Box>
                        </Box>

                        <GoogleMap
                          mapContainerStyle={mapContainerStyle}
                          center={center}
                          onLoad={onMapLoad}
                          onTilesLoaded={onTilesLoaded}
                          zoom={5}
                          options={{
                            fullscreenControl: false,
                            zoomControl: false,
                          }}
                        >
                          <DrawingManager
                            drawingMode={drawingMode}
                            onOverlayComplete={onOverlayComplete}
                            options={{
                              drawingControl: false,
                              drawingControlOptions: {
                                drawingModes: [
                                  google.maps.drawing.OverlayType.POLYGON,
                                  google.maps.drawing.OverlayType.RECTANGLE,
                                ],
                                position:
                                  google.maps.ControlPosition.RIGHT_CENTER,
                              },
                              polygonOptions: {
                                // fillColor: "#FF0000",
                                fillOpacity: 0,
                                strokeColor: "#FFF",
                                strokeWeight: 2,
                                clickable: false,
                                editable: true,
                                zIndex: 1,
                              },
                              rectangleOptions: {
                                // fillColor: "#FF0000",
                                fillOpacity: 0,
                                strokeColor: "#FFF",
                                strokeWeight: 2,
                                clickable: false,
                                editable: true,
                                zIndex: 1,
                              },
                            }}
                          />
                        </GoogleMap>
                      </Box>
                    </Box>
                  )}
                </Box>
                <Box
                  className="sidebar-wrapper pl-md pb-md pr-md tableStyles"
                  sx={{ height: "600px", overflowY: "auto" }}
                >
                  {apiData.cardDetailsInfo?.sequenceId > 0 && (
                    <CardComponentSidebar
                      cardDataArray={apiData.cardDetailsInfo}
                    />
                  )}
                  {viewMode === "planner" && (
                    <Box
                      className="holder-boxes cycle-holder"
                      display={"flex"}
                      flexDirection={"column"}
                      gap={2}
                    >
                      <Box
                        className="cycle-header"
                        display={"flex"}
                        gap={2}
                        justifyContent={"space-between"}
                      >
                        <Box display={"flex"} alignItems={"center"} gap={1}>
                          <MediumTypography
                            className="holder-box-title"
                            labelId="CurrentCycle"
                            defaultLabel="Current Cycle"
                          />
                          <Box>
                            <Chip
                              className="count-box"
                              label={`${zeroPrefixedValue(
                                currentCycle.cycleNumber
                              )}/${zeroPrefixedValue(
                                apiData.totalNumberOfCycles
                              )}`}
                            />
                          </Box>
                        </Box>
                        <Box
                          className="color-indicator-box"
                          display={"flex"}
                          alignItems={"center"}
                          gap={2}
                        >
                          <Box display={"flex"} alignItems={"center"} gap={1}>
                            <Box
                              className="color-circle"
                              sx={{ bgcolor: STATUS_COLORS.PLANNED }}
                            />
                            <MediumTypography
                              labelId="PlannedLabelText"
                              defaultLabel="Planned"
                            />
                          </Box>
                          <Box display={"flex"} alignItems={"center"} gap={1}>
                            <Box
                              className="color-circle"
                              sx={{ bgcolor: STATUS_COLORS.ACTUAL }}
                            />
                            <MediumTypography
                              labelId="ActualLabelText"
                              defaultLabel="Actual"
                            />
                          </Box>
                        </Box>
                      </Box>
                      <Box
                        className="cycle-content tableStyles"
                        sx={{ overflowX: "auto" }}
                      >
                        {cyclesInfo?.map((cycle) => (
                          <CycleProgressBar
                            key={cycle.cycleNumber}
                            cycle={cycle.cycleNumber}
                            planned={cycle.plannedPercentage}
                            actual={cycle.actualPercentage}
                            cumulativeCount={cycle.cumulativeCount}
                            activeCycle={cycle.currentCycle}
                            onCycleClick={onCycleClick}
                          />
                        ))}
                      </Box>
                    </Box>
                  )}
                  {apiData.cardDetailsInfo?.sequenceId > 0 && (
                    <Box className="form-wrapper pt-md">
                      <Grid
                        container
                        direction={"row"}
                        className="justifyContentSpaceBtw"
                        alignItems={"center"}
                        rowSpacing={"16px"}
                        columnSpacing={"12px"}
                        sx={{ marginBottom: "20px" }}
                      >
                        <Grid item xs={12}>
                          <MediumTypography
                            className="inputLabel"
                            labelId="PlannedModulesOrTableLabelText"
                            defaultLabel="Planned Tables | Modules"
                          />

                          <TextInput
                            labelId="PlannedWOModulesInputText"
                            defaultLabelId="-- No modules/tables selected --"
                            borderColor="1px solid rgba(166, 197, 226, 0.16)"
                            variant="outlined"
                            className="inputField"
                            backGroundColor="#22272B"
                            Value={workorderPlannedCountsText}
                            inputProps={{ readOnly: true, disabled: true }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <MediumTypography
                            className="inputLabel"
                            labelId="CleaningMethodLabelText"
                            defaultLabel="Cleaning Method"
                          />

                          <DropdownComponent
                            names={cleaningMethodList}
                            labelid="NoInputText"
                            defaultlabelid="-- Not assigned --"
                            value={
                              values.selectedCleaningMethodId === 0 ||
                              values.selectedCleaningMethodId === null
                                ? ""
                                : values.selectedCleaningMethodId.toString()
                            }
                            defaultValue={
                              values.selectedCleaningMethodId === 0 ||
                              values.selectedCleaningMethodId === null
                                ? ""
                                : values.selectedCleaningMethodId.toString()
                            }
                            textColor={
                              viewMode === "planner"
                                ? "rgba(255, 255, 255, 1)"
                                : "rgba(140, 155, 171, 1)"
                            }
                            handleChange={(value: string) => {
                              setFieldValue("selectedCleaningMethodId", value);
                            }}
                            className="inputField inputFieldDropdown"
                            disabled={viewMode === "planner" ? false : true}
                          />
                          {errors?.selectedCleaningMethodId &&
                            touched?.selectedCleaningMethodId && (
                              <Box className="loginError">
                                <Box>
                                  <ErrorIcon />
                                </Box>
                                <MediumTypography
                                  labelId={errors?.selectedCleaningMethodId}
                                  defaultLabel="Cleaning method is required"
                                  fontSize="12px"
                                  textColor="#AE2E24"
                                  fontWeight={400}
                                />
                              </Box>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                          <MediumTypography
                            className="inputLabel"
                            labelId="shift.Text"
                            defaultLabel="Shift"
                          />

                          <TextInput
                            labelId="NoInputText"
                            defaultLabelId="-- Not assigned --"
                            borderColor="1px solid rgba(166, 197, 226, 0.16)"
                            variant="outlined"
                            className="inputField"
                            backGroundColor="#22272B"
                            Value={apiData.shiftTimeRange}
                            inputProps={{ readOnly: true, disabled: true }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Box className="flex__justify__space-between">
                            <MediumTypography
                              className="inputLabel"
                              labelId="PlannedModulesOrTableLabelText"
                              defaultLabel="Planned Tables | Modules"
                            />
                            <MediumTypography
                              className="inputLabel inputChangeLabel"
                              labelId={
                                plannedPeriodToggle === "workorder"
                                  ? "TillDateLinkText"
                                  : "TodayOnlyLinkText"
                              }
                              defaultLabel={
                                plannedPeriodToggle === "workorder"
                                  ? "Till Date"
                                  : "Today"
                              }
                              onClick={() =>
                                setPlannedPeriodToggle((prev) =>
                                  prev === "workorder" ? "cycle" : "workorder"
                                )
                              }
                            />
                          </Box>

                          <TextInput
                            labelId="PlannedAchievedInputText"
                            defaultLabelId="Table : 0 | Modules : 0"
                            borderColor="1px solid rgba(166, 197, 226, 0.16)"
                            variant="outlined"
                            className="inputField"
                            backGroundColor="#22272B"
                            Value={plannedCountsText}
                            inputProps={{ readOnly: true, disabled: true }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Box className="flex__justify__space-between">
                            <MediumTypography
                              className="inputLabel"
                              labelId="ActualModulesOrTableLabelText"
                              defaultLabel="Actual Tables | Modules"
                            />
                            <MediumTypography
                              className="inputLabel inputChangeLabel"
                              labelId={
                                actualPeriodToggle === "workorder"
                                  ? "TillDateLinkText"
                                  : "TodayOnlyLinkText"
                              }
                              defaultLabel={
                                actualPeriodToggle === "workorder"
                                  ? "Till Date"
                                  : "Today"
                              }
                              onClick={() =>
                                setActualPeriodToggle((prev) =>
                                  prev === "workorder" ? "cycle" : "workorder"
                                )
                              }
                            />
                          </Box>

                          <TextInput
                            labelId="PlannedAchievedInputText"
                            defaultLabelId="Table : 0 | Modules : 0"
                            borderColor="1px solid rgba(166, 197, 226, 0.16)"
                            variant="outlined"
                            className="inputField"
                            backGroundColor="#22272B"
                            Value={actualCountsText}
                            inputProps={{ readOnly: true, disabled: true }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <MediumTypography
                            className="inputLabel"
                            labelId="VendorLabelText"
                            defaultLabel="Vendor"
                          />

                          <DropdownComponent
                            names={vendorList}
                            value={
                              values.selectedVendorId === 0 ||
                              values.selectedVendorId === null
                                ? ""
                                : values.selectedVendorId.toString()
                            }
                            labelid="NoInputText"
                            defaultlabelid="-- Not assigned --"
                            textColor={
                              viewMode === "planner"
                                ? "rgba(255, 255, 255, 1)"
                                : "rgba(140, 155, 171, 1)"
                            }
                            defaultValue={
                              values.selectedVendorId === 0 ||
                              values.selectedVendorId === null
                                ? ""
                                : values.selectedVendorId.toString()
                            }
                            handleChange={(value: string) => {
                              setFieldValue("selectedVendorId", value);
                            }}
                            className="inputField inputFieldDropdown"
                            disabled={viewMode === "planner" ? false : true}
                          />
                          {errors?.selectedVendorId &&
                            touched?.selectedVendorId && (
                              <Box
                                className="loginError"
                                sx={{ position: "relative" }}
                              >
                                <Box>
                                  <ErrorIcon />
                                </Box>
                                <MediumTypography
                                  labelId={errors?.selectedVendorId}
                                  defaultLabel="Vendor is required"
                                  fontSize="12px"
                                  textColor="#AE2E24"
                                  fontWeight={400}
                                />
                              </Box>
                            )}
                        </Grid>
                        <Grid item xs={6}>
                          <MediumTypography
                            className="inputLabel"
                            labelId="ScheduledStartTimeInput"
                            defaultLabel="Scheduled Start Time"
                          />

                          <DateTimePickerComponent
                            value={
                              values.woPlannedStartTime !== ""
                                ? dayjs(values.woPlannedStartTime)
                                : null
                            }
                            labelid="NoInputText"
                            defaultlabelid="-- Not assigned --"
                            handlechange={(date: Dayjs) => {
                              const formattedText = date.format(
                                "YYYY-MM-DDTHH:mm:ss"
                              );
                              setFieldValue(
                                "woPlannedStartTime",
                                formattedText
                              );
                            }}
                            // disabledDate={viewMode === "planner" ? false : true}
                            disabledDate={true}
                            inputFormat="DD MMM YYYY HH:mm:ss"
                            width="100%"
                            textWidth="100%"
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <MediumTypography
                            className="inputLabel"
                            labelId="ScheduledEndTimeTextInput"
                            defaultLabel="Scheduled End Time"
                          />

                          <DateTimePickerComponent
                            value={
                              values.woPlannedEndTime !== ""
                                ? dayjs(values.woPlannedEndTime)
                                : null
                            }
                            labelid="NoInputText"
                            defaultlabelid="-- Not assigned --"
                            handlechange={(date: Dayjs) => {
                              const formattedText = date.format(
                                "YYYY-MM-DDTHH:mm:ss"
                              );
                              setFieldValue("woPlannedEndTime", formattedText);
                            }}
                            // disabledDate={viewMode === "planner" ? false : true}
                            disabledDate={true}
                            inputFormat="DD MMM YYYY HH:mm:ss"
                            width="100%"
                            textWidth="100%"
                          />
                        </Grid>
                        {apiData.recommendation && (
                          <Grid item xs={12}>
                            <MediumTypography
                              className="inputLabel"
                              labelId="Recommendation"
                              defaultLabel="Recommendation"
                            />
                            <TextField
                              className="plannerTextArea"
                              variant="outlined"
                              sx={{
                                border: "1px solid rgba(166, 197, 226, 0.16)",
                                backgroundColor: "#22272B",
                                borderRadius: "4px",
                                "& .MuiOutlinedInput-root": {
                                  "& fieldset": {
                                    borderColor: "rgba(166, 197, 226, 0.16)",
                                  },
                                  "&:hover fieldset": {
                                    borderColor: "rgba(166, 197, 226, 0.16)",
                                  },
                                  "&.Mui-focused fieldset": {
                                    borderColor: "rgba(166, 197, 226, 0.16)",
                                  },
                                  "&.MuiInputBase-root": {
                                    color: "rgba(159, 173, 188, 1)",
                                    fontFamily: "Inter",
                                    fontWeight: 400,
                                    fontSize: "14px",
                                  },
                                },
                                "& .MuiInputLabel-root": {
                                  color: "rgba(159, 173, 188, 1)",
                                  fontFamily: "Inter",
                                  fontWeight: 400,
                                  fontSize: "14px",
                                },
                              }}
                              fullWidth
                              multiline
                              minRows={2}
                              value={apiData.recommendation ?? ""}
                              // onChange={(event) => {
                              //   setMessage(event.target.value.trimStart());
                              //   setFieldValue(
                              //     "cleaningInfoBean.remarks",
                              //     event.target.value.trimStart()
                              //   );
                              // }}
                              inputProps={{ readOnly: true, disabled: true }}
                            />
                          </Grid>
                        )}
                      </Grid>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          );
        }}
      </Formik>
    </Box>
  );
};

export default ModuleCleaningPlanner;
