import { Box } from "@mui/material";
import React, {
  ChangeEvent,
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import MediumTypography from "../../../components/formlib/MediumTypography";
import { ReactComponent as Upload } from "../../../assets/images/UploadIcon.svg";
import { LoaderContext, LoaderContextType } from "../../../layouts/appSidebar";
import { handleError } from "../../../utils/commonFunctions";
import {
  fetchKmlData,
  KMLresponseType,
  kmlUploadApi,
} from "../../../api/wizardsApi/Common";
import { GoogleMap, Libraries, useJsApiLoader } from "@react-google-maps/api";
import { FeatureCollectionType } from "../../planner/plannerTypes";
// @ts-ignore
import useAvailableWidth from "../../../utils/useAvailableWidth";
import useAvailableHeight from "../../../utils/useAvailableHeight";
import { WORK_FLOW_BASE_URL } from "../../../api/Constant";
import ErrorModal from "../../../components/formlib/modal/ErrorModal";
import { ReactComponent as NotAvialableIcon } from "../../../assets/images/woNotFound.svg";

interface UploadKmlProps {
  assetGroupId: number;
  assetGroupName: string;
  isMenuOpen: boolean;
  parentCall: () => void;
}

const mapContainerStyle = {
  width: "100%",
  height: "600px", // Use viewport height when in full-screen
};

type Library =
  | "core"
  | "maps"
  | "places"
  | "geocoding"
  | "routes"
  | "marker"
  | "geometry"
  | "elevation"
  | "streetView"
  | "journeySharing"
  | "drawing"
  | "visualization";

const DEFAULT_STYLE = {
  strokeColor: "#808080",
  strokeOpacity: 1,
  strokeWeight: 2,
  fillColor: "#808080",
  fillOpacity: 0,
};

const UploadKmlScreen: FC<UploadKmlProps> = ({
  assetGroupId,
  assetGroupName,
  isMenuOpen,
  parentCall,
}) => {
  const inputFileRef = useRef<HTMLInputElement>(null);
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  const [errorDesc, setErrorDesc] = useState<string>("");
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [map, setMap] = useState<google.maps.Map>();
  const customControlsRef = useRef<HTMLDivElement>(null);
  const availableWidth = useAvailableWidth(540);
  const availableWidth1 = useAvailableWidth(200);
  const availableHeight = useAvailableHeight(420);
  const [data, setData] = useState<any>();

  const apiKey = "AIzaSyA9e7q3S_JVIT8Tz16ZjWD3EFO03XGGwJo";

  const libraries: Libraries = ["drawing", "geometry"];
  const [polygons, setPolygons] = useState<Array<google.maps.Polygon>>([]);

  // Center coordinates for India, zoom should be set to 5
  const center = {
    lat: 20.5937,
    lng: 78.9629,
  };

  const mapType = "HYBRID";

  // Map display styles currently not working, might need to enable this feature on map console
  const mapStyles: google.maps.MapTypeStyle[] = [
    {
      featureType: "all",
      elementType: "all",
      stylers: [
        { saturation: -10 }, // reduce saturation
        { lightness: 10 }, // increase lightness
      ],
    },
  ];

  // Style Mappings
  const STYLE_MAPPING = {
    strokeColor: "strokeClr",
    strokeOpacity: "strokeOpac",
    strokeWeight: "strokeWt",
    fillColor: "fillColor",
    fillOpacity: "fillOpac",
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const url = `${WORK_FLOW_BASE_URL}${assetGroupId}`;
    if (WORK_FLOW_BASE_URL) {
      fetchKmlData<KMLresponseType>(url, "en")
        .then((response) => {
          if (typeof response.message === "string" && response.message !== "") {
            setData(JSON.parse(response.message));
          }
        })
        .catch((err) => {
          handleError(err, setErrorDesc);
          setOpenErrorModal(true);
        });
    }
  };

  const onMapLoad = useCallback((map: google.maps.Map) => {
    map.setOptions({
      styles: mapStyles, // custom map style array
      mapTypeId: google.maps.MapTypeId[mapType],
    });
    setMap(map);
  }, []);

  // Map tiles loaded callback
  const onTilesLoaded = useCallback(() => {
    if (customControlsRef.current) {
      customControlsRef.current.style.display = "block";
    }
  }, []);

  useEffect(() => {
    if (!map || !data) return;

    clearPolygons(); // Clear old polygons

    const features = data.features;

    const bounds = new google.maps.LatLngBounds();

    const newPolygons = features?.map((feature: any) => {
      const coordinates = feature.geometry.coordinates[0][0].map(
        (coord: any) => {
          bounds.extend(new google.maps.LatLng(coord[1], coord[0]));
          return { lng: coord[0], lat: coord[1] };
        }
      );

      const properties = feature.properties;

      const style = {
        strokeColor:
          properties[STYLE_MAPPING.strokeColor] || DEFAULT_STYLE.strokeColor,
        strokeOpacity:
          properties[STYLE_MAPPING.strokeOpacity] ||
          DEFAULT_STYLE.strokeOpacity,
        strokeWeight:
          properties[STYLE_MAPPING.strokeWeight] || DEFAULT_STYLE.strokeWeight,
        fillColor:
          properties[STYLE_MAPPING.fillColor] || DEFAULT_STYLE.fillColor,
        fillOpacity:
          properties[STYLE_MAPPING.fillOpacity] || DEFAULT_STYLE.fillOpacity,
      };

      const polygon = new google.maps.Polygon({
        paths: coordinates,
        ...style,
      });

      polygon.setMap(map);

      return polygon;
    });

    if (!bounds.isEmpty()) {
      map.fitBounds(bounds);
    }

    setPolygons(newPolygons);
  }, [map, data]);

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: apiKey,
    libraries: libraries as Library[], // Ensure the drawing library is loaded
  });

  const browseFiles = () => {
    inputFileRef.current?.click();
  };

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (!file) {
      console.error("Invalid file or no file selected.");
      return;
    }

    toggleLoader(true);
    try {
      const response = await kmlUploadApi(file, "en", assetGroupId);
      setData(response); // Update the data state with new GeoJSON
      toggleLoader(false);
      parentCall(); // Callback to parent if necessary
      fetchData();
    } catch (err) {
      toggleLoader(false);
      handleError(err, setErrorDesc);
      setOpenErrorModal(true);
    }
  };

  const clearPolygons = () => {
    polygons?.forEach((polygon) => {
      polygon.setMap(null); // Remove polygon from map
    });
    setPolygons([]); // Clear the state
  };

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading...</div>;
  }
  return (
    <Box sx={{ maxWidth: "100%" }}>
      {openErrorModal && (
        <ErrorModal
          descriptionText={errorDesc}
          open={openErrorModal}
          handleClose={() => {
            setOpenErrorModal(false);
          }}
          onPositiveClick={() => {
            setOpenErrorModal(false);
          }}
        />
      )}
      <Box className="flex__justify__space-between mb-md">
        <MediumTypography
          label={`${assetGroupName} Details`}
          fontSize="20px"
          fontWeight={600}
        />
        <Box
          className="flex__ cursor__pointer"
          sx={{
            padding: "8px 12px",
            borderRadius: "4px",
            border: "1px solid rgba(166, 197, 226, 0.16)",
            marginRight: "8px",
            backgroundColor: "#2B3033",
            justifyContent: "flex-end",
          }}
          onClick={browseFiles}
        >
          <input
            ref={inputFileRef}
            type="file"
            accept=".geojson"
            style={{ display: "none", height: "36px" }}
            onChange={handleFileChange}
          />
          <Upload />
          <MediumTypography
            labelId="Upload.text"
            defaultLabel="Upload"
            className="ml-xs"
          />
        </Box>
      </Box>
      <Box
        sx={{
          width: isMenuOpen ? availableWidth : availableWidth1,
          height: availableHeight,
        }}
      >
        {data && (
          <GoogleMap
            key={data.features}
            mapContainerStyle={mapContainerStyle}
            center={center}
            onLoad={onMapLoad}
            onTilesLoaded={onTilesLoaded}
            zoom={5}
            options={{
              fullscreenControl: false,
              zoomControl: false,
            }}
          ></GoogleMap>
        )}

        {!data && (
          <Box
            className="position__relative"
            sx={{
              display: "flex", // Flex container
              flexDirection: "column", // Stack items vertically
              justifyContent: "center", // Center items vertically
              alignItems: "center", // Center items horizontally
              textAlign: "center", // Optional: Center the text
              top: "50%",
            }}
          >
            <NotAvialableIcon width={`50px`} height={`50px`} />
            <MediumTypography
              className="noWOTitle"
              labelId="NoKml.text"
              defaultLabel="Upload a KML file"
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default UploadKmlScreen;
