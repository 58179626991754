import React, { useEffect, useRef, useState } from "react";
import { Box, Tooltip } from "@mui/material";
import { ReactComponent as ScheduledStepperIcon } from "../../assets/images/ScheduledStepperIcon.svg";
import { ReactComponent as DispatchStepperIcon } from "../../assets/images/DispatchStepperIcon.svg";
import { ReactComponent as PendingStepperIcon } from "../../assets/images/PendingStepperIcon.svg";
import { ReactComponent as CompletedStepperIcon } from "../../assets/images/CompletedStepperIcon.svg";
import { ReactComponent as StatusStepperIcon } from "../../assets/images/StatusStepperIcon.svg";
import { ReactComponent as ClosedStepperIcon } from "../../assets/images/ClosedStepperIcon.svg";
import { ReactComponent as APPROVESTATUS } from "../../assets/images/APPROVESTATUS.svg";
import { ReactComponent as ONHOLDSTATUS } from "../../assets/images/ONHOLDSTATUS.svg";
import { ReactComponent as REJECTSTATUS } from "../../assets/images/REJECTSTATUS.svg";
import { ReactComponent as PAUSESTATUS } from "../../assets/images/PAUSESTATUS.svg";
import { ReactComponent as CrossIcon } from "../../assets/images/_CrossIcon_.svg";
import { ReactComponent as LoaderIcon } from "../../assets/images/LoaderIcon.svg";
import { ReactComponent as LockImage } from "../../assets/images/LockImage.svg";
import UserprofileIcon from "../../assets/images/profile-circle.svg";

import MediumTypography from "./MediumTypography";
import ButtonComponent from "./ButtonComponent";
import RevokeModal from "./modal/RevokeModal";
import {
  AssigneeType,
  ScheduleExecutionType,
  StatusDataProps,
  StatusDataType,
  StatusType,
  UpdateStatusType,
  updateWorkOrderStatus,
} from "../../api/moduleCleaning/ModuleCleaningApi";
import ErrorModal from "./modal/ErrorModal";
import { isCustomError } from "../../api/ApiResponseHandler";
import { ApiErrorResponse } from "../../api/UserApi/User";
import {
  GRASS_CUTTING_PLANNER,
  MODULE_CLEANING_PLANNER,
} from "../../routes/Routing";
import { useNavigate } from "react-router-dom";
import UpdateStatusModal from "./modal/UpdateStatusModal";
import dayjs from "dayjs";

const statusIcons: Record<
  StatusType | string,
  React.FunctionComponent<React.SVGProps<SVGSVGElement>>
> = {
  SCHEDULED: ScheduledStepperIcon,
  DISPATCHED: DispatchStepperIcon,
  INPROGRESS: PendingStepperIcon,
  COMPLETED: CompletedStepperIcon,
  REJECTED: REJECTSTATUS,
  PAUSED: PAUSESTATUS,
  CLOSED: ClosedStepperIcon,
  ONHOLD: ONHOLDSTATUS,
  REVOKE: StatusStepperIcon,
  PAUSEREQUESTED: PAUSESTATUS,
  ADDITIONALSTATUS: StatusStepperIcon,
  APPROVE: APPROVESTATUS,
  PENDING: StatusStepperIcon,
  LEVEL1: APPROVESTATUS,
  LEVEL2: APPROVESTATUS,
  LEVEL3: APPROVESTATUS,
  NEW: ScheduledStepperIcon,
  FINALRCA: APPROVESTATUS,
};

const additionalStatuses: ScheduleExecutionType = {
  APPROVE: ["Revoke"],
  CLOSED: [],
  REVOKE: ["Dispatch"],
  PENDING: ["Revoke"],
  ADDITIONALSTATUS: ["Completed"],
};

interface StatusExecutionProps {
  statusArray: StatusDataType[];
  cardId: string;
  WOUniqueId: number;
  definedScheduleStatus: StatusDataProps[];
  scheduleExecution?: { [key: string]: string[] };
  statusCallBack: () => void;
  version?: number;
  code: string;
  assetGroupId?: number;
  idType?: string;
  currentDate?: string;
  dateRange?: string;
  isRCARequired?: boolean;
  isTaskListCompleted?: boolean;
  isPartialOrFull?: boolean;
  actualsValue?: number;
}

const StatusExecution: React.FC<StatusExecutionProps> = ({
  statusArray,
  cardId,
  WOUniqueId,
  definedScheduleStatus,
  scheduleExecution,
  statusCallBack,
  version,
  code,
  assetGroupId,
  idType,
  currentDate,
  dateRange,
  isRCARequired,
  isTaskListCompleted,
  isPartialOrFull,
  actualsValue,
}) => {
  const [staticFlow, setStaticFlow] = useState<StatusDataProps[]>(
    definedScheduleStatus
  );
  const [dynamicFlow, setDynamicFlow] = useState<StatusDataProps[]>(
    definedScheduleStatus
  );
  const [statusDataFlow, setStatusDataFlow] =
    useState<StatusDataType[]>(statusArray);
  const [nextStatusOptions, setNextStatusOptions] = useState<string[]>([]);
  const [openRevokeModal, setOpenRevokeModel] = useState(false);
  const date = new Date();
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [errorDesc, setErrorDesc] = useState<string>("");
  const [showRejectError, setShowRejectError] = useState<boolean>(false);
  const [isLocked, setIsLocked] = useState<boolean>(false);
  const [currentFlow, setCurrentFlow] = useState<StatusDataProps[]>(
    definedScheduleStatus
  );
  const [openUpdateStatusModal, setOpenUpdateStatusModal] =
    useState<boolean>(false);
  const remarkRef = useRef<string>("");
  const currentStatusRef = useRef<string | StatusType>("");
  const currentFlowRef = useRef<StatusDataProps>();
  const [isLockRequired, setIsLockRequired] = useState<boolean>(false);

  useEffect(() => {
    setStatusDataFlow(statusArray);
  }, [
    statusArray,
    cardId,
    WOUniqueId,
    version,
    definedScheduleStatus,
    scheduleExecution,
    statusCallBack,
    code,
    assetGroupId,
    idType,
    currentDate,
    dateRange,
  ]);

  const navigate = useNavigate();

  const ScheduleExecution = {
    ...scheduleExecution,
    ...additionalStatuses,
  };

  useEffect(() => {
    const updatedFlow = statusDataFlow.map((status, index) => ({
      id: index + 1,
      code: status.code as StatusType | string,
      name: status.status as StatusType | string,
      isUpdatedStatus: true,
      transitionDateTime: status.transitionDateTime,
      duration: status.duration,
      assignee: status.name, // Changed to status.name.name to access the name property of the nested object
      remarks: status.remarks ? status.remarks : "",
    }));

    const lastScheduledIndexCycle = updatedFlow
      .map((status, index) => ({
        code: status.code,
        index: index,
        transitionDateTime: status.transitionDateTime,
      }))
      .filter(
        (status) =>
          status.code === "SCHEDULED" && status.transitionDateTime !== null
      )
      .map((status) => status.index)
      .pop(); // Get the last index from the filtered array

    // Slice updatedFlow from the last "SCHEDULED" index
    let lastCycle = updatedFlow;
    if (lastScheduledIndexCycle !== -1) {
      lastCycle = updatedFlow.slice(lastScheduledIndexCycle);
    }

    // Add missing statuses from definedScheduleStatus to updatedFlow
    for (let i = 0; i < definedScheduleStatus.length; i++) {
      const currentStatus = definedScheduleStatus[i];
      const isAlreadyInLastCycle = lastCycle.find(
        (flow) => flow.code === currentStatus.code
      );

      if (!isAlreadyInLastCycle) {
        updatedFlow.push(currentStatus);
      }
    }

    setDynamicFlow(updatedFlow);
    let lastScheduledIndex = -1;

    for (let i = 0; i < updatedFlow.length; i++) {
      if (
        (updatedFlow[i].code === "SCHEDULED" ||
          updatedFlow[i].code === "NEW") &&
        updatedFlow[i].transitionDateTime !== null
      ) {
        lastScheduledIndex = i;
      }
    }

    if (lastScheduledIndex !== -1) {
      const latestCycle = updatedFlow.slice(lastScheduledIndex);
      setCurrentFlow(latestCycle);
      setStaticFlow(latestCycle);
    }

    const scheduledCount = updatedFlow.filter(
      (status) => status.code === "SCHEDULED"
    ).length;
    const newCount = updatedFlow.filter(
      (status) => status.code === "NEW"
    ).length;

    // Apply conditions based on counts
    if (scheduledCount > 1 || newCount > 1) {
      setIsLockRequired(true);
    } else {
      setIsLockRequired(false);
    }
  }, [
    statusArray,
    cardId,
    WOUniqueId,
    version,
    definedScheduleStatus,
    scheduleExecution,
    statusCallBack,
    code,
    assetGroupId,
    idType,
    currentDate,
    dateRange,
    statusDataFlow,
    isLockRequired,
  ]);

  const handleRejectionTypeChange = (val: string, remarks: string) => {
    if (val === "true") {
      if (code === "MC") {
        navigate(MODULE_CLEANING_PLANNER, {
          state: {
            cardId: cardId,
            assetGroupId: assetGroupId,
            idType: idType,
            date: currentDate,
            dateRange: dateRange,
            viewMode: "rejection",
          },
        });
      } else if (code === "GC") {
        navigate(GRASS_CUTTING_PLANNER, {
          state: {
            cardId: cardId,
            assetGroupId: assetGroupId,
            idType: idType,
            date: currentDate,
            dateRange: dateRange,
            viewMode: "rejection",
          },
        });
      }
    } else {
      setShowRejectError(!showRejectError);
      remarkRef.current = remarks;
      updateStatusApi("Reject", {
        id: 5,
        code: "REJECTED",
        name: "",
        isUpdatedStatus: false,
        transitionDateTime: "",
        duration: "",
        assignee: {} as AssigneeType,
        remarks: remarks,
      });
    }
  };

  const findLastStatus = () => {
    if (statusDataFlow.length > 0) {
      return statusDataFlow[statusDataFlow.length - 1].code;
    }
    return "SCHEDULED";
  };

  useEffect(() => {
    if (scheduleExecution) {
      const lastStatus = findLastStatus();
      setNextStatusOptions(ScheduleExecution[lastStatus] || []);
    }
  }, [statusDataFlow]);

  const handleButtonClick = (
    status: string | StatusType,
    flow: StatusDataProps
  ) => {
    if (
      actualsValue === 0 &&
      (code === "MC" || code === "GC") &&
      flow.code === "INPROGRESS"
    ) {
      setOpenErrorModal(!openErrorModal);
      setErrorDesc("Actual Tables are Missing");
    } else if (
      statusArray[statusArray.length - 2].code !== "PAUSEREQUESTED" &&
      status === "Reject" &&
      (code === "MC" || code === "GC")
    ) {
      setShowRejectError(!showRejectError);
      setOpenUpdateStatusModal(!openUpdateStatusModal);
      currentFlowRef.current = flow;
      currentStatusRef.current = status;
    } else if (
      (code === "MC" || code === "GC") &&
      status === "Reject" &&
      statusArray[statusArray.length - 2].code === "PAUSEREQUESTED"
    ) {
      currentFlowRef.current = flow;
      currentStatusRef.current = status;
      setOpenUpdateStatusModal(!openUpdateStatusModal);
    } else if (
      status === "Mark On Hold" ||
      status === "Reject" ||
      status === "Pause" ||
      status === "Approve"
    ) {
      currentFlowRef.current = flow;
      currentStatusRef.current = status;
      setOpenUpdateStatusModal(!openUpdateStatusModal);
    } else {
      updateStatusApi(status, flow);
    }
  };

  function convertDuration(duration: string) {
    const [hours, minutes] = duration.split(":").map(Number);
    // Convert hours and minutes to total minutes
    const totalMinutes = hours * 60 + minutes;
    // Convert total minutes to days, hours, and minutes
    const days = Math.floor(totalMinutes / (24 * 60));
    const remainingMinutes = totalMinutes % (24 * 60);
    const newHours = Math.floor(remainingMinutes / 60);
    const newMinutes = remainingMinutes % 60;

    return `${days}d:${String(newHours).padStart(2, "0")}h:${String(
      newMinutes
    ).padStart(2, "0")}m`;
  }

  const updateStatusApi = (
    status: string | StatusType,
    flow: StatusDataProps
  ) => {
    const statusMap: { [key: string]: string } = {
      "Execute WO": "INPROGRESS",
      "Mark As Completed": "COMPLETED",
      "Mark On Hold": "ONHOLD",
      Reject: "REJECTED",
      Pause: "PAUSEREQUESTED",
      New: "NEW",
      RCA: "FINALRCA",
    };

    let statusValue: string | StatusType = statusMap[status] || "";

    const pauseRequestedIndex = staticFlow.findIndex(
      (pauseRequestedFlow) => pauseRequestedFlow.code === "PAUSEREQUESTED"
    );
    if (status === "Reject" && pauseRequestedIndex !== -1) {
      statusValue = "PAUSEREJECTED";
    }

    if (status === "Approve") {
      const indexMap = {
        LEVEL1: staticFlow.findIndex(
          (level1Flow) => level1Flow.code === "LEVEL1"
        ),
        LEVEL2: staticFlow.findIndex(
          (level2Flow) => level2Flow.code === "LEVEL2"
        ),
        LEVEL3: staticFlow.findIndex(
          (level3Flow) => level3Flow.code === "LEVEL3"
        ),
        PAUSED: staticFlow.findIndex(
          (paused) => paused.code === "PAUSEREQUESTED"
        ),
      };

      if (indexMap.LEVEL3 !== -1) {
        statusValue = "LEVEL3";
      } else if (indexMap.LEVEL2 !== -1) {
        statusValue = "LEVEL2";
      } else if (indexMap.LEVEL1 !== -1) {
        statusValue = "LEVEL1";
      } else if (indexMap.PAUSED !== -1) {
        statusValue = "PAUSED";
      }
    }

    const userId = Number(localStorage.getItem("id"));

    let obj: UpdateStatusType = {
      code: statusValue,
      approver1: flow.code === "LEVEL1" ? userId : 0,
      approver2: flow.code === "LEVEL2" ? userId : 0,
      approver3: flow.code === "LEVEL3" ? userId : 0,
      peformedBy: userId,
      remarks: remarkRef.current,
      isRCARequired: status === "RCA" ? isRCARequired : false,
    };

    if (status !== "Revoke") {
      updateWorkOrderStatus(JSON.parse(cardId), WOUniqueId, obj)
        .then((res) => {
          setStatusDataFlow(res);
          if (code === "PM" || code === "CM") {
            statusCallBack();
          } else if (
            statusValue === "COMPLETED" ||
            statusValue === "LEVEL1" ||
            statusValue === "LEVEL2" ||
            statusValue === "LEVEL3"
          ) {
            statusCallBack();
          }
        })
        .catch((err) => {
          setOpenErrorModal(true);
          setErrorDesc(
            isCustomError(err)
              ? (err as ApiErrorResponse).issue[0].diagnostics
              : err.id
          );
        });
    } else {
      setOpenRevokeModel(!openRevokeModal);
    }
  };

  const normalizeStatusName = (name: string) => {
    return name.replace(/\s+/g, "").toUpperCase();
  };

  return (
    <>
      {openRevokeModal && (
        <RevokeModal
          open={openRevokeModal}
          handleClose={() => {
            setOpenRevokeModel(!openRevokeModal);
          }}
          sequenceId={WOUniqueId}
          cardId={JSON.parse(cardId)}
          version={version ? version : 0}
          statusCode={statusDataFlow[statusDataFlow.length - 2]?.code}
          status={statusDataFlow[statusDataFlow.length - 2]?.status}
          requestedDate={date}
          revokeCallBack={statusCallBack}
        />
      )}
      {openUpdateStatusModal && (
        <UpdateStatusModal
          open={openUpdateStatusModal}
          code={code}
          showRejectError={showRejectError}
          handleClose={() => {
            setOpenUpdateStatusModal(!openUpdateStatusModal);
            setShowRejectError(false);
          }}
          updateRemark={(value) => {
            remarkRef.current = value;
          }}
          rejectionCallBack={(value, remarksMessage) => {
            handleRejectionTypeChange(value, remarksMessage);
          }}
          updateCallBack={() => {
            if (currentFlowRef?.current) {
              updateStatusApi(currentStatusRef.current, currentFlowRef.current);
            }
          }}
        />
      )}
      {openErrorModal && (
        <ErrorModal
          descriptionText={errorDesc}
          open={openErrorModal}
          handleClose={() => setOpenErrorModal(false)}
          onPositiveClick={() => {
            setOpenErrorModal(false);
          }}
        />
      )}

      <Box
        className=""
        sx={{
          display: "grid",
          padding: "16px 0px",
          width: "100%",
          height: "100%",
          overflowX: "auto",
          WebkitOverflowScrolling: "touch",
          "-webkit-overflow-scrolling": "touch", // iOS specific - enables momentum scrolling
          "scroll-behavior": "smooth", // Optional: Adds smooth scrolling behavior
          "&::-webkit-scrollbar": {
            display: "none", // Hide scrollbar on Chrome, Safari, and Opera
          },
        }}
      >
        <Box sx={{ display: "flex", overflowX: "auto" }}>
          {isLockRequired && !isLocked && (
            <Box
              className="cursor__pointer"
              sx={{
                width: "40px",
                minWidth: "40px",
                height: "100px",
                borderRadius: "4px",
                border: "1px solid rgba(166, 197, 226, 0.16)",
                alignItems: "center",
                alignContent: "center",
                textAlign: "center",
              }}
              onClick={() => {
                setIsLocked(!isLocked);
                setStaticFlow(dynamicFlow);
              }}
            >
              {!isLocked && <LockImage style={{ position: "relative" }} />}
            </Box>
          )}
          {isLocked && (
            <Box
              className="cursor__pointer"
              sx={{
                width: "40px",
                minWidth: "40px",
                height: "100px",
                borderRadius: "4px",
                border: "1px solid rgba(166, 197, 226, 0.16)",
                alignItems: "center",
                alignContent: "center",
                textAlign: "center",
              }}
              onClick={() => {
                setIsLocked(!isLocked);
                setStaticFlow(currentFlow);
              }}
            >
              {isLocked && <CrossIcon style={{ position: "relative" }} />}
            </Box>
          )}

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              overflowX: "auto", // Enable horizontal scrolling
              WebkitOverflowScrolling: "touch", // Enable smooth scrolling on touch devices
              maxHeight: "calc(6 * 48px)", // Assuming each status box is about 48px tall, adjust if needed
              "-ms-overflow-style": "none", // Hide scrollbar on IE and Edge
              scrollbarWidth: "none", // Hide scrollbar on Firefox
              "&::-webkit-scrollbar": {
                display: "none", // Hide scrollbar on Chrome, Safari, and Opera
              },
              justifyContent: "space-between",
              "-webkit-overflow-scrolling": "touch", // iOS specific - enables momentum scrolling
              "scroll-behavior": "smooth", // Optional: Adds smooth scrolling behavior
            }}
          >
            {staticFlow.map((flow, index) => {
              const normalizedStatusName = normalizeStatusName(flow.code);
              const StepIcon = statusIcons[normalizedStatusName as StatusType];

              const textColor =
                flow.name === "Pending"
                  ? "#9E9E9E"
                  : flow.code === "REJECTED"
                  ? "rgba(217, 31, 17, 1)"
                  : flow.transitionDateTime &&
                    flow.code !== "ONHOLD" &&
                    flow.code !== "PAUSED" &&
                    flow.code !== "PAUSEREQUESTED"
                  ? "#20C374"
                  : flow.code === "ONHOLD"
                  ? "#FF9F0A"
                  : flow.code === "PAUSED"
                  ? "#006aff"
                  : flow.code === "PAUSEREQUESTED"
                  ? "#006aff"
                  : "#9E9E9E";
              const iconFill =
                flow.name === "Pending"
                  ? "#9E9E9E"
                  : flow.code === "REJECTED"
                  ? "rgba(217, 31, 17, 1)"
                  : flow.transitionDateTime
                  ? "#20C374"
                  : "#9E9E9E";

              const isFirstScheduled =
                (flow.code === "SCHEDULED" || flow.code === "NEW") &&
                flow.transitionDateTime !== null &&
                index > 0;

              const isSecondScheduled =
                (flow.code === "SCHEDULED" || flow.code === "NEW") &&
                flow.transitionDateTime !== null &&
                index > 0;
              const nextStepData = staticFlow[index + 1];

              return (
                <React.Fragment key={index}>
                  <Box
                    className="position__relative"
                    sx={{
                      left: "20px",
                      display: "flex",
                      minWidth:
                        index === staticFlow.length - 1 ? "190px" : "auto",
                      flexDirection: "column",
                      borderLeft:
                        isSecondScheduled || isFirstScheduled
                          ? "1px solid #E0E0E0"
                          : "none",
                      paddingLeft:
                        isSecondScheduled || isFirstScheduled ? "20px" : "0", // Indent after divider
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Tooltip
                        title={
                          flow.remarks !== "" || flow.remarks !== null
                            ? flow.remarks
                            : ""
                        }
                      >
                        <Box sx={{ width: "28px", height: "28px" }}>
                          {flow.code === "REJECTED" ? (
                            <REJECTSTATUS width={28} height={28} />
                          ) : (
                            <StepIcon fill={iconFill} width={28} height={28} />
                          )}
                        </Box>
                      </Tooltip>
                      {index >= 0 && index < staticFlow.length - 1 && (
                        <>
                          <Box
                            sx={{
                              height: "2px",
                              backgroundColor: flow?.transitionDateTime
                                ? "rgba(32, 195, 116, 1)"
                                : flow.code === "REJECTED"
                                ? "rgba(217, 31, 17, 1)"
                                : "#9E9E9E",
                              width: "100%",
                              minWidth: "200px",
                              margin: "16px 45px",
                            }}
                          >
                            <MediumTypography
                              label={
                                nextStepData?.duration
                                  ? convertDuration(nextStepData?.duration)
                                  : nextStepData?.duration
                              }
                              textColor={"rgba(159, 173, 188, 1)"}
                              fontSize="11px"
                              fontWeight={500}
                              sxProps={{
                                margin: "5px 0px",
                                textAlign: "center",
                              }}
                            />
                          </Box>
                        </>
                      )}
                    </Box>
                    <Box>
                      {flow.name !== "Pending" && (
                        <MediumTypography
                          label={flow.name}
                          textColor={textColor}
                          fontSize="10px"
                          fontWeight={500}
                        />
                      )}
                      {flow?.transitionDateTime === null && (
                        <MediumTypography
                          labelId="StatusText"
                          defaultLabel="Status"
                          textColor={textColor}
                          fontSize="10px"
                          fontWeight={500}
                        />
                      )}
                      {flow?.transitionDateTime === null && (
                        <Box className="flex__">
                          <LoaderIcon
                            className="position__relative"
                            style={{ top: "2px" }}
                          />
                          <MediumTypography
                            labelId="PendingText"
                            defaultLabel="Pending"
                            className="ml-sm"
                            textColor="#FFFFFF"
                            fontSize="14px"
                            fontWeight={400}
                          />
                        </Box>
                      )}
                    </Box>
                    {flow?.transitionDateTime && (
                      <Box>
                        <MediumTypography
                          label={`${dayjs(flow.transitionDateTime).format(
                            "DD MMMM YYYY"
                          )} ${dayjs(flow.transitionDateTime).format("HH:mm")}`}
                          textColor="#9FADBC"
                          fontSize="14px"
                          fontWeight={500}
                        />
                      </Box>
                    )}
                    {flow?.assignee && (
                      <Box
                        className="flex__ position__relative"
                        sx={{
                          right: "6px",
                        }}
                      >
                        <div
                          style={{
                            width: "36px",
                            height: "36px",
                            backgroundImage: flow.assignee?.image
                              ? `url(${flow.assignee?.image})`
                              : `url(${UserprofileIcon})`, // Use imported image
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            borderRadius: "50%",
                          }}
                        ></div>
                        <MediumTypography
                          label={flow.assignee.name}
                          className="ml-sm"
                          textColor="#888888"
                          fontSize="14px"
                          fontWeight={700}
                          sxProps={{ alignContent: "center" }}
                        />
                      </Box>
                    )}
                    <Box
                      sx={{
                        marginTop: "8px",
                      }}
                    >
                      {flow.transitionDateTime !== null &&
                        !flow.isUpdatedStatus && (
                          <Box
                            sx={{
                              width: "110px",
                              height: "8px",
                              borderRadius: "4px",
                              position: "relative",
                              padding: "0px 8px",
                              gap: "8px",
                              marginBottom: "8px",
                              background:
                                "linear-gradient(to right, rgba(158, 158, 158, 0.32), rgba(56, 56, 56, 0))",
                            }}
                          />
                        )}
                      {flow.transitionDateTime !== null &&
                        !flow.isUpdatedStatus && (
                          <Box
                            sx={{
                              width: "70px",
                              height: "5px",
                              borderRadius: "4px",
                              padding: "2px 8px",
                              position: "relative",
                              gap: "8px",
                              background:
                                "linear-gradient(to right, rgba(158, 158, 158, 0.32), rgba(56, 56, 56, 0))",
                            }}
                          />
                        )}
                    </Box>
                    <Box sx={{ marginTop: "0px" }}>
                      <div>
                        {flow?.transitionDateTime === null &&
                          nextStatusOptions.map(
                            (nextStatus, ind) =>
                              nextStatus !== "Dispatch" && (
                                <ButtonComponent
                                  key={ind}
                                  variantType={
                                    nextStatus !== "Mark On Hold"
                                      ? "contained"
                                      : "outlined"
                                  }
                                  sxProps={{
                                    margin: "0 8px",
                                    fontFamily: "Inter",
                                    fontSize: "12px",
                                    borderRadius: "3px",
                                    border: "none",
                                    padding: "0px 12px",
                                    textTransform: "none",
                                    color:
                                      nextStatus === "Mark On Hold"
                                        ? "rgba(245, 197, 24, 1)"
                                        : "#FFFFFF",
                                    backgroundColor:
                                      nextStatus === "Mark On Hold"
                                        ? "rgba(225, 181, 22, 0.12)"
                                        : "#175FFA",
                                    "&:hover": {
                                      backgroundColor:
                                        nextStatus === "Mark On Hold"
                                          ? "rgba(225, 181, 22, 0.12)"
                                          : "#175FFA",
                                      border: "none",
                                      textTransform: "none",
                                    },
                                    "&:disabled": {
                                      color: "white",
                                    },
                                  }}
                                  disabled={
                                    nextStatus === "Mark As Completed" &&
                                    flow.code === "INPROGRESS" &&
                                    (code === "PM" || code === "CM") &&
                                    !isTaskListCompleted
                                  }
                                  label={nextStatus}
                                  onClick={() =>
                                    handleButtonClick(
                                      nextStatus as StatusType,
                                      flow
                                    )
                                  }
                                />
                              )
                          )}
                      </div>
                    </Box>
                  </Box>
                </React.Fragment>
              );
            })}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default StatusExecution;
