// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.messageScroll::-webkit-scrollbar,
.fc-scroller::-webkit-scrollbar {
  height: 5px;
  width: 4px;
  margin-left: 10px;
}

.messageScroll::-webkit-scrollbar-track,
.fc-scroller::-webkit-scrollbar-track {
  background-color: transparent;
}

.messageScroll::-webkit-scrollbar-thumb,
.fc-scroller::-webkit-scrollbar-thumb {
  background-color: rgba(12, 102, 228, 0.6);
  border-radius: 10px;
  border: 1px solid rgba(12, 102, 228, 0.6);
  background-clip: content-box;
  margin-left: 10px;
}

.messageScroll::-webkit-scrollbar-thumb:hover,
.fc-scroller::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}
`, "",{"version":3,"sources":["webpack://./src/pages/workOrderDetailScreens/WorkOrderExecution.css"],"names":[],"mappings":"AAAA;;EAEE,WAAW;EACX,UAAU;EACV,iBAAiB;AACnB;;AAEA;;EAEE,6BAA6B;AAC/B;;AAEA;;EAEE,yCAAyC;EACzC,mBAAmB;EACnB,yCAAyC;EACzC,4BAA4B;EAC5B,iBAAiB;AACnB;;AAEA;;EAEE,yBAAyB;AAC3B","sourcesContent":[".messageScroll::-webkit-scrollbar,\n.fc-scroller::-webkit-scrollbar {\n  height: 5px;\n  width: 4px;\n  margin-left: 10px;\n}\n\n.messageScroll::-webkit-scrollbar-track,\n.fc-scroller::-webkit-scrollbar-track {\n  background-color: transparent;\n}\n\n.messageScroll::-webkit-scrollbar-thumb,\n.fc-scroller::-webkit-scrollbar-thumb {\n  background-color: rgba(12, 102, 228, 0.6);\n  border-radius: 10px;\n  border: 1px solid rgba(12, 102, 228, 0.6);\n  background-clip: content-box;\n  margin-left: 10px;\n}\n\n.messageScroll::-webkit-scrollbar-thumb:hover,\n.fc-scroller::-webkit-scrollbar-thumb:hover {\n  background-color: #a8bbbf;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
