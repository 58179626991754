import { Box } from "@mui/material";

const BorderLine = () => {
  return (
    <Box className="border-line-parent">
      <Box className="border-line-parent-child"></Box>
    </Box>
  );
};

export default BorderLine;
