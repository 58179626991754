import { ReassignAssestType } from "../../../pages/ReassignModalPopup";
import {
  ShiftDashboardType,
  ShiftsCardType,
  TeamViewDashboardType,
} from "../../../utils/type";
import { ApiResponseHandler } from "../../ApiResponseHandler";
import WorkOrderConfig from "./../../WorkOrderConfig";
import { AssigneeType } from "../../moduleCleaning/ModuleCleaningApi";
import moment from "moment";
import {
  AssignedUsers,
  CMSetType,
} from "../../../pages/workOrderDetailScreens/correctiveMaintenance/SetAssetsAssignee";

export interface SchedulerDashboardParams {
  selectedSiteId?: number;
  selectedSiteName?: string;
  selectedSubgroupId?: number;
  selectedSubgroupName?: string;
  selectedShiftIds?: number[] | null;
  selectedAssetIds?: number[] | null;
  selectedAssetGroupIds?: number[] | null;
  selectedMemberIds?: number[] | null;
  selectedWoCategoryIds: number[] | null;
  selectedAssetCategoryIds: number[] | null;
  selectedStatusIds: number[] | null;
  selectedDate: string;
  startDate?: Date;
  endDate?: Date;
}

export interface DispatchWorkOrderParams {
  dispatchDate: Date | string;
  isFirstTimeDispatch: number;
  includeWarningAndDispatch: number;
  shifts: ShiftsCardType[];
}

export interface SwapMembersParams {
  firstMember: number;
  secondMember: number;
  subGroupId: number;
  shiftId: number[];
  swapOn: Date | string;
}

export interface MoveWorkOrderParams {
  oldMemberId: number;
  newMemberId: number;
  woUniqueId: number;
  shiftId: number;
  startTime: string;
  endTime: string;
  currentDate: string;
  version: number;
}

export interface RevokeApiProps {
  uniqueId: number;
  requestedDate: Date | string;
  comments: string;
  version: number;
}

export interface ReassignMembersProps {
  woUniqueId: number;
  shiftId: number;
  startTime: string;
  endTime: string;
  currentDate: string;
  reassignedUsers: AssignedUsers[];
  version: number;
}

export interface MoveScheduleOrWorkOrderProps {
  uniqueId: number;
  moveTo: string;
  moveFrom: string;
  comment: string;
  version: number;
}

export interface ReAssignResponse {
  processCode: string;
}

export interface MoveScheduleCard {
  woUniqueId: number;
  shiftId: number;
  startTime: string;
  endTime: string;
  currentDate: string;
  version: number;
}
export type CardMovementActionType =
  | "MOVE_WO_VERTICAL"
  | "MOVE_WO_HORIZONTAL"
  | "MOVE_THROUGH_SHIFTS";

export interface RevokeDetails {
  member: AssigneeType;
  remarks: string;
}

export const getSchedulerDetailsApi = async (
  type: string,
  params: SchedulerDashboardParams
) => {
  return ApiResponseHandler(
    await WorkOrderConfig.getInstance().post<
      SchedulerDashboardParams,
      ShiftDashboardType
    >(`workorder/cardDetails/shifts?period=Day&type=${type}`, params)
  );
};

export const dispatchWorkOrderApi = async (
  siteId: number,
  subGroupId: number,
  params: DispatchWorkOrderParams
) => {
  return ApiResponseHandler(
    await WorkOrderConfig.getInstance().post<
      DispatchWorkOrderParams,
      DispatchWorkOrderParams
    >(
      `/schedule/dispatch?siteId=${siteId}&subGroupId=${subGroupId}&language=en`,
      params
    )
  );
};

export const getWorkOrderCardDetailsTeamApi = async (
  params: SchedulerDashboardParams
) => {
  return ApiResponseHandler(
    await WorkOrderConfig.getInstance().post<
      SchedulerDashboardParams,
      TeamViewDashboardType
    >(`workorder/cardDetails/teams?period=Day&type=WORKORDER`, params)
  );
};

export const swapMembersApi = async (
  params: SwapMembersParams,
  warningFlag = false,
  period = "Day"
) => {
  return ApiResponseHandler(
    await WorkOrderConfig.getInstance().put<
      SwapMembersParams,
      SwapMembersParams
    >(
      `workorder/swap/members?warningFlag=${warningFlag}&period=${period}`,
      params
    )
  );
};

export const moveWorkOrderApi = async (
  params: MoveWorkOrderParams,
  cardId: number,
  action: CardMovementActionType,
  period = "Day"
) => {
  return ApiResponseHandler(
    await WorkOrderConfig.getInstance().put<
      MoveWorkOrderParams,
      MoveWorkOrderParams
    >(`workorder/${cardId}/move?period=${period}&action=${action}`, params)
  );
};

export const revokeScheduleApi = async (
  cardId: number,
  params: RevokeApiProps
) => {
  return ApiResponseHandler(
    await WorkOrderConfig.getInstance().put<RevokeApiProps, RevokeApiProps>(
      `workorder/${cardId}/revoke?period=Day`,
      params
    )
  );
};

export const reAssignMembers = async (
  cardId: number,
  warning: boolean,
  params: ReassignMembersProps,
  cardType: "schedule" | "workorder" = "schedule"
) => {
  return ApiResponseHandler(
    await WorkOrderConfig.getInstance().put<
      ReassignMembersProps,
      ReAssignResponse
    >(
      `${cardType}/${cardId}/reassignTeamMembers?period=Day&warningFlag=${warning}`,
      params
    )
  );
};

export const moveScheduleOrWorkOrderApi = async (
  cardId: number,
  action: "PREPONE" | "POSTPONE",
  type: string,
  params: MoveScheduleOrWorkOrderProps
) => {
  return ApiResponseHandler(
    await WorkOrderConfig.getInstance().put<
      MoveScheduleOrWorkOrderProps,
      MoveScheduleOrWorkOrderProps
    >(
      `workorder/${cardId}/moveByDate?period=Day&action=${action}&type=${type}`,
      params
    )
  );
};

export const moveScheduleCardsApi = async (
  cardId: number,
  action: "MOVE_WO_VERTICAL" | "MOVE_THROUGH_SHIFTS",
  params: MoveScheduleCard
) => {
  return ApiResponseHandler(
    await WorkOrderConfig.getInstance().put<MoveScheduleCard, MoveScheduleCard>(
      `schedule/${cardId}/move?period=Day&action=${action}`,
      params
    )
  );
};

//reassignPOpup api
export interface ReassignRequest {
  woUniqueId: number;
  shiftId: number;
  version: number;
  currentDate: string;
  sets: CMSetType[];
}

export const getAssetSetAssignee = async (
  cardId: number,
  assetGroupId: number[],
  cardType: "schedule" | "workorder" = "schedule",
  date: string = moment().format("YYYY-MM-DD")
): Promise<ReassignAssestType> => {
  const assetGroupIdQueryParam = assetGroupId
    .map((id) => `assetGroupId=${id}`)
    .join("&");
  return ApiResponseHandler(
    await WorkOrderConfig.getInstance().get(
      `${cardType}/${cardId}/assetSetAssignee?selectedDate=${date}&${assetGroupIdQueryParam}`
    )
  );
};

export const reassignSetAssetMembers = async (
  cardId: number,

  warningFlag: boolean,
  params: ReassignRequest,
  cardType: "schedule" | "workorder" = "schedule"
) => {
  return ApiResponseHandler(
    await WorkOrderConfig.getInstance().put<ReassignRequest, ReAssignResponse>(
      `${cardType}/${cardId}/reassignSetAssetMembers?period=Day&warningFlag=${warningFlag}`,
      params
    )
  );
};

export const getRevokeDetails = async (
  cardId: number,
  woUniqueId: number
): Promise<RevokeDetails> => {
  return ApiResponseHandler(
    await WorkOrderConfig.getInstance().get(
      `workorder/${cardId}/remarks?woUniqueId=${woUniqueId}`
    )
  );
};

interface ReportGenerationResponse {
  status: string | null;
  message: string;
  encodedS3Url: string | null;
}
export const generateReport = async (
  cardId: number
): Promise<ReportGenerationResponse> => {
  return ApiResponseHandler(
    await WorkOrderConfig.getInstance().get(
      `workorder/${cardId}/generateReport`
    )
  );
};
