import { Avatar, Box, Tooltip } from "@mui/material";
import React, { FC } from "react";
import MediumTypography from "../../components/formlib/MediumTypography";
import { AssigneeType } from "../../api/moduleCleaning/ModuleCleaningApi";

export interface WorkOrderAssigneeProps {
  leadAssignee?: AssigneeType;
  assignees?: AssigneeType[];
  onChangeRequest?: () => void;
}

const WorkOrderAssignees: FC<WorkOrderAssigneeProps> = ({
  leadAssignee,
  assignees,
  onChangeRequest,
}) => {
  return (
    <Box sx={{ padding: "24px 0px 24px 0px" }}>
      <Box sx={{ marginBottom: "20px" }} display={"flex"} gap={"10px"}>
        <MediumTypography
          labelId="AssigneeLabelText"
          defaultLabel="Assignee"
          fontSize="20px"
          fontWeight={500}
          textColor="#FFFFFF"
        />
        {onChangeRequest && (
          <MediumTypography
            labelId="pm.changeText"
            defaultLabel="Change"
            fontSize="10px"
            fontWeight={500}
            textColor="rgba(37, 187, 250, 1)"
            sxProps={{ cursor: "pointer", mt: "12px" }}
            onClick={onChangeRequest}
          />
        )}
      </Box>

      <Box className="flex__">
        {leadAssignee && (
          <Box
            className="flex__"
            sx={{
              width: "146px",
              height: "56px",
              borderRadius: "4px",
              border: "1px solid #175FFA",
              backgroundColor: "rgba(23, 95, 250, 0.2)",
              marginRight: "8px",
            }}
          >
            <Box
              className="flex__ align__items__center ml-xs"
              sx={{ position: "relative" }}
            >
              <Avatar
                src={leadAssignee.image}
                alt="abc"
                style={{ width: "32px", height: "32px" }}
              />
            </Box>
            <Box className=" pt-sm">
              <Box className="flex__justify__space-between">
                <Box>
                  <Tooltip
                    title={
                      leadAssignee.name && leadAssignee.name.length > 15
                        ? leadAssignee.name
                        : ""
                    }
                    arrow={true}
                    followCursor
                  >
                    <Box>
                      <MediumTypography
                        label={
                          leadAssignee.name === "" || leadAssignee.name === null
                            ? "-"
                            : leadAssignee.name
                        }
                        className="ml-xs ellipsis"
                        fontSize="12px"
                        sxProps={{
                          width:
                            leadAssignee.name.length > 15 ? "100px" : "auto",
                        }}
                      />
                    </Box>
                  </Tooltip>
                  <MediumTypography
                    label={leadAssignee.title}
                    className=" ml-xs ellipsis"
                    textColor="rgba(255, 255, 255, 0.64)"
                    fontSize="10px"
                    fontWeight={400}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        )}

        {assignees && assignees.length > 0 && (
          <Box
            className="flex__ ml-xlg"
            sx={{
              width: "auto",
              height: "56px",
              borderRadius: "4px",
              padding: "4px",
              border: "1px solid rgba(166, 197, 226, 0.16)",
            }}
          >
            {assignees.map((assignee, index) => (
              <React.Fragment key={assignee.id}>
                <Box className="flex__">
                  <Box
                    className="flex__ align__items__center ml-xs"
                    sx={{ position: "relative" }}
                  >
                    <Avatar
                      src={assignee.image}
                      alt="abc"
                      style={{ width: "32px", height: "32px" }}
                    />
                  </Box>
                  <Box className=" pt-sm">
                    <Box className="flex__justify__space-between">
                      <Box>
                        <MediumTypography
                          label={assignee.name}
                          className="ml-xs ellipsis"
                          fontSize="12px"
                          textColor="#FFFFFF"
                        />
                        <MediumTypography
                          label={assignee.title}
                          className=" ml-xs ellipsis"
                          textColor="rgba(255, 255, 255, 0.64)"
                          fontSize="10px"
                          fontWeight={400}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
                {index < assignees.length - 1 && (
                  <Box
                    className="ml-md"
                    sx={{
                      width: "0px",
                      height: "28px",
                      borderLeft: "1px solid rgba(166, 197, 226, 0.16)",
                      padding: "4px 0px",
                      position: "relative",
                      top: "10px",
                    }}
                  ></Box>
                )}
              </React.Fragment>
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default WorkOrderAssignees;
