import {
  Box,
  Dialog,
  DialogContent,
  Grid,
  TextField,
  DialogTitle,
} from "@mui/material";
import React, { FC, useState } from "react";
import MediumTypography from "../MediumTypography";
import ButtonComponent from "../ButtonComponent";
import RadioComponent from "../RadioComponent";

interface UpdateStatusModalProps {
  open: boolean;
  handleClose: () => void;
  updateRemark: (value: string) => void;
  updateCallBack: () => void;
  code?: string;
  showRejectError?: boolean;
  rejectionCallBack: (value: string, remarks: string) => void;
}

const UpdateStatusModal: FC<UpdateStatusModalProps> = ({
  open,
  handleClose,
  updateRemark,
  updateCallBack,
  code,
  showRejectError,
  rejectionCallBack,
}) => {
  console.log("rejection check", showRejectError);
  const [message, setMessage] = useState<string>("");
  const [rejectionType, setRejectionType] = useState<string>("");

  const handleRejectionTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRejectionType(event.target.value);
  };

  return (
    <>
      <Dialog open={open} fullWidth maxWidth={"md"}>
        <DialogTitle sx={{ backgroundColor: "#282E33" }}>
          <MediumTypography
            labelId="UpdateStatus"
            fontSize="24px"
            fontWeight={700}
            textColor="#9FADBC"
            defaultLabel="Update Status"
          />
          <MediumTypography
            labelId="UpdateStatusSubHeaderText"
            fontSize="16px"
            fontWeight={400}
            textColor="#9FADBC"
            defaultLabel="Drop your comments to Update the status"
            sxProps={{ mt: "10px" }}
          />
        </DialogTitle>
        <DialogContent sx={{ backgroundColor: "#282E33" }}>
          <Box sx={{ mt: "10px", ml: "10px" }}>
            {(code === "MC" || code === "GC") && showRejectError && (
              // <div>
              <RadioComponent
                name="multipleBirth"
                value={rejectionType}
                handleChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleRejectionTypeChange(e);
                }}
                width={"200px"}
                labelid=""
                defaultlabelid=""
                labelidA="PartialRejectText"
                defaultlabelidA="Partial Rejection"
                labelidB="FullRejectText"
                defaultlabelidB="Full Rejection"
              />
              // </div>
            )}
          </Box>
          <Box sx={{ mt: "10px" }}>
            <TextField
              variant="outlined"
              sx={{
                backgroundColor: "#1D2226",
                borderRadius: "4px",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#1D2226",
                  },
                  width: "850px",
                  height: "80px",
                  "&:hover fieldset": {
                    borderColor: "#1D2226",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#1D2226",
                  },
                  "& input": {
                    color: "#fff", // Text color inside the input
                  },
                },
                "& .MuiInputLabel-root": {
                  color: "#bbb", // Placeholder color
                },
              }}
              placeholder="Message"
              value={message}
              onChange={(event) => {
                setMessage(event.target.value.trimStart());
              }}
            />
          </Box>
          <Box>
            <Grid
              className="flex__ justifyContent-FlexEnd pt-md  "
              container
              direction="row"
              alignItems="right"
            >
              <Grid item>
                <ButtonComponent
                  className="btn-confirm mr-md"
                  variantType="contained"
                  defaultLabelId="Confirm"
                  labelId="Revoke.confirmText"
                  sxProps={{
                    textTransform: "none",
                    fontFamily: "Inter",
                    "&:disabled": {
                      color: "white",
                      border: "none",
                    },
                  }}
                  disabled={
                    (code === "MC" || code === "GC") && showRejectError
                      ? rejectionType === "" || message === ""
                      : message === ""
                  }
                  onClick={() => {
                    if ((code === "MC" || code === "GC") && showRejectError) {
                      rejectionCallBack(rejectionType, message);
                    } else {
                      updateRemark(message);
                      updateCallBack();
                    }
                    handleClose();
                  }}
                />
              </Grid>
              <Grid item>
                <ButtonComponent
                  sxProps={{ color: "#B6C2CF !important" }}
                  className="btn-primary btn-cancel "
                  variantType="contained"
                  defaultLabelId="Cancel"
                  labelId="CancelBtnText"
                  onClick={() => {
                    handleClose();
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default UpdateStatusModal;
