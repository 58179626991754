import {
  Avatar,
  Box,
  ClickAwayListener,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  TextField,
  styled,
} from "@mui/material";
import React, { FC, useContext, useEffect, useRef, useState } from "react";
import {
  ActivityMessage,
  AssigneeType,
  getWorkOrderActivities,
  saveActivityMessage,
} from "../../api/moduleCleaning/ModuleCleaningApi";
import { ReactComponent as Send } from "../../assets/images/send.svg";
import MediumTypography from "../../components/formlib/MediumTypography";
import { ReactComponent as MessageIcon } from "../../assets/images/_EditorMentionIcon_.svg";
import ErrorModal from "../../components/formlib/modal/ErrorModal";

import { ReactComponent as FilterIcon } from "../../assets/images/_OverviewIcon_.svg";
import { ReactComponent as RefreshIcon } from "../../assets/images/refreshIcon.svg";
import dayjs from "dayjs";
import { useIntl } from "react-intl";
import useAvailableHeight from "../../utils/useAvailableHeight";
import { DropdownContext } from "../../layouts/appSidebar";
import { useLocation } from "react-router-dom";
import {
  CORRECTIVE_MAINTENANCE,
  PREVENTIVE_MAINTENANCE,
} from "../../routes/Routing";
import { handleError } from "../../utils/commonFunctions";

export interface ActivityProps {
  cardId: number;
  isVisible: boolean;
}

const BlueText = styled("span")({
  color: "rgba(37, 187, 250, 1)",
  fontSize: "14px",
  fontWeight: 500,
  fontFamily: "Inter",
});

const BlackText = styled("span")({
  color: "rgba(255, 255, 255, 1)",
  fontSize: "14px",
  fontWeight: 500,
  fontFamily: "Inter",
});

const WorkOrderActivity: FC<ActivityProps> = ({ cardId, isVisible }) => {
  const [selectedUser, setSelectedUser] = useState<AssigneeType | null>(null); // Track selected user
  const [selectedUsers, setSelectedUsers] = useState<number[]>([]);
  const [activityMes, setActivityMes] = useState<ActivityMessage[]>([]);
  const [page, setPage] = useState<number>(0);
  const containerRef = useRef(null);
  const isFetching = useRef(false);
  const pageRef = useRef(0);
  const [suggestions, setSuggestions] = useState<AssigneeType[]>([]);
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
  const [activityMessage, setActivityMessage] = useState<string>("");
  const [siteUsersData, setSiteUsersData] = useState<AssigneeType[]>([]);
  const [assignedUsersData, setAssigneeUsersData] = useState<AssigneeType[]>(
    []
  );
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [errorDesc, setErrorDesc] = useState<string>("");
  const [totalItemCount, setTotalItemCount] = useState(0);
  const countLimit = useRef<number>(0);
  const [isDescending, setIsDescending] = useState(true);
  const intl = useIntl();
  const context = useContext(DropdownContext);
  const availableHeight = useAvailableHeight(500);
  const collapseAvailHeight = useAvailableHeight(400);
  const preventiveMaintenanceHeight = useAvailableHeight(540);
  const location = useLocation(); // Get the current route
  const currentPath = location.pathname;

  const getALLActivities = async (pageNumber: number) => {
    try {
      if (isFetching.current) return;
      isFetching.current = true;

      const res = await getWorkOrderActivities(
        cardId,
        pageRef.current,
        context.selectedSubgroupId
      );
      const siteUsers = res.siteUsers || [];
      const assignedUsers = res.assignedUsers || [];
      const assignedUserIds = assignedUsers.map((user) => user.id);
      countLimit.current = res.msgCountPerPage;

      const messages = res?.messages?.map((msg) => {
        let taggedMessage = msg.message;
        let images: string[] = [];

        // Tag message based on toAssigneeId
        if (msg.toAssigneeId.length > 0) {
          const isAll = msg.toAssigneeId.length === siteUsers.length;
          const isHere = msg.toAssigneeId.every((id) =>
            assignedUserIds.includes(id)
          );

          if (isAll) {
            taggedMessage = `@all ${msg.message}`;
          } else if (isHere) {
            taggedMessage = `@here ${msg.message}`;
          } else {
            msg.toAssigneeId.forEach((id) => {
              const matchedUser = [...assignedUsers, ...siteUsers].find(
                (user) => user.id === id
              );
              if (matchedUser) {
                taggedMessage = `@${matchedUser.name} ${taggedMessage}`;
              }
            });
          }
        }

        // Add image based on fromAssigneeId
        const fromUser = [...assignedUsers, ...siteUsers].find(
          (user) => user.id === msg.fromAssigneeId
        );
        if (fromUser) {
          images.push(fromUser.image);
        }

        return {
          ...msg,
          taggedMessage,
          images,
        };
      });

      setAssigneeUsersData(assignedUsers);
      setSiteUsersData(siteUsers);

      if (messages && messages.length > 0) {
        setActivityMes((prevMessages) => [...prevMessages, ...messages]);
        setTotalItemCount((prevCount) => prevCount + messages.length);
      } else {
        setActivityMes(messages || []);
      }

      isFetching.current = false;
    } catch (err) {
      isFetching.current = false;
    }
  };

  useEffect(() => {
    getALLActivities(0);
  }, [context.selectedSubgroupId]);

  function formatLocalDateToISOString() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");
    const milliseconds = String(now.getMilliseconds()).padStart(3, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
  }

  function formatDateToLocaleString(date: Date | string) {
    const datetoIso = dayjs(date).format("DD MMM YYYY  HH:mm:ss");
    return datetoIso;
  }

  const siteUsers = siteUsersData || [];
  const assignedUsers = assignedUsersData || [];

  const getSuggestions = (value: string) => {
    const inputValue = value.trim();

    if (inputValue.includes("@here")) {
      setSelectedUsers(assignedUsers.map((assigned) => assigned.id));
      setShowSuggestions(false);
      return [];
    } else if (inputValue.includes("@all")) {
      setSelectedUsers(siteUsers.map((user) => user.id)); // Ensure all site user IDs are set

      setShowSuggestions(false);
      return [];
    } else {
      // Check if the input contains '@'
      if (inputValue.includes("@")) {
        // Split the input by '@' to get the last segment after '@'
        const segments = inputValue.split("@");
        const lastSegment = segments[segments.length - 1].trim();

        if (lastSegment.length === 0) {
          // If there's no text after '@', return empty array
          return [];
        }
        // Filter both assignedUsers and siteUsers based on lastSegment (the mention text)
        const filteredAssigned = assignedUsers.filter((user) =>
          user?.name?.toLowerCase().includes(lastSegment.toLowerCase())
        );
        const filteredSite = siteUsers.filter((user) =>
          user?.name?.toLowerCase().includes(lastSegment.toLowerCase())
        );

        return [
          ...filteredAssigned,
          ...filteredSite.filter(
            (siteUser) =>
              !assignedUsers.some(
                (assignedUser) => assignedUser.id === siteUser.id
              )
          ),
        ];
      }
    }

    // If no '@' found in the input, return empty array
    return [];
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setActivityMessage(value);

    const mentionRegex = /@\w+( \w+)*/g;
    const mentions = value.match(mentionRegex) || [];
    const lastMention =
      mentions.length > 0 ? mentions[mentions.length - 1] : "";
    const searchValue = lastMention.startsWith("@")
      ? lastMention.substring(1).toLowerCase()
      : "";

    if (searchValue.length > 0) {
      setSuggestions(getSuggestions(value));
      setShowSuggestions(true);
    } else {
      setSuggestions([]);
      setShowSuggestions(false);
    }
  };

  const handleSuggestionClick = (suggestion: AssigneeType) => {
    const mentionRegex = /@\w+( \w+)*/g;
    const mentions = activityMessage.match(mentionRegex) || [];
    const lastMention =
      mentions.length > 0 ? mentions[mentions.length - 1] : "";

    // Replace the last mention with the selected suggestion
    const newMessage = activityMessage.replace(
      lastMention,
      `@${suggestion.name} `
    );

    setSelectedUser(suggestion);
    setSelectedUsers((prevUsers) => [...prevUsers, suggestion.id]);
    setActivityMessage(newMessage);
    setSuggestions([]);
    setShowSuggestions(false);
  };

  const handleClickAway = () => {
    setShowSuggestions(false);
  };

  const parseMessage = (messages: string) => {
    let newActivityMessage = messages;
    let activityTags = "";
    if (newActivityMessage.includes("@here")) {
      activityTags += "@here ";
      newActivityMessage = newActivityMessage.replaceAll("@here", " ");
    }

    if (newActivityMessage.includes("@all")) {
      activityTags += "@all ";
      newActivityMessage = newActivityMessage.replaceAll("@all", " ");
    }

    [...assignedUsersData, ...siteUsersData].forEach((user) => {
      let constIncludes = newActivityMessage.includes(user?.name);
      if (constIncludes) {
        activityTags = activityTags + `@${user?.name} `;
        newActivityMessage = newActivityMessage.replace(`@${user?.name}`, ``);
      }
      return true;
    });

    return (
      <>
        <BlueText>{activityTags.trim()}</BlueText>{" "}
        <BlackText>{newActivityMessage.trim()}</BlackText>
      </>
    );
  };

  const handleScroll = () => {
    if (containerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
      if (
        scrollTop + clientHeight >= scrollHeight - 5 &&
        !isFetching.current &&
        totalItemCount >= (pageRef.current + 1) * countLimit.current
      ) {
        pageRef.current = page + 1;
        getALLActivities(pageRef.current + 1);
        setPage((prevPage) => {
          const newPage = prevPage + 1;
          pageRef.current = newPage; // Update the ref value when the page state changes
          return newPage;
        });
      }
    }
  };

  const saveActivity = () => {
    let newActivityMessage = activityMessage;

    [...assignedUsers, ...siteUsers].forEach((user) => {
      let constIinlcudes = newActivityMessage.includes(user?.name);
      if (constIinlcudes) {
        newActivityMessage = newActivityMessage.replace(`@${user.name}`, "");
      }
      return true;
    });

    let params = {
      fromAssigneeId: Number(localStorage.getItem("id")),
      toAssigneeId: selectedUsers,
      message: newActivityMessage,
      parentId: 0,
      messageId: 0,
      sentDate: formatLocalDateToISOString(),
      subActivityList: [],
    };

    saveActivityMessage(cardId, params)
      .then(() => {
        getALLActivities(page);
        setActivityMes([]);
        setActivityMessage("");
        setSelectedUsers([]);
      })
      .catch((err) => {
        setOpenErrorModal(true);
        handleError(err, setErrorDesc);
      });
  };

  const filterMessages = () => {
    let updatedMessages = [...activityMes];
    updatedMessages.sort((a, b) => {
      if (isDescending) {
        return new Date(b.sentDate).getTime() - new Date(a.sentDate).getTime();
      } else {
        return new Date(a.sentDate).getTime() - new Date(b.sentDate).getTime();
      }
    });
    setActivityMes(updatedMessages);
    setIsDescending(!isDescending); // Toggle the sorting order for next time
  };

  return (
    <Box>
      <Box className="flex__ p-lg">
        <Box>
          <MediumTypography
            labelId="ActivityLabelText"
            defaultLabel="Activity"
            fontSize="16px"
            fontWeight={600}
            textColor="rgba(255, 255, 255, 1)"
          />
        </Box>
        <Box sx={{ marginLeft: "auto" }}>
          <Grid
            className="flex__ justifyContent-FlexEnd"
            container
            direction="row"
            alignItems="right"
          >
            <Grid item>
              <MediumTypography
                labelId="NewestFilterLabel"
                defaultLabel="Newest First"
                fontSize="14px"
                className="mr-md"
                fontWeight={500}
                textColor="rgba(182, 194, 207, 1)"
              />
            </Grid>
            <Grid item>
              <FilterIcon
                className={`cursor__pointer mr-sm ${
                  isDescending ? "rotate-desc" : "rotate-asc"
                }`}
                onClick={filterMessages}
              />
            </Grid>
            <Grid item>
              <RefreshIcon
                className="cursor__pointer"
                style={{ marginTop: "2px" }}
                onClick={() => {
                  setActivityMes([]);
                  setTotalItemCount(0);
                  pageRef.current = 0;
                  getALLActivities(0);
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Grid container className="mt-xs">
        <Grid item xs={12} lg={12} md={12} xl={12}>
          <Box>
            <Divider
              variant="fullWidth"
              sx={{
                borderTop: "1px solid #333b43",
              }}
            />
          </Box>
        </Grid>
      </Grid>
      {openErrorModal && (
        <ErrorModal
          descriptionText={errorDesc}
          open={openErrorModal}
          handleClose={() => setOpenErrorModal(false)}
          onPositiveClick={() => {
            setOpenErrorModal(false);
          }}
        />
      )}
      <Box
        className="p-sm messageScroll"
        height={
          (currentPath === PREVENTIVE_MAINTENANCE ||
            currentPath === CORRECTIVE_MAINTENANCE) &&
          isVisible
            ? preventiveMaintenanceHeight
            : isVisible
            ? availableHeight
            : collapseAvailHeight
        }
        overflow={"scroll"}
        onScroll={handleScroll}
        ref={containerRef}
      >
        {activityMes &&
          activityMes.length > 0 &&
          activityMes.map((msg, index) => {
            const user =
              siteUsersData.find(
                (siteUser) => siteUser.id === msg.fromAssigneeId
              ) ||
              assignedUsersData.find(
                (assignedUser) => assignedUser.id === msg.fromAssigneeId
              );
            return (
              <Box className="flex__" sx={{ marginBottom: "20px" }} key={index}>
                <Box className="flex__ ml-xs" sx={{ position: "relative" }}>
                  {/* <ProfileIcon3 style={{ width: "32px", height: "32px" }} /> */}
                  <Avatar
                    src={
                      Array.isArray(msg.images) && msg.images.length > 0
                        ? msg.images[0]
                        : ""
                    }
                    style={{ width: "32px", height: "32px" }}
                  />
                </Box>
                <Box>
                  <Box className="flex__justify__space-between">
                    <Box>
                      <Box className="flex__ ">
                        <MediumTypography
                          label={user ? user.name : "Unknown"}
                          className="ml-xs ellipsis"
                          fontSize="14px"
                          textColor="rgba(160, 160, 160, 1)"
                        />
                        <MediumTypography
                          label={formatDateToLocaleString(msg.sentDate)}
                          className="ml-xs ellipsis"
                          fontSize="14px"
                          textColor="rgba(160, 160, 160, 1)"
                        />
                      </Box>
                      <Box className="ml-xs" sx={{ whiteSpace: "pre-wrap" }}>
                        {parseMessage(msg.taggedMessage)}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            );
          })}
      </Box>
      <Box
        className="flex__ p-sm"
        sx={{
          alignItems: "center",
          width: "100%",
        }}
      >
        <Avatar
          alt=""
          src={localStorage.getItem("Profile Image") || undefined}
        />
        <ClickAwayListener onClickAway={handleClickAway}>
          <Box
            position="relative"
            sx={{
              alignItems: "center",
              width: "100%",
            }}
          >
            <Box
              sx={{
                alignItems: "center",
                width: "100%",
              }}
            >
              <TextField
                variant="outlined"
                sx={{
                  backgroundColor: "#303030",
                  borderRadius: "4px",
                  width: "100%",
                  flexGrow: 1,
                  ml: 1,
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#1D2226",
                    },

                    "&:hover fieldset": {
                      borderColor: "#1D2226",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#1D2226",
                    },
                    "& input": {
                      color: "#fff", // Text color inside the input
                    },
                  },
                  "& textarea": {
                    color: "#fff", // Text color inside the input
                    lineHeight: "1.5",
                    minHeight: "40px !important",
                    alignContent: "center ",
                  },
                  "& .MuiInputLabel-root": {
                    color: "#bbb", // Placeholder color
                  },
                }}
                autoComplete="off"
                placeholder={intl.formatMessage({
                  id: "wo.textAreaPlaceholder",
                  defaultMessage:
                    "Enter long form text here maximum 1000 characters",
                })}
                value={activityMessage}
                onChange={handleInputChange}
                multiline
                rows={1}
                maxRows={4}
                InputProps={{
                  style: {
                    height: "40px !important",
                    padding: "10px 12px",
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end">
                        <MessageIcon
                          className="cursor__none"
                          style={{ position: "relative", top: "2px" }}
                        />
                      </IconButton>
                      <IconButton edge="end">
                        <Send
                          className="ml-sm cursor__pointer"
                          style={{ position: "relative", top: "2px" }}
                          onClick={saveActivity}
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            {showSuggestions && suggestions.length > 0 && (
              <Paper
                sx={{
                  position: "absolute",
                  top: "100%",
                  left: 0,
                  right: 0,
                  zIndex: 1,
                  maxHeight: "200px",
                  overflowY: "auto",
                }}
              >
                <List>
                  {suggestions.map((suggestion) => (
                    <ListItem
                      key={suggestion.id}
                      onClick={() => handleSuggestionClick(suggestion)}
                      sx={{
                        backgroundColor:
                          selectedUser && selectedUser.id === suggestion.id
                            ? "#e3f2fd"
                            : "transparent",
                        cursor: "pointer",
                      }}
                    >
                      <ListItemAvatar>
                        <Avatar alt={suggestion.name} src={suggestion.image} />
                      </ListItemAvatar>
                      <ListItemText primary={suggestion.name} />
                    </ListItem>
                  ))}
                </List>
              </Paper>
            )}
          </Box>
        </ClickAwayListener>
      </Box>
    </Box>
  );
};

export default WorkOrderActivity;
