import { Box, Dialog, DialogContent, DialogTitle, Grid } from "@mui/material";
import React, { FC } from "react";
import MediumTypography from "../MediumTypography";
import ButtonComponent from "../ButtonComponent";

interface ShiftWarningModalProps {
  open: boolean;
  handleClose: () => void;
  selectedText: (value: string) => void;
}

const ShiftWarningModal: FC<ShiftWarningModalProps> = ({
  open,
  handleClose,
  selectedText,
}) => {
  return (
    <Dialog open={open} fullWidth maxWidth={"xs"}>
      <DialogTitle sx={{ backgroundColor: "#1D2125" }}>
        <MediumTypography
          defaultLabel="You are Attempting to move the schedule to a different shift"
          labelId="ShiftWarningTiltle"
          textColor="#B6C2CF"
          fontSize="20px"
          fontWeight={700}
        />
      </DialogTitle>
      <DialogContent sx={{ backgroundColor: "#1D2125" }}>
        <Box>
          <Box className="flex__">
            <MediumTypography
              defaultLabel="This action will require re-assigning the team members. Do you want to continue?"
              labelId="ShiftWarningContent"
              textColor="#9FADBC"
              fontSize="16px"
              fontWeight={400}
            />
          </Box>
          <Box className="horizontalLine" sx={{ marginY: "10px" }} />
          <Box>
            <Grid
              className="flex__ justifyContent-FlexEnd"
              container
              direction="row"
              alignItems="right"
            >
              <Grid item>
                <ButtonComponent
                  className="btn-primary btn-submit mr-md"
                  variantType="contained"
                  defaultLabelId="Yes"
                  labelId="YesText"
                  onClick={() => {
                    selectedText("Yes");
                    handleClose();
                  }}
                />
              </Grid>
              <Grid item>
                <ButtonComponent
                  sxProps={{ color: "#B6C2CF !important" }}
                  className="btn-primary btn-cancel "
                  variantType="contained"
                  defaultLabelId="No"
                  labelId="NoText"
                  onClick={() => {
                    selectedText("No");
                    handleClose();
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ShiftWarningModal;
