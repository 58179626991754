import React, { FC, useState, useEffect } from "react";
import { Box, TextField } from "@mui/material";
import {
  BaseSingleInputFieldProps,
  DatePicker,
  DatePickerProps,
  DateValidationError,
  FieldSection,
  LocalizationProvider,
  UseDateFieldProps,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dayjs } from "dayjs";
import { ReactComponent as LeftArrow } from "../../assets/images/LeftArrow.svg";
import { ReactComponent as RightArrow } from "../../assets/images/RightArrow.svg";
import calendarIcon from "../../assets/images/calendarIcon.svg";
import { useIntl } from "react-intl";

export interface TextFieldProps
  extends UseDateFieldProps<Dayjs, false>,
    BaseSingleInputFieldProps<
      Dayjs | null,
      Dayjs,
      FieldSection,
      false,
      DateValidationError
    > {
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  hideIcon?: boolean;
  disabledDate?: boolean;
  textWidth?: string;
  alignCenter?: boolean;
  textColor?: string;
  labelId?: string;
  defaultLabelId?: string;
}

interface DateComponentProps {
  date: Dayjs | null;
  onDateSelect: (newDate: Dayjs) => void;
  onYearChange?: (value: Dayjs) => void;
  disableFutureDate?: boolean;
  views?: Array<"day" | "month" | "year">;
  inputFormat?: string;
  width?: string;
  disableOpenPicker?: boolean;
  disabledDate?: boolean;
  alwaysOpen?: boolean;
  hideIcon?: boolean;
  textWidth?: string;
  rightArrowRequired?: boolean;
  leftArrowRequired?: boolean;
  alignCenter?: boolean;
  textColor?: string;
  labelId?: string;
  defaultLabelId?: string;
}

const CustomTextField: FC<TextFieldProps> = (props) => {
  const {
    label,
    id,
    setOpen,
    InputProps: { ref } = {},
    hideIcon,
    disabledDate,
    textWidth,
    alignCenter,
    textColor,
    labelId,
    defaultLabelId,
  } = props;

  const datePickerIcon = () => {
    if (hideIcon) {
      return null;
    } else {
      return <img src={calendarIcon} alt="Calender Icon" />;
    }
  };
  const intl = useIntl();

  return (
    <Box onClick={() => setOpen?.((prev) => !prev)} display={"flex"}>
      <TextField
        id={id}
        ref={ref}
        value={label}
        disabled={disabledDate ? disabledDate : false}
        className={alignCenter ? "date-text-fieldCenter" : "date-text-field"}
        placeholder={
          label
            ? undefined
            : labelId
            ? intl.formatMessage({
                id: labelId,
                defaultMessage: defaultLabelId,
              })
            : ""
        }
        InputProps={{
          readOnly: true,
          endAdornment: <Box>{datePickerIcon()}</Box>,
        }}
        sx={{
          "& fieldset": { border: "none" },
          "& .MuiOutlinedInput-root": {
            height: "40px",
            fontFamily: "Inter",
            fontSize: "14px",
            color: textColor ? textColor : "#FFFFFF",
            cursor: "pointer",
            padding: "0px",
            width: textWidth ? textWidth : "100px",
          },
        }}
      />
    </Box>
  );
};

const DatePickerComponent = (
  props: Omit<DatePickerProps<Dayjs>, "open" | "onOpen" | "onClose"> & {
    views?: Array<"day" | "month" | "year">;
    alwaysOpen?: boolean;
    hideIcon?: boolean;
    textWidth?: string;
    textColor?: string;
    alignCenter?: boolean;
    labelId?: string;
    defaultLabelId?: string;
  }
) => {
  const [open, setOpen] = useState<boolean>(false);
  const {
    views,
    alwaysOpen,
    hideIcon,
    textWidth,
    alignCenter,
    textColor,
    labelId,
    defaultLabelId,
    ...otherProps
  } = props; // Destructure `views` from props

  return (
    <DatePicker
      {...otherProps}
      slots={{ ...otherProps.slots, field: CustomTextField }}
      slotProps={{
        ...otherProps.slotProps,
        field: {
          setOpen,
          hideIcon,
          textWidth,
          textColor,
          alignCenter,
          labelId,
          defaultLabelId,
        } as never,
      }}
      open={alwaysOpen ? alwaysOpen : open} // Set open state based on alwaysOpen prop
      onClose={() => !alwaysOpen && setOpen(false)}
      onOpen={() => setOpen(true)}
      views={views ?? ["year", "month", "day"]} // Use the provided `views` or fallback to default
    />
  );
};
const CustomDatePicker: FC<DateComponentProps> = ({
  date,
  onDateSelect,
  disableFutureDate,
  views,
  inputFormat,
  width,
  disableOpenPicker,
  disabledDate,
  alwaysOpen,
  hideIcon,
  textWidth,
  textColor,
  rightArrowRequired,
  leftArrowRequired,
  alignCenter,
  labelId,
  defaultLabelId,
  onYearChange,
}) => {
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);
  const unit = inputFormat === "MMMM YYYY" ? "month" : "day";

  const handleDateChange = (newDate: Dayjs | null) => {
    if (newDate !== null) {
      setSelectedDate(newDate);
      onDateSelect(newDate);
    }
  };

  useEffect(() => {
    if (date === null || date?.isValid()) {
      setSelectedDate(date);
    }
  }, [date]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box
        className={"flex__"}
        sx={{
          cursor: "pointer",
          paddingLeft: "8px",
          paddingRight: "8px",
          borderRadius: "4px",
          border: "1px solid rgba(166, 197, 226, 0.16)",
          backgroundColor: "#22272B",
          width: width ? width : "max-content",
        }}
      >
        {leftArrowRequired && (
          <Box
            sx={{ display: "flex", padding: "8px", cursor: "pointer" }}
            alignItems={"center"}
            onClick={() =>
              !disabledDate &&
              handleDateChange(selectedDate && selectedDate.subtract(1, unit))
            }
          >
            {leftArrowRequired && <LeftArrow width={"14px"} height={"14px"} />}
          </Box>
        )}
        <Box>
          <DatePickerComponent
            label={
              selectedDate &&
              selectedDate.format(inputFormat ? inputFormat : "DD MMM YYYY")
            }
            alwaysOpen={alwaysOpen}
            sx={{
              width: "100px",
            }}
            disableFuture={disableFutureDate}
            disableOpenPicker={disableOpenPicker}
            disabled={disabledDate}
            value={selectedDate}
            onChange={handleDateChange}
            views={inputFormat === "MMMM YYYY" ? ["month", "year"] : views}
            hideIcon={hideIcon}
            textWidth={textWidth}
            textColor={textColor}
            onYearChange={onYearChange}
            alignCenter={alignCenter}
            labelId={labelId}
            defaultLabelId={defaultLabelId}
          />
        </Box>
        {rightArrowRequired && (
          <Box
            sx={{ display: "flex", padding: "8px", cursor: "pointer" }}
            alignItems={"center"}
            onClick={() =>
              !disabledDate &&
              handleDateChange(selectedDate && selectedDate.add(1, unit))
            }
          >
            {rightArrowRequired && (
              <RightArrow width={"14px"} height={"14px"} />
            )}
          </Box>
        )}
      </Box>
    </LocalizationProvider>
  );
};
export default CustomDatePicker;
