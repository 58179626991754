import React, { FC, useContext, useEffect, useRef, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Box,
  Avatar,
  Tooltip,
  IconButton,
  MenuItem,
  Popover,
} from "@mui/material";
import { SxProps } from "@mui/system";
import MediumTypography from "../../../components/formlib/MediumTypography";
import { ReactComponent as More } from "../../../assets/images/ThreeDots.svg";
import useAvailableHeight from "../../../utils/useAvailableHeight";
import dayjs from "dayjs";
import useAvailableWidth from "../../../utils/useAvailableWidth";
import { hasPermission } from "../../../utils/checkPermission";
import CustomTableHeader from "../../../components/formlib/CustomTableHeader";
import { LoaderContext, LoaderContextType } from "../../../layouts/appSidebar";

import TableHeaderSelection from "../TableHeaderSelection";
import { ReactComponent as Menu } from "../../../assets/images/HorizontalMenu.svg";
import { ReactComponent as Sort } from "../../../assets/images/Sort.svg";
import ErrorModal from "../../../components/formlib/modal/ErrorModal";

import {
  applySearchFilters,
  cancelSearchFilters,
  sortTableData,
} from "../TableUtility";
import ModalPopup from "../../../components/formlib/modal/ModalPopup";
import { useLocation } from "react-router-dom";
import {
  deleteDataApi,
  fetchAllDataApi,
} from "../../../api/MasterDataManagement/Common";
import { HeadCell } from "../types/accountTypes";

import {
  ReasonForDeviationData,
  ReasonForDeviationRecord,
  woTypeList,
} from "../types/reasonForDeviation";

import FetchData from "../Json/RFDData.json";
import AddEditDeviationModal from "./AddEditDeviationModal";
import { handleError } from "../../../utils/commonFunctions";

const apiUri = "deviation";

interface TableProps {
  labelSxProp?: SxProps;
  className?: string | undefined;
}

export interface ManageDeviationProps {
  TableProps?: TableProps;
  onDataFetch: (data: RecordsType) => void;
  selectedLanguageCode: string;
  onOpenAddModal: boolean;
  handleModalClose: () => void;
  handleModalOpen: () => void;
  onFilter: (filteredData: { [key: string]: string }) => void;
}

type RecordType = ReasonForDeviationRecord;
type RecordsType = ReasonForDeviationData;

const staticColumn = "name";
const searchKeys = ["name"];

const ManageReasonForDeviation: FC<ManageDeviationProps> = ({
  TableProps,
  onDataFetch,
  selectedLanguageCode,
  onOpenAddModal,
  handleModalClose,
  handleModalOpen,
  onFilter,
}) => {
  const availableWidth = useAvailableWidth(420);
  const availableHeight = useAvailableHeight(220);
  const emptyTableHeight = useAvailableHeight(300);
  const [selectedRow, setSelectedRow] = useState<null | number>(null);
  const containerRef = useRef(null);

  const [activeFilters, setActiveFilters] = useState<{ [key: string]: string }>(
    {}
  );

  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;

  const [listData, setListData] = useState<RecordsType | null>(null);
  const [tableData, setTableData] = useState<RecordType[]>([]);
  const [reorderedFields, setReorderedFields] = useState<HeadCell[]>([]);
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [errorDesc, setErrorDesc] = useState<string>("");
  const [filterOpen, setFilterOpen] = useState<null | HTMLElement>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [originalTableData, setOriginalTableData] = useState<RecordType[]>([]);
  const [modalAccountId, setModalAccountId] = useState<number>();
  const [warningModal, setWarningModal] = useState<boolean>(false);
  const open = Boolean(filterOpen);
  const addEditOpen = Boolean(anchorEl);
  const [allWOTypes, setAllWOTypes] = useState<woTypeList[]>([]);

  const location = useLocation();

  const dataFieldsWithIcons =
    reorderedFields &&
    reorderedFields.map((field) => {
      return { ...field, icon: <Sort /> };
    });

  const handleClick = (
    event: React.MouseEvent<HTMLElement>,
    rowIndex: number
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(rowIndex);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handleEdit = (id: number) => {
    setModalAccountId(id);
    handleModalOpen();
    handleClose();
  };

  const getDeviationData = (
    language: string = selectedLanguageCode,
    applyFilters: boolean = false
  ) => {
    toggleLoader(true);
    fetchAllDataApi<RecordsType>(apiUri, language, "")
      .then((response) => {
        toggleLoader(false);
        if (response) {
          setListData(response);
          setTableData(response.deviationWoTypeMaps);
          setOriginalTableData(response.deviationWoTypeMaps);
          setAllWOTypes(response.woTypes);
          setReorderedFields(response.dataFields);
          onDataFetch(response);
          if (applyFilters && Object.keys(activeFilters).length > 0) {
            const filteredData = applySearchFilters(
              FetchData.deviationWoTypeMaps,
              activeFilters
            );
            setTableData(filteredData as RecordType[]);
          }
          setActiveFilters({});
        }
      })
      .catch((error) => {
        toggleLoader(false);
        setOpenErrorModal(true);
        handleError(error, setErrorDesc);
      });
  };

  useEffect(() => {
    getDeviationData();
  }, [selectedLanguageCode, location?.state]);

  const handleCloseMenu = () => {
    setFilterOpen(null);
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setFilterOpen(event.currentTarget);
  };

  const handleApply = (reorderedCheckedItems: HeadCell[]) => {
    setReorderedFields(reorderedCheckedItems);
    // Perform any additional actions with the reorderedCheckedItems
  };

  const handleSort = (columnKey: string, direction: string) => {
    const sortedData = sortTableData(
      tableData,
      columnKey,
      direction,
      originalTableData
    );
    setTableData(sortedData as RecordType[]);
  };

  const handleSearchApply = (columnKey: string, value: string) => {
    const newFilters = { ...activeFilters, [columnKey]: value };
    const filteredData = applySearchFilters(originalTableData, newFilters);
    setTableData(filteredData as RecordType[]);
    onFilter(newFilters);
    setActiveFilters(newFilters);
  };

  const handleSearchCancel = (columnKey: string) => {
    const { filteredData, updatedFilters } = cancelSearchFilters(
      originalTableData,
      activeFilters,
      columnKey
    );
    setTableData(filteredData as RecordType[]);
    onFilter(updatedFilters);
    setActiveFilters(updatedFilters);
  };

  const handleDeleteDeviation = (id: number) => {
    toggleLoader(true);
    deleteDataApi(apiUri, id)
      .then(() => {
        getDeviationData(selectedLanguageCode, true);
      })
      .catch((error) => {
        toggleLoader(false);
        setOpenErrorModal(true);
        handleError(error, setErrorDesc);
      });
    handleClose();
    setModalAccountId(undefined);
  };

  const handleConfirmDelete = () => {
    if (modalAccountId) {
      handleDeleteDeviation(modalAccountId);
    }
    setWarningModal(false);
  };
  const isFilterApplied = () => {
    const filterApplied = Object.values(activeFilters).some(
      (value) => value !== ""
    );

    if (filterApplied == false || JSON.stringify(activeFilters) === "{}") {
      return true;
    } else {
      return false;
    }
  };
  return (
    <Box>
      {onOpenAddModal && (
        <AddEditDeviationModal
          woTypes={allWOTypes}
          id={modalAccountId}
          open={onOpenAddModal}
          handleClose={() => {
            setModalAccountId(undefined);
            handleModalClose();
          }}
          getDataCallback={() => {
            getDeviationData();
          }}
        />
      )}
      {warningModal && (
        <ModalPopup
          descriptionText={"deleteWarningmessage.reasonfordeviation"}
          open={warningModal}
          handleClose={() => setWarningModal(!warningModal)}
          onPositiveClick={handleConfirmDelete}
          onNegativeClick={() => {
            setWarningModal(false);
            setModalAccountId(undefined);
            handleClose();
          }}
          positiveButtonLabel="Yes"
          positiveButtonLabelId="YesText"
          negativeButtonLabel="No"
          negativeButtonLabelId="NoText"
        />
      )}
      {openErrorModal && (
        <ErrorModal
          descriptionText={errorDesc}
          open={openErrorModal}
          handleClose={() => setOpenErrorModal(false)}
          onPositiveClick={() => {
            setOpenErrorModal(false);
          }}
        />
      )}
      {listData && (
        <Box>
          <Box className="flex__justify__space-between mb-md">
            <MediumTypography
              labelId="ManageReasons.title"
              defaultLabel="Reason For Deviation"
              fontSize="20px"
            />
            <Box className="display-flex-center">
              <MediumTypography
                labelId="totalNumberOfRecords"
                defaultLabel="Total number of records"
                textColor="rgba(255, 255, 255, 0.64)"
                className="mr-xs"
              />
              <MediumTypography
                label={
                  isFilterApplied()
                    ? listData?.totalRecords || 0
                    : `${tableData.length} / ${listData.totalRecords}`
                }
                textColor="rgba(255, 255, 255, 0.64)"
              />
              <Box
                className="ml-md display-flex-center menu-item"
                onClick={handleMenuClick}
              >
                <Menu />
              </Box>
              <TableHeaderSelection
                data={listData.dataFields}
                anchorEl={filterOpen}
                handleClose={handleCloseMenu}
                open={open}
                onApply={handleApply}
                fixedItem={staticColumn}
              />
            </Box>
          </Box>
          <Box
            sx={{
              height: availableHeight,
              overflowY: "auto",
            }}
            className={"position__relative overflow_Y"}
          >
            <TableContainer
              component={Paper}
              sx={{
                width: availableWidth,
                height: tableData.length !== 0 ? availableHeight : "auto",
              }}
              className={"tableContainer"}
              ref={containerRef}
            >
              <Table>
                <CustomTableHeader
                  headerNames={dataFieldsWithIcons}
                  searchableKeys={searchKeys}
                  stickyItems={staticColumn}
                  onSortApply={handleSort}
                  onCancelSearch={handleSearchCancel}
                  onSearchApply={handleSearchApply}
                  languageCode={selectedLanguageCode}
                />
                <TableBody>
                  {tableData.length !== 0 &&
                    tableData.map((row, rowIndex) => (
                      <TableRow
                        key={rowIndex}
                        sx={{
                          backgroundColor:
                            rowIndex % 2 === 0 ? "#2B3033" : "#22272B",
                        }}
                      >
                        {/* Sticky First Column */}
                        <TableCell
                          align={"left"}
                          sx={{
                            backgroundColor:
                              rowIndex % 2 === 0 ? "#2B3033" : "#22272B",
                          }}
                          className="stickyColumn"
                        >
                          <MediumTypography label={(rowIndex + 1).toString()} />
                        </TableCell>

                        {dataFieldsWithIcons.map((cell: HeadCell) => {
                          const cellValue = row[cell.key as keyof RecordType];

                          return (
                            <>
                              {cell.key === "lastUpdated" ? (
                                <TableCell
                                  key={cell.key}
                                  align={"left"}
                                  sx={{
                                    backgroundColor:
                                      rowIndex % 2 === 0
                                        ? "#2B3033"
                                        : "#22272B",
                                  }}
                                  className="p-md"
                                >
                                  <Box className="display-flex-center">
                                    <Avatar
                                      src={
                                        row.lastUpdated !== null &&
                                        row.lastUpdated !== undefined
                                          ? row.lastUpdated.imageUrl
                                          : ""
                                      }
                                      alt="abc"
                                      className="avatar-style"
                                    />
                                    <Box className="ml-sm">
                                      <MediumTypography
                                        label={
                                          row.lastUpdated !== null &&
                                          row.lastUpdated !== undefined
                                            ? row.lastUpdated.name
                                            : "-"
                                        }
                                        sxProps={{
                                          lineHeight: "normal",
                                        }}
                                        className="mt-xs"
                                      />
                                      <MediumTypography
                                        label={
                                          row.lastUpdated !== null &&
                                          row.lastUpdated !== undefined
                                            ? dayjs(
                                                row.lastUpdated.timestamp
                                              ).format("DD MMM YYYY HH:mm")
                                            : ""
                                        }
                                        fontSize="12px"
                                        textColor={"rgba(255, 255, 255, 0.64)"}
                                      />
                                    </Box>
                                  </Box>
                                </TableCell>
                              ) : (
                                <>
                                  {cell.key === "name" ? (
                                    <TableCell
                                      key={cell.key}
                                      align={"left"}
                                      sx={{
                                        backgroundColor:
                                          rowIndex % 2 === 0
                                            ? "#2B3033"
                                            : "#22272B",
                                      }}
                                      className="stickyColumn_left"
                                    >
                                      <MediumTypography
                                        label={
                                          cellValue !== undefined &&
                                          cellValue !== null &&
                                          cellValue !== ""
                                            ? cellValue.toString()
                                            : "-"
                                        }
                                      />
                                    </TableCell>
                                  ) : (
                                    <>
                                      {cell.key === "woType" ? (
                                        <TableCell
                                          key={cell.key}
                                          align={"left"}
                                          sx={{
                                            backgroundColor:
                                              rowIndex % 2 === 0
                                                ? "#2B3033"
                                                : "#22272B",
                                          }}
                                          className="p-md"
                                        >
                                          <Box
                                            className="display-flex-center"
                                            gap={1}
                                          >
                                            {row.selectedWoTypes
                                              .map((id) =>
                                                listData.woTypes.find(
                                                  (woType) => woType.id === id
                                                )
                                              )
                                              .filter(
                                                (woType) => woType !== undefined
                                              )
                                              .map((woType) => {
                                                if (
                                                  woType !== undefined &&
                                                  woType.code !== undefined &&
                                                  woType.code !== null
                                                ) {
                                                  return (
                                                    <Box
                                                      sx={{
                                                        border:
                                                          woType.code === "CM"
                                                            ? "1px solid #FF453A"
                                                            : "1px solid  #B8ACF6",
                                                        borderRadius: "4px",
                                                        padding: "2px",
                                                      }}
                                                    >
                                                      <MediumTypography
                                                        label={woType.code}
                                                        fontSize="12px"
                                                        textColor={
                                                          woType.code === "CM"
                                                            ? "#FF453A"
                                                            : "#B8ACF6"
                                                        }
                                                        sxProps={{
                                                          lineHeight: "normal",
                                                        }}
                                                      />
                                                    </Box>
                                                  );
                                                }
                                                return true;
                                              })}
                                          </Box>
                                        </TableCell>
                                      ) : (
                                        <TableCell
                                          key={cell.key}
                                          align={"left"}
                                          sx={{
                                            backgroundColor:
                                              rowIndex % 2 === 0
                                                ? "#2B3033"
                                                : "#22272B",
                                          }}
                                          className="p-md"
                                        >
                                          <MediumTypography
                                            label={
                                              cellValue !== undefined &&
                                              cellValue !== null &&
                                              cellValue !== ""
                                                ? cellValue.toString()
                                                : "-"
                                            }
                                          />
                                        </TableCell>
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          );
                        })}

                        {/* Sticky Last Column */}
                        <TableCell
                          align={"left"}
                          sx={{
                            backgroundColor:
                              rowIndex % 2 === 0 ? "#2B3033" : "#22272B",
                          }}
                          className="stickyColumn_right"
                        >
                          <Tooltip title="More">
                            <IconButton
                              onClick={(event) => handleClick(event, rowIndex)}
                            >
                              <More />
                            </IconButton>
                          </Tooltip>
                          <Popover
                            anchorEl={anchorEl}
                            open={addEditOpen && selectedRow === rowIndex}
                            onClose={handleClose}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                            sx={{
                              "& .MuiPaper-root": {
                                backgroundColor: "#2B3033",
                              },
                            }}
                          >
                            <MenuItem
                              onClick={() => {
                                if (
                                  selectedLanguageCode === "en" &&
                                  hasPermission("Onboarding_Admin")
                                ) {
                                  handleEdit(row.id);
                                } else {
                                  return;
                                }
                              }}
                              style={{
                                backgroundColor: "2B3033",
                              }}
                              disabled={selectedLanguageCode !== "en"}
                            >
                              <MediumTypography
                                labelId="btn.edit"
                                defaultLabel="Edit"
                              />
                            </MenuItem>
                            <MenuItem
                              onClick={() =>
                                hasPermission("Onboarding_Admin")
                                  ? (setWarningModal(true),
                                    setModalAccountId(row.id))
                                  : () => {}
                              }
                              style={{ backgroundColor: "2B3033" }}
                            >
                              <MediumTypography
                                labelId="planner.deleteText"
                                defaultLabel="Delete"
                              />
                            </MenuItem>
                          </Popover>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            {tableData.length === 0 && (
              <Box
                sx={{
                  height: emptyTableHeight,
                }}
                className="display-flex-center flex__justify__center "
              >
                <MediumTypography
                  labelId="ManageAccounts.emptymsg"
                  defaultLabel="No records available"
                  fontSize="20px"
                  fontWeight={700}
                />
              </Box>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ManageReasonForDeviation;
