import { RCAType } from "../../pages/workOrderDetailScreens/correctiveMaintenance/types";
import { ApiResponseHandler } from "../ApiResponseHandler";
import WorkOrderConfig from "../WorkOrderConfig";

export const getRCADetailsApi = async (id: number): Promise<RCAType> => {
  return ApiResponseHandler(
    await WorkOrderConfig.getInstance().get(
      `workorder/cm/fetch/${id}/rcaDetails`
    )
  );
};

export const saveRCADetails = async (
  id: number,
  body: RCAType
): Promise<RCAType> => {
  return ApiResponseHandler(
    await WorkOrderConfig.getInstance().post(
      `workorder/cm/save/${id}/rcaDetails`,
      body
    )
  );
};
