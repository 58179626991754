import React from "react";
import {
  Box,
  Tooltip,
  TooltipProps,
  styled,
  tooltipClasses,
} from "@mui/material";
import MediumTypography from "../formlib/MediumTypography";
import moment from "moment";

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className ?? "" }} />
))`
  & .${tooltipClasses.tooltip} {
    background-color: rgba(29, 33, 37, 0.9);
  }

  & .${tooltipClasses.arrow} {
    color: rgba(29, 33, 37, 0.9);
  }

  & .tooltip-text {
    font-family: Inter;
    font-size: 10px;
  }
`;

interface TimeProgressBarProps {
  scheduledStartTime: string;
  scheduledEndTime: string;
  actualStartTime: string | null;
  actualEndTime: string | null;
  progress: number;
}

const TimeProgressBar: React.FC<TimeProgressBarProps> = ({
  scheduledStartTime,
  scheduledEndTime,
  actualStartTime,
  actualEndTime,
  progress,
}) => {
  const scheduledStart = parseTime(scheduledStartTime);
  const scheduledEnd = parseTime(scheduledEndTime);

  const actualStart = actualStartTime ? parseTime(actualStartTime) : null;
  let actualEnd = actualEndTime ? parseTime(actualEndTime) : null;
  const actualEndInitial = actualEnd;

  if (!actualEnd) {
    // Get current time in 'HH:mm:ss' format
    const currentTime = moment().format("HH:mm:ss");

    // Extract only the date from the scheduledEnd date-time
    const scheduledEndDate = moment(scheduledEndTime).format("YYYY-MM-DD");

    // Combine scheduledEnd date with the current time
    const combinedScheduledEndCurrentTime = moment(
      `${scheduledEndDate}T${currentTime}`
    );

    // Parse the combined date and time as actualEnd
    actualEnd = Math.min(
      scheduledEnd,
      parseTime(combinedScheduledEndCurrentTime.toISOString())
    );
  }

  let timeDiffString = "";

  if (actualStart && actualEndInitial) {
    // Calculate the difference between scheduled start and end
    const scheduledStartMoment = moment(scheduledStartTime); // Full date-time
    const scheduledEndMoment = moment(scheduledEndTime); // Full date-time
    const actualStartMoment = moment(actualStartTime); // Full date-time
    const actualEndMoment = moment(actualEndTime); // Full date-time

    // Calculate the scheduled and actual durations
    const scheduledDurationMs = scheduledEndMoment.diff(scheduledStartMoment); // Duration in ms
    const actualDurationMs = actualEndMoment.diff(actualStartMoment); // Duration in ms

    // Calculate the difference between actual and scheduled durations
    const durationDiffMs = actualDurationMs - scheduledDurationMs; // Positive if longer, negative if shorter

    // Use absolute values for hours and minutes, but preserve the sign for display
    const durationDiff = moment.duration(Math.abs(durationDiffMs)); // Duration in positive values
    const hours = durationDiff.hours();
    const minutes = durationDiff.minutes();

    // Determine if the event finished early or late
    const sign = durationDiffMs < 0 ? "-" : "+"; // "-" for early finish, "+" for late finish

    // Build the time difference string
    timeDiffString = `${sign}`;

    if (hours > 0) {
      timeDiffString += `${hours} hr `;
    }

    timeDiffString += `${minutes} mins`;
  }

  const minStart = Math.min(scheduledStart, actualStart || scheduledStart);
  const maxEnd = Math.max(scheduledEnd, actualEnd);

  // Calculate the left position for the labels
  const scheduledStartLeft = calculatePercentage(
    minStart,
    scheduledStart,
    maxEnd
  );
  const scheduledEndLeft = calculatePercentage(minStart, scheduledEnd, maxEnd);

  return (
    <Box width="100%" mx="auto" sx={{ position: "relative" }}>
      {/* Timeline Wrapper to handle hover */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          width: "100%",
          height: "100%",
          "&:hover .timeline-label": {
            opacity: 1, // Toggle visibility on hover
          },
        }}
      >
        <CustomTooltip
          title={
            <Box>
              <MediumTypography
                className="tooltip-text"
                label={`Completion: ${progress}%`}
              />
              {actualEndInitial && timeDiffString && (
                <MediumTypography
                  className="tooltip-text"
                  label={`Time Taken: ${timeDiffString}`}
                />
              )}
            </Box>
          }
          placement="right"
          arrow
          PopperProps={{
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [0, 15], // Adjust this to control the distance. First value is horizontal, second is vertical.
                },
              },
            ],
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: 20,
              width: "100%",
              height: 12,
            }}
          >
            {/* Timeline background to provide a visual reference for the full duration */}
            <Box
              sx={{
                position: "absolute",
                left: `${calculatePercentage(minStart, minStart, maxEnd)}%`,
                width: `${calculatePercentage(minStart, maxEnd, maxEnd)}%`,
                bgcolor: "rgba(90, 96, 127, 0.4)",
                height: "100%",
                borderRadius: "2px",
              }}
            />
            {/* Full range of scheduled time */}
            <Box
              sx={{
                position: "absolute",
                left: `${scheduledStartLeft}%`,
                width: `${scheduledEndLeft - scheduledStartLeft}%`,
                height: "100%",
                borderRadius: "2px",
              }}
            />
            {/* Marker for scheduledEnd */}
            <Box
              sx={{
                position: "absolute",
                left: `calc(${scheduledEndLeft}% - 2px)`,
                width: "2px",
                bgcolor: "#FFF",
                height: "100%",
              }}
            />
            {/* Actual start and end time range */}
            {actualStart && actualEnd && (
              <Box
                sx={{
                  position: "absolute",
                  left: `${calculatePercentage(
                    minStart,
                    actualStart,
                    maxEnd
                  )}%`,
                  width: `${
                    calculatePercentage(minStart, actualEnd, maxEnd) -
                    calculatePercentage(minStart, actualStart, maxEnd)
                  }%`,
                  background:
                    "linear-gradient(to right bottom, rgba(107, 247, 188, 1), rgba(77, 130, 119, 1))",
                  height: "8px",
                  top: "2px",
                  borderRadius: "1px",
                }}
              />
            )}
          </Box>
        </CustomTooltip>

        {/* Labels for times */}
        {/* Scheduled Start Time Label */}
        <MediumTypography
          className="timeline-label"
          label={getFormattedTime(scheduledStartTime)}
          fontSize="10px"
          sxProps={{
            position: "absolute",
            left: `${scheduledStartLeft}%`,
            top: 33,
            opacity: 0, // Initially hidden
            transition: "opacity 0.3s",
          }}
        />
        <MediumTypography
          className="timeline-label"
          label={getFormattedTime(scheduledEndTime)}
          fontSize="10px"
          sxProps={{
            position: "absolute",
            left: `${Math.max(scheduledEndLeft, scheduledStartLeft + 22)}%`, // Add padding between the labels
            top: 33,
            opacity: 0, // Initially hidden
            transition: "opacity 0.3s",
          }}
        />
        {actualStart && actualEnd && (
          <>
            <MediumTypography
              className="timeline-label"
              label={getFormattedTime(actualStartTime)}
              fontSize="10px"
              sxProps={{
                position: "absolute",
                left: `${calculatePercentage(minStart, actualStart, maxEnd)}%`,
                top: 5,
                opacity: 1,
              }}
            />
            <MediumTypography
              className="timeline-label"
              label={getFormattedTime(actualEndTime)}
              fontSize="10px"
              sxProps={{
                position: "absolute",
                left: `${Math.max(
                  calculatePercentage(minStart, actualEnd, maxEnd),
                  calculatePercentage(minStart, actualStart, maxEnd) + 22
                )}%`, // Add padding between the labels
                top: 5,
                opacity: 1,
              }}
            />
          </>
        )}
      </Box>
    </Box>
  );
};

export default TimeProgressBar;

const parseTime = (dateTime: string): number => {
  return moment(dateTime).valueOf(); // Returns milliseconds since epoch
};

function getFormattedTime(dateString: string | null): string | null {
  if (!dateString) return null;

  const momentDate = moment(dateString);
  if (!momentDate.isValid()) return null;

  return momentDate.format("HH:mm");
}

const calculatePercentage = (min: number, value: number, max: number) => {
  return ((value - min) / (max - min)) * 100;
};
