import { Box } from "@mui/material";
import { ReactComponent as Congrats } from "../../assets/images/Congrats.svg";
import MediumTypography from "../../components/formlib/MediumTypography";
import ButtonComponent from "../../components/formlib/ButtonComponent";
import { FC } from "react";

export interface CongratsScreenProps {
  backToLogin: () => void;
  testId?: string;
}

const CongratulationsScreen: FC<CongratsScreenProps> = ({
  backToLogin,
  testId,
}) => {
  return (
    <Box className="login" data-testid={testId}>
      <Box className="congratsView">
        <Congrats />
        <MediumTypography
          className="title"
          labelId="congratulations.Header"
          defaultLabel="Congratulations!"
        />
        <MediumTypography
          className="congratsSubText subText mt-sm"
          labelId="congratulations.SubText"
          defaultLabel="You have successfully changed the password. Please use your new password when logging in."
        />
        <ButtonComponent
          type="submit"
          labelId="congratulations.Button"
          variantType="outlined"
          className="btn-primary btn-submit  mt-xlg"
          defaultLabelId="Login to continue"
          onClick={() => {
            backToLogin();
          }}
        />
      </Box>
    </Box>
  );
};

export default CongratulationsScreen;
