import { LeaveType, ManageAbsenceType } from "../../pages/manageAbsence/types";
import { ApiResponseHandler } from "../ApiResponseHandler";
import WorkOrderConfig from "./../WorkOrderConfig";

export const getWeekOffType = async (
  subGroupId: number,
  startDate: string,
  endDate: string
  // userId: number
): Promise<ManageAbsenceType> => {
  return ApiResponseHandler(
    await WorkOrderConfig.getInstance().get(
      `manageabsence/getWeekOffs?subGroupId=${subGroupId}&language=en&startDate=${startDate}&endDate=${endDate}`
    )
  );
};

export const getTimeOffType = async (
  subGroupId: number,
  startDate: string,
  endDate: string
  // userId: number
): Promise<ManageAbsenceType> => {
  return ApiResponseHandler(
    await WorkOrderConfig.getInstance().get(
      `manageabsence/getTimeOffs?subGroupId=${subGroupId}&language=en&startDate=${startDate}&endDate=${endDate}`
    )
  );
};

export const addWeekOff = async (
  subGroupId: number,
  startDate: string,
  endDate: string,
  body: LeaveType
  // userId: number
): Promise<ManageAbsenceType> => {
  return ApiResponseHandler(
    await WorkOrderConfig.getInstance().post(
      `manageabsence/saveWeekOffUsers?subGroupId=${subGroupId}&startDate=${startDate}&endDate=${endDate}`,
      body
    )
  );
};

export const addTimeOff = async (
  subGroupId: number,
  startDate: string,
  endDate: string,
  body: LeaveType
  // userId: number
): Promise<ManageAbsenceType> => {
  return ApiResponseHandler(
    await WorkOrderConfig.getInstance().post(
      `manageabsence/saveTimeOffUsers?subGroupId=${subGroupId}&startDate=${startDate}&endDate=${endDate}`,
      body
    )
  );
};
