import { ReactNode, createContext, useState } from "react";
import { StatusType, WOCategoryType } from "../../../utils/type";

export interface AssetGroups {
  assetGroupId: number;
  assetGroupName: string;
}

export interface SubgroupType {
  subGroupId: number;
  subGroupName: string;
  assetGroups: AssetGroups[];
}
export interface siteType {
  siteId: number;
  siteName: string;
  subgroups: SubgroupType[];
}
interface SchedulerData {
  period: string[];
  shifts: string[];
  totalWOStatusCount: number;
  totalWOCount: number;
  status: StatusType[];
  woCategory: WOCategoryType[];
  sites: siteType[];
}

type SchedulerContextType = {
  children: ReactNode;
};

const SchedulerContext = createContext<{
  schedulerFilterData: SchedulerData;
  updateSchedulerContext: (data: SchedulerData) => void;
}>({
  schedulerFilterData: {
    period: [],
    shifts: [],
    totalWOStatusCount: 0,
    totalWOCount: 0,
    status: [],
    woCategory: [],
    sites: [],
  },
  updateSchedulerContext: () => {},
});

export const SchedulerFilterContextProvider = ({
  children,
}: SchedulerContextType) => {
  const [schedulerFilterData, setSchedulerFilterData] = useState<SchedulerData>(
    {
      period: [],
      shifts: [],
      totalWOStatusCount: 0,
      totalWOCount: 0,
      status: [],
      woCategory: [],
      sites: [],
    }
  );

  const updateSchedulerContext = (data: SchedulerData) => {
    setSchedulerFilterData(data);
  };

  return (
    <SchedulerContext.Provider
      value={{ schedulerFilterData, updateSchedulerContext }}
    >
      {children}
    </SchedulerContext.Provider>
  );
};
export default SchedulerContext;
