import { Switch, styled } from "@mui/material";
import { ReactComponent as TickMark } from "../assets/images/tick-circle.svg";
import { ReactComponent as CrossMark } from "../assets/images/_CrossIcon_.svg";
import React, { FC } from "react";

const CustomSwitch = styled(Switch)(({ theme }) => ({
  //   "& .MuiSwitch-track": {
  //     borderRadius: 22 / 2,
  //     backgroundColor: "rgba(31, 132, 90, 1)",
  //   },
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor:
          theme.palette.mode === "dark" ? "#2ECA45" : "rgba(31, 132, 90, 1)",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor:
      theme.palette.mode === "light" ? "rgba(68, 84, 111, 1)" : "#39393D",
    "&::before, &::after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
    "&::before": {
      backgroundImage: `url(${TickMark})`,
      left: 12,
    },
    "&::after": {
      backgroundImage: `url(${CrossMark})`,
      right: 12,
    },
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export interface SwitchProps {
  value: boolean;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}
const SwitchComponent: FC<SwitchProps> = ({ value, handleChange }) => {
  return <CustomSwitch checked={value} onChange={handleChange} />;
};

export default SwitchComponent;
