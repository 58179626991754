import React from "react";
import {
  FilledInputProps,
  InputBaseComponentProps,
  InputLabelProps,
  InputProps,
  OutlinedInputProps,
  TextField,
} from "@mui/material";
import { useIntl } from "react-intl";
import { SxProps } from "@mui/system";

interface TextInputProps {
  name?: string;
  fieldheight?: string;
  Value?: string | string[] | null;
  handlechange?: (value: string) => void;
  handleClick?: () => void;
  type?: string;
  inputProps?: InputBaseComponentProps;
  InputProps?:
    | Partial<FilledInputProps>
    | Partial<InputProps>
    | Partial<OutlinedInputProps>;
  inputLabelProps?: Partial<InputLabelProps>;
  onblur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onKeyPress?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
  onPasteText?: (event: React.ClipboardEvent<HTMLInputElement>) => void;
  variant?: "standard" | "outlined" | "filled";
  disabled?: boolean;
  Required?: boolean;
  labelId?: string;
  defaultLabelId?: string;
  multiline?: boolean;
  maxRows?: number;
  sxProps?: SxProps;
  className?: string;
  inputRef?: React.RefObject<HTMLInputElement>;
  autoFocus?: boolean;
  autoComplete?: string;
  borderColor?: string;
  backGroundColor?: string;
  textColor?: string;
}

const TextInput: React.FC<TextInputProps> = (props) => {
  const {
    type,
    fieldheight,
    name,
    Value,
    handlechange,
    inputProps,
    InputProps,
    onblur,
    onFocus,
    onKeyUp,
    variant,
    disabled,
    Required,
    labelId,
    defaultLabelId,
    multiline,
    maxRows,
    sxProps,
    className,
    inputLabelProps,
    inputRef,
    autoFocus,
    onPasteText,
    autoComplete,
    onKeyPress,
    borderColor,
    backGroundColor,
    textColor,
    handleClick,
  } = props;
  const textFieldStyle = {
    height: fieldheight,
  };
  const intl = useIntl();
  return (
    <TextField
      sx={{
        width: "auto",
        "& .MuiInputBase-root": {
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "14px",
          alignItems: "center",
          color: textColor ? textColor : "#FFFFFF",
          backgroundColor: backGroundColor ? backGroundColor : "#2C2C2E",
        },
        "& label.Mui-focused": {
          color: "#2C2C2E",
        },
        "& .MuiOutlinedInput-multiline": {
          borderColor: "#2C2C2E",
        },
        "& .MuiOutlinedInput-root": {
          "&.Mui-focused fieldset": {
            borderColor: "#2C2C2E",
          },
          height: "inherit",
          border: borderColor,
        },
        "& .MuiOutlinedInput-input": {
          "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
          },
          "&:-webkit-autofill": {
            height: "7px",
          },
        },
        ...sxProps,
      }}
      inputRef={inputRef}
      autoFocus={autoFocus}
      name={name}
      className={className}
      autoComplete={autoComplete ?? "off"}
      variant={variant ?? "outlined"}
      value={Value}
      placeholder={
        labelId &&
        intl.formatMessage({
          id: labelId,
          defaultMessage: defaultLabelId,
        })
      }
      onChange={(event) => {
        if (handlechange !== undefined) {
          handlechange(event.target.value.trimStart());
        }
      }}
      style={textFieldStyle}
      type={type ?? "text"}
      onBlur={onblur}
      onFocus={onFocus}
      onKeyUp={onKeyUp}
      disabled={disabled}
      required={Required}
      multiline={multiline}
      maxRows={maxRows ?? 1}
      inputProps={inputProps}
      InputProps={InputProps}
      InputLabelProps={inputLabelProps}
      onPaste={onPasteText}
      onKeyDown={onKeyPress}
      onClick={handleClick}
    />
  );
};
export default TextInput;
