import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import { FC, useContext, useEffect, useRef, useState } from "react";
import MediumTypography from "../../../components/formlib/MediumTypography";
import ButtonComponent from "../../../components/formlib/ButtonComponent";
import CloseIcon from "@mui/icons-material/Close";
import "../styles.css";
import TextInput from "../../../components/formlib/TextInput";

import { LoaderContext, LoaderContextType } from "../../../layouts/appSidebar";
import ErrorModal from "../../../components/formlib/modal/ErrorModal";

import { Formik, FormikErrors, FormikProps } from "formik";
import * as Yup from "yup";
import FormikErrorComponent from "../../../components/formlib/FormikErrorComponent";

import {
  createDropdownOptionApi,
  fetchDataApi,
  saveDataApi,
} from "../../../api/MasterDataManagement/Common";
import {
  FetchPermitLineItemType,
  SavePermitLineItemType,
} from "../types/permitLineItemTypes";
import CustomDropdown from "../../../components/formlib/CustomDropdown";
import { trim } from "lodash";
import { handleError } from "../../../utils/commonFunctions";

// Configurations

type FetchDataType = FetchPermitLineItemType;
type SaveDataType = SavePermitLineItemType;

const apiUri = "permitLineItem";

interface ModalProps {
  open: boolean;
  handleClose: () => void;
  id?: number;
  getDataCallback: () => void;
  permitId: number;
  permitName: string; // reloadAccountData
}

const initialData: FetchDataType = {
  permitId: 0,
  permitName: "",
  permitLineItem: "",
  responseType: [],
  responseOption: [],
  responseOptionId: [],
  responseTypeId: 0,
};

const dropdownValidation = Yup.mixed()
  .nullable()
  .test("is-valid-number", "validation.dropdown", (value) => {
    const theValue = value ? value.toString() : "";
    if (trim(theValue) === "" || theValue === "0") {
      return true;
    }

    return parseFloat(theValue) > 0;
  });

const customRequiredFn = (value: any) => {
  const theValue = value ? value.toString() : "";
  return trim(theValue) !== "" && theValue !== "0";
};

const ValidationSchema = Yup.object().shape({
  permitName: Yup.string().required("validation.fieldRequired"),
  permitLineItem: Yup.string().required("validation.fieldRequired"),
  responseTypeId: dropdownValidation.test(
    "custom-required",
    "validation.dropdown",
    customRequiredFn
  ),
});

const muiColumnProps = {
  xs: 12,
};

const AddEditPermitLineItemMapModal: FC<ModalProps> = ({
  open,
  handleClose,
  id,
  getDataCallback,
  permitId,
  permitName,
}) => {
  const [apiData, setApiData] = useState<FetchDataType>(initialData);
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [errorDesc, setErrorDesc] = useState<string>("");
  const formikRef = useRef<FormikProps<FetchDataType>>(null);
  const [isVersionRequired, setIsVersionRequired] = useState<boolean>(false);

  useEffect(() => {
    if (open) {
      fetchData();
    }
  }, [id, open]);

  const getOptions = (data: any) => {
    if (data === undefined || data === null || !Array.isArray(data)) return [];
    return data.map((item) => ({
      id: item.id.toString(),
      name: item.name,
    }));
  };

  const fetchData = () => {
    toggleLoader(true);

    fetchDataApi<FetchDataType>(apiUri, id)
      .then(async (res) => {
        const updatedRes = {
          ...initialData,
          ...res,
          permitId: permitId,
          permitName: permitName,
        };

        setApiData(updatedRes);
        toggleLoader(false);
      })
      .catch((err) => {
        handleError(err, setErrorDesc);
        setOpenErrorModal(true);
        toggleLoader(false);
      });
  };

  const saveData = (params: FetchDataType) => {
    toggleLoader(true);

    saveDataApi<SaveDataType>(apiUri, params, isVersionRequired, id)
      .then(() => {
        formikRef.current?.resetForm({ values: initialData });
        handleClose();
        getDataCallback();
        toggleLoader(false);
      })
      .catch((err) => {
        handleError(err, setErrorDesc);
        setOpenErrorModal(true);
        toggleLoader(false);
      });
  };
  const handleValidation = (values: FetchDataType) => {
    const errors: FormikErrors<FetchDataType> = {};
    const responseTypeValidation =
      values.responseType.find(
        (item) => item.id === Number(values.responseTypeId)
      )?.name === "Single select";
    if (!responseTypeValidation && values.responseOptionId.length < 1) {
      errors.responseOptionId = "validation.dropdown";
    }
    return errors;
  };

  return (
    <>
      {openErrorModal && (
        <ErrorModal
          descriptionText={errorDesc}
          open={openErrorModal}
          handleClose={() => {
            setOpenErrorModal(false);
            handleClose();
          }}
          onPositiveClick={() => {
            setOpenErrorModal(false);
            handleClose();
          }}
        />
      )}

      <Formik
        initialValues={apiData}
        enableReinitialize
        validateOnChange
        innerRef={formikRef}
        validationSchema={ValidationSchema}
        validate={handleValidation}
        onSubmit={(values) => {
          const updatedValues = {
            ...values,
            responseTypeId: Number(values.responseTypeId),
            responseOptionId: values.responseOptionId.map(Number),
          };
          saveData(updatedValues);
        }}
      >
        {({ values, setFieldValue, handleSubmit, errors, touched, dirty }) => {
          return (
            <Dialog
              className="account-dialog main-wrapper-box"
              open={open}
              fullWidth
              maxWidth={"sm"}
            >
              <DialogTitle sx={{ padding: "20px" }}>
                <MediumTypography
                  labelId={id ? "permitMap.update" : "permitMap.add"}
                  defaultLabel="permit Details"
                  textColor="#FFF"
                  fontSize="22px"
                  fontWeight={700}
                />
                <MediumTypography
                  labelId={"common.basicDetails"}
                  defaultLabel="Enter basic details"
                  textColor="rgba(159, 173, 188, 1)"
                  fontSize="14px"
                  fontWeight={400}
                  sxProps={{ mt: "4px" }}
                />
              </DialogTitle>

              <IconButton
                aria-label="close"
                onClick={() => {
                  formikRef.current?.resetForm({ values: initialData });
                  handleClose();
                }}
                sx={{
                  position: "absolute",
                  right: 16,
                  top: 16,
                  color: "rgba(159, 173, 188, 1)",
                }}
              >
                <CloseIcon />
              </IconButton>

              <DialogContent dividers sx={{ padding: "20px" }}>
                <Grid container spacing={2}>
                  <Grid item {...muiColumnProps}>
                    <MediumTypography
                      className="input-label"
                      labelId="permitMap.title"
                      defaultLabel="Permit"
                    />
                    <TextInput
                      className="text-input-field"
                      type="text"
                      variant="outlined"
                      inputProps={{
                        readOnly: true,
                      }}
                      labelId="common.placeHolderText"
                      defaultLabelId="--- type here ---"
                      Value={values.permitName}
                      // handlechange={(value: string) => {
                      //   setFieldValue("permitName", value);
                      // }}
                      disabled
                    />

                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="permitName"
                    />
                  </Grid>
                  <Grid item {...muiColumnProps}>
                    <MediumTypography
                      className="input-label"
                      labelId="permitLineItem"
                      defaultLabel="lineItem"
                    />
                    <TextInput
                      className="text-input-field"
                      type="text"
                      variant="outlined"
                      inputProps={{
                        readOnly: false,
                      }}
                      labelId="common.placeHolderText"
                      defaultLabelId="--- type here ---"
                      Value={values.permitLineItem}
                      handlechange={(value: string) => {
                        setFieldValue("permitLineItem", value);
                      }}
                    />
                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="permitLineItem"
                    />
                  </Grid>
                  <Grid item {...muiColumnProps}>
                    <MediumTypography
                      className="input-label"
                      labelId="permitResponse"
                      defaultLabel="Permit Response"
                    />

                    <CustomDropdown
                      disableCreate={true}
                      options={getOptions(values.responseType)}
                      selectedValue={values.responseTypeId}
                      onSelectedValueChange={(value) => {
                        setFieldValue("responseTypeId", value);
                        setFieldValue("responseOptionId", []);
                      }}
                    />
                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="responseTypeId"
                    />
                  </Grid>
                  <Grid item {...muiColumnProps}>
                    <MediumTypography
                      className="input-label"
                      labelId="PermitOption"
                      defaultLabel="Permit Option"
                    />

                    <CustomDropdown
                      multiple={true}
                      // disableSearch={true}
                      options={getOptions(values.responseOption)}
                      selectedValue={values.responseOptionId}
                      onSelectedValueChange={(value) => {
                        setFieldValue("responseOptionId", value);
                      }}
                      overrideHeight="auto"
                      disabled={
                        values.responseType.find(
                          (item) => item.id === Number(values.responseTypeId)
                        )?.name === "Single select"
                      }
                      onCreateOption={(option) => {
                        toggleLoader(true);
                        createDropdownOptionApi(
                          "PERMIT_OPTION_TYPE",
                          "PermitOptionType",
                          option
                        )
                          .then((res) => {
                            setFieldValue("responseOption", res);
                            setFieldValue(
                              "responseOptionId",
                              res.find((acc) => acc.name === option)?.id || ""
                            );
                            toggleLoader(false);
                          })
                          .catch((err) => {
                            handleError(err, setErrorDesc);
                            // setErrorType("edit");
                            setOpenErrorModal(true);
                            toggleLoader(false);
                          });
                      }}
                    />
                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="responseOptionId"
                    />
                  </Grid>
                </Grid>
              </DialogContent>

              <DialogActions sx={{ padding: "12px 20px" }}>
                <Box>
                  <Grid
                    className="flex__ justifyContent-FlexEnd"
                    container
                    direction="row"
                    alignItems="right"
                  >
                    <Grid item>
                      <ButtonComponent
                        sxProps={{ color: "#B6C2CF !important" }}
                        className="btn-primary btn-cancel mr-md"
                        variantType="outlined"
                        defaultLabelId={"Reset"}
                        labelId={"common.reset"}
                        onClick={() => formikRef.current?.resetForm()}
                        disabled={!dirty}
                      />
                    </Grid>
                    <Grid item>
                      <ButtonComponent
                        className="btn-primary btn-submit"
                        variantType="contained"
                        defaultLabelId={"Save"}
                        labelId={id ? "btn.update" : "btn.Add"}
                        onClick={() => {
                          handleSubmit();
                          setIsVersionRequired(false);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </DialogActions>
            </Dialog>
          );
        }}
      </Formik>
    </>
  );
};

export default AddEditPermitLineItemMapModal;
