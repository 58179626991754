import { useState, useEffect } from "react";

const useAvailableWidth = (offset: number = 0) => {
  const [availableWidth, setAvailableWidth] = useState(
    window.innerWidth - offset
  );

  useEffect(() => {
    const handleResize = () => {
      setAvailableWidth(window.innerWidth - offset);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [offset]);

  return availableWidth;
};

export default useAvailableWidth;
