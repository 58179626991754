import { Box, Grid } from "@mui/material";
import MediumTypography from "../components/formlib/MediumTypography";
import "./scheduler/css/TeamMembers.css";
import ButtonComponent from "../components/formlib/ButtonComponent";
import TeamMembersComponent, {
  TeamMemberComponentProps,
} from "../components/formlib/TeamMemberComponent";
import SearchBox from "../components/formlib/SearchBox";
import React, { FC, useEffect, useState } from "react";
import { ShiftsCardType, TeamMemberType, TechnicianType } from "../utils/type";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { ReactComponent as CloseIcon } from "../assets/images/CloseIcon.svg";
import {
  ReassignMembersProps,
  reAssignMembers,
} from "../api/workOrderApi/schedulerApi/Scheduler";
import ModalPopup from "../components/formlib/modal/ModalPopup";
import ErrorModal from "../components/formlib/modal/ErrorModal";

import { hasPermission } from "../utils/checkPermission";
import { handleError } from "../utils/commonFunctions";
const moment = require("moment");

export interface CardDetailsType {
  cardId: number;
  sequenceId: number;
  type?: string;
  code?: string;
  frequency?: string;
  assetCategory?: string;
  asset?: string;
  status?: string;
  statusId?: number;
  cardCount?: number;
  description?: string;
  members: TeamMemberType[];
  processedOn?: string;
  processedCount?: number;
  processedBy?: string;
  shiftStartTime?: string;
  profile?: string;
  shiftEndTime?: string;
  shift?: string;
  shiftId?: number;
  shiftName?: string;
  sequenceCount?: number;
  id?: number;
  woMasterId?: number;
  assetCategoryId?: number;
  assetCategoryName?: string;
  dispatchStatusType?: string;
  dispatchMessage?: string;
  scheduledStartTime?: string;
  scheduledEndTime?: string;
  actualStartTime?: string;
  actualEndTime?: string;
  progress?: number;
  maxStaffCount: number;
  version: number;
  scheduledDate?: string;
  isAssetGrouped?: boolean;
  isPlanned?: boolean;
  assetGroupId?: number;
  statusCode?: string;
  estimatedDuration?: number;
}

interface TeamMembersShiftsProps {
  teamData: TeamMemberComponentProps[];
  cardDetails: CardDetailsType;
  totalUserCount: number | undefined;
  presentUserCount: number | undefined;
  cardShiftDetails: ShiftsCardType | undefined;
  currentDate: Date;
  isDragEnabled: boolean;
  reAssignCallBack: () => void;
  allShiftData: ShiftsCardType[];
}

const ReassignSidebar: FC<TeamMembersShiftsProps> = ({
  teamData,
  totalUserCount,
  presentUserCount,
  cardDetails,
  cardShiftDetails,
  currentDate,
  reAssignCallBack,
  allShiftData,
  isDragEnabled,
}) => {
  const shiftTypes = Array.from(
    new Set(teamData.map((item) => item.shiftType))
  );

  const shiftsToDisplay = cardShiftDetails?.shiftName
    ? shiftTypes.filter(
        (shiftType) => shiftType === cardShiftDetails.shiftName[0]
      )
    : shiftTypes;

  const [teamDataSet, setTeamData] = useState(teamData);
  const [initialTeamData, setInitialTeamData] = useState(teamData);

  const [cardMembersSet, setCardMembers] = useState(cardDetails.members);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedDragDropResult, setSelectedDragResult] = useState();
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [errorDesc, setErrorDesc] = useState<string>("");
  const [searchQuery, setSearchQuery] = useState("");
  const [warningStatus, setWarningStatus] = useState(false);
  const [openReassignModal, setOpenReassignModal] = useState(false);

  // useEffect(() => {
  //   reassignMembersApi();
  // }, [warningStatus]);

  useEffect(() => {
    // Set the initial teamData state

    setTeamData(teamData);
    setInitialTeamData(teamData);
    if (cardDetails.members) {
      // Map through cardDetails.members to update each member with availability from teamData
      const updatedCardMembers = cardDetails.members.map((cardMember) => {
        const teamMember = teamData.find(
          (team) => team.data.id === cardMember.id
        );
        return teamMember
          ? { ...cardMember, availability: teamMember.data.availability }
          : cardMember;
      });

      setCardMembers(updatedCardMembers);

      // Filter out team members whose IDs are present in cardMembers
      const filteredTeamData = teamData.filter(
        (teamMember) =>
          !cardDetails.members.some(
            (cardMember) => cardMember.id === teamMember.data.id
          )
      );

      setTeamData(filteredTeamData);
      setInitialTeamData(filteredTeamData);
    }
  }, [teamData, cardDetails]);

  const onDragEnd = (result: any) => {
    const { source, destination } = result;

    // If there's no destination, the item was dropped outside of any droppable area
    if (!destination) return;

    const draggedItemIndex = teamDataSet.findIndex(
      (item) => item.data.id === source.index
    );

    const draggedItem = teamDataSet[draggedItemIndex];

    if (draggedItem.data.availability === "0 hr") {
      setOpenModal(!openModal);
      setSelectedDragResult(result);
    } else {
      const newTeamDataSet = Array.from(teamDataSet);

      newTeamDataSet.splice(draggedItemIndex, 1);

      const newCardMembersSet = Array.from(cardMembersSet);
      newCardMembersSet.splice(destination.index, 0, draggedItem.data);

      setTeamData(newTeamDataSet);
      setInitialTeamData(
        initialTeamData.filter((item) => item.data.id !== draggedItem.data.id)
      );

      setCardMembers(newCardMembersSet);
    }
  };

  const performDrag = (result: any) => {
    const { source, destination } = result;
    const draggedItemIndex = teamDataSet.findIndex(
      (item) => item.data.id === source.index
    );

    const draggedItem = teamDataSet[draggedItemIndex];
    const newTeamDataSet = Array.from(teamDataSet);

    newTeamDataSet.splice(draggedItemIndex, 1);

    const newCardMembersSet = Array.from(cardMembersSet);
    newCardMembersSet.splice(destination.index, 0, draggedItem.data);

    setTeamData(newTeamDataSet);
    setInitialTeamData(
      initialTeamData.filter((item) => item.data.id !== draggedItem.data.id)
    );

    setCardMembers(newCardMembersSet);
  };

  const removeTeamMember = (member: TechnicianType, shiftType: string) => {
    const memberIndex = cardMembersSet.findIndex(
      (item) => item.id === member.id
    );

    if (memberIndex !== -1) {
      // Remove the member from cardMembersSet
      const newCardMembersSet = Array.from(cardMembersSet);
      newCardMembersSet.splice(memberIndex, 1);

      // Add the removed member back to teamDataSet
      const newTeamDataSet = [
        ...teamDataSet,
        { data: member, shiftType: shiftType },
      ];

      // Update the states
      setCardMembers(newCardMembersSet);
      setTeamData(newTeamDataSet);
      setInitialTeamData(newTeamDataSet);
    }
  };

  const reassignMembersApi = () => {
    const startTime = `${moment(currentDate).format("YYYY-MM-DD")} ${
      cardShiftDetails?.shiftStartTime
    }`;
    const endTime = `${moment(currentDate).format("YYYY-MM-DD")} ${
      cardShiftDetails?.shiftEndTime
    }`;

    if (cardMembersSet === undefined) {
      return;
    }
    const reassignedTeamMembers = cardMembersSet.map((member, index) => ({
      assigneeId: parseInt(member.id.toString(), 10),
      isLeadAssignee: index === 0,
      isPrimaryAssignee: index === 0,
    }));
    let obj: ReassignMembersProps = {
      woUniqueId: cardDetails.sequenceId,
      shiftId: cardShiftDetails?.shiftId ? cardShiftDetails?.shiftId : 0,
      startTime: moment(startTime).format("YYYY-MM-DDTHH:mm:ss"),
      endTime: moment(endTime).format("YYYY-MM-DDTHH:mm:ss"),
      currentDate: `${moment(currentDate).format("YYYY-MM-DD")}`,
      reassignedUsers: reassignedTeamMembers,
      version: cardDetails.version,
    };

    reAssignMembers(cardDetails.cardId, warningStatus, obj, "workorder")
      .then((response) => {
        if (response.processCode === "WARNING") {
          setOpenReassignModal(true);
        } else {
          setTeamData([]);
          setInitialTeamData([]);
          setOpenReassignModal(false);
          reAssignCallBack();
          setCardMembers([]);
        }
      })
      .catch((err) => {
        setOpenErrorModal(true);
        setOpenReassignModal(false);
        handleError(err, setErrorDesc);
      });
  };

  const handleSearchChange = (value: string) => {
    setSearchQuery(value);
  };

  useEffect(() => {
    if (!searchQuery) {
      setTeamData(initialTeamData); // Reset to the initial unfiltered data
    } else {
      const filteredTeamDataSet = initialTeamData.filter((item) =>
        item.data.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setTeamData(filteredTeamDataSet);
    }
  }, [searchQuery, initialTeamData]);

  return (
    <>
      {openModal && (
        <ModalPopup
          descriptionText="ReAssign.ValidationMesg"
          open={openModal}
          handleClose={() => setOpenModal(false)}
          onPositiveClick={() => {
            setOpenModal(false);
            performDrag(selectedDragDropResult);
          }}
          onNegativeClick={() => {
            setOpenModal(false);
          }}
          positiveButtonLabel="Yes"
          positiveButtonLabelId="YesText"
          negativeButtonLabel="No"
          negativeButtonLabelId="NoText"
        />
      )}
      {openReassignModal && (
        <ModalPopup
          descriptionText="ReAssign.Description"
          open={openReassignModal}
          handleClose={() => setOpenReassignModal(false)}
          onPositiveClick={() => {
            setWarningStatus(true);
            reassignMembersApi();
          }}
          onNegativeClick={() => {
            setOpenReassignModal(false);
          }}
          positiveButtonLabel="Yes"
          positiveButtonLabelId="YesText"
          negativeButtonLabel="No"
          negativeButtonLabelId="NoText"
        />
      )}
      {openErrorModal && (
        <ErrorModal
          descriptionText={errorDesc}
          open={openErrorModal}
          handleClose={() => setOpenErrorModal(false)}
          onPositiveClick={() => {
            setOpenErrorModal(false);
          }}
        />
      )}

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable_area">
          {(provided, snapshot) => (
            <Box ref={provided.innerRef} {...provided.droppableProps}>
              <Box component="div" className="">
                <Box className="teamMember_container_scheduler">
                  <MediumTypography
                    labelId="TeamMembers.title"
                    defaultLabel="Team Members"
                    fontSize="16px"
                    fontWeight={600}
                  />
                  <Box className="teamMember_count">
                    <MediumTypography
                      label={`${
                        presentUserCount ? presentUserCount?.toString() : 0
                      }/${totalUserCount?.toString()}`}
                      fontSize="12px"
                      fontWeight={400}
                    />
                  </Box>
                </Box>
                {teamData.length > 0 && (
                  <Box
                    sx={{
                      overflowY: "auto",
                      minHeight: "530px",
                      maxHeight: "530px",
                    }}
                    className="tableStyles"
                  >
                    <Box component="div" className="padding_12">
                      <Box
                        id="droppable_area"
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        <Box className="mb-sm">
                          <Box className="flex__">
                            <Grid container gap="12px">
                              {allShiftData &&
                                allShiftData.map((shift) => (
                                  <Grid item className="shift_count" xs={2.5}>
                                    <Box className="shift_btn_grey">
                                      <MediumTypography
                                        labelId={shift.shiftName}
                                        defaultLabel={shift.shiftName}
                                        fontSize="12px"
                                        fontWeight={800}
                                        textColor="#8C9BAB"
                                      />
                                    </Box>
                                    <MediumTypography
                                      label={
                                        String(
                                          shift.memberCount < 10 ? "0" : ""
                                        ) + shift.memberCount.toString()
                                      }
                                      fontSize="14px"
                                      textColor="#8C9BAB"
                                      fontWeight={600}
                                      className="ml-xs"
                                    />
                                  </Grid>
                                ))}
                            </Grid>
                          </Box>
                        </Box>

                        {cardMembersSet?.length > 0 &&
                          cardMembersSet?.map((cardMember) => (
                            <ul className="no-marker pt-sm">
                              <TeamMembersComponent
                                key={cardMember.id}
                                data={cardMember as TechnicianType}
                                checkBoxRequired={false}
                                isAvailabilityRequired={true}
                                statusNotRequired={true}
                                icon={<CloseIcon />}
                                shiftType={cardShiftDetails?.shiftName[0]}
                                shiftStartTime={
                                  cardShiftDetails?.shiftStartTime
                                }
                                shiftEndTime={cardShiftDetails?.shiftEndTime}
                                iconCallBackChange={(member, shiftType) =>
                                  removeTeamMember(member, shiftType)
                                }
                              />
                            </ul>
                          ))}
                        {provided.placeholder}
                        {cardMembersSet &&
                          cardMembersSet.length < cardDetails.maxStaffCount && (
                            <>
                              {cardMembersSet.length === 0 ? (
                                <ButtonComponent
                                  className="btn-primary btn-cancel leadAssignee_button"
                                  labelId="LeadAssignee.addButton"
                                  defaultLabelId="ADD LEAD ASSIGNEE"
                                  variantType="outlined"
                                  onClick={() => {}}
                                />
                              ) : (
                                <ButtonComponent
                                  className="btn-primary btn-cancel teammember_button mb-md"
                                  labelId="TeamMember.addButton"
                                  defaultLabelId="ADD TEAM MEMBER"
                                  variantType="outlined"
                                  onClick={() => {}}
                                />
                              )}
                            </>
                          )}
                        {cardMembersSet?.length === 0 &&
                          teamData.length > 0 && (
                            <Box className="assign_technician">
                              <MediumTypography
                                labelId="TeamMember.assign"
                                defaultLabel="Assign Technicians"
                                fontSize="12px"
                                textColor="rgba(159, 173, 188, 1)"
                                fontWeight={600}
                              />
                            </Box>
                          )}

                        {cardMembersSet?.length > 0 && (
                          <Box
                            className={`assign_technician ${
                              !hasPermission("Assign_Team_Member")
                                ? "cursor__block"
                                : "cursor__pointer"
                            }`}
                            sx={{
                              backgroundColor: "rgba(23, 95, 250, 1)",
                            }}
                            onClick={() =>
                              hasPermission("Assign_Team_Member")
                                ? reassignMembersApi()
                                : () => {}
                            }
                          >
                            <MediumTypography
                              labelId="ReAssigntext"
                              defaultLabel="Re-assign Technician"
                              fontSize="12px"
                              sxProps={{
                                backgroundColor: "rgba(23, 95, 250, 1)",
                              }}
                              textColor="rgba(159, 173, 188, 1)"
                              fontWeight={600}
                            />
                          </Box>
                        )}
                        {cardMembersSet?.length > 0 && (
                          <Box className="mt-md flex__justify__space-between">
                            <Box className="dividerLineHorizantal_25" />
                            <MediumTypography
                              labelId="TeamMember.drag"
                              defaultLabel="DRAG DROP TO ADD"
                              fontSize="12px"
                              textColor="#333B43"
                              className="p-xs"
                            />
                            <Box className="dividerLineHorizantal_25" />
                          </Box>
                        )}
                        <Box className="mt-md">
                          <SearchBox
                            labelId="defaultSearch"
                            defaultlabel="Search"
                            backgroundColor="#22272B"
                            sxProps={{
                              minWidth: "100% !important",
                              textAlign: "center",
                              alignContent: "center",
                              justifyItems: "center",
                            }}
                            onChange={handleSearchChange}
                          />
                        </Box>
                      </Box>
                      {shiftsToDisplay.map((shiftType, i) => (
                        <React.Fragment key={shiftType}>
                          <Box className="mt-md flex__justify__space-between">
                            <Box className="dividerLineHorizantal_35" />
                            <MediumTypography
                              label={`Shift ${shiftType}`}
                              fontSize="12px"
                              textColor="#333B43"
                              className="p-sm"
                            />
                            <Box className="dividerLineHorizantal_35" />
                          </Box>
                          {teamDataSet
                            .filter((item) => item.shiftType === shiftType)
                            .map((item, index) => (
                              <Draggable
                                key={`${item.data.id}${index}${index}`}
                                draggableId={`${item.data.id}${index}${index}`}
                                index={item.data.id}
                                isDragDisabled={
                                  !isDragEnabled ||
                                  !cardMembersSet ||
                                  cardMembersSet.length >=
                                    cardDetails.maxStaffCount
                                }
                              >
                                {(provided2, snapshot2) => (
                                  <Box
                                    className="mt-md"
                                    ref={provided2.innerRef}
                                    {...provided2.draggableProps}
                                    {...provided2.dragHandleProps}
                                  >
                                    <TeamMembersComponent
                                      {...item}
                                      isAvailabilityRequired={true}
                                      statusNotRequired={true}
                                    />
                                  </Box>
                                )}
                              </Draggable>
                            ))}
                        </React.Fragment>
                      ))}
                    </Box>
                  </Box>
                )}
                <Box sx={{ padding: "8px" }}>
                  {teamData.length <= 0 && (
                    <ButtonComponent
                      className="btn-primary btn-cancel teammember_button mb-md"
                      labelId="NoShiftsText"
                      defaultLabelId="No Shifts"
                      sxProps={{ cursor: "not-allowed" }}
                      variantType="outlined"
                      onClick={() => {}}
                    />
                  )}
                </Box>
              </Box>
            </Box>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

export default ReassignSidebar;
