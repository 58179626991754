import * as React from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import MediumTypography from "./MediumTypography";

interface VerticalSliderProps {
  width?: string | number;
  height?: string | number;
  thumbColor?: string;
  thumbWidth?: string | number;
  thumbHeight?: string | number;
  thumbShadowWidth?: string | number;
  thumbShadowColor?: string;
  fillColor?: string;
  bgColor?: string;
  borderRadius?: string | number;
  fontSize?: number | string;
  fontWeight?: number | string;
  fontColor?: string;
  inputValue?: number;
  onInputChange?: (value: number) => void;
  [key: string]: any;
  // Add any other props you want to accept
}

const VerticalSlider: React.FC<VerticalSliderProps> = ({
  width = "20px",
  height = "216px",
  thumbColor = "#FFF",
  thumbWidth = "20px",
  thumbHeight = "20px",
  thumbShadowWidth = "10px",
  thumbShadowColor = "rgb(29, 33, 37)",
  fillColor = "rgba(12, 102, 228, 1)",
  bgColor = "rgba(255, 255, 255, 0.16)",
  borderRadius = "48px",
  fontSize = "18px",
  fontWeight = "600",
  fontColor = "#FFF",
  inputValue = 0,
  onInputChange,
  ...props
}) => {
  function preventHorizontalKeyboardNavigation(event: React.KeyboardEvent) {
    if (event.key === "ArrowLeft" || event.key === "ArrowRight") {
      event.preventDefault();
    }
  }

  const handleChange = (event: Event, newValue: number | number[]) => {
    if (onInputChange) {
      onInputChange(newValue as number);
    }
  };

  return (
    <Box>
      <Box sx={{ textAlign: "center", mb: "20px" }}>
        <MediumTypography
          label={`${inputValue}%`}
          fontSize={fontSize as string}
          fontWeight={fontWeight as number}
          textColor={fontColor}
        />
      </Box>
      <Slider
        value={inputValue}
        onChange={handleChange}
        sx={{
          '& input[type="range"]': {
            WebkitAppearance: "slider-vertical",
          },
          "& .MuiSlider-thumb": {
            backgroundColor: thumbColor, // thumb color
            width: thumbWidth, // change the width
            height: thumbHeight, // change the height
            boxShadow: `0px 0px 0px ${thumbShadowWidth} ${thumbShadowColor}`, // border
            "&:hover, &.Mui-active, &.Mui-focusVisible": {
              backgroundColor: thumbColor, // thumb color
              width: thumbWidth, // change the width
              height: thumbHeight, // change the height
              boxShadow: `0px 0px 0px ${thumbShadowWidth} ${thumbShadowColor}`, // border
            },
          },
          "& .MuiSlider-track": {
            backgroundColor: fillColor, // fill color
          },
          "& .MuiSlider-rail": {
            backgroundColor: bgColor, // background color
          },
          width: width, // change the width
          height: height, // change the height
          borderRadius: borderRadius, // change the radius
        }}
        orientation="vertical"
        aria-label="Temperature"
        valueLabelDisplay="off"
        onKeyDown={preventHorizontalKeyboardNavigation}
        {...props} // Pass any other props to the Slider component
      />
    </Box>
  );
};

export default VerticalSlider;
