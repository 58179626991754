// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#monthlyPlanner .fc .fc-scrollgrid-section-sticky > th {
  background-color: transparent;
}

#monthlyPlanner .fc .fc-col-header-cell-cushion {
  color: #ffff;
  padding: 4px 16px 8px 16px;
}

#monthlyPlanner .fc th {
  text-align: right;
}

#monthlyPlanner .fc .fc-daygrid-day-number {
  color: #ffff;
}

.fc .fc-daygrid-body {
  border: 1px solid #333b43 !important;
}
#monthlyPlanner .fc-theme-standard td {
  border: 1px solid #333b43 !important;
}

.fc .fc-daygrid-day-frame {
  padding: 12px;
  height: 150px;
}
.fc .fc-daygrid-day-frame:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.fc .fc-daygrid-body-natural .fc-daygrid-day-events {
  display: flex;
  top: -30px;
}
.fc-h-event {
  border: none;
  background-color: transparent;
}
.fc-day-sun {
  background-color: rgba(255, 255, 255, 0.04);
}
.fc-col-header-cell.fc-day.fc-day-sun {
  background-color: transparent !important;
}

.fc td {
  cursor: pointer;
}
.fc-day-hover {
  /* triggered when hovering over calendar date cells */
  background-color: rgba(0, 0, 0, 0.05);
}
`, "",{"version":3,"sources":["webpack://./src/pages/shifts/ShiftPlanner.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;AAC/B;;AAEA;EACE,YAAY;EACZ,0BAA0B;AAC5B;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,oCAAoC;AACtC;AACA;EACE,oCAAoC;AACtC;;AAEA;EACE,aAAa;EACb,aAAa;AACf;AACA;EACE,oCAAoC;AACtC;AACA;EACE,aAAa;EACb,UAAU;AACZ;AACA;EACE,YAAY;EACZ,6BAA6B;AAC/B;AACA;EACE,2CAA2C;AAC7C;AACA;EACE,wCAAwC;AAC1C;;AAEA;EACE,eAAe;AACjB;AACA;EACE,qDAAqD;EACrD,qCAAqC;AACvC","sourcesContent":["#monthlyPlanner .fc .fc-scrollgrid-section-sticky > th {\n  background-color: transparent;\n}\n\n#monthlyPlanner .fc .fc-col-header-cell-cushion {\n  color: #ffff;\n  padding: 4px 16px 8px 16px;\n}\n\n#monthlyPlanner .fc th {\n  text-align: right;\n}\n\n#monthlyPlanner .fc .fc-daygrid-day-number {\n  color: #ffff;\n}\n\n.fc .fc-daygrid-body {\n  border: 1px solid #333b43 !important;\n}\n#monthlyPlanner .fc-theme-standard td {\n  border: 1px solid #333b43 !important;\n}\n\n.fc .fc-daygrid-day-frame {\n  padding: 12px;\n  height: 150px;\n}\n.fc .fc-daygrid-day-frame:hover {\n  background-color: rgba(0, 0, 0, 0.1);\n}\n.fc .fc-daygrid-body-natural .fc-daygrid-day-events {\n  display: flex;\n  top: -30px;\n}\n.fc-h-event {\n  border: none;\n  background-color: transparent;\n}\n.fc-day-sun {\n  background-color: rgba(255, 255, 255, 0.04);\n}\n.fc-col-header-cell.fc-day.fc-day-sun {\n  background-color: transparent !important;\n}\n\n.fc td {\n  cursor: pointer;\n}\n.fc-day-hover {\n  /* triggered when hovering over calendar date cells */\n  background-color: rgba(0, 0, 0, 0.05);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
