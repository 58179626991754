import React from "react";
import {
  Avatar,
  AvatarGroup,
  ThemeProvider,
  Tooltip,
  TooltipProps,
  createTheme,
  styled,
  tooltipClasses,
} from "@mui/material";
import MediumTypography from "./MediumTypography";
import { ReactComponent as UserprofileIcon } from "../../assets/images/UserprofileIcon.svg";

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className ?? "" }} />
))`
  & .${tooltipClasses.tooltip} {
    background-color: rgba(29, 33, 37, 0.9);
  }

  & .${tooltipClasses.arrow} {
    color: rgba(29, 33, 37, 0.9);
  }

  & .tooltip-text {
    font-family: Inter;
    font-size: 10px;
  }
`;

interface UserWithImage {
  id: number;
  name: string;
  image: string;
  title?: string;
  availability?: string;
}

interface UserWithoutImage {
  id: number;
  name: string;
  image?: never;
  title?: string;
  availability?: string;
}

type User = UserWithImage | UserWithoutImage;

interface UserStackProps {
  users: User[];
  showMax: number;
  maxStaffCount?: number;
}

const avatarTheme = createTheme({
  components: {
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: 27,
          height: 27,
          border: "0.7px solid rgba(34, 39, 43, 1) !important",
        },
      },
    },
  },
});

const UserStack: React.FC<UserStackProps> = ({
  users,
  showMax,
  maxStaffCount,
}) => {
  let placeholdersNeeded: number = 0;
  if (maxStaffCount) {
    placeholdersNeeded = maxStaffCount - users.length;
  }

  // Create placeholder users
  const placeholders = Array.from(
    { length: placeholdersNeeded },
    (_, index) => ({
      id: users.length + index + 1,
      name: "Assign",
    })
  );

  // Merge actual users with placeholders
  const allUsers = [...users, ...placeholders];

  // Determine users to show and the extra count
  let usersSliced = allUsers.slice(0, showMax);
  let extraUsersCount = allUsers.length - showMax;
  let usersRemaining = allUsers.slice(showMax);

  const usersRemainingText = usersRemaining.map((user) => user.name).join(", ");

  return (
    <ThemeProvider theme={avatarTheme}>
      <AvatarGroup sx={{ alignItems: "center" }}>
        {usersSliced.map((user, index) => (
          <CustomTooltip
            key={index}
            title={
              <MediumTypography className="tooltip-text" label={user.name} />
            }
            arrow
          >
            {"image" in user ? (
              <div
                style={{
                  width: "32px",
                  height: "32px",
                  backgroundImage: `url(${user.image})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  borderRadius: "50%",
                }}
              ></div>
            ) : (
              <Avatar sx={{ zIndex: 1, width: 32, height: 32 }}>
                <UserprofileIcon style={{ width: 32, height: 32 }} />
              </Avatar>
            )}
          </CustomTooltip>
        ))}

        {extraUsersCount > 0 && (
          <CustomTooltip title={usersRemainingText} arrow>
            <Avatar
              alt=""
              src=""
              sx={{
                height: "13px",
                padding: "2.36px 0.5px 1.5px",
                backgroundColor: "rgba(90, 96, 127, 1)",
                color: "#FFF",
                fontFamily: "Inter",
                fontSize: "10px",
                fontWeight: "700",
                borderRadius: "21.28px",
                zIndex: 1,
                position: "relative",
              }}
            >
              +{extraUsersCount}
            </Avatar>
          </CustomTooltip>
        )}
      </AvatarGroup>
    </ThemeProvider>
  );
};

export default UserStack;
