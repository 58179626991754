import { Box } from "@mui/material";
import React from "react";

const SmallBorderLine = () => {
  return (
    <Box
      sx={{
        width: "0px",
        height: "24px",
        borderLeft: "2px solid #FFFFFF",
        padding: "4px 0px",
        position: "relative",
        top: "2px",
      }}
    ></Box>
  );
};

export default SmallBorderLine;
