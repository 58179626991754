import { Box } from "@mui/material";
import "./css/Login.css";
import MediumTypography from "../../components/formlib/MediumTypography";
import TextInput from "../../components/formlib/TextInput";
import { ReactComponent as ParamLogo } from "../../assets/images/ParamLogo.svg";
import ButtonComponent from "../../components/formlib/ButtonComponent";
import { ReactComponent as Google } from "../../assets/images/googleIcon.svg";
import { Formik, FormikProps } from "formik";
import { SignInType } from "../../utils/type";
import { FC, useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { ReactComponent as ErrorIcon } from "../../assets/images/ErrorLogo.svg";
import { useError } from "./context/ApiErrorContext";
import PasswordTextField from "../../components/formlib/PasswordTextField";

const initialValues: SignInType = {
  username: "",
  password: "",
};

export interface LoginBody {
  handleSubmitData: (data: SignInType) => void;
  onForgotPassword: () => void;
  testId?: string;
}

const validationSchema = Yup.object({
  username: Yup.string().required("Login.errorUserName"),
  password: Yup.string().required("Login.errorPassword"),
});

const Login: FC<LoginBody> = ({
  handleSubmitData,
  onForgotPassword,
  testId,
}) => {
  const formRef = useRef<FormikProps<SignInType>>(null);
  const { error, setError } = useError();
  const [showPassword, setShowPassword] = useState(false);
  const handleLogin = (values: SignInType) => {
    handleSubmitData(values);
  };
  const handleForgotPassword = () => {
    onForgotPassword();
  };

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        if (formRef.current) {
          formRef.current.handleSubmit(); // Trigger Formik's submit
        }
      }
    };

    // Add event listener for keydown
    window.addEventListener("keydown", handleKeyPress);

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  return (
    <Box className="mainContainer login" data-testid={testId}>
      <Formik
        initialValues={initialValues}
        validateOnMount={true}
        innerRef={formRef}
        enableReinitialize={true}
        onSubmit={handleLogin}
        validationSchema={validationSchema}
      >
        {({ handleSubmit, setFieldValue, values, errors, touched }) => (
          <Box sx={{ padding: "65px" }}>
            <ParamLogo data-testid="param-logo" />

            <MediumTypography
              className="title"
              labelId="Login.title"
              defaultLabel="Login to your account"
            />
            <MediumTypography
              className="subText"
              labelId="Login.subTitle"
              defaultLabel="Welcome back! Select method to log in"
            />
            <Box className="inputContainer">
              <MediumTypography
                className="inputLabel"
                labelId="Login.userId"
                defaultLabel="User ID"
              />
              <TextInput
                labelId="Login.userIdPlaceHolder"
                defaultLabelId="Enter user id"
                variant="outlined"
                className="textinput"
                fieldheight="40px"
                Value={values.username}
                autoComplete={"on"}
                inputLabelProps={{ shrink: false }}
                name="username"
                inputProps={{
                  maxLength: 50,
                }}
                handlechange={(value: string) => {
                  setError("");
                  setFieldValue("username", value);
                }}
                onKeyPress={(event: React.KeyboardEvent<HTMLDivElement>) => {
                  if (event.key === "Enter") {
                    handleSubmit();
                  }
                }}
              />
              {errors.username && touched.username && (
                <Box className="loginError">
                  <Box>
                    <ErrorIcon />
                  </Box>
                  <MediumTypography
                    labelId={errors.username}
                    defaultLabel="Username is required"
                    fontSize="12px"
                    textColor="#AE2E24"
                    fontWeight={400}
                  />
                </Box>
              )}
              {error !== "" && error !== undefined && error !== null && (
                <Box className="loginError">
                  <Box>
                    <ErrorIcon />
                  </Box>
                  <MediumTypography
                    labelId={error}
                    fontSize="12px"
                    textColor="#AE2E24"
                    fontWeight={400}
                  />
                </Box>
              )}
              <Box sx={{ marginTop: "16px" }}>
                <MediumTypography
                  className="inputLabel pt-sm"
                  labelId="Login.password"
                  defaultLabel="Password"
                />
                <PasswordTextField
                  labelId="Login.passwordPlaceHolder"
                  defaultLabelId="Enter password"
                  className="textinput"
                  fieldheight="40px"
                  autoComplete={"on"}
                  name="password"
                  showpassword={showPassword}
                  handleTogglePasswordVisibility={() => {
                    setShowPassword(!showPassword);
                  }}
                  onKeyPress={(event: React.KeyboardEvent<HTMLDivElement>) => {
                    if (event.key === "Enter") {
                      handleSubmit();
                    }
                  }}
                  InputProps={{
                    maxLength: 20,
                  }}
                  Value={values.password}
                  handlechange={(
                    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                  ) => {
                    setError("");
                    setFieldValue("password", e.target.value);
                  }}
                />
                {errors.password && touched.password && (
                  <Box className="loginError">
                    <ErrorIcon />
                    <MediumTypography
                      labelId={errors.password}
                      defaultLabel="Password is required"
                      fontSize="12px"
                      textColor="#AE2E24"
                      fontWeight={400}
                    />
                  </Box>
                )}
              </Box>
            </Box>
            <Box className="forgotPassword">
              <MediumTypography
                className="forgotPasswordText"
                labelId="Login.forgot"
                defaultLabel="Forgot Password?"
                onClick={handleForgotPassword}
              />
            </Box>
            <Box sx={{ marginTop: "24px" }}>
              <ButtonComponent
                type="submit"
                labelId="Login.Primarybutton"
                variantType="outlined"
                className="btn-primary btn-submit width__100 mt-sm"
                defaultLabelId="Login"
                onClick={handleSubmit}
              />
              <MediumTypography
                labelId="Login.Or"
                defaultLabel="OR"
                className="mt-md orText"
              />
              <ButtonComponent
                variantType="outlined"
                labelId="Login.GoogleBtn"
                className="btn-primary btn-GoogleBtn width__100 mt-sm"
                defaultLabelId="Continue with Google"
                LeftIcon={<Google />}
              />
            </Box>
          </Box>
        )}
      </Formik>
    </Box>
  );
};
export default Login;
