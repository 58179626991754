import { useEffect, useState, useRef } from "react";
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemButton,
  Typography,
  IconButton,
  Popover,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import TextInput from "../../../components/formlib/TextInput";
import SearchBox from "../../../components/formlib/SearchBox";
import { ReactComponent as Add } from "../../../assets/images/add.svg";
import { ReactComponent as CrossIcon } from "../../../assets/images/_CrossIcon_.svg";
import MediumTypography from "../../../components/formlib/MediumTypography";
import AddSharpIcon from "@mui/icons-material/AddSharp";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import MenuIcon from "@mui/icons-material/Menu";

import {
  RolePermissionMappingType,
  PermissionRecordType,
  PermissionRecordTypeSort,
} from "../types/rolesAndPermission";
import { ReactComponent as RoleSave } from "../../../assets/images/tickAdd-circle.svg";
import React from "react";
import useAvailableHeight from "../../../utils/useAvailableHeight";

interface header {
  key: string;
  value: string;
}

const headers = [
  {
    key: "assign_permission",
    labelId: "assignedPermission.text",
    defaultLabelId: "Assigned Permission",
  },
  {
    key: "available_permission",
    labelId: "availablePermission.text",
    defaultLabelId: "Available Permission",
  },
];

interface AssignedPermissionProps {
  roleNotSaved: boolean;
  permissionData: PermissionRecordType[];
  mapData: RolePermissionMappingType[];
  selectedRoleId: number | null | undefined;
  getAssignPermission: (data: PermissionRecordType[]) => void;
  handleAddRolePermission: (
    rolename: string,
    type: string,
    originalVal: string,
    rowId?: number
  ) => void;
}
export default function AssignedPermission(props: AssignedPermissionProps) {
  const {
    permissionData,
    mapData,
    selectedRoleId,
    getAssignPermission,
    handleAddRolePermission,

    roleNotSaved,
  } = props;

  const [searchAssigned, setSearchAssigned] = useState<string>("");
  const [searchAvailable, setSearchAvailable] = useState<string>("");
  const [showSearchAssigned, setShowSearchAssigned] = useState<boolean>(false);
  const [showSearchAvailable, setShowSearchAvailable] =
    useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [available, setAvailable] = useState<PermissionRecordTypeSort[]>([]);
  const [assigned, setAssigned] = useState<PermissionRecordTypeSort[]>([]);
  const [focusedRoleIndex, setFocusedRoleIndex] = useState<number | null>(null);
  const [anchorElAssigned, setAnchorElAssigned] = useState<null | HTMLElement>(
    null
  );
  const [anchorElAvailable, setAnchorElAvailable] =
    useState<null | HTMLElement>(null);
  const [sortOrderAssigned, setSortOrderAssigned] = useState<"asc" | "desc">(
    "asc"
  );
  const [sortOrderAvailable, setSortOrderAvailable] = useState<"asc" | "desc">(
    "asc"
  );
  const [searchValue, setSearchValue] = useState("");
  const isPopoverOpen = Boolean(anchorEl);

  const availableHeight = useAvailableHeight(274);
  const listRef = useRef<HTMLUListElement | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [settedIndex, setIndex] = useState<number>();
  let originalPermissionVal = useRef<string>("");

  const role = mapData.find(
    (r: RolePermissionMappingType) => r.roleId == selectedRoleId
  );
  const permissionIds: number[] | [] = role ? role.permissionIds : [];

  const filteredItems = permissionData?.filter(
    (permData: PermissionRecordType) =>
      permData.id !== null && permissionIds.includes(permData.id ?? 0)
  );

  const notfilteredItems = permissionData?.filter(
    (permData: PermissionRecordType) =>
      permData.id !== null && !permissionIds?.includes(permData.id ?? 0)
  );

  const handleEditClick = (index: number, name: string) => {
    setFocusedRoleIndex(index);
    setIndex(index);
    setIsEditing(true);
    originalPermissionVal.current = name;
  };
  const scrollToBottom = () => {
    if (listRef.current) {
      listRef.current.scrollTop = listRef.current.scrollHeight;
    }
  };
  const handleClosePopoverAssigned = () => {
    setAnchorElAssigned(null);
  };

  const handleClosePopoverAvailable = () => {
    setAnchorElAvailable(null);
  };

  const handleOpenPopoverAssigned = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElAssigned(event.currentTarget);
  };

  const handleOpenPopoverAvailable = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElAvailable(event.currentTarget);
  };

  const handleSearchAssignedChange = (value: string) => {
    setSearchAssigned(value);
  };

  const handleSearchAvailableChange = (value: string) => {
    setSearchAvailable(value);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleSearchChange = (value: string) => {
    setSearchValue(value);
  };

  useEffect(() => {
    setAvailable(notfilteredItems);
    setAssigned(filteredItems);
  }, [props.permissionData, selectedRoleId]);

  const handleAddPermission = () => {
    const newPermission = {
      name: "",
    };
    setAvailable([...available, newPermission]);

    scrollToBottom();
  };
  useEffect(() => {
    scrollToBottom();
  }, []);
  const handleAddChange = (roleIndex: number) => {
    const newAvailable = [...available];
    const selectedRole = newAvailable[roleIndex];
    const newAssignedRole = { ...selectedRole };
    setAssigned([...assigned, newAssignedRole]);
    setAvailable(newAvailable.filter((_, index) => index !== roleIndex));
    getAssignPermission([...assigned, newAssignedRole]);
  };

  const handleRemovePermission = (roleIndex: number) => {
    const removedRole = assigned[roleIndex];
    setAssigned(
      assigned.filter(
        (_: PermissionRecordType, index: number) => index !== roleIndex
      )
    );
    setAvailable([...available, removedRole]);

    getAssignPermission(
      assigned.filter(
        (_: PermissionRecordType, index: number) => index !== roleIndex
      )
    );
  };

  const handleSort = (key: string, isAssigned: boolean) => {
    const sortOrder = isAssigned ? sortOrderAssigned : sortOrderAvailable;
    const setSortOrder = isAssigned
      ? setSortOrderAssigned
      : setSortOrderAvailable;

    const sortedData =
      sortOrder === "asc"
        ? [...(isAssigned ? assigned : available)].sort((a, b) =>
            a[key].localeCompare(b[key])
          )
        : [...(isAssigned ? assigned : available)].sort((a, b) =>
            b[key].localeCompare(a[key])
          );

    isAssigned ? setAssigned(sortedData) : setAvailable(sortedData);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  const filteredAssigned = assigned.filter((role: PermissionRecordType) =>
    role?.name.toLowerCase().includes(searchAssigned.toLowerCase())
  );
  const filteredAvailable = available.filter((role: PermissionRecordType) =>
    role?.name.toLowerCase().includes(searchAvailable.toLowerCase())
  );

  const handleResetRole = (index: number | undefined | null) => {
    if (index === null || index === undefined) {
      return;
    } else {
      const newRoleData1 = [...available];
      if (originalPermissionVal.current !== undefined) {
        newRoleData1[index].name = originalPermissionVal.current;
      }

      setAvailable(newRoleData1);
    }
  };
  return (
    <Box className="flex__ pl-none" gap={1}>
      {headers.map((header, headerIndex) => {
        const roles = headerIndex === 0 ? filteredAssigned : filteredAvailable;
        const isAssigned = headerIndex === 0;

        return (
          <Box width={isAssigned ? "75%" : "100%"}>
            <Box
              className=" flex__ "
              sx={{
                bgcolor: "#22272B",
                flexDirection: "row",
                alignItems: "center",
                padding: "10px",
              }}
            >
              <MediumTypography
                sxProps={{
                  paddingLeft: "5px",
                  font: "Inter",
                  fontSize: "14px",
                  fontWeight: 700,
                  LineHeight: "16.94px",
                  alignItems: "right",
                }}
                labelId={header.labelId}
                defaultLabel={header.defaultLabelId}
              />
              <Box
                className="flex__"
                alignItems="center"
                sx={{
                  ml: 1,
                  "&:hover": {
                    ".sort-icon": {
                      visibility: "visible",
                    },
                  },
                }}
              >
                <IconButton
                  className="sort-icon"
                  sx={{
                    color: "#B0B0B0",
                    visibility: "hidden",
                  }}
                  onClick={() => handleSort("name", isAssigned)}
                >
                  {isAssigned ? (
                    sortOrderAssigned === "asc" ? (
                      <ArrowUpwardIcon sx={{ fontSize: "20px" }} />
                    ) : (
                      <ArrowDownwardIcon sx={{ fontSize: "20px" }} />
                    )
                  ) : sortOrderAvailable === "asc" ? (
                    <ArrowUpwardIcon sx={{ fontSize: "20px" }} />
                  ) : (
                    <ArrowDownwardIcon sx={{ fontSize: "20px" }} />
                  )}
                </IconButton>
              </Box>
              <IconButton
                sx={{
                  color: "grey",
                  width: "25px",
                  height: "25px",
                }}
                onClick={
                  isAssigned
                    ? handleOpenPopoverAssigned
                    : handleOpenPopoverAvailable
                }
              >
                <MenuIcon sx={{ fontSize: "17px" }} />
              </IconButton>
              {headerIndex !== 0 && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    ml: "auto",
                    cursor: "pointer",
                  }}
                  onClick={handleAddPermission}
                >
                  <Add />
                  <MediumTypography
                    labelId={"btn.Add"}
                    defaultLabel="Add"
                    textColor="#6CDDF8"
                    className="ml-xs"
                  />
                </Box>
              )}
            </Box>
            <Popover
              open={Boolean(anchorElAssigned)}
              anchorEl={anchorElAssigned}
              onClose={handleClosePopoverAssigned}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <Box p={2} width="250px" sx={{ backgroundColor: "#323337" }}>
                <SearchBox
                  labelId="defaultSearch"
                  defaultlabel="Search Assigned"
                  backgroundColor="#22272B"
                  sxProps={{
                    minWidth: "180px",
                  }}
                  value={searchAssigned}
                  onChange={handleSearchAssignedChange}
                  onCancel={handleClosePopoverAssigned}
                  cancelRequired={true}
                />
              </Box>
            </Popover>

            <Popover
              open={Boolean(anchorElAvailable)}
              anchorEl={anchorElAvailable}
              onClose={handleClosePopoverAvailable}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <Box p={2} width="250px" sx={{ backgroundColor: "#323337" }}>
                <SearchBox
                  labelId="defaultSearch"
                  defaultlabel="Search Available"
                  backgroundColor="#22272B"
                  sxProps={{
                    minWidth: "180px",
                  }}
                  value={searchAvailable}
                  onChange={handleSearchAvailableChange}
                  onCancel={handleClosePopoverAvailable}
                  cancelRequired={true}
                />
              </Box>
            </Popover>

            {((isAssigned && showSearchAssigned) ||
              (!isAssigned && showSearchAvailable)) && (
              <Popover
                open={isPopoverOpen}
                anchorEl={anchorEl}
                onClose={handleClosePopover}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <Box p={2} width="250px" sx={{ backgroundColor: "#323337" }}>
                  <SearchBox
                    labelId="defaultSearch"
                    defaultlabel="Search"
                    backgroundColor="#22272B"
                    sxProps={{
                      minWidth: "180px",
                    }}
                    value={searchValue}
                    onChange={handleSearchChange}
                    onCancel={handleClosePopover}
                    cancelRequired={true}
                  />
                </Box>
              </Popover>
            )}
            <Box sx={{ width: "100%" }}>
              <List
                ref={listRef}
                sx={{
                  height: availableHeight,
                  overflowY: "auto",
                  paddingTop: 0,
                  borderRadius: "3px",
                  backgroundColor: "#2B3033",
                }}
              >
                {roles?.length === 0 ? (
                  <ListItem>
                    <Typography
                      sx={{
                        padding: 2,
                        color: "#B0B0B0",
                        alignItems: "center",
                        textAlign: "center",
                        width: "100%",
                      }}
                    >
                      {headerIndex === 0
                        ? "No assigned permissions."
                        : "No available permissions."}
                    </Typography>
                  </ListItem>
                ) : (
                  roles.map((role: PermissionRecordType, roleIndex: number) => (
                    <ListItem key={role.id} sx={{ bgcolor: "#2B3033" }}>
                      <ListItemButton
                        sx={{
                          height: 50,
                          bgcolor: "#33383D",
                          padding: 1,
                        }}
                      >
                        <Box
                          display="flex"
                          alignItems="center"
                          sx={{ width: "100%" }}
                        >
                          {headerIndex !== 0 ? (
                            <IconButton
                              style={{ color: "grey" }}
                              onClick={() => handleAddChange(roleIndex)}
                              disabled={!role?.id}
                            >
                              <AddSharpIcon />
                            </IconButton>
                          ) : null}

                          {(headerIndex !== 0 &&
                            isEditing &&
                            roleIndex == settedIndex &&
                            focusedRoleIndex == roleIndex) ||
                          !role?.id ? (
                            <TextInput
                              className="text-input-fieldRole"
                              type="text"
                              inputProps={{
                                readOnly: false,
                              }}
                              labelId="common.placeHolderText"
                              defaultLabelId="common.placeHolderText"
                              Value={role.name}
                              handlechange={(value: string) => {
                                const newRoleData = [...available];
                                newRoleData[roleIndex].name = value.replace(
                                  /\s+/g,
                                  ""
                                );
                                setAvailable(newRoleData);
                              }}
                            />
                          ) : headerIndex !== 0 ? (
                            <Box
                              className="pl-sm"
                              onClick={() =>
                                handleEditClick(roleIndex, role.name)
                              }
                              sx={{
                                pointerEvents: "auto",
                              }}
                            >
                              <Tooltip title={role.name} placement="bottom">
                                <span>
                                  <MediumTypography
                                    sxProps={{
                                      font: "Inter",
                                      fontSize: "14px",
                                      fontWeight: "500",
                                      lineHeight: "16px",
                                      maxWidth: "300px",
                                      overflow: "hidden",
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis",
                                    }}
                                    label={role.name}
                                  />
                                </span>
                              </Tooltip>
                            </Box>
                          ) : (
                            headerIndex === 0 && (
                              <Box className="pl-sm">
                                <Tooltip title={role.name} placement="bottom">
                                  <span>
                                    <MediumTypography
                                      sxProps={{
                                        font: "Inter",
                                        fontSize: "14px",
                                        fontWeight: "500",
                                        lineHeight: "16px",
                                        maxWidth: "200px",
                                        overflow: "hidden",
                                        whiteSpace: "nowrap",
                                        textOverflow: "ellipsis",
                                      }}
                                      label={role.name}
                                    />
                                  </span>
                                </Tooltip>
                              </Box>
                            )
                          )}

                          <Box
                            sx={{
                              position: "relative",
                              right: 0,
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            {(headerIndex !== 0 &&
                              isEditing &&
                              roleIndex == settedIndex &&
                              focusedRoleIndex == roleIndex) ||
                            !role?.id ? (
                              <IconButton
                                style={{
                                  paddingTop: "5px",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  if (role?.id) {
                                    handleAddRolePermission(
                                      role.name,
                                      "PERMISSION",
                                      originalPermissionVal?.current,
                                      role.id
                                    );
                                    setIsEditing(false);
                                  } else {
                                    handleAddRolePermission(
                                      role.name,
                                      "PERMISSION",
                                      originalPermissionVal?.current
                                    );
                                    setIsEditing(false);
                                  }
                                }}
                                disabled={!role?.name}
                              >
                                <RoleSave />
                              </IconButton>
                            ) : (
                              ""
                            )}

                            {headerIndex !== 0 &&
                              role?.id !== undefined &&
                              isEditing &&
                              focusedRoleIndex == roleIndex && (
                                <CrossIcon
                                  onClick={() => {
                                    handleResetRole(roleIndex);
                                    setIsEditing(false);
                                  }}
                                  style={{
                                    cursor: "pointer",
                                  }}
                                />
                              )}
                          </Box>
                          <Box
                            sx={{
                              position: "absolute",
                              right: 0,
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            {headerIndex === 0 && (
                              <CrossIcon
                                onClick={() =>
                                  handleRemovePermission(roleIndex)
                                }
                                style={{
                                  paddingTop: "5px",
                                  cursor: "pointer",
                                }}
                              />
                            )}
                          </Box>
                        </Box>
                      </ListItemButton>
                    </ListItem>
                  ))
                )}
              </List>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
}
