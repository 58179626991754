import { Box, Grid, Tooltip } from "@mui/material";
import MediumTypography from "./MediumTypography";
import { FC } from "react";
import CheckBox from "./CheckBox";
import { TechnicianType } from "../../utils/type";
import UserprofileIcon from "../../assets/images/profile-circle.svg";

export interface TeamMemberComponentProps {
  data: TechnicianType;
  shiftType?: string;
  icon?: JSX.Element;
  checkBoxRequired?: boolean;
  value?: boolean;
  onClickChange?: (checked: boolean) => void;
  dataId?: number;
  iconCallBackChange?: (data: TechnicianType, shiftType: string) => void;
  isAvailabilityRequired?: boolean;
  statusNotRequired?: boolean;
  shiftStartTime?: string;
  shiftEndTime?: string;
}

const TeamMembersComponent: FC<TeamMemberComponentProps> = ({
  icon,
  shiftType,
  data,
  checkBoxRequired,
  value,
  onClickChange,
  iconCallBackChange,
  isAvailabilityRequired,
  statusNotRequired,
  shiftStartTime,
  shiftEndTime,
}) => {
  let availabilityPercentage: number = 0;
  let remainingPercentage: number = 0;

  function timeStringToMinutes(time: string) {
    // Split the time string into parts
    const [hours, minutes, seconds] = time
      .split(":")
      .map((part) => parseInt(part, 10));
    // Convert hours to minutes and add to minutes
    return hours * 60 + minutes + seconds / 60;
  }

  if (data.availability && shiftStartTime && shiftEndTime) {
    const startTime = timeStringToMinutes(shiftStartTime);
    let endTime = timeStringToMinutes(shiftEndTime);

    // Check if the shift ends on the next day
    if (endTime < startTime) {
      // Add 24 hours (in minutes) to the endTime
      endTime += 24 * 60;
    }

    // Calculate the actual time in minutes
    const actualTimeInMinutes = endTime - startTime;

    // Your existing logic to calculate availability and remaining percentages
    const [hours, mins] = data.availability?.split("hr").map((s) => s.trim());
    const minutes = mins ? parseInt(mins.replace("mins", "").trim()) : 0;
    const totalMinutes = parseInt(hours) * 60 + minutes;

    availabilityPercentage = (totalMinutes / actualTimeInMinutes) * 100;
    remainingPercentage = 100 - availabilityPercentage;
  }

  return (
    <Box>
      <Grid container className="teamMemberCard ">
        <Grid item className="flex__ align__items__center pt-md pb-md pl-md">
          <div
            style={{
              width: "45px",
              height: "45px",
              backgroundImage: data.image
                ? `url(${data.image})`
                : `url(${UserprofileIcon})`, // Use imported image
              backgroundSize: "cover",
              backgroundPosition: "center",
              borderRadius: "50%",
            }}
          ></div>
        </Grid>
        <Grid item xs={9} className="ml-xs pt-md pb-sm">
          <Box className="flex__justify__space-between">
            <Box sx={{ width: "100%" }}>
              <Box className="flex__">
                {shiftType !== "" &&
                shiftType !== null &&
                shiftType !== undefined ? (
                  <MediumTypography
                    label={shiftType}
                    fontSize="14px"
                    fontWeight={800}
                    textColor="#b8acf6"
                    className="shift_btn_1"
                    sxProps={{ lineHeight: "normal" }}
                  />
                ) : null}
                <Tooltip
                  title={data.name && data.name.length > 15 ? data.name : ""}
                  arrow={true}
                  followCursor
                >
                  <Box>
                    <MediumTypography
                      label={
                        data.name === "" || data.name === null ? "-" : data.name
                      }
                      className="ml-xs ellipsis"
                      fontSize="14px"
                      sxProps={{
                        width: data.name.length > 15 ? "120px" : "auto",
                      }}
                    />
                  </Box>
                </Tooltip>
              </Box>
              <Tooltip
                title={data.title && data.title.length > 15 ? data.title : ""}
                arrow={true}
                followCursor
              >
                <Box>
                  <MediumTypography
                    label={
                      data.title === "" || data.title === null
                        ? "-"
                        : data.title
                    }
                    className="mt-xs ellipsis"
                    fontSize="12px"
                    sxProps={{
                      width: data.title.length > 15 ? "150px" : "auto",
                      opacity: 0.64,
                    }}
                  />
                </Box>
              </Tooltip>
            </Box>

            {!checkBoxRequired ? (
              <Box
                className="display-flex-center p-md cursor__pointer"
                onClick={() => {
                  if (iconCallBackChange)
                    iconCallBackChange(data, shiftType ? shiftType : "");
                }}
              >
                {icon}
              </Box>
            ) : (
              <Box className="flex__">
                <CheckBox
                  value={value === undefined ? false : value}
                  onCheckBoxClick={(checked) => {
                    if (onClickChange) {
                      onClickChange(checked);
                    }
                  }}
                />
              </Box>
            )}
          </Box>
          {!statusNotRequired && (
            <Box
              sx={{
                backgroundColor:
                  data.statusCode === "AVAILABLE"
                    ? "rgba(19, 196, 19, 0.24)"
                    : data.status === "NO SHOW"
                    ? "rgba(255, 0, 0, 0.24)"
                    : "",
                width: "max-content",
                borderRadius: "2px",
              }}
              className={"ml-xs mt-xs"}
            >
              <MediumTypography
                label={data.status}
                className="ml-xs pr-xs"
                fontSize="12px"
                sxProps={{
                  color:
                    data.statusCode === "AVAILABLE"
                      ? "#4BE74B"
                      : data.statusCode === "NO SHOW"
                      ? "#C9372C"
                      : "#FFFFFF",
                }}
              />
            </Box>
          )}
          {isAvailabilityRequired && (
            <Box className="flex__">
              <MediumTypography
                labelId="TeamMembers.Availability"
                defaultLabel="Availability:"
                fontSize="12px"
                sxProps={{ opacity: 0.64 }}
              />
              <MediumTypography
                label={data?.availability}
                className="ml-xs"
                fontSize="12px"
                sxProps={{ opacity: 0.64 }}
              />
            </Box>
          )}
        </Grid>
        {isAvailabilityRequired &&
          data.availability &&
          shiftStartTime &&
          shiftEndTime && (
            <Grid
              item
              sx={{
                background: `linear-gradient(to right, rgba(59, 120, 251, 1) ${availabilityPercentage}%, rgba(59, 120, 251, 0.2) ${availabilityPercentage}% ${remainingPercentage}%)`,
              }}
              className="teamMembers_progressBar"
            ></Grid>
          )}
      </Grid>
    </Box>
  );
};

export default TeamMembersComponent;
