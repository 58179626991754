import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { FC, useContext, useEffect, useState } from "react";
import MediumTypography from "../components/formlib/MediumTypography";
import { TechnicianType } from "../utils/type";
import TeamMembersComponent from "../components/formlib/TeamMemberComponent";
import { ReactComponent as DragIcon } from "../assets/images/DragIcon.svg";
import "./ReassignModal.css";
import ButtonComponent from "../components/formlib/ButtonComponent";
import CheckBox from "../components/formlib/CheckBox";
import { ReactComponent as Cancel } from "../assets/images/Cancel.svg";
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from "react-beautiful-dnd";
import {
  ReassignRequest,
  getAssetSetAssignee,
  reassignSetAssetMembers,
} from "../api/workOrderApi/schedulerApi/Scheduler";
import { LoaderContext, LoaderContextType } from "../layouts/appSidebar";

import ErrorModal from "../components/formlib/modal/ErrorModal";
import ModalPopup from "../components/formlib/modal/ModalPopup";

import { dateContext } from "./scheduler/SchedulerDashboard";
import moment from "moment";
import { handleError } from "../utils/commonFunctions";
import {
  AssignedUsers,
  CMSetType,
} from "./workOrderDetailScreens/correctiveMaintenance/SetAssetsAssignee";
import useAvailableHeight from "../utils/useAvailableHeight";
import useAvailableWidth from "../utils/useAvailableWidth";

export interface ShiftAssetDetailsType {
  shiftId: number;
  shiftName: string;
  shiftStartTime: string;
  shiftEndTime: string;
  cardBlockCount: number;
  memberCount: number;
  shiftStaticName: string;
  teams: TechnicianType[];
}
export interface AssetType {
  id: number;
  sequence: number;
  name: string;
}
export interface SetType {
  setId: number;
  assigneeId: number;
  assets: AssetType[];
}
export interface ReassignAssestType {
  shift: ShiftAssetDetailsType;
  sets: CMSetType[];
  minStaffCount: number;
  maxStaffCount: number;
}
interface ReassignModalProps {
  open: boolean;
  handleClose: () => void;
  cardId: number;
  assetGroupId: number[];
  woUniqueId: number;
  version: number;
  reAssignCallBack: () => void;
  cardType?: "schedule" | "workorder";
}

const ReassignModalPopup: FC<ReassignModalProps> = ({
  open,
  handleClose,
  cardId,
  assetGroupId,
  woUniqueId,
  version,
  reAssignCallBack,
  cardType = "schedule",
}) => {
  const [manageAssignee, setManageAssignee] =
    useState<ReassignAssestType | null>(null);
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  const [checkedAssets, setCheckedAssets] = useState<Record<string, boolean>>(
    {}
  );
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [errorDesc, setErrorDesc] = useState<string>("");
  const [warningStatus, setWarningStatus] = useState(false);
  const [openReassignModal, setOpenReassignModal] = useState(false);
  const [openMaxCountWarningModal, setOpenMaxCountWarningModal] =
    useState(false);

  const [minStaffCountModal, setMinStaffCountModal] = useState(false);

  const dateCtx = useContext(dateContext);
  const activeDate = moment(dateCtx.activeDate).format("YYYY-MM-DD");

  const availableWidth = useAvailableWidth(500);

  useEffect(() => {
    if (warningStatus) {
      saveApiAssets();
    }
  }, [warningStatus]);

  const getAssetSetAssigne = () => {
    if (cardId && assetGroupId) {
      toggleLoader(true);
      getAssetSetAssignee(cardId, assetGroupId, cardType, activeDate)
        .then((response) => {
          toggleLoader(false);
          const setsLength = response.sets.length;
          const maxStaffCount = response.maxStaffCount;

          // If sets array length is less than maxStaffCount, add more sets
          if (setsLength < maxStaffCount) {
            for (let i = setsLength; i < maxStaffCount; i++) {
              response.sets.push({
                setId: i + 1, // Assuming setId should be the index + 1
                assignedUsers: [],
                assets: [],
              });
            }
          }

          response.sets = response.sets.map((set, index) => {
            if (set.assignedUsers.length > 0) {
              if (index === 0) {
                // First set, move user with isLeadAssignee and isPrimaryAssignee to the front
                set.assignedUsers = set.assignedUsers.sort((a, b) => {
                  if (a.isLeadAssignee && a.isPrimaryAssignee) return -1;
                  if (b.isLeadAssignee && b.isPrimaryAssignee) return 1;
                  return 0;
                });
              } else {
                // Other sets, move user with isPrimaryAssignee to the front
                set.assignedUsers = set.assignedUsers.sort((a, b) => {
                  if (a.isPrimaryAssignee) return -1;
                  if (b.isPrimaryAssignee) return 1;
                  return 0;
                });
              }
            }
            return set;
          });
          setManageAssignee(response);
        })
        .catch((error) => {
          toggleLoader(false);
          setOpenErrorModal(true);
          handleError(error, setErrorDesc);
        });
    }
  };
  useEffect(() => {
    getAssetSetAssigne();
  }, []);

  let filteredTeamsArray: TechnicianType[] = [];
  if (manageAssignee?.shift && manageAssignee?.sets) {
    const assignedTeamMemberIds = manageAssignee.sets.flatMap((set) =>
      set.assignedUsers?.map((user) => user.assigneeId)
    );
    const filteredTeams =
      manageAssignee?.shift.teams &&
      manageAssignee?.shift.teams.filter(
        (team) => !assignedTeamMemberIds.includes(team.id)
      );

    filteredTeamsArray.push(...filteredTeams);
  }

  let assignedTeams: TechnicianType[] = [];

  if (manageAssignee?.shift && manageAssignee?.sets) {
    const assignedMembers =
      manageAssignee?.shift.teams &&
      manageAssignee?.shift.teams.filter((team) =>
        manageAssignee?.sets.some((set) =>
          set.assignedUsers?.some((user) => user.assigneeId === team.id)
        )
      );

    assignedTeams.push(...assignedMembers);
  }

  const moveAssetBetweenSets = (
    sourceSetId: number,
    assetId: string,
    destSetId: number
  ) => {
    setManageAssignee((prevState) => {
      // If there's no previous state, just return null
      if (!prevState) return null;

      // Create a deep copy of the sets to avoid direct state mutations
      const newSets = prevState.sets.map((set) => ({
        ...set,
        assets: set.assets.slice(), // Create a shallow copy of the assets array
      }));

      // Find the source and destination sets using their IDs
      const sourceSet = newSets.find((set) => set.setId === sourceSetId);
      const destinationSet = newSets.find((set) => set.setId === destSetId);

      // If both sets are found, move the asset
      if (sourceSet && destinationSet) {
        // Remove the asset from the source set
        const removedAsset = sourceSet.assets.find(
          (asset) => asset.id.toString() === assetId
        );
        if (removedAsset) {
          sourceSet.assets = sourceSet.assets.filter(
            (asset) => asset.id.toString() !== assetId
          );
          // Add the removed asset to the destination set
          destinationSet.assets.push(removedAsset);
        }
      }

      // Return the new state with updated sets
      return {
        ...prevState,
        sets: newSets,
      };
    });
  };

  const handleRemove = (setId: number, userId: number) => {
    if (manageAssignee) {
      const setIndex = manageAssignee?.sets.findIndex(
        (set) => set.setId === setId
      );
      const assigneeId = manageAssignee?.sets[setIndex].assignedUsers.find(
        (user) => user.assigneeId === userId
      )?.assigneeId;

      // Set assigneeId of the set to null
      const updatedSets = [...manageAssignee.sets];
      updatedSets[setIndex].assignedUsers = updatedSets[
        setIndex
      ].assignedUsers.filter((user) => user.assigneeId !== userId);

      // Remove the assignee from the assigned list and add it back to the teams array
      const removedAssignee = assignedTeams.findIndex(
        (member) => member.id === assigneeId
      );

      if (removedAssignee) {
        assignedTeams.splice(removedAssignee, 1);
      }

      // Update the state
      setManageAssignee({
        ...manageAssignee,
        sets: updatedSets,
      });
    }
  };
  const saveApiAssets = () => {
    setOpenMaxCountWarningModal(false);
    if (manageAssignee) {
      const updatedManageAssignee: ReassignAssestType = {
        ...manageAssignee,
        sets: manageAssignee.sets.map((set, setIndex) => ({
          ...set,
          assets: set.assets.map((asset, index) => ({
            ...asset,
            sequence: index + 1, // Update the sequence property
          })),
          assignedUsers: set.assignedUsers.map((user, userIndex) => {
            if (setIndex === 0) {
              // First set
              if (userIndex === 0) {
                return {
                  ...user,
                  isLeadAssignee: true,
                  isPrimaryAssignee: true,
                };
              } else {
                return {
                  ...user,
                  isLeadAssignee: false,
                  isPrimaryAssignee: false,
                };
              }
            } else {
              // Other sets
              if (userIndex === 0) {
                return {
                  ...user,
                  isLeadAssignee: false,
                  isPrimaryAssignee: true,
                };
              } else {
                return {
                  ...user,
                  isLeadAssignee: false,
                  isPrimaryAssignee: false,
                };
              }
            }
          }),
        })),
      };
      const filteredSets = updatedManageAssignee?.sets.filter(
        (set) =>
          !(
            set.assignedUsers &&
            set.assignedUsers.length === 0 &&
            set.assets.length === 0
          )
      );
      const object: ReassignRequest = {
        shiftId: manageAssignee?.shift.shiftId,
        woUniqueId: woUniqueId,
        sets: filteredSets,
        version: version,
        currentDate: activeDate,
      };

      reassignSetAssetMembers(cardId, warningStatus, object, cardType)
        .then((response) => {
          toggleLoader(false);

          if (response.processCode === "WARNING") {
            setOpenReassignModal(true);
          } else {
            handleClose();
            reAssignCallBack();
          }
        })
        .catch((error) => {
          toggleLoader(false);
          setOpenErrorModal(true);
          handleError(error, setErrorDesc);
        });
    }
  };
  const handleSave = () => {
    if (!manageAssignee) return;

    const nonZeroAssigneeIdCount = manageAssignee.sets.reduce((acc, set) => {
      return (
        acc + set.assignedUsers.filter((user) => user.assigneeId !== 0).length
      );
    }, 0);
    const maxStaffCount = manageAssignee.maxStaffCount;
    const minStaffCount = manageAssignee.minStaffCount;

    if (maxStaffCount > nonZeroAssigneeIdCount) {
      setOpenMaxCountWarningModal(true);
    } else if (minStaffCount > nonZeroAssigneeIdCount) {
      setMinStaffCountModal(true);
    } else {
      saveApiAssets();
    }
  };

  const assignTeamMemberToSet = (teamMemberId: number, setId: number) => {
    setManageAssignee((prevManageAssignee) => {
      if (!prevManageAssignee) return null;

      // Clone the previous state to avoid direct mutations
      const newManageAssignee: ReassignAssestType = {
        ...prevManageAssignee,
        shift: { ...prevManageAssignee.shift },
        sets: prevManageAssignee.sets.map((set) => ({ ...set })),
      };

      // Find the index of the target set and check if the assigneeId is null
      const targetSet = newManageAssignee.sets.find(
        (set) => set.setId === setId
      );

      if (targetSet) {
        // Create a new assigned user object
        const newAssignedUser: AssignedUsers = {
          assigneeId: teamMemberId,
          isLeadAssignee: false,
          isPrimaryAssignee: false,
        };

        // Add the new assigned user to the target set's assigned users array
        targetSet.assignedUsers.push(newAssignedUser);
      }

      // Return the updated state
      return newManageAssignee;
    });
  };
  const moveAssetWithinSet = (
    setId: number,
    assetId: string,
    destIndex: number
  ) => {
    setManageAssignee((prevState) => {
      if (!prevState) return prevState;

      // Create a deep copy of the sets to avoid direct state mutations
      const newSets = prevState.sets.map((set) => ({
        ...set,
        assets: set.assets.slice(), // Create a shallow copy of the assets array
      }));

      // Find the set using its ID
      const currentSet = newSets.find((set) => set.setId === setId);

      // If the set is found, reorder the asset
      if (currentSet) {
        const assetIndex = currentSet.assets.findIndex(
          (asset) => asset.id.toString() === assetId
        );
        if (assetIndex !== -1) {
          const [removedAsset] = currentSet.assets.splice(assetIndex, 1);
          currentSet.assets.splice(destIndex, 0, removedAsset);
        }
      }

      // Return the new state with updated sets
      return {
        ...prevState,
        sets: newSets,
      };
    });
  };

  const toggleCheckStatus = (memberId: string) => {
    setCheckedAssets((prevCheckedAssets) => ({
      ...prevCheckedAssets,
      [memberId]: !prevCheckedAssets[memberId],
    }));
  };

  const handleDragEnd = (result: DropResult) => {
    const { source, destination, draggableId } = result;

    if (!destination) {
      return;
    }

    if (
      source.droppableId.startsWith("technicians-list") &&
      destination.droppableId.startsWith("assignee/")
    ) {
      const teamMemberId = parseInt(draggableId.split("-")[1]);
      const setId = parseInt(destination.droppableId.split("/")[1]);
      // Assign the team member to the set
      assignTeamMemberToSet(teamMemberId, setId);
    } else if (
      source.droppableId.startsWith("assets/") &&
      destination.droppableId.startsWith("assets/")
    ) {
      const sourceSetId = parseInt(source.droppableId.split("/")[1]);
      const destSetId = parseInt(destination.droppableId.split("/")[1]);

      // Check if multiple assets are being dragged (checkboxes checked)
      const checkedItems = Object.keys(checkedAssets).filter(
        (assetId) => checkedAssets[assetId]
      );

      if (checkedItems.length > 0) {
        // Move multiple assets between sets

        if (sourceSetId === destSetId) {
          checkedItems.forEach((assetId) => {
            moveAssetWithinSet(sourceSetId, assetId, destination.index);
          });
        } else {
          // Move multiple assets between sets
          checkedItems.forEach((assetId) => {
            moveAssetBetweenSets(sourceSetId, assetId, destSetId);
          });
        }

        // Reset checked assets
        setCheckedAssets({});
      } else {
        // Single asset being dragged
        if (sourceSetId === destSetId) {
          // Reordering assets within the same set
          const assetId = draggableId.split("/")[1];
          moveAssetWithinSet(sourceSetId, assetId, destination.index);
        } else {
          // Dragging assets between different sets
          const assetId = draggableId.split("/")[1];
          moveAssetBetweenSets(sourceSetId, assetId, destSetId);
        }
      }
    }
  };

  const isAssignButtonDisabled = (sets: CMSetType[]) => {
    return sets.some(
      (set) =>
        (set.assets.length > 0 &&
          (set.assignedUsers.length === 0 ||
            set.assignedUsers[0].assigneeId === 0)) ||
        (set.assets.length === 0 &&
          set.assignedUsers.length > 0 &&
          set.assignedUsers[0].assigneeId !== 0)
    );
  };

  // Calculate the total width of the container based on the number of items
  const containerWidth = manageAssignee?.sets && manageAssignee.sets.length > 3;

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Dialog open={open} fullWidth maxWidth={"xl"}>
        <DialogTitle sx={{ backgroundColor: "#1D2125" }}>
          <MediumTypography
            labelId={"ReassignTitle"}
            defaultLabel="Assign Missing TeamMembers"
            textColor="#FFFF"
            fontSize="14px"
            fontWeight={700}
          />
        </DialogTitle>
        <DialogContent
          sx={{
            backgroundColor: "#282E33",
            height: "570px",
            maxHeight: "600px",
            padding: "0px",
          }}
        >
          {openErrorModal && (
            <ErrorModal
              descriptionText={errorDesc}
              open={openErrorModal}
              handleClose={() => setOpenErrorModal(false)}
              onPositiveClick={() => {
                setOpenErrorModal(false);
                handleClose();
              }}
            />
          )}
          {openReassignModal && (
            <ModalPopup
              descriptionText="ReAssign.Description"
              open={openReassignModal}
              handleClose={() => setOpenReassignModal(false)}
              onPositiveClick={() => {
                setWarningStatus(true);
              }}
              onNegativeClick={() => {
                setOpenReassignModal(false);
              }}
              positiveButtonLabel="Yes"
              positiveButtonLabelId="YesText"
              negativeButtonLabel="No"
              negativeButtonLabelId="NoText"
            />
          )}
          {openMaxCountWarningModal && (
            <ModalPopup
              descriptionText="MaxCount.description"
              open={openMaxCountWarningModal}
              handleClose={() => setOpenMaxCountWarningModal(false)}
              onPositiveClick={() => {
                saveApiAssets();
              }}
              onNegativeClick={() => {
                setOpenMaxCountWarningModal(false);
              }}
              positiveButtonLabel="Yes"
              positiveButtonLabelId="YesText"
              negativeButtonLabel="No"
              negativeButtonLabelId="NoText"
            />
          )}
          {minStaffCountModal && (
            <ModalPopup
              descriptionText="MinCount.description"
              open={minStaffCountModal}
              handleClose={() => setMinStaffCountModal(false)}
              onPositiveClick={() => {
                setMinStaffCountModal(false);
              }}
              positiveButtonLabel="Ok"
              positiveButtonLabelId="WarningText.ok"
              hideButton={true}
            />
          )}
          {manageAssignee && (
            <>
              {manageAssignee.shift === null ? (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "502px",
                    }}
                  >
                    <MediumTypography
                      labelId="Reassign.ShiftError"
                      defaultLabel="Team members not Available. Please contact site In-charge"
                      fontSize="20px"
                      fontWeight={400}
                      textColor="#808080"
                    />
                  </Box>
                </>
              ) : (
                <>
                  <Box className="flex__" height={"100%"}>
                    <Box
                      sx={{
                        position: "relative",
                        overflowY: "auto",
                        width: containerWidth ? "410px" : "300px",
                      }}
                      className="tableStyles pr-md pt-md pl-md"
                    >
                      <Droppable droppableId="technicians-list">
                        {(provided) => (
                          <Box
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            sx={{ width: "260px" }}
                          >
                            {manageAssignee?.shift &&
                              manageAssignee?.shift.teams !== null &&
                              filteredTeamsArray &&
                              filteredTeamsArray.map((mem, index) => {
                                return (
                                  <Draggable
                                    key={mem.id}
                                    draggableId={`technician-${mem.id}`}
                                    index={index}
                                  >
                                    {(provide) => (
                                      <Box
                                        className="mb-sm"
                                        {...provide.draggableProps}
                                        {...provide.dragHandleProps}
                                        ref={provide.innerRef}
                                        sx={{ width: "260px" }}
                                      >
                                        <TeamMembersComponent
                                          data={mem}
                                          icon={<DragIcon />}
                                          isAvailabilityRequired={true}
                                          statusNotRequired={true}
                                          shiftType={
                                            manageAssignee.shift.shiftStaticName
                                          }
                                          shiftStartTime={
                                            manageAssignee.shift.shiftStartTime
                                          }
                                          shiftEndTime={
                                            manageAssignee.shift.shiftEndTime
                                          }
                                        />
                                      </Box>
                                    )}
                                  </Draggable>
                                );
                              })}
                            {provided.placeholder}
                          </Box>
                        )}
                      </Droppable>
                    </Box>

                    <Box
                      sx={{
                        overflowX: "auto",
                        borderLeft: "1px solid #37424B",
                        width: containerWidth
                          ? availableWidth
                          : "calc(100% - 300px)",
                      }}
                      className="tableStyles position__relative "
                    >
                      <Box
                        sx={{
                          borderBottom: "1px solid #37424B",
                          overflowX: "auto",
                          width: containerWidth ? availableWidth : "100%",
                          display: "flex",
                        }}
                        className="pt-md pb-md pl-md"
                      >
                        {manageAssignee.sets &&
                          manageAssignee.sets.map((item, index) => {
                            return (
                              <Droppable droppableId={`assignee/${item.setId}`}>
                                {(provided) => (
                                  <Box
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    width={"270px"}
                                    className="mr-md"
                                  >
                                    {item.assignedUsers &&
                                      item.assignedUsers.map(
                                        (user, userIndex) => {
                                          let assignedTeamMember =
                                            assignedTeams.find(
                                              (team) =>
                                                team.id === user.assigneeId
                                            );

                                          return (
                                            <Box
                                              key={user.assigneeId}
                                              className="pr-md"
                                            >
                                              {user.assigneeId !== 0 && (
                                                <Box width={"270px"}>
                                                  {assignedTeamMember && (
                                                    <TeamMembersComponent
                                                      data={assignedTeamMember}
                                                      isAvailabilityRequired={
                                                        true
                                                      }
                                                      statusNotRequired={true}
                                                      shiftType={
                                                        manageAssignee.shift
                                                          .shiftStaticName
                                                      }
                                                      shiftStartTime={
                                                        manageAssignee.shift
                                                          .shiftStartTime
                                                      }
                                                      shiftEndTime={
                                                        manageAssignee.shift
                                                          .shiftEndTime
                                                      }
                                                      icon={
                                                        <Cancel
                                                          onClick={() => {
                                                            handleRemove(
                                                              item.setId,
                                                              user.assigneeId
                                                            );
                                                          }}
                                                        />
                                                      }
                                                    />
                                                  )}
                                                </Box>
                                              )}
                                            </Box>
                                          );
                                        }
                                      )}
                                    <>
                                      {item.assignedUsers &&
                                      item.assignedUsers.length === 0 &&
                                      item.setId === 1 ? (
                                        <ButtonComponent
                                          className="btn-primary btn-cancel leadAssignee_button"
                                          labelId="LeadAssignee.addButton"
                                          defaultLabelId="ADD LEAD ASSIGNEE"
                                          variantType="outlined"
                                          onClick={() => {}}
                                        />
                                      ) : (
                                        <>
                                          {item.assignedUsers &&
                                          item.assignedUsers.length === 0 ? (
                                            <ButtonComponent
                                              className="btn-primary btn-cancel primaryAssignee_button"
                                              labelId="Primary.addButton"
                                              defaultLabelId="ADD PRIMARY ASSIGNEE"
                                              variantType="outlined"
                                              onClick={() => {}}
                                            />
                                          ) : (
                                            <ButtonComponent
                                              className="btn-primary btn-cancel teammember_button"
                                              labelId="TeamMember.addButton"
                                              defaultLabelId="ADD TEAM MEMBER"
                                              variantType="outlined"
                                              onClick={() => {}}
                                            />
                                          )}
                                        </>
                                      )}
                                    </>
                                    {provided.placeholder}
                                  </Box>
                                )}
                              </Droppable>
                            );
                          })}
                      </Box>

                      <Box
                        sx={{
                          width: containerWidth ? availableWidth : "100%",
                          borderBottom: "1px solid #37424B",
                          overflowX: "auto",
                          display: "flex",
                        }}
                        className="pt-md pl-md"
                      >
                        {manageAssignee.sets.map((item, index) => (
                          <Droppable droppableId={`assets/${item.setId}`}>
                            {(provided) => (
                              <Box
                                key={index}
                                sx={{
                                  overflowY: "auto",
                                  height: "435px",
                                  width: "285px",
                                }}
                                className=" tableStyles"
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                              >
                                {item.assets.map((itemData, ind) => (
                                  <Box sx={{ width: "270px" }}>
                                    <Draggable
                                      key={itemData.id}
                                      draggableId={`assets/${itemData.id}`}
                                      index={ind}
                                    >
                                      {(provider) => (
                                        <Box
                                          className="flex__ "
                                          {...provider.draggableProps}
                                          {...provider.dragHandleProps}
                                          ref={provider.innerRef}
                                          sx={{
                                            padding: "12px",
                                            backgroundColor: "#22272B",
                                            marginBottom: "8px",
                                            borderRadius: "4px",
                                            alignItems: "center",
                                          }}
                                        >
                                          <MediumTypography
                                            label={(ind + 1).toString()}
                                            textColor="#9FADBC"
                                            className="mr-sm"
                                          />
                                          <CheckBox
                                            value={
                                              itemData.id !== undefined &&
                                              checkedAssets[itemData.id]
                                            }
                                            onCheckBoxClick={() => {
                                              if (itemData.id !== undefined) {
                                                toggleCheckStatus(
                                                  itemData.id.toString()
                                                );
                                              }
                                            }}
                                            className="p-none mr-sm"
                                          />
                                          <MediumTypography
                                            label={itemData.name}
                                          />
                                        </Box>
                                      )}
                                    </Draggable>
                                  </Box>
                                ))}
                                {provided.placeholder}
                              </Box>
                            )}
                          </Droppable>
                        ))}
                      </Box>
                    </Box>
                  </Box>
                </>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions sx={{ padding: "0px" }}>
          <Box
            className="width__100 flex__ p-md"
            sx={{
              backgroundColor: "#1D2125",
              justifyContent: "end",
            }}
          >
            {manageAssignee && manageAssignee.shift === null ? (
              <>
                <ButtonComponent
                  className="btn-primary btn-submit mr-md"
                  labelId="WarningText.ok"
                  defaultLabelId="Ok"
                  onClick={handleClose}
                />
              </>
            ) : (
              <>
                <ButtonComponent
                  className={
                    isAssignButtonDisabled(manageAssignee?.sets || [])
                      ? "btn-primary btn-disabled mr-md"
                      : "btn-primary btn-submit mr-md"
                  }
                  labelId="Assigne.btn"
                  defaultLabelId="Assign"
                  onClick={handleSave}
                  disabled={isAssignButtonDisabled(manageAssignee?.sets || [])}
                  sxProps={{
                    cursor: isAssignButtonDisabled(manageAssignee?.sets || [])
                      ? "not-allowed"
                      : "pointer",
                  }}
                />
                <ButtonComponent
                  className="btn-primary btn-cancel mr-md"
                  labelId="btn.cancel"
                  defaultLabelId="Cancel"
                  onClick={handleClose}
                />
              </>
            )}
          </Box>
        </DialogActions>
      </Dialog>
    </DragDropContext>
  );
};

export default ReassignModalPopup;
