import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import {
  ChangeEvent,
  FC,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import MediumTypography from "../../../components/formlib/MediumTypography";
import ButtonComponent from "../../../components/formlib/ButtonComponent";
import CloseIcon from "@mui/icons-material/Close";
import "../styles.css";
import TextInput from "../../../components/formlib/TextInput";

import CustomDropdown from "../../../components/formlib/CustomDropdown";
import { LoaderContext, LoaderContextType } from "../../../layouts/appSidebar";
import ErrorModal from "../../../components/formlib/modal/ErrorModal";

import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { trim } from "lodash";
import FormikErrorComponent from "../../../components/formlib/FormikErrorComponent";
import { getOptions, handleError } from "../../../utils/commonFunctions";
import { ReactComponent as Upload } from "../../../assets/images/UploadIcon.svg";

import {
  createDropdownOptionApi,
  fetchDataApi,
  saveDataApi,
  uploadTemplate,
} from "../../../api/MasterDataManagement/Common";
import {
  AssetCategoryOptionType,
  FetchChecklistType,
  MakeOptionType,
  SaveChecklistType,
} from "../types/checklistTypes";
import RadioComponent from "../../../components/formlib/RadioComponent";
import { CommonOptionType } from "../../../utils/type";

// Configurations

type FetchDataType = FetchChecklistType;
type SaveDataType = SaveChecklistType;

const apiUri = "checklist";

interface ModalProps {
  open: boolean;
  handleClose: () => void;
  id?: number;
  getDataCallback: () => void; // reloadAccountData
}

const initialData: FetchDataType = {
  name: "",
  woType: [],
  assetCategory: [],
  frequency: [],
  type: [],
  selectedWoTypeId: 0,
  selectedAssetCategoryId: 0,
  selectedFrequencyId: 0,
  selectedMakeId: 0,
  selectedModelId: 0,
  selectedTypeId: 0,
  isStandardChecklist: true,
  selectedTemplateId: 0,
  checkListTemplates: [],
};

export const getMakeOptions = (
  assetCategory: AssetCategoryOptionType[],
  selectedAssetCategoryId: string
) => {
  return (
    assetCategory.find(
      (ac) => ac.id.toString() === selectedAssetCategoryId.toString()
    )?.make || []
  );
};

export const getModelOptions = (
  make: MakeOptionType[],
  selectedMakeId: string
) => {
  return (
    make.find((m) => m.id.toString() === selectedMakeId.toString())?.models ||
    []
  );
};

const dropdownValidation = Yup.mixed()
  .nullable()
  .test("is-valid-number", "validation.dropdown", (value: any) => {
    const theValue = value ? value.toString() : "";
    if (trim(theValue) === "" || theValue === "0") {
      return true;
    }

    return parseFloat(theValue) > 0;
  });

const customRequiredFn = (value: any) => {
  const theValue = value ? value.toString() : "";
  return trim(theValue) !== "" && theValue !== "0";
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required("validation.fieldRequired"),
  isStandardChecklist: Yup.boolean().required("validation.fieldRequired"),
  selectedWoTypeId: dropdownValidation.test(
    "custom-required",
    "validation.dropdown",
    customRequiredFn
  ),
  selectedAssetCategoryId: dropdownValidation.test(
    "custom-required",
    "validation.dropdown",
    customRequiredFn
  ),

  selectedFrequencyId: dropdownValidation.test(
    "custom-required",
    "validation.dropdown",
    customRequiredFn
  ),
  selectedMakeId: dropdownValidation.test(
    "custom-required",
    "validation.dropdown",
    customRequiredFn
  ),
  selectedModelId: dropdownValidation.test(
    "custom-required",
    "validation.dropdown",
    customRequiredFn
  ),
  selectedTypeId: dropdownValidation.test(
    "custom-required",
    "validation.dropdown",
    customRequiredFn
  ),
});

const muiColumnProps = {
  xs: 12,
  sm: 6,
  md: 6,
  lg: 6,
  xl: 6,
};

const AddEditChecklistModal: FC<ModalProps> = ({
  open,
  handleClose,
  id,
  getDataCallback,
}) => {
  const [apiData, setApiData] = useState<FetchDataType>(initialData);
  const [makeOptions, setMakeOptions] = useState<MakeOptionType[]>([]);
  const [modelOptions, setModelOptions] = useState<CommonOptionType[]>([]);
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [validateModal, setValidateModal] = useState<boolean>(false);
  const [errorDesc, setErrorDesc] = useState<string>("");
  const formikRef = useRef<FormikProps<FetchDataType>>(null);
  const [isVersionRequired, setIsVersionRequired] = useState<boolean>(false);
  const inputFileRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (open) {
      fetchData();
    }
  }, [id, open]);

  const fetchData = () => {
    toggleLoader(true);
    fetchDataApi<FetchDataType>(apiUri, id)
      .then(async (res) => {
        const updatedRes = { ...initialData, ...res };
        setApiData(updatedRes);

        const makeOptionsList = getMakeOptions(
          updatedRes.assetCategory,
          updatedRes.selectedAssetCategoryId?.toString()
        );
        setMakeOptions(makeOptionsList);
        setModelOptions(
          getModelOptions(
            makeOptionsList,
            updatedRes.selectedMakeId?.toString()
          )
        );
        toggleLoader(false);
      })
      .catch((err) => {
        handleError(err, setErrorDesc);
        setOpenErrorModal(true);
        toggleLoader(false);
      });
  };

  const saveData = (params: FetchDataType) => {
    toggleLoader(true);

    const {
      woType,
      assetCategory,
      frequency,
      type,
      checkListTemplates,
      ...updatedParams
    } = params;

    saveDataApi<SaveDataType>(apiUri, updatedParams, isVersionRequired, id)
      .then((res) => {
        formikRef.current?.resetForm({ values: initialData });
        handleClose();
        getDataCallback();
        toggleLoader(false);
      })
      .catch((err) => {
        handleError(err, setErrorDesc);
        setOpenErrorModal(true);
        toggleLoader(false);
      });
  };

  //template upload

  const browseFiles = () => {
    inputFileRef.current?.click();
  };
  const getSelectedFile = (event: ChangeEvent<HTMLInputElement>) => {
    return event.target.files && event.target.files.length > 0
      ? event.target.files[0]
      : null;
  };
  const handleAlreadyExistFile = (errorText: string) => {
    toggleLoader(false);
    setValidateModal(true);
    setErrorDesc(errorText);
  };

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    toggleLoader(true);

    const selectedFile = getSelectedFile(event);
    const isFileNameEXist = apiData.checkListTemplates.find(
      (item) => item.name === selectedFile?.name
    );

    if (isFileNameEXist) {
      handleAlreadyExistFile("fileNameAlreadyExist");
      return;
    }

    try {
      const response = await uploadTemplate(selectedFile);
      toggleLoader(false);
      formikRef.current?.setValues({
        ...formikRef.current.values,
        checkListTemplates: response,
      });
    } catch (err) {
      handleError(err, setErrorDesc);
      setValidateModal(true);
      toggleLoader(false);
    } finally {
      event.target.value = "";
    }
  };

  return (
    <>
      {openErrorModal && (
        <ErrorModal
          descriptionText={errorDesc}
          open={openErrorModal}
          handleClose={() => {
            setOpenErrorModal(false);
            handleClose();
          }}
          onPositiveClick={() => {
            setOpenErrorModal(false);
            handleClose();
          }}
        />
      )}
      {validateModal && (
        <ErrorModal
          descriptionText={errorDesc}
          open={validateModal}
          handleClose={() => {
            setValidateModal(false);
          }}
          onPositiveClick={() => {
            setValidateModal(false);
          }}
        />
      )}

      <Formik
        initialValues={apiData}
        enableReinitialize
        validateOnChange
        innerRef={formikRef}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          saveData(values);
        }}
      >
        {({ values, setFieldValue, handleSubmit, errors, touched, dirty }) => {
          return (
            <Dialog
              className="account-dialog main-wrapper-box"
              open={open}
              fullWidth
              maxWidth={"md"}
            >
              <DialogTitle className="padding_20">
                <MediumTypography
                  labelId={id ? "checklist.update" : "checklist.add"}
                  defaultLabel="Check List Details"
                  textColor="#FFF"
                  fontSize="22px"
                  fontWeight={700}
                />
                <MediumTypography
                  labelId={"common.basicDetails"}
                  defaultLabel="Enter basic details"
                  textColor="rgba(159, 173, 188, 1)"
                  fontSize="14px"
                  fontWeight={400}
                  className="mt-xs"
                />
              </DialogTitle>

              <IconButton
                aria-label="close"
                onClick={() => {
                  formikRef.current?.resetForm({ values: initialData });
                  handleClose();
                }}
                className="closeIcon"
              >
                <CloseIcon />
              </IconButton>

              <DialogContent dividers className="padding_20">
                <Grid container spacing={2}>
                  <Grid item {...muiColumnProps}>
                    <MediumTypography
                      className="input-label"
                      labelId="skillNameText"
                      defaultLabel="Name"
                    />
                    <TextInput
                      className="text-input-field"
                      type="text"
                      variant="outlined"
                      inputProps={{
                        readOnly: false,
                      }}
                      labelId="common.placeHolderText"
                      defaultLabelId="--- type here ---"
                      Value={values.name}
                      handlechange={(value: string) => {
                        setFieldValue("name", value);
                      }}
                    />
                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="name"
                    />
                  </Grid>
                  <Grid item {...muiColumnProps}>
                    <MediumTypography
                      className="input-label"
                      labelId="checklist.woType"
                      defaultLabel="WO Type"
                    />

                    <CustomDropdown
                      disableCreate={true}
                      options={getOptions(values.woType)}
                      selectedValue={values.selectedWoTypeId}
                      onSelectedValueChange={(value) => {
                        setFieldValue("selectedWoTypeId", value);
                      }}
                    />
                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="selectedWoTypeId"
                    />
                  </Grid>
                  <Grid item {...muiColumnProps}>
                    <MediumTypography
                      className="input-label"
                      labelId="AssetCategoryText"
                      defaultLabel="Asset Category"
                    />

                    <CustomDropdown
                      disableCreate={true}
                      options={getOptions(values.assetCategory)}
                      selectedValue={values.selectedAssetCategoryId}
                      onSelectedValueChange={(value) => {
                        const assetCategoryIdValue = value as string;
                        setFieldValue(
                          "selectedAssetCategoryId",
                          assetCategoryIdValue
                        );
                        setFieldValue("selectedMakeId", "");
                        setFieldValue("selectedModelId", "");
                        setMakeOptions(
                          getMakeOptions(
                            apiData.assetCategory,
                            assetCategoryIdValue
                          )
                        );
                        setModelOptions([]);
                      }}
                    />
                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="selectedAssetCategoryId"
                    />
                  </Grid>
                  <Grid item {...muiColumnProps}>
                    <MediumTypography
                      className="input-label"
                      labelId="checklist.frequency"
                      defaultLabel="Frequency"
                    />

                    <CustomDropdown
                      disableCreate={true}
                      options={getOptions(values.frequency)}
                      selectedValue={values.selectedFrequencyId}
                      onSelectedValueChange={(value) => {
                        setFieldValue("selectedFrequencyId", value);
                      }}
                    />
                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="selectedFrequencyId"
                    />
                  </Grid>
                  <Grid item {...muiColumnProps} className="standard-radio-div">
                    <MediumTypography
                      className="input-label"
                      labelId="checklist.standard"
                      defaultLabel="Standard"
                    />

                    <RadioComponent
                      name="standard"
                      value={values.isStandardChecklist}
                      handleChange={(
                        e: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        setFieldValue("isStandardChecklist", e.target.value);
                      }}
                      labelid=""
                      defaultlabelid=""
                      labelidA="YesText"
                      defaultlabelidA="Yes"
                      labelidB="NoText"
                      defaultlabelidB="No"
                    />

                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="isStandardChecklist"
                    />
                  </Grid>
                  <Grid item {...muiColumnProps}>
                    <MediumTypography
                      className="input-label"
                      labelId="checklist.make"
                      defaultLabel="Make"
                    />

                    <CustomDropdown
                      disableCreate={true}
                      options={getOptions(makeOptions)}
                      selectedValue={values.selectedMakeId}
                      onSelectedValueChange={(value) => {
                        const makeIdValue = value as string;
                        setFieldValue("selectedMakeId", makeIdValue);
                        setFieldValue("selectedModelId", "");
                        setModelOptions(
                          getModelOptions(makeOptions, makeIdValue)
                        );
                      }}
                    />
                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="selectedMakeId"
                    />
                  </Grid>
                  <Grid item {...muiColumnProps}>
                    <MediumTypography
                      className="input-label"
                      labelId="checklist.model"
                      defaultLabel="Model"
                    />

                    <CustomDropdown
                      disableCreate={true}
                      options={getOptions(modelOptions)}
                      selectedValue={values.selectedModelId}
                      onSelectedValueChange={(value) => {
                        setFieldValue("selectedModelId", value);
                      }}
                    />
                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="selectedModelId"
                    />
                  </Grid>
                  <Grid item {...muiColumnProps}>
                    <MediumTypography
                      className="input-label"
                      labelId="checklist.checklistType"
                      defaultLabel="Check List Type"
                    />

                    <CustomDropdown
                      disableCreate={false}
                      options={getOptions(values.type)}
                      selectedValue={values.selectedTypeId}
                      onSelectedValueChange={(value) => {
                        setFieldValue("selectedTypeId", value);
                      }}
                      onCreateOption={(option) => {
                        toggleLoader(true);
                        createDropdownOptionApi(
                          "CHECKLIST_TYPE",
                          "checklistType",
                          option
                        )
                          .then((res) => {
                            setFieldValue("type", res);
                            setFieldValue(
                              "selectedTypeId",
                              res.find((acc) => acc.name === option)?.id ?? ""
                            );
                            toggleLoader(false);
                          })
                          .catch((err) => {
                            handleError(err, setErrorDesc);
                            setOpenErrorModal(true);
                            toggleLoader(false);
                          });
                      }}
                    />
                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="selectedTypeId"
                    />
                  </Grid>
                  <Grid item {...muiColumnProps}>
                    <MediumTypography
                      className="input-label"
                      labelId="checklist.template"
                      defaultLabel="Template Extract"
                    />

                    <CustomDropdown
                      disableCreate={true}
                      options={getOptions(values.checkListTemplates)}
                      selectedValue={values.selectedTemplateId}
                      onSelectedValueChange={(value) => {
                        setFieldValue("selectedTemplateId", value);
                      }}
                    />
                  </Grid>
                  <Grid item {...muiColumnProps}>
                    <Box
                      className="flex__ cursor__pointer mr-sm"
                      sx={{
                        padding: "8px 12px",
                        borderRadius: "4px",
                        border: "1px solid rgba(166, 197, 226, 0.16)",
                        backgroundColor: "#2B3033",
                        width: "120px",
                        marginTop: "28px",
                      }}
                      onClick={browseFiles}
                    >
                      <input
                        ref={inputFileRef}
                        type="file"
                        accept=".jrxml"
                        style={{ display: "none", height: "36px" }}
                        onChange={handleFileChange}
                      />
                      <Upload />
                      <MediumTypography
                        labelId="Upload.text"
                        defaultLabel="Upload"
                        className="ml-xs"
                      />
                    </Box>
                  </Grid>
                </Grid>
              </DialogContent>

              <DialogActions className="dialogActions">
                <Box>
                  <Grid
                    className="flex__ justifyContent-FlexEnd"
                    container
                    direction="row"
                    alignItems="right"
                  >
                    <Grid item>
                      <ButtonComponent
                        sxProps={{ color: "#B6C2CF !important" }}
                        className="btn-primary btn-cancel mr-md"
                        variantType="outlined"
                        defaultLabelId={"Reset"}
                        labelId={"common.reset"}
                        onClick={() => formikRef.current?.resetForm()}
                        disabled={!dirty}
                      />
                    </Grid>
                    <Grid item>
                      <ButtonComponent
                        className="btn-primary btn-submit"
                        variantType="contained"
                        defaultLabelId={"Save"}
                        labelId={id ? "btn.update" : "btn.Add"}
                        onClick={() => {
                          handleSubmit();
                          setIsVersionRequired(false);
                        }}
                      />
                    </Grid>
                    {id && (
                      <Grid item>
                        <ButtonComponent
                          className="btn-primary btn-submit ml-sm"
                          variantType="contained"
                          defaultLabelId={"Save"}
                          labelId={"btn.updateWithVersion"}
                          onClick={() => {
                            handleSubmit();
                            setIsVersionRequired(true);
                          }}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Box>
              </DialogActions>
            </Dialog>
          );
        }}
      </Formik>
    </>
  );
};

export default AddEditChecklistModal;
