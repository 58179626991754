import { Box, Dialog, DialogContent, DialogTitle, Grid } from "@mui/material";
import React, { FC } from "react";
import MediumTypography from "../MediumTypography";
import ButtonComponent from "../ButtonComponent";

interface ModalProps {
  open: boolean;
  handleClose: () => void;
  onPositiveClick: () => void;
  onNegativeClick?: () => void;
  titleText?: string;
  descriptionText: string;
  titleRequired?: boolean;
  positiveButtonLabelId?: string;
  negativeButtonLabelId?: string;
  positiveButtonLabel?: string;
  negativeButtonLabel?: string;
  hideButton?: boolean;
  descriptionMessage?: string;
}

const ModalPopup: FC<ModalProps> = ({
  open,
  handleClose,
  titleText,
  titleRequired,
  descriptionText,
  onPositiveClick,
  onNegativeClick,
  positiveButtonLabelId,
  negativeButtonLabelId,
  positiveButtonLabel,
  negativeButtonLabel,
  hideButton,
  descriptionMessage,
}) => {
  return (
    <Dialog open={open} fullWidth maxWidth={"xs"}>
      {titleRequired && (
        <DialogTitle sx={{ backgroundColor: "#1D2125" }}>
          <MediumTypography
            labelId={titleText}
            defaultLabel="You are Attempting to move the schedule to a different shift"
            textColor="#B6C2CF"
            fontSize="20px"
            fontWeight={700}
          />
        </DialogTitle>
      )}

      <DialogContent sx={{ backgroundColor: "#1D2125" }}>
        <Box>
          <Box className="flex__">
            {descriptionMessage ? (
              <MediumTypography
                label={descriptionMessage}
                textColor="#9FADBC"
                fontSize="16px"
                fontWeight={400}
              />
            ) : (
              <MediumTypography
                labelId={descriptionText}
                defaultLabel="Do you want to continue?"
                textColor="#9FADBC"
                fontSize="16px"
                fontWeight={400}
              />
            )}
          </Box>
          <Box className="horizontalLine" sx={{ marginY: "10px" }} />
          <Box>
            <Grid
              className="flex__ justifyContent-FlexEnd"
              container
              direction="row"
              alignItems="right"
            >
              <Grid item>
                <ButtonComponent
                  className="btn-primary btn-submit mr-md"
                  variantType="contained"
                  defaultLabelId={positiveButtonLabel}
                  labelId={positiveButtonLabelId}
                  onClick={onPositiveClick}
                />
              </Grid>
              {!hideButton && (
                <Grid item>
                  <ButtonComponent
                    sxProps={{ color: "#B6C2CF !important" }}
                    className="btn-primary btn-cancel"
                    variantType="contained"
                    defaultLabelId={negativeButtonLabel}
                    labelId={negativeButtonLabelId}
                    onClick={onNegativeClick}
                  />
                </Grid>
              )}
            </Grid>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ModalPopup;
