import React from "react";
import {
  Fab,
  Tooltip,
  TooltipProps,
  styled,
  tooltipClasses,
} from "@mui/material";
import MediumTypography from "../../components/formlib/MediumTypography";

const SquareFab = styled(Fab)`
  width: 40.42px;
  height: 40.42px;
  border-radius: 4px;
  margin: 7px;
  background-color: rgba(29, 33, 37, 0.9);
  &:hover {
    background-color: rgba(29, 33, 37, 1);
  }
`;

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className ?? "" }} />
))`
  & .${tooltipClasses.tooltip} {
    background-color: rgba(29, 33, 37, 0.9);
    color: #fff;
    font-weight: 500;
    border-radius: 4px;
    z-index: 11;
  }

  & .${tooltipClasses.arrow} {
    color: rgba(29, 33, 37, 0.9);
  }
`;

// Define the props for the new component
interface FabWithTooltipProps extends Omit<TooltipProps, "children"> {
  title: string;
  bgColor?: string;
  iconSrc?: string;
  icon?: React.ReactNode;
  onFabClick: () => void;
}

// Create the reusable component
const FabWithTooltip: React.FC<FabWithTooltipProps> = ({
  title,
  bgColor,
  iconSrc,
  icon,
  onFabClick,
  ...tooltipProps
}) => {
  return (
    <CustomTooltip
      title={
        <MediumTypography labelId={title} fontSize="12px" fontWeight={500} />
      }
      aria-label={title}
      {...tooltipProps}
    >
      <SquareFab
        aria-label={title}
        sx={{
          bgcolor: bgColor || "rgba(29, 33, 37, 0.9)",
          "&:hover": {
            bgcolor: bgColor || "rgba(29, 33, 37, 1)",
          },
          ...(title === "planner.zoomInText"
            ? { mb: "-2px", height: "36px", zIndex: 13 }
            : {}),
          ...(title === "planner.zoomOutText"
            ? { mt: "-2px", height: "36px", zIndex: 12 }
            : {}),
        }}
        onClick={onFabClick}
      >
        {iconSrc ? <img src={iconSrc} alt={title} /> : icon}
      </SquareFab>
    </CustomTooltip>
  );
};

export default FabWithTooltip;
