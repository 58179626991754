import { Box, Button, ButtonGroup } from "@mui/material";
import React, { FC, useState } from "react";

interface PeriodComponentProps {
  handleSelectedPeriod: (value: string) => void;
  period: string[];
}
const PeriodButtonComponent: FC<PeriodComponentProps> = ({
  handleSelectedPeriod,
  period,
}) => {
  const [activeButton, setActiveButton] = useState("Day");

  const handleButtonClick = (value: string) => {
    setActiveButton(value);
    handleSelectedPeriod(value);
  };
  return (
    <Box
      sx={{
        height: "32px",
        marginBottom: "5px",
        flexDirection: "column",
        overflowX: "auto",
      }}
    >
      <ButtonGroup variant="contained" aria-label="Basic button group">
        {period?.map((item, index) => (
          <Button
            className={`button-ui yearMonthDay_btn ${
              activeButton === item ? "active" : ""
            }`}
            onClick={() => handleButtonClick(item)}
            key={index}
            disabled={item !== "Day"}
            sx={{
              cursor: item === "Day" ? "pointer" : "not-allowed",
            }}
          >
            {item}
          </Button>
        ))}
      </ButtonGroup>
    </Box>
  );
};

export default PeriodButtonComponent;
