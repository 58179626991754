import { Box, Divider, Grid } from "@mui/material";
import MediumTypography from "../../../components/formlib/MediumTypography";
import { FC, useContext, useEffect, useRef, useState } from "react";
import { RCAType } from "./types";
import dayjs, { Dayjs } from "dayjs";
import ButtonComponent from "../../../components/formlib/ButtonComponent";
import { Formik, FormikProps } from "formik";
import DropdownComponent from "../../../components/formlib/DropdownComponent";
import { DropDownType } from "../../../api/GrassCutting/GrassCutting";
import { OptionType } from "../../../utils/type";
import TextInput from "../../../components/formlib/TextInput";
import { DocumentTypeBean } from "./types";

import { LoaderContext, LoaderContextType } from "../../../layouts/appSidebar";

import ErrorModal from "../../../components/formlib/modal/ErrorModal";
import RichTextEditor from "./RichTextEditor";
import { getRCADetailsApi, saveRCADetails } from "../../../api/RCA/rca";
import CustomDatePicker from "../../../components/formlib/CustomDatePicker";
import WorkOrderActivity from "../WorkOrderActivity";

import FileUploadInput from "./FileUploadInput";
import DateTimePickerComponent from "../../../components/formlib/DateTimePickerComponent";
import useAvailableHeight from "../../../utils/useAvailableHeight";
import { convertToNumber, handleError } from "../../../utils/commonFunctions";
import { Make, Model } from "../../MasterDataManagement/types/inventoryTypes";

interface RCAProps {
  id: number;
  isVisible: boolean;
}

const RCAInfo: FC<RCAProps> = ({ id, isVisible }) => {
  const formikRef = useRef<FormikProps<RCAType>>(null);
  const initialRCA = {
    documentedDate: null,
    documentNo: "",
    unit: "",
    equipmentNames: [],
    assetCategory: { id: 0, name: "" },
    make: "",
    model: "",
    voltage: "",
    rootCauseAnalysis: "",
    uploadDocBean: null,
    type: "",
    serialNo: "",
    warranty: "",
    ipClass: "",
    erectionDate: null,
    yearOfManufacture: 0,
    equipCriticality: [],
    selectedEquipCriticality: 0,
    preventiveMaintenanceFrequency: "",
    generationLoss: "",
    eventDescription: "",
    typeOfBreakdown: "",
    rcaDoneBy: [],
    selectedRcaDoneBy: 0,
    status: [],
    cardDetailsInfo: {
      asset: "",
      code: "",
      type: "",
      assetCatFreqDesc: "",
      workOrderUniqueId: 0,
      frequencyDisplay: "",
      assetNameAndCount: "",
      lastUpdated: "",
    },
    beforeImage: [],
    afterImage: [],
    dumpImage: [],
    makeModels: [],
    selectedMakeId: 0,
    selectedModelId: 0,
  };

  const [rcaInitialValues, setRcaInitialValues] = useState<RCAType>(initialRCA);
  const [cardEdit, setCardEdit] = useState(false);
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [errorDesc, setErrorDesc] = useState<string>("");
  const [saveErrorModal, setSaveErrorModal] = useState<boolean>(false);
  const [editDisable, setEditDisable] = useState(false);
  const [resetSignal, setResetSignal] = useState(false);
  const availableHeight = useAvailableHeight(400);
  const collapseAvailHeight = useAvailableHeight(300);
  const [modelOptions, setModelOptions] = useState<Model[]>([]);

  useEffect(() => {
    getRCADetails();
  }, []);
  useEffect(() => {
    if (rcaInitialValues.status !== undefined) {
      if (
        rcaInitialValues.status[rcaInitialValues.status.length - 1]?.code ===
        "CLOSED"
      ) {
        setEditDisable(true);
      } else {
        setEditDisable(false);
      }
    }
  }, [id, rcaInitialValues]);

  const urlToDocumentTypeBean = (url: string): Promise<DocumentTypeBean> => {
    return fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const reader = new FileReader();
        return new Promise<DocumentTypeBean>((resolve, reject) => {
          reader.onloadend = () => {
            const base64StringWithPrefix = reader.result as string;
            const base64String = base64StringWithPrefix.split(",")[1];
            const fileName = url.split("/").pop() || "file";
            const extension = fileName.split(".").pop() || "";
            resolve({
              document: base64String,
              fileName: fileName,
              extension: extension,
            });
          };
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        });
      });
  };
  const getRCADetails = () => {
    toggleLoader(true);
    getRCADetailsApi(id)
      .then((response) => {
        if (response) {
          setModelOptions(
            getModelOptions(response.makeModels, response.selectedMakeId)
          );
          const rcaUrl = response.rootCauseAnalysis;

          if (rcaUrl !== "") {
            return urlToDocumentTypeBean(rcaUrl).then((documentTypeBean) => {
              response.uploadDocBean = documentTypeBean;

              setRcaInitialValues({
                ...response,
                uploadDocBean: documentTypeBean,
              });
              toggleLoader(false);
            });
          } else {
            setRcaInitialValues(response);
            toggleLoader(false);
          }
          // formikRef.current?.setValues(response);
        }
        toggleLoader(false);
      })
      .catch((err) => {
        setOpenErrorModal(true);
        handleError(err, setErrorDesc);
        toggleLoader(false);
      });
  };

  const convertToOptionType = (data: DropDownType[]): OptionType[] => {
    return data.map((item) => {
      const itemData: OptionType = {
        id: item.id.toString(),
        label: item.name,
      };
      return itemData;
    });
  };

  const getModelOptions = (make: Make[], selectedId: number) => {
    return make.find((m) => m.id === selectedId)?.models || [];
  };

  const handleSave = (values: RCAType) => {
    setCardEdit(false);
    toggleLoader(true);
    saveRCADetails(id, values)
      .then(() => {
        setCardEdit(false);
        setResetSignal((prev) => !prev);
        getRCADetails();
      })
      .catch((err) => {
        toggleLoader(false);
        setSaveErrorModal(true);

        handleError(err, setErrorDesc);
      });
  };

  return (
    <Box>
      {openErrorModal && (
        <ErrorModal
          descriptionText={errorDesc}
          open={openErrorModal}
          handleClose={() => setOpenErrorModal(false)}
          onPositiveClick={() => {
            setOpenErrorModal(false);
          }}
        />
      )}

      {saveErrorModal && (
        <ErrorModal
          descriptionText={errorDesc}
          open={saveErrorModal}
          handleClose={() => setSaveErrorModal(false)}
          onPositiveClick={() => {
            setSaveErrorModal(false);
          }}
        />
      )}
      <Formik
        initialValues={rcaInitialValues}
        enableReinitialize
        innerRef={formikRef}
        validateOnChange
        validationSchema={""}
        onSubmit={(values) => {
          const params = {
            ...values,
            selectedMakeId: convertToNumber(values.selectedMakeId),
            selectedModelId: convertToNumber(values.selectedModelId),
          };
          handleSave(params);
        }}
      >
        {({ values, setFieldValue, handleSubmit, errors, touched }) => {
          return (
            <Grid container>
              <Grid container>
                <Grid
                  item
                  xs={9}
                  sx={{
                    borderRight: "1px solid #333b43",
                    overflowY: "auto",
                    height: isVisible ? availableHeight : collapseAvailHeight,
                  }}
                >
                  <Box sx={{ padding: "24px 0px 24px 0px" }}>
                    <Box className="flex__">
                      <Box>
                        <MediumTypography
                          labelId="RCA.InfoText"
                          defaultLabel="RCA Info"
                          fontSize="20px"
                          fontWeight={500}
                          textColor="#FFFFFF"
                        />
                      </Box>
                      {!editDisable && (
                        <Box sx={{ marginLeft: "auto" }}>
                          <Grid
                            className="flex__ justifyContent-FlexEnd"
                            container
                            direction="row"
                            alignItems="right"
                          >
                            {cardEdit && (
                              <Grid item>
                                <ButtonComponent
                                  sxProps={{ color: "#B6C2CF !important" }}
                                  className="btn-primary btn-cancel"
                                  variantType="outlined"
                                  defaultLabelId="Cancel"
                                  labelId="btn.cancel"
                                  onClick={() => {
                                    setCardEdit(false);
                                    formikRef.current?.resetForm();
                                  }}
                                />
                              </Grid>
                            )}
                            {cardEdit && (
                              <Grid item>
                                <ButtonComponent
                                  className="btn-primary btn-submit mr-md"
                                  variantType="contained"
                                  defaultLabelId="Save"
                                  labelId="btn.save"
                                  onClick={() => {
                                    handleSubmit();
                                    setCardEdit(false);
                                  }}
                                />
                              </Grid>
                            )}
                            {!cardEdit && (
                              <Grid item>
                                <ButtonComponent
                                  className="btn-primary btn-submit mr-md"
                                  variantType="contained"
                                  defaultLabelId="Edit"
                                  labelId="btn.edit"
                                  onClick={() => {
                                    setCardEdit(true);
                                  }}
                                />
                              </Grid>
                            )}
                          </Grid>
                        </Box>
                      )}
                    </Box>

                    <Grid
                      container
                      direction={"row"}
                      className="justifyContentSpaceBtw"
                      alignItems={"center"}
                      rowSpacing={"16px"}
                      columnSpacing={"40px"}
                      sx={{ marginBottom: "20px", marginTop: "10px" }}
                    >
                      <Grid item>
                        <MediumTypography
                          labelId="RCA.DocumentedDate"
                          defaultLabel="Documented Date"
                          sxProps={{ marginBottom: "10px" }}
                          textColor={"rgba(159, 173, 188, 1)"}
                        />

                        <DateTimePickerComponent
                          value={
                            values.documentedDate !== null
                              ? dayjs(values.documentedDate)
                              : null
                          }
                          labelid="RCA.DocumentedDate"
                          defaultlabelid="Documented Date"
                          handlechange={(date: Dayjs) => {
                            const formattedDate =
                              dayjs(date).format("YYYY-MM-DD");
                            setFieldValue("documentedDate", formattedDate);
                          }}
                          disabledDate={false}
                          inputFormat="DD MMM YYYY  HH:mm:ss"
                          width="250px"
                          textWidth="250px"
                        />
                      </Grid>
                      <Grid item>
                        <MediumTypography
                          labelId="RCA.DocumentNo"
                          defaultLabel="Document No"
                          sxProps={{ marginBottom: "10px" }}
                          textColor={"rgba(159, 173, 188, 1)"}
                        />
                        <TextInput
                          labelId="RCA.DocumentNo"
                          className="textInputWidth"
                          defaultLabelId="Document No"
                          borderColor="1px solid rgba(166, 197, 226, 0.16)"
                          Value={values.documentNo}
                          handlechange={(value: string) => {
                            setFieldValue("documentNo", value);
                          }}
                          textColor={
                            cardEdit
                              ? "rgba(255, 255, 255, 1)"
                              : "rgba(140, 155, 171, 1)"
                          }
                          inputProps={{
                            readOnly: !cardEdit,
                          }}
                          variant="outlined"
                          backGroundColor="#22272B"
                          fieldheight="40px"
                        />
                      </Grid>
                      <Grid item>
                        <MediumTypography
                          labelId="RCA.Plan/Unitt"
                          defaultLabel="Plan/unit"
                          sxProps={{ marginBottom: "10px" }}
                          textColor={"rgba(159, 173, 188, 1)"}
                        />
                        <Box className="flex__">
                          <TextInput
                            labelId="RCA.Plan/Unit"
                            className="textInputWidth"
                            defaultLabelId="Plan/unit"
                            borderColor="1px solid rgba(166, 197, 226, 0.16)"
                            Value={values.unit}
                            handlechange={(value: string) => {
                              setFieldValue("unit", value);
                            }}
                            variant="outlined"
                            backGroundColor="#22272B"
                            fieldheight="40px"
                            textColor={
                              cardEdit
                                ? "rgba(255, 255, 255, 1)"
                                : "rgba(140, 155, 171, 1)"
                            }
                            inputProps={{
                              readOnly: true,
                            }}
                          />
                        </Box>
                      </Grid>
                      <Grid item>
                        <MediumTypography
                          labelId="RCA.DoneBy"
                          defaultLabel="RCA Done by"
                          sxProps={{ marginBottom: "10px" }}
                          textColor={"rgba(159, 173, 188, 1)"}
                        />
                        <DropdownComponent
                          disabled={!cardEdit}
                          names={convertToOptionType(values.rcaDoneBy)}
                          value={
                            values.selectedRcaDoneBy === 0
                              ? ""
                              : values.selectedRcaDoneBy.toString()
                          }
                          handleChange={(value: string) => {
                            setFieldValue("selectedRcaDoneBy", parseInt(value));
                          }}
                          labelid="RCA.DoneBy"
                          defaultlabelid="RCA Done By"
                          minWidth="250px"
                          sxProps={{ width: "250px" }}
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      direction={"row"}
                      className="justifyContentSpaceBtw"
                      alignItems={"center"}
                      rowSpacing={"16px"}
                      columnSpacing={"40px"}
                      sx={{ marginBottom: "20px" }}
                    >
                      <Grid item>
                        <MediumTypography
                          labelId="RCA.RCA"
                          defaultLabel="Root Cause Analysis"
                          sxProps={{ marginBottom: "10px" }}
                          textColor={"rgba(159, 173, 188, 1)"}
                        />
                        <Box className="flex__">
                          <FileUploadInput
                            labelId="RCA.RCA"
                            defaultLabel="Root Cause Analysis"
                            value={
                              values.uploadDocBean !== null
                                ? values.uploadDocBean.fileName
                                : ""
                            }
                            handleChange={(value: DocumentTypeBean) =>
                              setFieldValue("uploadDocBean", value)
                            }
                            // url={
                            //   values.uploadDocBean !== null &&
                            //   values.uploadDocBean.document !== null
                            //     ? values.uploadDocBean.document
                            //     : ""
                            // }
                            url={
                              values.rootCauseAnalysis !== null
                                ? values.rootCauseAnalysis
                                : ""
                            }
                            reset={resetSignal}
                            cardEdit={cardEdit}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid container className="mt-xs">
                      <Grid item xs={12} lg={12} md={12} xl={12}>
                        <Box>
                          <Divider
                            variant="fullWidth"
                            sx={{
                              borderTop: "1px solid #333b43",
                            }}
                          />
                        </Box>
                      </Grid>
                    </Grid>

                    <Box sx={{ padding: "24px 0px 0px 0px" }}>
                      <Box className="flex__" sx={{ marginBottom: "20px" }}>
                        <Box>
                          <MediumTypography
                            labelId="RCA.EquipmentDetails"
                            defaultLabel="Equipment Nameplate Details"
                            fontSize="20px"
                            fontWeight={500}
                            textColor="#FFFFFF"
                          />
                        </Box>
                      </Box>
                    </Box>
                    <Grid
                      container
                      direction={"row"}
                      className="justifyContentSpaceBtw"
                      alignItems={"center"}
                      rowSpacing={"16px"}
                      columnSpacing={"40px"}
                      sx={{ marginBottom: "20px" }}
                    >
                      <Grid item>
                        <MediumTypography
                          labelId="RCA.EquipmentName"
                          defaultLabel="Equipment name/Tag no"
                          sxProps={{ marginBottom: "10px" }}
                          textColor={"rgba(159, 173, 188, 1)"}
                        />
                        <Box
                          className="flex__"
                          sx={{ position: "relative", alignItems: "center" }}
                        >
                          <TextInput
                            labelId="RCA.EquipmentName"
                            className="textInputWidth"
                            defaultLabelId="Equipment name/Tag no"
                            borderColor="1px solid rgba(166, 197, 226, 0.16)"
                            Value={values.equipmentNames[0]?.name}
                            handlechange={(value: string) => {}}
                            variant="outlined"
                            backGroundColor="#22272B"
                            fieldheight="40px"
                            textColor={
                              cardEdit
                                ? "rgba(255, 255, 255, 1)"
                                : "rgba(140, 155, 171, 1)"
                            }
                            inputProps={{
                              readOnly: true,
                            }}
                          />
                          <Box
                            sx={{
                              padding: "4px 8px 4px 8px",
                              backgroundColor: "#191E22",
                              border: "1px solid #374048",
                              height: "24px",
                              borderRadius: "4px",
                              right: "8px",
                              position: "absolute",
                            }}
                          >
                            <MediumTypography
                              labelId={
                                values.equipmentNames.length > 1
                                  ? values.equipmentNames.length.toString()
                                  : ""
                              }
                              defaultLabel={
                                values.equipmentNames.length > 1
                                  ? values.equipmentNames.length.toString()
                                  : ""
                              }
                              sxProps={{
                                lineHeight: "normal",
                                fontSize: "12px",
                              }}
                            />
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item>
                        <MediumTypography
                          labelId="RCA.AssetCategory"
                          defaultLabel="Asset Category"
                          sxProps={{ marginBottom: "10px" }}
                          textColor={"rgba(159, 173, 188, 1)"}
                        />
                        <TextInput
                          labelId="RCA.AssetCategory"
                          className="textInputWidth"
                          defaultLabelId="Asset Category"
                          borderColor="1px solid rgba(166, 197, 226, 0.16)"
                          Value={values.assetCategory.name}
                          handlechange={(value: string) => {
                            // setFieldValue("make", value);
                          }}
                          variant="outlined"
                          backGroundColor="#22272B"
                          fieldheight="40px"
                          textColor={
                            cardEdit
                              ? "rgba(255, 255, 255, 1)"
                              : "rgba(140, 155, 171, 1)"
                          }
                          inputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <MediumTypography
                          labelId="RCA.Make"
                          defaultLabel="Make"
                          sxProps={{ marginBottom: "10px" }}
                          textColor={"rgba(159, 173, 188, 1)"}
                        />
                        <DropdownComponent
                          disabled={!cardEdit}
                          names={convertToOptionType(values.makeModels || [])}
                          value={
                            values.selectedMakeId === 0
                              ? ""
                              : values.selectedMakeId.toString()
                          }
                          handleChange={(value: string) => {
                            setFieldValue("selectedMakeId", value);
                            setFieldValue("selectedModelId", "");
                            setModelOptions(
                              getModelOptions(
                                rcaInitialValues.makeModels || [],
                                Number(value) || 0
                              )
                            );
                          }}
                          labelid="RCA.MakeLabel"
                          defaultlabelid="--Select Make--"
                          minWidth="250px"
                          sxProps={{ width: "250px" }}
                        />
                      </Grid>
                      <Grid item>
                        <MediumTypography
                          labelId="RCA.Model"
                          defaultLabel="Model"
                          sxProps={{ marginBottom: "10px" }}
                          textColor={"rgba(159, 173, 188, 1)"}
                        />
                        <DropdownComponent
                          disabled={!cardEdit}
                          names={convertToOptionType(modelOptions || [])}
                          value={
                            values.selectedModelId === 0
                              ? ""
                              : values.selectedModelId.toString()
                          }
                          handleChange={(value: string) => {
                            setFieldValue("selectedModelId", value);
                          }}
                          labelid="RCA.ModelLabel"
                          defaultlabelid="--Select Model--"
                          minWidth="250px"
                          sxProps={{ width: "250px" }}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      direction={"row"}
                      className="justifyContentSpaceBtw"
                      alignItems={"center"}
                      rowSpacing={"16px"}
                      columnSpacing={"40px"}
                      sx={{ marginBottom: "20px" }}
                    >
                      <Grid item>
                        <MediumTypography
                          labelId="RCA.SerialNo"
                          defaultLabel="Serial No"
                          sxProps={{ marginBottom: "10px" }}
                          textColor={"rgba(159, 173, 188, 1)"}
                        />
                        <TextInput
                          labelId="RCA.SerialNo"
                          className="textInputWidth"
                          defaultLabelId="Serial No"
                          borderColor="1px solid rgba(166, 197, 226, 0.16)"
                          Value={values.serialNo}
                          handlechange={(value: string) => {
                            setFieldValue("serialNo", value);
                          }}
                          variant="outlined"
                          backGroundColor="#22272B"
                          fieldheight="40px"
                          textColor={
                            cardEdit
                              ? "rgba(255, 255, 255, 1)"
                              : "rgba(140, 155, 171, 1)"
                          }
                          inputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <MediumTypography
                          labelId="RCA.Warranty"
                          defaultLabel="Warranty"
                          sxProps={{ marginBottom: "10px" }}
                          textColor={"rgba(159, 173, 188, 1)"}
                        />
                        <TextInput
                          labelId="RCA.Warranty"
                          className="textInputWidth"
                          defaultLabelId="Warranty"
                          borderColor="1px solid rgba(166, 197, 226, 0.16)"
                          Value={values.warranty}
                          handlechange={(value: string) => {
                            setFieldValue("warranty", value);
                          }}
                          variant="outlined"
                          backGroundColor="#22272B"
                          fieldheight="40px"
                          textColor={
                            cardEdit
                              ? "rgba(255, 255, 255, 1)"
                              : "rgba(140, 155, 171, 1)"
                          }
                          inputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <MediumTypography
                          labelId="RCA.IPClass"
                          defaultLabel="IP Class"
                          sxProps={{ marginBottom: "10px" }}
                          textColor={"rgba(159, 173, 188, 1)"}
                        />
                        <Box className="flex__">
                          <TextInput
                            labelId="RCA.IPClass"
                            className="textInputWidth"
                            defaultLabelId="IP Class"
                            borderColor="1px solid rgba(166, 197, 226, 0.16)"
                            Value={values.ipClass}
                            handlechange={(value: string) => {
                              setFieldValue("ipClass", value);
                            }}
                            variant="outlined"
                            backGroundColor="#22272B"
                            fieldheight="40px"
                            textColor={
                              cardEdit
                                ? "rgba(255, 255, 255, 1)"
                                : "rgba(140, 155, 171, 1)"
                            }
                            inputProps={{
                              readOnly: !cardEdit,
                            }}
                          />
                        </Box>
                      </Grid>
                      <Grid item>
                        <MediumTypography
                          labelId="RCA.InstallDate"
                          defaultLabel="Installation Date"
                          sxProps={{ marginBottom: "10px" }}
                          textColor={"rgba(159, 173, 188, 1)"}
                        />
                        <Box className="flex__">
                          <CustomDatePicker
                            disabledDate={!cardEdit}
                            disableFutureDate={false}
                            date={
                              values.erectionDate !== null
                                ? dayjs(values.erectionDate)
                                : null
                            }
                            hideIcon={true}
                            onDateSelect={(newDate: Dayjs) => {
                              const formattedDate =
                                dayjs(newDate).format("YYYY-MM-DD");
                              setFieldValue("erectionDate", formattedDate);
                            }}
                            inputFormat="DD MMM YYYY"
                            leftArrowRequired={false}
                            textWidth="235px"
                            rightArrowRequired={false}
                            alignCenter={false}
                            labelId="RCA.date"
                            defaultLabelId="Select Date"
                            textColor={
                              cardEdit
                                ? "rgba(255, 255, 255, 1)"
                                : "rgba(140, 155, 171, 1)"
                            }
                            disableOpenPicker={!cardEdit}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      direction={"row"}
                      className="justifyContentSpaceBtw"
                      alignItems={"center"}
                      rowSpacing={"16px"}
                      columnSpacing={"40px"}
                      sx={{ marginBottom: "20px" }}
                    >
                      <Grid item>
                        <MediumTypography
                          labelId="RCA.YearOfManufacturing"
                          defaultLabel="Year of manufacturing"
                          sxProps={{ marginBottom: "10px" }}
                          textColor={"rgba(159, 173, 188, 1)"}
                        />
                        <TextInput
                          labelId="RCA.YearOfManufacturing"
                          className="textInputWidth"
                          defaultLabelId="Year of manufacturing"
                          borderColor="1px solid rgba(166, 197, 226, 0.16)"
                          Value={
                            values.yearOfManufacture === 0
                              ? ""
                              : values.yearOfManufacture.toString()
                          }
                          handlechange={(value: string) => {
                            setFieldValue("yearOfManufacture", value);
                          }}
                          variant="outlined"
                          backGroundColor="#22272B"
                          fieldheight="40px"
                          textColor={
                            cardEdit
                              ? "rgba(255, 255, 255, 1)"
                              : "rgba(140, 155, 171, 1)"
                          }
                          inputProps={{
                            readOnly: !cardEdit,
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <MediumTypography
                          labelId="RCA.TypeOfBreakDown"
                          defaultLabel="Type of Breakdown"
                          sxProps={{ marginBottom: "10px" }}
                          textColor={"rgba(159, 173, 188, 1)"}
                        />
                        <TextInput
                          labelId="RCA.TypeOfBreakDown"
                          className="textInputWidth"
                          defaultLabelId="Type of Breakdown"
                          borderColor="1px solid rgba(166, 197, 226, 0.16)"
                          Value={values.typeOfBreakdown}
                          handlechange={(value: string) => {
                            setFieldValue("typeOfBreakdown", value);
                          }}
                          variant="outlined"
                          backGroundColor="#22272B"
                          fieldheight="40px"
                          textColor={
                            cardEdit
                              ? "rgba(255, 255, 255, 1)"
                              : "rgba(140, 155, 171, 1)"
                          }
                          inputProps={{
                            readOnly: !cardEdit,
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <MediumTypography
                          labelId="RCA.EquipementCritical"
                          defaultLabel="Equipment Criticality"
                          sxProps={{ marginBottom: "10px" }}
                          textColor={"rgba(159, 173, 188, 1)"}
                        />
                        <Box className="flex__">
                          <DropdownComponent
                            disabled={!cardEdit}
                            names={convertToOptionType(
                              values.equipCriticality || []
                            )}
                            value={
                              values.selectedEquipCriticality === 0
                                ? ""
                                : values.selectedEquipCriticality.toString()
                            }
                            handleChange={(value: string) => {
                              setFieldValue("selectedEquipCriticality", value);
                            }}
                            labelid="RCA.EquipementCriticalityLabel"
                            defaultlabelid="--Select Criticality--"
                            minWidth="250px"
                            sxProps={{ width: "250px" }}
                          />
                        </Box>
                      </Grid>
                      <Grid item>
                        <MediumTypography
                          labelId="RCA.PMF"
                          defaultLabel="Preventive Maintanace Frequency"
                          sxProps={{ marginBottom: "10px" }}
                          textColor={"rgba(159, 173, 188, 1)"}
                        />
                        <Box className="flex__">
                          <TextInput
                            labelId="RCA.PMF"
                            className="textInputWidth"
                            defaultLabelId="Preventive Maintanace Frequency"
                            borderColor="1px solid rgba(166, 197, 226, 0.16)"
                            Value={values.preventiveMaintenanceFrequency}
                            handlechange={(value: string) => {
                              setFieldValue(
                                "preventiveMaintenanceFrequency",
                                value
                              );
                            }}
                            variant="outlined"
                            backGroundColor="#22272B"
                            fieldheight="40px"
                            textColor={
                              cardEdit
                                ? "rgba(255, 255, 255, 1)"
                                : "rgba(140, 155, 171, 1)"
                            }
                            inputProps={{
                              readOnly: true,
                            }}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      direction={"row"}
                      className="justifyContentSpaceBtw"
                      alignItems={"center"}
                      rowSpacing={"16px"}
                      columnSpacing={"40px"}
                      sx={{ marginBottom: "20px" }}
                    >
                      <Grid item>
                        <MediumTypography
                          labelId="RCA.generationLoss"
                          defaultLabel="Generation Loss"
                          sxProps={{ marginBottom: "10px" }}
                          textColor={"rgba(159, 173, 188, 1)"}
                        />
                        <TextInput
                          labelId="RCA.generationLoss"
                          className="textInputWidth"
                          defaultLabelId="Generation Loss"
                          borderColor="1px solid rgba(166, 197, 226, 0.16)"
                          Value={values.generationLoss}
                          handlechange={(value: string) => {
                            setFieldValue("generationLoss", value);
                          }}
                          variant="outlined"
                          backGroundColor="#22272B"
                          fieldheight="40px"
                          textColor={
                            cardEdit
                              ? "rgba(255, 255, 255, 1)"
                              : "rgba(140, 155, 171, 1)"
                          }
                          inputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Grid container className="mt-xs">
                    <Grid item xs={12} lg={12} md={12} xl={12}>
                      <Box>
                        <Divider
                          variant="fullWidth"
                          sx={{
                            borderTop: "1px solid #333b43",
                          }}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{ padding: "24px 0px 0px 0px" }}>
                      <Box className="flex__" sx={{ marginBottom: "20px" }}>
                        <Box>
                          <MediumTypography
                            labelId="RCA.EventDescription"
                            defaultLabel="Event Discription"
                            fontSize="20px"
                            fontWeight={500}
                            textColor="#FFFFFF"
                          />
                        </Box>
                      </Box>
                    </Box>
                    <RichTextEditor
                      labelId="RCA.EventDescription"
                      defaultLabelId="Event Description"
                      data={values.eventDescription}
                      onChangeCallBack={(e) =>
                        setFieldValue("eventDescription", e)
                      }
                      readOnly={!cardEdit}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={3}>
                  {<WorkOrderActivity cardId={id} isVisible={isVisible} />}
                </Grid>
              </Grid>
            </Grid>
          );
        }}
      </Formik>
    </Box>
  );
};

export default RCAInfo;
