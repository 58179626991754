import React, { createContext, useContext, useState, ReactNode } from 'react'

interface ErrorContextType {
  error: string
  setError: (error: string) => void
}

const ApiErrorContext = createContext<ErrorContextType | undefined>(undefined)

export const useError = (): ErrorContextType => {
  const context = useContext(ApiErrorContext)
  if (!context) {
    throw new Error('useError Hook must be used within an ErrorProvider')
  }
  return context
}

interface ErrorProviderProps {
  children: ReactNode
}

export const ErrorProvider = ({
  children,
}: ErrorProviderProps): JSX.Element => {
  const [error, setError] = useState<string>('')

  return (
    <ApiErrorContext.Provider value={{ error, setError }}>
      {children}
    </ApiErrorContext.Provider>
  )
}
