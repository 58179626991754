import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { FC, useContext, useEffect, useRef, useState } from "react";
import MediumTypography from "../../../components/formlib/MediumTypography";
import ButtonComponent from "../../../components/formlib/ButtonComponent";
import CloseIcon from "@mui/icons-material/Close";
import "../styles.css";
import TextInput from "../../../components/formlib/TextInput";

import CustomDropdown from "../../../components/formlib/CustomDropdown";
import { LoaderContext, LoaderContextType } from "../../../layouts/appSidebar";
import ErrorModal from "../../../components/formlib/modal/ErrorModal";

import { getOptions, handleError } from "../../../utils/commonFunctions";

import {
  customSaveDataApi,
  fetchDataApi,
} from "../../../api/MasterDataManagement/Common";

import { ReactComponent as Sort } from "../../../assets/images/Sort.svg";
import CustomTableHeader from "../../../components/formlib/CustomTableHeader";
import { HeadCell } from "../types/accountTypes";
import useAvailableWidth from "../../../utils/useAvailableWidth";
import useAvailableHeight from "../../../utils/useAvailableHeight";
import CheckBox from "../../../components/formlib/CheckBox";
import {
  ChecklistNameTypes,
  ManageMapChecklistRecordsType,
  ManageMapChecklistRecordType,
  SaveMapChecklist,
} from "../types/mapChecklistTypes";

// Configurations

const apiUri = "checklistLineMap";

const searchKeys: string[] = []; //["description", "weights"];

const staticColumn = "description";

type RecordType = ManageMapChecklistRecordType;
type RecordsType = ManageMapChecklistRecordsType;

interface ModalProps {
  open: boolean;
  handleClose: () => void;
  checklistData: ChecklistNameTypes[];
  id?: number;
  onIdChange: (idValue: number) => void;
  getDataCallback: () => void; // reloadAccountData
}

const MapLineItemsModal: FC<ModalProps> = ({
  open,
  handleClose,
  checklistData,
  id,
  onIdChange,
  getDataCallback,
}) => {
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;

  const availableWidth = useAvailableWidth(450);
  const availableHeight = useAvailableHeight(350);
  const emptyTableHeight = useAvailableHeight(420);

  const containerRef = useRef(null);

  const [listData, setListData] = useState<RecordsType | null>(null);
  const [tableData, setTableData] = useState<RecordType[]>([]);
  const [reorderedFields, setReorderedFields] = useState<HeadCell[]>([]);

  const [originalTableData, setOriginalTableData] = useState<RecordType[]>([]);
  const [updatedTableData, setUpdatedTableData] = useState<RecordType[]>([]);
  const [isSelectAll, setIsSelectAll] = useState<boolean>(false);

  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [errorDesc, setErrorDesc] = useState<string>("");

  const dataFieldsWithIcons = reorderedFields?.map((field) => {
    return { ...field, icon: <Sort /> };
  });

  const totalWeightageActual = updatedTableData
    .filter((ot) => ot.isLineItemSelected)
    .reduce((acc, curr) => acc + curr.weights, 0);

  const totalWeightage = Number(totalWeightageActual.toFixed(2));

  const sortLineItems = (lineItemsArr: RecordType[]) => {
    // sort response.checkListLineItems by isLineItemSelected which is boolean or undefined. consider undefined as false
    return lineItemsArr.sort((a, b) => {
      // Convert undefined to false (which becomes 0) and true to 1
      const aSelected = Number(!!a.isLineItemSelected);
      const bSelected = Number(!!b.isLineItemSelected);

      return bSelected - aSelected;
    });
  };

  const saveData = (params: RecordType[]) => {
    toggleLoader(true);

    const isWeightInvalid = updatedTableData
      .filter((ot) => ot.isLineItemSelected)
      .some((ot) => ot.weights < 1);

    if (isWeightInvalid) {
      setErrorDesc("checklistMap.invalidLineWeight");
      setOpenErrorModal(true);
      toggleLoader(false);
      return;
    }

    if (totalWeightage !== 100) {
      setErrorDesc("checklistMap.invalidWeight");
      setOpenErrorModal(true);
      toggleLoader(false);
      return;
    }

    const updatedParams = {
      selectedCheckListMasterId: id ?? 0,
      checkListLineItems: params
        .filter((item) => item?.isLineItemSelected)
        .map((item) => ({
          ...item,
          taskGroupId: undefined,
        })),
      mappedTaskGroups: null,
    };

    const fullUri = `${apiUri}/${id}/update?isVersionRequired=false&languageCode=en&isSequenceChanged=false`;

    customSaveDataApi<SaveMapChecklist>(fullUri, updatedParams)
      .then(() => {
        handleClose();
        getDataCallback();
        toggleLoader(false);
      })
      .catch((err) => {
        handleError(err, setErrorDesc);
        setOpenErrorModal(true);
        toggleLoader(false);
      });
  };

  useEffect(() => {
    if (open) {
      getRecords(true);
    }
  }, [id, open]);

  useEffect(() => {
    setTableData(sortLineItems(updatedTableData));
  }, [updatedTableData]);

  const getRecords = (
    resetData: boolean = false,

    language: string = "en"
  ) => {
    toggleLoader(true);

    fetchDataApi<RecordsType>(apiUri, id)
      .then((response) => {
        toggleLoader(false);
        if (response) {
          setListData(response);

          const sortedData = sortLineItems(response.checkListLineItems);

          if (resetData) {
            setTableData(sortedData);
            setOriginalTableData(sortedData);
            setUpdatedTableData(sortedData);
          } else {
            setTableData((prevMessages) => [...prevMessages, ...sortedData]);
            setOriginalTableData((prevMessages) => [
              ...prevMessages,
              ...sortedData,
            ]);
            setUpdatedTableData((prevMessages) => [
              ...prevMessages,
              ...sortedData,
            ]);
          }

          setReorderedFields(response.dataFields);
          setIsSelectAll(false);
        }
      })
      .catch((error) => {
        toggleLoader(false);
        setOpenErrorModal(true);
        handleError(error, setErrorDesc);
      });
  };

  const updateSelection = (rowId: number, checked: boolean) =>
    setUpdatedTableData(
      updatedTableData.map((ot) =>
        ot.id === rowId ? { ...ot, isLineItemSelected: checked } : ot
      )
    );

  const updateWeight = (rowId: number, value: string) =>
    setUpdatedTableData(
      updatedTableData.map((ot) =>
        ot.id === rowId ? { ...ot, weights: Number(value) ?? 0 } : ot
      )
    );

  const selectAllToggle = (checked: boolean) => {
    setUpdatedTableData(
      updatedTableData.map((ot) => ({ ...ot, isLineItemSelected: checked }))
    );
    setIsSelectAll(checked);
  };

  const findUpdatedTableData = (rowId: number) => {
    return updatedTableData.find((ot) => ot.id === rowId);
  };

  return (
    <>
      {openErrorModal && (
        <ErrorModal
          descriptionText={errorDesc}
          open={openErrorModal}
          handleClose={() => {
            setOpenErrorModal(false);
          }}
          onPositiveClick={() => {
            setOpenErrorModal(false);
          }}
        />
      )}

      <Dialog
        className="account-dialog main-wrapper-box"
        open={open}
        fullWidth
        maxWidth={"lg"}
      >
        <DialogTitle className="padding_20">
          <MediumTypography
            labelId={"checklistMap.title"}
            defaultLabel="Map Check List - Line Items"
            textColor="#FFF"
            fontSize="22px"
            fontWeight={700}
          />
        </DialogTitle>

        <IconButton
          aria-label="close"
          onClick={() => {
            handleClose();
          }}
          className="closeIcon"
        >
          <CloseIcon />
        </IconButton>

        <DialogContent dividers className="padding_20">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <MediumTypography
                className="input-label"
                labelId="checklist.title"
                defaultLabel="Check List"
              />

              <CustomDropdown
                disableCreate={true}
                options={getOptions(checklistData)}
                selectedValue={id}
                onSelectedValueChange={(value) => {
                  onIdChange(Number(value as string) ?? 0);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <MediumTypography
                className="input-label"
                labelId="checklist.checklistType"
                defaultLabel="Check List Type"
              />

              <TextInput
                className="text-input-field"
                type="text"
                variant="outlined"
                inputProps={{
                  readOnly: true,
                }}
                disabled={true}
                Value={
                  checklistData?.find(
                    (item) => item.id?.toString() === id?.toString()
                  )?.checklistType ?? ""
                }
              />
            </Grid>
            <Grid item xs={12}>
              {listData && (
                <Box>
                  <Box
                    sx={{
                      overflowY: "auto",
                      height: availableHeight,

                      "&::-webkit-scrollbar": { display: "none" },
                      position: "relative",
                    }}
                  >
                    <TableContainer
                      component={Paper}
                      sx={{
                        overflowX: "auto",
                        maxWidth: "100%",
                        width: availableWidth,
                        backgroundColor: "#323337",
                        height:
                          tableData.length !== 0 ? availableHeight : "auto",
                        "&::-webkit-scrollbar": { display: "none" },
                      }}
                      onScroll={() => {}}
                      ref={containerRef}
                    >
                      <Table>
                        <CustomTableHeader
                          hideSerialNo={true}
                          hideMoreButton={true}
                          widthObject={{
                            description: "auto",
                            weights: "180px",
                          }}
                          headerNames={dataFieldsWithIcons}
                          searchableKeys={searchKeys}
                          stickyItems={staticColumn}
                          dynamicValue={totalWeightage}
                          selectAllToggle={selectAllToggle}
                          isSelectAll={isSelectAll}
                        />
                        <TableBody>
                          {tableData.length !== 0 &&
                            tableData.map((row, rowIndex) => (
                              <TableRow
                                key={row.id}
                                sx={{
                                  backgroundColor:
                                    rowIndex % 2 === 0 ? "#2B3033" : "#22272B",
                                }}
                              >
                                {/* Sticky First Column */}

                                {dataFieldsWithIcons.map((cell: HeadCell) => {
                                  const cellValue =
                                    row[cell.key as keyof RecordType];

                                  if (cell.key === "weights") {
                                    let weightsValue = findUpdatedTableData(
                                      row.id
                                    )?.weights?.toString();

                                    if (!weightsValue || weightsValue === "0") {
                                      weightsValue = "";
                                    }

                                    return (
                                      <TableCell
                                        key={cell.key}
                                        align={"left"}
                                        sx={{
                                          padding: "16px 16px",
                                          borderBottom: "none",
                                          backgroundColor:
                                            rowIndex % 2 === 0
                                              ? "#2B3033"
                                              : "#22272B",
                                        }}
                                        style={{ width: "200px" }}
                                      >
                                        {findUpdatedTableData(row.id)
                                          ?.isLineItemSelected && (
                                          <TextInput
                                            type="number"
                                            fieldheight="28px"
                                            sxProps={{
                                              "&.MuiFormControl-root": {
                                                width: "150px",
                                              },
                                            }}
                                            className="text-input-field"
                                            variant="outlined"
                                            inputProps={{
                                              readOnly: false,
                                            }}
                                            labelId="common.placeHolderText"
                                            defaultLabelId="--- type here ---"
                                            Value={weightsValue}
                                            handlechange={(value: string) => {
                                              const roundedValue =
                                                Number(value).toFixed(2);
                                              updateWeight(
                                                row.id,
                                                roundedValue
                                              );
                                            }}
                                          />
                                        )}
                                      </TableCell>
                                    );
                                  }

                                  return (
                                    <>
                                      {cell.key === staticColumn ? (
                                        <TableCell
                                          key={cell.key}
                                          align={"left"}
                                          sx={{
                                            padding: "16px 16px",
                                            borderBottom: "none",
                                            position: "sticky",

                                            backgroundColor:
                                              rowIndex % 2 === 0
                                                ? "#2B3033"
                                                : "#22272B",
                                          }}
                                        >
                                          <Box
                                            display={"flex"}
                                            alignItems={"center"}
                                          >
                                            <CheckBox
                                              value={
                                                findUpdatedTableData(row.id)
                                                  ?.isLineItemSelected || false
                                              }
                                              style={{
                                                marginRight: "10px",
                                              }}
                                              onCheckBoxClick={(checked) => {
                                                updateSelection(
                                                  row.id,
                                                  checked
                                                );
                                              }}
                                            />
                                            <MediumTypography
                                              label={
                                                cellValue !== undefined &&
                                                cellValue !== null &&
                                                cellValue !== ""
                                                  ? cellValue.toString()
                                                  : "-"
                                              }
                                            />
                                          </Box>
                                        </TableCell>
                                      ) : (
                                        <TableCell
                                          key={cell.key}
                                          align={"left"}
                                          sx={{
                                            padding: "16px 16px",
                                            borderBottom: "none",
                                            backgroundColor:
                                              rowIndex % 2 === 0
                                                ? "#2B3033"
                                                : "#22272B",
                                          }}
                                          style={{ width: "200px" }}
                                        >
                                          <MediumTypography
                                            label={
                                              cellValue !== undefined &&
                                              cellValue !== null &&
                                              cellValue !== ""
                                                ? cellValue.toString()
                                                : "-"
                                            }
                                          />
                                        </TableCell>
                                      )}
                                    </>
                                  );
                                })}

                                {/* Sticky Last Column */}
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    {tableData.length === 0 && (
                      <Box
                        sx={{
                          alignItems: "center",
                          display: "flex",
                          justifyContent: "center",
                          height: emptyTableHeight,
                        }}
                      >
                        <MediumTypography
                          labelId="ManageAccounts.emptymsg"
                          defaultLabel="No records available"
                          fontSize="20px"
                          fontWeight={700}
                        />
                      </Box>
                    )}
                  </Box>
                </Box>
              )}
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions className="dialogActions">
          <Box>
            <Grid
              className="flex__ justifyContent-FlexEnd"
              container
              direction="row"
              alignItems="right"
            >
              <Grid item>
                <ButtonComponent
                  sxProps={{ color: "#B6C2CF !important" }}
                  className="btn-primary btn-cancel mr-md"
                  variantType="outlined"
                  defaultLabelId={"Reset"}
                  labelId={"common.reset"}
                  onClick={() => {
                    setTableData(originalTableData);
                    setUpdatedTableData(originalTableData);
                    setIsSelectAll(false);
                  }}
                />
              </Grid>
              <Grid item>
                <ButtonComponent
                  className="btn-primary btn-submit"
                  variantType="contained"
                  defaultLabelId={"Save"}
                  labelId={"btn.save"}
                  onClick={() => {
                    saveData(updatedTableData);
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MapLineItemsModal;
