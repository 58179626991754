import { Box, Divider, Grid, Tooltip } from "@mui/material";
import MediumTypography from "../../../components/formlib/MediumTypography";
import { useEffect, useState } from "react";
import { PMDetailsType } from "./types";
import dayjs from "dayjs";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as CrossIcon } from "../../../assets/images/_CrossIcon_.svg";
import SmallBorderLine from "../../../components/formlib/SmallBorderLine";
import StatusExecution from "../../../components/formlib/StatusExecution";
import PreventiveMaintenanceDetails from "./PreventiveMaintenanceDetails";
import { getPreventiveMaintenanceDetailsApi } from "../../../api/preventiveMaintenance/PreventiveMaintenance";

import Loader from "../../../components/shared/Loader";
import ErrorModal from "../../../components/formlib/modal/ErrorModal";
import ModalPopup from "../../../components/formlib/modal/ModalPopup";
import PreventiveMaintenanceTasks from "./PreventiveMaintenanceTasks";
import { SCHEDULER_DASHBOARD } from "../../../routes/Routing";
import { ReactComponent as Link } from "../../../assets/images/_LinkIcon_.svg";
import SOPModal from "../../../components/formlib/modal/SOPModal";
import { StatusDataType } from "../../../api/moduleCleaning/ModuleCleaningApi";
import { ReactComponent as ChevronCircleIcon } from "../../../assets/images/chevronDownCircleIcon.svg";
import { handleError } from "../../../utils/commonFunctions";
import { ReactComponent as Download } from "../../../assets/images/DownloadIcon.svg";
import { generateReport } from "../../../api/workOrderApi/schedulerApi/Scheduler";

const initialPmData: PMDetailsType = {
  woId: 0,
  isEdit: false,
  isAssetGrouped: false,
  noRequiredStaff: 0,
  noMinStaff: 0,
  version: 0,
  shift: {
    shiftId: 0,
    shiftName: "",
    shiftStartTime: "",
    shiftEndTime: "",
    teams: [],
    cardBlockCount: 0,
    memberCount: 0,
  },
  status: [],
  cardDetailsInfo: {
    asset: "",
    code: "",
    type: "",
    assetCatFreqDesc: "",
    workOrderUniqueId: 0,
    frequencyDisplay: "",
    assetNameAndCount: "",
    lastUpdated: "",
  },
  additionalInfoBean: {
    cycleCountInc: 0.0,
    cumulativeCycleCount: 0.0,
    rainFall: 0.0,
    grassImpact: false,
    vendorId: 0,
    vendorName: "",
    grassImpactTable: false,
    lastUpdatedBy: null,
  },
  assignee: [],
  approver1: null,
  approver2: null,
  approver3: null,
  workOrderPmInfo: {
    scheduledStartDateTime: "",
    scheduledEndDateTime: "",
    actualStartDateTime: "",
    actualEndDateTime: "",
    remarks: "",
    assetGroup: {
      assetGroupId: 0,
      assetGroupName: "",
    },
    assetCategory: {
      assetCategoryId: 0,
      assetCategoryName: "",
    },
    assets: [],
    frequency: "",
    cycleName: "",
    leadAssignee: {
      id: 0,
      name: "",
      title: "",
      image: "",
      status: "",
    },
  },
  pmInfoBean: {
    relatedPmWo: 0,
    relatedCmWo: 0,
    woDescription: "",
    contractLineInfo: "",
    estimatedEffort: 0,
    actalEffort: 0,
  },
  vendorList: [],
  consumablesAndSpares: {
    consumableAndSpares: {
      category: [],
    },
  },
  definedScheduleStatus: [],
  scheduleExecution: {},
  isTaskListCompleted: false,
};

const PreventiveMaintenance = () => {
  const [pmData, setPmData] = useState<PMDetailsType>(initialPmData);
  const [activePage, setActivePage] = useState("details");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [errorDesc, setErrorDesc] = useState<string>("");
  const location = useLocation();
  const [openSOPModal, setOpenSOPModal] = useState(false);
  const navState = location?.state;
  const [openTemplateModal, setOpenTemplateModal] = useState(false);

  const stateCardId = navState?.cardId;
  const [fileUrl, setFileUrl] = useState<string | null>(null);
  const [showAwaitingApproval, setShowAwaitingApproval] = useState(false);
  const [lastTransition, setLastTransition] = useState<StatusDataType>(
    {} as StatusDataType
  );
  const [isVisiblePM, setIsVisiblePM] = useState(true);

  const handleOpen = (url: string | null) => {
    setFileUrl(url);
    setOpenSOPModal(true);
  };

  const generateReportForWorkOrder = () => {
    setIsLoading(true);
    if (stateCardId) {
      generateReport(stateCardId)
        .then((response) => {
          setIsLoading(false);
          setOpenTemplateModal(true);
          setFileUrl(response.encodedS3Url);
        })
        .catch((err) => {
          setOpenErrorModal(true);
          handleError(err, setErrorDesc);
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    // setPmData(Data);
    getPreventiveMaintenanceDetails();
  }, []);

  const getPreventiveMaintenanceDetails = () => {
    setIsLoading(true);
    getPreventiveMaintenanceDetailsApi(stateCardId)
      .then((res) => {
        setPmData(res);
        const lastStatus = res.status[res.status.length - 2];
        const showAwaitingApprovals = [
          "COMPLETED",
          "LEVEL1",
          "LEVEL2",
        ].includes(lastStatus.code);

        setShowAwaitingApproval(showAwaitingApprovals);

        // Find the last transition date and time
        const lastTransitionStatus = lastStatus;
        setLastTransition(lastTransitionStatus);
        setFileUrl(res?.sopLink || null);
        setIsLoading(false);
      })
      .catch((err) => {
        setOpenErrorModal(true);
        handleError(err, setErrorDesc);
        setIsLoading(false);
      });
  };

  const toggleVisibility = () => {
    setIsVisiblePM(!isVisiblePM);
  };

  return (
    <Box component="main">
      {isLoading && <Loader />}
      {openErrorModal && (
        <ErrorModal
          descriptionText={errorDesc}
          open={openErrorModal}
          handleClose={() => setOpenErrorModal(false)}
          onPositiveClick={() => {
            setOpenErrorModal(false);
          }}
        />
      )}
      {openSOPModal && (
        <SOPModal
          open={openSOPModal}
          handleClose={() => setOpenSOPModal(false)}
          fileUrl={fileUrl}
        />
      )}
      {openTemplateModal && (
        <SOPModal
          open={openTemplateModal}
          handleClose={() => setOpenTemplateModal(false)}
          fileUrl={fileUrl}
        />
      )}
      {openModal && (
        <ModalPopup
          descriptionText={"SavedSuccessfully"}
          open={openModal}
          handleClose={() => setOpenModal(false)}
          onPositiveClick={() => setOpenModal(false)}
          hideButton={true}
          positiveButtonLabelId="OKButtonText"
        />
      )}
      {pmData.woId > 0 && (
        <Box sx={{ padding: "24px 0px 0px 0px" }}>
          <Box className="flex__" sx={{ gap: "10px", marginBottom: "10px" }}>
            <Box>
              <MediumTypography
                className="card-wo-category"
                label={pmData?.cardDetailsInfo.code}
                sxProps={{
                  color: "rgba(184, 172, 246, 1)",
                  border: `2px solid rgba(184, 172, 246, 1)`,
                  lineHeight: "normal",
                }}
              />
            </Box>
            <Box>
              <MediumTypography
                label={pmData?.cardDetailsInfo.type}
                textColor="rgba(184, 172, 246, 1)"
                fontSize="16px"
                fontWeight={700}
              />
            </Box>

            <Box className="flex__" sx={{ marginLeft: "auto" }}>
              <Box
                className="flex__ cursor__pointer"
                sx={{
                  marginRight: "40px",
                }}
                onClick={generateReportForWorkOrder}
              >
                <Download style={{ marginTop: "4px" }} />
                <MediumTypography
                  labelId="Download.text"
                  defaultLabel="Download"
                  className="ml-xs"
                />
              </Box>
              <Box
                className="flex__ cursor__pointer mr-md"
                onClick={() => handleOpen(fileUrl)}
              >
                <MediumTypography
                  labelId="sop.sopLink"
                  defaultLabel="View SOP"
                  textColor="#579DFF"
                  fontSize="16px"
                  className="mr-xs "
                />
                <Link />
              </Box>
              <Box className="position__relative mr-md">
                <MediumTypography
                  label={`Last Updated ${dayjs(
                    pmData?.cardDetailsInfo.lastUpdated
                  ).format("DD MMMM YYYY")} at ${dayjs(
                    pmData?.cardDetailsInfo.lastUpdated
                  ).format("HH:mm")}`}
                  textColor="#626F86"
                  fontSize="14px"
                  fontWeight={400}
                />
              </Box>
              <Box className="position__relative">
                <MediumTypography
                  labelId="CloseText"
                  defaultLabel="Close"
                  textColor="#626F86"
                  fontSize="14px"
                  fontWeight={400}
                />
              </Box>
              <Box>
                <CrossIcon
                  className="cursor__pointer"
                  onClick={() =>
                    navigate(SCHEDULER_DASHBOARD, {
                      state: {
                        date: navState.date,
                        dateRange: navState.dateRange,
                        schedulerViewChange: navState.schedulerViewChange,
                      },
                    })
                  }
                />
              </Box>
            </Box>
          </Box>
          <Box className="flex__" sx={{ gap: "10px", marginBottom: "10px" }}>
            <Box>
              <MediumTypography
                label={pmData?.cardDetailsInfo.workOrderUniqueId.toString()}
                textColor="#FFFFFF"
                fontSize="20px"
                fontWeight={700}
              />
            </Box>
            <SmallBorderLine />

            <Box>
              <MediumTypography
                className="card-wo-routine"
                label={pmData?.cardDetailsInfo.frequencyDisplay}
                sxProps={{ padding: "4px 4px !important" }}
              />
            </Box>
            <Box className="flex__">
              <MediumTypography
                label={pmData?.cardDetailsInfo.assetNameAndCount}
                textColor="#FFFFFF"
                fontSize="20px"
                fontWeight={700}
              />
              {isVisiblePM && (
                <Box
                  className="cursor__pointer"
                  sx={{
                    position: "relative",
                    alignItems: "end",
                    // backgroundColor: "#fff", // Optional: To give a white background around the icon
                    padding: "4px 8px", // Optional: Adjust the padding around the icon
                  }}
                  onClick={toggleVisibility}
                >
                  <Tooltip title="Hide State Transition">
                    <ChevronCircleIcon />
                  </Tooltip>
                </Box>
              )}
              {!isVisiblePM && (
                <Box
                  className="cursor__pointer"
                  sx={{
                    position: "relative",
                    alignItems: "end",
                    // backgroundColor: "#fff", // Optional: To give a white background around the icon
                    padding: "4px 8px", // Optional: Adjust the padding around the icon
                  }}
                  onClick={toggleVisibility}
                >
                  <Tooltip title="Show State Transition">
                    <ChevronCircleIcon className="rotated-180" />
                  </Tooltip>
                </Box>
              )}
            </Box>
            {showAwaitingApproval && (
              <Box className="flex__" sx={{ marginLeft: "auto" }}>
                <Box
                  className="flex__ position__relative"
                  sx={{
                    padding: "4px 12px 4px 12px",
                    borderRadius: "3px",
                    backgroundColor: "rgba(161, 189, 217, 0.08)",
                    right: "20px",
                  }}
                >
                  {/* <ProfilePhoto /> */}
                  <Box className="flex__">
                    <MediumTypography
                      defaultLabel={`Awaiting Approval - `}
                      labelId="AwaitingApproval"
                      textColor="rgba(255, 255, 255, 0.72)"
                      sxProps={{
                        textTransform: "none",
                        position: "relative",
                        left: "4px",
                      }}
                    />
                    <MediumTypography
                      label={`${
                        lastTransition.code === "COMPLETED"
                          ? lastTransition.approverRole1
                          : lastTransition.code === "LEVEL1"
                          ? lastTransition.approverRole2
                          : lastTransition.approverRole3
                      }`}
                      textColor="rgba(255, 255, 255, 0.72)"
                      className="ml-xs"
                      sxProps={{
                        textTransform: "none",
                        position: "relative",
                        left: "4px",
                      }}
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    padding: "4px 8px 4px 8px",
                    borderRadius: "3px",
                    backgroundColor: "rgba(161, 189, 217, 0.08)",
                  }}
                  className="flex__"
                >
                  <MediumTypography
                    defaultLabel={"Approval Requested on "}
                    labelId="ApprovedOnLabel"
                    textColor="rgba(255, 255, 255, 0.72)"
                    sxProps={{
                      textTransform: "none",
                    }}
                  />
                  <MediumTypography
                    label={`${dayjs(lastTransition.transitionDateTime).format(
                      "DD MMMM YYYY"
                    )} ${dayjs(lastTransition.transitionDateTime).format(
                      "HH:mm"
                    )}`}
                    className="ml-xs"
                    textColor="rgba(255, 255, 255, 0.72)"
                    sxProps={{
                      textTransform: "none",
                    }}
                  />
                </Box>
              </Box>
            )}
          </Box>
          {isVisiblePM && (
            <Grid container className="mt-md">
              <Grid item xs={12} lg={12} md={12} xl={12}>
                <Box>
                  <Divider
                    variant="fullWidth"
                    sx={{ borderTop: "1px solid #333b43" }}
                  />
                </Box>
              </Grid>
            </Grid>
          )}
          {isVisiblePM &&
            pmData.status.length > 0 &&
            pmData.definedScheduleStatus.length > 0 && (
              <StatusExecution
                statusArray={pmData.status}
                cardId={navState.cardId}
                WOUniqueId={pmData.cardDetailsInfo.workOrderUniqueId}
                version={pmData.version}
                definedScheduleStatus={pmData.definedScheduleStatus}
                scheduleExecution={pmData.scheduleExecution}
                statusCallBack={() => {
                  getPreventiveMaintenanceDetails();
                  // setActivePage("tasks");
                }}
                code={pmData.cardDetailsInfo.code}
                isTaskListCompleted={pmData.isTaskListCompleted}
              />
            )}
          <Grid container className="mt-md">
            <Grid item xs={12} lg={12} md={12} xl={12}>
              <Box>
                <Divider
                  variant="fullWidth"
                  sx={{ borderTop: "1px solid #333b43" }}
                />
              </Box>
            </Grid>
          </Grid>
          <Box className="mt-md ">
            <Box className="flex__ ">
              <Box
                sx={{
                  borderBottom:
                    activePage === "details" ? "2px solid #9FBBEB" : "",
                  cursor: "pointer",
                }}
                className="mr-md pb-md"
                onClick={() => setActivePage("details")}
              >
                <MediumTypography
                  labelId="workorderTitle"
                  defaultLabel="Work Order Details"
                  sxProps={{
                    color: activePage === "details" ? "#9FBBEB" : "#44546F",
                  }}
                />
              </Box>
              <Box
                sx={{
                  borderBottom:
                    activePage === "tasks" ? "2px solid #9FBBEB" : "",
                  cursor: "pointer",
                }}
                className="mr-md pb-md"
                onClick={() => setActivePage("tasks")}
              >
                <MediumTypography
                  labelId="pm.tasksText"
                  defaultLabel="Tasks"
                  sxProps={{
                    color: activePage === "tasks" ? "#9FBBEB" : "#44546F",
                  }}
                />
              </Box>
            </Box>
          </Box>
          <Grid container>
            <Grid item xs={12} lg={12} md={12} xl={12}>
              <Box>
                <Divider
                  variant="fullWidth"
                  sx={{ borderTop: "1px solid #333b43" }}
                />
              </Box>
            </Grid>
          </Grid>
          <Box component="div">
            {activePage === "details" && (
              <PreventiveMaintenanceDetails
                stateCardId={stateCardId}
                apiData={pmData}
                reloadPmApiData={getPreventiveMaintenanceDetails}
                isVisible={isVisiblePM}
              />
            )}

            {activePage === "tasks" && (
              <PreventiveMaintenanceTasks
                stateCardId={stateCardId}
                status={pmData.status}
                tasksCallBack={(completed) => {
                  getPreventiveMaintenanceDetails();
                  setActivePage("tasks");
                }}
                isVisible={isVisiblePM}
              />
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default PreventiveMaintenance;
