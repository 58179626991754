import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import React, { FC, useContext, useEffect, useState } from "react";
import MediumTypography from "../MediumTypography";
import ButtonComponent from "../ButtonComponent";
import CheckBox from "../CheckBox";
import CardComponent from "./../CardComponent";
import "../../../pages/scheduler/css/scheduler.css";
import { ShiftCardTypes, ShiftsCardType } from "../../../utils/type";
import { dispatchWorkOrderApi } from "../../../api/workOrderApi/schedulerApi/Scheduler";
import { LoaderContext, LoaderContextType } from "../../../layouts/appSidebar";
import moment from "moment";

interface DispatchModalProps {
  open: boolean;
  handleClose: () => void;
  ShiftDetails: ShiftsCardType[];
  selectedSiteId: number;
  selectedSubgroupId: number;
  activeDate: Date;
  handleDispatchCallBack: () => void;
}

const DispatchModal: FC<DispatchModalProps> = ({
  open,
  handleClose,
  ShiftDetails,
  selectedSiteId,
  selectedSubgroupId,
  activeDate,
  handleDispatchCallBack,
}) => {
  const [readyToDispatchCards, setReadyToDispatchCards] =
    useState<ShiftsCardType[]>(ShiftDetails);
  const [selectedIndices, setSelectedIndices] = useState<number[]>([]);
  const [selectedDispatchCards, setSelectedDispatchCards] = useState<
    ShiftsCardType[]
  >([]);
  const [isSkipWarning, setIsSkipWarning] = useState<boolean>(false);
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  const [isFirstTimeDispatch, setIsFirstTimeDispatch] = useState<number>(0);
  const [includeWarningAndDispatch, setIncludeWarningAndDispatch] =
    useState<number>(0);
  const [isReadyToDispatchButton, setIsReadyToDispatchBtutton] =
    useState<boolean>(false);

  const [isWarningToDispatch, setIsWarningToDispacth] =
    useState<boolean>(false);

  useEffect(() => {
    setReadyToDispatchCards(ShiftDetails);

    setSelectedIndices(
      Array.from({ length: ShiftDetails.length }, (_, i) =>
        ShiftDetails[i].cards.map((card) => card.cardId)
      ).flat()
    );

    const hasSuccessStatus = ShiftDetails.some((shift) =>
      shift.cards.some((card) => card.statusCode === "SUCCESS")
    );

    const hasWarningStatus = ShiftDetails.some((shift) =>
      shift.cards.some((card) => card.statusCode === "WARNING")
    );

    setIsReadyToDispatchBtutton(hasSuccessStatus);
    setIsWarningToDispacth(hasWarningStatus);

    const selectedShifts = ShiftDetails.filter(
      (shift) => shift.cards.length > 0
    );

    setSelectedDispatchCards(selectedShifts);
    setReadyToDispatchCards(ShiftDetails);
  }, [ShiftDetails]);

  const readyToDispatchApi = () => {
    toggleLoader(true);
    let obj = {
      dispatchDate: moment(activeDate).format("YYYY-MM-DD"),
      isFirstTimeDispatch: isFirstTimeDispatch + 1,
      includeWarningAndDispatch: includeWarningAndDispatch,
      shifts: selectedDispatchCards,
    };
    dispatchWorkOrderApi(selectedSiteId, selectedSubgroupId, obj)
      .then((response) => {
        setIsFirstTimeDispatch(response.isFirstTimeDispatch);

        // setSelectedDispatchCards(response.shifts);
        setReadyToDispatchCards(response.shifts);
        handleDispatchCallBack();
        setIsSkipWarning(false);
        toggleLoader(false);
      })
      .catch(() => {
        toggleLoader(false);
      });
  };

  const handleCheckboxClick = (
    index: number,
    shift: ShiftsCardType,
    card: ShiftCardTypes,
    e: boolean
  ) => {
    if (selectedIndices.includes(index)) {
      setSelectedIndices(selectedIndices.filter((i) => i !== index));
    } else {
      setSelectedIndices([...selectedIndices, index]);
    }

    setSelectedDispatchCards((prevSelectedDispatchCards) => {
      const selectedCardsForShift = prevSelectedDispatchCards.find(
        (selectedShift) => selectedShift.shiftName === shift.shiftName
      );

      if (selectedCardsForShift) {
        let updatedCards: ShiftCardTypes[];
        if (selectedCardsForShift.cards.some((c) => c.cardId === card.cardId)) {
          if (e) {
            updatedCards = selectedCardsForShift.cards.filter(
              (c) => c.cardId === card.cardId
            );
          } else {
            updatedCards = selectedCardsForShift.cards.filter(
              (c) => c.cardId !== card.cardId
            );
          }
        } else {
          updatedCards = [...selectedCardsForShift.cards, card];
        }

        return prevSelectedDispatchCards.map((selectedShift) =>
          selectedShift.shiftName === shift.shiftName
            ? { ...selectedShift, cards: updatedCards }
            : selectedShift
        );
      } else {
        const newShift = { ...shift, cards: [card] };
        return [...prevSelectedDispatchCards, newShift];
      }
    });
  };

  const handleDispatch = () => {
    readyToDispatchApi();
    handleClose();
  };

  const roundOffTime = (time: string): string => {
    // Parse the time string
    const [hours, minutes, seconds] = time.split(":").map(Number);

    // Create a Date object with the parsed time
    const date = new Date();
    date.setHours(hours, minutes, seconds, 0);

    // Determine if we should round up or down
    if (minutes >= 30) {
      date.setHours(date.getHours() + 1);
    }

    // Get the rounded hours
    const roundedHours = date.getHours();

    return `${roundedHours.toString().padStart(2, "0")}:00:00`;
  };

  return (
    <Dialog open={open} fullWidth maxWidth={"lg"}>
      <DialogTitle sx={{ backgroundColor: "#1D2125" }}></DialogTitle>
      <DialogContent sx={{ backgroundColor: "#1D2125" }}>
        <Box component="main">
          <Grid container sx={{ color: "#FFFFFF", fontSize: "12px" }}>
            <Grid item xs={12}>
              <TableContainer sx={{ maxHeight: 480 }} className="tableStyles">
                <Table
                  sx={{
                    color: "#FFFFFF",
                    fontSize: "12px",
                    width: "100%",
                    minHeight: "100px",
                  }}
                  stickyHeader
                  aria-label="sticky table"
                >
                  {readyToDispatchCards &&
                    readyToDispatchCards.length > 0 &&
                    readyToDispatchCards?.map((shift, shiftIndex) => (
                      <React.Fragment key={shiftIndex}>
                        {shift.shiftName && (
                          <TableBody>
                            <TableCell
                              style={{ borderBottom: "none" }}
                            ></TableCell>
                            <TableCell className="tableCellCss-withoutBackground">
                              <Box className="horizontalTextLine flex__">
                                <MediumTypography
                                  label={`SHIFT ${shift.shiftName[0]}`}
                                  className="mr-sm"
                                />
                                <ButtonComponent
                                  label={`${roundOffTime(
                                    shift.shiftStartTime
                                  ).substring(0, 5)} - ${roundOffTime(
                                    shift.shiftEndTime
                                  ).substring(0, 5)}`}
                                  type="button"
                                  className="mr-sm"
                                  variantType="outlined"
                                  sxProps={{
                                    width: "100px",
                                    height: "20px",
                                    borderRadius: "36px",
                                    padding: "4px",
                                    backgroundColor: "rgba(90, 96, 127, 1)",
                                    color: "rgba(255, 255, 255, 1)",
                                    minWidth: "0",
                                    border: "none",
                                  }}
                                />
                                <ButtonComponent
                                  label={shift.cardBlockCount.toString()}
                                  type="button"
                                  variantType="outlined"
                                  sxProps={{
                                    width: "27px",
                                    height: "20px",
                                    borderRadius: "36px",
                                    padding: "4px",
                                    backgroundColor: "rgba(90, 96, 127, 1)",
                                    color: "rgba(255, 255, 255, 1)",
                                    minWidth: "0",
                                    border: "none",
                                  }}
                                />
                              </Box>
                            </TableCell>
                            <TableCell
                              style={{ borderBottom: "none" }}
                            ></TableCell>
                          </TableBody>
                        )}
                        {shift.shiftName && (
                          <TableBody>
                            {shift.cards.map((item, index) => (
                              <TableRow
                                key={index}
                                sx={{
                                  backgroundColor:
                                    item.sequenceCount &&
                                    item.sequenceCount % 2 === 0
                                      ? "#161A1D"
                                      : "#1F272C",
                                }}
                              >
                                <TableCell className="tableCellCss-withoutBackground">
                                  <Box
                                    className="flex__"
                                    alignItems={"center"}
                                    sx={{
                                      textAlign: "center",
                                      color: "rgba(255, 255, 255, 1)",
                                    }}
                                  >
                                    <CheckBox
                                      value={selectedIndices.includes(
                                        item.cardId
                                      )}
                                      onCheckBoxClick={(e) =>
                                        handleCheckboxClick(
                                          item.cardId,
                                          shift,
                                          item,
                                          e
                                        )
                                      }
                                    />
                                    {item.sequenceCount}
                                  </Box>
                                  <Box
                                    sx={{
                                      textAlign: "center",
                                      position: "relative",
                                      right: "14px",
                                    }}
                                  ></Box>
                                </TableCell>
                                <TableCell style={{ borderBottom: "none" }}>
                                  <CardComponent
                                    cardDataArray={item}
                                    cardShiftStartTime={
                                      shift.shiftStartTime
                                        ? shift.shiftStartTime
                                        : ""
                                    }
                                    cardShiftName={shift.shiftName[0]}
                                    cardShiftEndTime={
                                      shift.shiftEndTime
                                        ? shift.shiftEndTime
                                        : ""
                                    }
                                    backGroundColor={
                                      item.sequenceCount &&
                                      item.sequenceCount % 2 === 0
                                        ? "#161A1D"
                                        : "#1F272C"
                                    }
                                    boxShadow={
                                      item.sequenceCount &&
                                      item.sequenceCount % 2 === 0
                                        ? "none"
                                        : "0 4px 8px rgba(0, 0, 0, 0.1)"
                                    }
                                  />
                                </TableCell>
                                <TableCell
                                  style={{
                                    padding: "15px 10px",

                                    marginTop: "16px",
                                    borderBottom: "none",
                                  }}
                                ></TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        )}
                      </React.Fragment>
                    ))}
                </Table>
              </TableContainer>
              <Box>
                <Grid
                  className="flex__ justifyContent-FlexEnd pt-md pb-lg "
                  container
                  direction="row"
                  alignItems="right"
                >
                  <Grid item>
                    <Box className="flex__">
                      <CheckBox
                        value={isSkipWarning}
                        style={{ marginLeft: "20px", padding: "0px" }}
                        onCheckBoxClick={() => {
                          setIsSkipWarning(!isSkipWarning);
                          if (isWarningToDispatch) {
                            setIsReadyToDispatchBtutton(
                              !isReadyToDispatchButton
                            );
                          }
                          setIncludeWarningAndDispatch(
                            includeWarningAndDispatch + 1
                          );
                        }}
                      />
                      <ButtonComponent
                        sxProps={{
                          color: "#B6C2CF !important",
                          "& :hover": { backgroundColor: "#1F272C" },
                        }}
                        className="btn-primary btn-cancel mr-md"
                        variantType="outlined"
                        defaultLabelId="Include Warning And Dispatch"
                        labelId="WarningAndDispatchBtnText"
                      />
                    </Box>
                  </Grid>
                  <Grid item>
                    <ButtonComponent
                      className={`btn-primary ${
                        !isReadyToDispatchButton ? "btn-disabled" : "btn-submit"
                      }  mr-md`}
                      sxProps={{
                        cursor: !isReadyToDispatchButton
                          ? "not-allowed"
                          : "pointer",

                        "&.Mui-disabled": {
                          color: "#ffffff",
                          background: "#333b43",
                          border: "1px solid #333b43",
                          opacity: "0.85",
                        },
                      }}
                      variantType="contained"
                      defaultLabelId="Dispatch"
                      labelId="DispatchBtnText"
                      disabled={!isReadyToDispatchButton}
                      onClick={() => {
                        handleDispatch();
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <ButtonComponent
                      sxProps={{ color: "#B6C2CF !important" }}
                      className="btn-primary btn-cancel mr-md"
                      variantType="contained"
                      defaultLabelId="Cancel"
                      labelId="CancelBtnText"
                      onClick={() => {
                        handleClose();
                        setIsSkipWarning(false);
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default DispatchModal;
