import React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useIntl } from "react-intl";
import { SxProps } from "@mui/system";
import { OptionType } from "../../utils/type";
import { ReactComponent as Language } from "../../assets/images/LanguageIcon.svg";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 10;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      scroll: "hidden",
      maxWidth: "min-content",
      backgroundColor: "#1D2125",
      color: "rgba(255, 255, 255, 1)",
    },
  },
};
interface DropdownComponentProps {
  name?: string;
  value?: string;
  handleChange?: (value: string) => void;
  names: OptionType[];
  menuwidth?: string;
  defaultValue?: string;
  disabled?: boolean;
  labelid?: string;
  defaultlabelid?: string;
  sxProps?: SxProps;
  className?: string;
  Required?: boolean;
  placeholderOnfloatBackgroundColor?: string;
  minWidth?: string;
  textColor?: string;
  iconRequired?: boolean;
}

export default function DropdownComponent(props: DropdownComponentProps) {
  const rctl = useIntl();
  const {
    name,
    value,
    handleChange,
    names,
    defaultValue,
    disabled,
    labelid,
    defaultlabelid,
    sxProps,
    Required,
    className,
    placeholderOnfloatBackgroundColor,
    minWidth,
    textColor,
    iconRequired,
  } = props;
  const selectStyles: SxProps = {
    fontFamily: "Inter",
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(166, 197, 226, 0.16)",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: !disabled ? "rgba(166, 197, 226, 0.16)" : "inherit",
    },
    "& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled":
      {
        WebkitTextFillColor: "#8c9bab",
      },
    height: "32px",
    fontSize: "14px",
    backgroundColor: "#22272B",
    minWidth: minWidth ? minWidth : "200px",
    ...sxProps,
  };
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    if (disabled) {
      return;
    }
    setOpen(true);
  };
  return (
    <FormControl
      className="inputSelectFormLabel"
      sx={{
        left: "0px",
        m: 0,
        borderRadius: "4px",
        border: "1px solid rgba(166, 197, 226, 0.16)",
        "&.inputSelectFormLabel > label.MuiFormLabel-filled": {
          backgroundColor: placeholderOnfloatBackgroundColor
            ? placeholderOnfloatBackgroundColor
            : "red",
          color: textColor ? textColor : "#FFFFFF",
        },
        "& .MuiOutlinedInput-root": {
          color: textColor ? textColor : "#FFFFFF",
        },
        "&.inputSelectFormLabel > label.Mui-focused": {
          backgroundColor: placeholderOnfloatBackgroundColor
            ? placeholderOnfloatBackgroundColor
            : "red",
          color: textColor ? textColor : "#FFFFFF",
        },
      }}
    >
      <Select
        className={className}
        MenuProps={MenuProps}
        name={name}
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        value={value}
        disabled={disabled}
        defaultValue={defaultValue}
        IconComponent={() => null}
        displayEmpty
        renderValue={(selectedValue) => {
          if (!selectedValue) {
            // If no value is selected, display the placeholder
            return labelid
              ? rctl.formatMessage({ id: labelid })
              : defaultlabelid;
          }
          // Find the label corresponding to the selected value
          const selectedOption = names.find(
            (option) => option.id === selectedValue
          );
          return selectedOption ? (
            <span style={{ display: "flex", alignItems: "center" }}>
              {iconRequired && (
                <span style={{ marginRight: 8 }}>{<Language />}</span>
              )}
              {selectedOption.label}
            </span>
          ) : (
            ""
          );
        }}
        onChange={(event: SelectChangeEvent) => {
          if (handleChange !== undefined) {
            handleChange(event.target.value);
          }
        }}
        required={Required}
        sx={selectStyles}
        input={
          <OutlinedInput
            disabled={disabled}
            id="selectcomponentid"
            required={Required}
            endAdornment={
              <KeyboardArrowDownIcon
                onClick={handleOpen}
                style={{
                  cursor: disabled ? "not-allowed" : "pointer",
                  color: !disabled ? "#FFFFFF" : "rgba(166, 197, 226, 0.16)",
                }}
              />
            }
          />
        }
      >
        {names.map((item: OptionType) => (
          <MenuItem
            key={item.id}
            value={item.id}
            defaultValue={item.id}
            sx={{
              maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
              fontFamily: "Inter",
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "17px",
              textOverflow: "ellipsis",

              ...sxProps,
            }}
          >
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
