import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import { FC, useContext, useEffect, useRef, useState } from "react";
import MediumTypography from "../../../components/formlib/MediumTypography";
import ButtonComponent from "../../../components/formlib/ButtonComponent";
import CloseIcon from "@mui/icons-material/Close";
import "../styles.css";
import TextInput from "../../../components/formlib/TextInput";

import { LoaderContext, LoaderContextType } from "../../../layouts/appSidebar";

import ErrorModal from "../../../components/formlib/modal/ErrorModal";

import { Formik, FormikProps } from "formik";
import * as Yup from "yup";

import FormikErrorComponent from "../../../components/formlib/FormikErrorComponent";
import {
  fetchDataApi,
  saveDataApi,
} from "../../../api/MasterDataManagement/Common";
import { AddAssetCategoryData } from "../types/assetCategoryTypes";
import { handleError } from "../../../utils/commonFunctions";

const apiUri = "assetCategory";

interface ModalProps {
  open: boolean;
  handleClose: () => void;
  id?: number;
  getDataCallback: () => void;
  languageCode: string;
}

const initialData: AddAssetCategoryData = {
  id: undefined,
  name: "",
  code: "",
  description: "",
  level: 0,
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required("validation.fieldRequired"),
  code: Yup.string()
    .matches(/^[^\s]*$/, "validation.noSpaces")
    .required("validation.fieldRequired"),
  description: Yup.string()
    .max(500, "validation.maxLength500")
    .required("validation.fieldRequired"),
  level: Yup.number()
    .min(1, "validation.fieldRequired")
    .required("validation.fieldRequired"),
});

const muiColumnProps = {
  xs: 12,
};

const AddEditAssetCategoryModal: FC<ModalProps> = ({
  open,
  handleClose,
  id,
  getDataCallback,
  languageCode,
}) => {
  const [apiData, setApiData] = useState<AddAssetCategoryData>(initialData);
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [errorDesc, setErrorDesc] = useState<string>("");
  const formikRef = useRef<FormikProps<AddAssetCategoryData>>(null);
  const [isVersionRequired, setIsVersionRequired] = useState<boolean>(false);

  useEffect(() => {
    if (open && id) {
      getAddAssetCategoryData(id);
    }
  }, [id, open]);

  const saveAssetCategoryData = (params: AddAssetCategoryData) => {
    toggleLoader(true);
    saveDataApi<AddAssetCategoryData>(
      apiUri,
      params,
      isVersionRequired,
      params.id
    )
      .then((res) => {
        formikRef.current?.resetForm({ values: initialData });
        handleClose();
        getDataCallback();
        toggleLoader(false);
      })
      .catch((err) => {
        handleError(err, setErrorDesc);
        setOpenErrorModal(true);
        toggleLoader(false);
      });
  };

  const getAddAssetCategoryData = (catId: number | undefined) => {
    toggleLoader(true);

    fetchDataApi<AddAssetCategoryData>(apiUri, catId, languageCode)
      .then(async (res) => {
        const updatedResponse: AddAssetCategoryData = {
          ...res,
          id: catId,
        };

        setApiData(updatedResponse);
        toggleLoader(false);
      })
      .catch((err) => {
        handleError(err, setErrorDesc);
        setOpenErrorModal(true);
        toggleLoader(false);
      });
  };

  return (
    <>
      {openErrorModal && (
        <ErrorModal
          descriptionText={errorDesc}
          open={openErrorModal}
          handleClose={() => {
            setOpenErrorModal(false);
            handleClose();
          }}
          onPositiveClick={() => {
            setOpenErrorModal(false);
            handleClose();
          }}
        />
      )}

      <Formik
        initialValues={apiData}
        enableReinitialize
        validateOnChange
        innerRef={formikRef}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          saveAssetCategoryData(values);
        }}
      >
        {({ values, setFieldValue, handleSubmit, errors, touched, dirty }) => {
          return (
            <Dialog
              className="account-dialog main-wrapper-box"
              open={open}
              fullWidth
              maxWidth={"sm"}
            >
              <DialogTitle className="padding_20">
                <MediumTypography
                  labelId={
                    id ? "assetCategories.update" : "assetCategories.add"
                  }
                  defaultLabel="Asset Category Details"
                  textColor="#FFF"
                  fontSize="22px"
                  fontWeight={700}
                />
                <MediumTypography
                  labelId={"common.basicDetails"}
                  defaultLabel="Enter basic details"
                  textColor="rgba(159, 173, 188, 1)"
                  fontSize="14px"
                  fontWeight={400}
                  className="mt-xs"
                />
              </DialogTitle>

              <IconButton
                aria-label="close"
                onClick={() => {
                  formikRef.current?.resetForm({ values: initialData });
                  handleClose();
                }}
                className="closeIcon"
              >
                <CloseIcon />
              </IconButton>

              <DialogContent dividers className="padding_20">
                <Grid container spacing={2}>
                  <Grid item {...muiColumnProps}>
                    <MediumTypography
                      className="input-label"
                      labelId="AssetCategoryText"
                      defaultLabel="Asset Category"
                    />
                    <TextInput
                      className="text-input-field"
                      type="text"
                      variant="outlined"
                      inputProps={{
                        readOnly: false,
                      }}
                      labelId="common.placeHolderText"
                      defaultLabelId="--- type here ---"
                      Value={values.name}
                      handlechange={(value: string) => {
                        setFieldValue("name", value);
                      }}
                    />
                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="name"
                    />
                  </Grid>
                  <Grid item {...muiColumnProps}>
                    <MediumTypography
                      className="input-label"
                      labelId="accounts.code"
                      defaultLabel="Code"
                    />
                    <TextInput
                      className="text-input-field"
                      type="text"
                      variant="outlined"
                      inputProps={{
                        readOnly: false,
                      }}
                      labelId="common.placeHolderText"
                      defaultLabelId="--- type here ---"
                      Value={values.code}
                      handlechange={(value: string) => {
                        setFieldValue("code", value);
                      }}
                    />
                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="code"
                    />
                  </Grid>
                  <Grid item {...muiColumnProps}>
                    <MediumTypography
                      className="input-label"
                      labelId="common.description"
                      defaultLabel="Description"
                    />
                    <TextInput
                      className="text-input-field"
                      type="text"
                      variant="outlined"
                      inputProps={{
                        readOnly: false,
                      }}
                      labelId="common.placeHolderText"
                      defaultLabelId="--- type here ---"
                      Value={values.description}
                      handlechange={(value: string) => {
                        setFieldValue("description", value);
                      }}
                    />
                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="description"
                    />
                  </Grid>
                  <Grid item {...muiColumnProps}>
                    <MediumTypography
                      className="input-label"
                      labelId="levelText"
                      defaultLabel="Level"
                    />
                    <TextInput
                      className="text-input-field"
                      type="text"
                      variant="outlined"
                      inputProps={{
                        readOnly: false,
                      }}
                      labelId="common.placeHolderText"
                      defaultLabelId="--- type here ---"
                      Value={
                        values.level !== null && values.level !== 0
                          ? String(values.level)
                          : ""
                      }
                      handlechange={(value: string) => {
                        const formattedValue = value.replace(/[^0-9]/g, "");
                        setFieldValue(
                          "level",
                          formattedValue ? parseInt(formattedValue) : 0
                        );
                      }}
                    />
                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="level"
                    />
                  </Grid>
                </Grid>
              </DialogContent>

              <DialogActions className="dialogActions">
                <Box>
                  <Grid
                    className="flex__ justifyContent-FlexEnd"
                    container
                    direction="row"
                    alignItems="right"
                  >
                    <Grid item>
                      <ButtonComponent
                        sxProps={{ color: "#B6C2CF !important" }}
                        className="btn-primary btn-cancel mr-md"
                        variantType="outlined"
                        defaultLabelId={"Reset"}
                        labelId={"common.reset"}
                        onClick={() => formikRef.current?.resetForm()}
                        disabled={!dirty}
                      />
                    </Grid>
                    <Grid item>
                      <ButtonComponent
                        className="btn-primary btn-submit"
                        variantType="contained"
                        defaultLabelId={"Save"}
                        labelId={id ? "btn.update" : "btn.Add"}
                        onClick={() => {
                          handleSubmit();
                          setIsVersionRequired(false);
                        }}
                      />
                    </Grid>
                    {id && (
                      <Grid item>
                        <ButtonComponent
                          className="btn-primary btn-submit ml-md"
                          variantType="contained"
                          defaultLabelId={"Save"}
                          labelId={id ? "btn.updateWithVersion" : undefined}
                          onClick={() => {
                            handleSubmit();
                            setIsVersionRequired(true);
                          }}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Box>
              </DialogActions>
            </Dialog>
          );
        }}
      </Formik>
    </>
  );
};

export default AddEditAssetCategoryModal;
