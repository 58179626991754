import React, { useContext, useEffect, useState } from "react";
import { Button, ButtonGroup, Grid } from "@mui/material";
import ArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import ArrowRight from "@mui/icons-material/KeyboardArrowRight";
import "./DayPagination.css";
import { dateContext } from "../../pages/scheduler/SchedulerDashboard";

interface DayPaginationProps {}

const DayPagination: React.FC<DayPaginationProps> = () => {
  const context = useContext(dateContext);

  // State to store the current active date
  const [activeDate, setActiveDate] = useState<Date>(context.activeDate);

  // State to store the current date range
  const [dateRange, setDateRange] = useState<Date[]>(context.activeDateRange);

  // Store in context whenever activeDate changes
  useEffect(() => {
    context.onDateChange(activeDate);
  }, [activeDate]);

  // Store in context whenever dateRange changes
  useEffect(() => {
    context.onDateRangeChange(dateRange);
  }, [dateRange]);

  // Function to handle left arrow click
  const handlePrevDay = () => {
    if (dateRange[0].toDateString() === activeDate.toDateString()) {
      setActiveDate((prevDate) => new Date(prevDate.getTime() - 86400000));
      setDateRange(() => [
        new Date(activeDate.getTime() - 7 * 86400000),
        new Date(activeDate.getTime() - 86400000),
      ]);
    } else {
      setActiveDate((prevDate) => new Date(prevDate.getTime() - 86400000));
    }
  };

  // Function to handle right arrow click
  const handleNextDay = () => {
    if (dateRange[1].toDateString() === activeDate.toDateString()) {
      setActiveDate((prevDate) => new Date(prevDate.getTime() + 86400000));
      setDateRange(() => [
        new Date(activeDate.getTime() + 86400000),
        new Date(activeDate.getTime() + 7 * 86400000),
      ]);
    } else {
      setActiveDate((prevDate) => new Date(prevDate.getTime() + 86400000));
    }
  };

  // Handler to change the active date to the clicked date
  const changeActiveDate = (newDate: Date) => {
    if(newDate.toDateString() === activeDate.toDateString()) return;
    setActiveDate(newDate);
  };

  // Helper function to get the dates in the range
  function getDatesInRange(startDate: Date, endDate: Date) {
    let datesArray = [];
    let currentDate = new Date(startDate);

    while (currentDate <= endDate) {
      datesArray.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return datesArray;
  }

  // Helper function to format the date
  function formatDate(date: Date) {
    return date.toLocaleDateString("en-US", { month: "short", day: "numeric" });
  }

  return (
    <Grid id="dayPaination" container justifyContent="center">
      <Grid item xs={12}>
        <ButtonGroup fullWidth variant="contained">
          <Button onClick={handlePrevDay} className="arrow-btn">
            <ArrowLeft />
          </Button>

          {getDatesInRange(dateRange[0], dateRange[1]).map((date, index) => (
            <Button
              key={index}
              className={
                activeDate.toDateString() === date.toDateString()
                  ? "active"
                  : ""
              }
              onClick={() => changeActiveDate(date)}
            >
              {formatDate(date)}
            </Button>
          ))}

          <Button onClick={handleNextDay} className="arrow-btn">
            <ArrowRight />
          </Button>
        </ButtonGroup>
      </Grid>
    </Grid>
  );
};

export default DayPagination;
