import { ViewShiftsType, shiftDetails } from "../../pages/manageAbsence/types";
import { ShiftMonthlyDetails } from "../../pages/shifts/DayShiftDetails";
import { ApiResponseHandler } from "../ApiResponseHandler";
import WorkOrderConfig from "./../WorkOrderConfig";

export const getShiftDetails = async (
  subGroupId: number,
  selectedDate: string
): Promise<ViewShiftsType> => {
  return ApiResponseHandler(
    await WorkOrderConfig.getInstance().get(
      `shift/${subGroupId}/fetch?selectedDate=${selectedDate}`
    )
  );
};

export const saveShiftPlan = async (
  subGroupId: number,
  shiftPlanType: string,
  body: ViewShiftsType
) => {
  return ApiResponseHandler(
    await WorkOrderConfig.getInstance().post(
      `shift/save?subGroupId=${subGroupId}&shiftPlanType=${shiftPlanType}`,
      body
    )
  );
};

export const updateShiftTimings = async (
  subGroupId: number,
  selectedDate: string,
  body: shiftDetails[]
) => {
  return ApiResponseHandler(
    await WorkOrderConfig.getInstance().put(
      `shift/timings/update?subGroupId=${subGroupId}&selectedDate=${selectedDate}`,
      body
    )
  );
};

export const getshiftPlannerDetails = async (
  subGroupId: number,
  monthDate: string
): Promise<ShiftMonthlyDetails[]> => {
  return ApiResponseHandler(
    await WorkOrderConfig.getInstance().get(
      `shift/monthly/planning?subGroupId=${subGroupId}&monthDate=${monthDate}`
    )
  );
};
