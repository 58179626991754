import React, { FC, useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { TimePicker as MuiTimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs, { Dayjs } from "dayjs";
import { useIntl } from "react-intl";
import { Box } from "@mui/material";

interface TimePickerProps {
  name?: string;
  labelid: string;
  defaultlabelid: string;
  value: Dayjs | null;
  handlechange?: (value: Dayjs | null) => void;
  handleClose?: () => void;
  width?: string;
  textWidth?: string;
}

const TimePicker: FC<TimePickerProps> = ({
  name,
  labelid,
  defaultlabelid,
  value,
  handlechange,
  handleClose,
  width,
  textWidth,
}) => {
  const rctl = useIntl();
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const internalHandleDateChange = (date: Dayjs | null) => {
    if (handlechange && date != null) {
      handlechange(date);
    }
  };

  const closeDatePicker = () => {
    setOpen(false);
    if (handleClose) {
      handleClose();
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box
        className={"flex__"}
        sx={{
          cursor: "pointer",
          borderRadius: "4px",
          border: "1px solid rgba(166, 197, 226, 0.16)",
          backgroundColor: "#22272B",
          width: width ? width : "max-content",
        }}
      >
        {/* <DemoContainer components={["TimePicker"]}> */}
        <MuiTimePicker
          className={`dateTimePickerStyle datepicker notDisabledTextColor`}
          slotProps={{
            field: {
              clearable: false,
            },
            textField: {
              placeholder: value
                ? undefined
                : rctl.formatMessage({
                    id: labelid,
                    defaultMessage: defaultlabelid,
                  }),
              onClick: () => setOpen(true),
              sx: {
                width: textWidth ? textWidth : "200px",
                cursor: "pointer",
              },
            },
            popper: {
              sx: {
                ".MuiPaper-root": {
                  backgroundColor: "#1D2125",
                  color: "#ffff",
                },
                ".MuiTypography-root.MuiTypography-caption.MuiDayCalendar-weekDayLabel":
                  {
                    color: "#ffff",
                  },
                ".MuiButtonBase-root.MuiIconButton-root": {
                  color: "#ffff",
                },
              },
            },
          }}
          value={value}
          onChange={(date: Dayjs | null | undefined) =>
            handlechange && date !== undefined && handlechange(date)
          }
          open={open}
          onOpen={() => setOpen(true)}
          format={"HH:mm"}
          ampm={false}
          onClose={closeDatePicker}
          slots={{
            openPickerIcon: () => null, // Hide the icon
          }}
          sx={{
            "& fieldset": { border: "none" },
            ".& MuiPaper-root-MuiPickersPopper-paper": {
              backgroundColor: "1D2125",
              height: "40px",
            },
            height: "40px",
          }}
        />
        {/* </DemoContainer> */}
      </Box>
    </LocalizationProvider>
  );
};

export default TimePicker;
