import {
  AdditionalDetails,
  BreakDownDetails,
  CorrectiveMaintenanceDetails,
  TaskCheckList,
  materialCodesData,
} from "../../pages/workOrderDetailScreens/correctiveMaintenance/types";
import { ApiResponseHandler } from "../ApiResponseHandler";
import WorkOrderConfig from "../WorkOrderConfig";

export const getCMDetailsApi = async (
  subGroupId: number,
  date: string,
  id: number
): Promise<CorrectiveMaintenanceDetails> => {
  return ApiResponseHandler(
    await WorkOrderConfig.getInstance().get(
      `workorder/cm/fetch/${id}/woCmDetails?subGroupId=${subGroupId}&selectedDate=${date}`
    )
  );
};

export const saveCMDetailsApi = async (
  id: number,
  body: CorrectiveMaintenanceDetail
): Promise<CorrectiveMaintenanceDetails> => {
  return ApiResponseHandler(
    await WorkOrderConfig.getInstance().post(
      `workorder/cm/save/${id}/woDetails`,
      body
    )
  );
};

export interface CorrectiveMaintenanceDetail {
  assetGroupId: number;
  assetCategoryId: number;
  selectedAssets: number[];
  estimatedEffort: number;
  actualEffort: number;
  taskCheckLists: TaskCheckList[];
  breakdownDetailBean: BreakDownDetails;
  additionalInfoBean: AdditionalDetails;
  materialRequest: materialCodesData;
}
