import {
  Box,
  Popover,
  SxProps,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import MediumTypography from "./MediumTypography";
import SearchBox from "./SearchBox";
import { useEffect, useState } from "react";
import { visuallyHidden } from "@mui/utils";
import { HeadCell } from "../../pages/MasterDataManagement/types/accountTypes";
import { ReactComponent as Filter } from "../../assets/images/filter.svg";
import CheckBox from "./CheckBox";
import { ReactComponent as Add } from "../../assets/images/add.svg";
import { useLocation } from "react-router-dom";

interface TableHeaderProps {
  rowCount?: number;
  headerNames: HeadCell[];
  labelSxProp?: SxProps;
  className?: string;
  onSearchApply?: (columnKey: string, searchValue: string) => void;
  onCancelSearch?: (columnKey: string) => void;
  onSortApply?: (columnKey: string, direction: string) => void;
  searchableKeys: string[];
  stickyItems: string;
  hideSerialNo?: boolean;
  hideMoreButton?: boolean;
  widthObject?: {
    [key: string]: string;
  };
  dynamicValue?: number;
  selectAllToggle?: (checked: boolean) => void;
  isSelectAll?: boolean;
  showButtonAdd?: boolean;
  onAddClick?: () => void;
  languageCode?: string;
  cellLabelExist?: boolean;
}
const CustomTableHeader = (props: TableHeaderProps) => {
  const {
    headerNames,
    labelSxProp,
    className,
    onSearchApply,
    onSortApply,
    onCancelSearch,
    searchableKeys,
    stickyItems,
    hideSerialNo = false,
    hideMoreButton = false,
    widthObject = {},
    dynamicValue = 0,
    selectAllToggle,
    isSelectAll = false,
    showButtonAdd,
    onAddClick,
    languageCode,
    cellLabelExist,
  } = props;

  const [anchorE1Search, setAnchorE1Search] = useState<null | HTMLElement>(
    null
  );

  const [searchValue, setSearchValue] = useState<{ [key: string]: string }>({});
  const [activeCellKey, setActiveCellKey] = useState<string>("");
  const [sortDirection, setSortDirection] = useState<{
    [key: string]: "asc" | "desc" | undefined;
  }>({});
  const handleHeaderClick = (columnKey: string) => {
    if (onSortApply) {
      const currentDirection = sortDirection[columnKey];
      let newDirection: "asc" | "desc" | undefined;

      if (!currentDirection) {
        newDirection = "desc"; // First click should sort in descending order
      } else if (currentDirection === "desc") {
        newDirection = "asc"; // Second click should sort in ascending order
      } else if (currentDirection === "asc") {
        newDirection = undefined; // Third click should clear the sorting
      }

      setSortDirection({
        [columnKey]: newDirection,
      });

      onSortApply(newDirection ? columnKey : "", newDirection ?? ""); // Pass both columnKey and direction
    }
  };

  const handleSearchIconClick = (
    event: React.MouseEvent<HTMLElement>,
    columnKey: string
  ) => {
    setAnchorE1Search(event.currentTarget);
    setSearchValue((prev) => ({ ...prev, columnKey }));
    setActiveCellKey(columnKey);
  };

  const handleSearchClose = () => {
    setAnchorE1Search(null);
    setActiveCellKey("");
  };

  const handleSearchChange = (value: string, columnKey: string) => {
    if (onSearchApply) {
      onSearchApply(columnKey, value); // Trigger parent callback
    }
    setSearchValue((prev) => ({ ...prev, [columnKey]: value }));
  };

  const handleCancel = (key: string) => {
    setSearchValue((prevValues) => ({
      ...prevValues,
      [key]: "",
    }));

    if (onCancelSearch) {
      onCancelSearch(key);
    }
    handleSearchClose();
  };
  const location = useLocation();

  useEffect(() => {
    setSearchValue({});
  }, [location?.state, languageCode]);

  const renderHeaderContent = (cell: HeadCell) => {
    if (selectAllToggle) {
      return (
        <Box display={"flex"} alignItems={"center"}>
          <CheckBox
            value={isSelectAll}
            style={{
              marginRight: "10px",
            }}
            onCheckBoxClick={(checked) => {
              selectAllToggle(checked);
            }}
          />
          <MediumTypography
            sxProps={{
              fontWeight: 700,
              color: "#FFFFFF",
              whiteSpace: "nowrap",
              cursor: "pointer",
              ...labelSxProp,
            }}
            label={cell.value}
            onClick={() => handleHeaderClick(cell.key)}
          />
        </Box>
      );
    } else {
      return (
        <MediumTypography
          sxProps={{
            fontWeight: 700,
            color: "#FFFFFF",
            whiteSpace: "nowrap",
            cursor: "pointer",
            ...labelSxProp,
          }}
          label={cellLabelExist ? cell.labelId : cell.value}
          onClick={() => handleHeaderClick(cell.key)}
        />
      );
    }
  };

  return (
    <TableHead className={className}>
      <TableRow>
        {showButtonAdd && (
          <TableCell
            align={"left"}
            sx={{
              verticalAlign: "top",
              backgroundColor: "#22272B",
              borderBottom: "none",
              position: "sticky",
              top: 0,
              left: 0,
              zIndex: 1,
            }}
            style={{ width: "5px" }}
          ></TableCell>
        )}
        {/* Sticky First Column */}
        {!hideSerialNo && (
          <TableCell
            align={"left"}
            sx={{
              verticalAlign: "top",
              backgroundColor: "#22272B",
              padding: "16px 16px",
              borderBottom: "none",
              position: "sticky",
              top: 0,
              left: 0,
              zIndex: 2,
            }}
            style={{ width: showButtonAdd ? "50px" : "100px" }}
          >
            <MediumTypography
              sxProps={{
                fontWeight: 700,
                color: "#FFFFFF",
                whiteSpace: "nowrap",
                ...labelSxProp,
              }}
              labelId="srNo"
              defaultLabel="Sr. No."
            />
          </TableCell>
        )}

        {headerNames.map((cell: HeadCell, index: number) => (
          <>
            {cell.key === stickyItems ? (
              <TableCell
                key={cell.key}
                align={"left"}
                sx={{
                  verticalAlign: "top",
                  backgroundColor: "#22272B",
                  padding: "16px 16px",
                  borderBottom: "none",
                  position: "sticky",
                  top: 0,
                  left: hideSerialNo ? 0 : 75,
                  zIndex: 2,
                }}
                style={{ width: widthObject[cell.key] ?? "200px" }}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  sx={{ width: widthObject[cell.key] ?? "200px" }}
                >
                  {/* {selectAllToggle ? (
                    <Box display={"flex"} alignItems={"center"}>
                      <CheckBox
                        value={isSelectAll}
                        style={{
                          marginRight: "10px",
                          // padding: "0px",
                        }}
                        onCheckBoxClick={(checked) => {
                          selectAllToggle(checked);
                        }}
                      />
                      <MediumTypography
                        sxProps={{
                          fontWeight: 700,
                          color: "#FFFFFF",
                          whiteSpace: "nowrap",
                          cursor: "pointer",
                          ...labelSxProp,
                        }}
                        label={cell.value}
                        onClick={() => handleHeaderClick(cell.key)}
                      />
                    </Box>
                  ) : (
                    <MediumTypography
                      sxProps={{
                        fontWeight: 700,
                        color: "#FFFFFF",
                        whiteSpace: "nowrap",
                        cursor: "pointer",
                        ...labelSxProp,
                      }}
                      label={cell.value}
                      onClick={() => handleHeaderClick(cell.key)}
                    />
                  )} */}
                  <>{renderHeaderContent(cell)}</>

                  <TableSortLabel
                    active={sortDirection[cell.key] !== undefined}
                    direction={sortDirection[cell.key] || "asc"} // Fallback to "asc" if undefined
                    onClick={() => handleHeaderClick(cell.key)}
                    sx={{
                      cursor: "pointer",
                      "&.MuiTableSortLabel-root.Mui-active .MuiTableSortLabel-icon":
                        {
                          opacity: 1,
                          color: "#FFFFFF",
                        },
                      "&.MuiTableSortLabel-root:hover .MuiTableSortLabel-icon":
                        {
                          opacity: 1,
                          color: "#B0B0B0",
                        },
                    }}
                  >
                    {sortDirection[cell.key] !== undefined && (
                      <Box component="span" sx={visuallyHidden}>
                        {sortDirection[cell.key] === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </Box>
                    )}
                  </TableSortLabel>
                  {searchValue[cell.key] && (
                    <Filter style={{ marginLeft: "8px" }} />
                  )}
                  {cell.icon && (
                    <Box
                      ml={1}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                      onClick={(event) =>
                        handleSearchIconClick(event, cell.key)
                      }
                    >
                      {cell.icon}
                    </Box>
                  )}
                </Box>
              </TableCell>
            ) : cell.key !== "role" ? (
              <TableCell
                key={cell.key}
                align={"left"}
                sx={{
                  verticalAlign: "top",
                  backgroundColor: "#22272B",
                  padding: "16px 16px",
                  borderBottom: "none",
                  position: "sticky",
                  top: 0,
                  zIndex: 1,
                }}
                style={{ width: widthObject[cell.key] ?? "200px" }}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  sx={{ width: widthObject[cell.key] ?? "200px" }}
                >
                  <MediumTypography
                    sxProps={{
                      fontWeight: 700,
                      color: "#FFFFFF",
                      whiteSpace: "nowrap",
                      cursor: "pointer",
                      ...labelSxProp,
                    }}
                    label={
                      cell.value == "Weightage - 100%"
                        ? `Weightage - ${dynamicValue}%`
                        : cell.value
                    }
                    onClick={() => handleHeaderClick(cell.key)}
                  />

                  <TableSortLabel
                    active={sortDirection[cell.key] !== undefined}
                    direction={sortDirection[cell.key] || "asc"} // Fallback to "asc" if undefined
                    onClick={() => handleHeaderClick(cell.key)}
                    sx={{
                      cursor: "pointer",
                      "&.MuiTableSortLabel-root.Mui-active .MuiTableSortLabel-icon":
                        {
                          opacity: 1,
                          color: "#FFFFFF",
                        },
                      "&.MuiTableSortLabel-root:hover .MuiTableSortLabel-icon":
                        {
                          opacity: 1,
                          color: "#B0B0B0",
                        },
                    }}
                  >
                    {sortDirection[cell.key] !== undefined && (
                      <Box component="span" sx={visuallyHidden}>
                        {sortDirection[cell.key] === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </Box>
                    )}
                  </TableSortLabel>
                  {searchValue[cell.key] && (
                    <Filter style={{ marginLeft: "8px" }} />
                  )}

                  {cell.icon && (
                    <Box
                      ml={1}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        cursor: searchableKeys.includes(cell.key)
                          ? "pointer"
                          : "not-allowed",
                      }}
                      onClick={(event) => {
                        handleSearchIconClick(event, cell.key);
                      }}
                    >
                      {cell.icon}
                    </Box>
                  )}
                </Box>
              </TableCell>
            ) : (
              <TableCell
                key={cell.key}
                align={"left"}
                sx={{
                  verticalAlign: "top",
                  backgroundColor: "#22272B",
                  padding: "16px 16px",
                  borderBottom: "none",
                  position: "sticky",
                  top: 0,
                  zIndex: 1,
                }}
              >
                <Box display="flex" alignItems="center">
                  <MediumTypography
                    sxProps={{
                      fontWeight: 700,
                      color: "#FFFFFF",
                      whiteSpace: "nowrap",
                      cursor: "pointer",
                      ...labelSxProp,
                    }}
                    label={
                      cell.value == "Weightage - 100%"
                        ? `Weightage - ${dynamicValue}%`
                        : cell.value
                    }
                    onClick={() => handleHeaderClick(cell.key)}
                  />

                  {searchValue[cell.key] && (
                    <Filter style={{ marginLeft: "8px" }} />
                  )}

                  {cell.key == "role" && (
                    <Box
                      display="flex"
                      alignItems="center"
                      sx={{ ml: "auto", cursor: "pointer" }}
                      onClick={onAddClick}
                    >
                      <Add />
                      <MediumTypography
                        labelId="btn.Add"
                        defaultLabel="Add"
                        sxProps={{ color: "#6CDDF8" }}
                        className="ml-xs"
                      />
                    </Box>
                  )}
                </Box>
              </TableCell>
            )}
            {searchableKeys.includes(cell.key) && (
              <Popover
                open={activeCellKey === cell.key}
                anchorEl={anchorE1Search}
                onClose={handleSearchClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                style={{
                  left: 10,
                  top: 20,
                }}
              >
                <Box p={2} width="250px" sx={{ backgroundColor: "#323337" }}>
                  <SearchBox
                    labelId="defaultSearch"
                    defaultlabel="Search"
                    backgroundColor="#22272B"
                    sxProps={{
                      minWidth: "180px",
                    }}
                    value={searchValue[cell.key] || ""}
                    onChange={(value: string) =>
                      handleSearchChange(value, cell.key)
                    }
                    onCancel={() => handleCancel(cell.key)}
                    cancelRequired={true}
                  />
                </Box>
              </Popover>
            )}
          </>
        ))}

        {/* Sticky Last Column */}
        {!hideMoreButton && !showButtonAdd && (
          <TableCell
            align={"left"}
            sx={{
              verticalAlign: "top",
              backgroundColor: "#22272B",
              padding: "8px 16px",
              borderBottom: "none",
              position: "sticky",
              top: 0,
              right: 0,
              zIndex: 1,
            }}
            style={{ width: "50px" }}
          />
        )}
      </TableRow>
    </TableHead>
  );
};

export default CustomTableHeader;
