import { Box } from "@mui/material";
import "./css/Login.css";
import MediumTypography from "../../components/formlib/MediumTypography";
import TextInput from "../../components/formlib/TextInput";
import { ReactComponent as ParamLogo } from "../../assets/images/ParamLogo.svg";
import ButtonComponent from "../../components/formlib/ButtonComponent";
import { FC, useRef } from "react";
import { Formik, FormikProps } from "formik";
import { ReactComponent as ErrorIcon } from "../../assets/images/ErrorLogo.svg";
import * as Yup from "yup";
import { useError } from "./context/ApiErrorContext";

export interface ResetPasswordProps {
  reset: boolean;
  handleSubmitChangePassword: (data: ResetPasswordScreenProps) => void;
  testId?: string;
}

export interface ResetPasswordScreenProps {
  verificationCode?: string;
  password: string;
  confirmPassword: string;
}
const initialValues: ResetPasswordScreenProps = {
  verificationCode: "",
  password: "",
  confirmPassword: "",
};

const ResetPassword: FC<ResetPasswordProps> = ({
  reset,
  handleSubmitChangePassword,
  testId,
}) => {
  const formRef = useRef<FormikProps<ResetPasswordScreenProps>>(null);
  const { setError, error } = useError();
  const ValidationSchema = () => {
    return Yup.object({
      verificationCode: !reset
        ? Yup.string().required("ResetPassword.codeError")
        : Yup.string(),
      password: Yup.string()
        .min(8, "ResetPassword.passwordLength")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[0-9]).*$/,
          "ResetPassword.passwordInstructions"
        )
        .required("ResetPassword.passwordError"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), undefined], "ResetPassword.matchError")
        .nullable()
        .required("ResetPassword.confirmPasswordError"),
    });
  };
  const handleChangePassword = (values: ResetPasswordScreenProps) => {
    handleSubmitChangePassword(values);
  };

  return (
    <Box className="mainContainer login" data-testid={testId}>
      <Box sx={{ marginTop: "50px", padding: "65px" }}>
        <ParamLogo data-testid="param-logo" />
        <MediumTypography
          className="title"
          labelId={reset ? "ResetPassword.Header" : "ChangePassword.Header"}
          defaultLabel={reset ? "Reset Password" : "Change Password"}
        />
        <MediumTypography
          className="subText width_300"
          labelId={reset ? "ResetPassword.subText" : "ChangePassword.subText"}
          defaultLabel={
            reset
              ? "As your a new user please reset your password for better security."
              : "Your new password must be different from previous used passwords."
          }
        />
        <Formik
          initialValues={initialValues}
          validateOnMount={true}
          innerRef={formRef}
          enableReinitialize={true}
          onSubmit={(values) => {
            handleChangePassword(values);
          }}
          validationSchema={ValidationSchema}
        >
          {({ handleSubmit, setFieldValue, values, errors, touched }) => (
            <Box>
              <Box className="inputContainer">
                {!reset && (
                  <Box className="mb-md">
                    <MediumTypography
                      className="inputLabel"
                      labelId="ChangePassword.VerificationCode"
                      defaultLabel="Verification Code"
                    />
                    <TextInput
                      labelId="ResetPassword.verificationCodePlaceholder"
                      defaultLabelId="Enter verification code"
                      variant="outlined"
                      className="textinput"
                      fieldheight="40px"
                      name="verificationCode"
                      inputProps={{
                        maxLength: 6,
                      }}
                      onKeyPress={(
                        event: React.KeyboardEvent<HTMLDivElement>
                      ) => {
                        if (event.key === "Enter") {
                          handleSubmit();
                        }
                      }}
                      Value={values.verificationCode}
                      handlechange={(value: string) => {
                        const newValue = value
                          .trimStart()
                          .replace(/[^0-9]/g, "");
                        setError("");
                        setFieldValue("verificationCode", newValue);
                      }}
                    />
                    {errors.verificationCode && touched.verificationCode && (
                      <Box className="loginError">
                        <ErrorIcon />
                        <MediumTypography
                          labelId={errors.verificationCode}
                          defaultLabel="Verification code is required "
                          fontSize="12px"
                          textColor="#AE2E24"
                          fontWeight={400}
                        />
                      </Box>
                    )}
                  </Box>
                )}
                {error !== "" && error !== undefined && error !== null && (
                  <Box className="loginError">
                    <Box>
                      <ErrorIcon />
                    </Box>
                    <MediumTypography
                      labelId={error}
                      fontSize="12px"
                      textColor="#AE2E24"
                      fontWeight={400}
                    />
                  </Box>
                )}
                <Box className="pt-sm pb-sm">
                  <MediumTypography
                    className="inputLabel"
                    labelId="Login.password"
                    defaultLabel="Password"
                  />
                  <TextInput
                    labelId="ResetPassword.newpasswordPlaceholder"
                    defaultLabelId="Enter new password"
                    variant="outlined"
                    className="textinput"
                    fieldheight="40px"
                    name="password"
                    Value={values.password}
                    type="password"
                    handlechange={(value: string) => {
                      setFieldValue("password", value);
                    }}
                    onKeyPress={(
                      event: React.KeyboardEvent<HTMLDivElement>
                    ) => {
                      if (event.key === "Enter") {
                        handleSubmit();
                      }
                    }}
                    inputProps={{
                      maxLength: 20,
                    }}
                    onPasteText={(e) => {
                      e.preventDefault();
                    }}
                  />
                  {errors.password && touched.password && (
                    <Box className="loginError">
                      <Box>
                        <ErrorIcon />
                      </Box>
                      <MediumTypography
                        labelId={errors.password}
                        defaultLabel="Password is required"
                        fontSize="12px"
                        textColor="#AE2E24"
                        fontWeight={400}
                      />
                    </Box>
                  )}
                  {values.password.length < 8 && (
                    <MediumTypography
                      className="passwordInstructions"
                      labelId="ResetPassword.PasswordInstructions"
                      defaultLabel="Must be at least 8 characters."
                    />
                  )}
                </Box>
                <MediumTypography
                  className="inputLabel pt-xs"
                  labelId="ResetPassword.confirmPassword"
                  defaultLabel="Confirm Password"
                />
                <TextInput
                  labelId="ResetPassword.confirmPasswordPlaceholder"
                  defaultLabelId="Confirm new password"
                  variant="outlined"
                  className="textinput"
                  fieldheight="40px"
                  Value={values.confirmPassword}
                  name="confirmPassword"
                  type="password"
                  handlechange={(value: string) => {
                    setFieldValue("confirmPassword", value);
                  }}
                  onKeyPress={(event: React.KeyboardEvent<HTMLDivElement>) => {
                    if (event.key === "Enter") {
                      handleSubmit();
                    }
                  }}
                  inputProps={{
                    maxLength: 20,
                  }}
                  onPasteText={(e) => {
                    e.preventDefault();
                  }}
                />
                {errors.confirmPassword && touched.confirmPassword && (
                  <Box className="loginError ">
                    <ErrorIcon />
                    <MediumTypography
                      labelId={errors.confirmPassword}
                      defaultLabel="Confirm Password is required"
                      fontSize="12px"
                      textColor="#AE2E24"
                      fontWeight={400}
                    />
                  </Box>
                )}
              </Box>

              <ButtonComponent
                type="submit"
                labelId={
                  reset ? "ResetPassword.Header" : "ChangePassword.Header"
                }
                variantType="outlined"
                className="btn-primary btn-submit width__100 mt-sm"
                defaultLabelId={reset ? "Reset Password" : "Change Password"}
                onClick={handleSubmit}
              />
            </Box>
          )}
        </Formik>
      </Box>
    </Box>
  );
};
export default ResetPassword;
