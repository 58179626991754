import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import { FC, useContext, useEffect, useRef, useState } from "react";
import MediumTypography from "../../../components/formlib/MediumTypography";
import ButtonComponent from "../../../components/formlib/ButtonComponent";
import CloseIcon from "@mui/icons-material/Close";
import "../styles.css";
import TextInput from "../../../components/formlib/TextInput";

import { LoaderContext, LoaderContextType } from "../../../layouts/appSidebar";

import ErrorModal from "../../../components/formlib/modal/ErrorModal";

import { Formik, FormikProps } from "formik";
import * as Yup from "yup";

import FormikErrorComponent from "../../../components/formlib/FormikErrorComponent";
import {
  createDropdownOptionApi,
  fetchDataApi,
  saveDataApi,
} from "../../../api/MasterDataManagement/Common";

import { FetchPermitType } from "../types/permitTypes";
import { trim } from "lodash";
import CustomDropdown from "../../../components/formlib/CustomDropdown";
import { getOptions, handleError } from "../../../utils/commonFunctions";
import RadioComponent from "../../../components/formlib/RadioComponent";

interface ModalProps {
  open: boolean;
  handleClose: () => void;
  id?: number;
  getDataCallback: () => void;
  languageCode: string;
}

type FetchDataType = FetchPermitType;
type SaveDataType = FetchPermitType;

const apiUri = "permit";
const initialData: FetchDataType = {
  permit: "",
  selectedPermitTypeId: 0,
  description: "",
  standardPermit: true,
};
const dropdownValidation = Yup.mixed()
  .nullable()
  .test("is-valid-number", "validation.dropdown", (value: any) => {
    const theValue = value ? value.toString() : "";
    if (trim(theValue) === "" || theValue === "0") {
      return true;
    }

    return parseFloat(theValue) > 0;
  });

const customRequiredFn = (value: any) => {
  const theValue = value ? value.toString() : "";
  return trim(theValue) !== "" && theValue !== "0";
};

const validationSchema = Yup.object().shape({
  permit: Yup.string().required("validation.fieldRequired"),
  standardPermit: Yup.boolean().required("validation.fieldRequired"),
  selectedPermitTypeId: dropdownValidation.test(
    "custom-required",
    "validation.dropdown",
    customRequiredFn
  ),
  description: Yup.string().max(500, "validation.maxLength500"),
});

const muiColumnProps = {
  xs: 12,
};

const AddEditPermitsModal: FC<ModalProps> = ({
  open,
  handleClose,
  id,
  getDataCallback,
  languageCode,
}) => {
  const [apiData, setApiData] = useState<FetchDataType>(initialData);
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [errorDesc, setErrorDesc] = useState<string>("");
  const formikRef = useRef<FormikProps<FetchDataType>>(null);
  const [isVersionRequired, setIsVersionRequired] = useState<boolean>(false);

  useEffect(() => {
    if (open) {
      fetchData();
    }
  }, [id, open]);

  const saveData = (params: FetchDataType) => {
    toggleLoader(true);
    const { permitTypes, ...updatedParams } = params;
    saveDataApi<SaveDataType>(apiUri, updatedParams, isVersionRequired, id)
      .then(() => {
        formikRef.current?.resetForm({ values: initialData });
        handleClose();
        getDataCallback();
        toggleLoader(false);
      })
      .catch((err) => {
        handleError(err, setErrorDesc);
        setOpenErrorModal(true);
        toggleLoader(false);
      });
  };

  const fetchData = () => {
    toggleLoader(true);
    fetchDataApi<FetchDataType>(apiUri, id)
      .then(async (res) => {
        const updatedRes = { ...initialData, ...res };
        setApiData(updatedRes);
        toggleLoader(false);
      })
      .catch((err) => {
        handleError(err, setErrorDesc);
        setOpenErrorModal(true);
        toggleLoader(false);
      });
  };

  return (
    <>
      {openErrorModal && (
        <ErrorModal
          descriptionText={errorDesc}
          open={openErrorModal}
          handleClose={() => {
            setOpenErrorModal(false);
            handleClose();
          }}
          onPositiveClick={() => {
            setOpenErrorModal(false);
            handleClose();
          }}
        />
      )}

      <Formik
        initialValues={apiData}
        enableReinitialize
        validateOnChange
        innerRef={formikRef}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          saveData(values);
        }}
      >
        {({ values, setFieldValue, handleSubmit, errors, touched, dirty }) => {
          return (
            <Dialog
              className="account-dialog main-wrapper-box"
              open={open}
              fullWidth
              maxWidth={"sm"}
            >
              <DialogTitle className="padding_20">
                <MediumTypography
                  labelId={id ? "permits.update" : "permits.add"}
                  defaultLabel="Permits"
                  textColor="#FFF"
                  fontSize="22px"
                  fontWeight={700}
                />
                <MediumTypography
                  labelId={"common.basicDetails"}
                  defaultLabel="Enter basic details"
                  textColor="rgba(159, 173, 188, 1)"
                  fontSize="14px"
                  fontWeight={400}
                  className="mt-xs"
                />
              </DialogTitle>

              <IconButton
                aria-label="close"
                onClick={() => {
                  formikRef.current?.resetForm({ values: initialData });
                  handleClose();
                }}
                className="closeIcon"
              >
                <CloseIcon />
              </IconButton>

              <DialogContent dividers className="padding_20">
                <Grid container spacing={2}>
                  <Grid item {...muiColumnProps}>
                    <MediumTypography
                      className="input-label"
                      labelId="permits.workPermitText"
                      defaultLabel="Work Permits"
                    />
                    <TextInput
                      className="text-input-field"
                      type="text"
                      variant="outlined"
                      inputProps={{
                        readOnly: false,
                      }}
                      labelId="common.placeHolderText"
                      defaultLabelId="--- type here ---"
                      Value={values.permit}
                      handlechange={(value: string) => {
                        setFieldValue("permit", value);
                      }}
                    />
                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="permit"
                    />
                  </Grid>
                  <Grid item {...muiColumnProps}>
                    <MediumTypography
                      className="input-label"
                      labelId="permits.PermitType"
                      defaultLabel="Permit Type"
                    />

                    <CustomDropdown
                      disableCreate={false}
                      options={getOptions(values?.permitTypes || [])}
                      selectedValue={values.selectedPermitTypeId}
                      onSelectedValueChange={(value) => {
                        setFieldValue("selectedPermitTypeId", value);
                      }}
                      onCreateOption={(option) => {
                        toggleLoader(true);
                        createDropdownOptionApi(
                          "PERMIT_TYPE",
                          "PermitType",
                          option
                        )
                          .then((res) => {
                            setFieldValue("permitTypes", res);
                            setFieldValue(
                              "selectedPermitTypeId",
                              res.find((inv) => inv.name === option)?.id || ""
                            );
                            toggleLoader(false);
                          })
                          .catch((err) => {
                            handleError(err, setErrorDesc);
                            setOpenErrorModal(true);
                            toggleLoader(false);
                          });
                      }}
                    />
                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="selectedPermitTypeId"
                    />
                  </Grid>

                  <Grid item {...muiColumnProps} className="standard-radio-div">
                    <MediumTypography
                      className="input-label"
                      labelId="checklist.standard"
                      defaultLabel="Standard"
                    />

                    <RadioComponent
                      name="standard"
                      value={values.standardPermit}
                      handleChange={(
                        e: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        setFieldValue("standardPermit", e.target.value);
                      }}
                      labelid=""
                      defaultlabelid=""
                      labelidA="YesText"
                      defaultlabelidA="Yes"
                      labelidB="NoText"
                      defaultlabelidB="No"
                    />

                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="standardPermit"
                    />
                  </Grid>

                  <Grid item {...muiColumnProps}>
                    <MediumTypography
                      className="input-label"
                      labelId="common.description"
                      defaultLabel="Description"
                    />
                    <TextInput
                      className="text-input-field"
                      type="text"
                      variant="outlined"
                      inputProps={{
                        readOnly: false,
                      }}
                      labelId="common.placeHolderText"
                      defaultLabelId="--- type here ---"
                      Value={values.description}
                      handlechange={(value: string) => {
                        setFieldValue("description", value);
                      }}
                    />
                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="description"
                    />
                  </Grid>
                </Grid>
              </DialogContent>

              <DialogActions className="dialogActions">
                <Box>
                  <Grid
                    className="flex__ justifyContent-FlexEnd"
                    container
                    direction="row"
                    alignItems="right"
                  >
                    <Grid item>
                      <ButtonComponent
                        sxProps={{ color: "#B6C2CF !important" }}
                        className="btn-primary btn-cancel mr-md"
                        variantType="outlined"
                        defaultLabelId={"Reset"}
                        labelId={"common.reset"}
                        onClick={() => formikRef.current?.resetForm()}
                        disabled={!dirty}
                      />
                    </Grid>
                    <Grid item>
                      <ButtonComponent
                        className="btn-primary btn-submit"
                        variantType="contained"
                        defaultLabelId={"Save"}
                        labelId={id ? "btn.update" : "btn.Add"}
                        onClick={() => {
                          handleSubmit();
                          setIsVersionRequired(false);
                        }}
                      />
                    </Grid>
                    {id && (
                      <Grid item>
                        <ButtonComponent
                          className="btn-primary btn-submit ml-md"
                          variantType="contained"
                          defaultLabelId={"Save"}
                          labelId={id ? "btn.updateWithVersion" : undefined}
                          onClick={() => {
                            handleSubmit();
                            setIsVersionRequired(true);
                          }}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Box>
              </DialogActions>
            </Dialog>
          );
        }}
      </Formik>
    </>
  );
};

export default AddEditPermitsModal;
