import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  TextField,
  ListItem,
  ListItemText,
  Divider,
  AutocompleteProps,
  SxProps,
  Paper,
} from "@mui/material";
import { useIntl } from "react-intl";
import MediumTypography from "./MediumTypography";
import ButtonComponent from "./ButtonComponent";

const convertToString = (value: any): string => {
  return value === undefined || value === null || value === 0 || value === "0"
    ? ""
    : value.toString();
};

const convertToStringArray = (value: any): string[] => {
  return value === undefined ||
    value === null ||
    value === 0 ||
    value === "0" ||
    !Array.isArray(value)
    ? []
    : value.map((item) => item.toString());
};

export interface OptionType {
  id: string;
  name: string;
  message?: string;
}

interface CustomDropdownProps
  extends Partial<AutocompleteProps<OptionType, boolean, boolean, boolean>> {
  selectedValue?: number | string | number[] | string[];
  onSelectedValueChange?: (value: string | string[]) => void;
  options?: OptionType[];
  multiple?: boolean;
  required?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  labelId?: string;
  defaultLabel?: string;
  placeholder?: string;
  onCreateOption?: (newOption: string) => void;
  disableSearch?: boolean;
  disableCreate?: boolean;
  sxStyles?: {
    textField?: SxProps;
    listItem?: SxProps;
    listItemText?: SxProps;
    divider?: SxProps;
  };
  overrideBgColor?: string;
  overrideBgColorHover?: string;
  overrideBgColorFocus?: string;
  overrideChipBgColor?: string;
  overrideBorderColor?: string;
  overrideBorderColorHover?: string;
  overrideBorderColorFocus?: string;
  overrideTextColor?: string;
  overrideHeight?: string;
  overrideMinHeight?: string;
  overrideWidth?: string;
}

const CustomDropdown: React.FC<CustomDropdownProps> = ({
  selectedValue,
  onSelectedValueChange,
  options = [],
  multiple = false,
  required = false,
  readOnly = false,
  disabled = false,
  labelId,
  defaultLabel,
  placeholder,
  onCreateOption,
  disableSearch = false,
  disableCreate = false,
  sxStyles = {},
  overrideBgColor = "rgba(34, 39, 43, 1)",
  overrideBgColorHover = "rgb(20 23 25)",
  overrideBgColorFocus = "rgb(20 23 25)",
  overrideChipBgColor = "rgb(40 46 51)",
  overrideBorderColor = "rgba(166, 197, 226, 0.16)",
  overrideBorderColorHover = "rgba(166, 197, 226, 0.16)",
  overrideBorderColorFocus = "rgba(166, 197, 226, 0.4)",
  overrideTextColor = "rgba(140, 155, 171, 1)",
  overrideHeight = "40px",
  overrideMinHeight = "40px",
  overrideWidth = "100%",
  ...autocompleteProps
}) => {
  const [localOptions, setLocalOptions] = useState<OptionType[]>(options);
  const intl = useIntl();

  useEffect(() => {
    setLocalOptions(options);
  }, [options]);

  const defaultStyles = {
    textField: {
      height: overrideHeight,
      minHeight: overrideMinHeight,
      width: overrideWidth,
      fontFamily: "Inter",
      "& .MuiOutlinedInput-root": {
        height: overrideHeight,
        minHeight: overrideMinHeight,
        padding: "0px 10px",
        bgcolor: overrideBgColor,
        fontFamily: "Inter",
        color: overrideTextColor,
        "& fieldset": {
          border: "1px solid " + overrideBorderColor,
          fontFamily: "Inter",
        },
        "&:hover fieldset": {
          border:
            readOnly || disabled
              ? "1px solid " + overrideBorderColor
              : "2px solid " + overrideBorderColorHover,
        },
        "&.Mui-focused fieldset": {
          fontFamily: "Inter",
          border:
            readOnly || disabled
              ? "1px solid " + overrideBorderColor
              : "2px solid " + overrideBorderColorFocus,
        },
        "& .MuiChip-root": {
          fontFamily: "Inter",
          bgcolor: overrideChipBgColor,
          color: overrideTextColor,
          "& .MuiChip-deleteIcon": {
            color: overrideTextColor,
          },
        },
      },
      "& .MuiOutlinedInput-root.Mui-disabled": {
        "& .MuiInputBase-input.Mui-disabled": {
          color: overrideTextColor + " !important",
          "-webkit-text-fill-color": overrideTextColor + " !important",
        },
        "& fieldset": {
          border: "1px solid " + overrideBorderColor,
        },
        "&:hover fieldset": {
          border: "2px solid " + overrideBorderColorHover,
        },
        "&.Mui-focused fieldset": {
          border: "2px solid " + overrideBorderColorFocus,
        },
        "& input[aria-expanded='true'] ~ fieldset": {
          border: "2px solid " + overrideBorderColorFocus,
        },
      },
      "& .MuiOutlinedInput-root.disabled-input-field.Mui-disabled": {
        "& .MuiInputBase-input.Mui-disabled": {
          color: overrideTextColor + " !important",
          "-webkit-text-fill-color": overrideTextColor + " !important",
        },
        "& fieldset": {
          border: "1px solid " + overrideBorderColor,
        },
      },
      "& .MuiOutlinedInput-input": {
        fontFamily: "Inter", // Apply font to input text
      },
      "& .MuiSvgIcon-root": {
        color: overrideTextColor,
      },
      "& .MuiInputBase-root": {
        display: "flex",
        flexWrap: "wrap", // Ensure the chips wrap
        alignItems: "center", // Keep the input on the same line as chips
      },
      "& .MuiChip-root": {
        maxWidth: "100%", // Ensure chips take up full width if needed
      },
      "& .MuiAutocomplete-tag": {
        whiteSpace: "normal", // Ensure long chip labels wrap properly
      },
      "& input": {
        height:
          multiple && disableSearch && (selectedValue as any[])?.length > 0
            ? 0
            : "auto",
        padding:
          multiple && disableSearch && (selectedValue as any[])?.length > 0
            ? "0 !important"
            : "auto",
        minWidth: "60px", // Ensure the input has a minimum width and stays on the same line as chips
        flexGrow: 1, // Allow the input to grow and occupy available space
        width: "auto", // Automatically adjust width as chips are added
      },
    },
    listItem: {
      fontFamily: "Inter",
      bgcolor: overrideBgColor,
      "&.Mui-focused": {
        bgcolor: overrideBgColorFocus + " !important",
      },
      "&.Mui-selected": {
        bgcolor: overrideBgColorFocus + " !important",
      },
      "&:hover": {
        bgcolor: overrideBgColorHover + " !important",
      },
    },
    listItemText: {
      color: overrideTextColor,
      fontFamily: "Inter",
    },
    divider: {
      bgcolor: overrideBorderColor,
    },
  };

  const mergedStyles = {
    textField: { ...defaultStyles.textField, ...sxStyles.textField },
    listItem: { ...defaultStyles.listItem, ...sxStyles.listItem },
    listItemText: { ...defaultStyles.listItemText, ...sxStyles.listItemText },
    divider: { ...defaultStyles.divider, ...sxStyles.divider },
  };

  const initialSelectedValue: string | string[] = multiple
    ? convertToStringArray(selectedValue)
    : convertToString(selectedValue);

  const showPlaceholder = multiple
    ? (initialSelectedValue as string[]).length === 0
    : initialSelectedValue === "";

  const handleCreateOption = (newOption: string) => {
    // const newOptionObject = { id: newOption, name: newOption };
    // setLocalOptions((prevOptions) => [...prevOptions, newOptionObject]);
    // if (onSelectedValueChange) {
    //   onSelectedValueChange(
    //     multiple ? [newOptionObject.id] : newOptionObject.id
    //   );
    // }
    if (onCreateOption) {
      onCreateOption(newOption);
    }
  };

  const defaultPlaceholder = multiple
    ? intl.formatMessage({
        id: "accounts.multiSelectPlaceholder",
        defaultMessage: "--- select multiple options ---",
      })
    : intl.formatMessage({
        id: "accounts.selectPlaceholder",
        defaultMessage: "--- select ---",
      });

  return (
    <Autocomplete
      {...autocompleteProps}
      multiple={multiple}
      disabled={disabled}
      readOnly={readOnly}
      options={localOptions}
      disableClearable={disableSearch}
      disableCloseOnSelect={multiple}
      getOptionLabel={(option) => {
        if (typeof option === "string") {
          return option;
        }

        return option.name;
      }}
      value={
        multiple
          ? localOptions.filter((opt) =>
              (initialSelectedValue as string[]).includes(opt.id)
            )
          : localOptions.find((opt) => opt.id === initialSelectedValue) ?? ""
      }
      onChange={(_, value) => {
        if (onSelectedValueChange) {
          onSelectedValueChange(
            multiple
              ? (value as OptionType[]).map((opt) => opt.id)
              : (value as OptionType)?.id ?? ""
          );
        }
      }}
      filterOptions={(options2, state) => {
        if (disableSearch) {
          return options2;
        }

        const filtered = options2.filter((option) =>
          option.name.toLowerCase().includes(state.inputValue.toLowerCase())
        );

        if (
          state.inputValue !== "" &&
          !disableCreate &&
          !filtered.some(
            (option) =>
              option.name.toLowerCase() === state.inputValue.toLowerCase()
          )
        ) {
          filtered.push({
            id: "createNewOption",
            name: state.inputValue,
            message:
              intl.formatMessage({
                id: "dropdown.createNewOption",
                defaultMessage: "Create new option",
              }) + ` "${state.inputValue}"`,
          });
        }

        return filtered;
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={
            showPlaceholder
              ? labelId
                ? intl.formatMessage({
                    id: labelId,
                    defaultMessage: defaultLabel ?? defaultPlaceholder,
                  })
                : defaultPlaceholder
              : ""
          }
          required={required}
          InputProps={{
            ...params.InputProps,
            className: readOnly || disabled ? "disabled-input-field" : "",
            readOnly: readOnly || disableSearch, // Disallow input
            disabled: disabled || disableSearch, // Disable input
            style: disableSearch ? { cursor: "pointer" } : {}, // Change cursor to pointer
          }}
          sx={{
            ...mergedStyles.textField,
            fontFamily: "Inter",
            "& .MuiInputBase-input": {
              cursor: disableSearch ? "pointer" : "inherit", // Ensure cursor changes within the input
              userSelect: disableSearch ? "none" : "auto", // Prevent text selection
              pointerEvents: disableSearch ? "none" : "auto", // Prevent focus
              caretColor: disableSearch ? "transparent" : "auto", // Hide the input marker (caret)
            },
          }}
        />
      )}
      renderOption={(props, option) => {
        if (option.id === "createNewOption") {
          return (
            !disableCreate && (
              <React.Fragment key={option.name}>
                <Divider sx={mergedStyles.divider} />
                <ListItem
                  {...props}
                  onClick={() => handleCreateOption(option.name)}
                  sx={mergedStyles.listItem}
                >
                  <ListItemText
                    primary={option.message}
                    sx={mergedStyles.listItemText}
                  />
                </ListItem>
              </React.Fragment>
            )
          );
        } else {
          return (
            <ListItem {...props} sx={mergedStyles.listItem}>
              <ListItemText
                primary={option.name}
                sx={mergedStyles.listItemText}
              />
            </ListItem>
          );
        }
      }}
      noOptionsText={
        <MediumTypography
          labelId="dropdown.noOption"
          defaultLabel="No option"
          textColor="#FF453A"
        />
      }
      PaperComponent={(props) => (
        <Paper {...props} sx={{ bgcolor: overrideBgColor }} />
      )}
    />
  );
};

export default CustomDropdown;
