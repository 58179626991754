import { FC } from "react";
import { ReactComponent as CheckedCircleIcon } from "../../../assets/images/TickCircle.svg";
import { ReactComponent as TimerPauseIcon } from "../../../assets/images/TimerPause.svg";
import { ReactComponent as UncheckedIcon } from "../../../assets/images/Unckecked.svg";
import { Tooltip } from "@mui/material";
import MediumTypography from "../../../components/formlib/MediumTypography";

interface CompletionStatusIconProps {
  status?: "checkedPositive" | "checkedNegative" | "unchecked" | "hidden";
  checkedPositiveIcon?: JSX.Element;
  checkedNegativeIcon?: JSX.Element;
  unCheckedIcon?: JSX.Element;
}

const CompletionStatusIcon: FC<CompletionStatusIconProps> = ({
  status,
  checkedPositiveIcon,
  checkedNegativeIcon,
  unCheckedIcon,
}) => {
  return (
    <>
      {status === "checkedPositive" && (
        <Tooltip
          title={
            <MediumTypography
              labelId="pm.doneTooltip"
              defaultLabel="Done"
              fontSize="12px"
            />
          }
        >
          <span>{checkedPositiveIcon || <CheckedCircleIcon />}</span>
        </Tooltip>
      )}
      {status === "checkedNegative" && (
        <Tooltip
          title={
            <MediumTypography
              labelId="pm.doneIssueTooltip"
              defaultLabel="Done With Issue"
              fontSize="12px"
            />
          }
        >
          <span>{checkedNegativeIcon || <TimerPauseIcon />}</span>
        </Tooltip>
      )}
      {status === "unchecked" && (unCheckedIcon || <UncheckedIcon />)}
    </>
  );
};

export default CompletionStatusIcon;
