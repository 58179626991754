import { useNavigate } from "react-router-dom";

let navigateFn: any;

export const NavigateSetter = () => {
  navigateFn = useNavigate();
  return null;
};

export const navigate = (path: string) => {
  navigateFn(path);
};
