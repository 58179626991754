import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { DialogTitle } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./WizardScreen.css";
import MediumTypography from "../../components/formlib/MediumTypography";
import CustomDropdown from "../../components/formlib/CustomDropdown";
import { getOptions, handleError } from "../../utils/commonFunctions";
import { LoaderContext, LoaderContextType } from "../../layouts/appSidebar";
import { fetchAllDataApi } from "../../api/MasterDataManagement/Common";
import {
  AccountData,
  ManageAccountType,
} from "../MasterDataManagement/types/accountTypes";

import ButtonComponent from "../../components/formlib/ButtonComponent";
import { useNavigate } from "react-router-dom";
import { WIZARDS_SITE_DETAILS } from "../../routes/Routing";
import AddEditAccountModal from "../MasterDataManagement/accounts/AddEditAccountModal";

interface AccountModalProps {
  open: boolean;
  handleClose: () => void;
}

const AccountModal: React.FC<AccountModalProps> = ({ open, handleClose }) => {
  const [alignment, setAlignment] = React.useState("1");
  const accountName = useRef<string>("");
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [errorDesc, setErrorDesc] = useState<string>("");
  const [tableData, setTableData] = useState<AccountData[]>([]);
  const navigate = useNavigate();
  const [onOpenAddModal, setOnOpenAddModal] = useState<boolean>(false);

  useEffect(() => {
    getAccountDetails();
  }, []);

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    setAlignment(newAlignment);
    if (newAlignment === "2") {
      setOnOpenAddModal(true);
    }
  };

  const getAccountDetails = (language: string = "en") => {
    toggleLoader(true);
    fetchAllDataApi<ManageAccountType>("account", language, "")
      .then((response) => {
        toggleLoader(false);
        if (response) {
          setTableData(response.account.records);
        }
      })
      .catch((error) => {
        toggleLoader(false);
        setOpenErrorModal(true);
        handleError(error, setErrorDesc);
      });
  };

  return (
    <Box>
      {onOpenAddModal && (
        <AddEditAccountModal
          open={onOpenAddModal}
          handleClose={() => {
            setOnOpenAddModal(false);
          }}
          getAccountDataCallback={() => {
            getAccountDetails();
            setAlignment("1");
          }}
        />
      )}
      <Dialog
        className="account-dialog main-wrapper-box"
        open={open}
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle sx={{ backgroundColor: "#1D2125" }}></DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            handleClose();
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 16,
            color: "rgba(159, 173, 188, 1)",
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent className="accountModalDailogcontent-css">
          <Box className="flex__ dailog-content-outer-box">
            <Box>
              <ToggleButtonGroup
                color="primary"
                className="secondButtonCss"
                value={alignment}
                exclusive
                onChange={handleChange}
                aria-label="Platform"
                sx={{
                  "& MuiButtonBase-root-MuiToggleButton-root.Mui-selected": {
                    backgroundColor: "#0C66E4",
                  },
                }}
              >
                <ToggleButton
                  value="1"
                  className="firstToggleButton-css"
                  sx={{
                    "&.Mui-selected": {
                      backgroundColor: "#0C66E4",
                    },
                    "&.Mui-selected:hover": {
                      backgroundColor: "#0C66E4",
                    },
                  }}
                >
                  <MediumTypography
                    labelId="accounts.existingAccount"
                    defaultLabel="Existing Account"
                    fontSize="16px"
                    fontWeight={700}
                    textColor="#FFFFFF"
                  />
                </ToggleButton>
                <ToggleButton
                  value="2"
                  className="secondToggleButton-css"
                  sx={{
                    "&.Mui-selected": {
                      backgroundColor: "#0C66E4",
                    },
                    "&.Mui-selected:hover": {
                      backgroundColor: "#0C66E4",
                    },
                  }}
                >
                  <MediumTypography
                    labelId="accounts.newAccount"
                    defaultLabel="New Account"
                    fontSize="16px"
                    fontWeight={700}
                    textColor="#FFFFFF"
                  />
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <MediumTypography
                  className="input-label"
                  labelId="accounts.accountType"
                  defaultLabel="Account Type"
                />
                <CustomDropdown
                  disabled={alignment === "newAccount"}
                  options={getOptions(tableData)}
                  selectedValue={accountName.current}
                  onSelectedValueChange={(value) => {
                    accountName.current = value as string;
                    localStorage.setItem("AccId", value as string);
                  }}
                  sx={{
                    width: "100%", // Ensure it takes full width of the parent
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            padding: "12px 20px",
            backgroundColor: "#1D2125",
          }}
        >
          <Box>
            <Grid
              className="flex__ justifyContent-FlexEnd"
              container
              direction="row"
              alignItems="right"
            >
              <Grid item>
                <ButtonComponent
                  sxProps={{ color: "#B6C2CF !important" }}
                  className="btn-primary btn-cancel mr-md"
                  variantType="outlined"
                  defaultLabelId={"Reset"}
                  labelId={"common.reset"}
                />
              </Grid>
              <Grid item>
                <ButtonComponent
                  className="btn-primary btn-submit"
                  variantType="contained"
                  defaultLabelId={"Begin"}
                  labelId={"BeginButtonText"}
                  onClick={() =>
                    navigate(WIZARDS_SITE_DETAILS, {
                      state: {
                        accounts: tableData,
                        selectedAccount: accountName.current,
                      },
                    })
                  }
                />
              </Grid>
            </Grid>
          </Box>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AccountModal;
