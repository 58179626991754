import React, { useState, ReactNode } from "react";
import { IntlProvider } from "react-intl";
import English from "./languagelocalisation/en-us.json";
import Hindi from "./languagelocalisation/hindi.json";
const local: string = localStorage.getItem("preferredLanguage") ?? "en-US";

export interface ContextType {
  locale: string;
  selectLanguage: () => void;
}

export const Context = React.createContext<ContextType>({
  locale: "en-US",
  selectLanguage: () => {},
});
interface WrapperProps {
  children: ReactNode;
}

const Wrapper = (props: WrapperProps) => {
  const { children } = props;
  const [myLanguage, setMyLanguage] = useState({});
  const [locale, setLocale] = useState(local);

  function selectLanguage() {
    const newlocale = local;
    setLocale(newlocale);
    if (localStorage.getItem("preferredLanguage") === "hn") {
      setMyLanguage(Hindi);
    } else {
      setMyLanguage(English);
    }
  }

  return (
    <Context.Provider value={{ locale, selectLanguage }}>
      <IntlProvider locale={local} messages={myLanguage}>
        {children}
      </IntlProvider>
    </Context.Provider>
  );
};

export default Wrapper;
