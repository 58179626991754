import { Menu, MenuProps, Theme, styled } from "@mui/material";

export const CustomMenu = styled(
  ({
    padding = "0px 0px",
    maxWidth = 500, // default maxWidth
    left = 0,
    width = "0",
    minWidth = 500, // default maxWidth
    ...props
  }: MenuProps & {
    padding?: string;
    maxWidth?: number;
    left?: number;
    width?: string;
    minWidth?: number;
  }) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      style={{ left: left }}
      {...props}
    />
  )
)(
  ({
    theme,
    padding = "0px 0px",
    maxWidth = 500, // fallback for maxWidth
    width = "0",
    minWidth = 0, // fallback for maxWidth
  }: {
    theme: Theme;
    width?: string;
    padding?: string;
    maxWidth?: number;
    minWidth?: number;
  }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      width: width,
      minWidth: minWidth,
      maxWidth: maxWidth, // dynamically set maxWidth
      maxHeight: 550,
      padding: padding,
      borderWidth: "0 10px 10px 10px",
      boxShadow: "none",
      backgroundColor: "#1D2125",
      border: "1px solid rgba(166, 197, 226, 0.16)",
    },
    "& .MuiPaper-root.MuiPaper-elevation > ul": {
      paddingBottom: "0px",
    },
    "& .MuiPaper-root::-webkit-scrollbar": {
      height: "5px",
      width: "4px",
      marginLeft: "10px",
    },
    "& .MuiPaper-root::-webkit-scrollbar-track": {
      backgroundColor: "transparent",
    },
    "& .MuiPaper-root::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(12, 102, 228, 0.6)",
      borderRadius: "10px",
      border: "1px solid rgba(12, 102, 228, 0.6)",
      backgroundClip: "content-box",
      marginLeft: "10px",
    },
    "& .MuiPaper-root::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "#a8bbbf",
    },
  })
);
