import { Box, Grid } from "@mui/material";
import MediumTypography from "../../../components/formlib/MediumTypography";
import ButtonComponent from "../../../components/formlib/ButtonComponent";
import { Formik, FormikProps } from "formik";
import { FC, useContext, useEffect, useRef, useState } from "react";
import FormikErrorComponent from "../../../components/formlib/FormikErrorComponent";
import TextInput from "../../../components/formlib/TextInput";
import {
  AssetCategoryType,
  AssetGroupWizardsType,
} from "../types/assetGroupTypes";
import { DocumentTypeBean } from "../../workOrderDetailScreens/correctiveMaintenance/types";
import * as Yup from "yup";
import CustomDropdown from "../../../components/formlib/CustomDropdown";
import {
  getOptions,
  handleError,
  splitBySelectionStatus,
} from "../../../utils/commonFunctions";
import FileUploadInput from "../../workOrderDetailScreens/correctiveMaintenance/FileUploadInput";
import DateTimePickerComponent from "../../../components/formlib/DateTimePickerComponent";
import dayjs, { Dayjs } from "dayjs";
import PhoneNumberInput from "../../../components/formlib/PhoneNumberInput";
import useAvailableHeight from "../../../utils/useAvailableHeight";
import { set, trim } from "lodash";
import { LoaderContext, LoaderContextType } from "../../../layouts/appSidebar";
import { WORK_FLOW_BASE_URL } from "../../../api/Constant";
import {
  customSaveDataApi,
  customUpdateDataApi,
} from "../../../api/MasterDataManagement/Common";
import {
  fetchWizardsDataApi,
  MappedCommonTypes,
} from "../../../api/wizardsApi/Common";
import {
  CountriesType,
  StateType,
} from "../../MasterDataManagement/types/accountTypes";
import MappingModal from "../../../components/formlib/modal/MappingModal";
import TextInputMap from "../../../components/formlib/TextInputMap";
import ErrorModal from "../../../components/formlib/modal/ErrorModal";

const muiColumnProps = {
  xs: 12,
  sm: 6,
  md: 4,
  lg: 4,
  xl: 4,
};

interface AssetGroupProps {
  subGroupId: number;
  assetGroupId: number;
  assetGroupName: string;
}

const initialAssetGroupData: AssetGroupWizardsType = {
  name: "",
  dcCapacity: 0,
  acCapacity: 0,
  sitePhoto: "",
  assetGroupImage: undefined as DocumentTypeBean | undefined,
  code: "",
  latitude: "",
  longitude: "",
  email: "",
  dateOfCommission: null,
  mobilizationDate: null,
  effectiveDate: null,
  selectedTimezoneId: 0,
  selectedCountryId: 0,
  selectedStateId: 0,
  selectedAssetGroupTypeId: 0,
  selectedEpcContractorId: 0,
  selectedStatusId: 0,
  selectedPlantTypeId: 0,
  assetGroupArea: 0,
  plannedCycleThresholdDays: 0,
  woMoveThreshold: 0,
  contactReference: "",
  countryCode: "",
  phoneNumber: 0,
  owner: "",
  spvName: "",
  location: "",
  contactPersonFirstName: "",
  contactPersonLastName: "",
  timezones: [],
  countries: [],
  states: [],
  statusList: [],
  assetGroupTypes: [],
  epcContractors: [],
  plantTypes: [],
  assetCategories: [],
  selectedAssetCategories: [],
};

const getStateOptions = (
  countries: CountriesType[],
  selectedCountryId: number
) => {
  return (
    countries.find((country) => country.id === selectedCountryId)?.states || []
  );
};

const urlToDocumentTypeBean = async (
  url: string
): Promise<DocumentTypeBean> => {
  return fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      const reader = new FileReader();
      return new Promise<DocumentTypeBean>((resolve, reject) => {
        reader.onloadend = () => {
          const base64StringWithPrefix = reader.result as string;
          const base64String = base64StringWithPrefix.split(",")[1];
          const fileName = url.split("/").pop() || "file";
          const extension = fileName.split(".").pop() || "";
          resolve({
            document: base64String,
            fileName: fileName,
            extension: extension,
          });
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    });
};
const dropdownValidation = Yup.mixed()
  .nullable()
  .test("is-valid-number", "validation.dropdown", (value) => {
    const theValue = value ? value.toString() : "";
    if (trim(theValue) === "" || theValue === "0") {
      return true;
    }

    return parseFloat(theValue) > 0;
  });

const customRequiredFn = (value: any) => {
  const theValue = value ? value.toString() : "";
  return trim(theValue) !== "" && theValue !== "0";
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required("validation.fieldRequired"),
  location: Yup.string().required("validation.fieldRequired"),
  code: Yup.string()
    .matches(/^[^\s]*$/, "validation.noSpaces")
    .max(20, "validation.maxLength20")
    .required("validation.fieldRequired"),
  latitude: Yup.string().required("validation.fieldRequired"),
  longitude: Yup.string().required("validation.fieldRequired"),
  selectedAssetGroupTypeId: dropdownValidation.test(
    "custom-required",
    "validation.dropdown",
    customRequiredFn
  ),
  selectedTimezoneId: dropdownValidation.test(
    "custom-required",
    "validation.dropdown",
    customRequiredFn
  ),
  selectedStateId: dropdownValidation.test(
    "custom-required",
    "validation.dropdown",
    customRequiredFn
  ),
  selectedStatusId: dropdownValidation.test(
    "custom-required",
    "validation.dropdown",
    customRequiredFn
  ),
  selectedCountryId: dropdownValidation.test(
    "custom-required",
    "validation.dropdown",
    customRequiredFn
  ),
  dcCapacity: Yup.number()
    .min(1, "validation.fieldRequired")
    .required("validation.fieldRequired"),
  acCapacity: Yup.number()
    .min(1, "validation.fieldRequired")
    .required("validation.fieldRequired"),
  plannedCycleThresholdDays: Yup.number()
    .min(1, "validation.fieldRequired")
    .required("validation.fieldRequired"),
  woMoveThreshold: Yup.number()
    .min(1, "validation.fieldRequired")
    .required("validation.fieldRequired"),
  assetGroupArea: Yup.number()
    .min(1, "validation.fieldRequired")
    .required("validation.fieldRequired"),
  selectedAssetCategories: Yup.array()
    .of(Yup.object())
    .min(1, "validation.fieldRequired"),
});

const convertToNumber = (value: any): number => {
  return value === undefined || value === null || value === ""
    ? 0
    : Number(value);
};

//mapping data

// const AvailableData: MapDataType[] = [
//   {
//     id: "1",
//     name: "Inverter",
//     count: "10",
//   },
//   {
//     id: "2",
//     name: "WSA",
//     count: "",
//   },
//   {
//     id: "5",
//     name: "Inverter1",
//     count: "10",
//   },
//   {
//     id: "6",
//     name: "WSB",
//     count: "",
//   },
//   {
//     id: "8",
//     name: "Inverter3",
//     count: "10",
//   },
//   {
//     id: "12",
//     name: "WSC",
//     count: "",
//   },
//   {
//     id: "15",
//     name: "Inverter4",
//     count: "10",
//   },
//   {
//     id: "21",
//     name: "WSD",
//     count: "",
//   },
//   {
//     id: "18",
//     name: "Inverter5",
//     count: "10",
//   },
//   {
//     id: "28",
//     name: "WSE",
//     count: "",
//   },
//   {
//     id: "13",
//     name: "Inverter6",
//     count: "10",
//   },
//   {
//     id: "20",
//     name: "WSF",
//     count: "",
//   },
// ];
// const MappedData: MapDataType[] = [
//   {
//     id: "3",
//     name: "String central Inverter data",
//     count: "10",
//   },
// ];

const AssetGroupWizards: FC<AssetGroupProps> = ({
  subGroupId,
  assetGroupId,
  assetGroupName,
}) => {
  const formikRef = useRef<FormikProps<AssetGroupWizardsType>>(null);
  const [assetGroupData, setAssetGroupData] = useState<AssetGroupWizardsType>(
    initialAssetGroupData
  );
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  const [stateOptions, setStateOptions] = useState<StateType[]>([]);
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [errorDesc, setErrorDesc] = useState<string>("");
  let siteId: number = 339;
  const [idExist, setIsIdExist] = useState<boolean>(true);
  const [assetCategoryArr, setAssetCategoryArr] = useState<AssetCategoryType[]>(
    []
  );

  const [openMapModal, setOpenMapModal] = useState<boolean>(false);
  const handleApply = (mappedData: MappedCommonTypes[]) => {
    setOpenMapModal(false);
    const updatedAssetCategoryArr = assetCategoryArr.map((category) => {
      const isSelected = mappedData.some((item) => item.id === category.id);
      const updatedLevel =
        mappedData.find((item) => item.id === category.id)?.level ??
        category.level;
      return {
        ...category,
        isSelected: isSelected,
        level: updatedLevel,
      };
    });
    setAssetCategoryArr(updatedAssetCategoryArr);
    formikRef.current?.setFieldValue("selectedAssetCategories", mappedData);
    // Do something with the mappedData and availableData
  };

  useEffect(() => {
    getAssetGroupById();
  }, [assetGroupId]);

  const getAssetGroupById = () => {
    const fetchAssetGroupUrl = `${WORK_FLOW_BASE_URL}assetGroup`;

    fetchWizardsDataApi<AssetGroupWizardsType>(fetchAssetGroupUrl, assetGroupId)
      .then(async (response: AssetGroupWizardsType) => {
        const responseData = { ...initialAssetGroupData, ...response };
        setAssetCategoryArr(responseData.assetCategories);
        const siteImageUrl = responseData.sitePhoto;

        if (
          siteImageUrl !== "" &&
          siteImageUrl !== null &&
          siteImageUrl !== undefined
        ) {
          const documentTypeBean = await urlToDocumentTypeBean(siteImageUrl);
          responseData.assetGroupImage = documentTypeBean;
        }
        setStateOptions(
          getStateOptions(
            responseData.countries,
            responseData.selectedCountryId
          )
        );
        setAssetGroupData(responseData);
        toggleLoader(false);
      })
      .catch((err) => {
        handleError(err, setErrorDesc);
        setOpenErrorModal(true);
        toggleLoader(false);
      });
  };

  const saveData = (data: AssetGroupWizardsType) => {
    const {
      timezones,
      countries,
      states,
      statusList,
      assetGroupTypes,
      epcContractors,
      plantTypes,
      ...updatedParams
    } = data;

    const assetGroupIdExist = assetGroupId === 0 ? undefined : assetGroupId;
    if (assetGroupIdExist) {
      const updateUri = `${WORK_FLOW_BASE_URL}assetGroup/${assetGroupIdExist}/update`;

      customUpdateDataApi(updateUri, updatedParams)
        .then(() => {
          getAssetGroupById();
        })
        .catch((error) => {
          handleError(error, setErrorDesc);
          setOpenErrorModal(true);
          toggleLoader(false);
        });
    } else {
      const url = `${WORK_FLOW_BASE_URL}${subGroupId}/assetGroup/save`;
      customSaveDataApi(url, updatedParams)
        .then(() => {
          getAssetGroupById();
        })
        .catch((error) => {
          handleError(error, setErrorDesc);
          setOpenErrorModal(true);
          toggleLoader(false);
        });
    }

    // Add your API call logic
  };

  const availableHeight = useAvailableHeight(280);
  return (
    <Box>
      {openMapModal && (
        <MappingModal
          titleLabelId="assetGroup.mappingTitle"
          handleClose={() => setOpenMapModal(false)}
          open={openMapModal}
          onApply={handleApply}
          availableLabel="assetGroup.available"
          mappingDetailsLabel="assetGroup.mappingDetails"
          mappedList={assetCategoryArr}
          textInputRequired={true}
        />
      )}
      {openErrorModal && (
        <ErrorModal
          descriptionText={errorDesc}
          open={openErrorModal}
          handleClose={() => setOpenErrorModal(false)}
          onPositiveClick={() => {
            setOpenErrorModal(false);
          }}
        />
      )}
      <Box className={"flex__justify__space-between mb-lg "}>
        <MediumTypography
          label={assetGroupName}
          textColor="#FFF"
          fontSize="20px"
          fontWeight={700}
        />

        <ButtonComponent
          className="btn-primary btn-submit"
          variantType="contained"
          defaultLabelId={"Add New"}
          labelId={"btn.AddNew"}
          onClick={() => {}}
        />
      </Box>

      <Formik
        initialValues={assetGroupData}
        enableReinitialize
        validateOnChange
        innerRef={formikRef}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          const paramsObj: AssetGroupWizardsType = {
            ...values,
            phoneNumber: convertToNumber(values.phoneNumber),
            selectedCountryId: convertToNumber(values.selectedCountryId),
            selectedStateId: convertToNumber(values.selectedStateId),
            selectedTimezoneId: convertToNumber(values.selectedTimezoneId),
            selectedAssetGroupTypeId: convertToNumber(
              values.selectedAssetGroupTypeId
            ),
            selectedEpcContractorId: convertToNumber(
              values.selectedEpcContractorId
            ),
            selectedPlantTypeId: convertToNumber(values.selectedPlantTypeId),
            selectedStatusId: convertToNumber(values.selectedStatusId),
          };
          saveData(paramsObj);
        }}
      >
        {({ values, setFieldValue, handleSubmit, errors, touched, dirty }) => {
          return (
            <Box>
              <Grid
                container
                spacing={2}
                sx={{
                  overflowY: "auto",
                  height: availableHeight,
                }}
                className="pr-sm"
              >
                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    className="input-label"
                    labelId="skillNameText"
                    defaultLabel="Name"
                  />
                  <TextInput
                    className="text-input-field"
                    type="text"
                    variant="outlined"
                    inputProps={{
                      readOnly: false,
                    }}
                    labelId="common.placeHolderText"
                    defaultLabelId="--- type here ---"
                    Value={values.name}
                    handlechange={(value: string) => {
                      setFieldValue("name", value);
                    }}
                  />
                  <FormikErrorComponent
                    errors={errors}
                    touched={touched}
                    field="name"
                  />
                </Grid>
                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    className="input-label"
                    labelId="assetGroupWizards.location"
                    defaultLabel="Location"
                  />
                  <TextInput
                    className="text-input-field"
                    type="text"
                    variant="outlined"
                    inputProps={{
                      readOnly: false,
                    }}
                    labelId="common.placeHolderText"
                    defaultLabelId="--- type here ---"
                    Value={values.location}
                    handlechange={(value: string) => {
                      setFieldValue("location", value);
                    }}
                  />
                  <FormikErrorComponent
                    errors={errors}
                    touched={touched}
                    field="location"
                  />
                </Grid>
                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    className="input-label"
                    labelId="accounts.code"
                    defaultLabel="Code"
                  />
                  <TextInput
                    className="text-input-field"
                    type="text"
                    variant="outlined"
                    inputProps={{
                      readOnly: false,
                    }}
                    labelId="common.placeHolderText"
                    defaultLabelId="--- type here ---"
                    Value={values.code}
                    handlechange={(value: string) => {
                      setFieldValue("code", value);
                    }}
                  />
                  <FormikErrorComponent
                    errors={errors}
                    touched={touched}
                    field="code"
                  />
                </Grid>
                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    className="input-label"
                    labelId="assetGroupWizards.type"
                    defaultLabel="Asset Group Type"
                  />

                  <CustomDropdown
                    disableCreate={true}
                    options={getOptions(values.assetGroupTypes)}
                    selectedValue={values.selectedAssetGroupTypeId}
                    onSelectedValueChange={(value) => {
                      const assetTypeIdValue = value as string;
                      setFieldValue(
                        "selectedAssetGroupTypeId",
                        assetTypeIdValue
                      );
                    }}
                  />
                  <FormikErrorComponent
                    errors={errors}
                    touched={touched}
                    field="selectedAssetGroupTypeId"
                  />
                </Grid>
                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    className="input-label"
                    labelId="assetGroupWizards.mapAssetCategory"
                    defaultLabel="Map Asset Category"
                  />

                  <TextInputMap
                    fieldValue={values.selectedAssetCategories.map(
                      (item) => item.name
                    )}
                    buttonCallBackClick={() => setOpenMapModal(true)}
                    // fieldValue={values.}
                  />
                  <FormikErrorComponent
                    errors={errors}
                    touched={touched}
                    field="selectedAssetCategories"
                  />
                </Grid>
                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    className="input-label"
                    labelId="assetGroupWizards.timeZone"
                    defaultLabel="Timezone"
                  />

                  <CustomDropdown
                    disableCreate={true}
                    options={getOptions(values.timezones)}
                    selectedValue={values.selectedTimezoneId}
                    onSelectedValueChange={(value) => {
                      const timeZoneIdValue = value as string;
                      setFieldValue("selectedTimezoneId", timeZoneIdValue);
                    }}
                  />
                  <FormikErrorComponent
                    errors={errors}
                    touched={touched}
                    field="selectedTimezoneId"
                  />
                </Grid>
                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    className="input-label"
                    labelId="assetGroupWizards.status"
                    defaultLabel="Status"
                  />

                  <CustomDropdown
                    disableCreate={true}
                    options={getOptions(values.statusList)}
                    selectedValue={values.selectedStatusId}
                    onSelectedValueChange={(value) => {
                      const statusIdValue = value as string;
                      setFieldValue("selectedStatusId", statusIdValue);
                    }}
                  />
                  <FormikErrorComponent
                    errors={errors}
                    touched={touched}
                    field="selectedStatusId"
                  />
                </Grid>
                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    className="input-label"
                    labelId="assetGroupWizards.owner"
                    defaultLabel="Owned By"
                  />
                  <TextInput
                    className="text-input-field"
                    type="text"
                    variant="outlined"
                    inputProps={{
                      readOnly: false,
                    }}
                    labelId="common.placeHolderText"
                    defaultLabelId="--- type here ---"
                    Value={values.owner}
                    handlechange={(value: string) => {
                      setFieldValue("owner", value);
                    }}
                  />
                </Grid>
                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    className="input-label"
                    labelId="accounts.country"
                    defaultLabel="Country"
                  />

                  <CustomDropdown
                    disableCreate={true}
                    options={getOptions(values.countries, "countryName")}
                    selectedValue={values.selectedCountryId}
                    onSelectedValueChange={(value) => {
                      const countryIdValue = value as string;
                      setFieldValue("selectedCountryId", countryIdValue);
                      setFieldValue("selectedStateId", "");
                      setStateOptions(
                        getStateOptions(
                          values.countries,
                          Number(countryIdValue) || 0
                        )
                      );
                    }}
                  />
                  <FormikErrorComponent
                    errors={errors}
                    touched={touched}
                    field="selectedCountryId"
                  />
                </Grid>
                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    className="input-label"
                    labelId="accounts.state"
                    defaultLabel="State"
                  />

                  <CustomDropdown
                    disableCreate={true}
                    options={getOptions(stateOptions, "stateName")}
                    selectedValue={values.selectedStateId}
                    onSelectedValueChange={(value) => {
                      setFieldValue("selectedStateId", value);
                    }}
                  />
                  <FormikErrorComponent
                    errors={errors}
                    touched={touched}
                    field="selectedStateId"
                  />
                </Grid>
                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    className="input-label"
                    labelId="assetGroupWizards.latitude"
                    defaultLabel="Latitude"
                  />
                  <TextInput
                    className="text-input-field"
                    type="text"
                    variant="outlined"
                    inputProps={{
                      readOnly: false,
                    }}
                    labelId="common.placeHolderText"
                    defaultLabelId="--- type here ---"
                    Value={values.latitude}
                    handlechange={(value: string) => {
                      setFieldValue("latitude", value);
                    }}
                  />
                  <FormikErrorComponent
                    errors={errors}
                    touched={touched}
                    field="latitude"
                  />
                </Grid>
                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    className="input-label"
                    labelId="assetGroupWizards.longitude"
                    defaultLabel="Longitude"
                  />
                  <TextInput
                    className="text-input-field"
                    type="text"
                    variant="outlined"
                    inputProps={{
                      readOnly: false,
                    }}
                    labelId="common.placeHolderText"
                    defaultLabelId="--- type here ---"
                    Value={values.longitude}
                    handlechange={(value: string) => {
                      setFieldValue("longitude", value);
                    }}
                  />
                  <FormikErrorComponent
                    errors={errors}
                    touched={touched}
                    field="longitude"
                  />
                </Grid>
                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    className="input-label"
                    labelId="assetGroupWizards.siteImage"
                    defaultLabel="Site Image"
                  />
                  <FileUploadInput
                    fileCategory="image"
                    labelId="accounts.uploadPlaceholder"
                    defaultLabel="--- upload ---"
                    value={
                      values.assetGroupImage !== null &&
                      values.assetGroupImage !== undefined
                        ? values?.assetGroupImage.fileName
                        : ""
                    }
                    handleChange={(value: DocumentTypeBean) =>
                      setFieldValue("assetGroupImage", value)
                    }
                    url={values.sitePhoto !== null ? values.sitePhoto : ""}
                    reset={false}
                    cardEdit={true}
                  />
                </Grid>

                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    className="input-label"
                    labelId="assetGroupWizards.dcCapacity"
                    defaultLabel="DC Capacity (MWp)"
                  />
                  <TextInput
                    className="text-input-field"
                    type="text"
                    variant="outlined"
                    inputProps={{
                      readOnly: false,
                    }}
                    labelId="common.placeHolderText"
                    defaultLabelId="--- type here ---"
                    Value={
                      values.dcCapacity !== null && values.dcCapacity !== 0
                        ? String(values.dcCapacity)
                        : ""
                    }
                    handlechange={(value: string) => {
                      const formattedValue = value.replace(/[^0-9]/g, "");
                      setFieldValue(
                        "dcCapacity",
                        formattedValue ? parseInt(formattedValue) : 0
                      );
                    }}
                  />
                  <FormikErrorComponent
                    errors={errors}
                    touched={touched}
                    field="dcCapacity"
                  />
                </Grid>
                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    className="input-label"
                    labelId="assetGroupWizards.acCapacity"
                    defaultLabel="AC Capacity (MW)"
                  />
                  <TextInput
                    className="text-input-field"
                    type="text"
                    variant="outlined"
                    inputProps={{
                      readOnly: false,
                    }}
                    labelId="common.placeHolderText"
                    defaultLabelId="--- type here ---"
                    Value={
                      values.acCapacity !== null && values.acCapacity !== 0
                        ? String(values.acCapacity)
                        : ""
                    }
                    handlechange={(value: string) => {
                      const formattedValue = value.replace(/[^0-9]/g, "");
                      setFieldValue(
                        "acCapacity",
                        formattedValue ? parseInt(formattedValue) : 0
                      );
                    }}
                  />
                  <FormikErrorComponent
                    errors={errors}
                    touched={touched}
                    field="acCapacity"
                  />
                </Grid>
                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    className="input-label"
                    labelId="assetGroupWizards.epcContractor"
                    defaultLabel="EPC Contractor"
                  />

                  <CustomDropdown
                    disableCreate={true}
                    options={getOptions(values.epcContractors)}
                    selectedValue={values.selectedEpcContractorId}
                    onSelectedValueChange={(value) => {
                      const EPCIdValue = value as string;
                      setFieldValue("selectedEpcContractorId", EPCIdValue);
                    }}
                  />
                </Grid>
                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    labelId="assetGroupWizards.dateofCommission"
                    defaultLabel="Date of Commission"
                    textColor={"rgba(159, 173, 188, 1)"}
                    className="mb-sm"
                  />

                  <DateTimePickerComponent
                    value={
                      values.dateOfCommission !== null
                        ? dayjs(values.dateOfCommission)
                        : null
                    }
                    labelid="common.placeHolderTextDate"
                    defaultlabelid="---dd/mm/yyyy---"
                    handlechange={(date: Dayjs) => {
                      const formattedDate = dayjs(date).format("YYYY-MM-DD");
                      setFieldValue("dateOfCommission", formattedDate);
                    }}
                    disabledDate={false}
                    inputFormat="DD MMM YYYY"
                    textWidth="450px"
                    width="450px"
                    views={["year", "month", "day"]}
                  />
                </Grid>

                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    labelId="assetGroupWizards.efficiency"
                    defaultLabel="Effective Date"
                    textColor={"rgba(159, 173, 188, 1)"}
                    className="mb-sm"
                  />

                  <DateTimePickerComponent
                    value={
                      values.effectiveDate !== null
                        ? dayjs(values.effectiveDate)
                        : null
                    }
                    labelid="common.placeHolderTextDate"
                    defaultlabelid="---dd/mm/yyyy---"
                    handlechange={(date: Dayjs) => {
                      const formattedDate = dayjs(date).format("YYYY-MM-DD");
                      setFieldValue("effectiveDate", formattedDate);
                    }}
                    disabledDate={false}
                    inputFormat="DD MMM YYYY"
                    textWidth="450px"
                    width="450px"
                    views={["year", "month", "day"]}
                  />
                </Grid>
                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    labelId="assetGroupWizards.mobility"
                    defaultLabel="Mobilization  Date"
                    textColor={"rgba(159, 173, 188, 1)"}
                    className="mb-sm"
                  />

                  <DateTimePickerComponent
                    value={
                      values.mobilizationDate !== null
                        ? dayjs(values.mobilizationDate)
                        : null
                    }
                    labelid="common.placeHolderTextDate"
                    defaultlabelid="---dd/mm/yyyy---"
                    handlechange={(date: Dayjs) => {
                      const formattedDate = dayjs(date).format("YYYY-MM-DD");
                      setFieldValue("mobilizationDate", formattedDate);
                    }}
                    disabledDate={false}
                    inputFormat="DD MMM YYYY"
                    textWidth="450px"
                    width="450px"
                    views={["year", "month", "day"]}
                  />
                </Grid>
                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    className="input-label"
                    labelId="assetGroupWizards.planttype"
                    defaultLabel="Plant Type"
                  />

                  <CustomDropdown
                    disableCreate={true}
                    options={getOptions(values.plantTypes)}
                    selectedValue={values.selectedPlantTypeId}
                    onSelectedValueChange={(value) => {
                      const assetTypeIdValue = value as string;
                      setFieldValue("selectedPlantTypeId", assetTypeIdValue);
                    }}
                  />
                </Grid>
                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    className="input-label"
                    labelId="assetGroupWizards.plannedCycles"
                    defaultLabel="Planned Cycle Threshold Days"
                  />
                  <TextInput
                    className="text-input-field"
                    type="text"
                    variant="outlined"
                    inputProps={{
                      readOnly: false,
                    }}
                    labelId="common.placeHolderText"
                    defaultLabelId="--- type here ---"
                    Value={
                      values.plannedCycleThresholdDays !== null &&
                      values.plannedCycleThresholdDays !== 0
                        ? String(values.plannedCycleThresholdDays)
                        : ""
                    }
                    handlechange={(value: string) => {
                      const formattedValue = value.replace(/[^0-9]/g, "");
                      setFieldValue(
                        "plannedCycleThresholdDays",
                        formattedValue ? parseInt(formattedValue) : 0
                      );
                    }}
                  />
                  <FormikErrorComponent
                    errors={errors}
                    touched={touched}
                    field="plannedCycleThresholdDays"
                  />
                </Grid>
                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    className="input-label"
                    labelId="assetGroupWizards.woMoveThreshold"
                    defaultLabel="WO Move Threshold"
                  />
                  <TextInput
                    className="text-input-field"
                    type="text"
                    variant="outlined"
                    inputProps={{
                      readOnly: false,
                    }}
                    labelId="common.placeHolderText"
                    defaultLabelId="--- type here ---"
                    Value={
                      values.woMoveThreshold !== null &&
                      values.woMoveThreshold !== 0
                        ? String(values.woMoveThreshold)
                        : ""
                    }
                    handlechange={(value: string) => {
                      const formattedValue = value.replace(/[^0-9]/g, "");
                      setFieldValue(
                        "woMoveThreshold",
                        formattedValue ? parseInt(formattedValue) : 0
                      );
                    }}
                  />
                  <FormikErrorComponent
                    errors={errors}
                    touched={touched}
                    field="woMoveThreshold"
                  />
                </Grid>
                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    className="input-label"
                    labelId="assetGroupWizards.spvname"
                    defaultLabel="SPV Name"
                  />
                  <TextInput
                    className="text-input-field"
                    type="text"
                    variant="outlined"
                    inputProps={{
                      readOnly: false,
                    }}
                    labelId="common.placeHolderText"
                    defaultLabelId="--- type here ---"
                    Value={values.spvName}
                    handlechange={(value: string) => {
                      setFieldValue("spvName", value);
                    }}
                  />
                </Grid>
                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    labelId="assetGroupWizards.assetGroupArea"
                    defaultLabel="Asset Group Area"
                    className="input-label"
                  />
                  <TextInput
                    labelId="common.placeHolderText"
                    defaultLabelId="--- type here ---"
                    className="text-input-field"
                    type="text"
                    variant="outlined"
                    inputProps={{
                      readOnly: false,
                    }}
                    Value={
                      values.assetGroupArea !== null &&
                      values.assetGroupArea !== 0
                        ? String(values.assetGroupArea)
                        : ""
                    }
                    handlechange={(value: string) => {
                      let formattedValue = value.replace(/[^0-9.]/g, "");

                      // Then, format the string to match the pattern "up to 4 digits, optional decimal, up to 2 digits after the decimal"
                      formattedValue = formattedValue.replace(
                        /^(\d{0,4})(\.\d{0,2})?.*$/,
                        (match, p1, p2) => p1 + (p2 || "") // p1 is the first group (digits), p2 is the second group (decimal and digits)
                      );
                      setFieldValue("assetGroupArea", formattedValue);
                    }}
                  />
                  <FormikErrorComponent
                    errors={errors}
                    touched={touched}
                    field="assetGroupArea"
                  />
                </Grid>
                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    className="input-label"
                    labelId="assetGroupWizards.contactReference"
                    defaultLabel="Contact Reference"
                  />
                  <TextInput
                    className="text-input-field"
                    type="text"
                    variant="outlined"
                    inputProps={{
                      readOnly: false,
                    }}
                    labelId="common.placeHolderText"
                    defaultLabelId="--- type here ---"
                    Value={values.contactReference}
                    handlechange={(value: string) => {
                      setFieldValue("contactReference", value);
                    }}
                  />
                </Grid>
                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    className="input-label"
                    labelId="assetGroupWizards.contactPersonName"
                    defaultLabel="Contact Person"
                  />
                  <TextInput
                    className="text-input-field"
                    type="text"
                    variant="outlined"
                    inputProps={{
                      readOnly: false,
                    }}
                    labelId="common.placeHolderText"
                    defaultLabelId="--- type here ---"
                    Value={values.contactPersonFirstName}
                    handlechange={(value: string) => {
                      setFieldValue("contactPersonFirstName", value);
                    }}
                  />
                </Grid>
                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    className="input-label"
                    labelId="assetGroupWizards.email"
                    defaultLabel="Contact Person Email"
                  />
                  <TextInput
                    className="text-input-field"
                    type="text"
                    variant="outlined"
                    inputProps={{
                      readOnly: false,
                    }}
                    labelId="common.placeHolderText"
                    defaultLabelId="--- type here ---"
                    Value={values.email}
                    handlechange={(value: string) => {
                      setFieldValue("email", value);
                    }}
                  />
                </Grid>
                <Grid item {...muiColumnProps}>
                  <MediumTypography
                    className="input-label"
                    labelId="assetGroupWizards.phone"
                    defaultLabel="Contact Person Phone"
                  />
                  <PhoneNumberInput
                    value={`${values.countryCode || ""}${
                      values.phoneNumber || ""
                    }`}
                    onChange={(value: string, countryData: any) => {
                      const countryCode = countryData.dialCode; // Get the country code
                      const phoneNumber = value.startsWith(countryCode)
                        ? value.slice(countryCode.length) // Remove country code from the start of the value
                        : value; // Use the value directly if no country code prefix

                      setFieldValue("countryCode", countryCode);
                      setFieldValue("phoneNumber", phoneNumber);
                    }}
                    labelId="common.placeHolderText"
                    defaultLabelId="--- type here ---"
                    disabled={false}
                  />
                </Grid>
              </Grid>
              <Box className=" flex__ p-md" sx={{ justifyContent: "end" }}>
                <ButtonComponent
                  className={"btn-primary btn-submit mr-md"}
                  defaultLabelId={"Save"}
                  labelId={assetGroupId !== 0 ? "btn.update" : "btn.Add"}
                  onClick={() => {
                    handleSubmit();
                  }}
                  sxProps={{
                    cursor: "pointer",
                  }}
                />
              </Box>
            </Box>
          );
        }}
      </Formik>
    </Box>
  );
};

export default AssetGroupWizards;
