import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Popover,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { FC, useCallback, useContext, useMemo, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { styled } from "@mui/material/styles";
import { ReactComponent as CalendarIcon } from "../../assets/images/calendarIcon.svg";
import ButtonComponent from "./ButtonComponent";
import { ShiftCardTypes } from "../../utils/type";
import moment from "moment";
import {
  generateReport,
  moveScheduleOrWorkOrderApi,
} from "../../api/workOrderApi/schedulerApi/Scheduler";
import MediumTypography from "./MediumTypography";
import ErrorModal from "./modal/ErrorModal";

import { ReactComponent as CalendarBackground } from "../../assets/images/CalendarBackground.svg";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ModalPopup from "./modal/ModalPopup";
import { CustomMenu } from "./CustomMenu";
import { handleError } from "../../utils/commonFunctions";
import { useIntl } from "react-intl";
import { LoaderContext, LoaderContextType } from "../../layouts/appSidebar";
import SOPModal from "./modal/SOPModal";

export interface SimpleCardType {
  cardId: number;
  sequenceId: number;
  scheduledDate?: string;
  version?: number;
}

export interface SchedulerCalendarProps {
  selectedDate: Date;
  cardDetails: ShiftCardTypes | SimpleCardType;
  scheduleMoveCallBack: () => void;
  type: string;
  shiftStartTime?: string;
  shiftEndTime?: string;
  code?: string;
}

const CustomDateCalendar = styled(DateCalendar)(({ theme }) => ({
  "& .MuiPickersDay-root": {
    color: "rgba(159, 173, 188, 1)", // Default color of dates
  },
  "& .MuiPickersCalendarHeader-dayLabel": {
    color: "rgba(159, 173, 188, 1)", // Color for weekday labels
  },
  "& .MuiPickersArrowSwitcher-button": {
    color: "rgba(159, 173, 188, 1)", // Color for navigation arrows
  },
  "& .MuiPickersCalendarHeader-switchViewButton": {
    color: "rgba(159, 173, 188, 1)",
  },
  "& .MuiDayCalendar-weekDayLabel": {
    color: "rgba(159, 173, 188, 1)",
  },
  "& .MuiPickersCalendarHeader-switchHeader button": {
    color: "rgba(159, 173, 188, 1)", // Color for navigation arrows
  },
  "& .MuiPickersDay-root.Mui-selected": {
    backgroundColor: "#1976d2", // Background color for selected dates
    color: "rgba(159, 173, 188, 1)", // Color for selected dates
  },
  "& .MuiPickersDay-root:hover": {
    backgroundColor: "#1976d2", // Background color when hovering over dates
    color: "rgba(159, 173, 188, 1)", // Color when hovering over dates
  },
  "& .MuiPickersDay-root:disabled": {
    color: "rgba(191, 219, 248, 0.28)",
    opacity: "1 ",
    "-webkit-text-fill-color": "rgba(191, 219, 248, 0.28) ",
  },
}));

const SchedulerCalendar: FC<SchedulerCalendarProps> = ({
  selectedDate,
  cardDetails,
  scheduleMoveCallBack,
  type,
  shiftStartTime,
  shiftEndTime,
  code,
}) => {
  const currentDate: Dayjs = dayjs(new Date());
  const [actualDate, setActualDate] = useState<Dayjs>(dayjs(new Date()));
  const [message, setMessage] = React.useState<string>("");
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [errorDesc, setErrorDesc] = useState<string>("");
  const MemorizedCustomMenu = useMemo(() => CustomMenu, []);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;

  const [schedulerAnchorEl, setSchedulerAnchorEl] =
    useState<null | HTMLElement>(null);
  const openitScheduler = Boolean(schedulerAnchorEl);

  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const openitMenu = Boolean(menuAnchorEl);
  const [fileUrl, setFileUrl] = useState<string | null>(null);
  const [openTemplateModal, setOpenTemplateModal] = useState(false);

  const intl = useIntl();

  const handleDateChange = (newDate: Dayjs | null) => {
    if (newDate !== null) {
      setActualDate(newDate);
    }
  };

  const handleSchedulerDropdown = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setSchedulerAnchorEl(event.currentTarget);
    },
    []
  );
  const handleSchedulerDropdownClose = useCallback(() => {
    setSchedulerAnchorEl(null);
    handleMenuClose();
  }, []);

  const handleMenuOpen = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget);
  }, []);
  const handleMenuClose = useCallback(() => {
    setMenuAnchorEl(null);
  }, []);

  const generateReportForWorkOrder = () => {
    toggleLoader(true);
    if (cardDetails.cardId) {
      generateReport(cardDetails.cardId)
        .then((response) => {
          setOpenTemplateModal(true);
          setFileUrl(response.encodedS3Url);
          toggleLoader(false);
        })
        .catch((err) => {
          setOpenErrorModal(true);
          handleError(err, setErrorDesc);
          toggleLoader(false);
        });
    }
  };

  const handleMove = () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Normalize today's date to ignore time component
    selectedDate.setHours(0, 0, 0, 0); // Normalize selectedDate to ignore time component

    let action: "PREPONE" | "POSTPONE" = "POSTPONE";

    if (type === "SCHEDULE") {
      if (selectedDate.getTime() === today.getTime()) {
        action = "POSTPONE";
      } else if (selectedDate.getTime() < today.getTime()) {
        action = "PREPONE";
      } else if (selectedDate.getTime() > today.getTime()) {
        action = "POSTPONE";
      }
    }

    let moveObj = {
      uniqueId: cardDetails.sequenceId,
      moveFrom: moment(cardDetails.scheduledDate).format("YYYY-MM-DD"),
      moveTo: actualDate.format("YYYY-MM-DD"),
      comment: message,
      version: cardDetails.version ? cardDetails.version : 0,
    };

    moveScheduleOrWorkOrderApi(cardDetails.cardId, action, type, moveObj)
      .then(() => {
        scheduleMoveCallBack();
        handleSchedulerDropdownClose();
      })
      .catch((err) => {
        setOpenErrorModal(true);
        handleError(err, setErrorDesc);
        handleSchedulerDropdownClose();
      });
  };

  const validationsCheck = () => {
    if (selectedDate) {
      const currentDateTime = moment();
      const selectedDateFormatted = dayjs(actualDate).format("YYYY-MM-DD");
      const currentDayFormatted = dayjs(new Date()).format("YYYY-MM-DD");

      // Check if the selected date is today
      if (selectedDateFormatted === currentDayFormatted) {
        const shiftEndDateTime = moment().set({
          hour: moment(shiftEndTime, "HH:mm").get("hour"),
          minute: moment(shiftEndTime, "HH:mm").get("minute"),
          second: 0,
          millisecond: 0,
        });
        if (currentDateTime.isAfter(shiftEndDateTime)) {
          setIsModalOpen(!isModalOpen);

          return;
        } else {
          handleMove();
        }
      } else {
        handleMove();
      }
    } else {
      handleMove();
    }
  };

  return (
    <Box>
      {isModalOpen && (
        <ModalPopup
          descriptionText={"ShiftEndTimePassedLabelText"}
          open={isModalOpen}
          handleClose={() => setIsModalOpen(!isModalOpen)}
          onPositiveClick={() => {
            handleMove();
            setIsModalOpen(!isModalOpen);
          }}
          onNegativeClick={() => {
            setIsModalOpen(!isModalOpen);
            handleSchedulerDropdownClose();
          }}
          positiveButtonLabel="Yes"
          positiveButtonLabelId="YesText"
          negativeButtonLabel="No"
          negativeButtonLabelId="NoText"
        />
      )}
      {openTemplateModal && (
        <SOPModal
          open={openTemplateModal}
          handleClose={() => setOpenTemplateModal(false)}
          fileUrl={fileUrl}
        />
      )}
      {openErrorModal && (
        <ErrorModal
          descriptionText={errorDesc}
          open={openErrorModal}
          handleClose={() => setOpenErrorModal(false)}
          onPositiveClick={() => {
            setOpenErrorModal(false);
          }}
        />
      )}
      {type === "SCHEDULE" && (
        <Box onClick={handleSchedulerDropdown}>
          <CalendarBackground className="cursor__pointer" />
        </Box>
      )}
      {type === "WORKORDER" && (
        <Box onClick={handleMenuOpen}>
          <MoreVertIcon className="cursor__pointer" />
        </Box>
      )}
      <Popover
        anchorEl={menuAnchorEl}
        open={openitMenu}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: "#2B3033",
          },
        }}
      >
        <MenuItem
          onClick={handleSchedulerDropdown}
          style={{
            backgroundColor: "2B3033",
          }}
        >
          <MediumTypography labelId="btn.Move" defaultLabel="Move" />
        </MenuItem>
        {(code === "CM" || code === "PM") && (
          <Tooltip
            title={intl.formatMessage({
              id: "btn.DownloadTemplate",
              defaultMessage: "Download Template",
            })}
          >
            <MenuItem
              onClick={generateReportForWorkOrder}
              style={{ backgroundColor: "2B3033" }}
            >
              <MediumTypography
                labelId="btn.download"
                defaultLabel="Download"
              />
            </MenuItem>
          </Tooltip>
        )}
      </Popover>
      <MemorizedCustomMenu
        id="demo-customized-menu"
        anchorEl={schedulerAnchorEl}
        open={openitScheduler}
        minWidth={400}
        maxWidth={400}
        onClose={handleSchedulerDropdownClose}
        sx={{
          width: "500px",
          padding: "0px",
        }}
      >
        <Box
          component="main"
          sx={{ minWidth: "300px", display: "flex", flexDirection: "column" }}
        >
          <MediumTypography
            labelId="FromText"
            defaultLabel="From"
            textColor="rgba(159, 173, 188, 1)"
            fontSize="14px"
            fontWeight={500}
          />
          <Box sx={{ my: "10px" }}>
            <TextField
              variant="outlined"
              sx={{
                backgroundColor: "#1D2226",
                borderRadius: "4px",
                border: "1px solid rgba(166, 197, 226, 0.16)",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "1px solid rgba(166, 197, 226, 0.16)",
                  },
                  width: "350px",
                  height: "40px",
                  "&:hover fieldset": {
                    border: "1px solid rgba(166, 197, 226, 0.16)",
                  },
                  "&.Mui-focused fieldset": {
                    border: "1px solid rgba(166, 197, 226, 0.16)",
                  },
                  "& input": {
                    color: "rgba(140, 155, 171, 0.5)",
                  },
                  "&.Mui-disabled input": {
                    color: "rgba(140, 155, 171, 0.5) ",
                    opacity: "1 ",
                    "-webkit-text-fill-color": "rgba(140, 155, 171, 0.5) ",
                  },
                },
                "& .MuiInputLabel-root": {
                  color: "rgba(140, 155, 171, 0.5)",
                  "&.Mui-disabled": {
                    color: "rgba(140, 155, 171, 0.5) ",
                  },
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end">
                      <CalendarIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              value={
                cardDetails.scheduledDate
                  ? cardDetails.scheduledDate
                  : currentDate.format("YYYY-MM-DD")
              }
              disabled={true}
            />
          </Box>

          <MediumTypography
            labelId="ToText"
            defaultLabel="To"
            textColor="rgba(159, 173, 188, 1)"
            fontSize="12px"
            fontWeight={500}
          />
          <Box sx={{ mt: "10px" }}>
            <TextField
              variant="outlined"
              sx={{
                backgroundColor: "#1D2226",
                borderRadius: "4px",

                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "1px solid rgba(166, 197, 226, 0.16)",
                  },
                  width: "350px",
                  height: "40px",
                  "&:hover fieldset": {
                    border: "1px solid rgba(166, 197, 226, 0.16)",
                  },
                  "&.Mui-focused fieldset": {
                    border: "1px solid rgba(166, 197, 226, 0.16)",
                  },
                  "& input": {
                    color: "rgba(159, 173, 188, 1)",
                  },
                },
                "& .MuiInputLabel-root": {
                  color: "rgba(159, 173, 188, 1)",
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end">
                      <CalendarIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              value={actualDate.format("YYYY-MM-DD")}
            />
          </Box>
          <Box
            sx={{ mt: "10px", border: "1px solid rgba(166, 197, 226, 0.16)" }}
            display={"flex"}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <CustomDateCalendar
                value={actualDate}
                minDate={dayjs(new Date())}
                onChange={handleDateChange}
                sx={{ color: "rgba(159, 173, 188, 1)" }}
              />
            </LocalizationProvider>
          </Box>

          <Box sx={{ mt: "10px" }}>
            <TextField
              variant="outlined"
              sx={{
                backgroundColor: "#1D2226",
                borderRadius: "4px",
                border: "1px solid rgba(166, 197, 226, 0.16)",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "1px solid rgba(166, 197, 226, 0.16)",
                  },
                  width: "350px",
                  height: "60px",
                  "&:hover fieldset": {
                    border: "1px solid rgba(166, 197, 226, 0.16)",
                  },
                  "&.Mui-focused fieldset": {
                    border: "1px solid rgba(166, 197, 226, 0.16)",
                  },
                  "& input": {
                    color: "#fff", // Text color inside the input
                  },
                },
                "& .MuiInputLabel-root": {
                  color: "rgba(159, 173, 188, 1)", // Placeholder color
                },
              }}
              placeholder="Type to record your comment"
              value={message}
              autoComplete="off"
              onChange={(event) => {
                setMessage(event.target.value.trimStart());
              }}
            />
          </Box>
        </Box>
        <Box component="main">
          <Grid
            className="flex__ justifyContent-FlexEnd pt-md "
            container
            direction="row"
            alignItems="right"
          >
            <Grid item>
              <ButtonComponent
                sxProps={{ color: "#B6C2CF !important" }}
                className="btn-primary btn-cancel "
                variantType="contained"
                defaultLabelId="Cancel"
                labelId="CancelBtnText"
                onClick={handleSchedulerDropdownClose}
              />
            </Grid>
            <Grid item>
              <ButtonComponent
                className="btn-confirm "
                variantType="contained"
                defaultLabelId="Submit"
                labelId="SubmitText"
                sxProps={{
                  textTransform: "none",
                  fontFamily: "Inter",
                }}
                onClick={validationsCheck}
              />
            </Grid>
          </Grid>
        </Box>
      </MemorizedCustomMenu>
    </Box>
  );
};

export default SchedulerCalendar;
