// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#dayPaination {
  border-radius: 4px;
  background: rgba(161, 189, 217, 0.08);
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
}

#dayPaination button.MuiButton-root {
  background-color: transparent;
  border: 1px;
  padding: 6px;
  margin: 3px 0px;
  border-radius: 3px;
  text-transform: capitalize;
}

#dayPaination button.active {
  background: rgba(37, 186, 250, 0.16);
  color: rgba(37, 186, 250, 1);
  font-weight: 600;
}

#dayPaination button.arrow-btn {
  width: 30px;
}
`, "",{"version":3,"sources":["webpack://./src/components/formlib/DayPagination.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,qCAAqC;EACrC,kBAAkB;EAClB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,6BAA6B;EAC7B,WAAW;EACX,YAAY;EACZ,eAAe;EACf,kBAAkB;EAClB,0BAA0B;AAC5B;;AAEA;EACE,oCAAoC;EACpC,4BAA4B;EAC5B,gBAAgB;AAClB;;AAEA;EACE,WAAW;AACb","sourcesContent":["#dayPaination {\n  border-radius: 4px;\n  background: rgba(161, 189, 217, 0.08);\n  font-family: Inter;\n  font-size: 14px;\n  font-weight: 500;\n}\n\n#dayPaination button.MuiButton-root {\n  background-color: transparent;\n  border: 1px;\n  padding: 6px;\n  margin: 3px 0px;\n  border-radius: 3px;\n  text-transform: capitalize;\n}\n\n#dayPaination button.active {\n  background: rgba(37, 186, 250, 0.16);\n  color: rgba(37, 186, 250, 1);\n  font-weight: 600;\n}\n\n#dayPaination button.arrow-btn {\n  width: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
