import { Box } from "@mui/material";
import MediumTypography from "../../../components/formlib/MediumTypography";
import { FC, useEffect, useState } from "react";
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from "react-beautiful-dnd";
import TeamMembersComponent from "../../../components/formlib/TeamMemberComponent";
import ButtonComponent from "../../../components/formlib/ButtonComponent";
import CheckBox from "../../../components/formlib/CheckBox";
import { TechnicianType } from "../../../utils/type";
import { ReactComponent as DragIcon } from "../../../assets/images/DragIcon.svg";
import { ReactComponent as Cancel } from "../../../assets/images/Cancel.svg";
import { shiftDetailsType } from "./types";
import useAvailableHeight from "../../../utils/useAvailableHeight";
import useAvailableWidth from "../../../utils/useAvailableWidth";

export interface ShiftAssetDetailsType {
  shiftId: number;
  shiftName: string;
  shiftStartTime: string;
  shiftEndTime: string;
  memberCount: number;
  shiftStaticName: string;
  teams: TechnicianType[];
}
export interface AssetType {
  id: number;
  sequence: number;
  name: string;
}
export interface CMSetType {
  setId: number;
  assignedUsers: AssignedUsers[];
  assets: AssetType[];
}

export interface AssignedUsers {
  assigneeId: number;
  isLeadAssignee: boolean;
  isPrimaryAssignee: boolean;
}
export interface AssigneeType {
  shift: ShiftAssetDetailsType;
  sets: CMSetType[];
}

export interface AssigneeProps {
  assigneeDetails: AssigneeType;
  onApply: (assignee: AssigneeType) => void;
  originialShiftData: shiftDetailsType[];
  skillsOriginalShiftData: shiftDetailsType[];
  selectedShiftId: number;
  skillsChecked: boolean;
  onTeamLengthUpdate: (value: number) => void;
}
const SetAssetsAssignee: FC<AssigneeProps> = ({
  assigneeDetails,
  onApply,
  originialShiftData,
  skillsOriginalShiftData,
  selectedShiftId,
  skillsChecked,
  onTeamLengthUpdate,
}) => {
  const [manageAssignee, setManageAssignee] = useState<AssigneeType | null>(
    null
  );
  const [checkedAssets, setCheckedAssets] = useState<Record<string, boolean>>(
    {}
  );

  const availableHeight = useAvailableHeight(390);
  const availableWidth = useAvailableWidth(150);
  const assetListHeight = useAvailableHeight(530);
  const autoWidth = useAvailableWidth(460);
  useEffect(() => {
    setManageAssignee(assigneeDetails);
  }, [assigneeDetails]);

  let filteredTeamsArray: TechnicianType[] = [];
  if (manageAssignee?.shift && manageAssignee?.sets) {
    const assignedTeamMemberIds = manageAssignee.sets.flatMap((set) =>
      set.assignedUsers.map((user) => user.assigneeId)
    );
    const filteredTeams =
      manageAssignee &&
      manageAssignee?.shift.teams.filter(
        (team) => !assignedTeamMemberIds.includes(team.id)
      );

    filteredTeamsArray.push(...filteredTeams);
  }

  let assignedTeams: TechnicianType[] = [];

  if (manageAssignee?.shift && manageAssignee?.sets) {
    const assignedMembers = manageAssignee?.shift.teams.filter((team) =>
      manageAssignee?.sets.some((set) =>
        set.assignedUsers.some((user) => user.assigneeId === team.id)
      )
    );

    assignedTeams.push(...assignedMembers);
  }

  const handleRemove = (setId: number, userId: number) => {
    if (manageAssignee) {
      const setIndex = manageAssignee?.sets.findIndex(
        (set) => set.setId === setId
      );
      const assigneeId = manageAssignee?.sets[setIndex].assignedUsers.find(
        (user) => user.assigneeId === userId
      )?.assigneeId;

      // Set assigneeId of the set to null
      let updatedSets = [...manageAssignee.sets];
      updatedSets[setIndex].assignedUsers = updatedSets[
        setIndex
      ].assignedUsers.filter((user) => user.assigneeId !== userId);

      // Remove the assignee from the assigned list and add it back to the teams array
      const removedAssignee = assignedTeams.findIndex(
        (member) => member.id === assigneeId
      );

      if (removedAssignee) {
        assignedTeams.splice(removedAssignee, 1);
      }

      const newlyCreatedSet = updatedSets[updatedSets.length - 1];
      if (
        newlyCreatedSet &&
        newlyCreatedSet.assignedUsers.length === 0 &&
        newlyCreatedSet.assets.length === 0 &&
        setIndex !== updatedSets.length - 1 &&
        updatedSets[setIndex].assignedUsers.length === 0
      ) {
        // Remove all empty sets after the current set
        updatedSets = updatedSets.filter(
          (set, index) =>
            index <= setIndex ||
            set.assignedUsers.length > 0 ||
            set.assets.length > 0
        );
      }

      // Update the teams array with the original shift data if skillsChecked is true and the removed assignee is the first set assignee
      if (skillsChecked && setIndex === 0) {
        const originalShiftTeams = skillsOriginalShiftData
          .filter((team) => team.shiftId === selectedShiftId)
          .reduce(
            (acc: TechnicianType[], data) => acc.concat(data.shiftMembers),
            []
          );

        setManageAssignee({
          ...manageAssignee,
          shift: {
            ...manageAssignee.shift,
            teams: originalShiftTeams,
          },
          sets: updatedSets,
        });
        onTeamLengthUpdate(originalShiftTeams.length);
      } else {
        // Update the state without changing the teams array
        setManageAssignee({
          ...manageAssignee,
          sets: updatedSets,
        });
        onTeamLengthUpdate(manageAssignee.shift.teams.length);
      }
    }
  };
  const isAssignButtonDisabled = (sets: CMSetType[]) => {
    return sets.some(
      (set) =>
        (set.assets.length > 0 &&
          (set.assignedUsers.length === 0 ||
            set.assignedUsers[0].assigneeId === 0)) ||
        (set.assets.length === 0 &&
          set.assignedUsers.length > 0 &&
          set.assignedUsers[0].assigneeId !== 0)
    );
  };
  const moveAssetBetweenSets = (
    sourceSetId: number,
    assetId: string,
    destSetId: number
  ) => {
    setManageAssignee((prevState) => {
      // If there's no previous state, just return null
      if (!prevState) return null;

      // Create a deep copy of the sets to avoid direct state mutations
      const newSets = prevState.sets.map((set) => ({
        ...set,
        assets: set.assets.slice(), // Create a shallow copy of the assets array
      }));

      // Find the source and destination sets using their IDs
      const sourceSet = newSets.find((set) => set.setId === sourceSetId);
      const destinationSet = newSets.find((set) => set.setId === destSetId);

      // If both sets are found, move the asset
      if (sourceSet && destinationSet) {
        // Remove the asset from the source set
        const removedAsset = sourceSet.assets.find(
          (asset) => asset.id.toString() === assetId
        );
        if (removedAsset) {
          sourceSet.assets = sourceSet.assets.filter(
            (asset) => asset.id.toString() !== assetId
          );
          // Add the removed asset to the destination set
          destinationSet.assets.push(removedAsset);
        }
      }

      // Return the new state with updated sets
      return {
        ...prevState,
        sets: newSets,
      };
    });
  };

  const toggleCheckStatus = (memberId: string) => {
    setCheckedAssets((prevCheckedAssets) => ({
      ...prevCheckedAssets,
      [memberId]: !prevCheckedAssets[memberId],
    }));
  };

  const assignTeamMemberToSet = (teamMemberId: number, setId: number) => {
    setManageAssignee((prevManageAssignee) => {
      if (!prevManageAssignee) return null;

      // Clone the previous state to avoid direct mutations
      let newManageAssignee: AssigneeType = {
        ...prevManageAssignee,
        sets: [...prevManageAssignee.sets], // Clone the sets array
      };
      const targetSet = newManageAssignee.sets.find(
        (set) => set.setId === setId
      );

      if (targetSet) {
        // Create a new assigned user object
        const newAssignedUser: AssignedUsers = {
          assigneeId: teamMemberId,
          isLeadAssignee: false,
          isPrimaryAssignee: false,
        };

        // Add the new assigned user to the target set's assigned users array
        targetSet.assignedUsers.push(newAssignedUser);

        if (skillsChecked) {
          newManageAssignee.shift.teams = originialShiftData
            .filter((team) => team.shiftId === selectedShiftId)
            .reduce(
              (acc: TechnicianType[], data) => acc.concat(data.shiftMembers),
              []
            );
        }

        //Check if there already exists an empty set after all existing sets, if not, add a new one
        const emptySetExists = newManageAssignee.sets.some(
          (set) => set.assignedUsers.length === 0
        );
        const maxCount = 5;
        if (!emptySetExists && maxCount > newManageAssignee.sets.length) {
          // Create a new, empty set with a new setId
          const newSet: CMSetType = {
            setId: newManageAssignee.sets.length,
            assignedUsers: [],
            assets: [],
          };

          newManageAssignee.sets.push(newSet);
        }
      }
      onTeamLengthUpdate(newManageAssignee.shift.teams.length);

      return newManageAssignee;
    });
  };
  const moveAssetWithinSet = (
    setId: number,
    assetId: string,
    destIndex: number
  ) => {
    setManageAssignee((prevState) => {
      if (!prevState) return prevState;

      // Create a deep copy of the sets to avoid direct state mutations
      const newSets = prevState.sets.map((set) => ({
        ...set,
        assets: set.assets.slice(), // Create a shallow copy of the assets array
      }));

      // Find the set using its ID
      const currentSet = newSets.find((set) => set.setId === setId);

      // If the set is found, reorder the asset
      if (currentSet) {
        const assetIndex = currentSet.assets.findIndex(
          (asset) => asset.id.toString() === assetId
        );
        if (assetIndex !== -1) {
          const [removedAsset] = currentSet.assets.splice(assetIndex, 1);
          currentSet.assets.splice(destIndex, 0, removedAsset);
        }
      }

      // Return the new state with updated sets
      return {
        ...prevState,
        sets: newSets,
      };
    });
  };

  const handleDragEnd = (result: DropResult) => {
    const { source, destination, draggableId } = result;

    if (!destination) {
      return;
    }

    if (
      source.droppableId.startsWith("technicians-list") &&
      destination.droppableId.startsWith("assignee/")
    ) {
      const teamMemberId = parseInt(draggableId.split("-")[1]);
      const setId = parseInt(destination.droppableId.split("/")[1]);
      // Assign the team member to the set
      assignTeamMemberToSet(teamMemberId, setId);
    } else if (
      source.droppableId.startsWith("assets/") &&
      destination.droppableId.startsWith("assets/")
    ) {
      const sourceSetId = parseInt(source.droppableId.split("/")[1]);
      const destSetId = parseInt(destination.droppableId.split("/")[1]);

      // Check if multiple assets are being dragged (checkboxes checked)
      const checkedItems = Object.keys(checkedAssets).filter(
        (assetId) => checkedAssets[assetId]
      );

      if (checkedItems.length > 0) {
        // Move multiple assets between sets

        if (sourceSetId === destSetId) {
          checkedItems.forEach((assetId) => {
            moveAssetWithinSet(sourceSetId, assetId, destination.index);
          });
        } else {
          // Move multiple assets between sets
          checkedItems.forEach((assetId) => {
            moveAssetBetweenSets(sourceSetId, assetId, destSetId);
          });
        }

        // Reset checked assets
        setCheckedAssets({});
      } else {
        // Single asset being dragged
        if (sourceSetId === destSetId) {
          // Reordering assets within the same set
          const assetId = draggableId.split("/")[1];
          moveAssetWithinSet(sourceSetId, assetId, destination.index);
        } else {
          // Dragging assets between different sets
          const assetId = draggableId.split("/")[1];
          moveAssetBetweenSets(sourceSetId, assetId, destSetId);
        }
      }
    }
  };

  const containerWidth = manageAssignee?.sets && manageAssignee.sets.length > 4;

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      {manageAssignee && (
        <>
          <>
            <Box
              className="flex__"
              height={availableHeight}
              width={availableWidth}
            >
              <Box
                sx={{
                  overflowY: "auto",
                  height: availableHeight,
                  width: "360px",
                }}
                className="tableStyles pr-md pt-md pl-md position__relative"
              >
                <Droppable droppableId="technicians-list">
                  {(provided) => (
                    <Box
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      sx={{ width: "260px" }}
                    >
                      {manageAssignee.shift.teams !== null &&
                        filteredTeamsArray &&
                        filteredTeamsArray.map((mem, index) => {
                          return (
                            <Draggable
                              key={mem.id}
                              draggableId={`technician-${mem.id}`}
                              index={index}
                            >
                              {(provide) => (
                                <Box
                                  className="mb-sm"
                                  {...provide.draggableProps}
                                  {...provide.dragHandleProps}
                                  ref={provide.innerRef}
                                  sx={{ width: "260px" }}
                                >
                                  <TeamMembersComponent
                                    data={mem}
                                    icon={<DragIcon />}
                                    isAvailabilityRequired={true}
                                    statusNotRequired={true}
                                    shiftType={
                                      manageAssignee.shift.shiftStaticName
                                    }
                                    shiftStartTime={
                                      manageAssignee.shift.shiftStartTime
                                    }
                                    shiftEndTime={
                                      manageAssignee.shift.shiftEndTime
                                    }
                                  />
                                </Box>
                              )}
                            </Draggable>
                          );
                        })}
                      {provided.placeholder}
                    </Box>
                  )}
                </Droppable>
              </Box>

              <Box
                sx={{
                  overflowX: "auto",
                  borderLeft: "1px solid #37424B",
                  width: availableWidth,
                }}
                className="tableStyles position__relative "
              >
                <Box
                  sx={{
                    borderBottom: "1px solid #37424B",
                    overflowX: "auto",

                    width: containerWidth ? availableWidth : autoWidth,
                    display: "flex",
                  }}
                  className="pt-md pb-md pl-md"
                >
                  {manageAssignee.sets.map((item, index) => {
                    return (
                      <Droppable droppableId={`assignee/${item.setId}`}>
                        {(provided) => (
                          <Box
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            width={"270px"}
                            className="mr-md"
                          >
                            {item.assignedUsers.map((user, userIndex) => {
                              let assignedTeamMember = assignedTeams.find(
                                (team) => team.id === user.assigneeId
                              );

                              return (
                                <Box key={user.assigneeId} className="pr-md">
                                  {user.assigneeId !== 0 && (
                                    <Box width={"270px"}>
                                      {assignedTeamMember && (
                                        <TeamMembersComponent
                                          data={assignedTeamMember}
                                          isAvailabilityRequired={true}
                                          statusNotRequired={true}
                                          shiftType={
                                            manageAssignee.shift.shiftStaticName
                                          }
                                          shiftStartTime={
                                            manageAssignee.shift.shiftStartTime
                                          }
                                          shiftEndTime={
                                            manageAssignee.shift.shiftEndTime
                                          }
                                          icon={
                                            <Cancel
                                              onClick={() => {
                                                handleRemove(
                                                  item.setId,
                                                  user.assigneeId
                                                );
                                              }}
                                            />
                                          }
                                        />
                                      )}
                                    </Box>
                                  )}
                                </Box>
                              );
                            })}
                            <>
                              {item.assignedUsers.length === 0 &&
                              item.setId === 0 ? (
                                <ButtonComponent
                                  className="btn-primary btn-cancel leadAssignee_button"
                                  labelId="LeadAssignee.addButton"
                                  defaultLabelId="ADD LEAD ASSIGNEE"
                                  variantType="outlined"
                                  onClick={() => {}}
                                />
                              ) : (
                                <>
                                  {item.assignedUsers.length === 0 ? (
                                    <ButtonComponent
                                      className="btn-primary btn-cancel primaryAssignee_button"
                                      labelId="Primary.addButton"
                                      defaultLabelId="ADD PRIMARY ASSIGNEE"
                                      variantType="outlined"
                                      onClick={() => {}}
                                    />
                                  ) : (
                                    <ButtonComponent
                                      className="btn-primary btn-cancel teammember_button"
                                      labelId="TeamMember.addButton"
                                      defaultLabelId="ADD TEAM MEMBER"
                                      variantType="outlined"
                                      onClick={() => {}}
                                    />
                                  )}
                                </>
                              )}
                            </>
                            {provided.placeholder}
                          </Box>
                        )}
                      </Droppable>
                    );
                  })}
                </Box>
                <Box
                  sx={{
                    width: containerWidth ? availableWidth : autoWidth,
                    borderBottom: "1px solid #37424B",
                    overflowX: "auto",
                    display: "flex",
                  }}
                  className="pt-md pl-md"
                >
                  {manageAssignee.sets.map((item, index) => (
                    <Droppable droppableId={`assets/${item.setId}`}>
                      {(provided) => (
                        <Box
                          key={index}
                          sx={{
                            overflowY: "auto",
                            height: assetListHeight,
                            width: "285px",
                          }}
                          className=" tableStyles"
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {item.assets.map((itemData, ind) => (
                            <Draggable
                              key={itemData.id}
                              draggableId={`assets/${itemData.id}`}
                              index={ind}
                            >
                              {(provider) => (
                                <Box
                                  className="flex__ "
                                  {...provider.draggableProps}
                                  {...provider.dragHandleProps}
                                  ref={provider.innerRef}
                                  sx={{
                                    padding: "12px",
                                    backgroundColor: "#22272B",
                                    marginBottom: "8px",
                                    borderRadius: "4px",
                                    alignItems: "center",
                                    width: "270px",
                                  }}
                                >
                                  <MediumTypography
                                    label={(ind + 1).toString()}
                                    textColor="#9FADBC"
                                    className="mr-sm"
                                  />
                                  <CheckBox
                                    value={
                                      itemData.id !== undefined &&
                                      checkedAssets[itemData.id]
                                    }
                                    onCheckBoxClick={() => {
                                      if (itemData.id !== undefined) {
                                        toggleCheckStatus(
                                          itemData.id.toString()
                                        );
                                      }
                                    }}
                                    className="p-none mr-sm"
                                  />
                                  <MediumTypography label={itemData.name} />
                                </Box>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </Box>
                      )}
                    </Droppable>
                  ))}
                  {/* </Box> */}
                </Box>
              </Box>
            </Box>
          </>
          <Box
            className="width__100 flex__ p-md"
            sx={{
              position: "sticky",
              bottom: 0,
              zIndex: 1,
              backgroundColor: "#323337",
              justifyContent: "end",
            }}
          >
            <ButtonComponent
              className={
                isAssignButtonDisabled(manageAssignee?.sets || [])
                  ? "btn-primary btn-disabled mr-md"
                  : "btn-primary btn-submit mr-md"
              }
              labelId="Assigne.btn"
              defaultLabelId="Assign"
              onClick={() => {
                // Clone the manageAssignee state to avoid direct mutations
                const updatedManageAssignee: AssigneeType = {
                  ...manageAssignee,
                  sets: manageAssignee.sets.map((set, setIndex) => ({
                    ...set,
                    assets: set.assets.map((asset, index) => ({
                      ...asset,
                      sequence: index + 1, // Update the sequence property
                    })),
                    assignedUsers: set.assignedUsers.map((user, userIndex) => {
                      if (setIndex === 0) {
                        // First set
                        if (userIndex === 0) {
                          return {
                            ...user,
                            isLeadAssignee: true,
                            isPrimaryAssignee: true,
                          };
                        } else {
                          return {
                            ...user,
                            isLeadAssignee: false,
                            isPrimaryAssignee: false,
                          };
                        }
                      } else {
                        // Other sets
                        if (userIndex === 0) {
                          return {
                            ...user,
                            isLeadAssignee: false,
                            isPrimaryAssignee: true,
                          };
                        } else {
                          return {
                            ...user,
                            isLeadAssignee: false,
                            isPrimaryAssignee: false,
                          };
                        }
                      }
                    }),
                  })),
                };

                onApply(updatedManageAssignee);
                setManageAssignee(updatedManageAssignee);
              }}
              disabled={isAssignButtonDisabled(manageAssignee?.sets || [])}
              sxProps={{
                cursor: isAssignButtonDisabled(manageAssignee?.sets || [])
                  ? "not-allowed"
                  : "pointer",
              }}
            />
          </Box>
        </>
      )}
    </DragDropContext>
  );
};

export default SetAssetsAssignee;
