import { Box } from "@mui/material";
import React, { FC, useContext, useEffect, useState } from "react";
import Login from "./Login";
import loginImage from "../../assets/images/FullLoginImage.svg";

import "./css/Login.css";
import MediumTypography from "../../components/formlib/MediumTypography";
import ForgotPassword, { ForgotPasswordProps } from "./ForgotPassword";
import {
  ApiErrorResponse,
  ChangePassword,
  ForgotPasswordResponse,
  ResetPasswordRequest,
  changePassword,
  forgotPassword,
  getUserProfile,
  login,
  loginRequest,
  loginResponse,
  resetPassword,
} from "../../api/UserApi/User";

import ResetPassword, { ResetPasswordScreenProps } from "./ResetPassword";
import CongratulationsScreen from "./CongratulationsScreen";
import { SignInType } from "../../utils/type";
import {
  CHANGE_PASSWORD,
  EMAIL,
  SESSION,
  STORAGE_USER_ID_KEY,
  USER_ID_DB,
  VERSION,
} from "../../api/Constant";
import { useError } from "./context/ApiErrorContext";
import { Context } from "../../LanguageWrapper";

import { useNavigate } from "react-router-dom";
import { SCHEDULER_DASHBOARD } from "../../routes/Routing";
import { handleError } from "../../utils/commonFunctions";

interface SignInPropType {
  onLoginSuccess: (userId: string) => void;
}

const SignInComponent: FC<SignInPropType> = ({ onLoginSuccess }) => {
  const [screen, setScreen] = useState("login");
  const [reset, setReset] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { setError } = useError();
  const navigate = useNavigate();
  const [messageModal, setMessageModal] = useState(false);
  const [tempPassword, setTempPassword] = useState(false);
  const [description, setDescription] = useState("");

  const context = useContext(Context);
  useEffect(() => {
    context.selectLanguage();
  }, [localStorage.getItem("language")]);

  const navigateForgotPassword = () => {
    setError("");
    setScreen("forgotPassword");
  };

  const handleChangePassword = (body: ResetPasswordScreenProps) => {
    if (!reset) {
      if (body.verificationCode) {
        const request: ChangePassword = {
          email: email,
          password: body.password,
          verificationcode: body.verificationCode,
        };

        changePassword(request)
          .then(() => {
            setError("");
            setScreen("congrats");
          })
          .catch((error) => {
            handleError(error, setError);
          });
      }
    } else {
      const emailId = localStorage.getItem(EMAIL);
      const session = localStorage.getItem(SESSION);
      if (emailId === null || session === null) {
        return;
      }
      const request: ResetPasswordRequest = {
        username: emailId,
        session: session,
        password: password,
        newpassword: body.password,
      };

      resetPassword(request)
        .then((response) => {
          localStorage.setItem("accessToken", response.accessToken);
          localStorage.setItem("refreshToken", response.refreshToken);
          localStorage.setItem(
            CHANGE_PASSWORD,
            JSON.stringify(response.changePassword)
          );
          onLoginSuccess(response.userId);
        })
        .catch((error) => {
          handleError(error, setError);
        });
    }
  };
  const handleModalClick = () => {
    setMessageModal(false); // Close the modal
    if (tempPassword) {
      // If a password reset was initiated, navigate back to the login screen
      handleBackToLogin();
    } else {
      // If a password reset was not initiated, proceed to the reset password screen
      setScreen("resetPassword");
      setReset(false);
    }
  };
  const handleForgotPassword = (body: ForgotPasswordProps) => {
    forgotPassword(body)
      .then((res: ForgotPasswordResponse) => {
        setEmail(body.email);
        setError("");
        setTempPassword(res.password);
        setDescription(res.message);
        setMessageModal(true);
      })
      .catch((error) => {
        handleError(error, setError);
      });
  };

  const handleBackToLogin = () => {
    setError("");
    setScreen("login");
  };
  const handleUserPermissions = (userId: number) => {
    getUserProfile(userId)
      .then((resp) => {
        localStorage.setItem("firstname", resp.firstName);
        localStorage.setItem("lastname", resp.lastName);
        localStorage.setItem("userRoles", JSON.stringify(resp.roles));

        localStorage.setItem("Profile Image", resp.profileImage);
        if (resp.language === "en") {
          localStorage.setItem("preferredLanguage", "en-US");
        } else if (resp.language === "hi") {
          localStorage.setItem("preferredLanguage", "hn");
        } else if (resp.language === "ta") {
          localStorage.setItem("preferredLanguage", "en-US");
        }
        context.selectLanguage();

        navigate(SCHEDULER_DASHBOARD);
      })
      .catch((err) => {
        handleError(err, setError);
      });
  };

  const handleLogin = (request: SignInType) => {
    const loginData: loginRequest = {
      empId: request.username,
      password: btoa(request.password),
      // password: request.password,
    };

    login(loginData)
      .then((response: loginResponse) => {
        localStorage.setItem("accessToken", response.accessToken);
        localStorage.setItem("refreshToken", response.refreshToken);
        localStorage.setItem(
          "language",
          response.preferredLanguage.languageCode
        );
        localStorage.setItem(
          "languageCode",
          String(response.preferredLanguage.id)
        );
        localStorage.setItem(
          CHANGE_PASSWORD,
          JSON.stringify(response.changePassword)
        );
        localStorage.setItem(SESSION, response.session);
        localStorage.setItem(STORAGE_USER_ID_KEY, response.userId);

        localStorage.setItem(EMAIL, response.email);
        localStorage.setItem(USER_ID_DB, JSON.stringify(response.id));
        setEmail(response.email);
        handleUserPermissions(response.id);

        if (response.changePassword) {
          setEmail(response.email);
          setPassword(request.password);
          setError("");
          setScreen("resetPassword");
          setReset(true);
        } else {
          onLoginSuccess(response.userId);
          // navigate(SCHEDULER_DASHBOARD);
        }
      })
      .catch((error) => {
        handleError(error, setError);
      });
  };
  return (
    <Box className="container">
      <Box
        className="gif"
        sx={{
          backgroundImage: `url(${loginImage})`,
          // background: "no-repeat",
        }}
      >
        <Box className="textContainer">
          <MediumTypography
            labelId="Gif.MainText"
            defaultLabel="Unlock the full potential of your renewable assets"
            className="bigText"
            sxProps={{
              color: "#1C1C1E",
            }}
          />
          <MediumTypography
            labelId="Gif.subText"
            defaultLabel="Cutting-edge technology, data-driven insights, AI-powered monitoring &
          analytics ensure your assets operate seamlessly"
            className="subText"
            sxProps={{
              color: "rgba(28, 28, 30, 0.76)",
            }}
          />
        </Box>
        <Box className="versionContainer">
          <Box className="flex__">
            <Box>
              <MediumTypography
                label={"Version :"}
                fontSize="20px"
                fontWeight={600}
                sxProps={{
                  color: "#1C1C1E",
                }}
              />
            </Box>
            <Box>
              <MediumTypography
                fontSize="20px"
                fontWeight={600}
                label={VERSION}
                sxProps={{
                  color: "#1C1C1E",
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>

      <>
        {screen === "login" && (
          <Login
            handleSubmitData={handleLogin}
            onForgotPassword={navigateForgotPassword}
            testId="login"
          />
        )}
        {screen === "forgotPassword" && (
          <ForgotPassword
            onReturnToLogin={handleBackToLogin}
            handlePassword={handleForgotPassword}
            testId="forgot-password"
            modalRequired={messageModal}
            description={description}
            handleModalClick={handleModalClick}
          />
        )}
        {screen === "resetPassword" && (
          <ResetPassword
            reset={reset}
            handleSubmitChangePassword={handleChangePassword}
            testId="reset-password"
          />
        )}
        {screen === "congrats" && (
          <CongratulationsScreen
            backToLogin={handleBackToLogin}
            testId="congrats"
          />
        )}
      </>
    </Box>
  );
};

export default SignInComponent;
