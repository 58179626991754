import React, { FC } from "react";
import { Box, CircularProgress, CircularProgressProps } from "@mui/material";
import MediumTypography from "./MediumTypography";

const STATUS_COLORS = {
  PLANNED: "#A3F000", // Green
  PLANNED_TODAY: "#70991d", // Dark Green
  ACTUAL: "#186ade", // Blue
  EXTRA: "#186ade", // Blue
  REJECTED: "#FF0000", // Red
};

const ACTIVE_COLORS = {
  OUTER_SQUARE: "rgba(66, 86, 107, 0.5)",
  INNER_CIRCLE: "1148be",
  FONT_COLOR: "#FFF",
};

const zeroPrefixedValue = (value: number) => {
  if (value) {
    if (value <= 0) return "0";
    return value.toString().padStart(2, "0");
  }
  return "0";
};

interface CycleProgressBarProps {
  cycle: number;
  planned: number; // Expected value between 0 and 100
  actual: number; // Expected value between 0 and 100
  cumulativeCount: number;
  activeCycle: boolean;
  onCycleClick: (cycle: number) => void;
}

const CycleProgressBar: FC<CycleProgressBarProps> = ({
  cycle,
  planned,
  actual,
  cumulativeCount,
  activeCycle,
  onCycleClick,
}) => {
  const outerSize = 40; // Outer circle size
  const innerSize = 32; // Inner circle size
  const thickness = 2; // Thickness of the progress bars

  const outerProgressProps: CircularProgressProps = {
    variant: "determinate",
    value: actual,
    size: outerSize,
    thickness: thickness,
  };

  const innerProgressProps: CircularProgressProps = {
    variant: "determinate",
    value: planned,
    size: innerSize,
    thickness: thickness,
  };

  return (
    <Box
      onClick={() => {
        if (!activeCycle) {
          onCycleClick(cycle);
        }
      }}
      sx={{
        // zIndex: 9999,
        cursor: activeCycle ? "auto" : "pointer",
        background: activeCycle ? ACTIVE_COLORS.OUTER_SQUARE : "inherit",
        borderRadius: "4px",
        p: "5px",
      }}
    >
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box position="relative" display="inline-flex">
          <CircularProgress
            {...outerProgressProps}
            sx={{ color: STATUS_COLORS.ACTUAL }}
          />
          <Box
            top={0}
            left={0}
            bottom={0}
            right={0}
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{
              borderRadius: "50%",
              border: activeCycle
                ? "0.5px solid rgba(255, 255, 255, 0.15)"
                : "1px solid rgba(166, 197, 226, 0.16)",
              backgroundColor: activeCycle
                ? ACTIVE_COLORS.INNER_CIRCLE
                : "rgba(34, 39, 43, 1)",
              width: innerSize, // Ensure the size matches the inner CircularProgress
              height: innerSize, // Ensure the size matches the inner CircularProgress
              margin: "auto", // This centers the box within the relative parent
            }}
          >
            <CircularProgress
              {...innerProgressProps}
              sx={{ color: STATUS_COLORS.PLANNED, position: "absolute" }}
            />
            <Box
              sx={{
                position: "absolute",
                fontFamily: "Inter",
                fontWeight: "500",
                fontSize: "14px",
                color: activeCycle
                  ? ACTIVE_COLORS.FONT_COLOR
                  : "rgba(140, 155, 171, 1)",
              }}
            >
              {zeroPrefixedValue(cycle)}
            </Box>
          </Box>
        </Box>
        <Box>
          <MediumTypography
            label={cumulativeCount.toString()}
            sxProps={{
              fontSize: "12px",
              fontWeight: 400,
              color: activeCycle
                ? ACTIVE_COLORS.FONT_COLOR
                : "rgba(140, 155, 171, 1)",
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default CycleProgressBar;
