import { Box } from "@mui/material";
import { ReactComponent as WelcomeScreenImage } from "../../assets/images/WelcomeScreen.svg";
import React from "react";
import MediumTypography from "../../components/formlib/MediumTypography";
import "./WizardScreen.css";
import useAvailableHeight from "../../utils/useAvailableHeight";
import ButtonComponent from "../../components/formlib/ButtonComponent";
import AccountModal from "./AccountModal";

const WelcomeScreen = () => {
  const availableHeight = useAvailableHeight(50);
  const [accountModalOpen, setAccountModalOpen] = React.useState(false);

  return (
    <Box className="center-container" height={availableHeight}>
      <AccountModal
        open={accountModalOpen}
        handleClose={() => setAccountModalOpen(false)}
      />
      <WelcomeScreenImage className="center-image" />
      <MediumTypography
        className="center-text"
        labelId="WelcomeParam.title"
        defaultLabel="Welcome to Param"
        fontSize="32px"
        fontWeight={700}
      />
      <MediumTypography
        className="center-text"
        labelId="WelconeParam.subheader"
        defaultLabel="Let’s get started with your onboarding process"
        fontSize="16px"
        fontWeight={400}
      />
      <ButtonComponent
        className="btn-primary btn-submit mt-md"
        labelId="BeginText"
        defaultLabelId="Onboard a new site"
        onClick={() => setAccountModalOpen(true)}
        sxProps={{
          cursor: "pointer",
          textTransform: "none",
          fontSize: "14px",
          height: "auto",
          minWidth: "130px",
          minHeight: "17px",
        }}
      />
    </Box>
  );
};

export default WelcomeScreen;
