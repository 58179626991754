import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import { FC, useContext, useEffect, useRef, useState } from "react";
import MediumTypography from "../../../components/formlib/MediumTypography";
import ButtonComponent from "../../../components/formlib/ButtonComponent";
import CloseIcon from "@mui/icons-material/Close";
import "../styles.css";
import { LoaderContext, LoaderContextType } from "../../../layouts/appSidebar";
import ErrorModal from "../../../components/formlib/modal/ErrorModal";

import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import FormikErrorComponent from "../../../components/formlib/FormikErrorComponent";
import {
  fetchDataApi,
  saveDataApi,
} from "../../../api/MasterDataManagement/Common";
import {
  FetchReasonForDeviationType,
  woTypeList,
} from "../types/reasonForDeviation";
import CustomDropdown from "../../../components/formlib/CustomDropdown";
import { getOptions, handleError } from "../../../utils/commonFunctions";
import { useIntl } from "react-intl";

// Configurations

type FetchDataType = FetchReasonForDeviationType;
type SaveDataType = FetchReasonForDeviationType;

const apiUri = "deviation";

interface ModalProps {
  woTypes: woTypeList[];
  open: boolean;
  handleClose: () => void;
  id?: number;
  getDataCallback: () => void; // reloadAccountData
}

const initialData: FetchDataType = {
  name: "",
  selectedWoTypes: [],
};

const convertToNumberArray = (value: any): number[] => {
  return value === undefined ||
    value === null ||
    value === "" ||
    !Array.isArray(value)
    ? []
    : value.map((item) => Number(item));
};
const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required("validation.fieldRequired")
    .max(200, "validation.maxLength200"),
  selectedWoTypes: Yup.array().min(1, "validation.dropdown"),
});

const muiColumnProps = {
  xs: 12,
};

const AddEditDeviationModal: FC<ModalProps> = ({
  woTypes,
  open,
  handleClose,
  id,
  getDataCallback,
}) => {
  const [apiData, setApiData] = useState<FetchDataType>(initialData);
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [errorDesc, setErrorDesc] = useState<string>("");
  const formikRef = useRef<FormikProps<FetchDataType>>(null);
  const [isVersionRequired, setIsVersionRequired] = useState<boolean>(false);
  const intl = useIntl();

  useEffect(() => {
    if (open && id) {
      fetchData();
    }
    initialData.woTypes = woTypes;
  }, [id, open]);

  const fetchData = () => {
    toggleLoader(true);
    fetchDataApi<FetchDataType>(apiUri, id)
      .then(async (res) => {
        const updatedRes = { ...initialData, ...res };
        updatedRes.woTypes = woTypes;
        setApiData(updatedRes);
        toggleLoader(false);
      })
      .catch((err) => {
        handleError(err, setErrorDesc);
        setOpenErrorModal(true);
        toggleLoader(false);
      });
  };

  const saveData = (params: FetchDataType) => {
    toggleLoader(true);
    const { woTypes, ...updatedParams } = params;

    saveDataApi<SaveDataType>(apiUri, updatedParams, isVersionRequired, id)
      .then(() => {
        formikRef.current?.resetForm({ values: initialData });
        handleClose();
        getDataCallback();
        toggleLoader(false);
      })
      .catch((err) => {
        handleError(err, setErrorDesc);
        setOpenErrorModal(true);
        toggleLoader(false);
      });
  };

  return (
    <>
      {openErrorModal && (
        <ErrorModal
          descriptionText={errorDesc}
          open={openErrorModal}
          handleClose={() => {
            setOpenErrorModal(false);
            handleClose();
          }}
          onPositiveClick={() => {
            setOpenErrorModal(false);
            handleClose();
          }}
        />
      )}

      <Formik
        initialValues={apiData}
        enableReinitialize
        validateOnChange
        innerRef={formikRef}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          const params: SaveDataType = {
            ...values,
            selectedWoTypes: convertToNumberArray(values.selectedWoTypes),
          };
          saveData(params);
        }}
      >
        {({ values, setFieldValue, handleSubmit, errors, touched, dirty }) => {
          return (
            <Dialog
              className="account-dialog main-wrapper-box"
              open={open}
              fullWidth
              maxWidth={"sm"}
            >
              <DialogTitle className="padding_20">
                <MediumTypography
                  labelId={id ? "deviation.update" : "deviation.add"}
                  defaultLabel="Reason For Deviation Details"
                  textColor="#FFF"
                  fontSize="22px"
                  fontWeight={700}
                />
                <MediumTypography
                  labelId={"common.basicDetails"}
                  defaultLabel="Enter basic details"
                  textColor="rgba(159, 173, 188, 1)"
                  fontSize="14px"
                  fontWeight={400}
                  className="mt-xs"
                />
              </DialogTitle>

              <IconButton
                aria-label="close"
                onClick={() => {
                  formikRef.current?.resetForm({ values: initialData });
                  handleClose();
                }}
                className="closeIcon"
              >
                <CloseIcon />
              </IconButton>

              <DialogContent dividers className="padding_20">
                <Grid container spacing={2}>
                  <Grid item {...muiColumnProps}>
                    <MediumTypography
                      className="input-label"
                      labelId="deviation.WoType"
                      defaultLabel="W/O Type"
                    />
                    <CustomDropdown
                      multiple={true}
                      disableSearch={true}
                      disableCreate={true}
                      options={getOptions(woTypes)}
                      selectedValue={values.selectedWoTypes}
                      onSelectedValueChange={(value) => {
                        setFieldValue("selectedWoTypes", value);
                      }}
                      overrideHeight="auto"
                    />
                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="selectedWoTypes"
                    />
                  </Grid>
                  <Grid item {...muiColumnProps}>
                    <MediumTypography
                      className="input-label"
                      labelId="ManageReasons.title"
                      defaultLabel="Reason For Deviation"
                    />
                    <TextField
                      variant="outlined"
                      sx={{
                        backgroundColor: "#22272B",
                        borderRadius: "4px",
                        border: "1px solid rgba(166, 197, 226, 0.16)",
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "#1D2226",
                          },

                          "&:hover fieldset": {
                            borderColor: "#1D2226",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#1D2226",
                          },
                          "& textarea": {
                            color: "#ffff",
                            // Text color inside the input
                          },
                        },
                        "& .MuiInputLabel-root": {
                          color: "#ffff",
                        },
                        ".MuiInputBase-input::placeholder ": {
                          color: "rgba(140, 155, 171, 0.68)",
                        },
                      }}
                      minRows={2}
                      fullWidth
                      multiline
                      value={values.name}
                      placeholder={intl.formatMessage({
                        id: "common.placeHolderText",
                        defaultMessage: "--- type here ---",
                      })}
                      onChange={(event) => {
                        setFieldValue("name", event.target.value.trimStart());
                      }}
                      inputProps={{
                        readOnly: id ? true : false,
                        maxLength: 500,
                      }}
                    />

                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="name"
                    />
                  </Grid>
                </Grid>
              </DialogContent>

              <DialogActions className="dialogActions">
                <Box>
                  <Grid
                    className="flex__ justifyContent-FlexEnd"
                    container
                    direction="row"
                    alignItems="right"
                  >
                    <Grid item>
                      <ButtonComponent
                        sxProps={{ color: "#B6C2CF !important" }}
                        className="btn-primary btn-cancel mr-md"
                        variantType="outlined"
                        defaultLabelId={"Reset"}
                        labelId={"common.reset"}
                        onClick={() => formikRef.current?.resetForm()}
                        disabled={!dirty}
                      />
                    </Grid>
                    <Grid item>
                      <ButtonComponent
                        className="btn-primary btn-submit"
                        variantType="contained"
                        defaultLabelId={"Save"}
                        labelId={id ? "btn.update" : "btn.Add"}
                        onClick={() => {
                          handleSubmit();
                          setIsVersionRequired(false);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </DialogActions>
            </Dialog>
          );
        }}
      </Formik>
    </>
  );
};

export default AddEditDeviationModal;
