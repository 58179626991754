import {
  Box,
  Chip,
  Divider,
  Grid,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Button,
  ButtonGroup,
  Tooltip,
  TooltipProps,
  styled,
  tooltipClasses,
} from "@mui/material";
import "./planner.css";
import MediumTypography from "../../components/formlib/MediumTypography";
import CardComponentSidebar from "../../components/formlib/CardComponentSidebar";
import CycleProgressBar from "../../components/formlib/CycleProgressBar";
import {
  CycleInfoType,
  FeatureCollectionType,
  GCPlannerDataType,
  GCPlannerSaveDataType,
  GeometryDataType,
  PlanType,
  PlannerCardDetailsType,
} from "./plannerTypes";
import StatusExecution from "../../components/formlib/StatusExecution";
import ButtonComponent from "../../components/formlib/ButtonComponent";
import TextInput from "../../components/formlib/TextInput";
import DropdownComponent from "../../components/formlib/DropdownComponent";
import dayjs, { Dayjs } from "dayjs";
import ErrorModal from "../../components/formlib/modal/ErrorModal";

import React, { useRef, useCallback, FC, useEffect, useState } from "react";
import {
  DrawingManager,
  GoogleMap,
  Libraries,
  useJsApiLoader,
} from "@react-google-maps/api";

import FullScreenIcon from "./../../assets/images/map_control_buttons/FullScreen.svg";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import PenToolIcon from "./../../assets/images/map_control_buttons/PenTool.svg";
import StoppingHandIcon from "./../../assets/images/map_control_buttons/StoppingHand.svg";
import ZoomInIcon from "./../../assets/images/map_control_buttons/ZoomIn.svg";
import ZoomOutIcon from "./../../assets/images/map_control_buttons/ZoomOut.svg";
import { ReactComponent as AreaIcon } from "./../../assets/images/Area.svg";
import FabWithTooltip from "./FabWithTooltip";
import * as yup from "yup";
import { Formik } from "formik";
import ModalPopup from "../../components/formlib/modal/ModalPopup";
import { useLocation, useNavigate } from "react-router-dom";
import DateTimePickerComponent from "../../components/formlib/DateTimePickerComponent";
import Loader from "../../components/shared/Loader";
import {
  getGrassCuttingPlannerApi,
  saveGrassCuttingPlannerApi,
} from "../../api/GrassCutting/GrassCuttingPlannerApi";
import { SCHEDULER_DASHBOARD } from "../../routes/Routing";
import { ReactComponent as ErrorIcon } from "../../assets/images/ErrorIcon.svg";
import { CleaningMethodType } from "../../api/moduleCleaning/ModuleCleaningApi";
import { handleError } from "../../utils/commonFunctions";

const CustomButton = styled(Button)`
  width: 40.42px;
  height: 35px;
  margin-right: 6px;
  margin-left: 6px;
  background-color: rgba(29, 33, 37, 0.9);
  &:hover {
    background-color: rgba(29, 33, 37, 1);
  }
`;

const CustomTooltip = styled(({ className, title, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    title={
      <MediumTypography
        labelId={title as string}
        fontSize="12px"
        fontWeight={500}
      />
    }
    classes={{ popper: className ?? "" }}
  />
))`
  & .${tooltipClasses.tooltip} {
    background-color: rgba(29, 33, 37, 0.9);
    color: #fff;
    font-weight: 500;
    border-radius: 4px;
  }

  & .${tooltipClasses.arrow} {
    color: rgba(29, 33, 37, 0.9);
  }
`;

// To fix type missmatch issue
type Library =
  | "core"
  | "maps"
  | "places"
  | "geocoding"
  | "routes"
  | "marker"
  | "geometry"
  | "elevation"
  | "streetView"
  | "journeySharing"
  | "drawing"
  | "visualization";

const libraries: Libraries = ["drawing", "geometry"];

type EditMode = "edit" | "delete" | null;

const apiKey = "AIzaSyAeJTj_G8GZMdKK-FQngfDdMf3glBkGfoM"; // "AIzaSyA9e7q3S_JVIT8Tz16ZjWD3EFO03XGGwJo";

// Center coordinates for India, zoom should be set to 5
const center = {
  lat: 20.5937,
  lng: 78.9629,
};

// Map view type
const mapType = "HYBRID"; // "ROADMAP" | "TERRAIN" | "SATELLITE" | "HYBRID"

// Map display styles currently not working, might need to enable this feature on map console
const mapStyles: google.maps.MapTypeStyle[] = [
  {
    featureType: "all",
    elementType: "all",
    stylers: [
      { saturation: -10 }, // reduce saturation
      { lightness: 10 }, // increase lightness
    ],
  },
];

// Style Mappings
const STYLE_MAPPING = {
  strokeColor: "strokeClr",
  strokeOpacity: "strokeOpac",
  strokeWeight: "strokeWt",
  fillColor: "fillColor",
  fillOpacity: "fillOpac",
};

const STATUS_COLORS = {
  PLANNED: "#A3F000", // Green
  PLANNED_TODAY: "#70991d", // Dark Green
  ACTUAL: "#186ade", // Blue
  EXTRA: "#FF6500", // Orange
  REJECTED: "#FF0000", // Red
};

const initialCardDetails: PlannerCardDetailsType = {
  sequenceId: 0,
  type: "",
  code: "",
  frequency: "",
  description: "",
  processedOn: "",
  processedCount: 0,
  processedBy: "",
  status: "",
  statusCode: "",
  members: [],
};

const initialGeoJson: FeatureCollectionType = {
  type: "FeatureCollection",
  name: "",
  crs: {
    type: "name",
    properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" },
  },
  features: [],
};

const initialPlannerData: GCPlannerDataType = {
  planType: "non-pv",
  plannedCoordinatesJson: "[]",
  actualCoordinatesJson: "[]",
  rejectedCoordinatesJson: "[]",
  woPlannedArea: 0,
  woActualArea: 0,
  woRejectedArea: 0,
  plannedTablesCount: 0,
  actualTablesCount: 0,
  rejectedTablesCount: 0,
  cycle: 0,
  cyclePlannedArea: 0,
  cycleActualArea: 0,
  cyclePlannedTablesCount: 0,
  cycleActualTablesCount: 0,
  cyclePercentage: 0,
  cycleCumulativeCount: 0,
  lastDaysActualCoordinatesJson: [],
  currentDayWosPlannedCoordinates: [],
  pendingForApprovalOrRejection: false,
  status: [],
  shiftTimeRange: "",
  vendorsList: [],
  selectedVendorId: 0,
  selectedVendorName: "",
  woPlannedStartTime: "",
  woPlannedEndTime: "",
  recommendation: "",
  shiftName: "",
  cardDetailsInfo: initialCardDetails,
  plainJson: JSON.stringify(initialGeoJson),
  cleaningMethod: "",
  definedScheduleStatus: [],
  gcMethod: [],
  selectedGCMethodId: 0,
  gcCoverageType: [],
  selectedGCCoverageTypeIds: [],
};

export interface CountsType {
  tables: number;
  area: number;
}

export interface AllCountsType {
  workorderPlanned: CountsType;
  workorderActual: CountsType;
  cyclePlanned: CountsType;
  cycleActual: CountsType;
}

type ViewModeType = "planner" | "actual" | "rejection" | null;

interface CoordinatesJsonType {
  [key: string]: GeometryDataType[];
  previousActual: GeometryDataType[];
  todayPlanned: GeometryDataType[];
  planned: GeometryDataType[];
  actual: GeometryDataType[];
  rejected: GeometryDataType[];
}

const defaultCounts: CountsType = {
  tables: 0,
  area: 0,
};

const defaultCountsAll = {
  workorderPlanned: defaultCounts,
  workorderActual: defaultCounts,
  workorderRejected: defaultCounts,
  cyclePlanned: defaultCounts,
  cycleActual: defaultCounts,
};

const zeroPrefixedValue = (value: number) => {
  if (value) {
    if (value <= 0) return "0";
    return value.toString().padStart(2, "0");
  }
  return "0";
};

const convertSquareMetersToAcres = (squareMeters: number): number => {
  return squareMeters * 0.000247105;
};

const getFormattedDisplayArea = (area: number): number => {
  return parseFloat(convertSquareMetersToAcres(area).toFixed(2));
};

export interface OptionType {
  id: string;
  label: string;
}

interface FormValuesType {
  selectedVendorId: number;
  selectedGCMethodId: number;
  woPlannedStartTime: string;
  woPlannedEndTime: string;
}

const GrassCuttingPlanner: FC = () => {
  const [apiData, setApiData] = useState<GCPlannerDataType>(initialPlannerData);

  const [counts, setCounts] = useState(defaultCountsAll);

  const [plannedPeriodToggle, setPlannedPeriodToggle] = useState<
    "workorder" | "cycle"
  >("workorder");
  const [actualPeriodToggle, setActualPeriodToggle] = useState<
    "workorder" | "cycle"
  >("workorder");

  const [vendorList, setVendorList] = useState<OptionType[]>([]);

  const [formValues, setFormValues] = useState<FormValuesType>({
    selectedVendorId: 0,
    selectedGCMethodId: 0,
    woPlannedStartTime: "",
    woPlannedEndTime: "",
  });

  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [errorDesc, setErrorDesc] = useState<string>("");

  const [map, setMap] = useState<google.maps.Map>();
  const [viewMode, setViewMode] = useState<ViewModeType>(null);

  const [polygons, setPolygons] = useState<Array<google.maps.Polygon>>([]);

  const [gcPolygons, setGCPolygons] = useState<Array<google.maps.Polygon>>([]);

  const [drawingMode, setDrawingMode] =
    useState<google.maps.drawing.OverlayType | null>(null);
  const [editMode, setEditMode] = useState<EditMode>(null);
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState(false);

  const originalStylesRef = useRef<Map<number, any>>(new Map());
  const mapContainerRef = useRef<HTMLElement>();
  const customControlsRef = useRef<HTMLDivElement>(null);

  const [cuttingMethodList, setCuttingMethodList] = useState<OptionType[]>([]);

  const validationSchema = yup.object().shape({
    selectedVendorId: yup
      .number()
      .required("vendorErrorText")
      .test("is-not-zero", "vendorErrorText", (value) => value !== 0),
    selectedGCMethodId: yup
      .number()
      .required("GCMErrorText")
      .test("is-not-zero", "GCMErrorText", (value) => value !== 0),
    woPlannedStartTime: yup.string().nullable(),
    woPlannedEndTime: yup.string().nullable(),
  });

  const [planType, setPlanType] = React.useState<PlanType>("non-pv");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({
    type: "alert",
    description: "",
    onNegative: () => {},
    onPositive: () => {},
  });

  const openConfirmationModal = (
    type: "alert" | "confirm",
    description: string,
    onNegative: () => void,
    onPositive: () => void
  ) => {
    setModalContent({ type, description, onNegative, onPositive });
    if (type === "confirm") {
      setIsConfirmModalOpen(true);
    } else {
      setIsModalOpen(true);
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setIsConfirmModalOpen(false);
  };

  const handleModalNegative = () => {
    modalContent.onNegative();
    handleModalClose();
  };

  const handleModalPositive = () => {
    modalContent.onPositive();
    handleModalClose();
  };

  const handlePlanTypeChange = (
    event: React.MouseEvent<HTMLElement>,
    newPlanType: PlanType
  ) => {
    if (newPlanType !== null && newPlanType !== planType) {
      if (planTypeChangeAction === "enable_change") {
        setPlanType(newPlanType);
      } else if (planTypeChangeAction === "reset_plan") {
        openConfirmationModal(
          "confirm",
          "gcPlanner.planTypeChangeWithReset",
          () => {
            // Negative
          },
          () => {
            // Positive
            gcPolygons.forEach((gcPolygon) => {
              gcPolygon.setMap(null);
            });
            setGCPolygons(() => []);
            onCountsChange(viewMode, {
              tables: 0,
              area: 0,
            });
            setPlanType(newPlanType);
          }
        );
      } else {
        openConfirmationModal(
          "alert",
          "gcPlanner.planTypeChangeDisabled",
          () => {},
          () => {}
        );
      }
    }
  };

  const location = useLocation();
  const navState = location?.state;
  const navigate = useNavigate();

  const overrideViewMode = location.state?.viewMode
    ? location.state?.viewMode
    : "actual";

  const refreshComponent = () => {
    polygons.forEach((polygon) => {
      polygon.setMap(null);
    });
    gcPolygons.forEach((gcPolygon) => {
      gcPolygon.setMap(null);
    });
    setPolygons(() => []);
    setGCPolygons(() => []);
    originalStylesRef.current = new Map();
    getGrassCuttingPlannerInfo();
  };

  // Fetch api data
  useEffect(() => {
    getGrassCuttingPlannerInfo();
  }, []);

  const getGrassCuttingPlannerInfo = () => {
    setIsLoading(true);
    getGrassCuttingPlannerApi(
      navState.cardId,
      navState.assetGroupId,
      navState.idType
    )
      .then((res) => {
        const countsObject = {
          workorderPlanned: {
            tables: res.plannedTablesCount,
            area: res.woPlannedArea,
          },
          workorderActual: {
            tables: res.actualTablesCount,
            area: res.woActualArea,
          },
          workorderRejected: {
            tables: res.rejectedTablesCount,
            area: res.woRejectedArea,
          },
          cyclePlanned: {
            tables: res.cyclePlannedTablesCount,
            area: res.cyclePlannedArea,
          },
          cycleActual: {
            tables: res.cycleActualTablesCount,
            area: res.cycleActualArea,
          },
        };

        const cardStatus = res.cardDetailsInfo?.statusCode;
        let updatedViewMode: ViewModeType = null;
        if (cardStatus && cardStatus !== "") {
          if (cardStatus === "SCHEDULED" || cardStatus === "DISPATCHED") {
            updatedViewMode = "planner";
          } else if (cardStatus === "INPROGRESS") {
            updatedViewMode = "actual";
          } else if (cardStatus === "COMPLETED") {
            updatedViewMode = overrideViewMode; // "actual" | "rejection";
          } else if (
            cardStatus === "LEVEL1" ||
            cardStatus === "LEVEL2" ||
            cardStatus === "LEVEL3"
          ) {
            updatedViewMode = "rejection";
          }
        }

        const vedorOptions: OptionType[] =
          res.vendorsList?.map((vendor) => ({
            id: vendor.id.toString(),
            label: vendor.name,
          })) || [];

        const cuttingMethodOptions: OptionType[] =
          res.gcMethod.map((cutting: CleaningMethodType) => ({
            id: cutting.id.toString(),
            label: cutting.name,
          })) || [];

        const planTypeId = res.selectedGCCoverageTypeIds[0] ?? 0;
        const planTypeCode =
          res.gcCoverageType?.find((ct) => ct.id === planTypeId)?.code ??
          "non-pv";

        setApiData(res);
        setPlanType(planTypeCode as PlanType);
        setCounts(countsObject);
        setViewMode(updatedViewMode);
        setVendorList(vedorOptions);
        setCuttingMethodList(cuttingMethodOptions);
        setFormValues({
          selectedVendorId: res.selectedVendorId || 0,
          selectedGCMethodId: res.selectedGCMethodId || 0,
          woPlannedStartTime: res.woPlannedStartTime || "",
          woPlannedEndTime: res.woPlannedEndTime || "",
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setOpenErrorModal(true);
        handleError(err, setErrorDesc);
        setIsLoading(false);
      });
  };

  const handleSaveGrassCuttingPlannerApi = (
    action:
      | "save-plan"
      | "save-actual"
      | "approve-actual"
      | "reject-actual"
      | "partial-reject"
      | "",
    formData?: FormValuesType
  ) => {
    if (action === "") return;

    const paramsObj: GCPlannerSaveDataType = {
      plannedCoordinatesJson: "",
      actualCoordinatesJson: "",
      rejectedCoordinatesJson: "",
      woPlannedArea: 0,
      woActualArea: 0,
      woRejectedArea: 0,
      plannedTablesCount: 0,
      actualTablesCount: 0,
      rejectedTablesCount: 0,
      scheduledStartDateTime: "",
      scheduledEndDateTime: "",
      vendorId: null,
      selectedGCMethodId: 0,
      selectedGCCoverageTypeIds: [],
    };

    if (action === "save-plan") {
      paramsObj.plannedCoordinatesJson = getFormattedGCPolygons();
      paramsObj.woPlannedArea = counts.workorderPlanned.area;
      paramsObj.plannedTablesCount = counts.workorderPlanned.tables;
      paramsObj.vendorId = formData?.selectedVendorId || null;
      paramsObj.selectedGCMethodId = formData?.selectedGCMethodId || null;
      paramsObj.scheduledStartDateTime = formData?.woPlannedStartTime || "";
      paramsObj.scheduledEndDateTime = formData?.woPlannedEndTime || "";
      const planTypeId = apiData.gcCoverageType?.find(
        (ct) => ct.code === planType
      )?.id;
      paramsObj.selectedGCCoverageTypeIds = planTypeId ? [planTypeId] : [];
    } else if (action === "save-actual") {
      paramsObj.actualCoordinatesJson = getFormattedGCPolygons();
      paramsObj.woActualArea = counts.workorderActual.area;
      paramsObj.actualTablesCount = counts.workorderActual.tables;
    } else if (action === "partial-reject") {
      paramsObj.rejectedCoordinatesJson = getFormattedGCPolygons();
      paramsObj.woRejectedArea = counts.workorderRejected.area;
      paramsObj.rejectedTablesCount = counts.workorderRejected.tables;
    }

    saveGrassCuttingPlannerApi(
      navState.cardId,
      navState.idType,
      action,
      paramsObj
    )
      .then(() => {
        setOpenModal(true);
      })
      .catch((err) => {
        setOpenErrorModal(true);
        handleError(err, setErrorDesc);
      });
  };

  useEffect(() => {
    const mapContainer = mapContainerRef.current;

    const disableScroll = () => {
      window.addEventListener("wheel", preventScroll, { passive: false });
    };

    const enableScroll = () => {
      window.removeEventListener("wheel", preventScroll);
    };

    const preventScroll = (e: WheelEvent) => {
      e.preventDefault();
    };

    mapContainer?.addEventListener("mouseenter", disableScroll);
    mapContainer?.addEventListener("mouseleave", enableScroll);

    return () => {
      // Cleanup listeners when the component unmounts
      mapContainer?.removeEventListener("mouseenter", disableScroll);
      mapContainer?.removeEventListener("mouseleave", enableScroll);
    };
  }, [mapContainerRef.current]);

  // Count will change dynamically for workorder only
  const onCountsChange = (type: ViewModeType, countsData: CountsType) => {
    if (type === "planner") {
      setCounts((prevCounts) => ({
        ...prevCounts,
        workorderPlanned: countsData,
      }));
    } else if (type === "actual") {
      setCounts((prevCounts) => ({
        ...prevCounts,
        workorderActual: countsData,
      }));
    } else if (type === "rejection") {
      setCounts((prevCounts) => ({
        ...prevCounts,
        workorderRejected: countsData,
      }));
    }
  };

  let showMap = false;

  const currentCycle: CycleInfoType = {
    cycleNumber: apiData.cycle,
    plannedPercentage: 0,
    actualPercentage: apiData.cyclePercentage,
    cumulativeCount: apiData.cycleCumulativeCount,
    currentCycle: true,
    cycleActualTables: [],
  };

  const cyclesInfo: CycleInfoType[] = [currentCycle];

  const plainJson: FeatureCollectionType = JSON.parse(apiData.plainJson);

  if (plainJson?.features?.length > 0) {
    showMap = true;
  }

  const coordinatesJson: CoordinatesJsonType = {
    previousActual: [],
    todayPlanned: [],
    planned: [],
    actual: [],
    rejected: [],
  };

  if (apiData.plannedCoordinatesJson) {
    coordinatesJson.planned = apiData.plannedCoordinatesJson
      ? JSON.parse(apiData.plannedCoordinatesJson)
      : [];
  }

  if (apiData.actualCoordinatesJson) {
    coordinatesJson.actual = apiData.actualCoordinatesJson
      ? JSON.parse(apiData.actualCoordinatesJson)
      : [];
  }

  if (apiData.rejectedCoordinatesJson) {
    coordinatesJson.rejected = apiData.rejectedCoordinatesJson
      ? JSON.parse(apiData.rejectedCoordinatesJson)
      : [];
  }

  if (apiData.lastDaysActualCoordinatesJson?.length > 0) {
    apiData.lastDaysActualCoordinatesJson.forEach((item) => {
      const parsedPreviousActual = JSON.parse(item) || [];
      coordinatesJson.previousActual.push(...parsedPreviousActual);
    });
  }

  if (apiData.currentDayWosPlannedCoordinates?.length > 0) {
    apiData.currentDayWosPlannedCoordinates.forEach((item) => {
      const parsedTodayPlanned = JSON.parse(item) || [];
      coordinatesJson.todayPlanned.push(...parsedTodayPlanned);
    });
  }

  let planTypeChangeAction: "enable_change" | "disable_change" | "reset_plan" =
    "disable_change";

  if (viewMode === "planner") {
    if (gcPolygons.length <= 0) {
      planTypeChangeAction = "enable_change";
    } else {
      planTypeChangeAction = "reset_plan";
    }
  }

  const workorderPlannedCountsText =
    planType === "non-pv"
      ? `${getFormattedDisplayArea(counts.workorderPlanned.area)}`
      : `${counts.workorderPlanned.tables} | ${getFormattedDisplayArea(
          counts.workorderPlanned.area
        )}`;

  const plannedCountsText =
    plannedPeriodToggle === "workorder"
      ? workorderPlannedCountsText
      : planType === "non-pv"
      ? `${getFormattedDisplayArea(counts.cyclePlanned.area)}`
      : `${counts.cyclePlanned.tables} | ${getFormattedDisplayArea(
          counts.cyclePlanned.area
        )}`;

  const actualCountsText =
    actualPeriodToggle === "workorder"
      ? planType === "non-pv"
        ? `${getFormattedDisplayArea(counts.workorderActual.area)}`
        : `${counts.workorderActual.tables} | ${getFormattedDisplayArea(
            counts.workorderActual.area
          )}`
      : planType === "non-pv"
      ? `${getFormattedDisplayArea(counts.cycleActual.area)}`
      : `${counts.cycleActual.tables} | ${getFormattedDisplayArea(
          counts.cycleActual.area
        )}`;

  let viewModeStyle = {
    fillColor: "#808080",
    fillOpacity: 0,
    strokeColor: "#FFFFFF",
    strokeOpacity: 1,
    strokeWeight: 1,
    clickable: false,
    editable: false,
    zIndex: 2,
  };

  if (viewMode === "planner") {
    viewModeStyle.fillColor = STATUS_COLORS.PLANNED;
    viewModeStyle.fillOpacity = 0.3;
  } else if (viewMode === "actual") {
    viewModeStyle.fillColor = STATUS_COLORS.ACTUAL;
    viewModeStyle.fillOpacity = 0.3;
  } else if (viewMode === "rejection") {
    viewModeStyle.fillColor = STATUS_COLORS.REJECTED;
    viewModeStyle.fillOpacity = 0.3;
  } else {
    viewModeStyle.fillOpacity = 0.3;
  }

  // Map container style - Full width & height
  const mapContainerStyle = {
    width: "100%",
    height: isFullScreen ? "100vh" : "600px", // Use viewport height when in full-screen
  };

  // Load required scripts
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: apiKey,
    libraries: libraries as Library[], // Ensure the drawing library is loaded
  });

  // Map load callback
  const onMapLoad = useCallback((mapInstance: google.maps.Map) => {
    mapInstance.setOptions({
      styles: mapStyles, // custom map style array
      mapTypeId: google.maps.MapTypeId[mapType],
    });
    setMap(mapInstance);
  }, []);

  // Map tiles loaded callback
  const onTilesLoaded = useCallback(() => {
    if (customControlsRef.current) {
      customControlsRef.current.style.display = "block";
    }
  }, []);

  const calculateArea = (polygon: google.maps.Polygon) => {
    const path = polygon.getPath();
    const area = google.maps.geometry.spherical.computeArea(path);

    return area;
  };

  // Drawing polygons from GeoJson data
  useEffect(() => {
    if (!map || !apiData) return;

    const features = plainJson.features;

    // Bounds to encompass all the polygons -  this will be the viewport of the map
    const bounds = new google.maps.LatLngBounds();

    const newPolygons = features.map((feature: any) => {
      const coordinates = feature.geometry.coordinates[0][0].map(
        (coord: any) => {
          // Extend the bounds to include each polygon's bounds
          bounds.extend(new google.maps.LatLng(coord[1], coord[0]));
          return {
            lng: coord[0],
            lat: coord[1],
          };
        }
      );

      const properties = feature.properties;

      // Create a style object based on the properties
      const defaultStyle = {
        strokeColor: properties[STYLE_MAPPING.strokeColor],
        strokeOpacity: properties[STYLE_MAPPING.strokeOpacity],
        strokeWeight: properties[STYLE_MAPPING.strokeWeight],
        fillColor: properties[STYLE_MAPPING.fillColor],
        fillOpacity: properties[STYLE_MAPPING.fillOpacity],
      };

      const style = { ...defaultStyle };

      let disableSelection = false;
      let disableTooltip = false;

      // const polygonId = feature.id;
      const polygonId = properties.id;
      const tableArea = Number(properties.Area);

      originalStylesRef.current.set(polygonId, defaultStyle);

      if (polygonId === "outer") {
        disableSelection = true;
        disableTooltip = true;
      }

      const polygonsData = new google.maps.Polygon({
        paths: coordinates,
        ...style,
        clickable: polygonId === "outer" ? false : true,
        zIndex: polygonId === "outer" ? 0 : 1,
      });

      polygonsData.set("polygonId", polygonId);
      polygonsData.set("tableArea", tableArea);
      polygonsData.set("disableSelection", disableSelection);
      polygonsData.set("disableTooltip", disableTooltip);

      // Add event listener on polygon hover to show info window
      polygonsData.addListener("mouseover", () => {
        const stringId = properties.stringId;
        if (!disableTooltip) {
          const middleCoords = polygonsData
            .getPath()
            .getArray()
            .slice(0, 2)
            .reduce(
              (acc, coord) => ({
                lng: acc.lng + coord.lng(),
                lat: acc.lat + coord.lat(),
              }),
              { lng: 0, lat: 0 }
            );

          const infoWindow = new google.maps.InfoWindow({
            content: "<p class='info-window-text'>" + stringId + "</p>",
            position: {
              lng: middleCoords.lng / 2,
              lat: middleCoords.lat / 2,
            },
          });

          infoWindow.open(map);
          polygonsData.addListener("mouseout", () => infoWindow.close());
        }
      });

      // Add event listener for polygon selection
      // polygonsData.addListener("click", () => {
      //   handlePolygonClick(polygonsData);
      // });

      polygonsData.setMap(map);

      return polygonsData;
    });

    // Check if bounds have been extended; if not, use indiaBounds
    if (bounds.isEmpty()) {
      // Bounds to show all India
      const indiaBounds = new google.maps.LatLngBounds(
        new google.maps.LatLng(6.5546079, 68.1113787), // southwest corner of India
        new google.maps.LatLng(35.6745457, 97.402561) // northeast corner of India
      );
      map.fitBounds(indiaBounds);
    } else {
      map.fitBounds(bounds);
    }

    // Grass Cutting Polygons

    let updatedGCPolygons: google.maps.Polygon[] = [];

    let jsonToDisplay: any = []; // None

    if (viewMode === "planner") {
      jsonToDisplay = ["planned", "actual", "previousActual", "todayPlanned"];
    } else if (viewMode === "actual") {
      jsonToDisplay = ["planned", "actual"];
    } else if (viewMode === "rejection") {
      jsonToDisplay = ["actual", "rejected"];
    } else {
      jsonToDisplay = ["planned", "actual", "rejected"];
    }

    Object.keys(coordinatesJson).forEach((jsonKey) => {
      if (jsonToDisplay.includes(jsonKey)) {
        const polygonStyle = { ...viewModeStyle };

        let gcJsonKey: "planned" | "actual" | null = null;

        if (jsonKey === "planned") {
          polygonStyle.fillColor = STATUS_COLORS.PLANNED;
          if (viewMode === "planner") {
            polygonStyle.clickable = true;
            gcJsonKey = jsonKey;
          }
        } else if (jsonKey === "actual") {
          polygonStyle.fillColor = STATUS_COLORS.ACTUAL;
          if (viewMode === "actual") {
            polygonStyle.clickable = true;
            gcJsonKey = jsonKey;
          }
        } else if (jsonKey === "todayPlanned") {
          polygonStyle.fillColor = STATUS_COLORS.PLANNED_TODAY;
          polygonStyle.clickable = false;
        } else if (jsonKey === "previousActual") {
          polygonStyle.fillColor = STATUS_COLORS.ACTUAL;
          polygonStyle.clickable = false;
        } else if (jsonKey === "rejected") {
          polygonStyle.fillColor = STATUS_COLORS.REJECTED;
          polygonStyle.clickable = false;
        }

        const newGCPolygons = coordinatesJson[jsonKey].map((geometryData) => {
          const gcCoordinates = geometryData.geometry.coordinates[0][0].map(
            (coord: any) => ({
              lng: coord[0],
              lat: coord[1],
            })
          );

          const newGCPolygon = new google.maps.Polygon({
            paths: gcCoordinates,
            ...polygonStyle,
          });

          newGCPolygon.setMap(map);

          return newGCPolygon;
        });

        if (gcJsonKey === jsonKey) {
          updatedGCPolygons = newGCPolygons;
        }
      }
    });

    setPolygons(newPolygons);
    setGCPolygons(updatedGCPolygons);
  }, [map, apiData]);

  const getFormattedGCPolygons = (): string => {
    const formattedGCPolygons: GeometryDataType[] = gcPolygons.map(
      (gcPolygon) => {
        const gcPolygonPaths = gcPolygon
          .getPaths()
          .getArray()
          .map((path) => {
            return path
              .getArray()
              .map((latLng) => [latLng.lng(), latLng.lat(), 0.0]);
          });
        return {
          geometry: {
            type: "MultiPolygon",
            coordinates: [gcPolygonPaths],
          },
        };
      }
    );
    return JSON.stringify(formattedGCPolygons);
  };

  function getCentroid(path: google.maps.MVCArray<google.maps.LatLng>) {
    const length = path.getLength();
    let sumLng = 0;
    let sumLat = 0;
    for (let i = 0; i < length; i++) {
      const vertex = path.getAt(i);
      sumLng += vertex.lng();
      sumLat += vertex.lat();
    }
    return new google.maps.LatLng(sumLat / length, sumLng / length);
  }

  // Return polygons within the drawn selection shape
  const selectPolygonsWithin = useCallback(
    (outerShape: google.maps.Polygon) => {
      if (planType === "non-pv") {
        let isPartiallyWithin = false;

        outerLoop: for (const innerPolygon of polygons) {
          // Disable selection or deselection of outer boundary polygon
          if (!innerPolygon.get("disableSelection")) {
            const innerPath = innerPolygon.getPath();

            for (let i = 0; i < innerPath.getLength(); i++) {
              const vertex = innerPath.getAt(i);
              // Check if the vertex is not within the outerShape
              if (
                google.maps.geometry.poly.containsLocation(vertex, outerShape)
              ) {
                isPartiallyWithin = true;
                break outerLoop;
              }
            }
          }
        }

        if (isPartiallyWithin) {
          openConfirmationModal(
            "alert",
            "gcPlanner.selectNonPvError",
            () => {
              // Negative
              outerShape.setMap(null); // Optional: remove the drawn selction shape from the map
              return;
            },
            () => {
              // Positive
              outerShape.setMap(null); // Optional: remove the drawn selction shape from the map
              return;
            }
          );
        } else {
          const outerShapeArea = calculateArea(outerShape);
          gcPolygons.forEach((gcPolygon) => {
            gcPolygon.setMap(null);
          });
          setGCPolygons(() => [outerShape]);
          onCountsChange(viewMode, {
            tables: 0,
            area: outerShapeArea,
          });
        }
      } else {
        let withinTablesCount = 0;
        let withinTablesArea = 0;
        polygons.forEach((innerPolygon) => {
          // Disable selection or deselection of outer boundary polygon
          if (!innerPolygon.get("disableSelection")) {
            const innerPath = innerPolygon.getPath();

            if (
              google.maps.geometry.poly.containsLocation(
                getCentroid(innerPath),
                outerShape
              )
            ) {
              withinTablesCount += 1;
              withinTablesArea += innerPolygon.get("tableArea");
            }
          }
        });

        if (withinTablesCount > 0) {
          const outerShapeArea = calculateArea(outerShape);

          let calculatedArea = 0;

          if (planType === "under-table") {
            calculatedArea = withinTablesArea;
          } else if (planType === "between-table") {
            calculatedArea = outerShapeArea - withinTablesArea;
          }

          gcPolygons.forEach((gcPolygon) => {
            gcPolygon.setMap(null);
          });
          setGCPolygons(() => [outerShape]);
          onCountsChange(viewMode, {
            tables: withinTablesCount,
            area: calculatedArea,
          });
        } else {
          openConfirmationModal(
            "alert",
            "gcPlanner.selectPvError",
            () => {
              // Negative
              outerShape.setMap(null); // Optional: remove the drawn selction shape from the map
              return;
            },
            () => {
              // Positive
              outerShape.setMap(null); // Optional: remove the drawn selction shape from the map
              return;
            }
          );
        }
      }
    },
    [polygons, planType, gcPolygons]
  );

  // Select polygons when selction drawing completes
  const onOverlayComplete = useCallback(
    (event: google.maps.drawing.OverlayCompleteEvent) => {
      if (event.type === google.maps.drawing.OverlayType.POLYGON) {
        const shape = event.overlay as google.maps.Polygon;
        if (shape.getPath().getLength() < 3) {
          //  Remove incomplete polygon
          shape.setMap(null);
          return;
        }
        selectPolygonsWithin(shape);
      }
    },
    [selectPolygonsWithin]
  );

  // Function to handle drawing mode change
  const handleDrawingModeChange = () => {
    if (!drawingMode) {
      setEditMode(null);
      setDrawingMode(google.maps.drawing.OverlayType.POLYGON);
    }
  };

  // const handleEditModeChange = () => {
  //   if (!editMode) {
  //     setDrawingMode(null);
  //     setEditMode("delete");
  //   }
  // };

  const handleStopDrawing = () => {
    setDrawingMode(null);
    setEditMode(null);
  };

  // Function to handle toggling fullscreen mode
  const handleToggleFullScreen = () => {
    if (mapContainerRef.current) {
      const mapDiv = mapContainerRef.current;
      if (document.fullscreenElement) {
        document.exitFullscreen();
        setIsFullScreen(false);
      } else {
        mapDiv.requestFullscreen();
        setIsFullScreen(true);
      }
    }
  };

  // Function to handle zoom changes
  const handleZoomChange = (increment: boolean) => {
    if (map) {
      const currentZoom = map.getZoom() || 0;
      map.setZoom(increment ? currentZoom + 1 : currentZoom - 1);
    }
  };

  const onCycleClick = (cycle: number) => {
    refreshComponent();
  };

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <Box component="main" className="planner-main">
      {isLoading && <Loader />}
      {openErrorModal && (
        <ErrorModal
          descriptionText={errorDesc}
          open={openErrorModal}
          handleClose={() => setOpenErrorModal(false)}
          onPositiveClick={() => {
            setOpenErrorModal(false);
          }}
        />
      )}
      {openModal && (
        <ModalPopup
          descriptionText={"SavedSuccessfully"}
          open={openModal}
          handleClose={() => {
            setOpenModal(false);
            refreshComponent();
          }}
          onPositiveClick={() => {
            setOpenModal(false);
            refreshComponent();
          }}
          hideButton={true}
          positiveButtonLabelId="OKButtonText"
        />
      )}
      {isModalOpen && (
        <ModalPopup
          descriptionText={modalContent.description}
          open={isModalOpen}
          handleClose={() => {}}
          onPositiveClick={handleModalPositive}
          onNegativeClick={handleModalNegative}
          positiveButtonLabel="OK"
          positiveButtonLabelId="OKButtonText"
          negativeButtonLabel="No"
          negativeButtonLabelId="NoText"
          hideButton={true}
        />
      )}
      {isConfirmModalOpen && (
        <ModalPopup
          descriptionText={modalContent.description}
          open={isConfirmModalOpen}
          handleClose={() => {}}
          onPositiveClick={handleModalPositive}
          onNegativeClick={handleModalNegative}
          positiveButtonLabel="Yes"
          positiveButtonLabelId="YesText"
          negativeButtonLabel="No"
          negativeButtonLabelId="NoText"
        />
      )}
      <Formik
        initialValues={formValues}
        enableReinitialize
        validateOnChange
        validationSchema={validationSchema}
        onSubmit={(values) => {
          const saveAction =
            viewMode === "planner"
              ? "save-plan"
              : viewMode === "actual"
              ? "save-actual"
              : viewMode === "rejection"
              ? "partial-reject"
              : "";
          if (values) {
            handleSaveGrassCuttingPlannerApi(saveAction, values);
          } else {
            handleSaveGrassCuttingPlannerApi(saveAction);
          }
        }}
      >
        {({ values, setFieldValue, handleSubmit, errors, touched }) => {
          return (
            <Box className="outer-form-wrapper">
              <Box
                className="planner-title flex__"
                sx={{ marginBottom: "10px" }}
                alignItems={"center"}
              >
                <Box>
                  <MediumTypography
                    label={apiData.cardDetailsInfo.type}
                    textColor="#626F86"
                    fontSize="14px"
                    fontWeight={400}
                  />
                </Box>
                <Box className="position__relative" sx={{ left: "5px" }}>
                  <MediumTypography
                    label={"/"}
                    textColor="#626F86"
                    fontSize="14px"
                    fontWeight={400}
                  />
                </Box>
                <Box className="position__relative" sx={{ left: "10px" }}>
                  <MediumTypography
                    labelId={
                      viewMode === "planner"
                        ? "PlanYourDayText"
                        : viewMode === "actual"
                        ? "planner.markActualsText"
                        : viewMode === "rejection"
                        ? "planner.markRejectionsText"
                        : "planner.viewOnlyText"
                    }
                    defaultLabel="Plan your day"
                    textColor="#FFFFFF"
                    fontSize="14px"
                    fontWeight={600}
                  />
                </Box>
                <Box className="flex__" sx={{ marginLeft: "auto" }}>
                  <Box className="flex__">
                    <ButtonComponent
                      className="btn-primary btn-cancel "
                      labelId="btn.cancel"
                      defaultLabelId="Cancel"
                      onClick={() =>
                        navigate(SCHEDULER_DASHBOARD, {
                          state: {
                            date: navState.date,
                            dateRange: navState.dateRange,
                            schedulerViewChange:
                              navState.schedulerViewChange ?? "team",
                          },
                        })
                      }
                    />
                    {viewMode && (
                      <ButtonComponent
                        className="btn-primary btn-submit "
                        labelId="btn.save"
                        defaultLabelId="Save"
                        onClick={handleSubmit}
                      />
                    )}
                  </Box>
                </Box>
              </Box>
              <Grid container className="planner-title-divider mt-xs">
                <Grid item xs={12} lg={12} md={12} xl={12}>
                  <Box>
                    <Divider
                      variant="fullWidth"
                      sx={{ borderTop: "1px solid #333b43" }}
                    />
                  </Box>
                </Grid>
              </Grid>

              {apiData.status?.length > 0 &&
                apiData.definedScheduleStatus?.length > 0 && (
                  <StatusExecution
                    statusArray={apiData.status}
                    cardId={navState.cardId}
                    WOUniqueId={apiData.cardDetailsInfo.sequenceId}
                    definedScheduleStatus={apiData.definedScheduleStatus}
                    statusCallBack={() => {}}
                    code={apiData.cardDetailsInfo.code}
                  />
                )}
              <Box className="planner-wrapper" display={"flex"}>
                <Box className="map-wrapper" flexGrow={1}>
                  {showMap && (
                    <Box ref={mapContainerRef}>
                      <Box style={{ position: "relative", width: "100%" }}>
                        <Box ref={customControlsRef} sx={{ display: "none" }}>
                          <Box
                            sx={{
                              position: "absolute",
                              bottom: "30px",
                              right: "20px",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-end",
                              zIndex: 11,
                              // "& > :not(style)": { m: 1 },
                            }}
                            className="custom-controls"
                          >
                            <FabWithTooltip
                              title="planner.fullScreenText"
                              iconSrc={!isFullScreen ? FullScreenIcon : ""}
                              icon={
                                isFullScreen ? (
                                  <CloseFullscreenIcon
                                    sx={{ color: "#FFF", fontSize: 20 }}
                                  />
                                ) : (
                                  ""
                                )
                              }
                              placement="left"
                              onFabClick={handleToggleFullScreen}
                            />

                            <FabWithTooltip
                              title="SelectText"
                              bgColor={
                                drawingMode ===
                                google.maps.drawing.OverlayType.POLYGON
                                  ? "rgba(23, 95, 250, 0.9)"
                                  : "rgba(29, 33, 37, 0.9)"
                              }
                              iconSrc={PenToolIcon}
                              placement="left"
                              onFabClick={() => {
                                if (gcPolygons.length > 0) {
                                  openConfirmationModal(
                                    "confirm",
                                    "gcPlanner.existingAreaWillRemove",
                                    () => {
                                      // Negative
                                      return;
                                    },
                                    () => {
                                      // Positive
                                      handleDrawingModeChange();
                                    }
                                  );
                                } else {
                                  handleDrawingModeChange();
                                }
                              }}
                            />

                            {/* <FabWithTooltip
                              title="planner.deleteText"
                              bgColor={
                                editMode === "delete"
                                  ? "rgba(217, 31, 17, 0.9)"
                                  : "rgba(29, 33, 37, 0.9)"
                              }
                              icon={<DeleteIcon sx={{ color: "#FFF" }} />}
                              // iconSrc={StoppingHandIcon}
                              placement="left"
                              onFabClick={() => handleEditModeChange()}
                            /> */}

                            <FabWithTooltip
                              title="planner.stopText"
                              bgColor={
                                !drawingMode && !editMode
                                  ? "rgba(23, 95, 250, 0.9)"
                                  : "rgba(29, 33, 37, 0.9)"
                              }
                              iconSrc={StoppingHandIcon}
                              placement="left"
                              onFabClick={handleStopDrawing}
                            />

                            <ButtonGroup
                              orientation="vertical"
                              aria-label="Zoom Buttons"
                              variant="contained"
                              sx={{
                                marginTop: "7px",
                                borderRadius: "4px",
                                boxShadow: "none",
                                "& > :not(:last-child)": {
                                  borderBottom: "none",
                                },
                              }}
                            >
                              <CustomTooltip
                                title="planner.zoomInText"
                                aria-label="Zoom In"
                                placement="left"
                              >
                                <CustomButton
                                  key="zin"
                                  onClick={() => handleZoomChange(true)}
                                >
                                  <img src={ZoomInIcon} alt="Zoom In" />
                                </CustomButton>
                              </CustomTooltip>

                              <CustomTooltip
                                title="planner.zoomOutText"
                                aria-label="Zoom Out"
                                placement="left"
                              >
                                <CustomButton
                                  key="zout"
                                  onClick={() => handleZoomChange(false)}
                                >
                                  <img src={ZoomOutIcon} alt="Zoom Out" />
                                </CustomButton>
                              </CustomTooltip>
                            </ButtonGroup>
                          </Box>
                        </Box>

                        <GoogleMap
                          mapContainerStyle={mapContainerStyle}
                          center={center}
                          onLoad={onMapLoad}
                          onTilesLoaded={onTilesLoaded}
                          zoom={5}
                          options={{
                            fullscreenControl: false,
                            zoomControl: false,
                          }}
                        >
                          <DrawingManager
                            drawingMode={drawingMode}
                            onOverlayComplete={onOverlayComplete}
                            options={{
                              drawingControl: false,
                              drawingControlOptions: {
                                drawingModes: [
                                  google.maps.drawing.OverlayType.POLYGON,
                                ],
                                position:
                                  google.maps.ControlPosition.RIGHT_CENTER,
                              },
                              polygonOptions: {
                                ...viewModeStyle,
                                clickable: true,
                                zIndex: 3,
                              },
                            }}
                          />
                        </GoogleMap>
                      </Box>
                    </Box>
                  )}
                </Box>
                <Box
                  className="sidebar-wrapper pl-md pb-md pr-md tableStyles"
                  sx={{ height: "600px", overflowY: "auto" }}
                >
                  {apiData.cardDetailsInfo?.sequenceId > 0 && (
                    <CardComponentSidebar
                      cardDataArray={apiData.cardDetailsInfo}
                    />
                  )}

                  <Box
                    className="holder-boxes cycle-holder"
                    display={"flex"}
                    flexDirection={"column"}
                    gap={2}
                  >
                    <Box
                      className="cycle-header"
                      display={"flex"}
                      gap={2}
                      justifyContent={"space-between"}
                    >
                      <Box display={"flex"} alignItems={"center"} gap={1}>
                        <MediumTypography
                          className="holder-box-title"
                          labelId="CurrentCycle"
                          defaultLabel="Current Cycle"
                        />
                        <Box>
                          <Chip
                            className="count-box"
                            label={`${zeroPrefixedValue(
                              currentCycle.cycleNumber
                            )}/${zeroPrefixedValue(currentCycle.cycleNumber)}`}
                          />
                        </Box>
                      </Box>
                      <Box
                        className="color-indicator-box"
                        display={"flex"}
                        alignItems={"center"}
                        gap={2}
                      >
                        <Box display={"flex"} alignItems={"center"} gap={1}>
                          <Box
                            className="color-circle"
                            sx={{ bgcolor: STATUS_COLORS.PLANNED }}
                          />
                          <MediumTypography
                            labelId="PlannedLabelText"
                            defaultLabel="Planned"
                          />
                        </Box>
                        <Box display={"flex"} alignItems={"center"} gap={1}>
                          <Box
                            className="color-circle"
                            sx={{ bgcolor: STATUS_COLORS.ACTUAL }}
                          />
                          <MediumTypography
                            labelId="ActualLabelText"
                            defaultLabel="Actual"
                          />
                        </Box>
                      </Box>
                    </Box>
                    <Box className="cycle-content">
                      {cyclesInfo?.map((cycle) => (
                        <CycleProgressBar
                          key={cycle.cycleNumber}
                          cycle={cycle.cycleNumber}
                          planned={cycle.plannedPercentage}
                          actual={cycle.actualPercentage}
                          cumulativeCount={cycle.cumulativeCount}
                          activeCycle={cycle.currentCycle}
                          onCycleClick={onCycleClick}
                        />
                      ))}
                    </Box>
                  </Box>

                  {apiData.cardDetailsInfo?.sequenceId > 0 && (
                    <Box className="form-wrapper pt-md">
                      <Grid
                        container
                        direction={"row"}
                        className="justifyContentSpaceBtw"
                        alignItems={"center"}
                        rowSpacing={"16px"}
                        columnSpacing={"12px"}
                        sx={{ marginBottom: "20px" }}
                      >
                        <Grid item xs={12}>
                          <ToggleButtonGroup
                            className="plan-type-toggle-group"
                            value={planType}
                            exclusive
                            onChange={handlePlanTypeChange}
                            aria-label="plan type"
                            fullWidth
                          >
                            <Grid container>
                              <Grid item xs={6} sx={{ pr: "4px" }}>
                                <ToggleButton
                                  className="plan-type-toggle"
                                  value="between-table"
                                  aria-label="Btw the table"
                                  fullWidth
                                >
                                  <Box
                                    display={"flex"}
                                    gap={1}
                                    alignItems={"center"}
                                    justifyContent={"center"}
                                  >
                                    <AreaIcon
                                      fill={
                                        planType === "between-table"
                                          ? "rgba(108, 221, 248, 1)"
                                          : "rgba(255, 255, 255, 0.4)"
                                      }
                                    />
                                    <MediumTypography
                                      className="toggleButtonLabel"
                                      labelId="gcPlanner.betweenTableText"
                                      defaultLabel="Btw the table"
                                    />
                                  </Box>
                                </ToggleButton>
                              </Grid>
                              <Grid item xs={6} sx={{ pl: "4px" }}>
                                <ToggleButton
                                  className="plan-type-toggle"
                                  value="under-table"
                                  aria-label="Under the table"
                                  fullWidth
                                >
                                  <Box
                                    display={"flex"}
                                    gap={1}
                                    alignItems={"center"}
                                    justifyContent={"center"}
                                  >
                                    <AreaIcon
                                      fill={
                                        planType === "under-table"
                                          ? "rgba(108, 221, 248, 1)"
                                          : "rgba(255, 255, 255, 0.4)"
                                      }
                                    />
                                    <MediumTypography
                                      className="toggleButtonLabel"
                                      labelId="gcPlanner.underTableText"
                                      defaultLabel="Under the table"
                                    />
                                  </Box>
                                </ToggleButton>
                              </Grid>
                              <Grid item xs={12} sx={{ pt: "8px" }}>
                                <ToggleButton
                                  className="plan-type-toggle"
                                  value="non-pv"
                                  aria-label="Non PV Area"
                                  fullWidth
                                >
                                  <Box
                                    display={"flex"}
                                    gap={1}
                                    alignItems={"center"}
                                    justifyContent={"center"}
                                  >
                                    <AreaIcon
                                      fill={
                                        planType === "non-pv"
                                          ? "rgba(108, 221, 248, 1)"
                                          : "rgba(255, 255, 255, 0.4)"
                                      }
                                    />
                                    <MediumTypography
                                      className="toggleButtonLabel"
                                      labelId="gcPlanner.nonPvText"
                                      defaultLabel="Non PV Area"
                                    />
                                  </Box>
                                </ToggleButton>
                              </Grid>
                            </Grid>
                          </ToggleButtonGroup>
                        </Grid>
                        <Grid item xs={12}>
                          <MediumTypography
                            className="inputLabel"
                            labelId={
                              planType === "non-pv"
                                ? "gcPlanner.plannedWOAreaLabelText"
                                : "gcPlanner.plannedWOTablesAreaLabelText"
                            }
                            defaultLabel={
                              planType === "non-pv"
                                ? "Planned Area"
                                : "Planned Tables | Area"
                            }
                          />

                          <TextInput
                            labelId={
                              planType === "non-pv"
                                ? "gcPlanner.noWOAreaInputText"
                                : "gcPlanner.noWOTablesAreaInputText"
                            }
                            defaultLabelId={
                              planType === "non-pv"
                                ? "-- No area planned --"
                                : "-- No tables/area planned --"
                            }
                            borderColor="1px solid rgba(166, 197, 226, 0.16)"
                            variant="outlined"
                            className="inputField"
                            backGroundColor="#22272B"
                            Value={workorderPlannedCountsText}
                            inputProps={{ readOnly: true, disabled: true }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <MediumTypography
                            className="inputLabel"
                            labelId="GrassCuttingMethodLabelText"
                            defaultLabel="Grass Cutting Method"
                          />
                          <DropdownComponent
                            names={cuttingMethodList}
                            labelid="NoInputText"
                            defaultlabelid="-- Not assigned --"
                            value={
                              values.selectedGCMethodId === 0 ||
                              values.selectedGCMethodId === null
                                ? ""
                                : values.selectedGCMethodId.toString()
                            }
                            defaultValue={
                              values.selectedGCMethodId === 0 ||
                              values.selectedGCMethodId === null
                                ? ""
                                : values.selectedGCMethodId.toString()
                            }
                            textColor={
                              viewMode === "planner"
                                ? "rgba(255, 255, 255, 1)"
                                : "rgba(140, 155, 171, 1)"
                            }
                            handleChange={(value: string) => {
                              setFieldValue("selectedGCMethodId", value);
                            }}
                            className="inputField inputFieldDropdown"
                            disabled={viewMode === "planner" ? false : true}
                          />
                          {errors?.selectedGCMethodId &&
                            touched?.selectedGCMethodId && (
                              <Box className="loginError">
                                <Box>
                                  <ErrorIcon />
                                </Box>
                                <MediumTypography
                                  labelId={errors?.selectedGCMethodId}
                                  defaultLabel="Grass cutting method is required"
                                  fontSize="12px"
                                  textColor="#AE2E24"
                                  fontWeight={400}
                                />
                              </Box>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                          <MediumTypography
                            className="inputLabel"
                            labelId="shift.Text"
                            defaultLabel="Shift"
                          />

                          <TextInput
                            labelId="NoInputText"
                            defaultLabelId="-- Not assigned --"
                            borderColor="1px solid rgba(166, 197, 226, 0.16)"
                            variant="outlined"
                            className="inputField"
                            backGroundColor="#22272B"
                            Value={apiData.shiftTimeRange}
                            inputProps={{ readOnly: true, disabled: true }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Box className="flex__justify__space-between">
                            <MediumTypography
                              className="inputLabel"
                              labelId={
                                planType === "non-pv"
                                  ? "gcPlanner.plannedWOAreaLabelText"
                                  : "gcPlanner.plannedWOTablesAreaLabelText"
                              }
                              defaultLabel={
                                planType === "non-pv"
                                  ? "Planned Area"
                                  : "Planned Tables | Area"
                              }
                            />
                            <MediumTypography
                              className="inputLabel inputChangeLabel"
                              labelId={
                                plannedPeriodToggle === "workorder"
                                  ? "TillDateLinkText"
                                  : "TodayOnlyLinkText"
                              }
                              defaultLabel={
                                plannedPeriodToggle === "workorder"
                                  ? "Till Date"
                                  : "Today"
                              }
                              onClick={() =>
                                setPlannedPeriodToggle((prev) =>
                                  prev === "workorder" ? "cycle" : "workorder"
                                )
                              }
                            />
                          </Box>

                          <TextInput
                            labelId={planType === "non-pv" ? "0" : "0 | 0"}
                            defaultLabelId={
                              planType === "non-pv" ? "0" : "0 | 0"
                            }
                            borderColor="1px solid rgba(166, 197, 226, 0.16)"
                            variant="outlined"
                            className="inputField"
                            backGroundColor="#22272B"
                            Value={plannedCountsText}
                            inputProps={{ readOnly: true, disabled: true }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Box className="flex__justify__space-between">
                            <MediumTypography
                              className="inputLabel"
                              labelId={
                                planType === "non-pv"
                                  ? "gcPlanner.actualWOAreaLabelText"
                                  : "gcPlanner.actualWOTablesAreaLabelText"
                              }
                              defaultLabel={
                                planType === "non-pv"
                                  ? "Achieved Area"
                                  : "Achieved Tables | Area"
                              }
                            />
                            <MediumTypography
                              className="inputLabel inputChangeLabel"
                              labelId={
                                actualPeriodToggle === "workorder"
                                  ? "TillDateLinkText"
                                  : "TodayOnlyLinkText"
                              }
                              defaultLabel={
                                actualPeriodToggle === "workorder"
                                  ? "Till Date"
                                  : "Today"
                              }
                              onClick={() =>
                                setActualPeriodToggle((prev) =>
                                  prev === "workorder" ? "cycle" : "workorder"
                                )
                              }
                            />
                          </Box>

                          <TextInput
                            labelId={planType === "non-pv" ? "0" : "0 | 0"}
                            defaultLabelId={
                              planType === "non-pv" ? "0" : "0 | 0"
                            }
                            borderColor="1px solid rgba(166, 197, 226, 0.16)"
                            variant="outlined"
                            className="inputField"
                            backGroundColor="#22272B"
                            Value={actualCountsText}
                            inputProps={{ readOnly: true, disabled: true }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <MediumTypography
                            className="inputLabel"
                            labelId="VendorLabelText"
                            defaultLabel="Vendor"
                          />

                          <DropdownComponent
                            names={vendorList}
                            value={
                              values.selectedVendorId === 0 ||
                              values.selectedVendorId === null
                                ? ""
                                : values.selectedVendorId.toString()
                            }
                            labelid="NoInputText"
                            defaultlabelid="-- Not assigned --"
                            textColor={
                              viewMode === "planner"
                                ? "rgba(255, 255, 255, 1)"
                                : "rgba(140, 155, 171, 1)"
                            }
                            defaultValue={
                              values.selectedVendorId === 0 ||
                              values.selectedVendorId === null
                                ? ""
                                : values.selectedVendorId.toString()
                            }
                            handleChange={(value: string) => {
                              setFieldValue("selectedVendorId", value);
                            }}
                            className="inputField inputFieldDropdown"
                            disabled={viewMode === "planner" ? false : true}
                          />
                          {errors?.selectedVendorId &&
                            touched?.selectedVendorId && (
                              <Box
                                className="loginError"
                                sx={{ position: "relative" }}
                              >
                                <Box>
                                  <ErrorIcon />
                                </Box>
                                <MediumTypography
                                  labelId={errors?.selectedVendorId}
                                  defaultLabel="Vendor is required"
                                  fontSize="12px"
                                  textColor="#AE2E24"
                                  fontWeight={400}
                                />
                              </Box>
                            )}
                        </Grid>
                        <Grid item xs={6}>
                          <MediumTypography
                            className="inputLabel"
                            labelId="ScheduledStartTimeInput"
                            defaultLabel="Scheduled Start Time"
                          />

                          <DateTimePickerComponent
                            value={
                              values.woPlannedStartTime !== ""
                                ? dayjs(values.woPlannedStartTime)
                                : null
                            }
                            labelid="NoInputText"
                            defaultlabelid="-- Not assigned --"
                            handlechange={(date: Dayjs) => {
                              const formattedText = date.format(
                                "YYYY-MM-DDTHH:mm:ss"
                              );
                              setFieldValue(
                                "woPlannedStartTime",
                                formattedText
                              );
                            }}
                            // disabledDate={viewMode === "planner" ? false : true}
                            disabledDate={true}
                            inputFormat="DD MMM YYYY  HH:mm:ss"
                            width="100%"
                            textWidth="100%"
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <MediumTypography
                            className="inputLabel"
                            labelId="ScheduledEndTimeTextInput"
                            defaultLabel="Scheduled End Time"
                          />

                          <DateTimePickerComponent
                            value={
                              values.woPlannedEndTime !== ""
                                ? dayjs(values.woPlannedEndTime)
                                : null
                            }
                            labelid="NoInputText"
                            defaultlabelid="-- Not assigned --"
                            handlechange={(date: Dayjs) => {
                              const formattedText = date.format(
                                "YYYY-MM-DDTHH:mm:ss"
                              );
                              setFieldValue("woPlannedEndTime", formattedText);
                            }}
                            // disabledDate={viewMode === "planner" ? false : true}
                            disabledDate={true}
                            inputFormat="DD MMM YYYY  HH:mm:ss"
                            width="100%"
                            textWidth="100%"
                          />
                        </Grid>
                        {apiData.recommendation && (
                          <Grid item xs={12}>
                            <MediumTypography
                              className="inputLabel"
                              labelId="Recommendation"
                              defaultLabel="Recommendation"
                            />
                            <TextField
                              className="plannerTextArea"
                              variant="outlined"
                              sx={{
                                border: "1px solid rgba(166, 197, 226, 0.16)",
                                backgroundColor: "#22272B",
                                borderRadius: "4px",
                                "& .MuiOutlinedInput-root": {
                                  "& fieldset": {
                                    borderColor: "rgba(166, 197, 226, 0.16)",
                                  },
                                  "&:hover fieldset": {
                                    borderColor: "rgba(166, 197, 226, 0.16)",
                                  },
                                  "&.Mui-focused fieldset": {
                                    borderColor: "rgba(166, 197, 226, 0.16)",
                                  },
                                  "&.MuiInputBase-root": {
                                    color: "rgba(159, 173, 188, 1)",
                                    fontFamily: "Inter",
                                    fontWeight: 400,
                                    fontSize: "14px",
                                  },
                                },
                                "& .MuiInputLabel-root": {
                                  color: "rgba(159, 173, 188, 1)",
                                  fontFamily: "Inter",
                                  fontWeight: 400,
                                  fontSize: "14px",
                                },
                              }}
                              fullWidth
                              multiline
                              minRows={2}
                              value={apiData.recommendation ?? ""}
                              // onChange={(event) => {
                              //   setMessage(event.target.value.trimStart());
                              //   setFieldValue(
                              //     "cleaningInfoBean.remarks",
                              //     event.target.value.trimStart()
                              //   );
                              // }}
                              inputProps={{ readOnly: true, disabled: true }}
                            />
                          </Grid>
                        )}
                      </Grid>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          );
        }}
      </Formik>
    </Box>
  );
};

export default GrassCuttingPlanner;
