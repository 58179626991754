import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import { FC, useContext, useEffect, useRef, useState } from "react";
import MediumTypography from "../../../components/formlib/MediumTypography";
import ButtonComponent from "../../../components/formlib/ButtonComponent";
import CloseIcon from "@mui/icons-material/Close";
import "../../MasterDataManagement/styles.css";
import { LoaderContext, LoaderContextType } from "../../../layouts/appSidebar";
import ErrorModal from "../../../components/formlib/modal/ErrorModal";

import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import FormikErrorComponent from "../../../components/formlib/FormikErrorComponent";

import CustomDropdown from "../../../components/formlib/CustomDropdown";
import {
  convertToNumber,
  getOptions,
  handleError,
} from "../../../utils/commonFunctions";

import { AddHolidayType, SaveHolidayType } from "../types/holidayTypes";
import TextInput from "../../../components/formlib/TextInput";
import DateTimePickerComponent from "../../../components/formlib/DateTimePickerComponent";
import dayjs, { Dayjs } from "dayjs";
import { trim } from "lodash";

import {
  fetchWizardsDataApi,
  SaveWizardsApi,
} from "../../../api/wizardsApi/Common";

type FetchDataType = AddHolidayType;
type SaveDataType = SaveHolidayType;

const apiUri = "holiday";

interface ModalProps {
  open: boolean;
  handleClose: () => void;
  id?: number;
  getDataCallback: () => void;
  subGroupId: number;
}

const initialData: FetchDataType = {
  name: "",
  holidayTypes: [],
  selectedTypeId: 0,
  date: null,
};
const dropdownValidation = Yup.mixed()
  .nullable()
  .test("is-valid-number", "validation.dropdown", (value) => {
    const theValue = value ? value.toString() : "";
    if (trim(theValue) === "" || theValue === "0") {
      return true;
    }

    return parseFloat(theValue) > 0;
  });
export const customRequiredFn = (value: any) => {
  const theValue = value ? value.toString() : "";
  return trim(theValue) !== "" && theValue !== "0";
};

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required("validation.fieldRequired")
    .max(200, "validation.maxLength200"),

  date: Yup.date().required("validation.fieldRequired"),
  selectedTypeId: dropdownValidation.test(
    "custom-required",
    "validation.dropdown",
    customRequiredFn
  ),
});

const muiColumnProps = {
  xs: 12,
};

const AddEditHolidayModal: FC<ModalProps> = ({
  open,
  handleClose,
  id,
  getDataCallback,
  subGroupId,
}) => {
  const [apiData, setApiData] = useState<FetchDataType>(initialData);
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [errorDesc, setErrorDesc] = useState<string>("");
  const formikRef = useRef<FormikProps<FetchDataType>>(null);

  useEffect(() => {
    if (open) {
      fetchData();
    }
  }, [id, open]);

  const fetchData = () => {
    toggleLoader(true);
    fetchWizardsDataApi<FetchDataType>(apiUri, id, "en")
      .then(async (res) => {
        const updatedRes = { ...initialData, ...res };
        setApiData(updatedRes);
        toggleLoader(false);
      })
      .catch((err) => {
        handleError(err, setErrorDesc);
        setOpenErrorModal(true);
        toggleLoader(false);
      });
  };

  const saveData = (params: SaveDataType) => {
    toggleLoader(true);
    const { holidayTypes, ...updatedParams } = params;

    const url = id ? apiUri : `${subGroupId}/${apiUri}`;

    SaveWizardsApi<SaveDataType>(url, updatedParams, false, id)
      .then(() => {
        formikRef.current?.resetForm({ values: initialData });
        handleClose();
        getDataCallback();
        toggleLoader(false);
      })
      .catch((err) => {
        handleError(err, setErrorDesc);
        setOpenErrorModal(true);
        toggleLoader(false);
      });
  };

  return (
    <>
      {openErrorModal && (
        <ErrorModal
          descriptionText={errorDesc}
          open={openErrorModal}
          handleClose={() => {
            setOpenErrorModal(false);
            handleClose();
          }}
          onPositiveClick={() => {
            setOpenErrorModal(false);
            handleClose();
          }}
        />
      )}

      <Formik
        initialValues={apiData}
        enableReinitialize
        validateOnChange
        innerRef={formikRef}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          const params: SaveDataType = {
            ...values,
            selectedTypeId: convertToNumber(values.selectedTypeId),
          };
          saveData(params);
        }}
      >
        {({ values, setFieldValue, handleSubmit, errors, touched, dirty }) => {
          return (
            <Dialog
              className="account-dialog main-wrapper-box"
              open={open}
              fullWidth
              maxWidth={"xs"}
            >
              <DialogTitle className="padding_20">
                <MediumTypography
                  labelId={id ? "holiday.update" : "holiday.add"}
                  defaultLabel="Holiday Details"
                  textColor="#FFF"
                  fontSize="22px"
                  fontWeight={700}
                />
                <MediumTypography
                  labelId={"common.basicDetails"}
                  defaultLabel="Enter basic details"
                  textColor="rgba(159, 173, 188, 1)"
                  fontSize="14px"
                  fontWeight={400}
                  className="mt-xs"
                />
              </DialogTitle>

              <IconButton
                aria-label="close"
                onClick={() => {
                  formikRef.current?.resetForm({ values: initialData });
                  handleClose();
                }}
                className="closeIcon"
              >
                <CloseIcon />
              </IconButton>

              <DialogContent dividers className="padding_20">
                <Grid container spacing={2}>
                  <Grid item {...muiColumnProps}>
                    <MediumTypography
                      className="input-label"
                      labelId="Holidays.Occasion"
                      defaultLabel="Occasion"
                    />
                    <TextInput
                      className="text-input-field"
                      type="text"
                      variant="outlined"
                      inputProps={{
                        readOnly: false,
                      }}
                      labelId="common.placeHolderText"
                      defaultLabelId="--- type here ---"
                      Value={values.name}
                      handlechange={(value: string) => {
                        setFieldValue("name", value);
                      }}
                    />
                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="name"
                    />
                  </Grid>
                  <Grid item {...muiColumnProps}>
                    <MediumTypography
                      className="input-label"
                      labelId="Holidays.Date"
                      defaultLabel="Date"
                    />
                    <DateTimePickerComponent
                      value={values.date !== null ? dayjs(values.date) : null}
                      labelid="----dd-mm-yyyy----"
                      defaultlabelid="----dd-mm-yyyy----"
                      handlechange={(date: Dayjs) => {
                        const formattedDate = dayjs(date).format("YYYY-MM-DD");
                        setFieldValue("date", formattedDate);
                      }}
                      disabledDate={false}
                      inputFormat="DD-MM-YYYY"
                      width="400px"
                      textWidth="400px"
                      views={["year", "month", "day"]}
                    />

                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="date"
                    />
                  </Grid>
                  <Grid item {...muiColumnProps}>
                    <MediumTypography
                      className="input-label"
                      labelId="Holidays.Type"
                      defaultLabel="Type"
                    />
                    <CustomDropdown
                      disableSearch={true}
                      disableCreate={true}
                      options={getOptions(values.holidayTypes || [])}
                      selectedValue={values.selectedTypeId}
                      onSelectedValueChange={(value) => {
                        setFieldValue("selectedTypeId", value);
                      }}
                      overrideHeight="auto"
                    />
                    <FormikErrorComponent
                      errors={errors}
                      touched={touched}
                      field="selectedTypeId"
                    />
                  </Grid>
                </Grid>
              </DialogContent>

              <DialogActions className="dialogActions">
                <Box>
                  <Grid
                    className="flex__ justifyContent-FlexEnd"
                    container
                    direction="row"
                    alignItems="right"
                  >
                    <Grid item>
                      <ButtonComponent
                        sxProps={{ color: "#B6C2CF !important" }}
                        className="btn-primary btn-cancel mr-md"
                        variantType="outlined"
                        defaultLabelId={"Reset"}
                        labelId={"common.reset"}
                        onClick={() => formikRef.current?.resetForm()}
                        disabled={!dirty}
                      />
                    </Grid>
                    <Grid item>
                      <ButtonComponent
                        className="btn-primary btn-submit"
                        variantType="contained"
                        defaultLabelId={"Save"}
                        labelId={id ? "btn.update" : "btn.Add"}
                        onClick={() => {
                          handleSubmit();
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </DialogActions>
            </Dialog>
          );
        }}
      </Formik>
    </>
  );
};

export default AddEditHolidayModal;
