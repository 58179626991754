import { Box, Dialog, DialogContent, DialogTitle, Grid } from "@mui/material";
import React, { FC } from "react";
import MediumTypography from "../MediumTypography";
import ButtonComponent from "../ButtonComponent";

interface ModalProps {
  open: boolean;
  handleClose: () => void;
  onPositiveClick: () => void;
  descriptionText: string;
}

const ErrorModal: FC<ModalProps> = ({
  open,
  handleClose,
  descriptionText,
  onPositiveClick,
}) => {
  return (
    <Dialog open={open} fullWidth maxWidth={"xs"}>
      <DialogTitle sx={{ backgroundColor: "#1D2125" }}>
        <MediumTypography
          labelId={"ErrorModal.Header"}
          defaultLabel="Error"
          textColor="#B6C2CF"
          fontSize="20px"
          fontWeight={700}
        />
      </DialogTitle>

      <DialogContent sx={{ backgroundColor: "#1D2125" }}>
        <Box>
          <Box className="flex__">
            <MediumTypography
              labelId={descriptionText}
              textColor="#9FADBC"
              fontSize="16px"
              fontWeight={400}
            />
          </Box>
          <Box className="horizontalLine" sx={{ marginY: "10px" }} />
          <Box>
            <Grid
              className="flex__ justifyContent-FlexEnd"
              container
              direction="row"
              alignItems="right"
            >
              <Grid item>
                <ButtonComponent
                  className="btn-primary btn-submit mr-md"
                  variantType="contained"
                  defaultLabelId={"Ok"}
                  labelId={"OkText"}
                  onClick={onPositiveClick}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ErrorModal;
