import {
  Avatar,
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import MediumTypography from "../../../components/formlib/MediumTypography";
import {
  AnomalyList,
  AssetGroup,
  Category,
  CorrectiveMaintenanceDetails,
  TableContentData,
  materialCodesData,
  materialData,
} from "./types";
import dayjs, { Dayjs } from "dayjs";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as CrossIcon } from "../../../assets/images/_CrossIcon_.svg";
import SmallBorderLine from "../../../components/formlib/SmallBorderLine";
import StatusExecution from "../../../components/formlib/StatusExecution";
import ButtonComponent from "../../../components/formlib/ButtonComponent";
import DateTimePickerComponent from "../../../components/formlib/DateTimePickerComponent";
import { Formik, FormikErrors, FormikProps } from "formik";
import * as yup from "yup";
import DropdownComponent from "../../../components/formlib/DropdownComponent";
import { DropDownType } from "../../../api/GrassCutting/GrassCutting";
import { OptionType, ShiftsCardType } from "../../../utils/type";
import TextInput from "../../../components/formlib/TextInput";
import { ReactComponent as DownArrow } from "../../../assets/images/DownArrow.svg";
import { ReactComponent as UpArrow } from "../../../assets/images/UpArrow.svg";
import React, { useContext, useEffect, useRef, useState } from "react";
import { ReactComponent as TickMark } from "../../../assets/images/tick-circle.svg";
import AssetListComponent from "./AssetListComponent";
import SearchDropDown from "../../../components/formlib/SearchDropdown";
import {
  ApproverType,
  AssigneeType,
  ImageType,
  StatusDataType,
} from "../../../api/moduleCleaning/ModuleCleaningApi";
import { ReactComponent as Delete } from "../../../assets/images/trash.svg";
import { LoaderContext, LoaderContextType } from "../../../layouts/appSidebar";
import {
  CorrectiveMaintenanceDetail,
  getCMDetailsApi,
  saveCMDetailsApi,
} from "../../../api/correctiveMaintenance/correctiveMaintenance";
import ErrorModal from "../../../components/formlib/modal/ErrorModal";
import * as Yup from "yup";
import WorkOrderActivity from "../WorkOrderActivity";
import RCAInfo from "./RCAInfo";
import { TeamMemberComponentProps } from "../../../components/formlib/TeamMemberComponent";
import ReassignSidebar, { CardDetailsType } from "../../ReassignSidebar";
import { hasPermission } from "../../../utils/checkPermission";
import ReassignModalPopup from "../../ReassignModalPopup";
import WorkOrderAssignees from "../WorkOrderAssignees";
import { ReactComponent as ErrorIcon } from "../../../assets/images/ErrorIcon.svg";
import { SCHEDULER_DASHBOARD } from "../../../routes/Routing";
import { useIntl } from "react-intl";
import { PMTeamType } from "../preventiveMaintenance/types";
import {
  convertHoursAndMinutesToMinutes,
  convertMinutesToHoursAndMinutes,
} from "../../../api/Constant";
import useAvailableHeight from "../../../utils/useAvailableHeight";
import { ReactComponent as ChevronCircleIcon } from "../../../assets/images/chevronDownCircleIcon.svg";
import WorkOrderGallery from "../WorkOrderGallery";
import { handleError } from "../../../utils/commonFunctions";
import { generateReport } from "../../../api/workOrderApi/schedulerApi/Scheduler";
import { ReactComponent as Download } from "../../../assets/images/DownloadIcon.svg";
import SOPModal from "../../../components/formlib/modal/SOPModal";

interface RowData {
  id: string;
  data: TableContentData;
}

interface SelectedSubCategoryMap {
  [key: string]: DropDownType;
}

const initialcorrectiveMaintenanceValues: CorrectiveMaintenanceDetails = {
  isAssetGrouped: false,
  isAnomalyRequired: false,
  noRequiredStaff: 0,
  noMinStaff: 0,
  version: 0,
  shift: {
    shiftId: 0,
    shiftName: "",
    shiftStartTime: "",
    shiftEndTime: "",
    teams: [],
    cardBlockCount: 0,
    memberCount: 0,
  },
  cardDetailsInfo: {
    code: "",
    type: "",
    assetCatFreqDesc: "",
    workOrderUniqueId: 0,
    frequencyDisplay: "",
    assetNameAndCount: "",
    asset: "",
    lastUpdated: "",
  },
  breakdownDetailBean: {
    selectedAnomalyId: 0,
    anomalyList: [],
    resolutions: [],
    rcaRequired: [],
    breakdownStartDateTime: null,
    breakdownEndDateTime: null,
    selectedResolutionId: 0,
    selectedRcaReqId: 0,
    dcCapacityLost: 0,
    irradiationDuringDownTime: 0,
    curtailment: 0,
    energyLost: 0,
    revenueLost: 0,
    breakDownDescription: "",
    remarks: "",
    observation: "",
  },
  cmAdditionalInfoBean: {
    //selectedReporterId: 0,
    reportedOn: null,
    cumulativeCycleFrequency: 0,
    selectedVendorId: 0,
    contractLineItem: 0,
    selectedScopeId: 0,
    scadaAlarmCode: "",
    vendorsList: [],
    scopes: [],
    lossCategories: [],
    selectedLossCategoryId: 0,
    selectedReportedBy: 0,
    reportMmeberList: [
      {
        id: 0,
        name: "",
        title: "",
        image: "",
        status: "",
        isPrimary: false,
        availability: "",
        date: "",
      },
    ],
    scopeDistribution: 0,
  },

  actualStartDateTime: null,
  actualEndDateTime: null,
  estimatedEffort: 0,
  actualEffort: 0,
  selectedAseetIds: [],
  selectedAssetGroupId: 0,
  selectedAssetCategoryId: 0,
  consumableAndSpares: {
    category: [],
  },
  assignee: [],
  safetyCheckReqAfterCompletion: false,
  sopLink: "",
  definedScheduleStatus: [],
  scheduleExecution: {},
  status: [],
  isRCARequired: false,
};

const validationSchema = Yup.object().shape({
  cardDetailsInfo: Yup.object().shape({
    code: Yup.string().nullable(),
    type: Yup.string().nullable(),
    assetCatFreqDesc: Yup.string().nullable(),
    workOrderUniqueId: Yup.number().required("vaidation.fieldRequired"),
    frequencyDisplay: Yup.string().nullable(),
    assetNameAndCount: Yup.string().nullable(),
    asset: Yup.string().nullable(),
    lastUpdated: Yup.string().nullable(), // ISO date string
  }),
  isAnomalyRequired: Yup.boolean().nullable(),
  breakdownDetailBean: Yup.object().shape({
    anomalyList: Yup.array().of(
      Yup.object().shape({
        anomalyId: Yup.number().nullable(),
        description: Yup.string().nullable(),
      })
    ),
    resolutions: Yup.array().nullable(), // Define the structure if needed
    rcaRequired: Yup.array().of(
      Yup.object().shape({
        id: Yup.number().nullable(),
        name: Yup.string().nullable(),
      })
    ),
    breakdownStartDateTime: Yup.string().required("vaidation.fieldRequired"), // ISO date string or null
    breakdownEndDateTime: Yup.string().required("vaidation.fieldRequired"), // ISO date string or null
    selectedResolutionId: yup
      .number()
      .required("vaidation.fieldRequired")
      .test("is-not-zero", "vaidation.fieldRequired", (value) => value !== 0),
    selectedRcaReqId: yup
      .number()
      .required("vaidation.fieldRequired")
      .test("is-not-zero", "vaidation.fieldRequired", (value) => value !== 0),
    dcCapacityLost: Yup.number().nullable(),
    irradiationDuringDownTime: Yup.number().nullable(),
    curtailment: Yup.number().nullable(),
    energyLost: Yup.number().nullable(),
    revenueLost: Yup.number().nullable(),
    breakDownDescription: Yup.string().required("vaidation.fieldRequired"),
    observation: Yup.string().required("vaidation.fieldRequired"),
    remarks: Yup.string().nullable(),
  }),
  cmAdditionalInfoBean: Yup.object().shape({
    reportedOn: Yup.string().required("vaidation.fieldRequired"), // ISO date string
    cumulativeCycleFrequency: Yup.number().nullable(),
    selectedVendorId: yup.number().nullable(),
    contractLineItem: Yup.number().nullable(),
    selectedScopeId: Yup.number()
      .required("vaidation.fieldRequired")
      .test("is-not-zero", "vaidation.fieldRequired", (value) => value !== 0),
    scadaAlarmCode: Yup.string().nullable(),
    vendorsList: Yup.array().of(
      Yup.object().shape({
        id: Yup.number().nullable(),
        name: Yup.string().nullable(),
        status: Yup.string().nullable(),
      })
    ),
    scopes: Yup.array().nullable(), // Define the structure if needed
    scopeDistribution: Yup.number().nullable(),
    lossCategories: Yup.array().nullable(), // Define the structure if needed
    selectedLossCategoryId: yup
      .number()
      .required("vaidation.fieldRequired")
      .test("is-not-zero", "vaidation.fieldRequired", (value) => value !== 0),
    selectedReportedBy: yup
      .number()
      .required("vaidation.fieldRequired")
      .test("is-not-zero", "vaidation.fieldRequired", (value) => value !== 0),
    reportMmeberList: Yup.array().of(
      Yup.object().shape({
        id: Yup.number().nullable(),
        name: Yup.string().nullable(),
        title: Yup.string().nullable(),
        image: Yup.string().nullable(),
        status: Yup.string().nullable(),
        isPrimary: Yup.boolean().nullable(),
        availability: Yup.string().nullable(),
        date: Yup.string().nullable(), // ISO date string
      })
    ),
  }),
  actualStartDateTime: Yup.string().nullable(), // ISO date string
  actualEndDateTime: Yup.string().nullable(), // ISO date string
  estimatedEffort: Yup.number().required("vaidation.fieldRequired"),
  actualEffort: Yup.number().nullable(),
  selectedAseetIds: Yup.array().of(Yup.number()).nullable(),
  selectedAssetGroupId: Yup.number().nullable(),
  selectedAssetCategoryId: Yup.number().nullable(),
  consumableAndSpares: Yup.object().shape({
    category: Yup.array().nullable(), // Define the structure if needed
  }),
  assignees: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.number().nullable(),
        name: Yup.string().nullable(),
        title: Yup.string().nullable(),
        image: Yup.string().nullable(),
        status: Yup.string().nullable(),
      })
    )
    .nullable(),
  safetyCheckReqAfterCompletion: Yup.boolean().nullable(),
  sopLink: Yup.string().nullable(),
});

const initialCardDetails = {
  cardId: 0,
  sequenceId: 0,
  version: 0,
  members: [],
  maxStaffCount: 0,
  isAssetGrouped: false,
};

interface UserCountsType {
  total: number;
  present: number;
}

const initialShiftData: ShiftsCardType = {
  shiftId: 0,
  shiftName: "",
  cardBlockCount: 0,
  shiftStartTime: "",
  shiftEndTime: "",
  teams: [],
  memberCount: 0,
  cards: [],
};

const CorrectiveMaintenance = () => {
  const activeDate = new Date();
  const [correctiveMaintenance, setCorrectiveMaintenance] =
    useState<CorrectiveMaintenanceDetails>(initialcorrectiveMaintenanceValues);
  // Reassign sidebar
  const [teamData, setTeamData] = useState<TeamMemberComponentProps[]>([]);
  const [cardDetails, setCardDetails] =
    useState<CardDetailsType>(initialCardDetails);
  const [shiftData, setShiftData] = useState<ShiftsCardType>(initialShiftData);
  const [isDragEnabled, setIsDragEnabled] = useState<boolean>(false);
  const [userCounts, setUserCounts] = useState<UserCountsType>({
    total: 0,
    present: 0,
  });
  const [leadAssignee, setLeadAssignee] = useState<AssigneeType>();
  const [assignees, setAssignees] = useState<AssigneeType[]>([]);
  const [reassignModal, setReassignModal] = useState(false);
  // General
  const navigate = useNavigate();
  const [activePage, setActivePage] = useState("details");
  const [cardEdit, setCardEdit] = useState(false);
  const [safetyCheckExpand, setSafetyCheckExpand] = useState(false);
  const [assetCategoryData, setAssetCategoryData] = useState<
    AssetGroup[] | null
  >(null);
  const [viewAllAssets, setViewAllAssets] = useState(false);
  const [toolsTableData, setToolsTableData] = useState<RowData[]>([]);
  const [selectedCMAssetIds, setSelectedCMAssetIds] = useState<number[]>([]);
  const [selectedCMAssetGroupId, setSelectedCMAssetGroupId] =
    useState<number>(0);
  const [selectedCMAssetCategoryId, setSelectedCMAssetCategoryId] =
    useState<number>(0);
  const [approver1, setApprover1] = useState<ApproverType | null | undefined>();
  const [approver2, setApprover2] = useState<ApproverType | null | undefined>();
  const [approver3, setApprover3] = useState<ApproverType | null | undefined>();
  const [selectedSubCategory, setSelectedSubCategory] =
    useState<SelectedSubCategoryMap>({});
  //category
  const [activeRowId, setActiveRowId] = useState<string | null>(null);
  const [subCategories, setSubCategories] = useState<DropDownType[]>([]);
  const [codes, setCodes] = useState<DropDownType[]>([]);
  const [selectedCodes, setSelectedCodes] = useState<SelectedSubCategoryMap>(
    {}
  );
  const [description, setDescription] = useState<DropDownType[]>([]);

  const [selectedDescriptions, setSelectedDescriptions] =
    useState<SelectedSubCategoryMap>({});
  //subCategory
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  //codes
  const [openCode, setOpenCode] = useState(false);
  const [anchorElCode, setAnchorElCode] = useState<null | HTMLElement>(null);
  //description
  const [openDescription, setOpenDescription] = useState(false);
  const [anchorElDescription, setAnchorElDescription] =
    useState<null | HTMLElement>(null);
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;

  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [saveErrorModal, setSaveErrorModal] = useState<boolean>(false);
  const [errorDesc, setErrorDesc] = useState<string>("");
  const navState = location?.state;

  const stateCardId = JSON.parse(navState?.cardId);
  const subGroupId = JSON.parse(navState?.subGroupId);
  const selectedDate = dayjs(navState?.date).format("YYYY-MM-DD");
  const [error, setError] = useState("");
  const intl = useIntl();
  const [editDisable, setEditDisable] = useState(false);
  const [disableAssets, setDisableAssets] = useState(false);
  const [disableSafetyChecks, setDisableSafetyChecks] = useState(false);
  const availableHeight = useAvailableHeight(400);
  const collapseAvailHeight = useAvailableHeight(300);
  const [isVisibleCM, setIsVisibleCM] = useState(true); // expand or collapse filters state

  const [showAwaitingApproval, setShowAwaitingApproval] = useState(false);
  const [lastTransition, setLastTransition] = useState<StatusDataType>(
    {} as StatusDataType
  );
  const [dumgImage, setDumpImage] = useState<ImageType[]>([]);
  const [afterImage, setAfterImage] = useState<ImageType[]>([]);
  const [beforeImage, setBeforeImage] = useState<ImageType[]>([]);
  const [fileUrl, setFileUrl] = useState<string | null>(null);
  const [openTemplateModal, setOpenTemplateModal] = useState(false);

  useEffect(() => {
    if (correctiveMaintenance.status !== undefined) {
      if (
        correctiveMaintenance.status[correctiveMaintenance.status.length - 1]
          ?.code === "CLOSED"
      ) {
        setEditDisable(true);
      } else if (correctiveMaintenance.status[2]?.code === "INPROGRESS") {
        setDisableAssets(true);
      } else {
        setEditDisable(false);
      }

      const cmStatusCode =
        correctiveMaintenance.status[correctiveMaintenance.status.length - 1]
          ?.code;

      if (cmStatusCode === "INPROGRESS" || cmStatusCode === "COMPLETED") {
        setDisableSafetyChecks(false);
      } else {
        setDisableSafetyChecks(true);
      }
    }
  }, [stateCardId, activePage, correctiveMaintenance]);

  const handleSave = (values: CorrectiveMaintenanceDetail) => {
    setCardEdit(false);
    toggleLoader(true);
    saveCMDetailsApi(stateCardId, values)
      .then(() => {
        setCardEdit(false);
        getCMDetails();
        setError("");
      })
      .catch((err) => {
        toggleLoader(false);
        setSaveErrorModal(true);
        setError("");
        handleError(err, setErrorDesc);
      });
  };

  const findSelectedNAIdForLineItem = (lineItemId: number) => {
    const taskCheckList =
      correctiveMaintenance?.taskResponseBean &&
      correctiveMaintenance?.taskResponseBean.tasks[0]?.taskCheckLists.find(
        (tcl) => tcl.lineItemId === lineItemId
      );
    return taskCheckList ? taskCheckList?.selectedNAId : null;
  };

  const handleQuantChange = (
    rowId: string,
    index: number,
    newValue: string
  ) => {
    setToolsTableData((prevToolsTableData: RowData[]) => {
      return prevToolsTableData.map((row) => {
        // Find the row by ID and update its subcategory
        if (row.id === rowId) {
          return {
            ...row,
            data: {
              ...row.data,
              quantity: newValue,
              // Update the subcategory name
              // You might also need to update other related fields here
            },
          };
        }
        // Leave other rows unchanged
        return row;
      });
    });
  };

  useEffect(() => {
    // Assuming toolsList is available in your component's scope

    const transformedSubCategories =
      correctiveMaintenance?.consumableAndSpares.category.map(
        (subCat, index) => ({
          id: subCat?.categoryId, // or any other unique identifier
          name: subCat?.categoryName || "",
        })
      ) || [];

    setSubCategories(transformedSubCategories);
  }, [correctiveMaintenance]);

  const [isRcaEnabled, setIsRcaEnabled] = useState(false);
  const formikRef = useRef<FormikProps<CorrectiveMaintenanceDetails>>(null);

  useEffect(() => {
    // Function to check if RCA is required based on the current selectedRcaReqId
    if (
      correctiveMaintenance.breakdownDetailBean.selectedRcaReqId !== 0 ||
      correctiveMaintenance.breakdownDetailBean.selectedRcaReqId !== undefined
    ) {
      const checkIfRcaIsRequired = () => {
        const selectedOption =
          correctiveMaintenance.breakdownDetailBean.rcaRequired.find(
            (option) =>
              option.id ===
              correctiveMaintenance.breakdownDetailBean.selectedRcaReqId
          );

        return selectedOption?.name === "Yes";
      };

      // Update isRcaEnabled state based on the selectedRcaReqId
      setIsRcaEnabled(checkIfRcaIsRequired());
    }
  }, [
    correctiveMaintenance.breakdownDetailBean.selectedRcaReqId,
    correctiveMaintenance.breakdownDetailBean.rcaRequired,
  ]);

  const handleCloseSubCategory = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  useEffect(() => {
    if (activeRowId) {
      const selectedCategory =
        correctiveMaintenance.consumableAndSpares.category.find(
          (subCat) =>
            subCat.categoryName === selectedSubCategory[activeRowId]?.name
        );

      if (selectedCategory) {
        const alreadySelectedCodes = new Set(
          Object.values(selectedCodes).map((code) => code.name)
        );
        const alreadySelectedDescriptions = new Set(
          Object.values(selectedDescriptions).map((desc) => desc.name)
        );

        const filteredCodes =
          selectedCategory?.materialCodes &&
          selectedCategory?.materialCodes
            .filter((code) => !alreadySelectedCodes.has(code.code))
            .map((code, index) => ({
              id: index + 1, // or any other unique identifier
              name: code.code,
            }));

        const filteredDescriptions =
          selectedCategory?.materialCodes &&
          selectedCategory.materialCodes
            .filter((code) => !alreadySelectedDescriptions.has(code.name))
            .map((code, index) => ({
              id: index + 1,
              name: code.name,
            }));

        if (filteredCodes) {
          setCodes(filteredCodes);
        }

        if (filteredDescriptions) {
          setDescription(filteredDescriptions);
        }
      }
    }
  }, [
    correctiveMaintenance,
    selectedSubCategory,
    selectedCodes,
    selectedDescriptions,
    activeRowId,
  ]);

  useEffect(() => {
    if (activePage === "details") {
      getCMDetails();
    }
  }, [activePage]);

  const getCMDetails = () => {
    if (activePage === "details") {
      toggleLoader(true);
      if (subGroupId) {
        getCMDetailsApi(subGroupId, selectedDate, stateCardId)
          .then((response) => {
            toggleLoader(false);
            if (response) {
              const lastStatus = response.status[response.status.length - 2];
              const showAwaitingApprovals = [
                "COMPLETED",
                "LEVEL1",
                "LEVEL2",
              ].includes(lastStatus.code);

              setShowAwaitingApproval(showAwaitingApprovals);

              // Find the last transition date and time
              const lastTransitionStatus = lastStatus;
              setLastTransition(lastTransitionStatus);

              // For reassign members
              const lead = response.assignee.find((person) => person.isPrimary);
              const assignee = response.assignee.filter(
                (person) => !person.isPrimary
              );

              const updatedShiftData = { ...response.shift, cards: [] };

              const cardMembers = response.assignee.map((member) => ({
                ...member,
                availability: member.availability || "",
              }));

              const updatedCardDetails = {
                cardId: stateCardId,
                sequenceId: response.cardDetailsInfo.workOrderUniqueId,
                version: response.version,
                members: cardMembers,
                maxStaffCount: response.noRequiredStaff,
                isAssetGrouped: response.isAssetGrouped,
              };

              const updatedTeamData: TeamMemberComponentProps[] =
                response.shift?.teams?.map((team: PMTeamType) => ({
                  data: { ...team },
                  shiftType: updatedShiftData.shiftName,
                }));

              const updatedUserCounts = {
                total: updatedTeamData?.length || 0,
                present: cardMembers?.length || 0,
              };

              setCorrectiveMaintenance(response);
              setTeamData(updatedTeamData);
              setCardDetails(updatedCardDetails);
              setShiftData(updatedShiftData);
              setUserCounts(updatedUserCounts);
              setLeadAssignee(lead);
              setAssignees(assignee);
              if (response.dumpImage) {
                setDumpImage(response.dumpImage);
              }
              if (response.afterImage) {
                setAfterImage(response.afterImage);
              }
              if (response.beforeImage) {
                setBeforeImage(response.beforeImage);
              }

              if ("approver1" in response) {
                setApprover1(response.approver1);
              }
              if ("approver2" in response) {
                setApprover2(response.approver2);
              }
              if ("approver3" in response) {
                setApprover3(response.approver3);
              }
              setConsumeablesData(response.consumableAndSpares.category); // Please check this entire Logic.
              setAssetCategoryData(response?.assetGroup || []);
              setSelectedCMAssetIds(response.selectedAseetIds);
              setSelectedCMAssetCategoryId(response.selectedAssetCategoryId);
              setSelectedCMAssetGroupId(response.selectedAssetGroupId);
            }
          })
          .catch((err) => {
            toggleLoader(false);
            setOpenErrorModal(true);
            handleError(err, setErrorDesc);
          });
      } else toggleLoader(false);
    }
  };

  const generateReportForWorkOrder = () => {
    toggleLoader(true);
    if (stateCardId) {
      generateReport(stateCardId)
        .then((response) => {
          toggleLoader(false);
          setOpenTemplateModal(true);
          setFileUrl(response.encodedS3Url);
        })
        .catch((err) => {
          setOpenErrorModal(true);
          handleError(err, setErrorDesc);
          toggleLoader(false);
        });
    }
  };

  const setConsumeablesData = (data: Category[]) => {
    let tableData: RowData[] = [];
    data.forEach((category) => {
      category.materialCodes?.forEach((materialCode) => {
        if (materialCode.status) {
          tableData.push({
            id: `row-${Date.now()}`,
            data: {
              id: materialCode.id,
              subCategory: category?.categoryName || "",
              code: materialCode.code,
              description: materialCode.name,
              quantity: materialCode.quantity.toString(),
              uom: materialCode.uom,
            },
          });
        }
      });
    });
    setToolsTableData(tableData);
  };

  const handleRadioChange = (lineItemId: number, newSelectedNAId: number) => {
    const updatedTasks =
      correctiveMaintenance.taskResponseBean &&
      correctiveMaintenance.taskResponseBean.tasks.map((task) => ({
        ...task,
        taskCheckLists: task.taskCheckLists.map((checkList) => {
          if (checkList.lineItemId === lineItemId) {
            return { ...checkList, selectedNAId: newSelectedNAId };
          }
          return checkList;
        }),
      }));

    // Correctly update the taskResponseBean within correctiveMaintenance
    if (correctiveMaintenance.taskResponseBean && updatedTasks) {
      const updatedCorrectiveMaintenance = {
        ...correctiveMaintenance,
        taskResponseBean: {
          ...correctiveMaintenance.taskResponseBean,
          tasks: updatedTasks,
        },
      };

      setCorrectiveMaintenance(updatedCorrectiveMaintenance);
    }
  };
  const handleDeleteSelectedItems = (selectedItems: number[]) => {
    if (assetCategoryData) {
      let updatedAssetCategoryData: AssetGroup[] = assetCategoryData.map(
        (group) => ({
          ...group,
          assetCategories: group.assetCategories.map((category) => ({
            ...category,
            assets: category.assets.filter(
              (asset) => !selectedItems.includes(asset.id)
            ),
          })),
        })
      );

      setAssetCategoryData(updatedAssetCategoryData);
    }
  };

  const convertToOptionType = (data: DropDownType[]): OptionType[] => {
    return data.map((item) => {
      const itemData: OptionType = {
        id: item.id.toString(),
        label: item.name,
      };
      return itemData;
    });
  };
  const convertToOptionTypeFromAssignee = (
    data: AssigneeType[]
  ): OptionType[] => {
    return data.map((item) => {
      const itemData: OptionType = {
        id: item?.id?.toString(),

        label: item?.name,
      };
      return itemData;
    });
  };
  const handleAssetListUpdate = (
    selectedIds: number[],
    assetCategoryId: number,
    assetGroupId: number
  ) => {
    setSelectedCMAssetIds(selectedIds);
    setSelectedCMAssetCategoryId(assetCategoryId);
    setSelectedCMAssetGroupId(assetGroupId);
    // Fetch the new data or update the state as needed
  };

  const handleClickSubCategory = (
    event: React.MouseEvent<HTMLElement>,
    rowId: string
  ) => {
    setActiveRowId(rowId);
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleSelectSubcategory = (
    rowId: string,
    subId: number,
    subName: string
  ) => {
    setSelectedSubCategory((prevState) => ({
      ...prevState,
      [rowId]: { id: subId, name: subName },
    }));
    setToolsTableData((prevToolsTableData: RowData[]) => {
      return prevToolsTableData.map((row) => {
        // Find the row by ID and update its subcategory
        if (row.id === rowId) {
          return {
            ...row,
            data: {
              ...row.data,
              subCategory: subName, // Update the subcategory name
              // You might also need to update other related fields here
            },
          };
        }
        // Leave other rows unchanged
        return row;
      });
    });
  };
  const handleClickCodes = (
    event: React.MouseEvent<HTMLElement>,
    rowId: string
  ) => {
    setActiveRowId(rowId);
    setAnchorElCode(event.currentTarget);
    setOpenCode(true);
  };

  const handleCloseCodes = () => {
    setOpenCode(false);
    setAnchorElCode(null);
  };

  const handleSelectCodes = (rowId: string, codeId: number, code: string) => {
    setSelectedCodes((prevState) => ({
      ...prevState,
      [rowId]: { id: codeId, name: code },
    }));

    // Find the selected code details and update description and UOM

    const selectedCode = correctiveMaintenance?.consumableAndSpares.category
      .flatMap((cat) => cat.materialCodes)
      .find((c) => c?.code === code);

    if (selectedCode) {
      setSelectedDescriptions((prevState) => ({
        ...prevState,
        [rowId]: {
          id: selectedCode.id,
          name: selectedCode.name,
        },
      }));
      setToolsTableData((prevToolsTableData: RowData[]) => {
        return prevToolsTableData.map((row) => {
          // Find the row by ID and update its subcategory
          if (row.id === rowId) {
            return {
              ...row,
              data: {
                ...row.data,
                code: selectedCode.code,
                description: selectedCode.name,
                uom: selectedCode.uom,
                id: selectedCode.id, // Update the subcategory name
                // You might also need to update other related fields here
              },
            };
          }
          // Leave other rows unchanged
          return row;
        });
      });
    }
  };
  //description
  const handleClickDescription = (
    event: React.MouseEvent<HTMLElement>,
    rowId: string
  ) => {
    setActiveRowId(rowId);
    setAnchorElDescription(event.currentTarget);
    setOpenDescription(true);
  };
  const handleSelectDescription = (
    rowId: string,
    desId: number,
    description: string
  ) => {
    setSelectedDescriptions((prevState) => ({
      ...prevState,
      [rowId]: { id: desId, name: description },
    }));

    // Find the selected code details and update description and UOM
    const selecteddescriptions =
      correctiveMaintenance?.consumableAndSpares.category
        .flatMap((cat) => cat.materialCodes)
        .find((c) => c?.name === description);

    if (selecteddescriptions) {
      setSelectedCodes((prevState) => ({
        ...prevState,
        [rowId]: {
          id: selecteddescriptions.id,
          name: selecteddescriptions.name,
        },
      }));

      setToolsTableData((prevToolsTableData: RowData[]) => {
        return prevToolsTableData.map((row) => {
          // Find the row by ID and update its subcategory
          if (row.id === rowId) {
            return {
              ...row,
              data: {
                ...row.data,
                code: selecteddescriptions.code,
                description: description,
                uom: selecteddescriptions.uom,
                id: selecteddescriptions.id, // Update the subcategory name
                // You might also need to update other related fields here
              },
            };
          }
          // Leave other rows unchanged
          return row;
        });
      });
    }
  };

  const handleCloseDescription = () => {
    setOpenDescription(false);
    setAnchorElDescription(null);
  };
  const addNewRow = () => {
    // Correctly calculate and store the new row ID beforehand

    const newRowId = `row-${Date.now()}`;

    // Define the new row using the newRowId
    const newRow: RowData = {
      id: newRowId, // Use the newRowId here
      data: {
        code: "",
        description: "",
        subCategory: "",
        uom: "",
        id: 0,
        quantity: "",
      },
    };

    // Update the toolsTableData with the new row
    const updatedToolsTableData: RowData[] = [...toolsTableData, newRow];
    setToolsTableData(updatedToolsTableData);

    // Update the selectedSubCategory state to include the new row with a default or empty value
    setSelectedSubCategory((prevState) => ({
      ...prevState,
      [newRowId]: { id: 0, name: "" }, // Assuming an id of 0 and an empty name represents a "no selection" state
    }));

    setSelectedSubCategory((prevState) => ({
      ...prevState,
      [newRowId]: { id: 0, name: "" }, // Assuming an id of 0 and an empty name represents a "no selection" state
    }));
    setSelectedCodes((prevState) => ({
      ...prevState,
      [newRowId]: { id: 0, name: "" },
    }));

    setCodes([]);
    setDescription([]);
  };

  const handleDelete = (rowId: string) => {
    const updatedRows = toolsTableData.filter((row) => row.id !== rowId);
    setToolsTableData(updatedRows);
  };
  const convertanomalyList = (data: AnomalyList[]): OptionType[] => {
    return data.map((item) => {
      const itemData: OptionType = {
        id: item.anomalyId.toString(),
        label: item.description,
      };
      return itemData;
    });
  };

  const materialRequestArray: materialData[] = toolsTableData.map((row) => ({
    materialId: row.data.id, // Use the id from the data object
    quantity: Number(row.data.quantity) || 0, // Convert the quantity to a number, defaulting to 0 if conversion fails
  }));

  const materialRequest: materialCodesData = {
    materials: materialRequestArray,
  };

  const selectedGroup =
    assetCategoryData &&
    assetCategoryData.find(
      (group) => group.assetGroupId === selectedCMAssetGroupId
    );
  const selectedCategory = selectedGroup?.assetCategories.find(
    (category) => category.assetCategoryId === selectedCMAssetCategoryId
  );

  const onAssigneeChange = () => {
    if (hasPermission("Assign_Team_Member")) {
      if (correctiveMaintenance.isAssetGrouped) {
        setReassignModal(true);
      } else {
        // setCardMembers(item);
        setIsDragEnabled(true);
        // setCardShiftDetails(shiftDetails);
      }
    }
  };

  const validateForm = (
    values: CorrectiveMaintenanceDetails
  ): FormikErrors<CorrectiveMaintenanceDetails> => {
    const errors: FormikErrors<CorrectiveMaintenanceDetails> = {};

    // Conditionally validate selectedAnomalyId based on isAnomalyRequired
    if (values.isAnomalyRequired) {
      if (
        !values.breakdownDetailBean.selectedAnomalyId ||
        values.breakdownDetailBean.selectedAnomalyId === 0
      ) {
        errors.breakdownDetailBean = {
          selectedAnomalyId: "validation.fieldRequired", // Error message if not provided
        };
      }
    }

    return errors;
  };

  const toggleVisibility = () => {
    setIsVisibleCM(!isVisibleCM);
  };

  return (
    <Box component="main" className="cm-main-container">
      {openErrorModal && (
        <ErrorModal
          descriptionText={errorDesc}
          open={openErrorModal}
          handleClose={() => setOpenErrorModal(false)}
          onPositiveClick={() => {
            setOpenErrorModal(false);
            navigate(-1);
          }}
        />
      )}

      {saveErrorModal && (
        <ErrorModal
          descriptionText={errorDesc}
          open={saveErrorModal}
          handleClose={() => setSaveErrorModal(false)}
          onPositiveClick={() => {
            setSaveErrorModal(false);
            setCardEdit(true);
          }}
        />
      )}

      {openTemplateModal && (
        <SOPModal
          open={openTemplateModal}
          handleClose={() => setOpenTemplateModal(false)}
          fileUrl={fileUrl}
        />
      )}
      {reassignModal && (
        <ReassignModalPopup
          open={reassignModal}
          handleClose={() => setReassignModal(false)}
          cardId={stateCardId}
          assetGroupId={[selectedCMAssetGroupId]}
          woUniqueId={correctiveMaintenance.cardDetailsInfo.workOrderUniqueId}
          version={correctiveMaintenance.version}
          reAssignCallBack={() => {
            getCMDetails();
          }}
          cardType="workorder"
        />
      )}
      <Box sx={{ padding: "24px 0px 0px 0px" }}>
        <Box className="flex__" sx={{ gap: "10px", marginBottom: "10px" }}>
          <Box>
            <MediumTypography
              className="card-wo-category"
              label={correctiveMaintenance?.cardDetailsInfo.code}
              sxProps={{
                color: "#FF453A",
                border: `2px solid #FF453A`,
                lineHeight: "normal",
              }}
            />
          </Box>
          <Box>
            <MediumTypography
              label={correctiveMaintenance?.cardDetailsInfo.type}
              textColor="#FF453A"
              fontSize="16px"
              fontWeight={700}
            />
          </Box>

          <Box className="flex__" sx={{ marginLeft: "auto" }}>
            <Box
              className="flex__ cursor__pointer"
              sx={{
                marginRight: "40px",
              }}
              onClick={generateReportForWorkOrder}
            >
              <Download style={{ marginTop: "4px" }} />
              <MediumTypography
                labelId="Download.text"
                defaultLabel="Download"
                className="ml-xs"
              />
            </Box>
            <Box className="position__relative" sx={{ right: "20px" }}>
              <MediumTypography
                label={`Last Updated ${dayjs(
                  correctiveMaintenance?.cardDetailsInfo.lastUpdated
                ).format("DD MMMM YYYY")} at ${dayjs(
                  correctiveMaintenance?.cardDetailsInfo.lastUpdated
                ).format("HH:mm")}`}
                textColor="#626F86"
                fontSize="14px"
                fontWeight={400}
              />
            </Box>
            <Box className="position__relative" sx={{ right: "10px" }}>
              <MediumTypography
                labelId="CloseText"
                defaultLabel="Close"
                textColor="#626F86"
                fontSize="14px"
                fontWeight={400}
              />
            </Box>
            <Box>
              <CrossIcon
                className="cursor__pointer"
                onClick={() =>
                  navigate(SCHEDULER_DASHBOARD, {
                    state: {
                      date: navState.date,
                      dateRange: navState.dateRange,
                      schedulerViewChange: navState.schedulerViewChange,
                    },
                  })
                }
              />
            </Box>
          </Box>
        </Box>
        <Box className="flex__" sx={{ gap: "10px", marginBottom: "10px" }}>
          <Box>
            <MediumTypography
              label={correctiveMaintenance?.cardDetailsInfo.workOrderUniqueId.toString()}
              textColor="#FFFFFF"
              fontSize="20px"
              fontWeight={700}
            />
          </Box>
          <SmallBorderLine />

          <Box>
            <MediumTypography
              className="card-wo-routine"
              label={correctiveMaintenance?.cardDetailsInfo.frequencyDisplay}
              sxProps={{ padding: "4px 4px !important" }}
            />
          </Box>
          <Box className="flex__">
            <MediumTypography
              label={correctiveMaintenance?.cardDetailsInfo.assetNameAndCount}
              textColor="#FFFFFF"
              fontSize="20px"
              fontWeight={700}
            />
            {isVisibleCM && (
              <Box
                className="cursor__pointer"
                sx={{
                  position: "relative",
                  alignItems: "end",
                  // backgroundColor: "#fff", // Optional: To give a white background around the icon
                  padding: "4px 8px", // Optional: Adjust the padding around the icon
                }}
                onClick={toggleVisibility}
              >
                <Tooltip title="Hide State Transition">
                  <ChevronCircleIcon />
                </Tooltip>
              </Box>
            )}
            {!isVisibleCM && (
              <Box
                className="cursor__pointer"
                sx={{
                  position: "relative",
                  alignItems: "end",
                  // backgroundColor: "#fff", // Optional: To give a white background around the icon
                  padding: "4px 8px", // Optional: Adjust the padding around the icon
                }}
                onClick={toggleVisibility}
              >
                <Tooltip title="Show State Transition">
                  <ChevronCircleIcon className="rotated-180" />
                </Tooltip>
              </Box>
            )}
          </Box>
          {showAwaitingApproval && (
            <Box className="flex__" sx={{ marginLeft: "auto" }}>
              <Box
                className="flex__ position__relative"
                sx={{
                  padding: "4px 12px 4px 12px",
                  borderRadius: "3px",
                  backgroundColor: "rgba(161, 189, 217, 0.08)",
                  right: "20px",
                }}
              >
                {/* <ProfilePhoto /> */}
                <Box className="flex__">
                  <MediumTypography
                    defaultLabel={`Awaiting Approval - `}
                    labelId="AwaitingApproval"
                    textColor="rgba(255, 255, 255, 0.72)"
                    sxProps={{
                      textTransform: "none",
                      position: "relative",
                      left: "4px",
                    }}
                  />
                  <MediumTypography
                    label={`${
                      lastTransition.code === "COMPLETED"
                        ? lastTransition.approverRole1
                        : lastTransition.code === "LEVEL1"
                        ? lastTransition.approverRole2
                        : lastTransition.approverRole3
                    }`}
                    textColor="rgba(255, 255, 255, 0.72)"
                    className="ml-xs"
                    sxProps={{
                      textTransform: "none",
                      position: "relative",
                      left: "4px",
                    }}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  padding: "4px 8px 4px 8px",
                  borderRadius: "3px",
                  backgroundColor: "rgba(161, 189, 217, 0.08)",
                }}
                className="flex__"
              >
                <MediumTypography
                  defaultLabel={"Approval Requested on "}
                  labelId="ApprovedOnLabel"
                  textColor="rgba(255, 255, 255, 0.72)"
                  sxProps={{
                    textTransform: "none",
                  }}
                />
                <MediumTypography
                  label={`${dayjs(lastTransition.transitionDateTime).format(
                    "DD MMMM YYYY"
                  )} ${dayjs(lastTransition.transitionDateTime).format(
                    "HH:mm"
                  )}`}
                  className="ml-xs"
                  textColor="rgba(255, 255, 255, 0.72)"
                  sxProps={{
                    textTransform: "none",
                  }}
                />
              </Box>
            </Box>
          )}
        </Box>
        {isVisibleCM && (
          <Grid container className="mt-md">
            <Grid item xs={12} lg={12} md={12} xl={12}>
              <Box>
                <Divider
                  variant="fullWidth"
                  sx={{ borderTop: "1px solid #333b43" }}
                />
              </Box>
            </Grid>
          </Grid>
        )}
        {isVisibleCM &&
          correctiveMaintenance.status.length > 0 &&
          correctiveMaintenance.definedScheduleStatus.length > 0 && (
            <StatusExecution
              statusArray={correctiveMaintenance.status}
              cardId={navState.cardId}
              WOUniqueId={
                correctiveMaintenance.cardDetailsInfo.workOrderUniqueId
              }
              version={correctiveMaintenance.version}
              definedScheduleStatus={
                correctiveMaintenance.definedScheduleStatus
              }
              scheduleExecution={correctiveMaintenance.scheduleExecution}
              statusCallBack={() => getCMDetails()}
              code={correctiveMaintenance.cardDetailsInfo.code}
              isRCARequired={correctiveMaintenance.isRCARequired}
              isTaskListCompleted={
                correctiveMaintenance.taskResponseBean?.isPcChecklistCompleted
              }
            />
          )}
        <Grid container className="mt-md">
          <Grid item xs={12} lg={12} md={12} xl={12}>
            <Box>
              <Divider
                variant="fullWidth"
                sx={{ borderTop: "1px solid #333b43" }}
              />
            </Box>
          </Grid>
        </Grid>
        <Box className="mt-md ">
          <Box className="flex__ ">
            <Box
              sx={{
                borderBottom:
                  activePage === "details" ? "2px solid #9FBBEB" : "",
                cursor: "pointer",
              }}
              className="mr-md pb-md"
              onClick={() => setActivePage("details")}
            >
              <MediumTypography
                labelId="workorderTitle"
                defaultLabel="Work Order Details"
                sxProps={{
                  color: activePage === "details" ? "#9FBBEB" : "#44546F",
                }}
              />
            </Box>
            <Box
              sx={{
                borderBottom: activePage === "rca" ? "2px solid #9FBBEB" : "",
                cursor: isRcaEnabled ? "pointer" : "not-allowed",
                opacity: isRcaEnabled ? 1 : 0.5,
              }}
              className="mr-md pb-md"
              onClick={() => {
                if (isRcaEnabled) {
                  setActivePage("rca");
                }
              }}
            >
              <MediumTypography
                labelId="rcaText"
                defaultLabel="RCA"
                sxProps={{
                  color: activePage === "rca" ? "#9FBBEB" : "#44546F",
                }}
              />
            </Box>
          </Box>
        </Box>
        <Grid container>
          <Grid item xs={12} lg={12} md={12} xl={12}>
            <Box>
              <Divider
                variant="fullWidth"
                sx={{ borderTop: "1px solid #333b43" }}
              />
            </Box>
          </Grid>
        </Grid>
        {activePage === "details" && (
          <Box component="div">
            <Formik
              initialValues={correctiveMaintenance}
              enableReinitialize
              validateOnChange
              innerRef={formikRef}
              validationSchema={validationSchema}
              validate={validateForm}
              onSubmit={(values) => {
                const taskCheckLists =
                  correctiveMaintenance?.taskResponseBean?.tasks[0]
                    ?.taskCheckLists || [];
                const isNAResponse =
                  correctiveMaintenance?.taskResponseBean?.isNAResponse || [];

                // Find the ID corresponding to "No"
                const noResponse = isNAResponse.find(
                  (response) => response.code === "No"
                );
                const noId = noResponse?.id;

                // Update isCompleted based on selectedNAId
                const updatedTaskCheckLists = taskCheckLists.map(
                  (checkList) => {
                    if (noId === undefined || checkList.selectedNAId === noId) {
                      return { ...checkList, isCompleted: false };
                    } else {
                      return { ...checkList, isCompleted: true };
                    }
                  }
                );

                const body: CorrectiveMaintenanceDetail = {
                  actualEffort: values.actualEffort,
                  assetGroupId: selectedCMAssetGroupId,
                  assetCategoryId: selectedCMAssetCategoryId,
                  taskCheckLists: updatedTaskCheckLists || [],
                  estimatedEffort: values.estimatedEffort,
                  materialRequest: materialRequest,
                  selectedAssets: selectedCMAssetIds,
                  additionalInfoBean: values.cmAdditionalInfoBean,
                  breakdownDetailBean: values.breakdownDetailBean,
                };
                if (!error) {
                  handleSave(body);
                }
              }}
            >
              {({ values, setFieldValue, handleSubmit, errors, touched }) => {
                return (
                  <Grid container>
                    <Grid container>
                      <Grid
                        item
                        xs={9}
                        sx={{
                          borderRight: "1px solid #333b43",
                          overflowY: "auto",
                          height: isVisibleCM
                            ? availableHeight
                            : collapseAvailHeight,
                        }}
                      >
                        <Box sx={{ padding: "24px 0px 24px 0px" }}>
                          <Box className="flex__" sx={{ marginBottom: "20px" }}>
                            <Box>
                              <MediumTypography
                                labelId="WorkOrderInfoText"
                                defaultLabel="Work order Info"
                                fontSize="20px"
                                fontWeight={500}
                                textColor="#FFFFFF"
                              />
                            </Box>
                            {!editDisable && (
                              <Box sx={{ marginLeft: "auto" }}>
                                <Grid
                                  className="flex__ justifyContent-FlexEnd"
                                  container
                                  direction="row"
                                  alignItems="right"
                                >
                                  {cardEdit && (
                                    <Grid item>
                                      <ButtonComponent
                                        sxProps={{
                                          color: "#B6C2CF !important",
                                        }}
                                        className="btn-primary btn-cancel"
                                        variantType="outlined"
                                        defaultLabelId="Cancel"
                                        labelId="btn.cancel"
                                        onClick={() => {
                                          setCardEdit(false);
                                          formikRef.current?.resetForm();
                                        }}
                                      />
                                    </Grid>
                                  )}
                                  {cardEdit && (
                                    <Grid item>
                                      <ButtonComponent
                                        className="btn-primary btn-submit mr-md"
                                        variantType="contained"
                                        defaultLabelId="Save"
                                        labelId="btn.save"
                                        onClick={() => {
                                          // setCardEdit(false);
                                          handleSubmit();
                                        }}
                                      />
                                    </Grid>
                                  )}
                                  {!cardEdit && (
                                    <Grid item>
                                      <ButtonComponent
                                        className="btn-primary btn-submit mr-md"
                                        variantType="contained"
                                        defaultLabelId="Edit"
                                        labelId="btn.edit"
                                        onClick={() => {
                                          setCardEdit(true);
                                        }}
                                      />
                                    </Grid>
                                  )}
                                </Grid>
                              </Box>
                            )}
                          </Box>
                          <Grid
                            container
                            direction={"row"}
                            className="justifyContentSpaceBtw"
                            alignItems={"center"}
                            rowSpacing={"16px"}
                            columnSpacing={"40px"}
                            sx={{ marginBottom: "20px", marginTop: "10px" }}
                          >
                            <Grid
                              item
                              sx={{
                                width: "100%",
                                marginRight: "16px",
                              }}
                            >
                              <MediumTypography
                                labelId="AssetCategoryText"
                                defaultLabel="Asset Category"
                                sxProps={{ marginBottom: "10px" }}
                                textColor={"rgba(159, 173, 188, 1)"}
                              />
                              <Box
                                sx={{
                                  padding: "12px 6px",
                                  backgroundColor: "#282D33",
                                  borderRadius: "4px",
                                }}
                              >
                                <Box
                                  sx={{
                                    borderLeft: "2px solid #9FBBEB",
                                    padding: "0px 12px",
                                  }}
                                >
                                  <MediumTypography
                                    label={selectedCategory?.assetCategoryName}
                                  />
                                </Box>
                              </Box>
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            direction={"row"}
                            className="justifyContentSpaceBtw"
                            alignItems={"center"}
                            rowSpacing={"16px"}
                            columnSpacing={"40px"}
                            sx={{ marginBottom: "20px", marginTop: "10px" }}
                          >
                            <Grid
                              item
                              sx={{
                                width: "100%",
                                marginRight: "16px",
                              }}
                            >
                              <Box
                                className="flex__"
                                sx={{ justifyContent: "space-between" }}
                              >
                                <MediumTypography
                                  labelId="ListOfAssetsText"
                                  defaultLabel="List of Assets"
                                  sxProps={{ marginBottom: "10px" }}
                                  textColor={"rgba(159, 173, 188, 1)"}
                                />
                                {!editDisable && !disableAssets && (
                                  <>
                                    {cardEdit && (
                                      <>
                                        {!viewAllAssets ? (
                                          <MediumTypography
                                            labelId="viewallText"
                                            defaultLabel="View all"
                                            textColor="#25BBFA"
                                            sxProps={{
                                              textDecoration: "underline",
                                              cursor: "pointer",
                                            }}
                                            onClick={() =>
                                              setViewAllAssets(true)
                                            }
                                          />
                                        ) : (
                                          <MediumTypography
                                            labelId="closeText"
                                            defaultLabel="Close"
                                            textColor="#25BBFA"
                                            sxProps={{
                                              textDecoration: "underline",
                                              cursor: "pointer",
                                            }}
                                            onClick={() =>
                                              setViewAllAssets(false)
                                            }
                                          />
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                              </Box>
                            </Grid>
                            {!viewAllAssets ? (
                              <>
                                {selectedCategory?.assets
                                  .filter((asset) =>
                                    selectedCMAssetIds.includes(asset.id)
                                  )
                                  .slice(0, 7)
                                  .map((item, index) => (
                                    <Grid item key={item.id}>
                                      <Box
                                        sx={{
                                          padding: "12px 6px",
                                          backgroundColor: "#282D33",
                                          borderRadius: "4px",
                                          width: "150px",
                                        }}
                                      >
                                        <Tooltip
                                          title={
                                            item.name && item.name.length > 10
                                              ? item.name
                                              : ""
                                          }
                                          arrow={true}
                                          followCursor
                                        >
                                          <Box
                                            sx={{
                                              borderLeft: "2px solid #9FBBEB",
                                              padding: "0px 12px",
                                            }}
                                          >
                                            <MediumTypography
                                              label={item.name}
                                              className="ellipsis"
                                              sxProps={{
                                                width:
                                                  item.name.length > 10
                                                    ? "120px"
                                                    : "auto",
                                              }}
                                            />
                                          </Box>
                                        </Tooltip>
                                      </Box>
                                    </Grid>
                                  ))}
                              </>
                            ) : (
                              <Box
                                sx={{
                                  backgroundColor: "#282D33",
                                  marginLeft: "40px",
                                  marginRight: "16px",
                                  width: "100%",
                                }}
                              >
                                <AssetListComponent
                                  data={assetCategoryData}
                                  onDeleteSelectedItems={
                                    handleDeleteSelectedItems
                                  }
                                  selectedAssetIds={
                                    correctiveMaintenance?.selectedAseetIds
                                  }
                                  selectedAssetGroupId={
                                    correctiveMaintenance?.selectedAssetGroupId
                                  }
                                  selectedAssetCategoryId={
                                    correctiveMaintenance?.selectedAssetCategoryId
                                  }
                                  onAssetListUpdate={handleAssetListUpdate}
                                />
                              </Box>
                            )}
                          </Grid>
                          <Grid
                            container
                            direction={"row"}
                            className="justifyContentSpaceBtw"
                            alignItems={"center"}
                            rowSpacing={"16px"}
                            columnSpacing={"40px"}
                            sx={{ marginBottom: "20px", marginTop: "10px" }}
                          >
                            <Grid item>
                              <MediumTypography
                                labelId="ActualStartTimeTextInput"
                                defaultLabel="Actual Start Time"
                                sxProps={{ marginBottom: "10px" }}
                                textColor={"rgba(159, 173, 188, 1)"}
                              />
                              <DateTimePickerComponent
                                value={
                                  values.actualStartDateTime !== null
                                    ? dayjs(
                                        values.actualStartDateTime?.toString()
                                      )
                                    : null
                                }
                                labelid="ActualStartTimeTextInput"
                                defaultlabelid="Actual Start Time"
                                handlechange={(date: Dayjs) => {
                                  const formattedText = date.format(
                                    "YYYY-MM-DDTHH:mm:ss"
                                  );
                                  setFieldValue(
                                    "actualStartDateTime",
                                    formattedText
                                  );
                                }}
                                disabledDate={true}
                                inputFormat="DD MMM YYYY  HH:mm:ss"
                                width="250px"
                                textWidth="250px"
                              />
                            </Grid>
                            <Grid item>
                              <MediumTypography
                                labelId="ActualEndTimeTextInput"
                                defaultLabel="Actual End Time"
                                sxProps={{ marginBottom: "10px" }}
                                textColor={"rgba(159, 173, 188, 1)"}
                              />
                              <DateTimePickerComponent
                                value={
                                  values.actualEndDateTime !== null
                                    ? dayjs(values.actualEndDateTime)
                                    : null
                                }
                                labelid="ActualEndTimeTextInput"
                                defaultlabelid="Actual End Time"
                                handlechange={(date: Dayjs) => {
                                  if (
                                    values.actualStartDateTime ===
                                    date.format("YYYY-MM-DDTHH:mm:ss")
                                  ) {
                                    setError(
                                      "End date can not be less than the start Date"
                                    );
                                  } else {
                                    setError("");
                                    setFieldValue(
                                      "actualEndDateTime",
                                      date.format("YYYY-MM-DDTHH:mm:ss")
                                    );
                                  }
                                }}
                                disabledDate={!cardEdit}
                                inputFormat="DD MMM YYYY  HH:mm:ss"
                                width="250px"
                                textWidth="250px"
                                minDateTime={
                                  values.actualStartDateTime !== null
                                    ? dayjs(values.actualStartDateTime)
                                    : undefined
                                }
                              />
                              {error && (
                                <Box
                                  className="loginError"
                                  sx={{ position: "relative" }}
                                >
                                  <Box>
                                    <ErrorIcon />
                                  </Box>
                                  <MediumTypography
                                    label={error}
                                    fontSize="12px"
                                    textColor="#AE2E24"
                                    fontWeight={400}
                                  />
                                </Box>
                              )}
                            </Grid>
                            <Grid item>
                              <MediumTypography
                                labelId="CM.estimatedEffort"
                                defaultLabel="Estimated Effort"
                                sxProps={{ marginBottom: "10px" }}
                                textColor={"rgba(159, 173, 188, 1)"}
                              />
                              <Box
                                className="flex__"
                                sx={{
                                  position: "relative",
                                  alignItems: "center",
                                }}
                              >
                                <TextInput
                                  labelId="CM.estimatedEffort"
                                  className="textInputWidth"
                                  defaultLabelId="Estimated Effort"
                                  borderColor="1px solid rgba(166, 197, 226, 0.16)"
                                  Value={convertMinutesToHoursAndMinutes(
                                    values?.estimatedEffort
                                  )}
                                  handlechange={(value: string) => {
                                    const minutes =
                                      convertHoursAndMinutesToMinutes(value);

                                    setFieldValue("estimatedEffort", minutes);
                                  }}
                                  variant="outlined"
                                  backGroundColor="#22272B"
                                  fieldheight="40px"
                                  textColor={
                                    cardEdit
                                      ? "rgba(255, 255, 255, 1)"
                                      : "rgba(140, 155, 171, 1)"
                                  }
                                />
                                <Box
                                  sx={{
                                    padding: "4px 8px 4px 8px",
                                    backgroundColor: "#191E22",
                                    border: "1px solid #374048",
                                    height: "24px",
                                    borderRadius: "4px",
                                    right: "8px",
                                    position: "absolute",
                                  }}
                                >
                                  <MediumTypography
                                    // labelId="cm.minutes"
                                    // defaultLabel="Minutes"
                                    label="( hh:mm )"
                                    sxProps={{
                                      lineHeight: "normal",
                                      fontSize: "12px",
                                    }}
                                  />
                                </Box>
                              </Box>
                              {errors.estimatedEffort &&
                                touched.estimatedEffort && (
                                  <Box
                                    className="loginError"
                                    sx={{ position: "relative" }}
                                  >
                                    <Box>
                                      <ErrorIcon />
                                    </Box>
                                    <MediumTypography
                                      labelId="vaidation.fieldRequired"
                                      defaultLabel="This field is required."
                                      fontSize="12px"
                                      textColor="#AE2E24"
                                      fontWeight={400}
                                    />
                                  </Box>
                                )}
                            </Grid>
                            <Grid item>
                              <MediumTypography
                                labelId="CM.ActualEffort"
                                defaultLabel="Actual Effort"
                                sxProps={{ marginBottom: "10px" }}
                                textColor={"rgba(159, 173, 188, 1)"}
                              />
                              <Box
                                className="flex__"
                                sx={{
                                  position: "relative",
                                  alignItems: "center",
                                }}
                              >
                                <TextInput
                                  labelId="CM.ActualEffort"
                                  className="textInputWidth"
                                  defaultLabelId="Actual Effort"
                                  borderColor="1px solid rgba(166, 197, 226, 0.16)"
                                  Value={
                                    values.actualEffort !== null
                                      ? convertMinutesToHoursAndMinutes(
                                          values.actualEffort
                                        )
                                      : ""
                                  }
                                  // Value={convertMinutesToHoursAndMinutes(
                                  //   values.estimatedEffort
                                  // )}
                                  handlechange={(value: string) => {
                                    setFieldValue(
                                      "workOrderInfo.actualEffort",
                                      value
                                    );
                                  }}
                                  inputProps={{
                                    readOnly: true,
                                  }}
                                  variant="outlined"
                                  backGroundColor="#22272B"
                                  fieldheight="40px"
                                  textColor={"rgba(140, 155, 171, 1)"}
                                />
                                <Box
                                  sx={{
                                    padding: "4px 8px 4px 8px",
                                    backgroundColor: "#191E22",
                                    border: "1px solid #374048",
                                    height: "24px",
                                    borderRadius: "4px",
                                    right: "8px",
                                    position: "absolute",
                                  }}
                                >
                                  <MediumTypography
                                    // labelId="cm.minutes"
                                    // defaultLabel="Minutes"
                                    label="( hh:mm )"
                                    sxProps={{
                                      lineHeight: "normal",
                                      fontSize: "12px",
                                    }}
                                  />
                                </Box>
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                        <Grid container className="mt-xs">
                          <Grid item xs={12} lg={12} md={12} xl={12}>
                            <Box>
                              <Divider
                                variant="fullWidth"
                                sx={{
                                  borderTop: "1px solid #333b43",
                                }}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                        <Box sx={{ padding: "24px 0px 24px 0px" }}>
                          <Box className="flex__" sx={{ marginBottom: "30px" }}>
                            <Box>
                              <MediumTypography
                                labelId="BreakDownText"
                                defaultLabel="Break Down Details"
                                fontSize="20px"
                                fontWeight={500}
                                textColor="#FFFFFF"
                              />
                              <Grid
                                container
                                direction={"row"}
                                className="justifyContentSpaceBtw"
                                alignItems={"center"}
                                rowSpacing={"16px"}
                                columnSpacing={"40px"}
                                sx={{ marginBottom: "20px", marginTop: "10px" }}
                              >
                                <Grid item>
                                  <MediumTypography
                                    labelId="BreakDownStartTimeLabelText"
                                    defaultLabel="Break Down Start Time"
                                    sxProps={{ marginBottom: "10px" }}
                                    textColor={"rgba(159, 173, 188, 1)"}
                                  />
                                  <DateTimePickerComponent
                                    value={
                                      values.breakdownDetailBean
                                        .breakdownStartDateTime !== null
                                        ? dayjs(
                                            values.breakdownDetailBean.breakdownStartDateTime?.toString()
                                          )
                                        : null
                                    }
                                    labelid="BreakDownStartTimeLabelText"
                                    defaultlabelid="Break Down Start Time"
                                    handlechange={(date: Dayjs) => {
                                      const formattedText = date.format(
                                        "YYYY-MM-DDTHH:mm:ss"
                                      );
                                      setFieldValue(
                                        "breakdownDetailBean.breakdownStartDateTime",
                                        formattedText
                                      );
                                    }}
                                    disabledDate={!cardEdit}
                                    inputFormat="DD MMM YYYY  HH:mm:ss"
                                    width="250px"
                                    textWidth="250px"
                                  />

                                  {errors.breakdownDetailBean
                                    ?.breakdownStartDateTime &&
                                    touched.breakdownDetailBean
                                      ?.breakdownStartDateTime && (
                                      <Box
                                        className="loginError"
                                        sx={{ position: "relative" }}
                                      >
                                        <Box>
                                          <ErrorIcon />
                                        </Box>
                                        <MediumTypography
                                          labelId="vaidation.fieldRequired"
                                          defaultLabel="This field is required."
                                          fontSize="12px"
                                          textColor="#AE2E24"
                                          fontWeight={400}
                                        />
                                      </Box>
                                    )}
                                </Grid>
                                <Grid item>
                                  <MediumTypography
                                    labelId="BreakDownEndTimeLabelText"
                                    defaultLabel="Break Down End Time"
                                    sxProps={{ marginBottom: "10px" }}
                                    textColor={"rgba(159, 173, 188, 1)"}
                                  />
                                  <DateTimePickerComponent
                                    value={
                                      values.breakdownDetailBean
                                        .breakdownEndDateTime !== null
                                        ? dayjs(
                                            values.breakdownDetailBean.breakdownEndDateTime?.toString()
                                          )
                                        : null
                                    }
                                    labelid="BreakDownEndTimeLabelText"
                                    defaultlabelid="Break Down End Time"
                                    handlechange={(date: Dayjs) => {
                                      const formattedText = date.format(
                                        "YYYY-MM-DDTHH:mm:ss"
                                      );
                                      if (
                                        values.breakdownDetailBean
                                          .breakdownStartDateTime ===
                                        formattedText
                                      ) {
                                        setError(
                                          intl.formatMessage({
                                            id: "Error.EndTimeGreaterThanStartTime",
                                            defaultMessage:
                                              "End time should be greater than start time",
                                          })
                                        );
                                      } else {
                                        setError("");
                                        setFieldValue(
                                          "breakdownDetailBean.breakdownEndDateTime",
                                          formattedText
                                        );
                                      }
                                    }}
                                    minDateTime={
                                      values.breakdownDetailBean
                                        .breakdownStartDateTime !== null
                                        ? dayjs(
                                            values.breakdownDetailBean
                                              .breakdownStartDateTime
                                          )
                                        : undefined
                                    }
                                    disabledDate={!cardEdit}
                                    inputFormat="DD MMM YYYY  HH:mm:ss"
                                    width="250px"
                                    textWidth="250px"
                                  />
                                  {error && (
                                    <Box
                                      className="loginError"
                                      sx={{ position: "relative" }}
                                    >
                                      <Box>
                                        <ErrorIcon />
                                      </Box>
                                      <MediumTypography
                                        label={error}
                                        fontSize="12px"
                                        textColor="#AE2E24"
                                        fontWeight={400}
                                      />
                                    </Box>
                                  )}
                                  {errors.breakdownDetailBean
                                    ?.breakdownEndDateTime &&
                                    touched.breakdownDetailBean
                                      ?.breakdownEndDateTime && (
                                      <Box
                                        className="loginError"
                                        sx={{ position: "relative" }}
                                      >
                                        <Box>
                                          <ErrorIcon />
                                        </Box>
                                        <MediumTypography
                                          labelId="vaidation.fieldRequired"
                                          defaultLabel="This field is required."
                                          fontSize="12px"
                                          textColor="#AE2E24"
                                          fontWeight={400}
                                        />
                                      </Box>
                                    )}
                                </Grid>
                                <Grid item>
                                  <MediumTypography
                                    labelId="BreakDownTextCause"
                                    defaultLabel="Breakdown Cause"
                                    sxProps={{ marginBottom: "10px" }}
                                    textColor={"rgba(159, 173, 188, 1)"}
                                  />
                                  <DropdownComponent
                                    names={convertanomalyList(
                                      correctiveMaintenance.breakdownDetailBean
                                        .anomalyList
                                    )}
                                    value={
                                      values.breakdownDetailBean
                                        .selectedAnomalyId === 0
                                        ? ""
                                        : values.breakdownDetailBean.selectedAnomalyId.toString()
                                    }
                                    handleChange={(value: string) => {
                                      setFieldValue(
                                        "breakdownDetailBean.selectedAnomalyId",
                                        parseInt(value)
                                      );
                                    }}
                                    disabled={!cardEdit}
                                    labelid="anomalyTextLabel"
                                    defaultlabelid="--Select Anomaly--"
                                    minWidth="250px"
                                    sxProps={{ width: "250px" }}
                                  />
                                  {errors.breakdownDetailBean
                                    ?.selectedAnomalyId &&
                                    touched.breakdownDetailBean
                                      ?.selectedAnomalyId && (
                                      <Box
                                        className="loginError"
                                        sx={{ position: "relative" }}
                                      >
                                        <Box>
                                          <ErrorIcon />
                                        </Box>
                                        <MediumTypography
                                          labelId="vaidation.fieldRequired"
                                          defaultLabel="This field is required."
                                          fontSize="12px"
                                          textColor="#AE2E24"
                                          fontWeight={400}
                                        />
                                      </Box>
                                    )}
                                </Grid>
                                <Grid item xs={3} lg={3}>
                                  <MediumTypography
                                    labelId="ResolutionText"
                                    defaultLabel="Resolution"
                                    sxProps={{ marginBottom: "10px" }}
                                    textColor={"rgba(159, 173, 188, 1)"}
                                  />
                                  <DropdownComponent
                                    names={convertToOptionType(
                                      correctiveMaintenance.breakdownDetailBean
                                        .resolutions
                                    )}
                                    value={
                                      values.breakdownDetailBean
                                        .selectedResolutionId === 0
                                        ? ""
                                        : values.breakdownDetailBean.selectedResolutionId.toString()
                                    }
                                    handleChange={(value: string) => {
                                      setFieldValue(
                                        "breakdownDetailBean.selectedResolutionId",
                                        parseInt(value)
                                      );
                                    }}
                                    disabled={!cardEdit}
                                    labelid="ResolutionTextLabel"
                                    defaultlabelid="Select Resolution"
                                    minWidth="250px"
                                    sxProps={{ width: "250px" }}
                                  />

                                  {errors.breakdownDetailBean
                                    ?.selectedResolutionId &&
                                    touched.breakdownDetailBean
                                      ?.selectedResolutionId && (
                                      <Box
                                        className="loginError"
                                        sx={{ position: "relative" }}
                                      >
                                        <Box>
                                          <ErrorIcon />
                                        </Box>
                                        <MediumTypography
                                          labelId="vaidation.fieldRequired"
                                          defaultLabel="This field is required."
                                          fontSize="12px"
                                          textColor="#AE2E24"
                                          fontWeight={400}
                                        />
                                      </Box>
                                    )}
                                </Grid>
                              </Grid>
                              <Grid
                                container
                                direction={"row"}
                                className="justifyContentSpaceBtw"
                                alignItems={"center"}
                                rowSpacing={"16px"}
                                columnSpacing={"40px"}
                                sx={{ marginBottom: "20px", marginTop: "10px" }}
                              >
                                <Grid item>
                                  <MediumTypography
                                    labelId="RcaRequiredText"
                                    defaultLabel="RCA"
                                    sxProps={{ marginBottom: "10px" }}
                                    textColor={"rgba(159, 173, 188, 1)"}
                                  />
                                  <DropdownComponent
                                    names={convertToOptionType(
                                      correctiveMaintenance.breakdownDetailBean
                                        .rcaRequired
                                    )}
                                    value={values.breakdownDetailBean.selectedRcaReqId.toString()}
                                    handleChange={(value: string) => {
                                      setFieldValue(
                                        "breakdownDetailBean.selectedRcaReqId",
                                        parseInt(value)
                                      );

                                      const selectedOption =
                                        correctiveMaintenance.breakdownDetailBean.rcaRequired.find(
                                          (option) =>
                                            option.id === parseInt(value)
                                        );

                                      // Check if the name of the selected option is "Yes" to determine if RCA is enabled
                                      const isRcaRequired =
                                        selectedOption?.name === "Yes";

                                      // Logic to enable or disable based on isRcaEnabled
                                      if (isRcaRequired) {
                                        // Logic to enable RCA related functionality
                                        setIsRcaEnabled(true);
                                      } else {
                                        setIsRcaEnabled(false);
                                        // Logic to disable RCA related functionality or take other actions
                                      }
                                    }}
                                    disabled={!cardEdit}
                                    labelid="RcaRequiredTextLabel"
                                    defaultlabelid="Select RCA"
                                    minWidth="250px"
                                    sxProps={{ width: "250px" }}
                                  />
                                  {errors.breakdownDetailBean
                                    ?.selectedRcaReqId &&
                                    touched.breakdownDetailBean
                                      ?.selectedRcaReqId && (
                                      <Box
                                        className="loginError"
                                        sx={{ position: "relative" }}
                                      >
                                        <Box>
                                          <ErrorIcon />
                                        </Box>
                                        <MediumTypography
                                          labelId="vaidation.fieldRequired"
                                          defaultLabel="This field is required."
                                          fontSize="12px"
                                          textColor="#AE2E24"
                                          fontWeight={400}
                                        />
                                      </Box>
                                    )}
                                </Grid>
                                <Grid item>
                                  <MediumTypography
                                    labelId="DCcapacityLostText"
                                    defaultLabel="DC Capacity lost"
                                    sxProps={{ marginBottom: "10px" }}
                                    textColor={"rgba(159, 173, 188, 1)"}
                                  />
                                  <Box
                                    className="flex__"
                                    sx={{
                                      position: "relative",
                                      alignItems: "center",
                                    }}
                                  >
                                    <TextInput
                                      labelId="DCcapacityLostText"
                                      className="textInputWidth"
                                      defaultLabelId="DC Capacity lost"
                                      borderColor="1px solid rgba(166, 197, 226, 0.16)"
                                      Value={
                                        values.breakdownDetailBean
                                          .dcCapacityLost !== null &&
                                        values.breakdownDetailBean
                                          .dcCapacityLost !== 0
                                          ? values.breakdownDetailBean.dcCapacityLost.toString()
                                          : ""
                                      }
                                      handlechange={(value: string) => {
                                        let formattedValue = value.replace(
                                          /[^0-9.]/g,
                                          ""
                                        );

                                        // Then, format the string to match the pattern "up to 4 digits, optional decimal, up to 2 digits after the decimal"
                                        formattedValue = formattedValue.replace(
                                          /^(\d{0,4})(\.\d{0,2})?.*$/,
                                          (match, p1, p2) => p1 + (p2 || "") // p1 is the first group (digits), p2 is the second group (decimal and digits)
                                        );
                                        setFieldValue(
                                          "breakdownDetailBean.dcCapacityLost",
                                          formattedValue
                                        );
                                      }}
                                      variant="outlined"
                                      backGroundColor="#22272B"
                                      fieldheight="40px"
                                      textColor={
                                        cardEdit
                                          ? "rgba(255, 255, 255, 1)"
                                          : "rgba(140, 155, 171, 1)"
                                      }
                                      inputProps={{
                                        readOnly: !cardEdit,
                                      }}
                                    />
                                    <Box
                                      sx={{
                                        padding: "4px 8px 4px 8px",
                                        backgroundColor: "#191E22",
                                        border: "1px solid #374048",
                                        height: "24px",
                                        borderRadius: "4px",
                                        right: "8px",
                                        position: "absolute",
                                      }}
                                    >
                                      <MediumTypography
                                        labelId="CM.DCCapacity"
                                        defaultLabel="MWp"
                                        sxProps={{
                                          lineHeight: "normal",
                                          fontSize: "12px",
                                        }}
                                      />
                                    </Box>
                                  </Box>
                                </Grid>
                                <Grid item>
                                  <MediumTypography
                                    labelId="Breakdown.Irradiation"
                                    defaultLabel="Irradiation During Downtime"
                                    sxProps={{ marginBottom: "10px" }}
                                    textColor={"rgba(159, 173, 188, 1)"}
                                  />
                                  <Box
                                    className="flex__"
                                    sx={{
                                      position: "relative",
                                      alignItems: "center",
                                    }}
                                  >
                                    <TextInput
                                      labelId="Breakdown.Irradiation"
                                      className="textInputWidth"
                                      defaultLabelId="Irradiation During Downtime"
                                      borderColor="1px solid rgba(166, 197, 226, 0.16)"
                                      Value={
                                        values.breakdownDetailBean
                                          .irradiationDuringDownTime !== null &&
                                        values.breakdownDetailBean
                                          .irradiationDuringDownTime !== 0
                                          ? values.breakdownDetailBean.irradiationDuringDownTime.toString()
                                          : ""
                                      }
                                      handlechange={(value: string) => {
                                        let formattedValue = value.replace(
                                          /[^0-9.]/g,
                                          ""
                                        );

                                        // Then, format the string to match the pattern "up to 4 digits, optional decimal, up to 2 digits after the decimal"
                                        formattedValue = formattedValue.replace(
                                          /^(\d{0,4})(\.\d{0,2})?.*$/,
                                          (match, p1, p2) => p1 + (p2 || "") // p1 is the first group (digits), p2 is the second group (decimal and digits)
                                        );
                                        setFieldValue(
                                          "breakdownDetailBean.irradiationDuringDownTime",
                                          formattedValue
                                        );
                                      }}
                                      variant="outlined"
                                      backGroundColor="#22272B"
                                      fieldheight="40px"
                                      textColor={
                                        cardEdit
                                          ? "rgba(255, 255, 255, 1)"
                                          : "rgba(140, 155, 171, 1)"
                                      }
                                      inputProps={{
                                        readOnly: !cardEdit,
                                      }}
                                    />
                                    <Box
                                      sx={{
                                        padding: "4px 8px 4px 8px",
                                        backgroundColor: "#191E22",
                                        border: "1px solid #374048",
                                        height: "24px",
                                        borderRadius: "4px",
                                        right: "8px",
                                        position: "absolute",
                                      }}
                                    >
                                      <MediumTypography
                                        labelId="CM.IDD"
                                        defaultLabel="Wh/m2"
                                        sxProps={{
                                          lineHeight: "normal",
                                          fontSize: "12px",
                                        }}
                                      />
                                    </Box>
                                  </Box>
                                </Grid>
                                <Grid item>
                                  <MediumTypography
                                    labelId="CurtalimentText"
                                    defaultLabel="Curtailment"
                                    sxProps={{ marginBottom: "10px" }}
                                    textColor={"rgba(159, 173, 188, 1)"}
                                  />
                                  <Box
                                    className="flex__"
                                    sx={{
                                      position: "relative",
                                      alignItems: "center",
                                    }}
                                  >
                                    <TextInput
                                      labelId="CurtalimentText"
                                      className="textInputWidth"
                                      defaultLabelId="Curtailment"
                                      borderColor="1px solid rgba(166, 197, 226, 0.16)"
                                      Value={
                                        values.breakdownDetailBean
                                          .curtailment !== null &&
                                        values.breakdownDetailBean
                                          .curtailment !== 0
                                          ? values.breakdownDetailBean.curtailment.toString()
                                          : ""
                                      }
                                      handlechange={(value: string) => {
                                        let formattedValue = value.replace(
                                          /[^0-9.]/g,
                                          ""
                                        );

                                        // Then, format the string to match the pattern "up to 4 digits, optional decimal, up to 2 digits after the decimal"
                                        formattedValue = formattedValue.replace(
                                          /^(\d{0,4})(\.\d{0,2})?.*$/,
                                          (match, p1, p2) => p1 + (p2 || "") // p1 is the first group (digits), p2 is the second group (decimal and digits)
                                        );
                                        setFieldValue(
                                          "breakdownDetailBean.curtailment",
                                          formattedValue
                                        );
                                      }}
                                      variant="outlined"
                                      backGroundColor="#22272B"
                                      fieldheight="40px"
                                      textColor={
                                        cardEdit
                                          ? "rgba(255, 255, 255, 1)"
                                          : "rgba(140, 155, 171, 1)"
                                      }
                                      inputProps={{
                                        readOnly: !cardEdit,
                                      }}
                                    />
                                    <Box
                                      sx={{
                                        padding: "4px 8px 4px 8px",
                                        backgroundColor: "#191E22",
                                        border: "1px solid #374048",
                                        height: "24px",
                                        borderRadius: "4px",
                                        right: "8px",
                                        position: "absolute",
                                      }}
                                    >
                                      <MediumTypography
                                        label="%"
                                        sxProps={{
                                          lineHeight: "normal",
                                          fontSize: "12px",
                                        }}
                                      />
                                    </Box>
                                  </Box>
                                </Grid>
                              </Grid>
                              <Grid
                                container
                                direction={"row"}
                                className="justifyContentSpaceBtw"
                                alignItems={"center"}
                                rowSpacing={"16px"}
                                columnSpacing={"40px"}
                                sx={{ marginBottom: "20px", marginTop: "10px" }}
                              >
                                <Grid item>
                                  <MediumTypography
                                    labelId="LossCategoryText"
                                    defaultLabel="Loss Category"
                                    sxProps={{ marginBottom: "10px" }}
                                    textColor={"rgba(159, 173, 188, 1)"}
                                  />
                                  <DropdownComponent
                                    Required={true}
                                    names={convertToOptionType(
                                      correctiveMaintenance.cmAdditionalInfoBean
                                        .lossCategories
                                    )}
                                    value={
                                      values.cmAdditionalInfoBean
                                        .selectedLossCategoryId === 0
                                        ? ""
                                        : values.cmAdditionalInfoBean.selectedLossCategoryId.toString()
                                    }
                                    handleChange={(value: string) => {
                                      setFieldValue(
                                        "cmAdditionalInfoBean.selectedLossCategoryId",
                                        parseInt(value)
                                      );
                                    }}
                                    disabled={!cardEdit}
                                    labelid="LossCategorLabel"
                                    defaultlabelid="Select Category"
                                    minWidth="250px"
                                    sxProps={{ width: "250px" }}
                                  />
                                  {errors.cmAdditionalInfoBean
                                    ?.selectedLossCategoryId &&
                                    touched.cmAdditionalInfoBean
                                      ?.selectedLossCategoryId && (
                                      <Box
                                        className="loginError"
                                        sx={{ position: "relative" }}
                                      >
                                        <Box>
                                          <ErrorIcon />
                                        </Box>
                                        <MediumTypography
                                          labelId="vaidation.fieldRequired"
                                          defaultLabel="This field is required."
                                          fontSize="12px"
                                          textColor="#AE2E24"
                                          fontWeight={400}
                                        />
                                      </Box>
                                    )}
                                </Grid>
                                <Grid item>
                                  <MediumTypography
                                    labelId="energyLostText"
                                    defaultLabel="Energy Lost"
                                    sxProps={{ marginBottom: "10px" }}
                                    textColor={"rgba(159, 173, 188, 1)"}
                                  />
                                  <Box
                                    className="flex__"
                                    sx={{
                                      position: "relative",
                                      alignItems: "center",
                                    }}
                                  >
                                    <TextInput
                                      labelId="energyLostText"
                                      className="textInputWidth"
                                      defaultLabelId="Energy Lost"
                                      borderColor="1px solid rgba(166, 197, 226, 0.16)"
                                      Value={
                                        values.breakdownDetailBean
                                          .energyLost !== null &&
                                        values.breakdownDetailBean
                                          .energyLost !== 0
                                          ? values.breakdownDetailBean.energyLost.toString()
                                          : ""
                                      }
                                      handlechange={(value: string) => {
                                        let formattedValue = value.replace(
                                          /[^0-9.]/g,
                                          ""
                                        );

                                        // Then, format the string to match the pattern "up to 4 digits, optional decimal, up to 2 digits after the decimal"
                                        formattedValue = formattedValue.replace(
                                          /^(\d{0,4})(\.\d{0,2})?.*$/,
                                          (match, p1, p2) => p1 + (p2 || "") // p1 is the first group (digits), p2 is the second group (decimal and digits)
                                        );
                                        setFieldValue(
                                          "breakdownDetailBean.energyLost",
                                          formattedValue
                                        );
                                      }}
                                      variant="outlined"
                                      backGroundColor="#22272B"
                                      fieldheight="40px"
                                      textColor={
                                        cardEdit
                                          ? "rgba(255, 255, 255, 1)"
                                          : "rgba(140, 155, 171, 1)"
                                      }
                                      inputProps={{
                                        readOnly: !cardEdit,
                                      }}
                                    />
                                    <Box
                                      sx={{
                                        padding: "4px 8px 4px 8px",
                                        backgroundColor: "#191E22",
                                        border: "1px solid #374048",
                                        height: "24px",
                                        borderRadius: "4px",
                                        right: "8px",
                                        position: "absolute",
                                      }}
                                    >
                                      <MediumTypography
                                        labelId="CM.EnergyLost"
                                        defaultLabel="MWh"
                                        sxProps={{
                                          lineHeight: "normal",
                                          fontSize: "12px",
                                        }}
                                      />
                                    </Box>
                                  </Box>
                                </Grid>
                                <Grid item>
                                  <MediumTypography
                                    labelId="revenueLostText"
                                    defaultLabel="Revenue Lost"
                                    sxProps={{ marginBottom: "10px" }}
                                    textColor={"rgba(159, 173, 188, 1)"}
                                  />
                                  <TextInput
                                    labelId="revenueLostText"
                                    className="textInputWidth"
                                    defaultLabelId="Revenue Lost"
                                    borderColor="1px solid rgba(166, 197, 226, 0.16)"
                                    Value={
                                      values.breakdownDetailBean.revenueLost !==
                                        null &&
                                      values.breakdownDetailBean.revenueLost !==
                                        0
                                        ? values.breakdownDetailBean.revenueLost.toString()
                                        : ""
                                    }
                                    handlechange={(value: string) => {
                                      let formattedValue = value.replace(
                                        /[^0-9.]/g,
                                        ""
                                      );

                                      // Then, format the string to match the pattern "up to 4 digits, optional decimal, up to 2 digits after the decimal"
                                      formattedValue = formattedValue.replace(
                                        /^(\d{0,4})(\.\d{0,2})?.*$/,
                                        (match, p1, p2) => p1 + (p2 || "") // p1 is the first group (digits), p2 is the second group (decimal and digits)
                                      );
                                      setFieldValue(
                                        "breakdownDetailBean.revenueLost",
                                        formattedValue
                                      );
                                    }}
                                    variant="outlined"
                                    backGroundColor="#22272B"
                                    fieldheight="40px"
                                    textColor={
                                      cardEdit
                                        ? "rgba(255, 255, 255, 1)"
                                        : "rgba(140, 155, 171, 1)"
                                    }
                                    inputProps={{
                                      readOnly: !cardEdit,
                                    }}
                                  />
                                </Grid>
                              </Grid>
                              <Grid
                                container
                                sx={{ marginBottom: "20px" }}
                                spacing={3}
                              >
                                <Grid item xs={6}>
                                  <MediumTypography
                                    labelId="cm.observationText"
                                    defaultLabel="Observation"
                                    sxProps={{ marginBottom: "10px" }}
                                    textColor={"rgba(159, 173, 188, 1)"}
                                  />
                                  <TextField
                                    variant="outlined"
                                    sx={{
                                      "& .MuiInputBase-input": {
                                        fontFamily: "Inter", // Set the font family
                                        fontSize: "14px", // Set the font size
                                        fontWeight: "400", // Set the font weight
                                        color: cardEdit
                                          ? "rgba(255, 255, 255, 1)"
                                          : "rgba(140, 155, 171, 1)",
                                      },
                                      backgroundColor: "#22272B",
                                      borderRadius: "4px",
                                      "& .MuiOutlinedInput-root": {
                                        "& fieldset": {
                                          borderColor: "#1D2226",
                                        },

                                        "&:hover fieldset": {
                                          borderColor: "#1D2226",
                                        },
                                        "&.Mui-focused fieldset": {
                                          borderColor: "#1D2226",
                                        },
                                        "& input": {
                                          color: cardEdit
                                            ? "rgba(255, 255, 255, 1)"
                                            : "rgba(140, 155, 171, 1)", // Text color inside the input
                                        },
                                      },
                                      "& .MuiInputLabel-root": {
                                        color: "#bbb", // Placeholder color
                                      },
                                      border:
                                        "1px solid rgba(166, 197, 226, 0.16)",
                                    }}
                                    multiline
                                    minRows={3}
                                    fullWidth
                                    value={
                                      values.breakdownDetailBean.observation
                                    }
                                    onChange={(event) => {
                                      setFieldValue(
                                        "breakdownDetailBean.observation",
                                        event.target.value.trimStart()
                                      );
                                    }}
                                    inputProps={{ readOnly: !cardEdit }}
                                  />
                                  {errors.breakdownDetailBean?.observation &&
                                    touched.breakdownDetailBean
                                      ?.observation && (
                                      <Box
                                        className="loginError"
                                        sx={{ position: "relative" }}
                                      >
                                        <Box>
                                          <ErrorIcon />
                                        </Box>
                                        <MediumTypography
                                          labelId="vaidation.fieldRequired"
                                          defaultLabel="This field is required."
                                          fontSize="12px"
                                          textColor="#AE2E24"
                                          fontWeight={400}
                                        />
                                      </Box>
                                    )}
                                </Grid>
                                <Grid item xs={6}>
                                  <MediumTypography
                                    labelId="BreakDown.description"
                                    defaultLabel="Breakdown Description"
                                    sxProps={{ marginBottom: "10px" }}
                                    textColor={"rgba(159, 173, 188, 1)"}
                                  />
                                  <TextField
                                    variant="outlined"
                                    sx={{
                                      "& .MuiInputBase-input": {
                                        fontFamily: "Inter", // Set the font family
                                        fontSize: "14px", // Set the font size
                                        fontWeight: "400", // Set the font weight
                                        color: cardEdit
                                          ? "rgba(255, 255, 255, 1)"
                                          : "rgba(140, 155, 171, 1)",
                                      },
                                      backgroundColor: "#22272B",
                                      borderRadius: "4px",
                                      "& .MuiOutlinedInput-root": {
                                        "& fieldset": {
                                          borderColor: "#1D2226",
                                        },

                                        "&:hover fieldset": {
                                          borderColor: "#1D2226",
                                        },
                                        "&.Mui-focused fieldset": {
                                          borderColor: "#1D2226",
                                        },
                                        "& input": {
                                          color: cardEdit
                                            ? "rgba(255, 255, 255, 1)"
                                            : "rgba(140, 155, 171, 1)", // Text color inside the input
                                        },
                                      },
                                      "& .MuiInputLabel-root": {
                                        color: "#bbb", // Placeholder color
                                      },
                                      border:
                                        "1px solid rgba(166, 197, 226, 0.16)",
                                    }}
                                    multiline
                                    minRows={3}
                                    fullWidth
                                    value={
                                      values.breakdownDetailBean
                                        .breakDownDescription
                                    }
                                    onChange={(event) => {
                                      setFieldValue(
                                        "breakdownDetailBean.breakDownDescription",
                                        event.target.value.trimStart()
                                      );
                                    }}
                                    inputProps={{ readOnly: !cardEdit }}
                                  />
                                  {errors.breakdownDetailBean
                                    ?.breakDownDescription &&
                                    touched.breakdownDetailBean
                                      ?.breakDownDescription && (
                                      <Box
                                        className="loginError"
                                        sx={{ position: "relative" }}
                                      >
                                        <Box>
                                          <ErrorIcon />
                                        </Box>
                                        <MediumTypography
                                          labelId="validation.fieldRequired"
                                          defaultLabel="This field is required."
                                          fontSize="12px"
                                          textColor="#AE2E24"
                                          fontWeight={400}
                                        />
                                      </Box>
                                    )}
                                </Grid>
                              </Grid>
                              <Grid container sx={{ marginBottom: "20px" }}>
                                <Grid
                                  xs={12}
                                  lg={12}
                                  md={12}
                                  sx={{ marginTop: "10px" }}
                                >
                                  <MediumTypography
                                    labelId="Revoke.RemarksText"
                                    defaultLabel="Remarks"
                                    sxProps={{ marginBottom: "10px" }}
                                    textColor={"rgba(159, 173, 188, 1)"}
                                  />
                                  <TextField
                                    variant="outlined"
                                    sx={{
                                      "& .MuiInputBase-input": {
                                        fontFamily: "Inter", // Set the font family
                                        fontSize: "14px", // Set the font size
                                        fontWeight: "400", // Set the font weight
                                        color: cardEdit
                                          ? "rgba(255, 255, 255, 1)"
                                          : "rgba(140, 155, 171, 1)",
                                      },
                                      backgroundColor: "#22272B",
                                      borderRadius: "4px",
                                      "& .MuiOutlinedInput-root": {
                                        "& fieldset": {
                                          borderColor: "#1D2226",
                                        },

                                        "&:hover fieldset": {
                                          borderColor: "#1D2226",
                                        },
                                        "&.Mui-focused fieldset": {
                                          borderColor: "#1D2226",
                                        },
                                        "& input": {
                                          color: cardEdit
                                            ? "rgba(255, 255, 255, 1)"
                                            : "rgba(140, 155, 171, 1)", // Text color inside the input
                                        },
                                      },
                                      "& .MuiInputLabel-root": {
                                        color: "#bbb", // Placeholder color
                                      },
                                      border:
                                        "1px solid rgba(166, 197, 226, 0.16)",
                                    }}
                                    multiline
                                    minRows={3}
                                    fullWidth
                                    value={values.breakdownDetailBean.remarks}
                                    onChange={(event) => {
                                      setFieldValue(
                                        "breakdownDetailBean.remarks",
                                        event.target.value.trimStart()
                                      );
                                    }}
                                    inputProps={{ readOnly: !cardEdit }}
                                  />
                                </Grid>
                              </Grid>
                            </Box>
                          </Box>
                        </Box>
                        <Grid container className="mt-xs">
                          <Grid item xs={12} lg={12} md={12} xl={12}>
                            <Box>
                              <Divider
                                variant="fullWidth"
                                sx={{
                                  borderTop: "1px solid #333b43",
                                }}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                        <Box sx={{ padding: "24px 0px 24px 0px" }}>
                          <Box className="flex__" sx={{ marginBottom: "30px" }}>
                            <Box>
                              <MediumTypography
                                labelId="CM.AdditionalInfo"
                                defaultLabel="Additional Info"
                                fontSize="20px"
                                fontWeight={500}
                                textColor="#FFFFFF"
                              />
                              <Grid
                                container
                                direction={"row"}
                                className="justifyContentSpaceBtw"
                                alignItems={"center"}
                                rowSpacing={"16px"}
                                columnSpacing={"40px"}
                                sx={{ marginBottom: "20px", marginTop: "10px" }}
                              >
                                <Grid item>
                                  <MediumTypography
                                    labelId="ReportedByText"
                                    defaultLabel="Reported by"
                                    sxProps={{ marginBottom: "10px" }}
                                    textColor={"rgba(159, 173, 188, 1)"}
                                  />
                                  <DropdownComponent
                                    names={convertToOptionTypeFromAssignee(
                                      correctiveMaintenance.cmAdditionalInfoBean
                                        .reportMmeberList
                                    )}
                                    value={
                                      values.cmAdditionalInfoBean
                                        .selectedReportedBy === 0
                                        ? ""
                                        : values.cmAdditionalInfoBean.selectedReportedBy.toString()
                                    }
                                    handleChange={(value: string) => {
                                      setFieldValue(
                                        "cmAdditionalInfoBean.selectedReportedBy",
                                        parseInt(value)
                                      );
                                    }}
                                    disabled={!cardEdit}
                                    labelid="ReportedByLabel"
                                    defaultlabelid="Select Reporter"
                                    minWidth="250px"
                                    sxProps={{ width: "250px" }}
                                  />
                                  {errors.cmAdditionalInfoBean
                                    ?.selectedReportedBy &&
                                    touched.cmAdditionalInfoBean
                                      ?.selectedReportedBy && (
                                      <Box
                                        className="loginError"
                                        sx={{ position: "relative" }}
                                      >
                                        <Box>
                                          <ErrorIcon />
                                        </Box>
                                        <MediumTypography
                                          labelId="vaidation.fieldRequired"
                                          defaultLabel="This field is required."
                                          fontSize="12px"
                                          textColor="#AE2E24"
                                          fontWeight={400}
                                        />
                                      </Box>
                                    )}
                                </Grid>
                                <Grid item>
                                  <MediumTypography
                                    labelId="ReportedOnText"
                                    defaultLabel="Reported on"
                                    sxProps={{ marginBottom: "10px" }}
                                    textColor={"rgba(159, 173, 188, 1)"}
                                  />
                                  <DateTimePickerComponent
                                    value={
                                      values.cmAdditionalInfoBean.reportedOn !==
                                      null
                                        ? dayjs(
                                            values.cmAdditionalInfoBean.reportedOn?.toString()
                                          )
                                        : null
                                    }
                                    labelid="ReportedOnText"
                                    defaultlabelid="Reported on"
                                    handlechange={(date: Dayjs) => {
                                      const formattedText = date.format(
                                        "YYYY-MM-DDTHH:mm:ss"
                                      );
                                      setFieldValue(
                                        "cmAdditionalInfoBean.reportedOn",
                                        formattedText
                                      );
                                    }}
                                    disabledDate={!cardEdit}
                                    inputFormat="DD MMM YYYY  HH:mm:ss"
                                    width="250px"
                                    textWidth="250px"
                                  />
                                  {errors.cmAdditionalInfoBean?.reportedOn &&
                                    touched.cmAdditionalInfoBean
                                      ?.reportedOn && (
                                      <Box
                                        className="loginError"
                                        sx={{ position: "relative" }}
                                      >
                                        <Box>
                                          <ErrorIcon />
                                        </Box>
                                        <MediumTypography
                                          labelId="vaidation.fieldRequired"
                                          defaultLabel="This field is required."
                                          fontSize="12px"
                                          textColor="#AE2E24"
                                          fontWeight={400}
                                        />
                                      </Box>
                                    )}
                                </Grid>
                                <Grid item>
                                  <MediumTypography
                                    labelId="CumulativeFrequencyText"
                                    defaultLabel="Cumulative Frequency"
                                    sxProps={{ marginBottom: "10px" }}
                                    textColor={"rgba(159, 173, 188, 1)"}
                                  />
                                  <TextInput
                                    labelId="CumulativeFrequencyText"
                                    className="textInputWidth"
                                    defaultLabelId="Cumulative Frequency"
                                    borderColor="1px solid rgba(166, 197, 226, 0.16)"
                                    Value={
                                      values.cmAdditionalInfoBean
                                        .cumulativeCycleFrequency !== null &&
                                      values.cmAdditionalInfoBean
                                        .cumulativeCycleFrequency !== 0
                                        ? values.cmAdditionalInfoBean.cumulativeCycleFrequency.toString()
                                        : ""
                                    }
                                    handlechange={(value: string) => {
                                      setFieldValue(
                                        "cmAdditionalInfoBean.cumulativeCycleFrequency",
                                        value
                                      );
                                    }}
                                    variant="outlined"
                                    backGroundColor="#22272B"
                                    fieldheight="40px"
                                    inputProps={{
                                      readOnly: true,
                                    }}
                                    textColor={"rgba(140, 155, 171, 1)"}
                                  />
                                </Grid>
                                <Grid item>
                                  <MediumTypography
                                    labelId="VendorLabelText"
                                    defaultLabel="Vendor"
                                    sxProps={{ marginBottom: "10px" }}
                                    textColor={"rgba(159, 173, 188, 1)"}
                                  />
                                  <DropdownComponent
                                    names={convertToOptionType(
                                      correctiveMaintenance.cmAdditionalInfoBean
                                        .vendorsList
                                    )}
                                    value={
                                      values.cmAdditionalInfoBean
                                        .selectedVendorId === 0
                                        ? ""
                                        : values.cmAdditionalInfoBean.selectedVendorId.toString()
                                    }
                                    handleChange={(value: string) => {
                                      setFieldValue(
                                        "cmAdditionalInfoBean.selectedVendorId",
                                        parseInt(value)
                                      );
                                    }}
                                    disabled={!cardEdit}
                                    labelid="VendoeSelectionLabel"
                                    defaultlabelid="--Select Vendor--"
                                    minWidth="250px"
                                    sxProps={{ width: "250px" }}
                                  />
                                  {/* {errors.cmAdditionalInfoBean
                                    ?.selectedVendorId &&
                                    touched.cmAdditionalInfoBean
                                      ?.selectedVendorId && (
                                      <Box
                                        className="loginError"
                                        sx={{ position: "relative" }}
                                      >
                                        <Box>
                                          <ErrorIcon />
                                        </Box>
                                        <MediumTypography
                                          labelId="vaidation.fieldRequired"
                                          defaultLabel="This field is required."
                                          fontSize="12px"
                                          textColor="#AE2E24"
                                          fontWeight={400}
                                        />
                                      </Box>
                                    )} */}
                                </Grid>
                              </Grid>
                              <Grid
                                container
                                direction={"row"}
                                className="justifyContentSpaceBtw"
                                alignItems={"center"}
                                rowSpacing={"16px"}
                                columnSpacing={"40px"}
                                sx={{ marginBottom: "20px", marginTop: "10px" }}
                              >
                                <Grid item>
                                  <MediumTypography
                                    labelId="ContractLineItemLabelText"
                                    defaultLabel="Contract Line Item"
                                    sxProps={{ marginBottom: "10px" }}
                                    textColor={"rgba(159, 173, 188, 1)"}
                                  />
                                  <TextInput
                                    labelId="ContractLineItemLabelText"
                                    className="textInputWidth"
                                    defaultLabelId="Contract Line Item"
                                    borderColor="1px solid rgba(166, 197, 226, 0.16)"
                                    Value={
                                      values.cmAdditionalInfoBean
                                        .contractLineItem !== null &&
                                      values.cmAdditionalInfoBean
                                        .contractLineItem !== 0
                                        ? values.cmAdditionalInfoBean.contractLineItem.toString()
                                        : ""
                                    }
                                    handlechange={(value: string) => {
                                      setFieldValue(
                                        "cmAdditionalInfoBean.contractLineItem",
                                        value
                                      );
                                    }}
                                    variant="outlined"
                                    backGroundColor="#22272B"
                                    fieldheight="40px"
                                    inputProps={{
                                      readOnly: true,
                                    }}
                                    textColor={"rgba(140, 155, 171, 1)"}
                                  />
                                </Grid>
                                <Grid item>
                                  <MediumTypography
                                    labelId="ScopeText"
                                    defaultLabel="Scope"
                                    sxProps={{ marginBottom: "10px" }}
                                    textColor={"rgba(159, 173, 188, 1)"}
                                  />
                                  <DropdownComponent
                                    names={convertToOptionType(
                                      correctiveMaintenance.cmAdditionalInfoBean
                                        .scopes
                                    )}
                                    value={
                                      values.cmAdditionalInfoBean
                                        .selectedScopeId === 0
                                        ? ""
                                        : values.cmAdditionalInfoBean.selectedScopeId.toString()
                                    }
                                    handleChange={(value: string) => {
                                      setFieldValue(
                                        "cmAdditionalInfoBean.selectedScopeId",
                                        parseInt(value)
                                      );
                                    }}
                                    disabled={!cardEdit}
                                    labelid="ScopeSelectionLabel"
                                    defaultlabelid="Select Scope"
                                    minWidth="250px"
                                    sxProps={{ width: "250px" }}
                                  />
                                  {errors.cmAdditionalInfoBean
                                    ?.selectedScopeId &&
                                    touched.cmAdditionalInfoBean
                                      ?.selectedScopeId && (
                                      <Box
                                        className="loginError"
                                        sx={{ position: "relative" }}
                                      >
                                        <Box>
                                          <ErrorIcon />
                                        </Box>
                                        <MediumTypography
                                          labelId="vaidation.fieldRequired"
                                          defaultLabel="This field is required."
                                          fontSize="12px"
                                          textColor="#AE2E24"
                                          fontWeight={400}
                                        />
                                      </Box>
                                    )}
                                </Grid>
                                <Grid item>
                                  <MediumTypography
                                    labelId="cm.scopeDistributionText"
                                    defaultLabel="Scope Distribution"
                                    sxProps={{ marginBottom: "10px" }}
                                    textColor={"rgba(159, 173, 188, 1)"}
                                  />
                                  <TextInput
                                    labelId="cm.scopeDistributionText"
                                    className="textInputWidth"
                                    defaultLabelId="Scope Distribution"
                                    borderColor="1px solid rgba(166, 197, 226, 0.16)"
                                    Value={
                                      values.cmAdditionalInfoBean
                                        .scopeDistribution !== null &&
                                      values.cmAdditionalInfoBean
                                        .scopeDistribution !== 0
                                        ? values.cmAdditionalInfoBean.scopeDistribution.toString()
                                        : ""
                                    }
                                    handlechange={(value: string) => {
                                      setFieldValue(
                                        "cmAdditionalInfoBean.scopeDistribution",
                                        value
                                      );
                                    }}
                                    variant="outlined"
                                    backGroundColor="#22272B"
                                    fieldheight="40px"
                                    inputProps={{
                                      readOnly: !cardEdit,
                                      maxLength: 50,
                                    }}
                                    textColor={
                                      cardEdit
                                        ? "rgba(255, 255, 255, 1)"
                                        : "rgba(140, 155, 171, 1)"
                                    }
                                  />
                                </Grid>
                                <Grid item>
                                  <MediumTypography
                                    labelId="alarmCodeText"
                                    defaultLabel="SCADA Alarm code"
                                    sxProps={{ marginBottom: "10px" }}
                                    textColor={"rgba(159, 173, 188, 1)"}
                                  />
                                  <TextInput
                                    labelId="alarmCodeText"
                                    className="textInputWidth"
                                    defaultLabelId="SCADA Alarm code"
                                    borderColor="1px solid rgba(166, 197, 226, 0.16)"
                                    Value={values.cmAdditionalInfoBean.scadaAlarmCode.toString()}
                                    handlechange={(value: string) => {
                                      setFieldValue(
                                        "cmAdditionalInfoBean.scadaAlarmCode",
                                        value
                                      );
                                    }}
                                    variant="outlined"
                                    backGroundColor="#22272B"
                                    fieldheight="40px"
                                    inputProps={{
                                      readOnly: !cardEdit,
                                      maxLength: 50,
                                    }}
                                    textColor={
                                      cardEdit
                                        ? "rgba(255, 255, 255, 1)"
                                        : "rgba(140, 155, 171, 1)"
                                    }
                                  />
                                </Grid>
                              </Grid>
                            </Box>
                          </Box>
                        </Box>
                        <Grid container className="mt-xs">
                          <Grid item xs={12} lg={12} md={12} xl={12}>
                            <Box>
                              <Divider
                                variant="fullWidth"
                                sx={{
                                  borderTop: "1px solid #333b43",
                                }}
                              />
                            </Box>
                          </Grid>
                        </Grid>

                        <Grid
                          item
                          sx={{ marginBottom: "20px", marginTop: "16px" }}
                        >
                          <Box
                            className="flex__"
                            sx={{
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <MediumTypography
                              labelId="consumablesText"
                              defaultLabel="Consumables & Spares"
                              sxProps={{
                                fontSize: "16px",
                              }}
                            />
                            <ButtonComponent
                              className="btn-primary btn-submit mr-md"
                              variantType="contained"
                              defaultLabelId={"Add Row"}
                              labelId={"btn.AddRow"}
                              onClick={addNewRow}
                              disabled={!cardEdit}
                            />
                          </Box>
                        </Grid>
                        <Box sx={{ marginRight: "16px" }}>
                          <TableContainer
                            sx={{ maxHeight: "400px", marginBottom: "30px" }}
                            className="mr-md tableStyles"
                          >
                            <Table stickyHeader aria-label="sticky table">
                              <TableHead>
                                <TableRow>
                                  <TableCell
                                    sx={{
                                      verticalAlign: "top",
                                      border: "1px solid #323337",
                                      backgroundColor: "#22272B",
                                      width: "50px",
                                    }}
                                  >
                                    <Box>
                                      <MediumTypography
                                        sxProps={{
                                          fontWeight: 700,
                                          color: "#FFFF",
                                          whiteSpace: "nowrap",

                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                        labelId={"table.srNo"}
                                        defaultLabel="Sr. No."
                                      />
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      verticalAlign: "top",
                                      border: "1px solid #323337",
                                      backgroundColor: "#22272B",
                                      width: "100px",
                                    }}
                                  >
                                    <Box>
                                      <MediumTypography
                                        sxProps={{
                                          fontWeight: 700,
                                          color: "#FFFF",
                                          whiteSpace: "nowrap",

                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                        labelId="table.Category"
                                        defaultLabel="Category"
                                      />
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      verticalAlign: "top",
                                      border: "1px solid #323337",
                                      backgroundColor: "#22272B",
                                      width: "100px",
                                    }}
                                  >
                                    <Box>
                                      <MediumTypography
                                        sxProps={{
                                          fontWeight: 700,
                                          color: "#FFFF",
                                          whiteSpace: "nowrap",

                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                        labelId={"table.materialCode"}
                                        defaultLabel="Material Code"
                                      />
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      verticalAlign: "top",
                                      border: "1px solid #323337",
                                      backgroundColor: "#22272B",
                                      width: "250px",
                                    }}
                                  >
                                    <Box>
                                      <MediumTypography
                                        sxProps={{
                                          fontWeight: 700,
                                          color: "#FFFF",
                                          whiteSpace: "nowrap",

                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                        labelId={"table.description"}
                                        defaultLabel="Description"
                                      />
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      verticalAlign: "top",
                                      border: "1px solid #323337",
                                      backgroundColor: "#22272B",
                                      width: "100px",
                                    }}
                                  >
                                    <Box>
                                      <MediumTypography
                                        sxProps={{
                                          fontWeight: 700,
                                          color: "#FFFF",
                                          whiteSpace: "nowrap",

                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                        labelId={"table.uom"}
                                        defaultLabel="UoM"
                                      />
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      verticalAlign: "top",
                                      border: "1px solid #323337",
                                      backgroundColor: "#22272B",
                                      width: "100px",
                                    }}
                                  >
                                    <Box>
                                      <MediumTypography
                                        sxProps={{
                                          fontWeight: 700,
                                          color: "#FFFF",
                                          whiteSpace: "nowrap",

                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                        labelId={"quantConsumed.text"}
                                        defaultLabel="Quant Consumed"
                                      />
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      verticalAlign: "top",
                                      border: "1px solid #323337",
                                      backgroundColor: "#22272B",
                                      width: "50px",
                                    }}
                                  >
                                    <Box>
                                      <MediumTypography
                                        sxProps={{
                                          fontWeight: 700,
                                          color: "#FFFF",
                                          whiteSpace: "nowrap",

                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                        labelId={""}
                                        defaultLabel=""
                                      />
                                    </Box>
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {toolsTableData &&
                                  toolsTableData.map((row, index) => {
                                    return (
                                      <TableRow key={row.id}>
                                        <TableCell
                                          sx={{
                                            verticalAlign: "top",
                                            border: "1px solid #323337",
                                            backgroundColor: "#22272B",
                                            width: "50px",
                                          }}
                                        >
                                          <MediumTypography
                                            label={(index + 1).toString()}
                                          />
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            verticalAlign: "top",
                                            border: "1px solid #323337",
                                            backgroundColor: "#22272B",
                                            width: "100px",
                                          }}
                                        >
                                          <Box
                                            sx={{
                                              display: "flex",
                                              height: "32px",
                                              width: "100%",
                                            }}
                                            onClick={(
                                              event: React.MouseEvent<HTMLElement>
                                            ) =>
                                              handleClickSubCategory(
                                                event,
                                                row.id
                                              )
                                            }
                                          >
                                            <MediumTypography
                                              label={row.data.subCategory}
                                            />
                                          </Box>
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            verticalAlign: "top",
                                            border: "1px solid #323337",
                                            backgroundColor: "#22272B",
                                            width: "50px",
                                          }}
                                          onClick={(
                                            event: React.MouseEvent<HTMLElement>
                                          ) => handleClickCodes(event, row.id)}
                                        >
                                          <MediumTypography
                                            label={
                                              // selectedCodes[row.id]
                                              //   ? selectedCodes[row.id].name
                                              //   : "" // Default text or leave it empty"

                                              row.data.code //Please check the logic pavan of prepopulating table values.
                                            }
                                          />
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            verticalAlign: "top",
                                            border: "1px solid #323337",
                                            backgroundColor: "#22272B",
                                            width: "50px",
                                          }}
                                          onClick={(
                                            event: React.MouseEvent<HTMLElement>
                                          ) =>
                                            handleClickDescription(
                                              event,
                                              row.id
                                            )
                                          }
                                        >
                                          <MediumTypography
                                            label={
                                              // selectedDescriptions[row.id]
                                              //   ? selectedDescriptions[row.id].name
                                              //   : "" // Default text or leave it empty

                                              row.data.description //Please check the logic pavan of prepopulating table values.
                                            }
                                          />
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            verticalAlign: "top",
                                            border: "1px solid #323337",
                                            backgroundColor: "#22272B",
                                            width: "50px",
                                          }}
                                        >
                                          <MediumTypography
                                            label={
                                              // selectedUOMs[row.id]
                                              //   ? selectedUOMs[row.id].name
                                              //   : "" // Default text or leave it empty

                                              row.data.uom //Please check the logic pavan of prepopulating table values.
                                            }
                                          />
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            verticalAlign: "top",
                                            border: "1px solid #323337",
                                            backgroundColor: "#22272B",
                                            width: "50px",
                                          }}
                                        >
                                          <TextField
                                            variant="outlined"
                                            sx={{
                                              backgroundColor: "#22272B",

                                              "& .MuiOutlinedInput-root": {
                                                "& fieldset": {
                                                  border: "none",
                                                },

                                                "&:hover fieldset": {
                                                  border: "none",
                                                },
                                                "&.Mui-focused fieldset": {
                                                  border: "none",
                                                },
                                                "& input": {
                                                  padding: 0,
                                                  color: cardEdit
                                                    ? "rgba(255, 255, 255, 1)"
                                                    : "rgba(140, 155, 171, 1)", // Text color inside the input
                                                },
                                              },
                                            }}
                                            value={
                                              // quant[row.id]
                                              //   ? quant[row.id].name
                                              //   : ""

                                              row.data.quantity //Please check the logic pavan of prepopulating table values.
                                            }
                                            onChange={(event) =>
                                              handleQuantChange(
                                                row.id,
                                                index,
                                                event.target.value
                                              )
                                            }
                                          />
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            verticalAlign: "top",
                                            border: "1px solid #323337",
                                            backgroundColor: "#22272B",
                                            width: "50px",
                                          }}
                                        >
                                          {!editDisable && (
                                            <Delete
                                              onClick={() => {
                                                handleDelete(row.id);
                                              }}
                                            />
                                          )}
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })}
                              </TableBody>
                              <SearchDropDown
                                open={open}
                                onClose={handleCloseSubCategory}
                                anchorE1={anchorEl}
                                width="300px"
                                searchWidth="270px"
                                Data={subCategories}
                                onSelect={(id, name) => {
                                  if (activeRowId) {
                                    handleSelectSubcategory(
                                      activeRowId,
                                      id,
                                      name
                                    );
                                    setActiveRowId(null);
                                  }
                                }}
                              />
                              <SearchDropDown
                                open={openCode}
                                onClose={handleCloseCodes}
                                anchorE1={anchorElCode}
                                width="300px"
                                searchWidth="270px"
                                Data={codes}
                                onSelect={(id, name) => {
                                  if (activeRowId) {
                                    handleSelectCodes(activeRowId, id, name);
                                    setActiveRowId(null);
                                  }
                                }}
                              />
                              <SearchDropDown
                                open={openDescription}
                                onClose={handleCloseDescription}
                                anchorE1={anchorElDescription}
                                width="500px"
                                searchWidth="450px"
                                Data={description}
                                onSelect={(id, name) => {
                                  if (activeRowId) {
                                    handleSelectDescription(
                                      activeRowId,
                                      id,
                                      name
                                    );
                                    setActiveRowId(null);
                                  }
                                }}
                              />
                            </Table>
                            {(toolsTableData?.length === 0 ||
                              !toolsTableData) && (
                              <Box
                                sx={{
                                  backgroundColor: "#22272B",
                                  width: "100%",
                                  padding: "24px",
                                  display: "flex",
                                  borderBottomLeftRadius: "4px",
                                  borderBottomRightRadius: "4px",
                                  justifyContent: "center",
                                }}
                              >
                                <MediumTypography
                                  labelId="CM.EmptyMessage"
                                  defaultLabel="No records added yet"
                                />
                              </Box>
                            )}
                          </TableContainer>
                        </Box>
                        <Grid container className="mt-xs">
                          <Grid item xs={12} lg={12} md={12} xl={12}>
                            <Box>
                              <Divider
                                variant="fullWidth"
                                sx={{
                                  borderTop: "1px solid #333b43",
                                }}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                        <WorkOrderAssignees
                          leadAssignee={leadAssignee}
                          assignees={assignees}
                          onChangeRequest={
                            cardEdit ? onAssigneeChange : undefined
                          }
                        />

                        <Grid container className="mt-xs">
                          <Grid item xs={12} lg={12} md={12} xl={12}>
                            <Box>
                              <Divider
                                variant="fullWidth"
                                sx={{
                                  borderTop: "1px solid #333b43",
                                }}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                        <Box className="mt-md mb-lg ">
                          <MediumTypography
                            labelId="ApproverText"
                            defaultLabel="Approver"
                            fontSize="20px"
                            fontWeight={500}
                            textColor="#FFFFFF"
                            className="mb-md"
                          />
                          <Box className="flex__">
                            {approver1 !== undefined && (
                              <Box
                                className="flex__"
                                sx={{
                                  width: "146px",
                                  height: "56px",
                                  borderRadius: "4px",
                                  border: "1px solid rgba(166, 197, 226, 0.16)",
                                  backgroundColor: "#22272B",
                                  marginRight: "16px",
                                }}
                              >
                                <>
                                  {approver1 !== undefined &&
                                    approver1 !== null && (
                                      <React.Fragment>
                                        <Box
                                          className="flex__ align__items__center ml-sm"
                                          sx={{ position: "relative" }}
                                        >
                                          <Avatar
                                            src={approver1.image}
                                            alt="abc"
                                            style={{
                                              width: "32px",
                                              height: "32px",
                                            }}
                                          />
                                        </Box>
                                        <Box className="pt-sm">
                                          <Box className="flex__justify__space-between">
                                            <Box
                                              sx={{
                                                width: "100px",
                                                marginTop: "4px",
                                              }}
                                            >
                                              <Tooltip
                                                title={
                                                  approver1.name &&
                                                  approver1.name.length > 12
                                                    ? approver1.name
                                                    : ""
                                                }
                                                arrow={true}
                                                followCursor
                                              >
                                                <Box>
                                                  <MediumTypography
                                                    label={approver1.name}
                                                    className="ml-sm ellipsis"
                                                    fontSize="12px"
                                                    textColor="#FFFFFF"
                                                    sxProps={{
                                                      width:
                                                        approver1.name.length >
                                                        12
                                                          ? "100px"
                                                          : "auto",
                                                    }}
                                                  />
                                                </Box>
                                              </Tooltip>
                                              <Tooltip
                                                title={
                                                  approver1.roleName &&
                                                  approver1.roleName.length > 12
                                                    ? approver1.roleName
                                                    : ""
                                                }
                                                arrow={true}
                                                followCursor
                                              >
                                                <Box>
                                                  <MediumTypography
                                                    label={approver1.roleName}
                                                    className=" ml-sm ellipsis"
                                                    textColor="rgba(255, 255, 255, 0.64)"
                                                    fontSize="10px"
                                                    fontWeight={400}
                                                    sxProps={{
                                                      width:
                                                        approver1.roleName
                                                          .length > 12
                                                          ? "100px"
                                                          : "auto",
                                                      marginTop:
                                                        approver1.name === ""
                                                          ? "8px"
                                                          : "0px",
                                                    }}
                                                  />
                                                </Box>
                                              </Tooltip>
                                            </Box>{" "}
                                          </Box>
                                        </Box>
                                      </React.Fragment>
                                    )}
                                  {approver1 !== undefined &&
                                    approver1 === null && (
                                      <React.Fragment>
                                        <Box
                                          className="flex__ align__items__center ml-sm"
                                          sx={{ position: "relative" }}
                                        >
                                          <Avatar
                                            src={""}
                                            alt="No approver"
                                            style={{
                                              width: "32px",
                                              height: "32px",
                                            }}
                                          />
                                        </Box>
                                        <Box className=" pt-sm">
                                          <Box className="flex__justify__space-between">
                                            <Box>
                                              <MediumTypography
                                                label={""}
                                                className="ml-xs ellipsis"
                                                fontSize="12px"
                                                textColor="#FFFFFF"
                                              />
                                              <MediumTypography
                                                label={""}
                                                className=" ml-xs ellipsis"
                                                textColor="rgba(255, 255, 255, 0.64)"
                                                fontSize="10px"
                                                fontWeight={400}
                                              />
                                            </Box>
                                          </Box>
                                        </Box>
                                      </React.Fragment>
                                    )}
                                </>
                              </Box>
                            )}

                            {approver2 !== undefined && (
                              <Box
                                className="flex__"
                                sx={{
                                  width: "146px",
                                  height: "56px",
                                  borderRadius: "4px",
                                  border: "1px solid rgba(166, 197, 226, 0.16)",
                                  backgroundColor: "#22272B",
                                  marginRight: "16px",
                                }}
                              >
                                {approver2 !== undefined &&
                                  approver2 !== null && (
                                    <React.Fragment>
                                      <Box
                                        className="flex__ align__items__center ml-sm"
                                        sx={{ position: "relative" }}
                                      >
                                        <Avatar
                                          src={approver2.image}
                                          alt="abc"
                                          style={{
                                            width: "32px",
                                            height: "32px",
                                          }}
                                        />
                                      </Box>
                                      <Box className="pt-sm">
                                        <Box className="flex__justify__space-between">
                                          <Box
                                            sx={{
                                              width: "100px",
                                              marginTop: "4px",
                                            }}
                                          >
                                            <Tooltip
                                              title={
                                                approver2.name &&
                                                approver2.name.length > 12
                                                  ? approver2.name
                                                  : ""
                                              }
                                              arrow={true}
                                              followCursor
                                            >
                                              <Box>
                                                <MediumTypography
                                                  label={approver2.name}
                                                  className="ml-sm ellipsis"
                                                  fontSize="12px"
                                                  textColor="#FFFFFF"
                                                  sxProps={{
                                                    width:
                                                      approver2.name.length > 12
                                                        ? "100px"
                                                        : "auto",
                                                  }}
                                                />
                                              </Box>
                                            </Tooltip>
                                            <Tooltip
                                              title={
                                                approver2.roleName &&
                                                approver2.roleName.length > 12
                                                  ? approver2.roleName
                                                  : ""
                                              }
                                              arrow={true}
                                              followCursor
                                            >
                                              <Box>
                                                <MediumTypography
                                                  label={approver2.roleName}
                                                  className=" ml-sm ellipsis"
                                                  textColor="rgba(255, 255, 255, 0.64)"
                                                  fontSize="10px"
                                                  fontWeight={400}
                                                  sxProps={{
                                                    width:
                                                      approver2.roleName
                                                        .length > 12
                                                        ? "100px"
                                                        : "auto",
                                                    marginTop:
                                                      approver2.name === ""
                                                        ? "8px"
                                                        : "0px",
                                                  }}
                                                />
                                              </Box>
                                            </Tooltip>
                                          </Box>
                                        </Box>
                                      </Box>
                                    </React.Fragment>
                                  )}
                                {approver2 !== undefined &&
                                  approver2 === null && (
                                    <React.Fragment>
                                      <Box
                                        className="flex__ align__items__center ml-sm"
                                        sx={{ position: "relative" }}
                                      >
                                        <Avatar
                                          src={""}
                                          alt="No approver"
                                          style={{
                                            width: "32px",
                                            height: "32px",
                                          }}
                                        />
                                      </Box>
                                      <Box className=" pt-sm">
                                        <Box className="flex__justify__space-between">
                                          <Box>
                                            <MediumTypography
                                              label={""}
                                              className="ml-xs ellipsis"
                                              fontSize="12px"
                                              textColor="#FFFFFF"
                                            />
                                            <MediumTypography
                                              label={""}
                                              className=" ml-xs ellipsis"
                                              textColor="rgba(255, 255, 255, 0.64)"
                                              fontSize="10px"
                                              fontWeight={400}
                                            />
                                          </Box>
                                        </Box>
                                      </Box>
                                    </React.Fragment>
                                  )}
                              </Box>
                            )}

                            {approver3 !== undefined && (
                              <Box
                                className="flex__"
                                sx={{
                                  width: "146px",
                                  height: "56px",
                                  borderRadius: "4px",
                                  border: "1px solid rgba(166, 197, 226, 0.16)",
                                  backgroundColor: "#22272B",
                                  marginRight: "16px",
                                }}
                              >
                                {approver3 !== undefined &&
                                  approver3 !== null && (
                                    <React.Fragment>
                                      <Box
                                        className="flex__ align__items__center ml-sm"
                                        sx={{ position: "relative" }}
                                      >
                                        <Avatar
                                          src={approver3.image}
                                          alt="abc"
                                          style={{
                                            width: "32px",
                                            height: "32px",
                                          }}
                                        />
                                      </Box>
                                      <Box className="pt-sm">
                                        <Box className="flex__justify__space-between">
                                          <Box
                                            sx={{
                                              width: "100px",
                                              marginTop: "4px",
                                            }}
                                          >
                                            <Tooltip
                                              title={
                                                approver3.name &&
                                                approver3.name.length > 12
                                                  ? approver3.name
                                                  : ""
                                              }
                                              arrow={true}
                                              followCursor
                                            >
                                              <Box>
                                                <MediumTypography
                                                  label={approver3.name}
                                                  className="ml-sm ellipsis"
                                                  fontSize="12px"
                                                  textColor="#FFFFFF"
                                                  sxProps={{
                                                    width:
                                                      approver3.name.length > 12
                                                        ? "100px"
                                                        : "auto",
                                                  }}
                                                />
                                              </Box>
                                            </Tooltip>
                                            <Tooltip
                                              title={
                                                approver3.roleName &&
                                                approver3.roleName.length > 12
                                                  ? approver3.roleName
                                                  : ""
                                              }
                                              arrow={true}
                                              followCursor
                                            >
                                              <Box>
                                                <MediumTypography
                                                  label={approver3.roleName}
                                                  className=" ml-sm ellipsis"
                                                  textColor="rgba(255, 255, 255, 0.64)"
                                                  fontSize="10px"
                                                  fontWeight={400}
                                                  sxProps={{
                                                    width:
                                                      approver3.roleName
                                                        .length > 12
                                                        ? "100px"
                                                        : "auto",
                                                    marginTop:
                                                      approver3.name === ""
                                                        ? "8px"
                                                        : "0px",
                                                  }}
                                                />
                                              </Box>
                                            </Tooltip>
                                          </Box>
                                        </Box>
                                      </Box>
                                    </React.Fragment>
                                  )}{" "}
                                {approver3 !== undefined &&
                                  approver3 === null && (
                                    <React.Fragment>
                                      <Box
                                        className="flex__ align__items__center ml-sm"
                                        sx={{ position: "relative" }}
                                      >
                                        <Avatar
                                          src={""}
                                          alt="No approver"
                                          style={{
                                            width: "32px",
                                            height: "32px",
                                          }}
                                        />
                                      </Box>
                                      <Box className=" pt-sm">
                                        <Box className="flex__justify__space-between">
                                          <Box>
                                            <MediumTypography
                                              label={""}
                                              className="ml-xs ellipsis"
                                              fontSize="12px"
                                              textColor="#FFFFFF"
                                            />
                                            <MediumTypography
                                              label={""}
                                              className=" ml-xs ellipsis"
                                              textColor="rgba(255, 255, 255, 0.64)"
                                              fontSize="10px"
                                              fontWeight={400}
                                            />
                                          </Box>
                                        </Box>
                                      </Box>
                                    </React.Fragment>
                                  )}
                              </Box>
                            )}
                          </Box>
                        </Box>
                        <Grid container className="mt-xs">
                          <Grid item xs={12} lg={12} md={12} xl={12}>
                            <Box>
                              <Divider
                                variant="fullWidth"
                                sx={{
                                  borderTop: "1px solid #333b43",
                                }}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                        <WorkOrderGallery
                          dumgImage={dumgImage}
                          beforeImage={beforeImage}
                          afterImage={afterImage}
                        />
                        <Grid container className="mt-xs">
                          <Grid item xs={12} lg={12} md={12} xl={12}>
                            <Box>
                              <Divider
                                variant="fullWidth"
                                sx={{
                                  borderTop: "1px solid #333b43",
                                }}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                        {correctiveMaintenance.safetyCheckReqAfterCompletion && (
                          <Grid
                            item
                            className="mt-lg"
                            sx={{
                              backgroundColor: "#323337",
                              borderRadius: "4px",
                              marginRight: "16px",
                            }}
                          >
                            <Box>
                              <Grid container direction={"row"}>
                                <Grid
                                  item
                                  className="flex__"
                                  sx={{
                                    padding: "12px 20px 12px 20px",
                                    justifyContent: "space-between",
                                    width: "100%",
                                    alignItems: "center",
                                  }}
                                >
                                  <MediumTypography
                                    labelId="safetyCheckText"
                                    defaultLabel="Safety Checks After Completion"
                                    sxProps={{
                                      fontSize: "16px",
                                    }}
                                  />
                                  <Box className="flex__">
                                    {correctiveMaintenance?.taskResponseBean
                                      ?.isPcChecklistCompleted && (
                                      <TickMark
                                        style={{
                                          marginRight: "8px",
                                          marginTop: "8px",
                                        }}
                                      />
                                    )}
                                    {safetyCheckExpand ? (
                                      <UpArrow
                                        onClick={() =>
                                          setSafetyCheckExpand(false)
                                        }
                                        style={{
                                          cursor: "pointer",
                                          marginTop: "8px",
                                        }}
                                      />
                                    ) : (
                                      <DownArrow
                                        onClick={() =>
                                          setSafetyCheckExpand(true)
                                        }
                                        style={{
                                          cursor: "pointer",
                                          width: "10px",
                                          height: "10px",
                                          marginRight: "8px",
                                          marginTop: "16px",
                                        }}
                                      />
                                    )}
                                  </Box>
                                </Grid>
                              </Grid>
                              <Grid container className="mt-xs">
                                <Grid item xs={12} lg={12} md={12} xl={12}>
                                  <Box>
                                    <Divider
                                      variant="fullWidth"
                                      sx={{
                                        borderTop: "1px solid #545558",
                                      }}
                                    />
                                  </Box>
                                </Grid>
                              </Grid>
                              {safetyCheckExpand && (
                                <Grid container className="ml-md mr-md">
                                  {correctiveMaintenance?.taskResponseBean?.lineItems.map(
                                    (item, index) => (
                                      <React.Fragment key={item.lineItemId}>
                                        <Grid item className={"mt-md mb-md"}>
                                          <MediumTypography
                                            label={`${index + 1}.${
                                              item.description
                                            }`}
                                            sxProps={{ fontSize: "16px" }}
                                            className="mb-sm"
                                          />
                                          <FormControl
                                            component="fieldset"
                                            sx={{ marginLeft: "16px" }}
                                          >
                                            <RadioGroup
                                              aria-label={item.description}
                                              name={`radio-group-${item.lineItemId}`}
                                              value={findSelectedNAIdForLineItem(
                                                item.lineItemId
                                              )}
                                              onChange={(e) =>
                                                handleRadioChange(
                                                  item.lineItemId,
                                                  parseInt(e.target.value)
                                                )
                                              }
                                              row
                                            >
                                              {correctiveMaintenance?.taskResponseBean &&
                                                correctiveMaintenance?.taskResponseBean?.isNAResponse.map(
                                                  (response) => {
                                                    return (
                                                      <FormControlLabel
                                                        key={response.id}
                                                        value={response.id.toString()}
                                                        control={
                                                          <Radio
                                                            sx={{
                                                              "&.Mui-checked": {
                                                                color:
                                                                  "#165FFA",
                                                              },
                                                              padding: "0px",
                                                              color:
                                                                "rgba(127, 127, 128, 1)",
                                                              boxShadow: "none",
                                                              marginRight:
                                                                "8px",
                                                            }}
                                                            disabled={
                                                              !cardEdit ||
                                                              disableSafetyChecks
                                                            }
                                                          />
                                                        }
                                                        label={
                                                          <MediumTypography
                                                            label={
                                                              response.name
                                                            }
                                                          />
                                                        }
                                                      />
                                                    );
                                                  }
                                                )}
                                            </RadioGroup>
                                          </FormControl>
                                        </Grid>
                                        <Grid
                                          item
                                          xs={11.5}
                                          lg={11.5}
                                          md={11.5}
                                          xl={11.5}
                                        >
                                          <Box>
                                            <Divider
                                              variant="fullWidth"
                                              sx={{
                                                borderTop: "1px solid #545558",
                                              }}
                                            />
                                          </Box>
                                        </Grid>
                                      </React.Fragment>
                                    )
                                  )}
                                </Grid>
                              )}
                            </Box>
                          </Grid>
                        )}
                      </Grid>
                      <Grid item xs={3}>
                        {!isDragEnabled && (
                          <WorkOrderActivity
                            isVisible={isVisibleCM}
                            cardId={stateCardId}
                          />
                        )}
                        {isDragEnabled && (
                          <ReassignSidebar
                            teamData={teamData}
                            cardDetails={cardDetails}
                            totalUserCount={userCounts.total}
                            presentUserCount={userCounts.present}
                            cardShiftDetails={shiftData}
                            currentDate={activeDate}
                            isDragEnabled={isDragEnabled}
                            reAssignCallBack={() => {
                              setIsDragEnabled(false);
                              getCMDetails();
                              // setCardMembers({} as ShiftCardTypes);
                              // setCardShiftDetails({} as ShiftsCardType);
                              // callApiCallBack();
                            }}
                            allShiftData={[shiftData]}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                );
              }}
            </Formik>
          </Box>
        )}

        {activePage === "rca" && (
          <RCAInfo id={stateCardId} isVisible={isVisibleCM} />
        )}
      </Box>
    </Box>
  );
};

export default CorrectiveMaintenance;
