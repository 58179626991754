import React from "react";
import { SxProps, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

interface TextProps {
  label?: string | React.ReactNode;
  fontWeight?: number;
  labelId?: string;
  defaultLabel?: string;
  fontSize?: string;
  sxProps?: SxProps;
  onClick?: () => void;
  id?: string;
  className?: string;
  textColor?: string;
}
const MediumTypography: React.FC<TextProps> = ({
  label,
  fontWeight,
  labelId,
  defaultLabel,
  fontSize,
  sxProps,
  onClick,
  id,
  className,
  textColor,
}) => {
  return (
    <Typography
      onClick={onClick}
      id={id}
      className={className}
      sx={{
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: fontWeight ?? 500,
        fontSize: fontSize ?? "14px",
        color: textColor ?? "#FFFFFF",
        ...sxProps,
      }}
    >
      {labelId ? (
        <FormattedMessage id={labelId} defaultMessage={defaultLabel} />
      ) : (
        label
      )}
    </Typography>
  );
};
export default MediumTypography;
