import { useState, useEffect, useRef } from "react";
import { Client } from "@stomp/stompjs";

interface WebSocketConfig {
  url: string;
}

const useWebSocket = (config: WebSocketConfig) => {
  const [connected, setConnected] = useState(false);
  const [error, setError] = useState(null);
  const [reconnecting, setReconnecting] = useState(false);

  const client = useRef<Client | null>(null);
  const reconnectTimeout = useRef<NodeJS.Timeout | null>(null);

  const maxReconnectAttempts = 10;
  const reconnectAttempts = useRef(0);
  const disconnectIntentionally = useRef(false);

  const handleConnectionError = (connectionError: any) => {
    console.log("Connection error:", connectionError);
    setError(connectionError);
  };

  const handleConnectionOpen = () => {
    console.log("Connected to WebSocket");
    setConnected(true);
  };

  const handleConnectionClose = () => {
    console.log("Disconnected from WebSocket");
    setConnected(false);
    if (!disconnectIntentionally.current) {
      reconnect();
    }
  };

  const reconnect = () => {
    try {
      if (!reconnecting && reconnectAttempts.current < maxReconnectAttempts) {
        setReconnecting(true);
        reconnectTimeout.current = setTimeout(() => {
          if (client.current) {
            client.current.activate();
          }
          reconnectAttempts.current++;
          setReconnecting(false);
        }, 5000); // try reconnecting every 5 seconds
      } else {
        console.log("Maximum reconnect attempts reached.");
        setReconnecting(false);
        // You can also add additional logic here to handle the case when the maximum reconnect attempts are reached.
      }
    } catch (catchErr) {
      handleConnectionError(catchErr);
    }
  };

  const connect = () => {
    try {
      if (client.current) {
        disconnectIntentionally.current = false;
        client.current.activate();
      }
    } catch (err) {
      handleConnectionError(err);
    }
  };

  const disconnect = () => {
    try {
      if (client.current) {
        disconnectIntentionally.current = true;
        client.current.deactivate();
      }
    } catch (disconnectErr) {
      handleConnectionError(disconnectErr);
    }
  };

  const subscribe = (destination: string, callback: (message: any) => void) => {
    try {
      if (client.current) {
        client.current.subscribe(destination, (message) => {
          callback(message);
        });
      }
    } catch (connectErr) {
      handleConnectionError(connectErr);
    }
  };

  const unsubscribe = (destination: string) => {
    try {
      if (client.current) {
        client.current.unsubscribe(destination);
      }
    } catch (unSubscribeErr) {
      handleConnectionError(unSubscribeErr);
    }
  };

  const send = (destination: string, body: any, headers: any) => {
    try {
      if (client.current) {
        client.current.publish({
          destination,
          body,
          headers,
        });
      }
    } catch (er) {
      handleConnectionError(er);
    }
  };

  const clientConfig = {
    brokerURL: config.url,
    onConnect: handleConnectionOpen,
    onDisconnect: handleConnectionClose,
    onWebSocketClose: handleConnectionClose,
    onWebSocketError: handleConnectionError,
  };

  useEffect(() => {
    if (config.url) {
      client.current = new Client();
      client.current.configure(clientConfig);
      client.current.onStompError = handleConnectionError;
      client.current.activate();
    }

    return () => {
      disconnect();
    };
  }, []);

  return {
    connected,
    error,
    reconnecting,
    connect,
    disconnect,
    subscribe,
    unsubscribe,
    send,
  };
};

export default useWebSocket;
