import React, { FC, useState, useEffect } from "react";
import { Box, Menu, MenuProps, styled, Theme } from "@mui/material";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import MediumTypography from "../../components/formlib/MediumTypography";
import CheckBox from "../../components/formlib/CheckBox";

import ButtonComponent from "../../components/formlib/ButtonComponent";
import { ReactComponent as Drag } from "../../assets/images/DragIcon.svg";
import { HeadCell } from "./types/accountTypes";

interface TableHeaderProps {
  data: HeadCell[];
  anchorEl: null | HTMLElement;
  handleClose: () => void;
  open: boolean;
  onApply: (reorderedCheckedItems: HeadCell[]) => void;
  fixedItem: string;
}

const CustomMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
    style={{ left: -25 }}
  />
))(({ theme }: { theme: Theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    width: "230px",
    maxWidth: 310,
    maxHeight: 550,
    padding: "0px 0px",
    borderWidth: "0 10px 10px 10px",
    boxShadow: "none",
    backgroundColor: "#22272B",
    border: "1px solid rgba(166, 197, 226, 0.16)",
  },
  "& .MuiPaper-root.MuiPaper-elevation > ul": {
    paddingBottom: "0px",
  },
  "& .MuiPaper-root::-webkit-scrollbar": {
    display: "none",
  },
}));

const TableHeaderSelection: FC<TableHeaderProps> = ({
  data,
  anchorEl,
  handleClose,
  open,
  onApply,
  fixedItem,
}) => {
  const [items, setItems] = useState<HeadCell[]>([]);
  const [checkedItems, setCheckedItems] = useState<Set<string>>(new Set());

  const [localItems, setLocalItems] = useState<HeadCell[]>([]);
  const [localCheckedItems, setLocalCheckedItems] = useState<Set<string>>(
    new Set()
  );

  useEffect(() => {
    const initialItems = data.filter((item) => item.key !== fixedItem);
    const initialCheckedItems = new Set(data.map((item) => item.key));
    setItems(initialItems);
    setCheckedItems(initialCheckedItems);
  }, [data]);

  useEffect(() => {
    if (open) {
      setLocalItems(items);
      setLocalCheckedItems(checkedItems);
    }
  }, [open, items, checkedItems]);

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const reorderedItems = Array.from(localItems);
    const [removed] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, removed);

    setLocalItems(reorderedItems);
  };

  const handleCheckBoxClick = (key: string) => {
    if (key === fixedItem) {
      return;
    }
    setLocalCheckedItems((prev) => {
      const newCheckedItems = new Set(prev);
      if (newCheckedItems.has(key)) {
        newCheckedItems.delete(key);
      } else {
        newCheckedItems.add(key);
      }
      return newCheckedItems;
    });
  };

  const handleApply = () => {
    const accountNameItems = data.find((item) => item.key === fixedItem);
    const reorderedCheckedItems = [
      accountNameItems!,
      ...localItems.filter((item) => localCheckedItems.has(item.key)),
    ];
    onApply(reorderedCheckedItems);
    setItems(localItems);
    setCheckedItems(localCheckedItems);
    handleClose();
  };

  const accountNameItem = data.find((item) => item.key === fixedItem);

  return (
    <CustomMenu
      id="demo-customized-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      sx={{ marginLeft: "15px" }}
    >
      <Box className="p-md" sx={{ position: "relative" }}>
        {accountNameItem && (
          <ul className="no-marker " key={accountNameItem.key}>
            <Box className="flex__ p-sm" sx={{ alignItems: "center" }}>
              <CheckBox value={true} disable={true} className="p-none mr-sm" />
              <Drag />
              <MediumTypography
                label={accountNameItem.value}
                className={"ml-sm"}
              />
            </Box>
          </ul>
        )}
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="droppable">
            {(provider) => (
              <Box {...provider.droppableProps} ref={provider.innerRef}>
                {localItems.map((item, index) => (
                  <Draggable
                    key={item.key}
                    draggableId={item.key}
                    index={index}
                    isDragDisabled={!localCheckedItems.has(item.key)}
                  >
                    {(provided) => (
                      <ul
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        key={item.key}
                        className="no-marker "
                      >
                        <Box
                          className="flex__ p-sm"
                          sx={{ alignItems: "center" }}
                        >
                          <CheckBox
                            value={localCheckedItems.has(item.key)}
                            onCheckBoxClick={() =>
                              handleCheckBoxClick(item.key)
                            }
                            className="p-none mr-sm"
                          />
                          <Drag />
                          <MediumTypography
                            label={item.value}
                            className={"ml-sm"}
                          />
                        </Box>
                      </ul>
                    )}
                  </Draggable>
                ))}
                {provider.placeholder}
              </Box>
            )}
          </Droppable>
        </DragDropContext>
      </Box>
      <Box
        className="width__100 flex__container p-md"
        sx={{
          position: "sticky",
          bottom: 0,
          zIndex: 1,
          backgroundColor: "#1D2125",
        }}
      >
        <ButtonComponent
          className="btn-primary btn-submit"
          labelId="Apply"
          defaultLabelId="Apply"
          sxProps={{ width: "100px" }}
          onClick={handleApply}
        />
      </Box>
    </CustomMenu>
  );
};

export default TableHeaderSelection;
