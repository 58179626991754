import {
  Box,
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  Tooltip,
} from "@mui/material";
import React, {
  useState,
  useRef,
  useEffect,
  ChangeEvent,
  useContext,
  createContext,
} from "react";
import MediumTypography from "../../components/formlib/MediumTypography";
import useAvailableHeight from "../../utils/useAvailableHeight";
import CustomDropdown from "../../components/formlib/CustomDropdown";
import { getOptions, handleError } from "../../utils/commonFunctions";
import { useLocation } from "react-router-dom";
import ButtonComponent from "../../components/formlib/ButtonComponent";
import { ReactComponent as CollpaseLeftIcon } from "../../assets/images/collpaseLeftIcon.svg";
import "./WizardScreen.css";
import SiteDetailsComponent from "./siteDeatilsScreen/SiteDetailsComponent";
import { ReactComponent as Upload } from "../../assets/images/UploadIcon.svg";
import { ReactComponent as Download } from "../../assets/images/DownloadIcon.svg";
import { USER_BASE_URL, WORK_FLOW_BASE_URL } from "../../api/Constant";
import { LoaderContext, LoaderContextType } from "../../layouts/appSidebar";
import ErrorModal from "../../components/formlib/modal/ErrorModal";
import ValidationErrorModal from "../../components/formlib/modal/ValidationErrorModal";
import {
  fetchWizardsMenuMetaDataApi,
  uploadWizardsCSVFile,
  validateWizradsCSVFileApi,
} from "../../api/wizardsApi/Common";
import SubgroupDetailsComponent from "./subgroupDetailScreen/SubgroupDetailsComponent";
import AssetGroupWizards from "./assetGroup/AssetGroupWizards";
import {
  AssetGroupDataType,
  DynamicMenuTypes,
  siteDataTypes,
  SiteDetailsTypes,
  subGroupDataTypes,
  SubgroupDetailsTypes,
} from "./wizrads.types";

import { ReactComponent as DownArrow } from "../../assets/images/DownArrowColoured.svg"; // Small circle icon for items
import { ReactComponent as Subgroup } from "../../assets/images/subgroup.svg";
import { ReactComponent as SubMenu } from "../../assets/images/submenu.svg";
import { ReactComponent as Round } from "../../assets/images/round.svg";
import DropdownComponent from "../../components/formlib/DropdownComponent";
import { LanguageType } from "../MasterDataManagement/types/accountTypes";
import { OptionType } from "../../utils/type";
import ShiftDetailScreen from "./shiftMaster/ShiftDetailScreen";
import UsersList from "./user/usersListScreen";
import { UserType } from "./user/users";
import AssetsList from "./assets/AssetsListScreen";
import { AssetDataType } from "./types/assetTypes";
import HolidayListScreen from "./holidays/HolidayListScreen";
import { HolidayDataType } from "./types/holidayTypes";
import UploadKmlScreen from "./kml/UploadKmlScreen";
import LoadDistributionScreen from "./loadDistribution/LoadDistributionScreen";
// import LoadDistributionScreen from "./loadDistribution/LoadDistributionScreen";
// import Test from "./kml/Test";

export type MasterWizardsType = AssetDataType | UserType | HolidayDataType;

export interface WizardsDetailsType {
  selelctedSiteDetails: SiteDetailsTypes;
  siteOnChange: (val: SiteDetailsTypes) => void;
  selelctedSubgroupDetails: SubgroupDetailsTypes;
  subgroupOnChange: (val: SubgroupDetailsTypes) => void;
}

export const WizardsContext = createContext<WizardsDetailsType | undefined>(
  undefined
);

const WizardParentComponent = () => {
  const inputFileRef = useRef<HTMLInputElement>(null);
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState(true); // Initially expanded
  const selectedAccountName = useRef<string>(
    localStorage.getItem("AccId") ?? ""
  );
  const [selectedAccName, setSelectedAccName] = useState<string>("");

  const [selectedSite, setSelectedSite] = useState<string>("");
  const [openSubGroup, setOpenSubGroup] = useState<number | null>(null);
  const [openAssetGroup, setOpenAssetGroup] = useState<number | null>(null);
  const [openAssets, setOpenAssets] = useState<boolean>(false);
  const [menuData, setMenuData] = useState<DynamicMenuTypes | null>(null);
  const menuHeight = useAvailableHeight(500);
  const [selectedSubGroupId, setSelectedSubGroupId] = useState<number>(0);
  const [selectedAssetGroupId, setSelectedAssetGroupId] = useState<number>(0);
  const [subGroupName, setSubGroupName] = useState<string>("");
  const [siteName, setSiteName] = useState<string>("");
  const [assetGroupName, setAssetGroupName] = useState<string>("");
  const availableHeightForMenu = useAvailableHeight(250);
  const availableHeightForContainer = useAvailableHeight(210);
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  const [selectedComponent, setSelectedComponent] = useState<string>("site");
  const [exportBeanList, setExportBeanList] = useState<any>([]);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [validationSuccess, setValidationSuccess] = useState<string>("");
  const [openValidationSuccessModal, setOpenValidationSuccessModal] =
    useState<boolean>(false);
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [openValidationErrorModal, setOpenValidationErrorModal] =
    useState<boolean>(false);
  const [errorDesc, setErrorDesc] = useState<string>("");
  const [files, setFiles] = useState<File | null>(null);
  const storedLanguageCode = localStorage.getItem("language") ?? "en";
  const [selectedLanguageCode, setSelectedLanguageCode] =
    useState(storedLanguageCode);
  const [selectedLanguageId, setSelectedLanguageId] = useState<number | null>(
    null
  );
  const [languageChanged, setLanguageChanged] = useState(false);
  const [selectedSiteData, setSelectedSiteData] =
    useState<siteDataTypes | null>(null);

  const [selelctedSiteDetails, setSelectedSiteDetails] =
    useState<SiteDetailsTypes>({} as SiteDetailsTypes);
  const [selelctedSubgroupDetails, setSelectedSubgroupDetails] =
    useState<SubgroupDetailsTypes>({} as SubgroupDetailsTypes);
  const [isSubgroupFirst, setIsSubgroupFirst] = useState<boolean>(false);
  const [isAssetGroupFirst, setIsAssetgroupFirst] = useState<boolean>(false);
  const [currentLevel, setCurrentLevel] = useState("site"); // Default to "site"
  const isFirstLoad = useRef(true);
  const [activeFilters, setActiveFilters] = useState<{ [key: string]: string }>(
    {}
  );

  const [selectedAssetCategoryId, setSelectedAssetCategoryId] =
    useState<number>(0);
  const [assetCategoryName, setAssetCategoryName] = useState<string>("");
  const [addButtonClick, setAddButtonClick] = useState(false);
  const [listData, setListData] = useState<MasterWizardsType | null>(null);

  const toggleSubgroup = (id: number, name: string) => {
    setSubGroupName(name);
    setOpenSubGroup((prev) => (prev === id ? null : id));

    setSelectedSubGroupId(id);
    setSelectedAssetGroupId(0);
    setSelectedAssetCategoryId(0);
    setOpenAssets(false);
    setOpenAssetGroup(null);
    setSelectedComponent("subgroup");
    setActiveFilters({});
  };

  const toggleAssetGroup = (id: number, name: string) => {
    setOpenAssetGroup((prev) => (prev === id ? null : id));
    setSelectedAssetGroupId(id);
    setAssetGroupName(name);
    setSelectedAssetCategoryId(0);
    setOpenAssets(false);
    setSelectedComponent("assetGroup");
    setActiveFilters({});
  };

  const toggleShifts = (id: number) => {
    setSelectedSubGroupId(id);
    setSelectedComponent("shifts");
  };

  const toggleKml = (id: number) => {
    setOpenAssetGroup(id);
    setSelectedAssetGroupId(id);
    setSelectedComponent("kml");
  };

  const toggleLoadDistribution = (id: number) => {
    setSelectedSubGroupId(id);
    setSelectedComponent("loadDistribution");
  };
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleSiteChange = (siteName: string) => {
    setSelectedSite(siteName);
    setSiteName(siteName);
  };

  useEffect(() => {
    setActiveFilters({});
  }, [selectedLanguageCode, location?.state]);

  // Find the selected site based on the name
  useEffect(() => {
    if (menuData && selectedSite) {
      const siteData = menuData?.wizSiteList?.find(
        (site) => site.id.toString() === selectedSite
      );
      if (siteData) {
        setSelectedSiteData(siteData);
        setSiteName(siteData.name);
      } else {
        setSelectedSiteData(null);
      }
      setActiveFilters({});
    }
  }, [menuData, selectedSite, selectedLanguageCode]);

  useEffect(() => {
    if (selectedSubGroupId && selectedSiteData) {
      const subgroups = selectedSiteData.subgroups.find(
        (sub) => sub.id === selectedSubGroupId
      );

      if (subgroups) {
        setSubGroupName(subgroups.name);
      }
    }
  }, [selectedSubGroupId, selectedSiteData, selectedLanguageCode]);

  useEffect(() => {
    if (selectedSubGroupId && selectedSiteData && selectedAssetGroupId) {
      const subgroups = selectedSiteData.subgroups.find(
        (sub) => sub.id === selectedSubGroupId
      );

      const assetGroups = subgroups?.assetGroups.find(
        (asset) => asset.id === selectedAssetGroupId
      );
      if (assetGroups) {
        setAssetGroupName(assetGroups.name);
      }
    }
  }, [
    selectedSubGroupId,
    selectedSiteData,
    selectedAssetGroupId,
    selectedLanguageCode,
  ]);

  useEffect(() => {
    if (!languageChanged) {
      fetchData(storedLanguageCode);
    } else {
      fetchData(selectedLanguageCode);
    }
  }, [languageChanged, selectedLanguageCode]);

  const componentToUrlMap: { [key: string]: string } = {
    assets: `${WORK_FLOW_BASE_URL}${selectedSubGroupId}/assets`,
    users: `${USER_BASE_URL}user`,
    holidays: `${WORK_FLOW_BASE_URL}${selectedSubGroupId}/holiday`,
    // Add more mappings as needed
  };
  const getNameFromComponent = (component: string | null): string => {
    if (component && componentToUrlMap[component]) {
      return componentToUrlMap[component];
    }
    throw new Error("Unknown component");
  };

  useEffect(() => {
    const currentPageKey = location?.state?.pageKey;

    if (currentPageKey) {
      fetchData(selectedLanguageCode);
    }
  }, [location?.state]);

  const handleData = (data: MasterWizardsType) => {
    setListData(data);
  };

  const browseFiles = () => {
    inputFileRef.current?.click();
  };

  const handleFilter = (filteredData: { [key: string]: string }) => {
    setActiveFilters(filteredData);
  };

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    toggleLoader(true);

    const selectedFile = getSelectedFile(event);
    setFiles(selectedFile);
    if (!selectedFile) {
      handleInvalidFile("CsvValidate");
      return;
    }

    if (!isValidCSVFile(selectedFile)) {
      handleInvalidFile("CsvValidate");
      return;
    }

    try {
      const name =
        selectedComponent === "assets" ||
        selectedComponent === "holidays" ||
        selectedComponent === "users"
          ? `${getNameFromComponent(selectedComponent)}`
          : `${WORK_FLOW_BASE_URL}${selectedComponent}`;

      const response = await validateWizradsCSVFileApi(
        selectedFile,
        selectedLanguageCode,
        name
      );
      await handleValidationResponse(response);
    } catch (err) {
      handleError(err, setErrorDesc);
      setOpenErrorModal(true);
      toggleLoader(false);
    } finally {
      event.target.value = "";
    }
  };

  const getSelectedFile = (event: ChangeEvent<HTMLInputElement>) => {
    return event.target.files && event.target.files.length > 0
      ? event.target.files[0]
      : null;
  };

  const isValidCSVFile = (file: File) => {
    return (
      (file.type === "application/csv" || file.type === "text/csv") &&
      file.size <= 102400 &&
      file.size !== 0
    );
  };

  const handleInvalidFile = (errorText: string) => {
    toggleLoader(false);
  };

  const handleValidationResponse = async (response: any) => {
    toggleLoader(false);
    if (response.errorMessages.length > 0) {
      setValidationErrors(response.errorMessages);
      setOpenValidationErrorModal(true);
      return;
    } else {
      setValidationSuccess("SuccessMessageForUpload");
      setOpenValidationSuccessModal(true);
      setExportBeanList(response.exportBeanList);
    }
  };

  const uploadFile = async (file: File, isVersionRequired: boolean) => {
    setOpenValidationSuccessModal(false);
    try {
      const name =
        selectedComponent === "assets" ||
        selectedComponent === "holidays" ||
        selectedComponent === "users"
          ? `${getNameFromComponent(selectedComponent)}`
          : `${WORK_FLOW_BASE_URL}${selectedComponent}`;
      await uploadWizardsCSVFile(
        file.name,
        selectedLanguageCode,
        exportBeanList,
        name,
        isVersionRequired
      );
      toggleLoader(false);
    } catch (err) {
      handleError(err, setErrorDesc);
      setOpenErrorModal(true);
      toggleLoader(false);
    }
  };

  const handleDownload = async () => {
    const nonEmptyFilters = Object.entries(activeFilters).filter(
      ([, value]) => value.trim() !== ""
    );
    const filterQuery = nonEmptyFilters
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join("&");
    const queryString = filterQuery
      ? `?${filterQuery}&languageCode=${selectedLanguageCode}`
      : `?languageCode=${selectedLanguageCode}`;

    try {
      const downloadUrl =
        selectedComponent === "assets" ||
        selectedComponent === "holidays" ||
        selectedComponent === "users"
          ? `${getNameFromComponent(selectedComponent)}/export${queryString}`
          : `${WORK_FLOW_BASE_URL}${selectedComponent}/export${queryString}`;
      const response = await fetch(downloadUrl, {
        headers: {
          Authorization: `Bearer ${localStorage?.getItem("accessToken")}`,
          userId: String(localStorage.getItem("id")),
          "Accept-Language": localStorage.getItem("language") ?? "en",
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      // Create a timestamp
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are 0-based
      const day = String(now.getDate()).padStart(2, "0");
      const hours = String(now.getHours()).padStart(2, "0");
      const minutes = String(now.getMinutes()).padStart(2, "0");
      const timestamp = `${year}-${month}-${day} ${hours}-${minutes}`;
      const FormattedName =
        selectedComponent.charAt(0).toUpperCase() + selectedComponent.slice(1);
      const filename = `${FormattedName} Details ${timestamp}.csv`; // e.g., "Account_Details_20240812_123456.csv"

      const a = document.createElement("a");
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
      setActiveFilters({});

      return { success: true };
    } catch (error) {
      setOpenErrorModal(true);
      handleError(error, setErrorDesc);
    }
  };

  const importButtonClick = () => {
    if (files) {
      uploadFile(files, false);
    }
  };

  const importWithVersionClick = () => {
    if (files) {
      uploadFile(files, true);
    }
  };

  const fetchData = async (languageCode: string) => {
    setSelectedAccName(location.state?.selectedAccount || "");

    const fetchAssetGroupUrl = `${WORK_FLOW_BASE_URL}wizard/${selectedAccountName.current}`;
    toggleLoader(true);
    fetchWizardsMenuMetaDataApi<DynamicMenuTypes>(
      fetchAssetGroupUrl,
      languageCode
    )
      .then(async (response: DynamicMenuTypes) => {
        setMenuData(response);

        const preferredLanguage = response.languages.find(
          (lang) => lang.languageCode === storedLanguageCode
        );
        if (preferredLanguage && !languageChanged) {
          setSelectedLanguageId(preferredLanguage.id);
          setSelectedLanguageCode(preferredLanguage.languageCode);
        }

        if (response.wizSiteList.length === 0) {
          setSelectedSite(
            response.wizSiteList.length > 0
              ? response.wizSiteList[0].id.toString()
              : ""
          );
          setSiteName(
            response.wizSiteList.length > 0 ? response.wizSiteList[0].name : ""
          );
          setSelectedComponent("site");
        } else {
          const site = response.wizSiteList.find(
            (site) => site.id.toString() === selectedSite.toString()
          );
          setSelectedSite(
            site ? site.id.toString() : response.wizSiteList[0].id.toString()
          );
          setSiteName(
            site ? site.name.toString() : response.wizSiteList[0].name
          );
          setSelectedComponent("site");
        }

        toggleLoader(false);
      })
      .catch((err) => {
        handleError(err, setErrorDesc);
        setOpenErrorModal(true);
        toggleLoader(false);
      });
  };

  const handleSubgroupClick = (subGroupNameText: string) => {
    setSelectedComponent("subgroup");
    setSubGroupName(subGroupNameText);
    setSelectedSubGroupId(0);
    setIsSubgroupFirst(true);
  };

  const handleAssetGroupClick = (name: string) => {
    setSelectedComponent("assetGroup");
    setAssetGroupName(name);
    setSelectedAssetGroupId(0);
    if (name === "Asset Group 1") {
      setIsAssetgroupFirst(true);
    }
  };

  const addNewGroupToSite = (
    groupName: string,
    groupId: number,
    type: "subgroup" | "assetGroup"
  ) => {
    setSubGroupName(groupName);

    const updatedMenuData = JSON.parse(JSON.stringify(menuData));

    const selectedSiteName = updatedMenuData?.wizSiteList.find(
      (site: SiteDetailsTypes) => site?.id?.toString() === selectedSite
    );

    if (selectedSiteName) {
      if (type === "subgroup") {
        const newSubgroup = {
          name: groupName,
          id: groupId,
          usersCount: 0,
          shiftsCount: 0,
          holidayCount: 0,
          eventCount: 0,
          assetGroups: [],
        };
        setSelectedSubGroupId(groupId);
        selectedSiteName.subgroups.push(newSubgroup);
        setSubGroupName(groupName);
      } else if (type === "assetGroup") {
        setAssetGroupName(groupName);
        const targetSubgroup = selectedSiteName.subgroups.find(
          (subgroup: any) => subgroup.id === selectedSubGroupId
        );
        setSelectedSubGroupId(targetSubgroup.id);

        if (targetSubgroup) {
          const newAssetGroup = {
            name: groupName,
            id: groupId,
            assetCategory: [],
          };

          targetSubgroup.assetGroups.push(newAssetGroup);
        }
        setSelectedAssetGroupId(groupId);
      }
    }

    setMenuData(updatedMenuData);
  };

  const [isNextDisabled, setIsNextDisabled] = useState(false);
  const [isPreviousDisabled, setIsPreviousDisabled] = useState(true);

  const handleNext = () => {
    if (isNextDisabled) return; // Prevent navigation if disabled

    const selectFirstIfExists = (items: any[]) => {
      if (items?.length) {
        const firstItem = items[0];
        setSelectedAssetGroupId(firstItem.id);
        setOpenAssetGroup(firstItem.id);
        setAssetGroupName(firstItem.name);
        setSelectedComponent("assetGroup"); // Select the asset group level
        return true;
      }
      return false;
    };

    switch (currentLevel) {
      case "site":
        const firstSubgroup = selectedSiteData?.subgroups?.[0];
        if (firstSubgroup) {
          setSelectedSubGroupId(firstSubgroup.id);
          setOpenSubGroup(firstSubgroup.id);
          setSelectedComponent("subgroup"); // Start with the subgroup detailed view
          setCurrentLevel("subgroup");
          setSubGroupName(firstSubgroup.name);
          setIsPreviousDisabled(false); // Enable "Previous" button
        } else {
          setIsNextDisabled(true); // No subgroups available
        }
        break;

      case "subgroup":
        const selectedSubgroup = selectedSiteData?.subgroups.find(
          (subgroup) => subgroup.id === selectedSubGroupId
        );

        if (selectedSubgroup) {
          if (selectedComponent === "subgroup") {
            // Start navigating subgroup items (users, shifts, holidays, etc.)
            const subgroupItems = [
              { type: "users", count: selectedSubgroup.usersCount },
              { type: "shifts" },
              { type: "holidays", count: selectedSubgroup.holidayCount },
              { type: "events", count: selectedSubgroup.eventCount },
              { type: "loadDistribution" },
            ];

            // Find the first valid item to navigate to
            const nextItem = subgroupItems.find(
              (item) => !item.count || item.count > 0 // Navigate to items with count > 0 or no count defined
            );

            if (nextItem) {
              setSelectedComponent(nextItem.type); // Set the next component
            } else if (selectedSubgroup.assetGroups?.length) {
              // If no subgroup items remain, check for asset groups
              if (selectFirstIfExists(selectedSubgroup.assetGroups)) {
                setCurrentLevel("assetGroup");
              }
            } else {
              // No more subgroup items or asset groups, move to the next subgroup
              const subgroups = selectedSiteData?.subgroups || [];
              const currentSubgroupIndex = subgroups.findIndex(
                (subgroup) => subgroup.id === selectedSubGroupId
              );

              if (
                currentSubgroupIndex >= 0 &&
                currentSubgroupIndex < subgroups.length - 1
              ) {
                // Move to the next subgroup
                const nextSubgroup = subgroups[currentSubgroupIndex + 1];
                setSelectedSubGroupId(nextSubgroup.id);
                setOpenSubGroup(nextSubgroup.id);
                setSubGroupName(nextSubgroup.name);
                setSelectedComponent("subgroup"); // Start at subgroup details
              } else {
                // No more subgroups, disable "Next" button
                setIsNextDisabled(true);
              }
            }
          } else {
            // Navigate through the rest of the subgroup items
            const subgroupItems = [
              { type: "users", count: selectedSubgroup.usersCount },
              { type: "shifts" },
              { type: "holidays", count: selectedSubgroup.holidayCount },
              { type: "events", count: selectedSubgroup.eventCount },
              { type: "loadDistribution" },
            ];

            // Find the index of the current selected component
            const currentIndex = subgroupItems.findIndex(
              (item) => item.type === selectedComponent
            );

            if (currentIndex >= 0 && currentIndex < subgroupItems.length - 1) {
              // Move to the next nested item
              const nextItem = subgroupItems[currentIndex + 1];
              setSelectedComponent(nextItem.type);
            } else if (selectedSubgroup.assetGroups?.length) {
              // After subgroup items, move to asset groups
              if (selectFirstIfExists(selectedSubgroup.assetGroups)) {
                setCurrentLevel("assetGroup");
              }
            } else {
              // No more subgroup items or asset groups, move to the next subgroup
              const subgroups = selectedSiteData?.subgroups || [];
              const currentSubgroupIndex = subgroups.findIndex(
                (subgroup) => subgroup.id === selectedSubGroupId
              );

              if (
                currentSubgroupIndex >= 0 &&
                currentSubgroupIndex < subgroups.length - 1
              ) {
                // Move to the next subgroup
                const nextSubgroup = subgroups[currentSubgroupIndex + 1];
                setSelectedSubGroupId(nextSubgroup.id);
                setOpenSubGroup(nextSubgroup.id);
                setSubGroupName(nextSubgroup.name);
                setSelectedComponent("subgroup"); // Start at subgroup details
              } else {
                // No more subgroups, disable "Next" button
                setIsNextDisabled(true);
              }
            }
          }
        }
        break;

      case "assetGroup":
        const currentSubgroups = selectedSiteData?.subgroups.find(
          (subgroup) => subgroup.id === selectedSubGroupId
        );

        if (currentSubgroups) {
          const currentAssetGroups = currentSubgroups.assetGroups;
          const currentIndex = currentAssetGroups.findIndex(
            (asset) => asset.id === selectedAssetGroupId
          );

          if (currentIndex >= 0) {
            const currentAssetGroup = currentAssetGroups[currentIndex];

            const assetGroupItems = [{ type: "kml" }, { type: "schedule" }];

            const nestedIndex = assetGroupItems.findIndex(
              (item) => item.type === selectedComponent
            );

            if (nestedIndex >= 0 && nestedIndex < assetGroupItems.length - 1) {
              // Navigate nested items inside the asset group
              const nextItem = assetGroupItems[nestedIndex + 1];
              setSelectedComponent(nextItem.type);
            } else if (currentIndex < currentAssetGroups.length - 1) {
              // Move to the next asset group
              const nextAssetGroup = currentAssetGroups[currentIndex + 1];
              setSelectedAssetGroupId(nextAssetGroup.id);
              setOpenAssetGroup(nextAssetGroup.id);
              setSelectedComponent("assetGroup");
              setAssetGroupName(nextAssetGroup.name);
            } else {
              // After asset groups, check if there is another subgroup
              const subgroups = selectedSiteData?.subgroups;
              if (subgroups) {
                const subgroupIndex = subgroups.findIndex(
                  (sub) => sub.id === selectedSubGroupId
                );

                if (
                  subgroupIndex >= 0 &&
                  subgroupIndex < subgroups.length - 1
                ) {
                  const nextSubgroup = subgroups[subgroupIndex + 1];
                  setSelectedSubGroupId(nextSubgroup.id);
                  setOpenSubGroup(nextSubgroup.id);
                  setSubGroupName(nextSubgroup.name);
                  setSelectedComponent("subgroup");
                  setCurrentLevel("subgroup");
                } else {
                  setIsNextDisabled(true); // No more subgroups
                }
              }
            }
          }
        }
        break;

      default:
        setIsNextDisabled(true); // Disable navigation for unsupported levels
        break;
    }
  };

  const handlePrevious = () => {
    if (isPreviousDisabled) return; // Prevent navigation if already disabled

    switch (currentLevel) {
      case "assetGroup":
        const currentSubgroup = selectedSiteData?.subgroups.find(
          (subgroup) => subgroup.id === selectedSubGroupId
        );

        if (currentSubgroup) {
          const currentAssetGroups = currentSubgroup.assetGroups;
          const currentIndex = currentAssetGroups.findIndex(
            (asset) => asset.id === selectedAssetGroupId
          );

          if (currentIndex > 0) {
            const previousAssetGroup = currentAssetGroups[currentIndex - 1];
            setSelectedAssetGroupId(previousAssetGroup.id);
            setOpenAssetGroup(previousAssetGroup.id);
            setAssetGroupName(previousAssetGroup.name);
            setSelectedComponent("assetGroup");
          } else {
            setCurrentLevel("subgroup");
            setSelectedSubGroupId(currentSubgroup.id);
            setOpenSubGroup(currentSubgroup.id);
            setSelectedComponent("subgroup");
            setIsPreviousDisabled(
              currentSubgroup.id === selectedSiteData?.subgroups[0]?.id
            ); // Disable if first subgroup
          }
        }
        break;

      case "subgroup":
        const selectedSubgroup = selectedSiteData?.subgroups.find(
          (subgroup) => subgroup.id === selectedSubGroupId
        );

        if (selectedSubgroup) {
          // Define subgroup items (e.g., users, shifts, etc.)
          const subgroupItems = [
            { type: "users", count: selectedSubgroup.usersCount },
            { type: "shifts" },
            { type: "holidays", count: selectedSubgroup.holidayCount },
            { type: "events", count: selectedSubgroup.eventCount },
            { type: "loadDistribution" },
          ];

          // Find the index of the current selected component
          const currentIndex = subgroupItems.findIndex(
            (item) => item.type === selectedComponent
          );

          if (currentIndex > 0) {
            // Navigate to the previous nested item
            const previousItem = subgroupItems[currentIndex - 1];
            setSelectedComponent(previousItem.type);
          } else {
            // If no previous nested item, move to the previous subgroup (if exists)
            const subgroups = selectedSiteData?.subgroups || [];
            const currentSubgroupIndex = subgroups.findIndex(
              (subgroup) => subgroup.id === selectedSubGroupId
            );

            if (currentSubgroupIndex > 0) {
              // Navigate to the previous subgroup
              const previousSubgroup = subgroups[currentSubgroupIndex - 1];
              setSelectedSubGroupId(previousSubgroup.id);
              setOpenSubGroup(previousSubgroup.id);
              setSubGroupName(previousSubgroup.name);
              setSelectedComponent("subgroup");
            } else {
              // Disable "Previous" if at the first subgroup
              setIsPreviousDisabled(true);
            }
          }
        }
        break;

      case "site":
        setIsPreviousDisabled(true); // Already at the site level
        break;

      default:
        break;
    }
  };

  const siteOnChange = (data: SiteDetailsTypes) => {
    setSelectedSiteDetails(data);
  };

  const subgroupOnChange = (data: SubgroupDetailsTypes) => {
    setSelectedSubgroupDetails(data);
  };
  const convertToOptionType = (languages: LanguageType[]): OptionType[] =>
    languages
      .filter((language) => language.implemented)
      .map((language) => ({
        id: language.id.toString(),
        label: language.language,
      }));

  const languageMap = (menuData?.languages ?? []).reduce((acc, language) => {
    acc[language.id] = language.languageCode;
    return acc;
  }, {} as { [key: number]: string });

  return (
    <WizardsContext.Provider
      value={{
        selelctedSiteDetails,
        siteOnChange,
        selelctedSubgroupDetails,
        subgroupOnChange,
      }}
    >
      <Box component={"main"} sx={{ padding: "24px 12px 0px 12px" }}>
        {openErrorModal && (
          <ErrorModal
            descriptionText={errorDesc}
            open={openErrorModal}
            handleClose={() => setOpenErrorModal(false)}
            onPositiveClick={() => {
              setOpenErrorModal(false);
            }}
          />
        )}
        {openValidationErrorModal && (
          <ValidationErrorModal
            descriptionTexts={validationErrors}
            open={openValidationErrorModal}
            handleClose={() => setOpenValidationErrorModal(false)}
            onOkClick={() => {
              setOpenValidationErrorModal(false);
            }}
            selectedComponent={selectedComponent}
          />
        )}
        {openValidationSuccessModal && (
          <ValidationErrorModal
            descriptionSuccess={validationSuccess}
            open={openValidationSuccessModal}
            handleClose={() => setOpenValidationSuccessModal(false)}
            onCancelClick={() => {
              setOpenValidationSuccessModal(false);
            }}
            onImportClick={importButtonClick}
            onImportWithVersionClick={importWithVersionClick}
            isValid={true}
            languageCode={"en"}
            selectedComponent={selectedComponent}
          />
        )}

        <Box component={"section"}>
          <Box
            component={"div"}
            className="flex__"
            sx={{ justifyContent: "flex-end" }}
          >
            <>
              <Box
                className="flex__ cursor__pointer"
                sx={{
                  padding: "8px 12px",
                  borderRadius: "4px",
                  border: "1px solid rgba(166, 197, 226, 0.16)",
                  marginRight: "8px",
                  backgroundColor: "#2B3033",
                }}
                onClick={() => browseFiles()}
              >
                <input
                  ref={inputFileRef}
                  type="file"
                  accept=".csv"
                  style={{ display: "none", height: "36px" }}
                  onChange={handleFileChange}
                />
                <Upload />
                <MediumTypography
                  labelId="Upload.text"
                  defaultLabel="Upload"
                  className="ml-xs"
                />
              </Box>
              <Box
                className="flex__ cursor__pointer"
                sx={{
                  padding: "8px 12px",
                  borderRadius: "4px",
                  border: "1px solid rgba(166, 197, 226, 0.16)",
                  marginRight: "8px",
                  backgroundColor: "#2B3033",
                }}
                onClick={() => handleDownload()}
              >
                <Download style={{ marginTop: "4px" }} />
                <MediumTypography
                  labelId="Download.text"
                  defaultLabel="Download"
                  className="ml-xs"
                />
              </Box>
            </>

            {selectedComponent !== "holidays" && (
              <DropdownComponent
                names={convertToOptionType(menuData?.languages ?? [])}
                value={selectedLanguageId?.toString() || ""}
                handleChange={(value: string) => {
                  const newId = parseInt(value);
                  setSelectedLanguageId(newId);

                  setSelectedLanguageCode(languageMap[newId]);
                  setLanguageChanged(true);
                }}
                minWidth="150px"
                sxProps={{ height: "40px" }}
                iconRequired={true}
                disabled={
                  listData && listData.isEnRecordsExist === false ? true : false
                }
              />
            )}
          </Box>
          {/* Custom Drawer-like Sidebar */}
          <Box
            sx={{
              height: availableHeightForContainer,
            }}
            className="master-data-container flex__ position__relative mainBox"
          >
            {/* Left Menu Section */}
            <Box
              sx={{
                height: availableHeightForMenu,
                //width: menuOpen ? "600px" : "5px", // Collapsible effect
                minWidth: menuOpen ? "370px" : "5px",
                padding: menuOpen ? "0px" : "16px",
                "&::-webkit-scrollbar": { display: "none" },
              }}
              className="flex__ position__relative left-menu_section"
            >
              {menuOpen && (
                <Box>
                  <Box>
                    <MediumTypography
                      defaultLabel="Select Account"
                      labelId="selectAccount"
                      className="input-label"
                      fontSize="12px"
                      fontWeight={600}
                    />
                    <CustomDropdown
                      disableCreate={true}
                      sxStyles={{
                        textField: {
                          padding: "0.5px 4px 0px 5px",
                        },
                      }}
                      options={getOptions(menuData?.accountList ?? [])}
                      selectedValue={selectedAccountName.current}
                      onSelectedValueChange={(value) => {
                        setSelectedAccName(value as string);
                        selectedAccountName.current = value as string;
                        fetchData(selectedLanguageCode);
                      }}
                    />
                  </Box>
                  <Box className="mt-md">
                    <MediumTypography
                      defaultLabel="Select Site"
                      labelId="selectSite"
                      className="input-label"
                      fontSize="12px"
                      fontWeight={600}
                    />
                    <CustomDropdown
                      disableCreate={true}
                      options={getOptions(menuData?.wizSiteList || [])}
                      selectedValue={selectedSite}
                      sxStyles={{
                        textField: {
                          padding: "0.5px 4px 0px 5px",
                        },
                      }}
                      onSelectedValueChange={(value) => {
                        handleSiteChange(value as string);
                        setSelectedSite(value as string);
                        setSiteName(value as string);
                        setSelectedComponent("site");
                        setSelectedSubGroupId(0);
                        setSelectedAssetGroupId(0);
                      }}
                    />
                  </Box>

                  <Box
                    mt={2}
                    sx={{
                      height: menuHeight,
                      overflow: "auto",
                      "&::-webkit-scrollbar": { display: "none" },
                    }}
                  >
                    <List>
                      <MediumTypography
                        defaultLabel="Subgroups"
                        labelId="Subgroup.HeaderText"
                        className="input-label"
                        fontSize="12px"
                        fontWeight={600}
                        textColor="White"
                      />
                      {menuData?.wizSiteList?.length !== 0 &&
                      selectedSiteData?.subgroups?.length
                        ? selectedSiteData?.subgroups
                            .filter((subgroup) => subgroup.name)
                            .map((subgroup) => (
                              <Box key={subgroup.id}>
                                <ListItem
                                  button
                                  onClick={() => {
                                    toggleSubgroup(subgroup.id, subgroup.name);
                                  }}
                                >
                                  {openSubGroup === subgroup.id ? (
                                    <DownArrow width={30} height={30} />
                                  ) : (
                                    <DownArrow
                                      width={30}
                                      height={30}
                                      className="rotated-270"
                                    />
                                  )}
                                  <ListItemIcon sx={{ minWidth: "20px" }}>
                                    <Subgroup
                                      className={
                                        subgroup.id === selectedSubGroupId
                                          ? "active"
                                          : "inactive"
                                      }
                                    />
                                  </ListItemIcon>
                                  <Tooltip
                                    title={subgroup.name}
                                    followCursor
                                    arrow
                                  >
                                    <Box>
                                      <MediumTypography
                                        label={subgroup.name}
                                        sxProps={{
                                          width: "250px",
                                          opacity:
                                            subgroup.id === selectedSubGroupId
                                              ? 1
                                              : 0.48,
                                        }}
                                        className="ellipsis"
                                      />
                                    </Box>
                                  </Tooltip>
                                </ListItem>

                                <Collapse
                                  in={openSubGroup === subgroup.id}
                                  timeout="auto"
                                  unmountOnExit
                                >
                                  <List
                                    component="div"
                                    disablePadding
                                    sx={{
                                      borderLeft: "1px solid #5C7E9F",
                                      ml: 4,
                                    }}
                                  >
                                    <ListItem
                                      button
                                      onClick={() => {
                                        setSelectedComponent("users");
                                        setOpenAssetGroup(null);
                                        setSelectedAssetGroupId(0);
                                      }}
                                    >
                                      <ListItemIcon sx={{ minWidth: "20px" }}>
                                        <SubMenu
                                          className={
                                            subgroup.id ===
                                              selectedSubGroupId &&
                                            selectedComponent === "users"
                                              ? "active"
                                              : "inactive"
                                          }
                                        />
                                      </ListItemIcon>

                                      <Box className="flex__">
                                        <MediumTypography
                                          labelId="usersCount"
                                          defaultLabel="Users"
                                          sxProps={{
                                            opacity:
                                              subgroup.id ===
                                                selectedSubGroupId &&
                                              selectedComponent === "users"
                                                ? 1
                                                : "0.48",
                                          }}
                                        />
                                        <MediumTypography
                                          label={`(${
                                            subgroup.usersCount ?? 0
                                          })`}
                                          sxProps={{
                                            opacity:
                                              subgroup.id ===
                                                selectedSubGroupId &&
                                              selectedComponent === "users"
                                                ? "1"
                                                : "0.48",
                                          }}
                                        />
                                      </Box>
                                    </ListItem>
                                    <ListItem
                                      button
                                      onClick={() => toggleShifts(subgroup.id)}
                                    >
                                      <ListItemIcon sx={{ minWidth: "20px" }}>
                                        <SubMenu
                                          className={
                                            subgroup.id ===
                                              selectedSubGroupId &&
                                            selectedComponent === "shifts"
                                              ? "active"
                                              : "inactive"
                                          }
                                        />
                                      </ListItemIcon>
                                      <Box className="flex__">
                                        <MediumTypography
                                          labelId="shiftsCount"
                                          defaultLabel="Shifts"
                                          className="cursor__pointer"
                                          sxProps={{
                                            opacity:
                                              subgroup.id ===
                                                selectedSubGroupId &&
                                              selectedComponent === "shifts"
                                                ? 1
                                                : "0.48",
                                          }}
                                        />
                                      </Box>
                                    </ListItem>
                                    <ListItem
                                      button
                                      onClick={() => {
                                        setSelectedComponent("holidays");
                                        setOpenAssetGroup(null);
                                        setSelectedAssetGroupId(0);
                                      }}
                                    >
                                      <ListItemIcon sx={{ minWidth: "20px" }}>
                                        <SubMenu
                                          className={
                                            subgroup.id ===
                                              selectedSubGroupId &&
                                            selectedComponent === "holidays"
                                              ? "active"
                                              : "inactive"
                                          }
                                        />
                                      </ListItemIcon>
                                      <Box className="flex__">
                                        <MediumTypography
                                          labelId="holidaysCount"
                                          defaultLabel="Holidays"
                                          sxProps={{
                                            opacity:
                                              subgroup.id ===
                                                selectedSubGroupId &&
                                              selectedComponent === "holidays"
                                                ? 1
                                                : "0.48",
                                          }}
                                        />
                                        <MediumTypography
                                          label={`(${
                                            subgroup.holidayCount ?? 0
                                          })`}
                                          sxProps={{
                                            opacity:
                                              subgroup.id ===
                                                selectedSubGroupId &&
                                              selectedComponent === "holidays"
                                                ? 1
                                                : "0.48",
                                          }}
                                        />
                                      </Box>
                                    </ListItem>
                                    <ListItem>
                                      <ListItemIcon sx={{ minWidth: "20px" }}>
                                        <SubMenu />
                                      </ListItemIcon>
                                      <Box className="flex__">
                                        <MediumTypography
                                          labelId="eventsText"
                                          defaultLabel="Events"
                                          sxProps={{ opacity: "0.48" }}
                                        />
                                        <MediumTypography
                                          label={`(${
                                            subgroup.eventCount ?? 0
                                          })`}
                                          sxProps={{ opacity: "0.48" }}
                                        />
                                      </Box>
                                    </ListItem>
                                    <ListItem
                                      button
                                      onClick={() =>
                                        toggleLoadDistribution(subgroup.id)
                                      }
                                    >
                                      <ListItemIcon sx={{ minWidth: "20px" }}>
                                        <SubMenu
                                          className={
                                            subgroup.id ===
                                              selectedSubGroupId &&
                                            selectedComponent ===
                                              "loadDistribution"
                                              ? "active"
                                              : "inactive"
                                          }
                                        />
                                      </ListItemIcon>
                                      <Box className="flex__">
                                        <MediumTypography
                                          labelId="loadText"
                                          defaultLabel="Load Distribution"
                                          sxProps={{
                                            opacity:
                                              subgroup.id ===
                                                selectedSubGroupId &&
                                              selectedComponent ===
                                                "loadDistribution"
                                                ? 1
                                                : "0.48",
                                          }}
                                        />
                                      </Box>
                                    </ListItem>
                                    <ListItem>
                                      <ListItemIcon sx={{ minWidth: "20px" }}>
                                        <SubMenu />
                                      </ListItemIcon>

                                      <Box className="flex__">
                                        <MediumTypography
                                          labelId="vendorsText"
                                          defaultLabel="Vendor & Services"
                                          sxProps={{ opacity: "0.48" }}
                                        />
                                      </Box>
                                    </ListItem>
                                    {subgroup.id &&
                                    subgroup.assetGroups &&
                                    subgroup?.assetGroups?.length > 0 ? (
                                      subgroup.assetGroups.map((assetGroup) => (
                                        <Box key={assetGroup.id}>
                                          <ListItem
                                            button
                                            onClick={() =>
                                              toggleAssetGroup(
                                                assetGroup.id,
                                                assetGroup.name
                                              )
                                            }
                                          >
                                            {openAssetGroup ===
                                            assetGroup.id ? (
                                              <DownArrow
                                                width={30}
                                                height={30}
                                              />
                                            ) : (
                                              <DownArrow
                                                width={30}
                                                height={30}
                                                className="rotated-270"
                                              />
                                            )}
                                            <ListItemIcon
                                              sx={{ minWidth: "20px" }}
                                            >
                                              <SubMenu
                                                className={
                                                  assetGroup.id ===
                                                  selectedAssetGroupId
                                                    ? "active"
                                                    : "inactive"
                                                }
                                              />
                                            </ListItemIcon>
                                            <Tooltip
                                              title={assetGroup.name}
                                              followCursor
                                              arrow
                                            >
                                              <Box>
                                                <MediumTypography
                                                  label={assetGroup.name}
                                                  sxProps={{
                                                    width: "250px",
                                                    opacity:
                                                      assetGroup.id ===
                                                      selectedAssetGroupId
                                                        ? 1
                                                        : 0.48,
                                                  }}
                                                  className="ellipsis"
                                                />
                                              </Box>
                                            </Tooltip>
                                          </ListItem>

                                          <Collapse
                                            in={
                                              openAssetGroup === assetGroup.id
                                            }
                                            timeout="auto"
                                            unmountOnExit
                                          >
                                            <List
                                              component="div"
                                              disablePadding
                                              sx={{
                                                borderLeft: "1px solid #5C7E9F",
                                                ml: 4,
                                              }}
                                            >
                                              <ListItem
                                                button
                                                onClick={() =>
                                                  toggleKml(assetGroup.id)
                                                }
                                              >
                                                <ListItemIcon
                                                  sx={{ minWidth: "20px" }}
                                                >
                                                  <Round
                                                    className={
                                                      openAssetGroup ===
                                                        assetGroup.id &&
                                                      selectedComponent ===
                                                        "kml"
                                                        ? "active"
                                                        : "inactive"
                                                    }
                                                  />
                                                </ListItemIcon>

                                                <Box className="flex__">
                                                  <MediumTypography
                                                    labelId="uploadKmlText"
                                                    defaultLabel="Upload KML"
                                                    // sxProps={{
                                                    //   opacity: "0.48",
                                                    // }}
                                                    sxProps={{
                                                      opacity:
                                                        openAssetGroup ===
                                                          assetGroup.id &&
                                                        selectedComponent ===
                                                          "kml"
                                                          ? 1
                                                          : "0.48",
                                                    }}
                                                  />
                                                </Box>
                                              </ListItem>
                                              <ListItem>
                                                <ListItemIcon
                                                  sx={{ minWidth: "20px" }}
                                                >
                                                  <Round />
                                                </ListItemIcon>
                                                <Box className="flex__">
                                                  <MediumTypography
                                                    labelId="scheduleText"
                                                    defaultLabel="Schedule"
                                                    sxProps={{
                                                      opacity: "0.48",
                                                    }}
                                                  />
                                                </Box>
                                              </ListItem>
                                              <ListItem>
                                                <ListItemIcon
                                                  sx={{ minWidth: "20px" }}
                                                >
                                                  <Round />
                                                </ListItemIcon>
                                                <Box className="flex__">
                                                  <MediumTypography
                                                    labelId="workorderMaster"
                                                    defaultLabel="Workorder Master"
                                                    sxProps={{
                                                      opacity: "0.48",
                                                    }}
                                                  />
                                                </Box>
                                              </ListItem>
                                              <ListItem
                                                button
                                                onClick={() =>
                                                  setOpenAssets(!openAssets)
                                                }
                                              >
                                                {assetGroup.assetCategory
                                                  ?.length > 0 && (
                                                  <>
                                                    {openAssets ? (
                                                      <DownArrow
                                                        width={30}
                                                        height={30}
                                                      />
                                                    ) : (
                                                      <DownArrow
                                                        width={30}
                                                        height={30}
                                                        className="rotated-270"
                                                      />
                                                    )}
                                                  </>
                                                )}

                                                <ListItemIcon
                                                  sx={{ minWidth: "20px" }}
                                                >
                                                  <SubMenu
                                                    className={
                                                      openAssets
                                                        ? "active"
                                                        : "inactive"
                                                    }
                                                  />
                                                </ListItemIcon>
                                                <Box>
                                                  <MediumTypography
                                                    labelId={"assetsText"}
                                                    defaultLabel="Assets"
                                                    sxProps={{
                                                      opacity: openAssets
                                                        ? "1"
                                                        : "0.48",
                                                    }}
                                                  />
                                                </Box>
                                              </ListItem>

                                              <Box>
                                                <Collapse
                                                  in={openAssets}
                                                  timeout="auto"
                                                  unmountOnExit
                                                >
                                                  {assetGroup?.assetCategory?.map(
                                                    (assetCat) => (
                                                      <List
                                                        component="div"
                                                        disablePadding
                                                        sx={{
                                                          borderLeft:
                                                            "1px solid #5C7E9F",
                                                          ml: 4,
                                                        }}
                                                      >
                                                        <ListItem
                                                          button
                                                          onClick={() => {
                                                            setSelectedComponent(
                                                              "assets"
                                                            );
                                                            setSelectedAssetCategoryId(
                                                              assetCat.id
                                                            );
                                                            setAssetCategoryName(
                                                              assetCat.name
                                                            );
                                                            setActiveFilters(
                                                              {}
                                                            );
                                                            setAddButtonClick(
                                                              false
                                                            );
                                                          }}
                                                        >
                                                          <ListItemIcon
                                                            sx={{
                                                              minWidth: "20px",
                                                            }}
                                                          >
                                                            <Round
                                                              className={
                                                                assetCat.id ===
                                                                selectedAssetCategoryId
                                                                  ? "active"
                                                                  : "inactive"
                                                              }
                                                            />
                                                          </ListItemIcon>
                                                          <Tooltip
                                                            title={`${
                                                              assetCat.name
                                                            }(${
                                                              assetCat.count ??
                                                              0
                                                            })`}
                                                            followCursor
                                                            arrow
                                                          >
                                                            <Box className="flex__">
                                                              <MediumTypography
                                                                label={`${
                                                                  assetCat.name
                                                                }(${
                                                                  assetCat.count ??
                                                                  0
                                                                })`}
                                                                sxProps={{
                                                                  opacity:
                                                                    assetCat.id ===
                                                                    selectedAssetCategoryId
                                                                      ? "1"
                                                                      : "0.48",
                                                                  width:
                                                                    "200px",
                                                                }}
                                                                className="ellipsis"
                                                              />
                                                            </Box>
                                                          </Tooltip>
                                                        </ListItem>
                                                      </List>
                                                    )
                                                  )}
                                                </Collapse>
                                              </Box>
                                            </List>
                                          </Collapse>
                                        </Box>
                                      ))
                                    ) : (
                                      <ListItem
                                        button
                                        onClick={() =>
                                          handleAssetGroupClick("Asset Group 1")
                                        }
                                      >
                                        <ListItemIcon sx={{ minWidth: "20px" }}>
                                          <SubMenu
                                            className={
                                              selectedComponent === "assetGroup"
                                                ? "active"
                                                : "inactive"
                                            }
                                          />
                                        </ListItemIcon>
                                        <Box>
                                          <MediumTypography
                                            labelId="AssetGroupText"
                                            defaultLabel="Asset Group 1"
                                            sxProps={{
                                              opacity:
                                                selectedComponent ===
                                                "assetGroup"
                                                  ? 1
                                                  : 0.48,
                                            }}
                                          />
                                        </Box>
                                      </ListItem>
                                    )}
                                  </List>
                                </Collapse>
                              </Box>
                            ))
                        : selectedSite !== "" &&
                          selectedSite !== null &&
                          selectedSite !== undefined && (
                            <ListItem
                              button
                              onClick={() => {
                                handleSubgroupClick("Subgroup 1");
                              }}
                            >
                              <ListItemIcon sx={{ minWidth: "20px" }}>
                                <Subgroup
                                  className={
                                    selectedComponent === "subgroup"
                                      ? "active"
                                      : "inactive"
                                  }
                                />
                              </ListItemIcon>
                              <Box>
                                <MediumTypography
                                  labelId="subGroupText"
                                  defaultLabel="Subgroup 1"
                                  sxProps={{
                                    opacity:
                                      selectedComponent === "assetGroup"
                                        ? 1
                                        : 0.48,
                                  }}
                                />
                              </Box>
                            </ListItem>
                          )}
                    </List>
                  </Box>
                </Box>
              )}

              {/* Collapse Button at the bottom of the Menu */}
              {!menuOpen && (
                <Box className="collapse-button flex__">
                  <ButtonComponent
                    className="CollapseClickButton flex__"
                    LeftIcon={
                      <CollpaseLeftIcon
                        style={{ transform: "rotate(180deg)" }}
                      />
                    } // Ensure you have the correct icon here
                    onClick={toggleMenu}
                  />
                </Box>
              )}

              {menuOpen && (
                <Box className="button-box">
                  <ButtonComponent
                    className="empty-button"
                    LeftIcon={<CollpaseLeftIcon />}
                    label="Collapse"
                    onClick={toggleMenu}
                  />
                </Box>
              )}

              {/* Vertical Open Button when Menu is Collapsed */}
              {!menuOpen && (
                <MediumTypography
                  label="EXPAND NAVIGATION"
                  className="expand-naviationCss"
                />
              )}
            </Box>

            {/* Divider */}
            <Box
              className="divider-boxCss"
              sx={{
                height: availableHeightForMenu, // Maintain the height of the menu section
              }}
            ></Box>

            {/* Content */}
            <Box>
              {selectedComponent === "site" && menuData && (
                <SiteDetailsComponent
                  selectedAcc={selectedAccName}
                  siteId={parseInt(selectedSite)}
                  siteName={siteName}
                  siteSaveCallBack={() => fetchData(selectedLanguageCode)}
                  selectedLanguageCode={selectedLanguageCode}
                  AddSiteCallBack={() => {
                    if (selectedSiteData) {
                      setSiteName(`Site ${menuData?.wizSiteList?.length + 1}`);
                      setSelectedSite("0");
                    }
                  }}
                />
              )}
              {selectedComponent === "subgroup" && (
                <SubgroupDetailsComponent
                  siteId={parseInt(selectedSite)}
                  subGroupId={selectedSubGroupId}
                  subGroupName={subGroupName}
                  childCallBack={() => {
                    fetchData(selectedLanguageCode);
                    setIsAssetgroupFirst(false);
                    setIsSubgroupFirst(false);
                  }}
                  AddCallBack={() => {
                    let addsubgroupName = "";
                    if (
                      selectedSiteData &&
                      selectedSiteData.subgroups &&
                      selectedSiteData?.subgroups?.length > 0
                    ) {
                      addsubgroupName = `Subgroup ${
                        selectedSiteData?.subgroups?.length + 1
                      }`;
                    } else {
                      addsubgroupName = `Subgroup 1`;
                    }
                    addNewGroupToSite(addsubgroupName, 0, "subgroup");
                  }}
                  selectedLanguageCode={selectedLanguageCode}
                  isSubgroupFirst={isSubgroupFirst}
                />
              )}
              {selectedComponent === "assetGroup" && (
                <AssetGroupWizards
                  subGroupId={selectedSubGroupId}
                  assetGroupId={selectedAssetGroupId}
                  assetGroupName={assetGroupName}
                  addAssetGroupCallback={() => {
                    let addAssetGroupName = "";

                    if (
                      selectedSiteData &&
                      selectedSiteData.subgroups &&
                      selectedSiteData.subgroups.length > 0
                    ) {
                      const targetSubgroup = selectedSiteData.subgroups.find(
                        (subgroup: subGroupDataTypes) =>
                          subgroup.id === selectedSubGroupId
                      );
                      const assetGroupCount =
                        targetSubgroup?.assetGroups?.length || 0;
                      addAssetGroupName = `Asset Group ${assetGroupCount + 1}`;
                    } else {
                      addAssetGroupName = "Asset Group 1";
                    }

                    addNewGroupToSite(addAssetGroupName, 0, "assetGroup");
                  }}
                  assetGroupChildCallback={() => {
                    fetchData(selectedLanguageCode);
                    setIsAssetgroupFirst(false);
                  }}
                  selectedLanguageCode={selectedLanguageCode}
                  isAssetGroupFirst={isAssetGroupFirst}
                />
              )}
              {selectedComponent === "shifts" && (
                <ShiftDetailScreen
                  isMenuOpen={menuOpen}
                  selectedSubGroupId={selectedSubGroupId}
                />
              )}
              {selectedComponent === "users" && (
                <UsersList
                  onDataFetch={handleData}
                  menuOpen={menuOpen}
                  selectedLanguageCode={selectedLanguageCode}
                  onFilter={handleFilter}
                  subGroupId={selectedSubGroupId}
                  accountsList={menuData?.accountList || []}
                  userChildCallback={() => {
                    fetchData(selectedLanguageCode);
                  }}
                />
              )}
              {selectedComponent === "assets" && (
                <AssetsList
                  selectedLanguageCode={selectedLanguageCode}
                  onDataFetch={handleData}
                  assetCategoryId={selectedAssetCategoryId}
                  assetGroupId={selectedAssetGroupId}
                  assetCategoryName={assetCategoryName}
                  onFilter={handleFilter}
                  assetChildCallback={() => {
                    fetchData(selectedLanguageCode);
                  }}
                  showAddEditForm={addButtonClick}
                  setShowAddEditForm={setAddButtonClick}
                />
              )}

              {selectedComponent === "holidays" && (
                <HolidayListScreen
                  onDataFetch={handleData}
                  selectedLanguageCode={selectedLanguageCode}
                  menuOpen={menuOpen}
                  subGroupId={selectedSubGroupId}
                  holidayChildCallback={() => {
                    fetchData("en");
                  }}
                  onFilter={handleFilter}
                />
              )}

              {selectedComponent === "kml" && (
                <UploadKmlScreen
                  assetGroupId={selectedAssetGroupId}
                  assetGroupName={assetGroupName}
                  isMenuOpen={menuOpen}
                  parentCall={() => {
                    toggleKml(selectedAssetGroupId);
                  }}
                />
              )}
              {selectedComponent === "loadDistribution" && (
                <LoadDistributionScreen
                  isMenuOpen={menuOpen}
                  selectedSubGroupId={selectedSubGroupId}
                />
              )}
            </Box>
          </Box>
        </Box>
        <Box className="flex__ p-md" sx={{ justifyContent: "space-between" }}>
          <ButtonComponent
            label="Previous"
            className={`justifyContent-FlexStart ${
              isPreviousDisabled ? "btn-disabled" : "nextButton"
            }`}
            disabled={isPreviousDisabled}
            onClick={handlePrevious}
          />
          <ButtonComponent
            label="Next"
            className={`justifyContent-FlexStart ${
              isNextDisabled ? "btn-disabled" : "nextButton"
            }`}
            disabled={isNextDisabled}
            onClick={handleNext}
          />
        </Box>
      </Box>
    </WizardsContext.Provider>
  );
};

export default WizardParentComponent;
