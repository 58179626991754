import { Avatar, Box, Divider, Grid, TextField, Tooltip } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import "./WorkOrderExecution.css";
import MediumTypography from "../../components/formlib/MediumTypography";
import SmallBorderLine from "../../components/formlib/SmallBorderLine";
import { ReactComponent as CrossIcon } from "../../assets/images/_CrossIcon_.svg";
import ButtonComponent from "../../components/formlib/ButtonComponent";
import TextInput from "../../components/formlib/TextInput";
import dayjs, { Dayjs } from "dayjs";
import DropdownComponent from "../../components/formlib/DropdownComponent";
import RadioComponent from "../../components/formlib/RadioComponent";
import {
  ApproverType,
  AssigneeType,
  GeoStampType,
  ImageType,
  ModuleCleaning,
  StatusDataType,
  getModuleCleaningApi,
  saveModuleCleaningApi,
} from "../../api/moduleCleaning/ModuleCleaningApi";
import * as yup from "yup";
import { Formik, FormikProps } from "formik";
import StatusExecution from "../../components/formlib/StatusExecution";
import { useLocation } from "react-router";
import { OptionType } from "../../utils/type";
import { useNavigate } from "react-router-dom";

import ErrorModal from "../../components/formlib/modal/ErrorModal";
import DateTimePickerComponent from "../../components/formlib/DateTimePickerComponent";
import { ReactComponent as ErrorIcon } from "../../assets/images/ErrorIcon.svg";
import { ReactComponent as ChevronCircleIcon } from "../../assets/images/chevronDownCircleIcon.svg";

import {
  MODULE_CLEANING_PLANNER,
  SCHEDULER_DASHBOARD,
} from "../../routes/Routing";
import WorkOrderActivity from "./WorkOrderActivity";
import WorkOrderGallery from "./WorkOrderGallery";
import WorkOrderAssignees from "./WorkOrderAssignees";
import { hasPermission } from "../../utils/checkPermission";
import { useIntl } from "react-intl";
import {
  convertHoursAndMinutesToMinutes,
  convertMinutesToHoursAndMinutes,
} from "../../api/Constant";
import useAvailableHeight from "../../utils/useAvailableHeight";
import { handleError } from "../../utils/commonFunctions";

export interface GeoStampTy {
  comments: string;
  geoStamp: GeoStampType;
  createdDateTime: string;
  category?: string;
}

const initialModuleCleaningValues: ModuleCleaning = {
  woId: 0,
  isEdit: false,
  isEditCard: false,
  status: [],
  cardDetailsInfo: {
    code: "",
    type: "",
    assetCatFreqDesc: "",
    workOrderUniqueId: 0,
    frequencyDisplay: "",
    assetNameAndCount: "",
    asset: "",
    lastUpdated: "",
  },
  workOrderInfo: {
    scheduledStartDateTime: null,
    scheduledEndDateTime: null,
    actualStartDateTime: null,
    actualEndDateTime: null,
    plannedTables: 0,
    actualTables: 0,
    plannedModules: 0,
    actualModules: 0,
    cycleName: "",
    rejectedTables: 0,
    rejectedModules: 0,
    numberOfWorkingHours: 0,
    cleaningCompletion: "",
    leadAssignee: {
      name: "",
      id: 0,
      title: "",
      image: "",
      status: "",
    },
  },
  cleaningInfoBean: {
    robotsAvailableAutomatic: 0,
    robotsAvailableSemiAutomatic: 0,
    robotsFunctionalAutomatic: 0,
    robotsFunctionalSemiAutomatic: 0,
    remarks: "",
    cleaningType: "",
    selectedCleaningTypeId: 0,
    selectedCleaningMethodId: 0,
    laboursAllocated: 0,
    selectedReasonForDeviationId: 0,
    selectedCleaningTypeName: "",
    otherReason: "",
    impactedHours: 0,
    selectedCleaningTypeCode: "",
  },
  cleaningMethod: [{ id: 0, name: "" }],
  vendorList: [],
  reasonForDeviationList: [{ id: 0, name: "", code: "" }],
  additionalInfoBean: {
    cycleCountInc: 0,
    cumulativeCycleCount: 0,
    rainFall: 0,
    grassImpact: false,
    vendorId: 0,
    contractLineItem: "",
    lastUpdatedBy: {
      name: "",
      id: 0,
      title: "",
      image: "",
      status: "",
    },
  },
  assignee: [],
  dumpImage: [],
  afterImage: [],
  beforeImage: [],
  scheduleExecution: {},
  definedScheduleStatus: [],
  version: 0,
};

const ModuleCleaningComponent = () => {
  const [message, setMessage] = useState<string>("");

  const [moduleCleaningValues, setModuleCleaningValues] =
    useState<ModuleCleaning>(initialModuleCleaningValues);
  const location = useLocation();
  const [vendorList, setVendorList] = useState<OptionType[]>([]);
  const [reasonForDeviationList, setReasonForDeviationList] = useState<
    OptionType[]
  >([]);
  const [cleaningMethodList, setCleaningMethodList] = useState<OptionType[]>(
    []
  );
  const [othersReasonMessage, setOthersReasonMessage] = useState<string>("");
  const [leadAssignee, setLeadAssignee] = useState<AssigneeType>();
  const [assignees, setAssignees] = useState<AssigneeType[]>([]);
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [errorDesc, setErrorDesc] = useState<string>("");
  const navigate = useNavigate();
  const [dumgImage, setDumpImage] = useState<ImageType[]>([]);
  const [afterImage, setAfterImage] = useState<ImageType[]>([]);
  const [beforeImage, setBeforeImage] = useState<ImageType[]>([]);
  const [approver1, setApprover1] = useState<ApproverType | null | undefined>();
  const [approver2, setApprover2] = useState<ApproverType | null | undefined>();
  const [approver3, setApprover3] = useState<ApproverType | null | undefined>();
  const navState = location?.state;
  const [error, setError] = useState("");
  const intl = useIntl();
  const formikRef = useRef<FormikProps<ModuleCleaning>>(null);
  const [editDisable, setEditDisable] = useState(false);
  const availableHeight = useAvailableHeight(330);
  const collapseAvailHeight = useAvailableHeight(230);
  const [showAwaitingApproval, setShowAwaitingApproval] = useState(false);
  const [lastTransition, setLastTransition] = useState<StatusDataType>(
    {} as StatusDataType
  );
  const [isVisible, setIsVisible] = useState(true); // expand or collapse filters state

  // let lastTransition: StatusDataType = {} as StatusDataType;

  const validationSchema = yup.object().shape({
    woId: yup.number().nullable(),
    isEdit: yup.boolean().nullable(),
    isEditCard: yup.boolean().nullable(),
    workOrderInfo: yup.object().shape({
      scheduledStartDateTime: yup.string().nullable(),
      scheduledEndDateTime: yup.string().nullable(),
      actualStartDateTime: yup.string().nullable(),
      actualEndDateTime: yup.string().nullable(),
      plannedTables: yup.number().nullable(),
      actualTables: yup.number().nullable(),
      plannedModules: yup.number().nullable(),
      actualModules: yup.number().nullable(),
      cycleName: yup.string().nullable(),
      rejectedTables: yup.number().nullable(),
      rejectedModules: yup.number().nullable(),
      numberOfWorkingHours: yup.number().nullable(),
      cleaningCompletion: yup.string().nullable(),
    }),
    cleaningInfoBean: yup.object().shape({
      robotsAvailableAutomatic: yup.number().nullable(),
      robotsAvailableSemiAutomatic: yup.number().nullable(),
      robotsFunctionalAutomatic: yup.number().nullable(),
      robotsFunctionalSemiAutomatic: yup.number().nullable(),
      remarks: yup.string().nullable(),
      cleaningType: yup.string().nullable(),
      cleaningMethod: yup.string().nullable(),
      noOfLabors: yup.number().nullable(),
      selectedReasonForDeviationId: yup.string().nullable(),
      selectedCleaningTypeId: yup.string().nullable(),
      selectedCleaningMethodId: yup
        .number()
        .required("validation.fieldRequired")
        .test(
          "is-not-zero",
          "validation.fieldRequired",
          (value) => value !== 0
        ),
      selectedCleaningTypeName: yup.string().nullable(),
      otherReason: yup.string().nullable(),
      impactedHours: yup.number().nullable(),
    }),
    additionalInfoBean: yup.object().shape({
      cycleCountInc: yup.number().nullable(),
      cumulativeCycleCount: yup.number().nullable(),
      rainFall: yup.number().nullable(),
      grassImpact: yup.boolean().nullable(),
      vendorId: yup
        .number()
        .required("validation.fieldRequired")
        .test(
          "is-not-zero",
          "validation.fieldRequired",
          (value) => value !== 0
        ),
      contractLineItem: yup.string().nullable(),
    }),
  });

  useEffect(() => {
    const getDetails = async () => {
      getModuleCleaningInfo();
    };
    getDetails();
  }, []);
  const stateCardId = JSON.parse(navState.cardId);
  useEffect(() => {
    if (moduleCleaningValues.status !== undefined) {
      if (
        moduleCleaningValues.status[moduleCleaningValues.status.length - 1]
          ?.code === "CLOSED"
      ) {
        setEditDisable(true);
      } else {
        setEditDisable(false);
      }
    }
  }, [stateCardId, moduleCleaningValues]);

  const getModuleCleaningInfo = () => {
    getModuleCleaningApi(JSON.parse(navState.cardId))
      .then((res) => {
        setModuleCleaningValues(res);
        setOthersReasonMessage(res.cleaningInfoBean.otherReason ?? "");
        const lastStatus = res.status[res.status.length - 2];
        const showAwaitingApprovals = [
          "COMPLETED",
          "LEVEL1",
          "LEVEL2",
        ].includes(lastStatus.code);

        setShowAwaitingApproval(showAwaitingApprovals);

        // Find the last transition date and time
        const lastTransitionStatus = lastStatus;
        setLastTransition(lastTransitionStatus);

        setMessage(res.cleaningInfoBean.remarks);
        const options: OptionType[] = res.vendorList.map((vendor) => ({
          id: vendor.id.toString(),
          label: vendor.name,
        }));
        const reasonOptions: OptionType[] = res.reasonForDeviationList.map(
          (reason) => ({
            id: reason.id.toString(),
            label: reason.name,
          })
        );
        const cleaningMethodOptions: OptionType[] = res.cleaningMethod.map(
          (cleaning) => ({
            id: cleaning.id.toString(),
            label: cleaning.name,
          })
        );
        const lead = res.assignee.find((person) => person.isPrimary);
        const assignee = res.assignee.filter((person) => !person.isPrimary);

        if ("approver1" in res) {
          setApprover1(res.approver1);
        }
        if ("approver2" in res) {
          setApprover2(res.approver2);
        }
        if ("approver3" in res) {
          setApprover3(res.approver3);
        }

        setLeadAssignee(lead);
        setAssignees(assignee);
        if (res.dumpImage) {
          setDumpImage(res.dumpImage);
        }
        if (res.afterImage) {
          setAfterImage(res.afterImage);
        }
        if (res.beforeImage) {
          setBeforeImage(res.beforeImage);
        }
        setVendorList(options);
        setReasonForDeviationList(reasonOptions);
        setCleaningMethodList(cleaningMethodOptions);
      })
      .catch((err) => {
        setOpenErrorModal(true);
        handleError(err, setErrorDesc);
      });
  };

  const handleSaveModuleCleaning = (values: ModuleCleaning) => {
    values.isEdit = true;
    values.isEditCard = true;
    let params = {
      isEdit: values.isEdit,
      woId: values.woId,
      assignee: values.assignee,
      additionalInfoBean: values.additionalInfoBean,
      cardDetailsInfo: values.cardDetailsInfo,
      status: values.status,
      cleaningInfoBean: values.cleaningInfoBean,
      workOrderInfo: values.workOrderInfo,
      isEditCard: values.isEditCard,
      cleaningMethod: values.cleaningMethod,
      vendorList: values.vendorList,
      reasonForDeviationList: values.reasonForDeviationList,
      definedScheduleStatus: [],
      scheduleExecution: {},
      version: values.version,
    };
    saveModuleCleaningApi(JSON.parse(navState.cardId), params)
      .then(() => {
        getModuleCleaningInfo();
        setError("");
        values.isEditCard = false;
      })
      .catch((err) => {
        setOpenErrorModal(true);
        setError("");
        handleError(err, setErrorDesc);
      });
  };

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  return (
    <Box component="main">
      {openErrorModal && (
        <ErrorModal
          descriptionText={errorDesc}
          open={openErrorModal}
          handleClose={() => setOpenErrorModal(false)}
          onPositiveClick={() => {
            setOpenErrorModal(false);
          }}
        />
      )}

      <Box sx={{ padding: "24px 0px 24px 0px" }}>
        <Box className="flex__" sx={{ gap: "10px", marginBottom: "10px" }}>
          <Box>
            <MediumTypography
              className="card-wo-category"
              label={moduleCleaningValues.cardDetailsInfo.code}
              sxProps={{
                color: "#B8ACF6",
                border: `2px solid #B8ACF6`,
              }}
            />
          </Box>
          <Box>
            <MediumTypography
              label={moduleCleaningValues.cardDetailsInfo.type}
              textColor="#B8ACF6"
              fontSize="16px"
              fontWeight={700}
            />
          </Box>
          <Box className="flex__" sx={{ marginLeft: "auto" }}>
            <Box className="position__relative" sx={{ right: "20px" }}>
              <MediumTypography
                label={`Last Updated ${dayjs(
                  moduleCleaningValues.cardDetailsInfo.lastUpdated
                ).format("DD MMMM YYYY")} at ${dayjs(
                  moduleCleaningValues.cardDetailsInfo.lastUpdated
                ).format("HH:mm")}`}
                textColor="#626F86"
                fontSize="14px"
                fontWeight={400}
              />
            </Box>
            <Box className="position__relative" sx={{ right: "10px" }}>
              <MediumTypography
                labelId="CloseText"
                defaultLabel="Close"
                textColor="#626F86"
                fontSize="14px"
                fontWeight={400}
              />
            </Box>
            <Box>
              <CrossIcon
                className="cursor__pointer"
                onClick={() =>
                  navigate(SCHEDULER_DASHBOARD, {
                    state: {
                      date: navState.date,
                      dateRange: navState.dateRange,
                      schedulerViewChange: navState.schedulerViewChange,
                    },
                  })
                }
              />
            </Box>
          </Box>
        </Box>
        <Box className="flex__" sx={{ gap: "10px", marginBottom: "10px" }}>
          <Box>
            <MediumTypography
              label={moduleCleaningValues.cardDetailsInfo.workOrderUniqueId.toString()}
              textColor="#FFFFFF"
              fontSize="20px"
              fontWeight={700}
            />
          </Box>
          <SmallBorderLine />

          <Box>
            <MediumTypography
              className="card-wo-routine"
              label={moduleCleaningValues.cardDetailsInfo.frequencyDisplay}
              sxProps={{ padding: "4px 4px !important" }}
            />
          </Box>
          <Box className="flex__">
            <MediumTypography
              label={moduleCleaningValues.cardDetailsInfo.asset}
              textColor="#FFFFFF"
              fontSize="20px"
              fontWeight={700}
            />
            {isVisible && (
              <Box
                className="cursor__pointer"
                sx={{
                  position: "relative",
                  alignItems: "end",
                  // backgroundColor: "#fff", // Optional: To give a white background around the icon
                  padding: "4px 8px", // Optional: Adjust the padding around the icon
                }}
                onClick={toggleVisibility}
              >
                <Tooltip title="Hide State Transition">
                  <ChevronCircleIcon />
                </Tooltip>
              </Box>
            )}
            {!isVisible && (
              <Box
                className="cursor__pointer"
                sx={{
                  position: "relative",
                  alignItems: "end",
                  // backgroundColor: "#fff", // Optional: To give a white background around the icon
                  padding: "4px 8px", // Optional: Adjust the padding around the icon
                }}
                onClick={toggleVisibility}
              >
                <Tooltip title="Show State Transition">
                  <ChevronCircleIcon className="rotated-180" />
                </Tooltip>
              </Box>
            )}
          </Box>
          {showAwaitingApproval && (
            <Box className="flex__" sx={{ marginLeft: "auto" }}>
              <Box
                className="flex__ position__relative"
                sx={{
                  padding: "4px 12px 4px 12px",
                  borderRadius: "3px",
                  backgroundColor: "rgba(161, 189, 217, 0.08)",
                  right: "20px",
                }}
              >
                {/* <ProfilePhoto /> */}
                <Box className="flex__">
                  <MediumTypography
                    defaultLabel={`Awaiting Approval - `}
                    labelId="AwaitingApproval"
                    textColor="rgba(255, 255, 255, 0.72)"
                    sxProps={{
                      textTransform: "none",
                      position: "relative",
                      left: "4px",
                    }}
                  />
                  <MediumTypography
                    label={`${
                      lastTransition.code === "COMPLETED"
                        ? lastTransition.approverRole1
                        : lastTransition.code === "LEVEL1"
                        ? lastTransition.approverRole2
                        : lastTransition.approverRole3
                    }`}
                    textColor="rgba(255, 255, 255, 0.72)"
                    className="ml-xs"
                    sxProps={{
                      textTransform: "none",
                      position: "relative",
                      left: "4px",
                    }}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  padding: "4px 8px 4px 8px",
                  borderRadius: "3px",
                  backgroundColor: "rgba(161, 189, 217, 0.08)",
                }}
                className="flex__"
              >
                <MediumTypography
                  defaultLabel={"Approval Requested on "}
                  labelId="ApprovedOnLabel"
                  textColor="rgba(255, 255, 255, 0.72)"
                  sxProps={{
                    textTransform: "none",
                  }}
                />
                <MediumTypography
                  label={`${dayjs(lastTransition.transitionDateTime).format(
                    "DD MMMM YYYY"
                  )} ${dayjs(lastTransition.transitionDateTime).format(
                    "HH:mm"
                  )}`}
                  className="ml-xs"
                  textColor="rgba(255, 255, 255, 0.72)"
                  sxProps={{
                    textTransform: "none",
                  }}
                />
              </Box>
            </Box>
          )}
        </Box>
        {isVisible && (
          <Grid container className="mt-xs">
            <Grid item xs={12} lg={12} md={12} xl={12}>
              <Box>
                <Divider
                  variant="fullWidth"
                  sx={{ borderTop: "1px solid #333b43" }}
                />
              </Box>
            </Grid>
          </Grid>
        )}
        {isVisible && moduleCleaningValues.status.length && (
          <StatusExecution
            statusArray={moduleCleaningValues.status}
            cardId={navState.cardId}
            WOUniqueId={moduleCleaningValues.cardDetailsInfo.workOrderUniqueId}
            version={moduleCleaningValues.version}
            definedScheduleStatus={moduleCleaningValues.definedScheduleStatus}
            scheduleExecution={moduleCleaningValues.scheduleExecution}
            statusCallBack={getModuleCleaningInfo}
            code={moduleCleaningValues.cardDetailsInfo.code}
            assetGroupId={navState.assetGroupId}
            idType="workorder"
            currentDate={navState.date}
            dateRange={navState.dateRange}
            actualsValue={moduleCleaningValues?.workOrderInfo?.actualTables}
          />
        )}

        <Box component="div">
          <Formik
            initialValues={moduleCleaningValues}
            enableReinitialize
            validateOnChange
            innerRef={formikRef}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              if (!error) {
                handleSaveModuleCleaning(values);
              }
            }}
          >
            {({ values, setFieldValue, handleSubmit, errors, touched }) => {
              const reasonOthers =
                moduleCleaningValues.reasonForDeviationList?.find(
                  (type) => type.code === "Others"
                );
              const reasonOthersId = reasonOthers ? reasonOthers.id : null;
              return (
                <Grid container>
                  <Grid
                    item
                    xs={9}
                    sx={{
                      borderRight: "1px solid #333b43",
                      overflowY: "auto",
                      height: isVisible ? availableHeight : collapseAvailHeight,
                    }}
                  >
                    <Grid container className="mt-xs">
                      <Grid item xs={12} lg={12} md={12} xl={12}>
                        <Box>
                          <Divider
                            variant="fullWidth"
                            sx={{
                              borderTop: "1px solid #333b43",
                            }}
                          />
                        </Box>
                      </Grid>
                    </Grid>

                    <Box sx={{ padding: "24px 0px 24px 0px" }}>
                      <Box className="flex__" sx={{ marginBottom: "20px" }}>
                        <Box>
                          <MediumTypography
                            labelId="WorkOrderInfoText"
                            defaultLabel="Work order Info"
                            fontSize="20px"
                            fontWeight={500}
                            textColor="#FFFFFF"
                          />
                        </Box>
                        {!editDisable && (
                          <Box sx={{ marginLeft: "auto" }}>
                            <Grid
                              className="flex__ justifyContent-FlexEnd"
                              container
                              direction="row"
                              alignItems="right"
                            >
                              {values.isEditCard && (
                                <Grid item>
                                  <ButtonComponent
                                    sxProps={{ color: "#B6C2CF !important" }}
                                    className="btn-primary btn-cancel"
                                    variantType="outlined"
                                    defaultLabelId="Cancel"
                                    labelId="btn.cancel"
                                    onClick={() => {
                                      setFieldValue("isEditCard", false);
                                      formikRef.current?.resetForm();
                                      setError("");
                                    }}
                                  />
                                </Grid>
                              )}
                              {values.isEditCard && (
                                <Grid item>
                                  <ButtonComponent
                                    className="btn-primary btn-submit mr-md"
                                    variantType="contained"
                                    defaultLabelId="Save"
                                    labelId="btn.save"
                                    onClick={handleSubmit}
                                  />
                                </Grid>
                              )}
                              {!values.isEditCard && (
                                <Grid item>
                                  <ButtonComponent
                                    className="btn-primary btn-submit mr-md"
                                    variantType="contained"
                                    defaultLabelId="Edit"
                                    labelId="btn.edit"
                                    onClick={() => {
                                      setFieldValue("isEditCard", true);
                                    }}
                                  />
                                </Grid>
                              )}
                            </Grid>
                          </Box>
                        )}
                      </Box>
                      <Grid
                        container
                        direction={"row"}
                        className="justifyContentSpaceBtw"
                        alignItems={"center"}
                        rowSpacing={"16px"}
                        columnSpacing={"40px"}
                        sx={{ marginBottom: "20px" }}
                      >
                        <Grid item>
                          <Box className="flex__">
                            <MediumTypography
                              labelId="AssetTextinput"
                              defaultLabel="Asset"
                              sxProps={{ marginBottom: "10px" }}
                              textColor={"rgba(159, 173, 188, 1)"}
                            />
                          </Box>
                          <TextInput
                            labelId="AssetTextinput"
                            className="textInputWidth"
                            defaultLabelId="Asset"
                            borderColor="1px solid rgba(166, 197, 226, 0.16)"
                            Value={values.cardDetailsInfo.asset}
                            handlechange={(value: string) => {
                              setFieldValue("cardDetailsInfo.asset", value);
                            }}
                            variant="outlined"
                            backGroundColor="#22272B"
                            fieldheight="40px"
                            inputProps={{ readOnly: true }}
                            textColor={"rgba(140, 155, 171, 1)"}
                            sxProps={{
                              color: "rgba(159, 173, 188, 1)",
                            }}
                          />
                        </Grid>
                        <Grid item>
                          <MediumTypography
                            labelId="CycleNameTextInput"
                            defaultLabel="Cycle Name"
                            sxProps={{ marginBottom: "10px" }}
                            textColor={"rgba(159, 173, 188, 1)"}
                          />
                          <TextInput
                            labelId="CycleNameTextInput"
                            className="textInputWidth"
                            defaultLabelId="Cycle Name"
                            borderColor="1px solid rgba(166, 197, 226, 0.16)"
                            Value={values.workOrderInfo.cycleName}
                            handlechange={(value: string) => {
                              setFieldValue("workOrderInfo.cycleName", value);
                            }}
                            textColor={
                              values.isEditCard
                                ? "rgba(255, 255, 255, 1)"
                                : "rgba(140, 155, 171, 1)"
                            }
                            variant="outlined"
                            backGroundColor="#22272B"
                            fieldheight="40px"
                            sxProps={{
                              color: values.isEditCard
                                ? "rgba(159, 173, 188, 1)"
                                : "white",
                            }}
                          />
                        </Grid>
                        <Grid item>
                          <MediumTypography
                            labelId="LeadAssigneeTextinput"
                            defaultLabel="Lead Assignee."
                            sxProps={{ marginBottom: "10px" }}
                            textColor={"rgba(159, 173, 188, 1)"}
                          />
                          <TextInput
                            labelId="LeadAssigneeTextinput"
                            defaultLabelId="Lead Assignee."
                            className="textInputWidth"
                            borderColor="1px solid rgba(166, 197, 226, 0.16)"
                            variant="outlined"
                            Value={values.workOrderInfo.leadAssignee.name}
                            backGroundColor="#22272B"
                            fieldheight="40px"
                            inputProps={{ readOnly: true }}
                            textColor={"rgba(140, 155, 171, 1)"}
                            sxProps={{
                              color: "rgba(140, 155, 171, 1)",
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        direction={"row"}
                        className="justifyContentSpaceBtw"
                        alignItems={"center"}
                        rowSpacing={"16px"}
                        columnSpacing={"40px"}
                        sx={{ marginBottom: "20px" }}
                      >
                        <Grid item>
                          <MediumTypography
                            labelId="ScheduledStartTimeInput"
                            defaultLabel="Scheduled Start Time"
                            sxProps={{ marginBottom: "10px" }}
                            textColor={"rgba(159, 173, 188, 1)"}
                          />

                          <DateTimePickerComponent
                            value={dayjs(
                              values.workOrderInfo.scheduledStartDateTime?.toString()
                            )}
                            labelid="ScheduledStartTimeInput"
                            defaultlabelid="Scheduled Start Time"
                            handlechange={(date: Dayjs) => {
                              const formattedText = date.format(
                                "YYYY-MM-DDTHH:mm:ss"
                              );
                              setFieldValue(
                                "workOrderInfo.scheduledStartDateTime",
                                formattedText
                              );
                            }}
                            disabledDate={true}
                            inputFormat="DD MMM YYYY  HH:mm:ss"
                            width="250px"
                            textWidth="250px"
                          />
                        </Grid>
                        <Grid item>
                          <MediumTypography
                            labelId="ScheduledEndTimeTextInput"
                            defaultLabel="Scheduled End Time"
                            sxProps={{ marginBottom: "10px" }}
                            textColor={"rgba(159, 173, 188, 1)"}
                          />

                          <DateTimePickerComponent
                            value={dayjs(
                              values.workOrderInfo.scheduledEndDateTime?.toString()
                            )}
                            labelid="ScheduledEndTimeTextInput"
                            defaultlabelid="Scheduled End Time"
                            handlechange={(date: Dayjs) => {
                              const formattedText = date.format(
                                "YYYY-MM-DDTHH:mm:ss"
                              );
                              setFieldValue(
                                "workOrderInfo.scheduledEndDateTime",
                                formattedText
                              );
                            }}
                            disabledDate={true}
                            inputFormat="DD MMM YYYY  HH:mm:ss"
                            width="250px"
                            textWidth="250px"
                          />
                        </Grid>
                        <Grid item>
                          <MediumTypography
                            labelId="ActualStartTimeTextInput"
                            defaultLabel="Actual Start Time"
                            sxProps={{ marginBottom: "10px" }}
                            textColor={"rgba(159, 173, 188, 1)"}
                          />
                          <DateTimePickerComponent
                            value={
                              values.workOrderInfo.actualStartDateTime !== null
                                ? dayjs(
                                    values.workOrderInfo.actualStartDateTime
                                  )
                                : null
                            }
                            labelid="ActualStartTimeTextInput"
                            defaultlabelid="Actual Start Time"
                            handlechange={(date: Dayjs) => {
                              const formattedText = date.format(
                                "YYYY-MM-DDTHH:mm:ss"
                              );

                              setFieldValue(
                                "workOrderInfo.actualStartDateTime",
                                formattedText
                              );
                            }}
                            minDateTime={
                              values.workOrderInfo.scheduledStartDateTime !==
                              null
                                ? dayjs(
                                    values.workOrderInfo.scheduledStartDateTime
                                  )
                                : undefined
                            }
                            disabledDate={!values.isEditCard}
                            inputFormat="DD MMM YYYY  HH:mm:ss"
                            width="250px"
                            textWidth="250px"
                          />
                        </Grid>
                        <Grid item>
                          <MediumTypography
                            labelId="ActualEndTimeTextInput"
                            defaultLabel="Actual End Time"
                            sxProps={{ marginBottom: "10px" }}
                            textColor={"rgba(159, 173, 188, 1)"}
                          />

                          <DateTimePickerComponent
                            value={
                              values.workOrderInfo.actualEndDateTime !== null
                                ? dayjs(values.workOrderInfo.actualEndDateTime)
                                : null
                            }
                            labelid="ActualEndTimeTextInput"
                            defaultlabelid="Actual End Time"
                            handlechange={(date: Dayjs) => {
                              if (
                                values.workOrderInfo.actualStartDateTime ===
                                date.format("YYYY-MM-DDTHH:mm:ss")
                              ) {
                                setError(
                                  intl.formatMessage({
                                    id: "Error.EndTimeGreaterThanStartTime",
                                    defaultMessage:
                                      "End time should be greater than start time",
                                  })
                                );
                              } else {
                                setError("");
                                setFieldValue(
                                  "workOrderInfo.actualEndDateTime",
                                  date.format("YYYY-MM-DDTHH:mm:ss")
                                );
                              }
                            }}
                            disabledDate={!values.isEditCard}
                            inputFormat="DD MMM YYYY  HH:mm:ss"
                            width="250px"
                            textWidth="250px"
                            minDateTime={
                              values.workOrderInfo.actualStartDateTime !== null
                                ? dayjs(
                                    values.workOrderInfo.actualStartDateTime
                                  )
                                : undefined
                            }
                          />
                          {error && (
                            <Box
                              className="loginError"
                              sx={{ position: "relative" }}
                            >
                              <Box>
                                <ErrorIcon />
                              </Box>
                              <MediumTypography
                                label={error}
                                fontSize="12px"
                                textColor="#AE2E24"
                                fontWeight={400}
                              />
                            </Box>
                          )}
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        direction={"row"}
                        className="justifyContentSpaceBtw"
                        alignItems={"center"}
                        rowSpacing={"16px"}
                        columnSpacing={"40px"}
                      >
                        <Grid item>
                          <Box className="flex__">
                            <MediumTypography
                              labelId="PlannedModulesOrTableLabelText"
                              defaultLabel="Planned Tables | Modules"
                              sxProps={{ marginBottom: "10px" }}
                              textColor={"rgba(159, 173, 188, 1)"}
                            />

                            <MediumTypography
                              labelId={
                                values.workOrderInfo.plannedTables > 0
                                  ? "ReassignText"
                                  : "SelectText"
                              }
                              defaultLabel={
                                values.workOrderInfo.plannedTables > 0
                                  ? "Reassign"
                                  : "Select"
                              }
                              fontSize="10px"
                              textColor="#25BBFA"
                              className="ml-sm mt-xs"
                              sxProps={{
                                textDecoration: "underline",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                hasPermission("Plan_MC_GC_WO") &&
                                  navigate(MODULE_CLEANING_PLANNER, {
                                    state: {
                                      cardId: navState.cardId,
                                      assetGroupId: navState.assetGroupId,
                                      idType: "workorder",
                                      date: navState.date,
                                      dateRange: navState.dateRange,
                                    },
                                  });
                              }}
                            />
                          </Box>

                          <TextInput
                            labelId="PlannedModulesOrTableLabelText"
                            defaultLabelId="Planned Tables | Modules"
                            borderColor="1px solid rgba(166, 197, 226, 0.16)"
                            variant="outlined"
                            inputProps={{ readOnly: true }}
                            textColor={"rgba(140, 155, 171, 1)"}
                            sxProps={{
                              color: "rgba(140, 155, 171, 1)",
                            }}
                            className="textInputWidth"
                            backGroundColor="#22272B"
                            Value={`${
                              values.workOrderInfo.plannedTables !== 0
                                ? values.workOrderInfo.plannedTables
                                : "-"
                            } | ${
                              values.workOrderInfo.plannedModules !== 0
                                ? values.workOrderInfo.plannedModules
                                : "-"
                            } `}
                            fieldheight="40px"
                          />
                        </Grid>
                        <Grid item>
                          <MediumTypography
                            labelId="CleanedModulesOrTableLabelText"
                            defaultLabel="Cleaned Tables | Modules"
                            sxProps={{ marginBottom: "10px" }}
                            textColor={"rgba(159, 173, 188, 1)"}
                          />

                          <TextInput
                            labelId="CleanedModulesOrTableLabelText"
                            defaultLabelId="Cleaned Tables | Modules"
                            borderColor="1px solid rgba(166, 197, 226, 0.16)"
                            variant="outlined"
                            className="textInputWidth"
                            inputProps={{ readOnly: true }}
                            textColor={"rgba(140, 155, 171, 1)"}
                            sxProps={{
                              color: "rgba(140, 155, 171, 1)",
                            }}
                            backGroundColor="#22272B"
                            Value={`${
                              values.workOrderInfo.rejectedTables !== 0 ||
                              values.workOrderInfo.actualTables !== 0
                                ? values.workOrderInfo.actualTables
                                : "-"
                            } | ${
                              values.workOrderInfo.actualModules !== 0 ||
                              values.workOrderInfo.rejectedTables !== 0
                                ? values.workOrderInfo.actualModules
                                : "-"
                            }`}
                            fieldheight="40px"
                          />
                        </Grid>
                        <Grid item>
                          <MediumTypography
                            labelId="RejectedModulesOrTableLabelText"
                            defaultLabel="Rejected Tables | Modules"
                            sxProps={{ marginBottom: "10px" }}
                            textColor={"rgba(159, 173, 188, 1)"}
                          />

                          <TextInput
                            labelId="RejectedModulesOrTableLabelText"
                            defaultLabelId="Rejected Tables | Modules"
                            borderColor="1px solid rgba(166, 197, 226, 0.16)"
                            variant="outlined"
                            Value={`${
                              values.workOrderInfo.rejectedTables !== 0
                                ? values.workOrderInfo.rejectedTables
                                : "-"
                            } | ${
                              values.workOrderInfo.rejectedModules !== 0
                                ? values.workOrderInfo.rejectedModules
                                : "-"
                            }`}
                            inputProps={{ readOnly: true }}
                            textColor={"rgba(140, 155, 171, 1)"}
                            sxProps={{
                              color: "rgba(140, 155, 171, 1)",
                            }}
                            backGroundColor="#22272B"
                            fieldheight="40px"
                            className="textInputWidth"
                          />
                        </Grid>
                        <Grid item xs={3} lg={3}>
                          <MediumTypography
                            labelId="ReasonForDeviation"
                            defaultLabel="Reason For Deviation"
                            sxProps={{ marginBottom: "10px" }}
                            textColor={"rgba(159, 173, 188, 1)"}
                          />
                          <DropdownComponent
                            names={reasonForDeviationList}
                            value={
                              values.cleaningInfoBean
                                .selectedReasonForDeviationId === 0 ||
                              values.cleaningInfoBean
                                .selectedReasonForDeviationId === null
                                ? ""
                                : values.cleaningInfoBean.selectedReasonForDeviationId.toString()
                            }
                            labelid="ReasonForDeviationLabel"
                            defaultlabelid="--Select Reason For Deviation--"
                            handleChange={(value: string) => {
                              setFieldValue(
                                "cleaningInfoBean.selectedReasonForDeviationId",
                                parseInt(value)
                              );
                              setOthersReasonMessage("");
                              setFieldValue(
                                "grassCuttingInfoBean.otherReason",
                                ""
                              );
                            }}
                            textColor={
                              values.isEditCard
                                ? "rgba(255, 255, 255, 1)"
                                : "rgba(140, 155, 171, 1)"
                            }
                            minWidth="250px"
                            disabled={!values.isEditCard}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        direction={"row"}
                        className="justifyContentSpaceBtw"
                        alignItems={"center"}
                        rowSpacing={"16px"}
                        columnSpacing={"40px"}
                        sx={{ marginTop: "0px" }}
                      >
                        {values.cleaningInfoBean.selectedReasonForDeviationId &&
                          values.cleaningInfoBean
                            .selectedReasonForDeviationId !== null && (
                            <Grid item>
                              <MediumTypography
                                labelId="MC.ImpactedHours"
                                defaultLabel="Impacted Hours"
                                sxProps={{ marginBottom: "10px" }}
                                textColor={"rgba(159, 173, 188, 1)"}
                              />
                              <Box
                                className="flex__"
                                sx={{
                                  position: "relative",
                                  alignItems: "center",
                                }}
                              >
                                <TextInput
                                  labelId="MC.ImpactedHours"
                                  className="textInputWidth"
                                  defaultLabelId="Impacted Hours"
                                  borderColor="1px solid rgba(166, 197, 226, 0.16)"
                                  Value={convertMinutesToHoursAndMinutes(
                                    values?.cleaningInfoBean?.impactedHours
                                  )}
                                  // Value={values.estimatedEffort.toString()}
                                  handlechange={(value: string) => {
                                    // setFieldValue("estimatedEffort", value);
                                    const minutes =
                                      convertHoursAndMinutesToMinutes(value);
                                    setFieldValue(
                                      "cleaningInfoBean.impactedHours",
                                      minutes
                                    );
                                  }}
                                  variant="outlined"
                                  backGroundColor="#22272B"
                                  fieldheight="40px"
                                  textColor={
                                    values.isEditCard
                                      ? "rgba(255, 255, 255, 1)"
                                      : "rgba(140, 155, 171, 1)"
                                  }
                                />
                                <Box
                                  sx={{
                                    padding: "4px 8px 4px 8px",
                                    backgroundColor: "#191E22",
                                    border: "1px solid #374048",
                                    height: "24px",
                                    borderRadius: "4px",
                                    right: "8px",
                                    position: "absolute",
                                  }}
                                >
                                  <MediumTypography
                                    // labelId="cm.minutes"
                                    // defaultLabel="Minutes"
                                    label="( hh:mm )"
                                    sxProps={{
                                      lineHeight: "normal",
                                      fontSize: "12px",
                                    }}
                                  />
                                </Box>
                              </Box>
                            </Grid>
                          )}
                        <Grid item xs={8} lg={8} md={8}>
                          {values.cleaningInfoBean
                            .selectedReasonForDeviationId ===
                            reasonOthersId && (
                            <Grid xs={11.5} lg={11.5} md={11.5}>
                              <MediumTypography
                                labelId="OthersText"
                                defaultLabel="Others"
                                textColor={"rgba(159, 173, 188, 1)"}
                                sxProps={{ marginBottom: "10px" }}
                              />
                              <TextField
                                variant="outlined"
                                sx={{
                                  backgroundColor: "#22272B",
                                  borderRadius: "4px",
                                  border: "1px solid rgba(166, 197, 226, 0.16)",
                                  "& .MuiOutlinedInput-root": {
                                    "& fieldset": {
                                      borderColor: "#1D2226",
                                    },

                                    "&:hover fieldset": {
                                      borderColor: "#1D2226",
                                    },
                                    "&.Mui-focused fieldset": {
                                      borderColor: "#1D2226",
                                    },
                                    "& input": {
                                      color: values.isEditCard
                                        ? "rgba(255, 255, 255, 1)"
                                        : "rgba(140, 155, 171, 1)", // Text color inside the input
                                    },
                                  },
                                  "& .MuiInputLabel-root": {
                                    color: "#bbb", // Placeholder color
                                  },
                                }}
                                minRows={5}
                                fullWidth
                                value={othersReasonMessage}
                                onChange={(event) => {
                                  setOthersReasonMessage(
                                    event.target.value.trimStart()
                                  );
                                  setFieldValue(
                                    "cleaningInfoBean.otherReason",
                                    event.target.value.trimStart()
                                  );
                                }}
                                inputProps={{ readOnly: !values.isEditCard }}
                              />
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    </Box>
                    <Grid container className="mt-xs">
                      <Grid item xs={12} lg={12} md={12} xl={12}>
                        <Box>
                          <Divider
                            variant="fullWidth"
                            sx={{
                              borderTop: "1px solid #333b43",
                            }}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                    <Box sx={{ padding: "24px 0px 24px 0px" }}>
                      <Box className="flex__" sx={{ marginBottom: "30px" }}>
                        <Box>
                          <MediumTypography
                            labelId="CleaningInfoText"
                            defaultLabel="Cleaning Info"
                            fontSize="20px"
                            fontWeight={500}
                            textColor="#FFFFFF"
                          />
                        </Box>
                      </Box>
                      <Grid
                        container
                        direction={"row"}
                        className="justifyContentSpaceBtw"
                        // alignItems={"center"}
                        rowSpacing={"16px"}
                        columnSpacing={"40px"}
                        sx={{ marginBottom: "20px" }}
                      >
                        <Grid item>
                          <MediumTypography
                            labelId="CleaningTypeLabelText"
                            defaultLabel="Cleaning Type"
                            sxProps={{ marginBottom: "10px" }}
                            textColor={"rgba(159, 173, 188, 1)"}
                          />
                          <TextInput
                            labelId="CleaningTypeLabelText"
                            defaultLabelId="Cleaning Type"
                            borderColor="1px solid rgba(166, 197, 226, 0.16)"
                            variant="outlined"
                            Value={
                              values.cleaningInfoBean.selectedCleaningTypeName
                            }
                            inputProps={{ readOnly: true }}
                            textColor={"rgba(140, 155, 171, 1)"}
                            backGroundColor="#22272B"
                            fieldheight="40px"
                            className="textInputWidth"
                          />
                        </Grid>
                        <Grid item>
                          <MediumTypography
                            labelId="CleaningMethodLabelText"
                            defaultLabel="Cleaning Method"
                            sxProps={{ marginBottom: "10px" }}
                            textColor={"rgba(159, 173, 188, 1)"}
                          />
                          <DropdownComponent
                            names={cleaningMethodList}
                            labelid="SelectCleaningMethodLabel"
                            defaultlabelid="--Select Cleaning Method--"
                            value={
                              values.cleaningInfoBean
                                .selectedCleaningMethodId === 0 ||
                              values.cleaningInfoBean
                                .selectedCleaningMethodId === null
                                ? ""
                                : values.cleaningInfoBean.selectedCleaningMethodId.toString()
                            }
                            textColor={
                              values.isEditCard
                                ? "rgba(255, 255, 255, 1)"
                                : "rgba(140, 155, 171, 1)"
                            }
                            disabled={true}
                            handleChange={(value: string) => {
                              setFieldValue(
                                "cleaningInfoBean.selectedCleaningMethodId",
                                value
                              );
                            }}
                            className="textInputWidth"
                          />
                          {errors.cleaningInfoBean?.selectedCleaningMethodId &&
                            touched.cleaningInfoBean
                              ?.selectedCleaningMethodId && (
                              <Box
                                className="loginError"
                                sx={{ position: "relative" }}
                              >
                                <Box>
                                  <ErrorIcon />
                                </Box>
                                <MediumTypography
                                  labelId={
                                    errors.cleaningInfoBean
                                      ?.selectedCleaningMethodId
                                  }
                                  defaultLabel="Cleaning method is required"
                                  fontSize="12px"
                                  textColor="#AE2E24"
                                  fontWeight={400}
                                />
                              </Box>
                            )}
                        </Grid>

                        <Grid item>
                          <MediumTypography
                            labelId="NumberOfLaborsLabelText"
                            defaultLabel="Number of Labors"
                            sxProps={{ marginBottom: "10px" }}
                            textColor={"rgba(159, 173, 188, 1)"}
                          />
                          <TextInput
                            labelId="NumberOfLaborsLabelText"
                            defaultLabelId="Number of Labors"
                            borderColor="1px solid rgba(166, 197, 226, 0.16)"
                            variant="outlined"
                            Value={
                              values.cleaningInfoBean.laboursAllocated !== 0 &&
                              values.cleaningInfoBean.laboursAllocated !== null
                                ? values.cleaningInfoBean.laboursAllocated.toString()
                                : ""
                            }
                            type={"number"}
                            handlechange={(value: string) => {
                              setFieldValue(
                                "cleaningInfoBean.laboursAllocated",
                                value
                              );
                            }}
                            inputProps={{
                              maxLength: 10,
                              readOnly: !values.isEditCard,
                            }}
                            textColor={
                              values.isEditCard
                                ? "rgba(255, 255, 255, 1)"
                                : "rgba(140, 155, 171, 1)"
                            }
                            backGroundColor="#22272B"
                            fieldheight="40px"
                            className="textInputWidth"
                          />
                        </Grid>
                        {values.cleaningInfoBean.selectedCleaningTypeCode ===
                          "Dry" && (
                          <Grid item>
                            <MediumTypography
                              labelId="RobotsAvailableAutoLabelText"
                              defaultLabel="No of robots available (Auto)"
                              sxProps={{ marginBottom: "10px" }}
                              textColor={"rgba(159, 173, 188, 1)"}
                            />
                            <TextInput
                              labelId="RobotsAvailableAutoLabelText"
                              defaultLabelId="No of robots available (Auto)"
                              borderColor="1px solid rgba(166, 197, 226, 0.16)"
                              variant="outlined"
                              backGroundColor="#22272B"
                              Value={String(
                                values.cleaningInfoBean.robotsAvailableAutomatic
                              )}
                              handlechange={(value: string) => {
                                setFieldValue(
                                  "cleaningInfoBean.robotsAvailableAutomatic",
                                  value
                                );
                              }}
                              fieldheight="40px"
                              className="textInputWidth"
                              inputProps={{ readOnly: !values.isEditCard }}
                              textColor={
                                values.isEditCard
                                  ? "rgba(255, 255, 255, 1)"
                                  : "rgba(140, 155, 171, 1)"
                              }
                            />
                          </Grid>
                        )}
                        {values.cleaningInfoBean.selectedCleaningTypeCode ===
                          "Wet" && (
                          <Grid item>
                            <MediumTypography
                              labelId="NoOfTankersLabelText"
                              defaultLabel="No. of Tankers"
                              sxProps={{ marginBottom: "10px" }}
                              textColor={"rgba(159, 173, 188, 1)"}
                            />
                            <TextInput
                              labelId="NoOfTankersLabelText"
                              defaultLabelId="No. of Tankers"
                              borderColor="1px solid rgba(166, 197, 226, 0.16)"
                              variant="outlined"
                              backGroundColor="#22272B"
                              Value={String(values.cleaningInfoBean.tankers)}
                              handlechange={(value: string) => {
                                setFieldValue(
                                  "cleaningInfoBean.tankers",
                                  value
                                );
                              }}
                              fieldheight="40px"
                              className="textInputWidth"
                              inputProps={{ readOnly: !values.isEditCard }}
                              textColor={
                                values.isEditCard
                                  ? "rgba(255, 255, 255, 1)"
                                  : "rgba(140, 155, 171, 1)"
                              }
                            />
                          </Grid>
                        )}
                      </Grid>
                      <Grid
                        container
                        direction={"row"}
                        className="justifyContentSpaceBtw"
                        alignItems={"center"}
                        rowSpacing={"16px"}
                        columnSpacing={"40px"}
                        sx={{ marginBottom: "20px" }}
                      >
                        {values.cleaningInfoBean.selectedCleaningTypeCode ===
                          "Dry" && (
                          <Grid item>
                            <MediumTypography
                              labelId="RobotsAvailableSemiAutoLabelText"
                              defaultLabel="No of robots available (Semi Auto)"
                              sxProps={{ marginBottom: "10px" }}
                              textColor={"rgba(159, 173, 188, 1)"}
                            />
                            <TextInput
                              labelId="RobotsAvailableSemiAutoLabelText"
                              defaultLabelId="No of robots available (Semi Auto)"
                              borderColor="1px solid rgba(166, 197, 226, 0.16)"
                              variant="outlined"
                              Value={String(
                                values.cleaningInfoBean
                                  .robotsAvailableSemiAutomatic
                              )}
                              handlechange={(value: string) => {
                                setFieldValue(
                                  "cleaningInfoBean.robotsAvailableSemiAutomatic",
                                  value
                                );
                              }}
                              backGroundColor="#22272B"
                              fieldheight="40px"
                              className="textInputWidth"
                              inputProps={{ readOnly: !values.isEditCard }}
                              textColor={
                                values.isEditCard
                                  ? "rgba(255, 255, 255, 1)"
                                  : "rgba(140, 155, 171, 1)"
                              }
                            />
                          </Grid>
                        )}
                        {values.cleaningInfoBean.selectedCleaningTypeCode ===
                          "Wet" && (
                          <Grid item>
                            <MediumTypography
                              labelId="TankerCapacityinLitresLabelText"
                              defaultLabel="Tanker Capacity (Litres)"
                              sxProps={{ marginBottom: "10px" }}
                              textColor={"rgba(159, 173, 188, 1)"}
                            />
                            <TextInput
                              labelId="TankerCapacityinLitresLabelText"
                              defaultLabelId="Tanker Capacity (Litres)"
                              borderColor="1px solid rgba(166, 197, 226, 0.16)"
                              variant="outlined"
                              Value={String(
                                values.cleaningInfoBean.tankerCapacity
                              )}
                              handlechange={(value: string) => {
                                setFieldValue(
                                  "cleaningInfoBean.tankerCapacity",
                                  value
                                );
                              }}
                              backGroundColor="#22272B"
                              fieldheight="40px"
                              className="textInputWidth"
                              inputProps={{ readOnly: !values.isEditCard }}
                              textColor={
                                values.isEditCard
                                  ? "rgba(255, 255, 255, 1)"
                                  : "rgba(140, 155, 171, 1)"
                              }
                            />
                          </Grid>
                        )}
                        {values.cleaningInfoBean.selectedCleaningTypeCode ===
                          "Dry" && (
                          <Grid item>
                            <MediumTypography
                              labelId="RobotsFunctionalAutomaticLabelText"
                              defaultLabel="No of robots Functional (Automatic)"
                              sxProps={{ marginBottom: "10px" }}
                              textColor={"rgba(159, 173, 188, 1)"}
                            />
                            <TextInput
                              labelId="RobotsFunctionalAutomaticLabelText"
                              defaultLabelId="No of robots Functional (Automatic)"
                              borderColor="1px solid rgba(166, 197, 226, 0.16)"
                              variant="outlined"
                              Value={String(
                                values.cleaningInfoBean
                                  .robotsFunctionalAutomatic
                              )}
                              handlechange={(value: string) => {
                                setFieldValue(
                                  "cleaningInfoBean.robotsFunctionalAutomatic",
                                  value
                                );
                              }}
                              backGroundColor="#22272B"
                              fieldheight="40px"
                              className="textInputWidth"
                              inputProps={{ readOnly: !values.isEditCard }}
                              textColor={
                                values.isEditCard
                                  ? "rgba(255, 255, 255, 1)"
                                  : "rgba(140, 155, 171, 1)"
                              }
                            />
                          </Grid>
                        )}
                        {values.cleaningInfoBean.selectedCleaningTypeCode ===
                          "Wet" && (
                          <Grid item>
                            <MediumTypography
                              labelId="WaterConsumedLabelText"
                              defaultLabel="Water Consumed (Liters)"
                              sxProps={{ marginBottom: "10px" }}
                              textColor={"rgba(159, 173, 188, 1)"}
                            />
                            <TextInput
                              labelId="WaterConsumedLabelText"
                              defaultLabelId="Water Consumed (Liters)"
                              borderColor="1px solid rgba(166, 197, 226, 0.16)"
                              variant="outlined"
                              Value={String(
                                values.cleaningInfoBean.waterConsumed
                              )}
                              handlechange={(value: string) => {
                                setFieldValue(
                                  "cleaningInfoBean.waterConsumed",
                                  value
                                );
                              }}
                              backGroundColor="#22272B"
                              fieldheight="40px"
                              className="textInputWidth"
                              inputProps={{ readOnly: !values.isEditCard }}
                              textColor={
                                values.isEditCard
                                  ? "rgba(255, 255, 255, 1)"
                                  : "rgba(140, 155, 171, 1)"
                              }
                            />
                          </Grid>
                        )}
                        {values.cleaningInfoBean.selectedCleaningTypeCode ===
                          "Dry" && (
                          <Grid item>
                            <MediumTypography
                              labelId="RobotsFunctionalSemiAutoLabelText"
                              defaultLabel="No of robots Functional (Semi Auto)"
                              sxProps={{ marginBottom: "10px" }}
                              textColor={"rgba(159, 173, 188, 1)"}
                            />
                            <TextInput
                              labelId="RobotsFunctionalSemiAutoLabelText"
                              defaultLabelId="No of robots Functional (Semi Auto)"
                              borderColor="1px solid rgba(166, 197, 226, 0.16)"
                              variant="outlined"
                              Value={String(
                                values.cleaningInfoBean
                                  .robotsFunctionalSemiAutomatic
                              )}
                              handlechange={(value: string) => {
                                setFieldValue(
                                  "cleaningInfoBean.robotsFunctionalSemiAutomatic",
                                  value
                                );
                              }}
                              backGroundColor="#22272B"
                              fieldheight="40px"
                              className="textInputWidth"
                              inputProps={{ readOnly: !values.isEditCard }}
                              textColor={
                                values.isEditCard
                                  ? "rgba(255, 255, 255, 1)"
                                  : "rgba(140, 155, 171, 1)"
                              }
                            />
                          </Grid>
                        )}
                        {values.cleaningInfoBean.selectedCleaningTypeCode ===
                          "Wet" && (
                          <Grid item>
                            <MediumTypography
                              labelId="TDSLabelText"
                              defaultLabel="TDS level (ppm)"
                              sxProps={{ marginBottom: "10px" }}
                              textColor={"rgba(159, 173, 188, 1)"}
                            />
                            <TextInput
                              labelId="TDSLabelText"
                              defaultLabelId="TDS level (ppm)"
                              borderColor="1px solid rgba(166, 197, 226, 0.16)"
                              variant="outlined"
                              Value={String(values.cleaningInfoBean.tdsLevel)}
                              handlechange={(value: string) => {
                                setFieldValue(
                                  "cleaningInfoBean.tdsLevel",
                                  value
                                );
                              }}
                              backGroundColor="#22272B"
                              fieldheight="40px"
                              className="textInputWidth"
                              inputProps={{ readOnly: !values.isEditCard }}
                              textColor={
                                values.isEditCard
                                  ? "rgba(255, 255, 255, 1)"
                                  : "rgba(140, 155, 171, 1)"
                              }
                            />
                          </Grid>
                        )}

                        <Grid item>
                          <MediumTypography
                            labelId="NumberOfWorkingHoursLabelText"
                            defaultLabel="No.of Working Hours"
                            sxProps={{ marginBottom: "10px" }}
                            textColor={"rgba(159, 173, 188, 1)"}
                          />
                          <Box
                            className="flex__"
                            sx={{
                              position: "relative",
                              alignItems: "center",
                            }}
                          >
                            <TextInput
                              labelId="NumberOfWorkingHoursLabelText"
                              defaultLabelId="No.of Working Hours"
                              borderColor="1px solid rgba(166, 197, 226, 0.16)"
                              variant="outlined"
                              Value={convertMinutesToHoursAndMinutes(
                                values.workOrderInfo?.numberOfWorkingHours
                              )}
                              type="text"
                              handlechange={(value: string) => {
                                const minutes =
                                  convertHoursAndMinutesToMinutes(value);
                                setFieldValue(
                                  "workOrderInfo.numberOfWorkingHours",
                                  minutes
                                );
                              }}
                              textColor={
                                values.isEditCard
                                  ? "rgba(255, 255, 255, 1)"
                                  : "rgba(140, 155, 171, 1)"
                              }
                              inputProps={{
                                maxLength: 10,
                                readOnly: !values.isEditCard,
                              }}
                              backGroundColor="#22272B"
                              fieldheight="40px"
                              className="textInputWidth"
                            />

                            <Box
                              sx={{
                                padding: "4px 8px 4px 8px",
                                backgroundColor: "#191E22",
                                border: "1px solid #374048",
                                height: "24px",
                                borderRadius: "4px",
                                right: "8px",
                                position: "absolute",
                              }}
                            >
                              <MediumTypography
                                label="( hh:mm )"
                                sxProps={{
                                  lineHeight: "normal",
                                  fontSize: "12px",
                                }}
                              />
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item>
                          <MediumTypography
                            labelId="cleaningCompletionLabelText"
                            defaultLabel="Cleaning Completion "
                            sxProps={{ marginBottom: "10px" }}
                            textColor={"rgba(159, 173, 188, 1)"}
                          />
                          <TextInput
                            labelId="cleaningCompletionLabelText"
                            defaultLabelId="Cleaning Completion"
                            borderColor="1px solid rgba(166, 197, 226, 0.16)"
                            variant="outlined"
                            Value={
                              String(
                                parseFloat(
                                  values.workOrderInfo.cleaningCompletion || "0"
                                ).toFixed(2)
                              ) + "%"
                            }
                            type="text"
                            handlechange={(value: string) => {
                              let formattedValue = value.replace(
                                /[^0-9.%]/g,
                                ""
                              );

                              // Then, format the string to match the pattern "up to 4 digits, optional decimal, up to 2 digits after the decimal"
                              formattedValue = formattedValue.replace(
                                /^(\d{0,4})(\.\d{0,2})?.*$/,
                                (match, p1, p2) => p1 + (p2 || "") // p1 is the first group (digits), p2 is the second group (decimal and digits)
                              );
                              setFieldValue(
                                "workOrderInfo.cleaningCompletion",
                                formattedValue
                              );
                            }}
                            textColor={
                              values.isEditCard
                                ? "rgba(255, 255, 255, 1)"
                                : "rgba(140, 155, 171, 1)"
                            }
                            inputProps={{
                              maxLength: 10,
                              readOnly: !values.isEditCard,
                            }}
                            backGroundColor="#22272B"
                            fieldheight="40px"
                            className="textInputWidth"
                          />
                        </Grid>
                      </Grid>

                      <Grid container sx={{ marginBottom: "20px" }}>
                        <Grid xs={11.5} lg={11.5} md={11.5}>
                          <MediumTypography
                            labelId="Revoke.RemarksText"
                            defaultLabel="Remarks"
                            sxProps={{ marginBottom: "10px" }}
                            textColor={"rgba(159, 173, 188, 1)"}
                          />
                          <TextField
                            variant="outlined"
                            sx={{
                              backgroundColor: "#22272B",
                              borderRadius: "4px",
                              border: "1px solid rgba(166, 197, 226, 0.16)",
                              "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                  borderColor: "#1D2226",
                                },

                                "&:hover fieldset": {
                                  borderColor: "#1D2226",
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: "#1D2226",
                                },
                                "& input": {
                                  color: values.isEditCard
                                    ? "rgba(255, 255, 255, 1)"
                                    : "rgba(140, 155, 171, 1)", // Text color inside the input
                                },
                                // "& input": {
                                //   color: "#fff", // Text color inside the input
                                // },
                              },
                              "& .MuiInputLabel-root": {
                                color: "#bbb", // Placeholder color
                              },
                            }}
                            minRows={10}
                            fullWidth
                            value={message}
                            onChange={(event) => {
                              setMessage(event.target.value.trimStart());
                              setFieldValue(
                                "cleaningInfoBean.remarks",
                                event.target.value.trimStart()
                              );
                            }}
                            inputProps={{ readOnly: !values.isEditCard }}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                    <Grid container className="mt-xs">
                      <Grid item xs={12} lg={12} md={12} xl={12}>
                        <Box>
                          <Divider
                            variant="fullWidth"
                            sx={{
                              borderTop: "1px solid #333b43",
                            }}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                    <Box sx={{ padding: "24px 0px 24px 0px" }}>
                      <Box sx={{ marginBottom: "20px" }}>
                        <Box>
                          <MediumTypography
                            labelId="AdditionalInfoLaabelText"
                            defaultLabel="Additional Info"
                            fontSize="20px"
                            fontWeight={500}
                            textColor="#FFFFFF"
                          />
                        </Box>
                      </Box>
                      <Grid
                        container
                        direction={"row"}
                        className="justifyContentSpaceBtw"
                        alignItems={"center"}
                        rowSpacing={"16px"}
                        columnSpacing={"40px"}
                        sx={{ marginBottom: "20px" }}
                      >
                        <Grid item>
                          <MediumTypography
                            labelId="CycleCountIncrementLabelText"
                            defaultLabel="Cycle Count Increment"
                            sxProps={{ marginBottom: "10px" }}
                            textColor={"rgba(159, 173, 188, 1)"}
                          />
                          <TextInput
                            labelId="CycleCountIncrementLabelText"
                            defaultLabelId="Cycle Count Increment"
                            borderColor="1px solid rgba(166, 197, 226, 0.16)"
                            variant="outlined"
                            Value={
                              values.additionalInfoBean.cycleCountInc !==
                                null &&
                              values.additionalInfoBean.cycleCountInc !== 0
                                ? String(
                                    values.additionalInfoBean.cycleCountInc
                                  )
                                : ""
                            }
                            handlechange={(value: string) => {
                              setFieldValue(
                                "additionalInfoBean.cycleCountInc",
                                value
                              );
                            }}
                            type={"number"}
                            backGroundColor="#22272B"
                            fieldheight="40px"
                            className="textInputWidth"
                            inputProps={{ readOnly: true }}
                            textColor={"rgba(140, 155, 171, 1)"}
                          />
                        </Grid>
                        <Grid item>
                          <MediumTypography
                            labelId="CumulativeCycleCountLabelText"
                            defaultLabel="Cumulative Cycle Count"
                            sxProps={{ marginBottom: "10px" }}
                            textColor={"rgba(159, 173, 188, 1)"}
                          />
                          <TextInput
                            labelId="CumulativeCycleCountLabelText"
                            defaultLabelId="Cumulative Cycle Count"
                            borderColor="1px solid rgba(166, 197, 226, 0.16)"
                            variant="outlined"
                            Value={
                              values.additionalInfoBean.cumulativeCycleCount !==
                                null &&
                              values.additionalInfoBean.cumulativeCycleCount !==
                                0
                                ? String(
                                    values.additionalInfoBean
                                      .cumulativeCycleCount
                                  )
                                : ""
                            }
                            handlechange={(value: string) => {
                              setFieldValue(
                                "additionalInfoBean.cumulativeCycleCount",
                                value
                              );
                            }}
                            type={"number"}
                            backGroundColor="#22272B"
                            fieldheight="40px"
                            className="textInputWidth"
                            inputProps={{ readOnly: true }}
                            textColor={"rgba(140, 155, 171, 1)"}
                          />
                        </Grid>

                        <Grid item>
                          <MediumTypography
                            labelId="RainFallLabelText"
                            defaultLabel="Rainfall (mm)"
                            sxProps={{ marginBottom: "10px" }}
                            textColor={"rgba(159, 173, 188, 1)"}
                          />
                          <TextInput
                            labelId="RainFallLabelText"
                            defaultLabelId="Rainfall"
                            Value={
                              values.additionalInfoBean.rainFall !== null &&
                              values.additionalInfoBean.rainFall !== 0
                                ? String(values.additionalInfoBean.rainFall)
                                : ""
                            }
                            handlechange={(value: string) => {
                              setFieldValue(
                                "additionalInfoBean.rainFall",
                                value
                              );
                            }}
                            borderColor="1px solid rgba(166, 197, 226, 0.16)"
                            variant="outlined"
                            type={"number"}
                            backGroundColor="#22272B"
                            fieldheight="40px"
                            className="textInputWidth"
                            inputProps={{ readOnly: !values.isEditCard }}
                            textColor={
                              values.isEditCard
                                ? "rgba(255, 255, 255, 1)"
                                : "rgba(140, 155, 171, 1)"
                            }
                          />
                        </Grid>
                        <Grid item>
                          <MediumTypography
                            labelId="GrassImpactLabelText"
                            defaultLabel="Grass Impact"
                            sxProps={{ marginBottom: "10px" }}
                            textColor={"rgba(159, 173, 188, 1)"}
                          />
                          <RadioComponent
                            name="multipleBirth"
                            value={values.additionalInfoBean.grassImpact}
                            handleChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              setFieldValue(
                                "additionalInfoBean.grassImpact",
                                e.target.value
                              );
                            }}
                            labelid=""
                            defaultlabelid=""
                            labelidA="YesText"
                            defaultlabelidA="Yes"
                            labelidB="NoText"
                            defaultlabelidB="No"
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        direction={"row"}
                        className="justifyContentSpaceBtw"
                        // alignItems={"center"}
                        rowSpacing={"16px"}
                        columnSpacing={"40px"}
                        sx={{ marginBottom: "20px" }}
                      >
                        <Grid item>
                          <MediumTypography
                            labelId="VendorLabelText"
                            defaultLabel="Vendor"
                            sxProps={{ marginBottom: "10px" }}
                            textColor={"rgba(159, 173, 188, 1)"}
                          />

                          <DropdownComponent
                            names={vendorList}
                            value={
                              values.additionalInfoBean.vendorId === 0 ||
                              values.additionalInfoBean.vendorId === null
                                ? ""
                                : values.additionalInfoBean.vendorId.toString()
                            }
                            labelid="VendoeSelectionLabel"
                            defaultlabelid="--Select Vendor--"
                            textColor={
                              values.isEditCard
                                ? "rgba(255, 255, 255, 1)"
                                : "rgba(140, 155, 171, 1)"
                            }
                            defaultValue={
                              values.additionalInfoBean.vendorId === 0
                                ? vendorList[0]?.id
                                : values.additionalInfoBean.vendorId?.toString()
                            }
                            disabled={!values.isEditCard}
                            handleChange={(value: string) => {
                              setFieldValue(
                                "additionalInfoBean.vendorId",
                                value
                              );
                            }}
                            className="textInputWidth"
                          />
                          {errors.additionalInfoBean?.vendorId &&
                            touched.additionalInfoBean?.vendorId && (
                              <Box
                                className="loginError"
                                sx={{ position: "relative" }}
                              >
                                <Box>
                                  <ErrorIcon />
                                </Box>
                                <MediumTypography
                                  labelId={errors.additionalInfoBean?.vendorId}
                                  defaultLabel="Vendor is required"
                                  fontSize="12px"
                                  textColor="#AE2E24"
                                  fontWeight={400}
                                />
                              </Box>
                            )}
                        </Grid>
                        <Grid item>
                          <MediumTypography
                            labelId="ContractLineItemLabelText"
                            defaultLabel="Contract Line Item"
                            sxProps={{ marginBottom: "10px" }}
                            textColor={"rgba(159, 173, 188, 1)"}
                          />
                          <TextInput
                            labelId="ContractLineItemLabelText"
                            defaultLabelId="Contract Line Item"
                            borderColor="1px solid rgba(166, 197, 226, 0.16)"
                            variant="outlined"
                            Value={String(
                              values.additionalInfoBean.contractLineItem
                            )}
                            handlechange={(value: string) => {
                              setFieldValue(
                                "additionalInfoBean.contractLineItem",
                                value
                              );
                            }}
                            type={"number"}
                            backGroundColor="#22272B"
                            fieldheight="40px"
                            className="textInputWidth"
                            inputProps={{ readOnly: !values.isEditCard }}
                            textColor={
                              values.isEditCard
                                ? "rgba(255, 255, 255, 1)"
                                : "rgba(140, 155, 171, 1)"
                            }
                          />
                        </Grid>
                      </Grid>
                      <Grid container className="mt-xs">
                        <Grid item xs={12} lg={12} md={12} xl={12}>
                          <Box>
                            <Divider
                              variant="fullWidth"
                              sx={{
                                borderTop: "1px solid #333b43",
                              }}
                            />
                          </Box>
                        </Grid>
                      </Grid>

                      <WorkOrderAssignees
                        leadAssignee={leadAssignee}
                        assignees={assignees}
                      />

                      <Grid container className="mt-xs">
                        <Grid item xs={12} lg={12} md={12} xl={12}>
                          <Box>
                            <Divider
                              variant="fullWidth"
                              sx={{
                                borderTop: "1px solid #333b43",
                              }}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                      <Box className="mt-md mb-lg ">
                        <MediumTypography
                          labelId="ApproverText"
                          defaultLabel="Approver"
                          fontSize="20px"
                          fontWeight={500}
                          textColor="#FFFFFF"
                          className="mb-md"
                        />
                        <Box className="flex__">
                          {approver1 !== undefined && (
                            <Box
                              className="flex__"
                              sx={{
                                width: "146px",
                                height: "56px",
                                borderRadius: "4px",
                                border: "1px solid rgba(166, 197, 226, 0.16)",
                                backgroundColor: "#22272B",
                                marginRight: "16px",
                              }}
                            >
                              <>
                                {approver1 !== undefined &&
                                  approver1 !== null && (
                                    <React.Fragment>
                                      <Box
                                        className="flex__ align__items__center ml-sm"
                                        sx={{ position: "relative" }}
                                      >
                                        <Avatar
                                          src={approver1.image}
                                          alt="abc"
                                          style={{
                                            width: "32px",
                                            height: "32px",
                                          }}
                                        />
                                      </Box>
                                      <Box className="pt-sm">
                                        <Box className="flex__justify__space-between">
                                          <Box
                                            sx={{
                                              width: "100px",
                                              marginTop: "4px",
                                            }}
                                          >
                                            <Tooltip
                                              title={
                                                approver1.name &&
                                                approver1.name.length > 12
                                                  ? approver1.name
                                                  : ""
                                              }
                                              arrow={true}
                                              followCursor
                                            >
                                              <Box>
                                                <MediumTypography
                                                  label={approver1.name}
                                                  className="ml-sm ellipsis"
                                                  fontSize="12px"
                                                  textColor="#FFFFFF"
                                                  sxProps={{
                                                    width:
                                                      approver1.name.length > 12
                                                        ? "100px"
                                                        : "auto",
                                                  }}
                                                />
                                              </Box>
                                            </Tooltip>
                                            <Tooltip
                                              title={
                                                approver1.roleName &&
                                                approver1.roleName.length > 12
                                                  ? approver1.roleName
                                                  : ""
                                              }
                                              arrow={true}
                                              followCursor
                                            >
                                              <Box>
                                                <MediumTypography
                                                  label={approver1.roleName}
                                                  className=" ml-sm ellipsis"
                                                  textColor="rgba(255, 255, 255, 0.64)"
                                                  fontSize="10px"
                                                  fontWeight={400}
                                                  sxProps={{
                                                    width:
                                                      approver1.roleName
                                                        .length > 12
                                                        ? "100px"
                                                        : "auto",
                                                    marginTop:
                                                      approver1.name === ""
                                                        ? "8px"
                                                        : "0px",
                                                  }}
                                                />
                                              </Box>
                                            </Tooltip>
                                          </Box>
                                        </Box>
                                      </Box>
                                    </React.Fragment>
                                  )}
                                {approver1 !== undefined &&
                                  approver1 === null && (
                                    <React.Fragment>
                                      <Box
                                        className="flex__ align__items__center ml-sm"
                                        sx={{ position: "relative" }}
                                      >
                                        <Avatar
                                          src={""}
                                          alt="No approver"
                                          style={{
                                            width: "32px",
                                            height: "32px",
                                          }}
                                        />
                                      </Box>
                                      <Box className=" pt-sm">
                                        <Box className="flex__justify__space-between">
                                          <Box>
                                            <MediumTypography
                                              label={""}
                                              className="ml-xs ellipsis"
                                              fontSize="12px"
                                              textColor="#FFFFFF"
                                            />
                                            <MediumTypography
                                              label={""}
                                              className=" ml-xs ellipsis"
                                              textColor="rgba(255, 255, 255, 0.64)"
                                              fontSize="10px"
                                              fontWeight={400}
                                            />
                                          </Box>
                                        </Box>
                                      </Box>
                                    </React.Fragment>
                                  )}
                              </>
                            </Box>
                          )}

                          {approver2 !== undefined && (
                            <Box
                              className="flex__"
                              sx={{
                                width: "146px",
                                height: "56px",
                                borderRadius: "4px",
                                border: "1px solid rgba(166, 197, 226, 0.16)",
                                backgroundColor: "#22272B",
                                marginRight: "16px",
                              }}
                            >
                              {approver2 !== undefined &&
                                approver2 !== null && (
                                  <React.Fragment>
                                    <Box
                                      className="flex__ align__items__center ml-sm"
                                      sx={{ position: "relative" }}
                                    >
                                      <Avatar
                                        src={approver2.image}
                                        alt="abc"
                                        style={{
                                          width: "32px",
                                          height: "32px",
                                        }}
                                      />
                                    </Box>
                                    <Box className="pt-sm">
                                      <Box className="flex__justify__space-between">
                                        <Box
                                          sx={{
                                            width: "100px",
                                            marginTop: "4px",
                                          }}
                                        >
                                          <Tooltip
                                            title={
                                              approver2.name &&
                                              approver2.name.length > 12
                                                ? approver2.name
                                                : ""
                                            }
                                            arrow={true}
                                            followCursor
                                          >
                                            <Box>
                                              <MediumTypography
                                                label={approver2.name}
                                                className="ml-sm ellipsis"
                                                fontSize="12px"
                                                textColor="#FFFFFF"
                                                sxProps={{
                                                  width:
                                                    approver2.name.length > 12
                                                      ? "100px"
                                                      : "auto",
                                                }}
                                              />
                                            </Box>
                                          </Tooltip>
                                          <Tooltip
                                            title={
                                              approver2.roleName &&
                                              approver2.roleName.length > 12
                                                ? approver2.roleName
                                                : ""
                                            }
                                            arrow={true}
                                            followCursor
                                          >
                                            <Box>
                                              <MediumTypography
                                                label={approver2.roleName}
                                                className=" ml-sm ellipsis"
                                                textColor="rgba(255, 255, 255, 0.64)"
                                                fontSize="10px"
                                                fontWeight={400}
                                                sxProps={{
                                                  width:
                                                    approver2.roleName.length >
                                                    12
                                                      ? "100px"
                                                      : "auto",
                                                  marginTop:
                                                    approver2.name === ""
                                                      ? "8px"
                                                      : "0px",
                                                }}
                                              />
                                            </Box>
                                          </Tooltip>
                                        </Box>
                                      </Box>
                                    </Box>
                                  </React.Fragment>
                                )}
                              {approver2 !== undefined &&
                                approver2 === null && (
                                  <React.Fragment>
                                    <Box
                                      className="flex__ align__items__center ml-sm"
                                      sx={{ position: "relative" }}
                                    >
                                      <Avatar
                                        src={""}
                                        alt="No approver"
                                        style={{
                                          width: "32px",
                                          height: "32px",
                                        }}
                                      />
                                    </Box>
                                    <Box className=" pt-sm">
                                      <Box className="flex__justify__space-between">
                                        <Box>
                                          <MediumTypography
                                            label={""}
                                            className="ml-xs ellipsis"
                                            fontSize="12px"
                                            textColor="#FFFFFF"
                                          />
                                          <MediumTypography
                                            label={""}
                                            className=" ml-xs ellipsis"
                                            textColor="rgba(255, 255, 255, 0.64)"
                                            fontSize="10px"
                                            fontWeight={400}
                                          />
                                        </Box>
                                      </Box>
                                    </Box>
                                  </React.Fragment>
                                )}
                            </Box>
                          )}

                          {approver3 !== undefined && (
                            <Box
                              className="flex__"
                              sx={{
                                width: "146px",
                                height: "56px",
                                borderRadius: "4px",
                                border: "1px solid rgba(166, 197, 226, 0.16)",
                                backgroundColor: "#22272B",
                                marginRight: "16px",
                              }}
                            >
                              {approver3 !== undefined &&
                                approver3 !== null && (
                                  <React.Fragment>
                                    <Box
                                      className="flex__ align__items__center ml-sm"
                                      sx={{ position: "relative" }}
                                    >
                                      <Avatar
                                        src={approver3.image}
                                        alt="abc"
                                        style={{
                                          width: "32px",
                                          height: "32px",
                                        }}
                                      />
                                    </Box>
                                    <Box className="pt-sm">
                                      <Box className="flex__justify__space-between">
                                        <Box
                                          sx={{
                                            width: "100px",
                                            marginTop: "4px",
                                          }}
                                        >
                                          <Tooltip
                                            title={
                                              approver3.name &&
                                              approver3.name.length > 12
                                                ? approver3.name
                                                : ""
                                            }
                                            arrow={true}
                                            followCursor
                                          >
                                            <Box>
                                              <MediumTypography
                                                label={approver3.name}
                                                className="ml-sm ellipsis"
                                                fontSize="12px"
                                                textColor="#FFFFFF"
                                                sxProps={{
                                                  width:
                                                    approver3.name.length > 12
                                                      ? "100px"
                                                      : "auto",
                                                }}
                                              />
                                            </Box>
                                          </Tooltip>
                                          <Tooltip
                                            title={
                                              approver3.roleName &&
                                              approver3.roleName.length > 12
                                                ? approver3.roleName
                                                : ""
                                            }
                                            arrow={true}
                                            followCursor
                                          >
                                            <Box>
                                              <MediumTypography
                                                label={approver3.roleName}
                                                className=" ml-sm ellipsis"
                                                textColor="rgba(255, 255, 255, 0.64)"
                                                fontSize="10px"
                                                fontWeight={400}
                                                sxProps={{
                                                  width:
                                                    approver3.roleName.length >
                                                    12
                                                      ? "100px"
                                                      : "auto",
                                                  marginTop:
                                                    approver3.name === ""
                                                      ? "8px"
                                                      : "0px",
                                                }}
                                              />
                                            </Box>
                                          </Tooltip>
                                        </Box>
                                      </Box>
                                    </Box>
                                  </React.Fragment>
                                )}{" "}
                              {approver3 !== undefined &&
                                approver3 === null && (
                                  <React.Fragment>
                                    <Box
                                      className="flex__ align__items__center ml-sm"
                                      sx={{ position: "relative" }}
                                    >
                                      <Avatar
                                        src={""}
                                        alt="No approver"
                                        style={{
                                          width: "32px",
                                          height: "32px",
                                        }}
                                      />
                                    </Box>
                                    <Box className=" pt-sm">
                                      <Box className="flex__justify__space-between">
                                        <Box>
                                          <MediumTypography
                                            label={""}
                                            className="ml-xs ellipsis"
                                            fontSize="12px"
                                            textColor="#FFFFFF"
                                          />
                                          <MediumTypography
                                            label={""}
                                            className=" ml-xs ellipsis"
                                            textColor="rgba(255, 255, 255, 0.64)"
                                            fontSize="10px"
                                            fontWeight={400}
                                          />
                                        </Box>
                                      </Box>
                                    </Box>
                                  </React.Fragment>
                                )}
                            </Box>
                          )}
                        </Box>
                      </Box>
                      <Grid container className="mt-xs">
                        <Grid item xs={12} lg={12} md={12} xl={12}>
                          <Box>
                            <Divider
                              variant="fullWidth"
                              sx={{
                                borderTop: "1px solid #333b43",
                              }}
                            />
                          </Box>
                        </Grid>
                      </Grid>

                      <WorkOrderGallery
                        dumgImage={dumgImage}
                        beforeImage={beforeImage}
                        afterImage={afterImage}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Grid container className="mt-xs">
                      <Grid item xs={12} lg={12} md={12} xl={12}>
                        <Box>
                          <Divider
                            variant="fullWidth"
                            sx={{
                              borderTop: "1px solid #333b43",
                            }}
                          />
                        </Box>
                      </Grid>
                    </Grid>

                    <WorkOrderActivity
                      isVisible={isVisible}
                      cardId={JSON.parse(navState?.cardId)}
                    />
                  </Grid>
                </Grid>
              );
            }}
          </Formik>
        </Box>
      </Box>
    </Box>
  );
};

export default ModuleCleaningComponent;
