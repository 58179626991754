// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.no-marker {
  list-style: none;
  padding-left: 0;
}
ul {
  position: relative;
}
.listItem {
  padding-left: 30px; /* Adjust this value based on your design */
}
.listItem:before {
  content: "";
  position: absolute;
  /* top: 0px; */
  bottom: 5px;
  left: 11px; /* Adjust this value based on your design */
  /* border-left: 2px solid #626262;  */
  height: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/formlib/TreeDropDown.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,eAAe;AACjB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,kBAAkB,EAAE,2CAA2C;AACjE;AACA;EACE,WAAW;EACX,kBAAkB;EAClB,cAAc;EACd,WAAW;EACX,UAAU,EAAE,2CAA2C;EACvD,qCAAqC;EACrC,YAAY;AACd","sourcesContent":[".no-marker {\n  list-style: none;\n  padding-left: 0;\n}\nul {\n  position: relative;\n}\n.listItem {\n  padding-left: 30px; /* Adjust this value based on your design */\n}\n.listItem:before {\n  content: \"\";\n  position: absolute;\n  /* top: 0px; */\n  bottom: 5px;\n  left: 11px; /* Adjust this value based on your design */\n  /* border-left: 2px solid #626262;  */\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
