import {
  Box,
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";
import React, {
  useState,
  useRef,
  useEffect,
  ChangeEvent,
  useContext,
} from "react";
import MediumTypography from "../../components/formlib/MediumTypography";
import useAvailableHeight from "../../utils/useAvailableHeight";
import CustomDropdown from "../../components/formlib/CustomDropdown";
import { getOptions, handleError } from "../../utils/commonFunctions";
import { useLocation } from "react-router-dom";
import ButtonComponent from "../../components/formlib/ButtonComponent";
import { ReactComponent as CollpaseLeftIcon } from "../../assets/images/collpaseLeftIcon.svg";
import "./WizardScreen.css";
import SiteDetailsComponent from "./siteDeatilsScreen/SiteDetailsComponent";
import { ReactComponent as Upload } from "../../assets/images/UploadIcon.svg";
import { ReactComponent as Download } from "../../assets/images/DownloadIcon.svg";
import { WORK_FLOW_BASE_URL } from "../../api/Constant";
import { LoaderContext, LoaderContextType } from "../../layouts/appSidebar";
import ErrorModal from "../../components/formlib/modal/ErrorModal";
import ValidationErrorModal from "../../components/formlib/modal/ValidationErrorModal";
import {
  fetchWizardsMenuMetaDataApi,
  uploadWizardsCSVFile,
  validateWizradsCSVFileApi,
} from "../../api/wizardsApi/Common";
import SubgroupDetailsComponent from "./subgroupDetailScreen/SubgroupDetailsComponent";
import AssetGroupWizards from "./assetGroup/AssetGroupWizards";
import { DynamicMenuTypes } from "./wizrads.types";

import { ReactComponent as DownArrow } from "../../assets/images/DownArrowColoured.svg"; // Small circle icon for items
import { ReactComponent as Subgroup } from "../../assets/images/subgroup.svg";
import { ReactComponent as SubMenu } from "../../assets/images/submenu.svg";
import { ReactComponent as Round } from "../../assets/images/round.svg";
import { set } from "lodash";

const styles = {
  container: {
    backgroundColor: "#1e1e1e",
    padding: "10px",
    color: "#d1d1d1",
    fontFamily: "Arial, sans-serif",
  },
  section: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    padding: "5px 0",
    fontSize: "14px",
  },
  icon: { marginRight: "8px" },
  subgroup: { marginLeft: "15px" },
  assetGroup: { marginLeft: "30px" },
  assetCategory: { marginLeft: "45px" },
  item: { marginLeft: "20px", display: "flex", alignItems: "center" },
};
const WizardParentComponent = () => {
  const inputFileRef = useRef<HTMLInputElement>(null);
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState(true); // Initially expanded
  const selectedAccountName = useRef<string>(location.state?.selectedAccount);
  const [selectedAccName, setSelectedAccName] = useState<string>("");
  const [expanded, setExpanded] = useState(false);
  const [selectedSite, setSelectedSite] = useState<string>("");
  const [openSubGroup, setOpenSubGroup] = useState<number | null>(null);
  const [openAssetGroup, setOpenAssetGroup] = useState<number | null>(null);
  const [openAssets, setOpenAssets] = useState<boolean>(false);
  const [menuData, setMenuData] = useState<DynamicMenuTypes | null>(null);
  const menuHeight = useAvailableHeight(400);
  const [selectedSubGroupId, setSelectedSubGroupId] = useState<number>(0);
  const [selectedAssetGroupId, setSelectedAssetGroupId] = useState<number>(0);
  const [subGroupName, setSubGroupName] = useState<string>("");
  const [assetGroupName, setAssetGroupName] = useState<string>("");

  const toggleSubgroup = (id: number, name: string) => {
    setOpenSubGroup((prev) => (prev === id ? null : id));
    setSelectedSubGroupId(id);
    setSelectedAssetGroupId(0);
    setOpenAssetGroup(null);
    setSubGroupName(name);
    setSelectedComponent("subGroup");
  };

  const toggleAssetGroup = (id: number, name: string) => {
    setOpenAssetGroup((prev) => (prev === id ? null : id));
    setSelectedAssetGroupId(id);
    setAssetGroupName(name);
    setSelectedComponent("assetGroup");
  };

  const handleToggle = () => {
    setExpanded(!expanded);
  };
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleSiteChange = (siteName: string) => {
    setSelectedSite(siteName);
  };

  // Find the selected site based on the name
  const selectedSiteData =
    menuData &&
    menuData.wizSiteList.find((site) => site.id.toString() === selectedSite);

  useEffect(() => {
    fetchData();
  }, []);

  const availableHeightForMenu = useAvailableHeight(160);
  const availableHeightForContainer = useAvailableHeight(140);
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  const [selectedComponent, setSelectedComponent] = useState<string>("site");
  const [exportBeanList, setExportBeanList] = useState<any>([]);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [validationSuccess, setValidationSuccess] = useState<string>("");
  const [openValidationSuccessModal, setOpenValidationSuccessModal] =
    useState<boolean>(false);
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [openValidationErrorModal, setOpenValidationErrorModal] =
    useState<boolean>(false);
  const [errorDesc, setErrorDesc] = useState<string>("");
  const [files, setFiles] = useState<File | null>(null);

  const browseFiles = () => {
    inputFileRef.current?.click();
  };

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    toggleLoader(true);

    const selectedFile = getSelectedFile(event);
    setFiles(selectedFile);
    if (!selectedFile) {
      handleInvalidFile("CsvValidate");
      return;
    }

    if (!isValidCSVFile(selectedFile)) {
      handleInvalidFile("CsvValidate");
      return;
    }

    try {
      const name = selectedComponent;
      const response = await validateWizradsCSVFileApi(
        selectedFile,
        "en",
        name
      );
      await handleValidationResponse(response);
    } catch (err) {
      handleError(err, setErrorDesc);
    } finally {
      event.target.value = "";
    }
  };

  const getSelectedFile = (event: ChangeEvent<HTMLInputElement>) => {
    return event.target.files && event.target.files.length > 0
      ? event.target.files[0]
      : null;
  };

  const isValidCSVFile = (file: File) => {
    return (
      (file.type === "application/csv" || file.type === "text/csv") &&
      file.size <= 102400 &&
      file.size !== 0
    );
  };

  const handleInvalidFile = (errorText: string) => {
    toggleLoader(false);
  };

  const handleValidationResponse = async (response: any) => {
    toggleLoader(false);
    if (response.errorMessages.length > 0) {
      setValidationErrors(response.errorMessages);
      setOpenValidationErrorModal(true);
      return;
    } else {
      setValidationSuccess("SuccessMessageForUpload");
      setOpenValidationSuccessModal(true);
      setExportBeanList(response.exportBeanList);
    }
  };

  const uploadFile = async (file: File, isVersionRequired: boolean) => {
    setOpenValidationSuccessModal(false);
    try {
      const name = selectedComponent;
      await uploadWizardsCSVFile(
        file.name,
        "en",
        exportBeanList,
        name,
        isVersionRequired
      );
      toggleLoader(false);
    } catch (err) {
      handleError(err, setErrorDesc);
      setOpenErrorModal(true);
      toggleLoader(false);
    }
  };

  const handleDownload = async () => {
    try {
      const response = await fetch(
        `${WORK_FLOW_BASE_URL}${selectedComponent}/export?languageCode=en`,
        {
          headers: {
            Authorization: `Bearer ${localStorage?.getItem("accessToken")}`,
            userId: String(localStorage.getItem("id")),
            "Accept-Language": localStorage.getItem("language") ?? "en",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      // Create a timestamp
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are 0-based
      const day = String(now.getDate()).padStart(2, "0");
      const hours = String(now.getHours()).padStart(2, "0");
      const minutes = String(now.getMinutes()).padStart(2, "0");
      const timestamp = `${year}-${month}-${day} ${hours}-${minutes}`;
      const FormattedName = "site".charAt(0).toUpperCase() + "site".slice(1);
      const filename = `${FormattedName} Details ${timestamp}.csv`; // e.g., "Account_Details_20240812_123456.csv"

      const a = document.createElement("a");
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);

      return { success: true };
    } catch (error) {}
  };

  const importButtonClick = () => {
    if (files) {
      uploadFile(files, false);
    }
  };

  const importWithVersionClick = () => {
    if (files) {
      uploadFile(files, true);
    }
  };

  const fetchData = async () => {
    setSelectedAccName(location.state?.selectedAccount || "");
    if (location.state.selectedAccount) {
      const fetchAssetGroupUrl = `${WORK_FLOW_BASE_URL}wizard/${location.state.selectedAccount}`;
      toggleLoader(true);
      fetchWizardsMenuMetaDataApi<DynamicMenuTypes>(fetchAssetGroupUrl, "en")
        .then(async (response: DynamicMenuTypes) => {
          setMenuData(response);
          setSelectedSite(
            response.wizSiteList.length > 0
              ? response.wizSiteList[0].id.toString()
              : ""
          );
          toggleLoader(false);
        })
        .catch((err) => {
          handleError(err, setErrorDesc);
          setOpenErrorModal(true);
          toggleLoader(false);
        });
    }
  };

  const handleSubgroupClick = (subGroupName: string) => {
    setSelectedComponent("subGroup");
    setSubGroupName(subGroupName);
    setSelectedSubGroupId(0);
  };

  const handleAssetGroupClick = (name: string) => {
    setSelectedComponent("assetGroup");
    setAssetGroupName(name);
    setSelectedAssetGroupId(0);
  };

  return (
    <Box component={"main"} sx={{ padding: "24px 12px 0px 12px" }}>
      {openErrorModal && (
        <ErrorModal
          descriptionText={errorDesc}
          open={openErrorModal}
          handleClose={() => setOpenErrorModal(false)}
          onPositiveClick={() => {
            setOpenErrorModal(false);
          }}
        />
      )}
      {openValidationErrorModal && (
        <ValidationErrorModal
          descriptionTexts={validationErrors}
          open={openValidationErrorModal}
          handleClose={() => setOpenValidationErrorModal(false)}
          onOkClick={() => {
            setOpenValidationErrorModal(false);
          }}
        />
      )}
      {openValidationSuccessModal && (
        <ValidationErrorModal
          descriptionSuccess={validationSuccess}
          open={openValidationSuccessModal}
          handleClose={() => setOpenValidationSuccessModal(false)}
          onCancelClick={() => {
            setOpenValidationSuccessModal(false);
          }}
          onImportClick={importButtonClick}
          onImportWithVersionClick={importWithVersionClick}
          isValid={true}
          languageCode={"en"}
        />
      )}

      <Box component={"section"}>
        <Box
          component={"div"}
          className="flex__"
          sx={{ justifyContent: "flex-end" }}
        >
          <>
            <Box
              className="flex__ cursor__pointer"
              sx={{
                padding: "8px 12px",
                borderRadius: "4px",
                border: "1px solid rgba(166, 197, 226, 0.16)",
                marginRight: "8px",
                backgroundColor: "#2B3033",
              }}
              onClick={() => browseFiles()}
            >
              <input
                ref={inputFileRef}
                type="file"
                accept=".csv"
                style={{ display: "none", height: "36px" }}
                onChange={handleFileChange}
              />
              <Upload />
              <MediumTypography
                labelId="Upload.text"
                defaultLabel="Upload"
                className="ml-xs"
              />
            </Box>
            <Box
              className="flex__ cursor__pointer"
              sx={{
                padding: "8px 12px",
                borderRadius: "4px",
                border: "1px solid rgba(166, 197, 226, 0.16)",
                marginRight: "8px",
                backgroundColor: "#2B3033",
              }}
              onClick={() => handleDownload()}
            >
              <Download style={{ marginTop: "4px" }} />
              <MediumTypography
                labelId="Download.text"
                defaultLabel="Download"
                className="ml-xs"
              />
            </Box>
          </>
        </Box>
        {/* Custom Drawer-like Sidebar */}
        <Box
          sx={{
            height: availableHeightForContainer,
          }}
          className="master-data-container flex__ position__relative mainBox"
        >
          {/* Left Menu Section */}
          <Box
            sx={{
              height: availableHeightForMenu,
              width: menuOpen ? "520px" : "5px", // Collapsible effect
              minWidth: menuOpen ? "300px" : "5px",
              "&::-webkit-scrollbar": { display: "none" },
              padding: !menuOpen ? "16px" : "0px",
            }}
            className="flex__ position__relative left-menu_section"
          >
            {menuOpen && (
              <Box>
                <Box>
                  <MediumTypography
                    defaultLabel="Select Account"
                    labelId="selectAccount"
                    className="input-label"
                    fontSize="12px"
                    fontWeight={600}
                  />
                  <CustomDropdown
                    disableCreate={true}
                    options={getOptions(menuData?.accountList ?? [])}
                    selectedValue={selectedAccName}
                    onSelectedValueChange={(value) => {
                      setSelectedAccName(value as string);
                      selectedAccountName.current = value as string;
                    }}
                  />
                </Box>
                <Box className="mt-md">
                  <MediumTypography
                    defaultLabel="Select Site"
                    labelId="selectSite"
                    className="input-label"
                    fontSize="12px"
                    fontWeight={600}
                  />
                  <CustomDropdown
                    disableCreate={true}
                    options={getOptions(menuData?.wizSiteList || [])}
                    selectedValue={selectedSite}
                    onSelectedValueChange={(value) => {
                      handleSiteChange(value as string);
                      setSelectedSite(value as string);
                      setSelectedComponent("site");
                      setSelectedSubGroupId(0);
                      setSelectedAssetGroupId(0); // Update selected site
                    }}
                  />
                </Box>

                <Box
                  mt={2}
                  sx={{
                    height: menuHeight,
                    overflow: "auto",
                    "&::-webkit-scrollbar": { display: "none" },
                  }}
                >
                  {/* {selectedSiteData?.subgroups.length ? (
                    selectedSiteData.subgroups.map((subgroup) => (
                      <Typography key={subgroup.id}>{subgroup.name}</Typography>
                    ))
                  ) : (
                    <Typography>Subgroup 1</Typography>
                  )} */}
                  <List>
                    {selectedSiteData?.subgroups.length
                      ? selectedSiteData.subgroups.map((subgroup) => (
                          <Box key={subgroup.id}>
                            <ListItem
                              button
                              onClick={() =>
                                toggleSubgroup(subgroup.id, subgroup.name)
                              }
                            >
                              {openSubGroup === subgroup.id ? (
                                <DownArrow width={30} height={30} />
                              ) : (
                                <DownArrow
                                  width={30}
                                  height={30}
                                  className="rotated-270"
                                />
                              )}
                              <ListItemIcon sx={{ minWidth: "20px" }}>
                                <Subgroup
                                  className={
                                    subgroup.id === selectedSubGroupId
                                      ? "active"
                                      : "inactive"
                                  }
                                />
                              </ListItemIcon>
                              <Tooltip title={subgroup.name} followCursor arrow>
                                <Box>
                                  <MediumTypography
                                    label={subgroup.name}
                                    sxProps={{
                                      width: "200px",
                                      opacity:
                                        subgroup.id === selectedSubGroupId
                                          ? 1
                                          : 0.48,
                                    }}
                                    className="ellipsis"
                                  />
                                </Box>
                              </Tooltip>
                            </ListItem>

                            <Collapse
                              in={openSubGroup === subgroup.id}
                              timeout="auto"
                              unmountOnExit
                            >
                              <List
                                component="div"
                                disablePadding
                                sx={{
                                  borderLeft: "1px solid #5C7E9F",
                                  ml: 4,
                                }}
                              >
                                <ListItem>
                                  <ListItemIcon sx={{ minWidth: "20px" }}>
                                    <SubMenu />
                                  </ListItemIcon>

                                  <Box className="flex__">
                                    <MediumTypography
                                      labelId="usersCount"
                                      defaultLabel="Users"
                                      sxProps={{ opacity: "0.48" }}
                                    />
                                    <MediumTypography
                                      label={`(${subgroup.usersCount})`}
                                      sxProps={{ opacity: "0.48" }}
                                    />
                                  </Box>
                                </ListItem>
                                <ListItem>
                                  <ListItemIcon sx={{ minWidth: "20px" }}>
                                    <SubMenu />
                                  </ListItemIcon>
                                  <Box className="flex__">
                                    <MediumTypography
                                      labelId="shiftsCount"
                                      defaultLabel="Shifts"
                                      sxProps={{ opacity: "0.48" }}
                                    />
                                    <MediumTypography
                                      label={`(${subgroup.shiftsCount})`}
                                      sxProps={{ opacity: "0.48" }}
                                    />
                                  </Box>
                                </ListItem>
                                <ListItem>
                                  <ListItemIcon sx={{ minWidth: "20px" }}>
                                    <SubMenu />
                                  </ListItemIcon>
                                  <Box className="flex__">
                                    <MediumTypography
                                      labelId="holidaysCount"
                                      defaultLabel="Holidays"
                                      sxProps={{ opacity: "0.48" }}
                                    />
                                    <MediumTypography
                                      label={`(${subgroup.holidayCount})`}
                                      sxProps={{ opacity: "0.48" }}
                                    />
                                  </Box>
                                </ListItem>
                                <ListItem>
                                  <ListItemIcon sx={{ minWidth: "20px" }}>
                                    <SubMenu />
                                  </ListItemIcon>
                                  <Box className="flex__">
                                    <MediumTypography
                                      labelId="eventsText"
                                      defaultLabel="Events"
                                      sxProps={{ opacity: "0.48" }}
                                    />
                                    <MediumTypography
                                      label={`(${subgroup.eventCount})`}
                                      sxProps={{ opacity: "0.48" }}
                                    />
                                  </Box>
                                </ListItem>
                                <ListItem>
                                  <ListItemIcon sx={{ minWidth: "20px" }}>
                                    <SubMenu />
                                  </ListItemIcon>
                                  <Box className="flex__">
                                    <MediumTypography
                                      labelId="loadText"
                                      defaultLabel="Load Distribution"
                                      sxProps={{ opacity: "0.48" }}
                                    />
                                  </Box>
                                </ListItem>
                                <ListItem>
                                  <ListItemIcon sx={{ minWidth: "20px" }}>
                                    <SubMenu />
                                  </ListItemIcon>

                                  <Box className="flex__">
                                    <MediumTypography
                                      labelId="vendorsText"
                                      defaultLabel="Vendor & Services"
                                      sxProps={{ opacity: "0.48" }}
                                    />
                                  </Box>
                                </ListItem>
                                {subgroup.assetGroups.length > 0 ? (
                                  subgroup.assetGroups.map((assetGroup) => (
                                    <Box key={assetGroup.id}>
                                      <ListItem
                                        button
                                        onClick={() =>
                                          toggleAssetGroup(
                                            assetGroup.id,
                                            assetGroup.name
                                          )
                                        }
                                      >
                                        {openAssetGroup === assetGroup.id ? (
                                          <DownArrow width={30} height={30} />
                                        ) : (
                                          <DownArrow
                                            width={30}
                                            height={30}
                                            className="rotated-270"
                                          />
                                        )}
                                        <ListItemIcon sx={{ minWidth: "20px" }}>
                                          <SubMenu
                                            className={
                                              assetGroup.id ===
                                              selectedAssetGroupId
                                                ? "active"
                                                : "inactive"
                                            }
                                          />
                                        </ListItemIcon>
                                        <Tooltip
                                          title={assetGroup.name}
                                          followCursor
                                          arrow
                                        >
                                          <Box>
                                            <MediumTypography
                                              label={assetGroup.name}
                                              sxProps={{
                                                width: "150px",
                                                opacity:
                                                  assetGroup.id ===
                                                  selectedAssetGroupId
                                                    ? 1
                                                    : 0.48,
                                              }}
                                              className="ellipsis"
                                            />
                                          </Box>
                                        </Tooltip>
                                      </ListItem>

                                      <Collapse
                                        in={openAssetGroup === assetGroup.id}
                                        timeout="auto"
                                        unmountOnExit
                                      >
                                        <List
                                          component="div"
                                          disablePadding
                                          sx={{
                                            borderLeft: "1px solid #5C7E9F",
                                            ml: 4,
                                          }}
                                        >
                                          <ListItem>
                                            <ListItemIcon
                                              sx={{ minWidth: "20px" }}
                                            >
                                              <Round />
                                            </ListItemIcon>

                                            <Box className="flex__">
                                              <MediumTypography
                                                labelId="uploadKmlText"
                                                defaultLabel="Upload KML"
                                                sxProps={{ opacity: "0.48" }}
                                              />
                                            </Box>
                                          </ListItem>
                                          <ListItem>
                                            <ListItemIcon
                                              sx={{ minWidth: "20px" }}
                                            >
                                              <Round />
                                            </ListItemIcon>
                                            <Box className="flex__">
                                              <MediumTypography
                                                labelId="scheduleText"
                                                defaultLabel="Schedule"
                                                sxProps={{ opacity: "0.48" }}
                                              />
                                            </Box>
                                          </ListItem>
                                          <ListItem>
                                            <ListItemIcon
                                              sx={{ minWidth: "20px" }}
                                            >
                                              <Round />
                                            </ListItemIcon>
                                            <Box className="flex__">
                                              <MediumTypography
                                                labelId="workorderMaster"
                                                defaultLabel="Workorder Master"
                                                sxProps={{ opacity: "0.48" }}
                                              />
                                            </Box>
                                          </ListItem>
                                          <ListItem
                                            button
                                            onClick={() =>
                                              setOpenAssets(!openAssets)
                                            }
                                          >
                                            {assetGroup.assetCategory.length >
                                              0 && (
                                              <>
                                                {openAssets ? (
                                                  <DownArrow
                                                    width={30}
                                                    height={30}
                                                  />
                                                ) : (
                                                  <DownArrow
                                                    width={30}
                                                    height={30}
                                                    className="rotated-270"
                                                  />
                                                )}
                                              </>
                                            )}

                                            <ListItemIcon
                                              sx={{ minWidth: "20px" }}
                                            >
                                              <SubMenu />
                                            </ListItemIcon>
                                            <Box>
                                              <MediumTypography
                                                labelId={"assetsText"}
                                                defaultLabel="Assets"
                                                sxProps={{
                                                  opacity: "0.48",
                                                }}
                                              />
                                            </Box>
                                          </ListItem>

                                          <Box>
                                            <Collapse
                                              in={openAssets}
                                              timeout="auto"
                                              unmountOnExit
                                            >
                                              {assetGroup.assetCategory.map(
                                                (assetCat) => (
                                                  <List
                                                    component="div"
                                                    disablePadding
                                                    sx={{
                                                      borderLeft:
                                                        "1px solid #5C7E9F",
                                                      ml: 4,
                                                    }}
                                                  >
                                                    <ListItem>
                                                      <ListItemIcon
                                                        sx={{
                                                          minWidth: "20px",
                                                        }}
                                                      >
                                                        <Round />
                                                      </ListItemIcon>
                                                      <Tooltip
                                                        title={`${assetCat.name}(${assetCat.count})`}
                                                        followCursor
                                                        arrow
                                                      >
                                                        <Box className="flex__">
                                                          <MediumTypography
                                                            label={`${assetCat.name}(${assetCat.count})`}
                                                            sxProps={{
                                                              opacity: "0.48",
                                                              width: "100px",
                                                            }}
                                                            className="ellipsis"
                                                          />
                                                        </Box>
                                                      </Tooltip>
                                                    </ListItem>
                                                  </List>
                                                )
                                              )}
                                            </Collapse>
                                          </Box>
                                        </List>
                                      </Collapse>
                                    </Box>
                                  ))
                                ) : (
                                  <ListItem
                                    button
                                    onClick={() =>
                                      handleAssetGroupClick("Asset Group 1")
                                    }
                                  >
                                    <ListItemIcon sx={{ minWidth: "20px" }}>
                                      <SubMenu
                                        className={
                                          selectedComponent === "assetGroup"
                                            ? "active"
                                            : "inactive"
                                        }
                                      />
                                    </ListItemIcon>
                                    <Box>
                                      <MediumTypography
                                        labelId="AssetGroupText"
                                        defaultLabel="Asset Group 1"
                                        sxProps={{
                                          opacity:
                                            selectedComponent === "assetGroup"
                                              ? 1
                                              : 0.48,
                                        }}
                                      />
                                    </Box>
                                  </ListItem>
                                )}
                              </List>
                            </Collapse>
                          </Box>
                        ))
                      : selectedSite !== "" &&
                        selectedSite !== null &&
                        selectedSite !== undefined && (
                          <ListItem
                            button
                            onClick={() => {
                              handleSubgroupClick("Subgroup 1");
                            }}
                          >
                            <ListItemIcon sx={{ minWidth: "20px" }}>
                              <Subgroup
                                className={
                                  selectedComponent === "subGroup"
                                    ? "active"
                                    : "inactive"
                                }
                              />
                            </ListItemIcon>
                            <Box>
                              <MediumTypography
                                labelId="subGroupText"
                                defaultLabel="Subgroup 1"
                                sxProps={{
                                  opacity:
                                    selectedComponent === "assetGroup"
                                      ? 1
                                      : 0.48,
                                }}
                              />
                            </Box>
                          </ListItem>
                        )}
                  </List>
                </Box>
              </Box>
            )}

            {/* Collapse Button at the bottom of the Menu */}
            {!menuOpen && (
              <Box className="collapse-button flex__">
                <ButtonComponent
                  className="CollapseClickButton flex__"
                  LeftIcon={<CollpaseLeftIcon />} // Ensure you have the correct icon here
                  onClick={toggleMenu}
                />
              </Box>
            )}

            {menuOpen && (
              <Box className="button-box">
                <ButtonComponent
                  className="empty-button"
                  LeftIcon={<CollpaseLeftIcon />}
                  label="Collapse"
                  onClick={toggleMenu}
                />
              </Box>
            )}

            {/* Vertical Open Button when Menu is Collapsed */}
            {!menuOpen && (
              <MediumTypography
                label="EXPAND NAVIGATION"
                className="expand-naviationCss"
              />
            )}
          </Box>

          {/* Divider */}
          <Box
            className="divider-boxCss"
            sx={{
              height: availableHeightForMenu, // Maintain the height of the menu section
            }}
          ></Box>

          {/* Main content area */}
          {/* <Box> */}
          {/* <SiteDetailsComponent selectedAcc={selectedAccName} /> */}
          {/* <SubgroupDetailsComponent /> */}
          {/* <AssetGroupWizards /> */}
          {/* </Box> */}
          {selectedComponent === "site" && menuData && (
            <SiteDetailsComponent
              selectedAcc={selectedAccName}
              siteId={parseInt(selectedSite)}
              siteName={selectedSiteData?.name || "Site 1"}
            />
          )}
          {selectedComponent === "subGroup" && (
            <SubgroupDetailsComponent
              siteId={parseInt(selectedSite)}
              subGroupId={selectedSubGroupId}
              subGroupName={subGroupName}
            />
          )}
          {selectedComponent === "assetGroup" && (
            <AssetGroupWizards
              subGroupId={selectedSubGroupId}
              assetGroupId={selectedAssetGroupId}
              assetGroupName={assetGroupName}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default WizardParentComponent;
