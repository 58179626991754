import { Box, Button, Card, CardContent, Stack } from "@mui/material";
import React, { useContext } from "react";
import MediumTypography from "./MediumTypography";
import { ReactComponent as EnquireIcon } from "../../assets/images/enquire.svg";
import { ReactComponent as ProfilePhoto } from "../../assets/images/ProfilePicture.svg";
import { ReactComponent as ErrorIcon } from "../../assets/images/ErrorIcon.svg";
import { ReactComponent as DispatchIcon } from "../../assets/images/DispatchIcon.svg";
import SmallBorderLine from "./SmallBorderLine";
import UserStack from "./UserStack";
import { useNavigate } from "react-router-dom";
import {
  GRASS_CUTTING_PLANNER,
  MODULE_CLEANING_PLANNER,
} from "../../routes/Routing";
import { hasPermission } from "../../utils/checkPermission";
import { dateContext } from "../../pages/scheduler/SchedulerDashboard";

export interface TeamMembers {
  id: number;
  name: string;
  image: string;
}

export interface CardData {
  cardId: number;
  shift?: string;
  shiftId?: number;
  shiftStartTime?: string;
  shiftEndTime?: string;
  cardCount?: number;
  sequenceId: number;
  type: string;
  code: string;
  frequency: string;
  assetCategory: string;
  asset: string;
  status: string;
  processedOn?: string;
  processedCount?: number;
  processedBy?: string;
  profile?: string;
  members: TeamMembers[];
  description?: string;
  dispatchStatusType?: string;
  dispatchMessage?: string;
  maxStaffCount: number;
  assetGroupId?: number;
  statusCode: string;
  estimatedDuration: number;
}

type CardProps = {
  cardDataArray: CardData;
  cardShiftStartTime?: string;
  cardShiftEndTime?: string;
  backGroundColor?: string;
  boxShadow?: string;
  cardShiftName?: string;
  userStackCallback?: () => void;
  cardType?: string;
};

const CardComponent: React.FC<CardProps> = ({
  cardDataArray,
  cardShiftEndTime,
  cardShiftStartTime,
  backGroundColor,
  boxShadow,
  cardShiftName,
  userStackCallback,
  cardType,
}) => {
  const navigate = useNavigate();
  const dateCtx = useContext(dateContext);

  const convertMinutesToHoursAndMinutes = (minutes: number) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    const formattedHours = hours < 10 ? `0${hours}` : `${hours}`;
    const formattedMinutes = mins < 10 ? `0${mins}` : `${mins}`;

    return `${formattedHours}:${formattedMinutes}`;
  };

  let cardBg = "rgba(19, 196, 19, 0.12)"; // SCHEDULED | DISPATCHED - green
  return (
    <Stack direction="column" spacing={-20} alignItems="center">
      <Card
        style={{
          position: "relative",
          width: "800px",
          height: "124px",
          borderRadius: "3.74px",
          boxShadow: boxShadow,
          background: `linear-gradient(to right, ${cardBg}, transparent), linear-gradient(to right, rgba(40, 45, 51, 1), rgba(40, 45, 51, 1))`,
          borderLeft:
            cardDataArray.statusCode === "ONHOLD"
              ? "4px solid rgba(245, 197, 24, 1)"
              : cardDataArray.statusCode === "EXPIRED"
              ? "4px solid rgba(91, 114, 130, 1)"
              : cardDataArray.statusCode === "REJECTED"
              ? "4px solid rgba(217, 31, 17, 1)"
              : cardDataArray.statusCode === "PAUSED"
              ? "4px solid rgba(24, 106, 222, 1)"
              : cardDataArray.statusCode === "SCHEDULED" ||
                cardDataArray.statusCode === "DISPATCHED" ||
                cardDataArray.statusCode === "NEW"
              ? "4px solid rgba(19, 196, 19, 1)"
              : "rgba(24, 106, 222, 1)",
          top: "0px",
          left: "0px",
        }}
      >
        <CardContent>
          <Box className="flex__">
            <Button
              className={`button-ui p-xs ${
                cardDataArray.code === "CM" ? "tabs_warning" : "card_panel_btn"
              } flex__`}
            >
              <Box className="tabs_scroller_child">
                <MediumTypography
                  label={cardDataArray.code}
                  textColor={
                    cardDataArray.code === "CM" ? "#FF453A" : "#B8ACF6"
                  }
                  sxProps={{ textTransform: "none" }}
                />
              </Box>
              <Box className="" sx={{ color: "black" }}>
                <MediumTypography
                  label={cardDataArray.type}
                  fontSize="14px"
                  textColor={
                    cardDataArray.code === "CM" ? "#FF453A" : "#B8ACF6"
                  }
                  sxProps={{ textTransform: "none" }}
                />
              </Box>
            </Button>
            <Box sx={{ padding: "7px 8px" }}>
              <MediumTypography
                label={cardDataArray.sequenceId.toString()}
                textColor="#FFFFFF"
                sxProps={{
                  textTransform: "none",
                }}
              />
            </Box>
            <SmallBorderLine />
            <Box sx={{ padding: "5px 8px" }}>
              <Box display={"flex"} className="ellipsis-item-container">
                <MediumTypography
                  className="card-wo-routine"
                  label={cardDataArray.frequency}
                />
                <MediumTypography
                  label={` ${cardDataArray.asset}`}
                  textColor="#FFFFFF"
                  className={`ml-xs ${
                    cardType === "shift" && "cursor__pointer"
                  }`}
                  sxProps={{
                    textTransform: "none",
                  }}
                  onClick={() => {
                    const navRoute =
                      cardDataArray.code === "MC"
                        ? MODULE_CLEANING_PLANNER
                        : cardDataArray.code === "GC"
                        ? GRASS_CUTTING_PLANNER
                        : null;
                    if (
                      cardType === "shift" &&
                      navRoute &&
                      (hasPermission("Plan_MC_GC_WO") ||
                        hasPermission("Read_Only"))
                    ) {
                      navigate(navRoute, {
                        state: {
                          cardId: cardDataArray.cardId,
                          assetGroupId: cardDataArray.assetGroupId,
                          idType: "schedule",
                          date: dateCtx.activeDate,
                          dateRange: dateCtx.activeDateRange,
                          schedulerViewChange: "",
                        },
                      });
                    }
                  }}
                />
              </Box>
            </Box>
            <SmallBorderLine />
            <Box sx={{ padding: "5px 8px" }}>
              <Box className="shift_card_btn">
                <Box className="" sx={{ color: "#FFFFFF" }}>
                  {cardShiftName}
                </Box>
              </Box>
            </Box>
            <Box sx={{ padding: "3px 0px" }} className="flex__">
              <MediumTypography
                label={convertMinutesToHoursAndMinutes(
                  cardDataArray.estimatedDuration
                )}
                textColor="#FFFFFF"
                sxProps={{
                  textTransform: "none",
                }}
              />
              <MediumTypography
                label="( hh:mm )"
                textColor="rgba(255, 255, 255, 0.72)"
                className="ml-xs"
                sxProps={{
                  textTransform: "none",
                }}
              />
            </Box>
            <Box sx={{ padding: "7px 8px", marginLeft: "auto" }}>
              <Box
                className="userStack cursor__pointer"
                onClick={userStackCallback}
              >
                <UserStack
                  users={cardDataArray.members}
                  showMax={4}
                  maxStaffCount={cardDataArray.maxStaffCount}
                />
              </Box>
            </Box>
          </Box>
          <Box className="flex__">
            <Box sx={{ padding: "4px 4px" }}>
              <MediumTypography
                label={cardDataArray.description}
                textColor="rgba(255, 255, 255, 0.72)"
                sxProps={{
                  textTransform: "none",
                }}
              />
            </Box>
          </Box>
          <Box className="flex__" sx={{ gap: "8px", padding: "0px 0px" }}>
            {cardDataArray.processedOn && (
              <Box
                sx={{
                  padding: "4px 8px 4px 8px",
                  borderRadius: "3px",
                  backgroundColor: "rgba(161, 189, 217, 0.08)",
                }}
              >
                <MediumTypography
                  label={cardDataArray.processedOn}
                  textColor="rgba(255, 255, 255, 0.72)"
                  sxProps={{
                    textTransform: "none",
                  }}
                />
              </Box>
            )}

            {cardDataArray.processedCount &&
            cardDataArray.processedCount > 0 ? (
              <Box
                className="flex__"
                sx={{
                  padding: "4px 8px 4px 8px",
                  borderRadius: "3px",
                  backgroundColor: "rgba(161, 189, 217, 0.08)",
                }}
              >
                <EnquireIcon style={{ width: "20px", height: "20px" }} />
                <MediumTypography
                  label={cardDataArray.processedCount?.toString()}
                  textColor="rgba(255, 255, 255, 0.72)"
                  sxProps={{
                    textTransform: "none",
                  }}
                />
              </Box>
            ) : null}
            {cardDataArray.processedBy && (
              <Box
                className="flex__"
                sx={{
                  padding: "4px 8px 4px 8px",
                  borderRadius: "3px",
                  backgroundColor: "rgba(161, 189, 217, 0.08)",
                }}
              >
                <ProfilePhoto />
                <MediumTypography
                  label={cardDataArray.processedBy}
                  textColor="rgba(255, 255, 255, 0.72)"
                  sxProps={{
                    textTransform: "none",
                    position: "relative",
                    left: "4px",
                  }}
                />
              </Box>
            )}
            <Box
              sx={{
                padding: "0px 16px 0px 16px",
                borderRadius: "4px",
                height: "24px",
                backgroundColor:
                  cardDataArray.statusCode === "ONHOLD"
                    ? "rgba(245, 197, 24, 1)"
                    : cardDataArray.statusCode === "EXPIRED"
                    ? "rgba(91, 114, 130, 1)"
                    : cardDataArray.statusCode === "REJECTED"
                    ? "rgba(217, 31, 17, 1)"
                    : cardDataArray.statusCode === "PAUSED"
                    ? "rgba(24, 106, 222, 1)"
                    : cardDataArray.statusCode === "SCHEDULED" ||
                      cardDataArray.statusCode === "DISPATCHED" ||
                      cardDataArray.statusCode === "NEW"
                    ? "rgba(7, 125, 85, 1)"
                    : cardDataArray.statusCode === "ERROR"
                    ? "rgba(244, 71, 57, 0.2)"
                    : cardDataArray.statusCode === "SUCCESS"
                    ? "rgba(28, 51, 41, 1)"
                    : cardDataArray.statusCode === "WARNING"
                    ? "rgba(245, 197, 24, 1)"
                    : "rgba(24, 106, 222, 1)",
                marginLeft: "auto",
              }}
            >
              {cardDataArray.statusCode === "ERROR" && (
                <Box
                  className={`button-ui
              flex__`}
                  sx={{ width: "auto" }}
                >
                  <ErrorIcon
                    style={{ position: "relative", top: "2px", right: "2px" }}
                  />
                  <MediumTypography
                    label={cardDataArray.dispatchMessage}
                    textColor={"rgba(244, 71, 57, 1)"}
                    sxProps={{
                      textTransform: "none",
                    }}
                  />
                </Box>
              )}
              {cardDataArray.statusCode === "SUCCESS" && (
                <Box
                  className={`button-ui
              flex__`}
                  sx={{ width: "170px" }}
                >
                  <DispatchIcon
                    style={{ position: "relative", top: "2px", right: "2px" }}
                  />
                  <MediumTypography
                    label={cardDataArray.dispatchMessage}
                    textColor={"rgba(42, 187, 127, 1)"}
                    sxProps={{
                      textTransform: "none",
                    }}
                  />
                </Box>
              )}
              {cardDataArray.statusCode === "WARNING" && (
                <Box
                  className={`button-ui
              flex__`}
                >
                  <ErrorIcon
                    style={{ position: "relative", top: "2px", right: "2px" }}
                  />
                  <MediumTypography
                    label={cardDataArray.dispatchMessage}
                    textColor={"#1D2125"}
                    sxProps={{
                      textTransform: "none",
                    }}
                  />
                </Box>
              )}
              {cardDataArray.statusCode !== "ERROR" &&
                cardDataArray.statusCode !== "SUCCESS" &&
                cardDataArray.statusCode !== "WARNING" && (
                  <MediumTypography
                    label={cardDataArray.dispatchStatusType}
                    textColor={
                      cardDataArray.dispatchStatusType === "ONHOLD"
                        ? "#1C2B36"
                        : "#FFFFFF"
                    }
                    sxProps={{
                      textTransform: "none",
                    }}
                  />
                )}
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Stack>
  );

  // return (
  //   <Stack direction="column" spacing={-20} alignItems="center">
  //     {cardDataArray.map((card, index) => (
  //       <Box>
  //         <Card
  //           style={{
  //             position: "relative",
  //             width: `${card.width}px`,
  //             height: "150px",
  //             borderRadius: "3.74px",
  //             boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  //             backgroundColor: "#1F272C",
  //             borderLeft: "4px solid red",
  //             top: `${card.top}px`,
  //             left: `${card.left}px`,
  //             zIndex: `${index}`,
  //           }}
  //         >
  //           <CardContent>
  //             <Box className="flex__">
  //               <Button className="button-ui p-none  tabs_warning flex__">
  //                 <Box className="tabs_scroller_child">
  //                   <MediumTypography
  //                     label="CM"
  //                     textColor="#FF453A"
  //                     sxProps={{ textTransform: "none" }}
  //                   />
  //                 </Box>
  //                 <Box className="" sx={{ color: "black" }}>
  //                   <MediumTypography
  //                     label="Corrective Maintenance"
  //                     textColor="#FF453A"
  //                     sxProps={{ textTransform: "none" }}
  //                   />
  //                 </Box>
  //               </Button>
  //               <Box sx={{ padding: "7px 8px" }}>
  //                 <MediumTypography
  //                   label={card.priorityNumber}
  //                   textColor="#FFFFFF"
  //                   sxProps={{
  //                     textTransform: "none",
  //                   }}
  //                 />
  //               </Box>
  //               <SmallBorderLine />
  //               <Box sx={{ padding: "7px 8px" }}>
  //                 <MediumTypography
  //                   label={card.workOrderNumber}
  //                   textColor="#FFFFFF"
  //                   sxProps={{
  //                     textTransform: "none",
  //                   }}
  //                 />
  //               </Box>
  //               <SmallBorderLine />
  //               <Box sx={{ padding: "7px 8px" }}>
  //                 <Box className="shift_card_btn">
  //                   <Box className="" sx={{ color: "#FFFFFF" }}>
  //                     {card.shift === "General" ? "G" : "A"}
  //                   </Box>
  //                 </Box>
  //               </Box>
  //               <Box sx={{ padding: "7px 8px" }}>
  //                 <MediumTypography
  //                   label={"09:00 - 18:00"}
  //                   textColor="#FFFFFF"
  //                   sxProps={{
  //                     textTransform: "none",
  //                   }}
  //                 />
  //               </Box>
  //               <Box sx={{ padding: "7px 8px", marginLeft: "auto" }}>
  //                 <UserStack users={USERS} showMax={4} />
  //               </Box>
  //             </Box>
  //             <Box className="flex__">
  //               <Box sx={{ padding: "4px 4px" }}>
  //                 <MediumTypography
  //                   label="1234 7835 - Ground Fault"
  //                   textColor="rgba(255, 255, 255, 0.72)"
  //                   sxProps={{
  //                     textTransform: "none",
  //                   }}
  //                 />
  //               </Box>
  //             </Box>
  //             <Box className="flex__" sx={{ padding: "8px 0px", gap: "8px" }}>
  //               <Box
  //                 sx={{
  //                   padding: "4px 8px 4px 8px",
  //                   borderRadius: "3px",
  //                   backgroundColor: "rgba(161, 189, 217, 0.08)",
  //                 }}
  //               >
  //                 <MediumTypography
  //                   label="Rejected on 24 JAN 2024"
  //                   textColor="rgba(255, 255, 255, 0.72)"
  //                   sxProps={{
  //                     textTransform: "none",
  //                   }}
  //                 />
  //               </Box>
  //               <Box
  //                 sx={{
  //                   padding: "4px 8px 4px 8px",
  //                   borderRadius: "3px",
  //                   backgroundColor: "rgba(161, 189, 217, 0.08)",
  //                 }}
  //               >
  //                 <MediumTypography
  //                   label="Expiring in 1 days"
  //                   textColor="rgba(255, 255, 255, 0.72)"
  //                   sxProps={{
  //                     textTransform: "none",
  //                   }}
  //                 />
  //               </Box>
  //               <Box
  //                 className="flex__"
  //                 sx={{
  //                   padding: "4px 8px 4px 8px",
  //                   borderRadius: "3px",
  //                   backgroundColor: "rgba(161, 189, 217, 0.08)",
  //                 }}
  //               >
  //                 <EnquireIcon style={{ width: "20px", height: "20px" }} />
  //                 <MediumTypography
  //                   label="32"
  //                   textColor="rgba(255, 255, 255, 0.72)"
  //                   sxProps={{
  //                     textTransform: "none",
  //                   }}
  //                 />
  //               </Box>
  //               <Box
  //                 className="flex__"
  //                 sx={{
  //                   padding: "4px 8px 4px 8px",
  //                   borderRadius: "3px",
  //                   backgroundColor: "rgba(161, 189, 217, 0.08)",
  //                 }}
  //               >
  //                 <ProfilePhoto />
  //                 <MediumTypography
  //                   label="Rejected by Floyd Miles"
  //                   textColor="rgba(255, 255, 255, 0.72)"
  //                   sxProps={{
  //                     textTransform: "none",
  //                     position: "relative",
  //                     left: "4px",
  //                   }}
  //                 />
  //               </Box>
  //               <Box
  //                 sx={{
  //                   padding: "4px 10px 4px 10px",
  //                   borderRadius: "4px",
  //                   backgroundColor: "rgba(217, 31, 17, 1)",
  //                   marginLeft: "auto",
  //                 }}
  //               >
  //                 <MediumTypography
  //                   label="Rejected"
  //                   textColor="#FFFFFF"
  //                   sxProps={{
  //                     textTransform: "none",
  //                   }}
  //                 />
  //               </Box>
  //             </Box>
  //           </CardContent>
  //         </Card>
  //       </Box>
  //     ))}
  //   </Stack>
  // );
};

export default CardComponent;
