import React, { ChangeEvent } from "react";
import {
  TextField,
  InputBaseComponentProps,
  InputAdornment,
  IconButton,
  SxProps,
} from "@mui/material";
import { ReactComponent as EyeIconOpen } from "../../assets/images/EyeIconOpen.svg";
import { ReactComponent as EyeIconClose } from "../../assets/images/EyeIconClose.svg";
import { useIntl } from "react-intl";

interface PasswordTextFieldProps {
  name?: string;
  fieldheight?: string;
  InputProps?: InputBaseComponentProps;
  Value?: string;
  handlechange?: (
    value: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  type?: string;

  autoComplete?: string;
  className?: string;
  onblur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  handleTogglePasswordVisibility?: () => void;
  onKeyPress?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
  showpassword?: boolean;
  labelId?: string;
  defaultLabelId?: string;
  sxProps?: SxProps;
}
const PasswordTextField: React.FC<PasswordTextFieldProps> = (props) => {
  const {
    labelId,
    defaultLabelId,
    fieldheight,
    name,
    Value,
    handlechange,
    autoComplete,
    InputProps,
    onblur,
    onFocus,
    showpassword,
    className,
    handleTogglePasswordVisibility,
    onKeyPress,
    sxProps,
  } = props;

  const internalHandlePasswordChange = (
    value: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (handlechange && value != null) {
      handlechange(value);
    }
  };
  const textFieldStyle = {
    height: fieldheight,
  };
  const intl = useIntl();
  return (
    <TextField
      sx={{
        width: "auto",
        "& .MuiInputBase-root": {
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "14px",
          alignItems: "center",
          color: "#FFFFFF",
          backgroundColor: "#2C2C2E",
        },
        "& label.Mui-focused": {
          color: "#2C2C2E",
        },
        "& .MuiOutlinedInput-multiline": {
          borderColor: "#2C2C2E",
        },
        "& .MuiOutlinedInput-root": {
          "&.Mui-focused fieldset": {
            borderColor: "#2C2C2E",
          },
          height: "inherit",
        },
        "& .MuiOutlinedInput-input": {
          "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
          },
          "&:-webkit-autofill": {
            height: "7px",
          },
        },
        ...sxProps,
      }}
      className={className}
      name={name}
      variant="outlined"
      value={Value}
      onBlur={onblur}
      onFocus={onFocus}
      autoComplete={autoComplete}
      placeholder={intl.formatMessage({
        id: labelId,
        defaultMessage: defaultLabelId,
      })}
      onChange={internalHandlePasswordChange}
      style={textFieldStyle}
      InputLabelProps={{ shrink: false }}
      type={showpassword ? "text" : "password"}
      inputProps={InputProps}
      onKeyDown={onKeyPress}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handleTogglePasswordVisibility} edge="end">
              {showpassword ? <EyeIconOpen /> : <EyeIconClose />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};
export default PasswordTextField;
