import React, { useState, FC } from "react";
import ReactQuill from "react-quill";
import { Box } from "@mui/material";
import "react-quill/dist/quill.snow.css";
import "./RichTextEditor.css";
import { useIntl } from "react-intl";

interface RichTextEditorProps {
  data: string;
  labelId: string;
  defaultLabelId: string;
  readOnly: boolean;
  onChangeCallBack: (value: string) => void;
}
const RichTextEditor: FC<RichTextEditorProps> = ({
  labelId,
  defaultLabelId,
  data,
  readOnly,
  onChangeCallBack,
}) => {
  const [value, setValue] = useState<string>("");
  const intl = useIntl();

  React.useEffect(() => {
    setValue(data);
  }, [data]);

  const modules = {
    toolbar: [
      [{ size: [] }],
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
    ],
  };

  const handleChange = (value: string) => {
    setValue(value);
    onChangeCallBack(value); // Call the onChange callback prop with the new value
  };

  const formats = ["size", "bold", "italic", "underline", "list", "bullet"];

  return (
    <Box sx={{ mt: 2 }}>
      <ReactQuill
        value={value}
        onChange={handleChange}
        modules={modules}
        formats={formats}
        readOnly={readOnly}
        placeholder={intl.formatMessage({
          id: labelId,
          defaultMessage: defaultLabelId,
        })}
      />
    </Box>
  );
};

export default RichTextEditor;
