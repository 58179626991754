import { Box, Dialog, DialogContent, DialogTitle } from "@mui/material";
import React, { FC } from "react";
import { ReactComponent as Close } from "../../../assets/images/_CrossIcon_.svg";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import MediumTypography from "../MediumTypography";

interface ModalProps {
  open: boolean;
  handleClose: () => void;
  fileUrl: string | null;
}

const SOPModal: FC<ModalProps> = ({ open, handleClose, fileUrl }) => {
  return (
    <Dialog open={open} fullWidth maxWidth={"lg"}>
      <DialogTitle sx={{ backgroundColor: "#1D2125" }}>
        <Box className="flex__" sx={{ justifyContent: "flex-end" }}>
          <Close style={{ cursor: "pointer" }} onClick={handleClose} />
        </Box>
      </DialogTitle>

      <DialogContent
        sx={{ backgroundColor: "#1D2125" }}
        className="tableStyles"
      >
        {fileUrl !== null ? (
          <DocViewer
            documents={[{ uri: fileUrl }]}
            pluginRenderers={DocViewerRenderers}
            config={{
              header: {
                disableHeader: true,
              },
            }}
          />
        ) : (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "502px",
            }}
          >
            <MediumTypography
              labelId={"Sop.NOdoc"}
              defaultLabel="No SOP Document Found"
              fontSize="20px"
              fontWeight={400}
              textColor="#808080"
            />
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default SOPModal;
