import React, {
  FC,
  useContext,
  useEffect,
  useRef,
  useState,
  useCallback,
} from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Box,
  Avatar,
  Tooltip,
  IconButton,
  MenuItem,
  Popover,
} from "@mui/material";
import MediumTypography from "../../../components/formlib/MediumTypography";
import { ReactComponent as More } from "../../../assets/images/ThreeDots.svg";
import useAvailableHeight from "../../../utils/useAvailableHeight";
import dayjs from "dayjs";
import useAvailableWidth from "../../../utils/useAvailableWidth";
import { hasPermission } from "../../../utils/checkPermission";
import CustomTableHeader from "../../../components/formlib/CustomTableHeader";
import { LoaderContext, LoaderContextType } from "../../../layouts/appSidebar";
import { isCustomError } from "../../../api/ApiResponseHandler";
import { ApiErrorResponse } from "../../../api/UserApi/User";

import { ReactComponent as Sort } from "../../../assets/images/Sort.svg";
import ErrorModal from "../../../components/formlib/modal/ErrorModal";
import ModalPopup from "../../../components/formlib/modal/ModalPopup";
import ButtonComponent from "../../../components/formlib/ButtonComponent";

import {
  applySearchFilters,
  cancelSearchFilters,
  sortTableData,
} from "../../MasterDataManagement/TableUtility";
import { useLocation } from "react-router-dom";
import { HeadCell } from "../user/users";
import {
  deleteWizardsDataApi,
  fetchAllWizardsDataApi,
} from "../../../api/wizardsApi/Common";
import { ListType } from "../wizrads.types";
import { HolidayDataType, HolidayRecordType } from "../types/holidayTypes";
import AddEditHolidayModal from "./AddEditHoliday";

// Configurations

type RecordType = HolidayRecordType;
type RecordsType = HolidayDataType;

const apiUri = "holiday";

const searchKeys = ["name", "type"];

const staticColumn = "name";

const dataFields: HeadCell[] = [
  {
    key: "name",
    value: "Occasion",
    labelId: "Holidays.Occasion",
  },

  {
    key: "formattedDate",
    value: "Date",
    labelId: "Holidays.Date",
  },
  {
    key: "type",
    value: "Type",
    labelId: "Holidays.Type",
  },
];

export interface ManageComponentProps {
  onDataFetch: (data: RecordsType) => void;
  selectedLanguageCode: string;
  menuOpen: boolean;
  subGroupId: number;
  holidayChildCallback: () => void;
  onFilter: (filteredData: { [key: string]: string }) => void;
}

const HolidayListScreen: FC<ManageComponentProps> = ({
  onDataFetch,
  selectedLanguageCode,
  menuOpen,
  subGroupId,
  holidayChildCallback,
  onFilter,
}) => {
  const availableWidth = useAvailableWidth(550);
  const MenuOpenAvailableWidth = useAvailableWidth(210);
  const availableHeight = useAvailableHeight(300);
  const emptyTableHeight = useAvailableHeight(420);
  const [selectedRow, setSelectedRow] = useState<null | number>(null);
  const containerRef = useRef(null);
  const [activeFilters, setActiveFilters] = useState<{ [key: string]: string }>(
    {}
  );
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  const [listData, setListData] = useState<RecordsType | null>(null);
  const [tableData, setTableData] = useState<RecordType[]>([]);
  const [reorderedFields, setReorderedFields] = useState<HeadCell[]>([]);
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [errorDesc, setErrorDesc] = useState<string>("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [originalTableData, setOriginalTableData] = useState<RecordType[]>([]);
  const [warningModal, setWarningModal] = useState<boolean>(false);
  const [modalAccountId, setModalAccountId] = useState<number>();

  const addEditOpen = Boolean(anchorEl);
  const [showAddEditForm, setShowAddEditForm] = useState<boolean>(false);

  const dataFieldsWithIcons = reorderedFields?.map((field) => {
    return { ...field, icon: <Sort /> };
  });

  const location = useLocation();

  useEffect(() => {
    getRecords(true);
  }, [selectedLanguageCode, location?.state]);

  const getRecords = async (
    resetData: boolean = false,
    language: string = selectedLanguageCode,
    applyFilters: boolean = false
  ) => {
    toggleLoader(true);

    const filterQuery = Object.entries(activeFilters)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join("&");

    const fullUri = `${subGroupId}/${apiUri}`;

    fetchAllWizardsDataApi<RecordsType>(fullUri, language, filterQuery)
      .then(async (response) => {
        setListData(response);

        toggleLoader(false);

        if (resetData) {
          setTableData(response.holidayRecords);
          setOriginalTableData(response.holidayRecords);
        } else {
          setTableData((prevMessages) => [
            ...prevMessages,
            ...response.holidayRecords,
          ]);
          setOriginalTableData((prevMessages) => [
            ...prevMessages,
            ...response.holidayRecords,
          ]);
        }

        setReorderedFields(dataFields);
        onDataFetch(response);
        if (applyFilters && Object.keys(activeFilters).length > 0) {
          const filteredData = applySearchFilters(
            response.holidayRecords,
            activeFilters
          );
          setTableData(filteredData as RecordType[]);
        }
      })
      .catch((error) => {
        toggleLoader(false);
        setOpenErrorModal(true);
        if (isCustomError(error)) {
          const apiError = error as ApiErrorResponse;
          setErrorDesc(apiError.issue[0].diagnostics);
        } else {
          setErrorDesc(error.id);
        }
      });
    setActiveFilters({});
  };

  const handleDelete = (id: number) => {
    toggleLoader(true);

    deleteWizardsDataApi(apiUri, id)
      .then(() => {
        getRecords(true, selectedLanguageCode, true);
        holidayChildCallback();
      })
      .catch((error) => {
        toggleLoader(false);
        setOpenErrorModal(true);
        if (isCustomError(error)) {
          const apiError = error as ApiErrorResponse;
          setErrorDesc(apiError.issue[0].diagnostics);
        } else {
          setErrorDesc(error.id);
        }
      });
    handleClose();
    setModalAccountId(undefined);
  };

  const handleSort = (columnKey: string, direction: string) => {
    const sortedData = sortTableData(
      tableData,
      columnKey,
      direction,
      originalTableData
    );
    setTableData(sortedData as RecordType[]);
  };

  const handleSearchApply = (columnKey: string, value: string) => {
    const newFilters = { ...activeFilters, [columnKey]: value };
    const filteredData = applySearchFilters(originalTableData, newFilters);
    setTableData(filteredData as RecordType[]);
    onFilter(newFilters);
    setActiveFilters(newFilters);
  };

  const handleSearchCancel = (columnKey: string) => {
    const { filteredData, updatedFilters } = cancelSearchFilters(
      originalTableData,
      activeFilters,
      columnKey
    );
    setTableData(filteredData as RecordType[]);
    onFilter(updatedFilters);
    setActiveFilters(updatedFilters);
  };

  const handleClick = (
    event: React.MouseEvent<HTMLElement>,
    rowIndex: number
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(rowIndex);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handleEdit = (id: number | undefined) => {
    setModalAccountId(id);
    setShowAddEditForm(true);
    //handleModalOpen();
    handleClose();
  };

  const handleConfirmDelete = useCallback(() => {
    if (modalAccountId) {
      handleDelete(modalAccountId);
    }
    setWarningModal(false);
  }, [modalAccountId]);

  const isFilterApplied = () => {
    const filterApplied = Object.values(activeFilters).some(
      (value) => value !== ""
    );

    if (filterApplied === false || JSON.stringify(activeFilters) === "{}") {
      return true;
    } else {
      return false;
    }
  };

  const displayValues = (array: string[]) => {
    if (array.length === 0) {
      return "";
    }

    const firstValue = array[0];
    const remainingCount = array.length - 1;

    if (remainingCount > 0) {
      return (
        <Tooltip title={array.join(", ")}>
          <span>
            <MediumTypography
              labelId={
                Array.isArray(array)
                  ? `${firstValue} +${remainingCount}`
                  : array !== undefined && array !== null && array !== ""
                  ? (array as string[]).toString()
                  : "-"
              }
            />
          </span>
        </Tooltip>
      );
    } else {
      return <MediumTypography labelId={firstValue} />;
    }
  };
  const formatDate = (dateString: string) => {
    const dateParts = dateString.split(" - ");
    const date = dayjs(dateParts[0]);
    const dayOfWeek = dateParts[1].toLowerCase();
    return (
      date.format("DD-MM-YYYY") +
      " - " +
      dayOfWeek.charAt(0).toUpperCase() +
      dayOfWeek.slice(1)
    );
  };

  return (
    <Box>
      {showAddEditForm && (
        <AddEditHolidayModal
          open={showAddEditForm}
          handleClose={() => {
            setModalAccountId(undefined);
            setShowAddEditForm(false);
            //handleModalClose();
          }}
          id={modalAccountId}
          getDataCallback={() => {
            getRecords(true);
            holidayChildCallback();
          }}
          subGroupId={subGroupId}
        />
      )}

      {warningModal && (
        <ModalPopup
          descriptionText={"Holidays.deleteWarningmessage"}
          open={warningModal}
          handleClose={() => setWarningModal(!warningModal)}
          onPositiveClick={handleConfirmDelete}
          onNegativeClick={() => {
            setWarningModal(false);
            handleClose();
            setModalAccountId(undefined);
          }}
          positiveButtonLabel="Yes"
          positiveButtonLabelId="YesText"
          negativeButtonLabel="No"
          negativeButtonLabelId="NoText"
        />
      )}
      {openErrorModal && (
        <ErrorModal
          descriptionText={errorDesc}
          open={openErrorModal}
          handleClose={() => setOpenErrorModal(false)}
          onPositiveClick={() => {
            setOpenErrorModal(false);
          }}
        />
      )}
      {listData && (
        <>
          <Box className="flex__justify__space-between mb-md">
            <MediumTypography
              labelId="holiday.title"
              defaultLabel="Holidays"
              fontSize="20px"
            />
            <Box className="display-flex-center">
              <MediumTypography
                labelId="totalNumberOfRecords"
                defaultLabel="Total number of records"
                textColor="rgba(255, 255, 255, 0.64)"
                sxProps={{ marginRight: "4px" }}
              />
              <MediumTypography
                label={
                  isFilterApplied()
                    ? listData.totalRecords
                    : `${tableData.length} / ${listData.totalRecords}`
                }
                textColor="rgba(255, 255, 255, 0.64)"
              />

              <ButtonComponent
                className="btn-primary btn-submit ml-md"
                variantType="contained"
                defaultLabelId={"Add Holiday"}
                labelId={"btn.AddHoliday"}
                onClick={() => setShowAddEditForm(true)}
              />
            </Box>
          </Box>
          <Box
            sx={{
              height: availableHeight,

              // "&::-webkit-scrollbar": { display: "none" },
            }}
            className={"position__relative overflow_Y"}
          >
            <TableContainer
              component={Paper}
              sx={{
                width: menuOpen ? availableWidth : MenuOpenAvailableWidth,
                height: tableData.length !== 0 ? availableHeight : "auto",
                // "&::-webkit-scrollbar": { display: "none" },
              }}
              className={"tableContainer"}
              onScroll={() => {}}
              ref={containerRef}
            >
              <Table>
                <CustomTableHeader
                  headerNames={dataFieldsWithIcons}
                  searchableKeys={searchKeys}
                  stickyItems={staticColumn}
                  onSortApply={handleSort}
                  onCancelSearch={handleSearchCancel}
                  onSearchApply={handleSearchApply}
                  languageCode={selectedLanguageCode}
                />
                <TableBody>
                  {tableData.length !== 0 &&
                    tableData.map((row, rowIndex) => (
                      <TableRow
                        key={row.id}
                        sx={{
                          backgroundColor:
                            rowIndex % 2 === 0 ? "#2B3033" : "#22272B",
                        }}
                      >
                        {/* Sticky First Column */}
                        <TableCell
                          align={"left"}
                          sx={{
                            backgroundColor:
                              rowIndex % 2 === 0 ? "#2B3033" : "#22272B",
                          }}
                          className="stickyColumn"
                        >
                          <MediumTypography label={(rowIndex + 1).toString()} />
                        </TableCell>

                        {dataFieldsWithIcons.map((cell: HeadCell) => {
                          const cellValue = row[cell.key as keyof RecordType];

                          if (cell.key === "formattedDate") {
                            return (
                              <TableCell
                                key={cell.key}
                                align={"left"}
                                sx={{
                                  backgroundColor:
                                    rowIndex % 2 === 0 ? "#2B3033" : "#22272B",
                                }}
                                className="p-md"
                              >
                                <MediumTypography
                                  label={
                                    row.formattedDate !== null
                                      ? formatDate(row.formattedDate)
                                      : "-"
                                  }
                                />
                              </TableCell>
                            );
                          }

                          if (cell.key === "lastUpdated") {
                            return (
                              <TableCell
                                key={cell.key}
                                align={"left"}
                                sx={{
                                  backgroundColor:
                                    rowIndex % 2 === 0 ? "#2B3033" : "#22272B",
                                }}
                                className="p-md"
                              >
                                <Box className="display-flex-center">
                                  <Avatar
                                    src={
                                      row.lastUpdated !== null &&
                                      row.lastUpdated !== undefined
                                        ? row.lastUpdated.imageUrl
                                        : "-"
                                    }
                                    alt="abc"
                                    className="avatar-style"
                                  />
                                  <Box className="ml-sm">
                                    <MediumTypography
                                      label={
                                        row.lastUpdated !== null &&
                                        row.lastUpdated !== undefined
                                          ? row.lastUpdated.name
                                          : "-"
                                      }
                                      sxProps={{
                                        lineHeight: "normal",
                                      }}
                                      className="mt-xs"
                                    />

                                    <MediumTypography
                                      label={
                                        row.lastUpdated !== null &&
                                        row.lastUpdated !== undefined
                                          ? dayjs(
                                              row.lastUpdated.timestamp
                                            ).format("DD MMM YYYY HH:mm")
                                          : "-"
                                      }
                                      fontSize="12px"
                                      textColor={"rgba(255, 255, 255, 0.64)"}
                                    />
                                  </Box>
                                </Box>
                              </TableCell>
                            );
                          }

                          return (
                            <>
                              {cell.key === staticColumn ? (
                                <TableCell
                                  key={cell.key}
                                  align={"left"}
                                  sx={{
                                    backgroundColor:
                                      rowIndex % 2 === 0
                                        ? "#2B3033"
                                        : "#22272B",
                                  }}
                                  className="stickyColumn_left"
                                >
                                  <MediumTypography
                                    label={
                                      cellValue !== undefined &&
                                      cellValue !== null &&
                                      cellValue !== ""
                                        ? cellValue.toString()
                                        : "-"
                                    }
                                  />
                                </TableCell>
                              ) : (
                                <TableCell
                                  key={cell.key}
                                  align={"left"}
                                  sx={{
                                    backgroundColor:
                                      rowIndex % 2 === 0
                                        ? "#2B3033"
                                        : "#22272B",
                                  }}
                                  className="p-md"
                                >
                                  <MediumTypography
                                    label={
                                      cellValue !== undefined &&
                                      cellValue !== null &&
                                      cellValue !== ""
                                        ? cellValue.toString()
                                        : "-"
                                    }
                                  />
                                </TableCell>
                              )}
                            </>
                          );
                        })}

                        {/* Sticky Last Column */}
                        <TableCell
                          align={"left"}
                          sx={{
                            backgroundColor:
                              rowIndex % 2 === 0 ? "#2B3033" : "#22272B",
                          }}
                          className="stickyColumn_right"
                        >
                          <Tooltip title="More">
                            <IconButton
                              onClick={(event) => handleClick(event, rowIndex)}
                            >
                              <More />
                            </IconButton>
                          </Tooltip>
                          <Popover
                            anchorEl={anchorEl}
                            open={addEditOpen && selectedRow === rowIndex}
                            onClose={handleClose}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                            sx={{
                              "& .MuiPaper-root": {
                                backgroundColor: "#2B3033",
                              },
                            }}
                          >
                            <MenuItem
                              onClick={() =>
                                hasPermission("Onboarding_Admin")
                                  ? handleEdit(row.id)
                                  : () => {}
                              }
                              style={{ backgroundColor: "2B3033" }}
                              disabled={selectedLanguageCode !== "en"}
                            >
                              <MediumTypography
                                labelId="btn.edit"
                                defaultLabel="Edit"
                              />
                            </MenuItem>
                            <MenuItem
                              onClick={() =>
                                hasPermission("Onboarding_Admin")
                                  ? (setWarningModal(true),
                                    setModalAccountId(row.id))
                                  : () => {}
                              }
                              style={{ backgroundColor: "2B3033" }}
                            >
                              <MediumTypography
                                labelId="planner.deleteText"
                                defaultLabel="Delete"
                              />
                            </MenuItem>
                          </Popover>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            {tableData.length === 0 && (
              <Box
                sx={{
                  height: emptyTableHeight,
                }}
                className="display-flex-center flex__justify__center "
              >
                <MediumTypography
                  labelId="ManageAccounts.emptymsg"
                  defaultLabel="No records available"
                  fontSize="20px"
                  fontWeight={700}
                />
              </Box>
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default HolidayListScreen;
